import { createIcon } from '@chakra-ui/icon';

export const SelectedMessagesIcon = createIcon({
  displayName: 'SelectedMessagesIcon',
  viewBox: '0 0 12 12',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='12'
      width='12'
      viewBox='0 0 12 12'
    >
      <title>letter</title>
      <g fill='#ffffff' className='nc-icon-wrapper'>
        <path
          d='M6.718,7.817a1.5,1.5,0,0,1-1.437,0L0,4.936V9.5A1.5,1.5,0,0,0,1.5,11h9A1.5,1.5,0,0,0,12,9.5V4.936Z'
          fill='#ffffff'
        />
        <path
          data-color='color-2'
          d='M6,7a.5.5,0,0,1-.239-.061l-5.5-3A.5.5,0,0,1,0,3.5v-1A1.5,1.5,0,0,1,1.5,1h9A1.5,1.5,0,0,1,12,2.5v1a.5.5,0,0,1-.261.439l-5.5,3A.5.5,0,0,1,6,7Zm5.5-3.5h0Z'
        />
      </g>
    </svg>
  ),
});

export const MessagesIcon = createIcon({
  displayName: 'MessagesIcon',
  viewBox: '0 0 12 12',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='12'
      width='12'
      viewBox='0 0 12 12'
    >
      <title>letter</title>
      <g
        strokeWidth='1'
        strokeLinejoin='round'
        fill='none'
        stroke='#D3DAE2'
        strokeLinecap='round'
        className='nc-icon-wrapper'
      >
        <path d='M11.5,5.5v4a1,1,0,0,1-1,1h-9a1,1,0,0,1-1-1v-4' />
        <path
          d='M11.5,3.5v-1a1,1,0,0,0-1-1h-9a1,1,0,0,0-1,1v1L6,6.5Z'
          stroke='#D3DAE2'
        />
      </g>
    </svg>
  ),
});
