import { useReactiveVar } from '@apollo/client';
import {
  showingFilterPanelVar,
  windowWidthVar,
} from 'app/src/apollo/rootReactiveVariables';
import { useMemo } from 'react';
import {
  fullNavPanelWidthInt,
  navbarSizeInt,
  navbarSizeStr,
} from 'app/src/constants/navbarSize';

export const usePanelWidthAdjustments = () => {
  const windowWidth = useReactiveVar(windowWidthVar);
  const filterPanelIsShowing = useReactiveVar(showingFilterPanelVar);

  const contentWidth = useMemo(() => {
    if (filterPanelIsShowing) {
      return windowWidth - navbarSizeInt - fullNavPanelWidthInt;
    }
    return windowWidth - navbarSizeInt;
  }, [windowWidth, filterPanelIsShowing, navbarSizeInt, fullNavPanelWidthInt]);

  const contentLeft = useMemo(() => {
    if (filterPanelIsShowing) {
      return `${navbarSizeInt + fullNavPanelWidthInt}px`;
    }
    return navbarSizeStr;
  }, [
    filterPanelIsShowing,
    navbarSizeInt,
    fullNavPanelWidthInt,
    navbarSizeStr,
  ]);
  return { contentLeft, contentWidth };
};
