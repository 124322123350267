import { createIcon } from '@chakra-ui/icon';

export const SelectedHomeIcon = createIcon({
  displayName: 'SelectedHomeIcon',
  viewBox: '0 0 12 12',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12px'
      height='12px'
      viewBox='0 0 12 12'
      version='1.1'
    >
      <g id='surface1'>
        <path
          stroke='none'
          fillRule='nonzero'
          fill='rgb(100%,100%,100%)'
          fillOpacity='1'
          d='M 11.816406 5.113281 L 6.316406 0.613281 C 6.132812 0.460938 5.867188 0.460938 5.683594 0.613281 L 0.183594 5.113281 C -0.0234375 5.289062 -0.0546875 5.601562 0.117188 5.8125 C 0.292969 6.023438 0.601562 6.058594 0.816406 5.886719 L 1.5 5.328125 L 1.5 11 C 1.5 11.277344 1.722656 11.5 2 11.5 L 5 11.5 L 5 9 L 7 9 L 7 11.5 L 10 11.5 C 10.277344 11.5 10.5 11.277344 10.5 11 L 10.5 5.328125 L 11.183594 5.886719 C 11.320312 6.003906 11.511719 6.035156 11.679688 5.972656 C 11.847656 5.910156 11.972656 5.761719 12 5.582031 C 12.027344 5.40625 11.957031 5.226562 11.816406 5.113281 Z M 7 7 L 5 7 L 5 5 L 7 5 Z M 7 7 '
        />
      </g>
    </svg>
  ),
});

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 12 12',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12px'
      height='12px'
      viewBox='0 0 12 12'
      version='1.1'
    >
      <g id='surface1'>
        <path
          fill='none'
          strokeWidth='2'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          stroke='rgb(83.137255%,85.490196%,88.235294%)'
          strokeOpacity='1'
          strokeMiterlimit='10'
          d='M 10 23 L 10 17 L 14 17 L 14 23 '
          transform='matrix(0.5,0,0,0.5,0,0)'
        />
        <path
          fill='none'
          strokeWidth='2'
          strokeLinecap='butt'
          strokeLinejoin='miter'
          stroke='rgb(83.137255%,85.490196%,88.235294%)'
          strokeOpacity='1'
          strokeMiterlimit='10'
          d='M 1 11 L 12 2 L 23 11 '
          transform='matrix(0.5,0,0,0.5,0,0)'
        />
        <path
          fill='none'
          strokeWidth='2'
          strokeLinecap='square'
          strokeLinejoin='miter'
          stroke='rgb(83.137255%,85.490196%,88.235294%)'
          strokeOpacity='1'
          strokeMiterlimit='10'
          d='M 4 13 L 4 23 L 20 23 L 20 13 '
          transform='matrix(0.5,0,0,0.5,0,0)'
        />
        <path
          fillRule='nonzero'
          fill='rgb(83.137255%,85.490196%,88.235294%)'
          fillOpacity='1'
          strokeWidth='2'
          strokeLinecap='square'
          strokeLinejoin='miter'
          stroke='rgb(83.137255%,85.490196%,88.235294%)'
          strokeOpacity='1'
          strokeMiterlimit='10'
          d='M 11 11 L 13 11 L 13 13 L 11 13 Z M 11 11 '
          transform='matrix(0.5,0,0,0.5,0,0)'
        />
      </g>
    </svg>
  ),
});
