import { createBreakpoints } from '@chakra-ui/theme-tools';

/**
 * Breakpoints for responsive design
 */
export const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
});
