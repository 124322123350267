import {
  Box,
  Flex,
  HStack,
  Select,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { BannerPopover } from 'app/src/components/TableSection/components/BannerPopover';
import { Button, Input } from 'shared/components/Core';
import {
  AddIcon,
  CloseIcon,
  MinusIcon,
  PlusSquareIcon,
  ViewOffIcon,
} from '@chakra-ui/icons';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import {
  appliedListsVar,
  globalFilterVar,
} from 'app/src/apollo/rootReactiveVariables';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  GetAccountGroupDocument,
  GetAccountsListWithFilterVarDocument,
  UpdateAccountGroupAccountsDocument,
  useAddAccountGroupMutation,
  useGetAllAccountGroupsQuery,
} from 'shared/graphql/generatedApiTypes';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import AuthManager from 'shared/firebase/classes/AuthManager';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { FieldValue } from 'shared/firebase/initializeFirebase';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { usePanelWidthAdjustments } from 'app/src/hooks/usePanelWidthAdjustments';
import { groupAccountGroups } from 'app/src/components/GlobalFilters/helpers/groupAccountGroups';
import _difference from 'lodash/difference';
import { ExportIcon } from 'shared/components/Core/Icon/export';
import { useAccountCsvExport } from 'app/src/components/TableSection/hooks/useAccountCsvExport';

type UnhiddenActionBarProps = {
  selectedRowIds: { [key: string | number]: boolean };
  toggleAllRowsSelected: (val: boolean) => void;
  pageSize: any;
  selectedSort: any;
  pageInfo: any;
};

export const UnhiddenActionBar = ({
  selectedRowIds,
  toggleAllRowsSelected,
  pageSize,
  selectedSort,
  pageInfo,
}: UnhiddenActionBarProps) => {
  const newListInteractionModal = useDisclosure();
  const addToListInteractionModal = useDisclosure();
  const removeFromListInteractionModal = useDisclosure();
  const hubSpotListInteractionModel = useDisclosure();
  const hideAccountInteractionModal = useDisclosure();
  const exportModal = useDisclosure();
  const toast = useToast();
  const { updateExcludedIds } = useContext(AggregateDataContext);
  const globalFilter = useReactiveVar(globalFilterVar);
  const { contentLeft, contentWidth } = usePanelWidthAdjustments();
  const appliedLists = appliedListsVar();
  const [selectedList, setSelectedList] = useState<string>(
    appliedLists && appliedLists.length ? appliedLists[0].toString() : '',
  );
  const klearlyUser = AuthManager?.klearlyUser;
  const [selectedFlatRows, setSelectedFlatRows] = useState<any[]>([]);
  const { accountExportButton, accountExportFieldsCheckboxGrid } =
    useAccountCsvExport(selectedFlatRows, pageSize, selectedSort, pageInfo);

  const apolloClient = useApolloClient();
  const [hubSpotListName, setHubSpotListName] = useState<string>('');
  useEffect(() => {
    (async () => {
      const accountQuery = await apolloClient.query({
        query: GetAccountsListWithFilterVarDocument,
        variables: {
          limit: Object.keys(selectedRowIds).length,
          filterString: JSON.stringify({
            accountFilterType: 'and',
            accountIds: Object.keys(selectedRowIds),
          }),
        },
      });
      setSelectedFlatRows(accountQuery?.data?.accounts?.accounts ?? []);
    })();
  }, [selectedRowIds]);

  const [addAccountGroup, { loading: addAccountGroupLoading }] =
    useAddAccountGroupMutation();

  const { data: allAccountGroupsData } = useGetAllAccountGroupsQuery();
  const { lists: userListsToAddTo } = groupAccountGroups({
    accountGroups: allAccountGroupsData?.accountGroups,
    klearlyUser: AuthManager.klearlyUser,
    showOnlyLoggedInUsersGroups: true,
  });
  const yourListOptions = useMemo(
    () =>
      userListsToAddTo?.map((list) => ({
        label: list?.title ?? '',
        value: list?.id.toString() ?? '',
      })) ?? [],
    [userListsToAddTo],
  );

  const {
    bind: bindAccountGroupTitle,
    setValue: setAccountGroupTitle,
    value: accountGroupTitle,
  } = useInput('', {
    label: 'Name',
    placeholder: 'Name this list',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindAccountGroupDescription,
    setValue: setAccountGroupDescription,
    value: accountGroupDescription,
  } = useInput('', {
    label: 'Description',
    placeholder: 'Describe this list',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;

  const saveNewList = async () => {
    try {
      await addAccountGroup({
        variables: {
          input: {
            accountIds: selectedFlatRows.map((row) => row.id),
            description: accountGroupDescription,
            title: accountGroupTitle,
            filterString: undefined,
          },
        },
      });
      newListInteractionModal.onClose();
      setAccountGroupDescription('');
      setAccountGroupTitle('');
      toggleAllRowsSelected(false);

      toast({
        title: `List successfully created`,
        status: 'success',
        position: 'top',
        isClosable: true,
      });
    } catch {
      toast({
        title: `Error creating list; please check your connection & try again`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  };

  const addToExistingList = async () => {
    const groupId = parseInt(selectedList);
    addToListInteractionModal.onClose();
    const accountGroup = await apolloClient.query({
      query: GetAccountGroupDocument,
      variables: { id: groupId },
    });
    const idsToAdd = selectedFlatRows.map((row) => row.id);
    const idsAlreadyOnList = accountGroup.data.accountGroup.accounts.map(
      (acct) => acct.id,
    );
    const mergedIds = Array.from(new Set([...idsAlreadyOnList, ...idsToAdd]));
    await apolloClient.mutate({
      mutation: UpdateAccountGroupAccountsDocument,
      variables: {
        input: {
          accountIds: mergedIds,
          id: groupId,
        },
      },
    });
    setSelectedList('');
    toggleAllRowsSelected(false);

    toast({
      title: `Successfully added selected account(s) to list "${accountGroup.data.accountGroup.title}"`,
      status: 'success',
      position: 'top',
      isClosable: true,
    });
  };

  const removeFromExistingList = async () => {
    const groupId = parseInt(selectedList);
    removeFromListInteractionModal.onClose();
    const accountGroup = await apolloClient.query({
      query: GetAccountGroupDocument,
      variables: { id: groupId },
    });
    const idsToRemove = selectedFlatRows.map((row) => row.id);
    const idsAlreadyOnList = accountGroup.data.accountGroup.accounts.map(
      (acct) => acct.id,
    );
    const updatedIds = _difference(idsAlreadyOnList, idsToRemove);
    await apolloClient.mutate({
      mutation: UpdateAccountGroupAccountsDocument,
      variables: {
        input: {
          accountIds: updatedIds,
          id: groupId,
        },
      },
    });
    toggleAllRowsSelected(false);
    setSelectedList('');

    toast({
      title: `Successfully removed selected account(s) from list "${accountGroup.data.accountGroup.title}"`,
      status: 'success',
      position: 'top',
      isClosable: true,
    });
  };

  const hideSelectedCompanies = async () => {
    try {
      hideAccountInteractionModal.onClose();
      const firebaseUserUID = AuthManager.currentUser?.uid ?? '';

      const companyName = AuthManager.klearlyUser?.companyName ?? '';
      const userSettingsDocId = `${firebaseUserUID}#${companyName}`;
      const idsToHide = selectedFlatRows.map((row) => row.id);
      if (userSettingsDocId) {
        const userSettingsDoc = await DatabaseManager.UserSettingsModel.get(
          userSettingsDocId,
        );
        if (userSettingsDoc) {
          // merge in the selected ID
          await userSettingsDoc.ref.update({
            excludedAccountIds: FieldValue.arrayUnion(...idsToHide),
          });
        } else {
          // no doc, so create the doc with the new ids
          await DatabaseManager.UserSettingsModel.create(
            {
              excludedAccountIds: idsToHide,
              labCardFavorites: [],
              filterFavorites: [],
              listFavorites: [],
              quickAccessFilters: null,
            },
            userSettingsDocId,
          );
        }
        // update the current global filter to reflect
        if (globalFilter) {
          updateExcludedIds(
            Array.from(
              new Set([
                ...(globalFilter.excludedAccountIds ?? []),
                ...idsToHide,
              ]),
            ),
          );
        }
        toggleAllRowsSelected(false);
      }
    } catch (err) {
      console.error(err);
      toast({
        title: `Error hiding companies; please check your connection & try again`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  };

  // const handleHubSpotIntegration = async () => {
  //   try {
  //     let tempSelectedData: any = [];
  //     selectedFlatRows.forEach((row) => {
  //       let tempObj: any = {
  //         properties: [],
  //       };
  //       Object.entries(row).forEach(([key, value]) => {
  //         if (key === 'accountName' || key === 'id') {
  //           tempObj.properties.push({
  //             name: key === 'id' ? 'description' : 'name',
  //             value: key === 'id' ? `Example of ${value}` : value,
  //           });
  //         }
  //       });
  //       tempSelectedData.push(tempObj);
  //     });

  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         listId: hubSpotListName,
  //         data: tempSelectedData,
  //         companyId: klearlyUser?.company?.id,
  //       }),
  //     };
  //     let response = await fetch(
  //       'https://actions-gxn6pxrd4q-uc.a.run.app/companies/bulkCreate',
  //       requestOptions,
  //     );
  //   } catch {}
  // };

  return (
    <Box
      left={contentLeft}
      pos={'fixed'}
      w={contentWidth}
      bottom={0}
      color={'brand.white'}
    >
      <Flex
        align={'center'}
        justify={'space-between'}
        bg={'brand.klarityBlue'}
        minH={'56px'}
        p={3}
      >
        <Text ml={4} mr={2}>
          {selectedFlatRows?.length ?? 0} item(s) selected
        </Text>
        <Flex maxW={'60%'} gap={2} flexWrap={'wrap'} justify={'center'}>
          <BannerPopover
            interactionModal={newListInteractionModal}
            popoverTitle={'Create new list'}
            body={
              addAccountGroupLoading ? (
                <Text>Creating list...</Text>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    saveNewList();
                  }}
                >
                  <Input {...bindAccountGroupTitle} />
                  <Input {...bindAccountGroupDescription} />
                  <Button type={'submit'} text={'Save New List'} />
                </form>
              )
            }
          >
            <Button
              fontSize={['sm', null, null, 'md', null]}
              text={'New list'}
              variant={'banner'}
              leftIcon={<PlusSquareIcon />}
              onClick={newListInteractionModal.onOpen}
            />
          </BannerPopover>
          <BannerPopover
            interactionModal={addToListInteractionModal}
            popoverTitle={'Add to existing list'}
            body={
              <>
                {!userListsToAddTo || !userListsToAddTo.length ? (
                  <Text pb={4}>
                    You have no lists. Create a new one instead.
                  </Text>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      addToExistingList();
                    }}
                  >
                    <Select
                      placeholder={'Select from your lists'}
                      value={selectedList}
                      onChange={(e) => setSelectedList(e.target.value)}
                      pb={4}
                    >
                      {yourListOptions.map((opt) => (
                        <option value={opt.value} key={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </Select>
                    <Button
                      type={'submit'}
                      text={'Add to List'}
                      isDisabled={!selectedList}
                    />
                  </form>
                )}
              </>
            }
          >
            <Button
              fontSize={['sm', null, null, 'md', null]}
              text={'Add to list'}
              onClick={addToListInteractionModal.onOpen}
              variant={'banner'}
              leftIcon={<AddIcon />}
            />
          </BannerPopover>
          <BannerPopover
            interactionModal={removeFromListInteractionModal}
            popoverTitle={'Remove from existing list'}
            body={
              <>
                {!userListsToAddTo || !userListsToAddTo.length ? (
                  <Text pb={4}>
                    You have no lists. Create a new one instead.
                  </Text>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      removeFromExistingList();
                    }}
                  >
                    <Select
                      placeholder={'Select from your lists'}
                      value={selectedList}
                      onChange={(e) => setSelectedList(e.target.value)}
                      pb={4}
                    >
                      {yourListOptions.map((opt) => (
                        <option value={opt.value} key={opt.value}>
                          {opt.label}
                        </option>
                      ))}
                    </Select>
                    <Button
                      type={'submit'}
                      text={'Remove from List'}
                      isDisabled={!selectedList}
                    />
                  </form>
                )}
              </>
            }
          >
            <Button
              fontSize={['sm', null, null, 'md', null]}
              text={'Remove from list'}
              onClick={removeFromListInteractionModal.onOpen}
              variant={'banner'}
              leftIcon={<MinusIcon />}
            />
          </BannerPopover>
          <BannerPopover
            popoverTitle={'Export'}
            interactionModal={exportModal}
            body={
              <>
                <Text>Select fields:</Text>
                {accountExportFieldsCheckboxGrid}
                <HStack justify={'flex-end'} spacing={2}>
                  <Button
                    onClick={() => {
                      toggleAllRowsSelected(false);
                      exportModal.onClose();
                    }}
                    variant={'actionOutline'}
                    text={'Cancel'}
                    fontSize={['sm', null, null, 'md', null]}
                  />
                  {accountExportButton}
                </HStack>
              </>
            }
          >
            <Button
              fontSize={['sm', null, null, 'md', null]}
              text={'Export'}
              variant={'banner'}
              leftIcon={<ExportIcon />}
              onClick={exportModal.onOpen}
            />
          </BannerPopover>

          {/* <BannerPopover
            interactionModal={hubSpotListInteractionModel}
            popoverTitle={'Create a new static list'}
            body={
              <>
                <Input
                  placeholder=''
                  onChange={(e) => {
                    setHubSpotListName(e.target.value);
                  }}
                />
                <Button
                  text={'Continue'}
                  isDisabled={hubSpotListName !== '' ? false : true}
                  onClick={handleHubSpotIntegration}
                />
              </>
            }
          >
            <Button
              fontSize={['sm', null, null, 'md', null]}
              text={'Hub spot'}
              onClick={hubSpotListInteractionModel.onOpen}
              variant={'banner'}
              // leftIcon={<MinusIcxon />}
            />
          </BannerPopover> */}
          {/* <Button
            fontSize={['sm', null, null, 'md', null]}
            text={'Hub spot'}
            onClick={handleHubSpotIntegration}
            variant={'banner'}
            // leftIcon={<MinusIcxon />}
          /> */}
        </Flex>
        <Button
          mr={24}
          ml={6}
          text={'Cancel'}
          leftIcon={<CloseIcon boxSize={3} />}
          onClick={() => toggleAllRowsSelected(false)}
        />
      </Flex>
    </Box>
  );
};
