import { useContext, useEffect, useMemo, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  globalFilterVar,
  showingFilterPanelVar,
  windowHeightVar,
  windowWidthVar,
} from '../../apollo/rootReactiveVariables';
import { AppNavigationalStructureContext } from '../../context/AppNavigationalStructureContext';
import { Box } from '@chakra-ui/react';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import {
  ComponentType,
  FullComponentType,
  FullStructuredTabComponent,
} from 'shared/firebase/schemas/SavedComponent';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import {
  GridComponent,
  StructuredComponentRenderer,
} from 'shared/renderers/ComponentRenderer';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { SectionComponents } from 'shared/types/sectionComponents';
import { statSummaryItemRenderer } from 'app/src/renderers/statSummaryItemRenderer';
import { fullNavPanelWidthInt } from 'app/src/constants/navbarSize';
import { useWindowSize } from 'app/src/hooks/useWindowSize';
import { usePanelWidthAdjustments } from 'app/src/hooks/usePanelWidthAdjustments';
import { Card } from 'shared/components/Core';

export const RootPage = ({ pageKey }: { pageKey: string }) => {
  useReactiveVar(globalFilterVar);
  const { structure } = useContext(AppNavigationalStructureContext);
  const selectedPage = structure?.pages.find((page) => pageKey === page.key);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const filterPanelIsShowing = useReactiveVar(showingFilterPanelVar);
  const { data, loading } = useContext(AggregateDataContext);

  const accountData = useMemo(() => {
    if (data?.aggregateData && selectedPage?.statSummaryConfig) {
      return selectedPage.statSummaryConfig.map((item) =>
        statSummaryItemRenderer(item, data.aggregateData),
      );
    }
    return null;
  }, [data?.aggregateData]);

  const summaryGridSubComponents = accountData?.map((item) => ({
    key: SectionComponents.SingleAccountQuickStat,
    gridWidth: 120 / accountData.length,
    data: {
      title: item?.title ?? '',
      center: {
        leftText: item?.value ?? 'N/A',
        fallbackText: 'N/A',
      },
      bottom: {
        leftText: item?.lowerText ?? '',
      },
      bgColor: '#F5F8F9',
    },
  }));

  useEffect(() => {
    if (selectedPage?.browserTabTitle) {
      updateAppHeaders(selectedPage.browserTabTitle);
    }
  }, [selectedPage?.browserTabTitle]);

  useEffect(() => {
    windowWidthVar(windowWidth);
  }, [windowWidth]);

  useEffect(() => {
    windowHeightVar(windowHeight);
  }, [windowHeight]);

  const { contentWidth } = usePanelWidthAdjustments();

  const topBarLeft = useMemo(() => {
    if (filterPanelIsShowing) {
      return `${fullNavPanelWidthInt}px`;
    }
    return 0;
  }, [filterPanelIsShowing]);

  if (
    selectedPage?.statSummaryConfig &&
    selectedPage?.statSummaryConfig.length &&
    loading
  ) {
    return (
      <Box left={topBarLeft} top={0} w={`${contentWidth}px`}>
        <Card isLoading={true} mx={4}>
          <Box />
        </Card>
      </Box>
    );
  }

  // components that include "header" go above the tabs
  const firstComponentIsHeader = (
    selectedPage?.components[0].components[0] as FullComponentType
  )?.component.key
    .toLowerCase()
    .includes('header');

  return selectedPage ? (
    <>
      <Box left={topBarLeft} top={0} w={`${contentWidth}px`}>
        {!!summaryGridSubComponents?.length && (
          <GridComponent
            component={{
              componentType: ComponentType.partWidth,
              subComponents: summaryGridSubComponents,
            }}
          />
        )}
        {firstComponentIsHeader &&
          selectedPage &&
          StructuredComponentRenderer(
            selectedPage?.components[0].components[0] as FullComponentType,
          )}
        <TabComponent component={selectedPage.components} isMock={false} />
      </Box>
    </>
  ) : null;
};

const TabComponent = ({
  component,
  isMock = false,
}: {
  component: FullStructuredTabComponent[];
  isMock?: boolean;
}) => {
  const { pathname, hash, search } = useLocation();
  const history = useHistory();
  const globalFilter = useReactiveVar(globalFilterVar);
  const [tabIndex, setTabIndex] = useState<number | undefined>(undefined);
  const orderedTabComponents = useMemo(
    () => component.sort((a, b) => (a.order > b.order ? 1 : -1)),
    [component],
  );
  // manage tab changes based on the URL hash - only if it's not a mock though
  useEffect(() => {
    let tabIndices: { [key: string]: number } = {};
    let defaultTab = '';
    orderedTabComponents.forEach((tab, index) => {
      if (index === 0) {
        defaultTab = tab.hash;
      }
      tabIndices[tab.hash] = index;
    });
    if (tabIndices[hash] !== undefined) {
      setTabIndex(tabIndices[hash]);
    } else {
      setTabIndex(tabIndices[defaultTab]);
      if (!isMock) {
        // make sure we set filter params into the query string when the filter is updated
        const newQueryString = queryString.stringify({
          filter: encodeURIComponent(
            globalFilter ? JSON.stringify(globalFilter) : '{}',
          ),
        });
        history.replace(`${pathname}?${newQueryString}${defaultTab}`);
      }
    }
  }, [
    pathname,
    hash,
    history,
    orderedTabComponents,
    isMock,
    search,
    globalFilter,
  ]);

  return (
    <Tabs index={tabIndex} isLazy variant={'unstyled'}>
      <>
        <TabList
          ml={4}
          display={orderedTabComponents.length === 1 ? 'none' : undefined}
        >
          {orderedTabComponents.map((tab) => (
            <Tab
              key={tab.hash}
              onClick={() => history.replace(`${pathname}${search}${tab.hash}`)}
              borderBottomColor={'transparent'}
              borderBottomWidth={2}
              px={2}
              mx={2}
              _selected={{
                borderBottomColor: 'brand.coral',
              }}
            >
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <TabPanels style={{backgroundColor: '#FBFBFC'}}>
          {orderedTabComponents.map((tab, i) => (
            <TabPanel p={6} key={i}>
              {tab.components.map((tabComponent, index) => (
                <Box key={index}>
                  {/*header is  shown above tabs*/}
                  {tabComponent.componentType === ComponentType.fullWidth &&
                  tabComponent.component.key.toLowerCase().includes('header')
                    ? null
                    : StructuredComponentRenderer(tabComponent)}
                </Box>
              ))}
            </TabPanel>
          ))}
        </TabPanels>
      </>
    </Tabs>
  );
};
