import React from 'react';
import {ApiXeominPurchaseTrend} from 'shared/graphql/generatedApiTypes';
import {FirstRecentSaleIcon} from '../../components/Core/Icon/firstRecentSale';
import {NeverPurchasedIcon} from '../../components/Core/Icon/neverPurchased';
import {SteadyIcon} from '../../components/Core/Icon/steady';
import {TrendingUpIcon} from '../../components/Core/Icon/trendingUp';
import {TrendingDownIcon} from '../../components/Core/Icon/trendingDown';
import {TrendingFlatIcon} from '../../components/Core/Icon/trendingFlat';
import {NewAccountIcon} from '../../components/Core/Icon/newAccount';
import {NoRecentSaleIcon} from '../../components/Core/Icon/noRecentSale';

export const getPurchaseTrendIcon = (
  trend: ApiXeominPurchaseTrend,
  iconColor: string | undefined,
) => {
  switch (trend) {
    case ApiXeominPurchaseTrend.FirstRecentSale:
      return (
        <FirstRecentSaleIcon color={iconColor} data-testid={'purchase-trend'} />
      );
    case ApiXeominPurchaseTrend.NeverPurchased:
      return (
        <NeverPurchasedIcon color={iconColor} data-testid={'purchase-trend'} />
      );
    case ApiXeominPurchaseTrend.Steady:
      return <SteadyIcon color={iconColor} data-testid={'purchase-trend'} />;
    case ApiXeominPurchaseTrend.TrendingUp:
      return (
        <TrendingUpIcon color={iconColor} data-testid={'purchase-trend'} />
      );
    case ApiXeominPurchaseTrend.TrendingDown:
      return (
        <TrendingDownIcon color={iconColor} data-testid={'purchase-trend'} />
      );
    case ApiXeominPurchaseTrend.TrendingFlat:
      return (
        <TrendingFlatIcon color={iconColor} data-testid={'purchase-trend'} />
      );
    case ApiXeominPurchaseTrend.NewAccount:
      return (
        <NewAccountIcon color={iconColor} data-testid={'purchase-trend'} />
      );
    case ApiXeominPurchaseTrend.NoRecentSale:
      return (
        <NoRecentSaleIcon color={iconColor} data-testid={'purchase-trend'} />
      );
    default:
      return null;
  }
};
