import { ApiAccountField } from 'shared/graphql/generatedApiTypes';

export const mapColumnNamesToSorts = (columnName: string) => {
  switch (columnName) {
    case 'xeominAverageQuantity':
      return ApiAccountField.XeominAverageQuantity;
    case 'xeominPurchaseTrendShort':
      return ApiAccountField.XeominPurchaseTrendShort;
    case 'xeominSpendFraction':
      return ApiAccountField.XeominSpendFraction;
    case 'customerStatus':
      return ApiAccountField.CustomerStatus;
    case 'xeominPredictionSummary':
      return ApiAccountField.XeominPredictionSummary;
    case 'engagementStatus':
      return ApiAccountField.EngagementStatus;
    case 'outreachStatus':
      return ApiAccountField.OutreachStatus;
    case 'signalResponseIntensity':
      return ApiAccountField.SignalResponseIntensity;
    case 'signalActivityIntensity':
      return ApiAccountField.SignalActivityIntensity;
    case 'lastInboundSignal.occurredAt':
      return ApiAccountField.LastInboundSignalOccurredAt;
    case 'lastOutboundSignal.occurredAt':
      return ApiAccountField.LastOutboundSignalOccurredAt;
    case "contactCount":
      return ApiAccountField.ContactCount;
    case 'rankPriority':
      return ApiAccountField.RankPriority;
    case 'messageCount':
      return ApiAccountField.MessageCount;
    default:
      return ApiAccountField.AccountName;
  }
};
