import { GetImpactValuesType } from '../../../types/impactValuesTypes';
import { useImpactValuesTable } from './useImpactValuesTable';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { Table } from 'shared/components/Core';
import { RecommendationsToggleShowHideButton } from './RecommendationsToggleShowHideButton';
import { recommendationsTableAccessors } from '../../../constants/recommendationsTableAccessors';

export const MaxImpact = ({
  accountInfo,
  tableHeader,
  sectionIsShowing,
  setSectionIsShowing,
}: GetImpactValuesType) => {
  const {
    nonPriorityImpactLabel: impactLabel,
    nonPriorityTableData: tableData,
    tableColumns,
  } = useImpactValuesTable(accountInfo, tableHeader);

  return (
    <Stack spacing={4}>
      <Flex alignItems={'center'} justify={'space-between'}>
        <Flex alignItems={'center'}>
          <Flex
            align={'center'}
            borderColor={'brand.white'}
            borderRadius={'50%'}
            boxShadow={'0 1px 1px rgba(0, 0, 0, 0.25)'}
            justify={'center'}
            margin={'auto'}
            bg={'brand.green'}
            height={10}
            minW={10}
            mr={6}
          >
            <Text color={'brand.white'} fontWeight={'extrabold'}>
              {impactLabel}
            </Text>
          </Flex>
          <Stack>
            <Text fontWeight={'bold'}>ALL ACTIONS:</Text>
            <Text>
              Two or more actions that, if executed together, would collectively
              have the greatest Winfluence impact.
            </Text>
          </Stack>
        </Flex>
        <RecommendationsToggleShowHideButton
          accountId={accountInfo?.id ?? 0}
          isShowing={sectionIsShowing}
          sectionType={'all'}
          setIsShowing={setSectionIsShowing}
        />
      </Flex>
      {sectionIsShowing && (
        <Table
          columns={tableColumns}
          data={tableData}
          initialDefaultSortByColumnId={
            recommendationsTableAccessors.signalType
          }
          tableVPadding={'lg'}
        />
      )}
    </Stack>
  );
};
