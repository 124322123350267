import {ReactNode} from 'react';
import _map from 'lodash/map';
import {HeatMapDefaultRows, HeatMapPropsType} from './HeatMap';

type RowsType = HeatMapDefaultRows & {
  rowsFormatter: (props: HeatMapPropsType) => ReactNode;
};

const Rows = ({
  rows = [],
  rowsFormatter = (props) => props.label,
}: RowsType) => (
  <>
    {_map(rows, (row, idx) => (
      <span className={'heatmap-row-label'} key={idx}>
        {rowsFormatter(row)}
      </span>
    ))}
  </>
);

export default Rows;
