import {createIcon} from '@chakra-ui/icon';

export const TrendingDownIcon = createIcon({
  displayName: 'TrendingDown',
  viewBox: '0 0 17 10',
  path: (
    <svg
      width='17'
      height='10'
      viewBox='0 0 17 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6667 10L13.575 8.09167L9.50833 4.025L6.175 7.35833L0 1.175L1.175 0L6.175 5L9.50833 1.66667L14.7583 6.90833L16.6667 5V10H11.6667Z'
        fill='#F62930'
      />
    </svg>
  ),
});
