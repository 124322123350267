import { typography } from './typography';
import { ChakraTheme } from '@chakra-ui/theme';
import { breakpoints } from './breakpoints';
import { zIndices } from './zIndices';
import { radii } from './radii';
import { colors } from './colors';
import { sizes } from './sizes';
import { shadows } from './shadows';
import { borders } from './borders';
import { transition } from './transition';
import { spacing } from './spacing';

export const foundations: Pick<
  ChakraTheme,
  | 'breakpoints'
  | 'zIndices'
  | 'radii'
  | 'colors'
  | 'letterSpacings'
  | 'lineHeights'
  | 'fontWeights'
  | 'fonts'
  | 'fontSizes'
  | 'sizes'
  | 'shadows'
  | 'space'
  | 'borders'
  | 'transition'
> = {
  breakpoints,
  zIndices,
  radii,
  colors,
  ...typography,
  sizes,
  shadows,
  space: spacing,
  borders,
  transition,
};
