import { useApolloClient } from '@apollo/client';
import _get from 'lodash/get';
import {
  ApiGetKlearlyUserQuery,
  useGetKlearlyUserQuery,
} from 'shared/graphql/generatedApiTypes';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import Honeybadger from '@honeybadger-io/js';
import { ChildrenType } from '../types/appTypes';
import { pendo } from '../constants/pendo';
import { WithLoading } from './WithLoading';
import { useEffect, useState } from 'react';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { FullSavedStructureSchema } from 'shared/firebase/schemas/SavedStructure';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { FullSavedPageSchema } from 'shared/firebase/schemas/SavedPage';

export const AppKlearlyUser = ({ children }: ChildrenType) => {
  const [structureFromLoading, setStructureFromLoading] =
    useState<FullSavedStructureSchema | null>(null);

  // setup hooks
  const apolloClient = useApolloClient();
  const isAuthenticated = !!AuthManager.currentUser;

  // setup API calls
  const { data, loading: klearlyUserLoading } = useGetKlearlyUserQuery({
    ...defaultQueryFetchPolicy,
    onError: () =>
      AuthManager.signOut().then(() => {
        apolloClient.resetStore();
        return <Redirect push to={ROUTES.LOG_IN} />;
      }),
    skip: !isAuthenticated,
  });
  // setup consts
  const user: ApiGetKlearlyUserQuery['me'] | null = _get(data, 'me', null);
  const companyName = _get(user, 'companyName', '');
  const userEmail = _get(user, 'email', '');
  // use the auth user id from firebase so that it's unique across tenants
  const userId = AuthManager?.currentUser?.uid ?? '';
  const userFirstName = _get(user, 'firstName', '');
  const userLastName = _get(user, 'lastName', '');
  const userRole = _get(user, 'role', '');
  const userName = `${userFirstName} ${userLastName}`;
  // setup analytics and firebase user
  if (user) {
    AuthManager.setKlearlyUser(user);
    Honeybadger.setContext({
      user_company_name: companyName,
      user_email: userEmail,
      user_id: userId,
      user_name: userName,
    });
    pendo.initialize({
      visitor: {
        id: userId?.toString() ?? '', // Required if user is logged in
        email: userEmail ?? '', // Recommended if using Pendo Feedback, or NPS Email
        full_name: userName, // Recommended if using Pendo Feedback
        role: userRole, // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      account: {
        id: companyName ?? '', // Highly recommended
        name: companyName ?? '', // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  }

  useEffect(() => {
    (async () => {
      if (AuthManager?.klearlyUser) {
        const structure = await DatabaseManager.UiConfigModel.get(
          AuthManager.klearlyUser.companyName,
        );
        const data = structure?.data;
        if (data?.structureRef) {
          const savedStructure = await DatabaseManager.SavedStructureModel.get(
            data.structureRef,
          );
          if (savedStructure?.data) {
            const pagePromises = savedStructure.data.pages.map(
              async (pageId) => {
                const pageDocument = await DatabaseManager.SavedPageModel.get(
                  pageId,
                );
                return pageDocument?.data;
              },
            );
            const pages = await Promise.all(pagePromises);
            const resolvedFullPagePromises = pages.map(async (page) => {
              if (page) {
                const pageComponentPromises = page.components.map(
                  async (tabComponent) => {
                    const tabComponentPromises = tabComponent.components.map(
                      async (componentId) => {
                        const componentDocument =
                          await DatabaseManager.SavedComponentModel.get(
                            componentId,
                          );
                        return componentDocument?.data;
                      },
                    );
                    return {
                      ...tabComponent,
                      components: await Promise.all(tabComponentPromises),
                    };
                  },
                );
                return {
                  ...page,
                  components: await Promise.all(pageComponentPromises),
                };
              }
            });
            const resolvedFullPages = (await Promise.all(
              resolvedFullPagePromises,
            )) as FullSavedPageSchema[];

            const resolvedFullStructure = {
              ...savedStructure?.data,
              pages: resolvedFullPages,
            };
            setStructureFromLoading(resolvedFullStructure);
          }
        }
      }
    })();
  }, [AuthManager?.klearlyUser]);

  return (
    <WithLoading
      isAuthenticated={isAuthenticated}
      isLoading={
        klearlyUserLoading || (isAuthenticated && !structureFromLoading)
      }
      structureFromLoading={structureFromLoading}
      user={user}
    >
      {children}
    </WithLoading>
  );
};
