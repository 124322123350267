import { Card, Loading } from '../../../../../shared/components/Core';
import { Box, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { useGetAccountMessagesQuery } from 'shared/graphql/generatedApiTypes';
import { MessageItem } from 'app/src/components/MessageFeed/components/MessageItem';
import { formatDistance } from 'date-fns';

export type MessageFeedProps = {};

export const MessageFeed = ({}: MessageFeedProps) => {
  const { id } = useParams<{ id: string }>();

  const { data, loading } = useGetAccountMessagesQuery({
    ...defaultQueryFetchPolicy,
    variables: { id: parseInt(id) },
  });

  return (
    <>
      <Text fontSize={'xl'} fontWeight={'bold'} py={4}>
        Messages
      </Text>
      {loading ? (
        <Loading />
      ) : data?.account?.messages?.length ? (
        data?.account?.messages.map((insight, index) =>
          insight ? (
            <Box key={index}>
              <Card isLoading={loading} minH={36}>
                <MessageItem
                  messageType={insight.type}
                  date={formatDistance(
                    new Date(insight.occurredAt),
                    new Date(),
                    {
                      addSuffix: true,
                      includeSeconds: true,
                    },
                  )}
                  message={insight.body}
                />
              </Card>
            </Box>
          ) : null,
        )
      ) : (
        <Text>No messages available for this company.</Text>
      )}
    </>
  );
};
