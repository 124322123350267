import { getAdaptedFilter } from 'app/src/components/GlobalFilters/helpers/adaptFilterForQuery';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { adaptPredictionTypeLevelToFilterIndices } from 'app/src/components/GaugeChart/constants/gaugeBins';

export const mergeAcceptIncomingTypePolicy = {
  merge(_, incoming) {
    return incoming;
  },
};

export const aggregateAccountsDataTypePolicy = {
  keyArgs: ['filterString'],
  merge(existing, incoming) {
    if (incoming.predictionLevel) {
      // handle conversion of the facto fields to our filter indices
      return {
        __typename: 'AggregateAccountsData',
        ...adaptPredictionTypeLevelToFilterIndices({ ...incoming }),
      };
    }
    return { ...incoming };
  },
};

export const mergeExistingAndIncomingTypePolicy = {
  merge(existing = {}, incoming) {
    return { ...existing, ...incoming };
  },
};

export const mergeObjectsTypePolicy = {
  merge(existing, incoming, { mergeObjects }) {
    return mergeObjects(existing, incoming);
  },
};

export const accountReadFromReferenceTypePolicy = {
  read(val, { args, toReference }) {
    return toReference({
      __typename: 'Account',
      id: args?.id,
    });
  },
};

export const accountsWithoutPredictionsTypePolicy = {
  keyArgs: ['sort'],
  merge(
    existing = {
      accounts: [],
      pageInfo: { totalPages: 0, totalEntries: 0 },
    },
    incoming,
  ) {
    return {
      pageInfo: { ...incoming.pageInfo },
      accounts: mergePaginatedAccounts(existing.accounts, incoming.accounts),
    };
  },
  read(existing, { args }) {
    // A read function should always return undefined if existing is
    // undefined. Returning undefined signals that the field is
    // missing from the cache, which instructs Apollo Client to
    // fetch its value from your GraphQL server.
    if (!existing || existing.accounts.length < args?.page * args?.limit) {
      return undefined;
    }
    if (args?.page && args?.limit) {
      return {
        pageInfo: {
          ...existing.pageInfo,
          totalPages: Math.ceil(existing.pageInfo.totalEntries / args.limit),
        },
        accounts: existing.accounts.slice(
          (args.page - 1) * args.limit,
          args.page * args.limit,
        ),
      };
    }
  },
};

const mergePaginatedAccounts = (existing, incoming) => {
  // this means that we've increased the page length, so we should replace existing with the whole new page
  if (incoming.length > existing.length) {
    return [...incoming];
  }
  return [...existing, ...incoming];
};

export const filterStringTypePolicy = {
  read() {
    return getAdaptedFilter();
  },
};

export const filterStringWithoutEngagementStatusTypePolicy = {
  read() {
    return getAdaptedFilter([FilterIndices.ENGAGEMENT_STATUS]);
  },
};
