import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  useToast,
} from '@chakra-ui/react';
import { Button } from 'shared/components/Core';
import { useEffect, useState } from 'react';
import { UPDATE_CURRENT_USER_COMPANY } from 'shared/graphql/gqlMutations';
import { useApolloClient } from '@apollo/client';
import { useGetAllCompaniesLazyQuery } from 'shared/graphql/generatedApiTypes';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import AuthManager from 'shared/firebase/classes/AuthManager';

type UpdateCompanyModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const UpdateCompanyModal = ({
  isOpen,
  onClose,
}: UpdateCompanyModalProps) => {
  const apolloClient = useApolloClient();
  const klearlyUser = AuthManager.klearlyUser;
  const [currentCompany, setCurrentCompany] = useState<string>(
    klearlyUser?.companyName ?? '',
  );
  const toast = useToast();
  const [getAllCompanies, { data }] = useGetAllCompaniesLazyQuery({
    ...defaultQueryFetchPolicy,
  });
  useEffect(() => {
    // only want to make this call if we're a Klearly admin
    if (klearlyUser?.isKlearlyAdmin) {
      getAllCompanies();
    }
  }, []);

  const updateCompany = async () => {
    try {
      await apolloClient.mutate({
        mutation: UPDATE_CURRENT_USER_COMPANY,
        variables: { companyName: currentCompany },
      });
      // Clear the filter string

      // Force a full reload to clear the apollo cache & the SDUI structure
      window.location.replace(window.location.pathname);
    } catch (err) {
      onClose();
      toast({
        title: `Failed to update your company`,
        status: 'error',
        position: 'top',
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Select Company</ModalHeader>
        <ModalBody>
          {data?.companies && (
            <Select
              value={currentCompany}
              onChange={(e) => setCurrentCompany(e.target.value)}
            >
              {data.companies
                .filter((company) => company)
                .map((company) => (
                  <option
                    value={company?.companyName}
                    key={company?.companyName}
                  >
                    {company?.companyName}
                  </option>
                ))}
            </Select>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button
              variant={'activeOutline'}
              onClick={onClose}
              text={'Cancel'}
            />
            <Button onClick={updateCompany} text={'Update'} />
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
