import { PrimaryActionSection } from './PrimaryActionSection';
import { RecommendationsProps } from './Recommendations';
import { MaxImpact } from './MaxImpactSection';

export const RecommendationsBody = ({
  accountInfo,
  sectionVisibilityToggles,
  ...props
}: Omit<RecommendationsProps, 'title' | 'subtitle'>) => {
  const tableHeaders = {
    expectedRecommendedText: props.expectedRecommended.title,
    expectedRecommendedTooltip: props.expectedRecommended.title,
    recommendationText: props.recommendation.title,
    recommendationTooltip: props.recommendation.title,
    signalTypeText: props.signalType.title,
    signalTypeTooltip: props.signalType.title,
  };

  //get section visibility toggles
  const {
    setShowingAllActions,
    showingAllActions,
    setShowingPrimaryActions,
    showingPrimaryActions,
  } = sectionVisibilityToggles;

  return (
    <>
      <PrimaryActionSection
        accountInfo={accountInfo}
        tableHeader={tableHeaders}
        sectionIsShowing={showingPrimaryActions}
        setSectionIsShowing={setShowingPrimaryActions}
      />
      <MaxImpact
        accountInfo={accountInfo}
        tableHeader={tableHeaders}
        sectionIsShowing={showingAllActions}
        setSectionIsShowing={setShowingAllActions}
      />
    </>
  );
};
