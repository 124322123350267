import { LabFirestoreValuesType } from '../types/firestoreValuesTypes';
import {
  AccordionPanelWrapper,
  Card,
  Container,
  Loading,
} from '../../../../../shared/components/Core';
import { useParams } from 'react-router-dom';
import { LabCardDetail } from 'app/src/components/Lab/components/LabCardDetail';
import AuthManager from 'shared/firebase/classes/AuthManager';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { useAsync } from 'app/src/hooks/useAsync';
import { useEffect, useMemo } from 'react';
import { Accordion, SimpleGrid } from '@chakra-ui/react';
import { LabCard } from 'app/src/components/Lab/components/LabCard';
import { buildLabAccordionSections } from 'app/src/components/Lab/helpers/buildLabAccordionSections';
import { cleanStringCase } from 'shared/helpers/formatHelpers';
import { FieldValue } from 'shared/firebase/initializeFirebase';

export type LabsAccordionsProps = {
  labCards: LabFirestoreValuesType[];
  labTags: Array<{ order: number; index: string }>;
};

export const LabsAccordions = ({ labCards, labTags }: LabsAccordionsProps) => {
  const { id: labCardId } = useParams<{ id: string }>();
  const selectedCard = labCards.find((card) => card.id === labCardId);
  if (labCardId && selectedCard) {
    return <LabCardDetail labCard={selectedCard} />;
  }
  const firebaseUserUID = AuthManager.currentUser?.uid ?? '';
  const companyName = AuthManager.klearlyUser?.companyName ?? '';
  const userSettingsDocId = `${firebaseUserUID}#${companyName}`;

  const getUserSettingsAsync = async () => {
    return DatabaseManager.UserSettingsModel.get(userSettingsDocId);
  };
  const getUserSettings = useAsync(getUserSettingsAsync);

  const getLabCardFeedbackAsync = async ({
    companyName,
  }: {
    companyName: string;
  }) => {
    return DatabaseManager.LabCardFeedbackModel.get(companyName);
  };
  const getLabCardFeedback = useAsync(getLabCardFeedbackAsync);

  useEffect(() => {
    if (firebaseUserUID) {
      getUserSettings.execute();
    }
    if (companyName) {
      getLabCardFeedback.execute({ companyName });
    }
  }, [firebaseUserUID]);

  const toggleFavoriteFlag = async (
    labCardId: string,
    isFavorited: boolean,
  ) => {
    if (getUserSettings.value?.data && firebaseUserUID) {
      const { data } = getUserSettings.value;
      // it's already favorited
      if (isFavorited) {
        await getUserSettings.value.ref.update({
          labCardFavorites: FieldValue.arrayRemove(labCardId),
        });
        getUserSettings.execute();
      } else {
        const newFavorites = data?.labCardFavorites ?? [];
        newFavorites.push(labCardId);
        await DatabaseManager.UserSettingsModel.update(userSettingsDocId, {
          ...data,
          labCardFavorites: newFavorites,
        });
      }
    } else {
      await DatabaseManager.UserSettingsModel.create(
        {
          excludedAccountIds: [],
          labCardFavorites: [labCardId],
          filterFavorites: [],
          listFavorites: [],
          quickAccessFilters: null,
        },
        userSettingsDocId,
      );
    }
    getUserSettings.execute();
  };

  const toggleFeedbackLikeFlag = async (
    labCardId: string,
    isLiked: boolean,
  ) => {
    if (
      getLabCardFeedback.value?.data &&
      getLabCardFeedback.value.data[labCardId]
    ) {
      if (isLiked) {
        const newLikes = getLabCardFeedback.value.data[labCardId].likes.filter(
          (like) => like !== firebaseUserUID,
        );
        await DatabaseManager.LabCardFeedbackModel.update(companyName, {
          ...getLabCardFeedback.value.data,
          [labCardId]: {
            ...getLabCardFeedback.value.data[labCardId],
            likes: newLikes,
          },
        });
      } else {
        const newLikes =
          getLabCardFeedback.value.data[labCardId].likes ?? ([] as string[]);
        newLikes.push(firebaseUserUID);
        await DatabaseManager.LabCardFeedbackModel.update(companyName, {
          ...getLabCardFeedback.value.data,
          [labCardId]: {
            ...getLabCardFeedback.value.data[labCardId],
            likes: newLikes,
          },
        });
      }
    } else {
      const existingData = getLabCardFeedback.value?.data ?? {};
      await DatabaseManager.LabCardFeedbackModel.create(
        {
          ...existingData,
          [labCardId]: { comments: [], likes: [firebaseUserUID] },
        },
        companyName,
      );
    }
    getLabCardFeedback.execute({ companyName });
  };

  const tableValues = labCards.filter((item) => item.showCard);

  const labCardIds = useMemo(() => {
    if (getUserSettings.value) {
      return getUserSettings.value.data?.labCardFavorites ?? [];
    }
    return [];
  }, [getUserSettings?.value, getUserSettings?.error]);

  const { accordionSections, orderedTags } = buildLabAccordionSections({
    labCardIds,
    tableValues,
    tagsValues: labTags,
  });

  return (
    <Container ph={'xl'} pv={'xl'} width={'full'}>
      {!getUserSettings.pending ? (
        <Accordion allowToggle allowMultiple defaultIndex={[0]} reduceMotion>
          {orderedTags.map((tag, index) => {
            return !!accordionSections[tag.index].length ? (
              <AccordionPanelWrapper
                key={index}
                render={(isExpanded) => (
                  <SimpleGrid columns={[1, null, null, 2, 3, null]} gap={3}>
                    {accordionSections[tag.index].map((lab) =>
                      isExpanded ? (
                        <LabCard
                          userSettingsDocument={getUserSettings.value}
                          labCardFeedbackDocument={getLabCardFeedback.value}
                          userSettingsError={getUserSettings.error}
                          labCardFeedbackError={getUserSettings.error}
                          toggleFavoriteFlag={toggleFavoriteFlag}
                          toggleFeedbackLikeFlag={toggleFeedbackLikeFlag}
                          key={lab.id}
                          lab={lab}
                          section={tag.index}
                        />
                      ) : (
                        <Card key={lab.id}>
                          <div className={'lab-card'} id={lab.id}>
                            <Loading />
                          </div>
                        </Card>
                      ),
                    )}
                  </SimpleGrid>
                )}
                title={`${cleanStringCase(tag.index)} (${accordionSections[
                  tag.index
                ].length.toLocaleString()})`}
              />
            ) : null;
          })}
        </Accordion>
      ) : (
        <div style={{ minHeight: '450px' }}>
          <Loading
            dotColor={'color'}
            flexDirection={'column'}
            maxW={'94vw'}
            text={'Experiments are afoot...'}
          />
        </div>
      )}
    </Container>
  );
};
