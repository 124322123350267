import { Grid, GridItem } from '@chakra-ui/react';
import SingleAccountPredictionsOverview from 'app/src/components/GaugeChart/components/SingleAccountPredictionsOverview';
import SignalTimeline from 'app/src/components/SignalTimeline';
import React from 'react';
import About from '../About';
import { SignalsOverview } from '../sav/overview/signalsOverview/SignalsOverview';

export default function TurnkeyCompanyDetailsSummmaryTab() {
  return (
    <Grid templateColumns='repeat(8, 1fr)' h='100%' gap='4'>
      <GridItem colSpan={6}>
        <div>
          <SingleAccountPredictionsOverview />
          <SignalsOverview />
        </div>
      </GridItem>
      <GridItem colSpan={2}>
        <About />
        <SignalTimeline />
      </GridItem>
    </Grid>
  );
}
