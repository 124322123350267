const HUBSPOT_CLIENT_ID = process.env.HUBSPOT_CLIENT_ID;
const PARDOT_CLIENT_ID = process.env.PARDOT_CLIENT_ID;
const SALESFORCE_CLIENT_ID = process.env.SALESFORCE_CLIENT_ID;
const INTEGRATION_REDIRECT_URI = process.env.INTEGRATION_REDIRECT_URI;
export const HUBSPOT_INSTALL_URL = `https://app.hubspot.com/oauth/authorize?client_id=${HUBSPOT_CLIENT_ID}&redirect_uri=${INTEGRATION_REDIRECT_URI}&scope=content%20reports%20automation%20business-intelligence%20oauth%20forms%20sales-email-read%20crm.lists.read%20crm.objects.contacts.read%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.companies.read%20crm.schemas.deals.read%20crm.objects.owners.read`;
export const PARDOT_INSTALL_URL = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${PARDOT_CLIENT_ID}&redirect_uri=${INTEGRATION_REDIRECT_URI}`;
export const SALESFORCE_INSTALL_URL = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${SALESFORCE_CLIENT_ID}&redirect_uri=${INTEGRATION_REDIRECT_URI}`;
export const HUBSPOT_LEARN_MORE_LINK =
  'https://see.klearly.com/knowledge/instructions-api-access-hubspot';
export const MARKETO_LEARN_MORE_LINK =
  'https://see.klearly.com/knowledge/instructions-api-access-marketo';
export const SALESFORCE_LEARN_MORE_LINK =
  'https://see.klearly.com/knowledge/instructions-api-access-salesforce';
export const PENDO_LEARN_MORE_LINK =
  'https://see.klearly.com/knowledge/instructions-for-klearly-access-to-pendo';
export const PARDOT_LEARN_MORE_LINK =
  'https://see.klearly.com/knowledge/instructions-for-klearly-access-to-pardot';
