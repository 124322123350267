import { createIcon } from '@chakra-ui/icon';

export const SelectedCompaniesIcon = createIcon({
  displayName: 'SelectedCompaniesIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7 10H5V1C5 0.447 5.447 0 6 0H18C18.553 0 19 0.447 19 1V7H17V2H7V10Z'
        fill='white'
      />
      <path
        d='M23 8H14C13.447 8 13 8.447 13 9V22H11V13C11 12.447 10.553 12 10 12H1C0.447 12 0 12.447 0 13V23C0 23.553 0.447 24 1 24H23C23.553 24 24 23.553 24 23V9C24 8.447 23.553 8 23 8ZM7 21H4V19H7V21ZM7 17H4V15H7V17ZM20 21H17V19H20V21ZM20 17H17V15H20V17ZM20 13H17V11H20V13Z'
        fill='white'
      />
    </svg>
  ),
});

export const CompaniesIcon = createIcon({
  displayName: 'CompaniesIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 6V1H6V9'
        stroke='#D3DAE2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 23H14'
        stroke='#D3DAE2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 12H1V23H10V12Z'
        stroke='#D3DAE2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23 9H14V23H23V9Z'
        stroke='#D3DAE2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 13H19'
        stroke='#D3DAE2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 16H19'
        stroke='#D3DAE2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 19H19'
        stroke='#D3DAE2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 19H6'
        stroke='#D3DAE2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 16H6'
        stroke='#D3DAE2'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  ),
});
