import { DrawerButtonType } from 'shared/firebase/types/tableTypes';
import { BannerPopover } from 'app/src/components/TableSection/components/BannerPopover';
import { Select, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { Button } from 'shared/components/Core';
import { AddIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  GetAccountGroupDocument,
  UpdateAccountGroupAccountsDocument,
  useGetAllAccountGroupsQuery,
} from 'shared/graphql/generatedApiTypes';
import { groupAccountGroups } from 'app/src/components/GlobalFilters/helpers/groupAccountGroups';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { useContext, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { FieldValue } from 'shared/firebase/initializeFirebase';
import _difference from 'lodash/difference';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';

export const AccountDrawerButtonRenderer = ({
  accountId,
  buttonType,
  onDrawerClose,
}: {
  accountId: string;
  buttonType: DrawerButtonType;
  onDrawerClose: () => void;
}) => {
  switch (buttonType) {
    case DrawerButtonType.AccountAddToList:
      return (
        <AddToListPopover
          accountId={parseInt(accountId)}
          onDrawerClose={onDrawerClose}
        />
      );
    case DrawerButtonType.HideAccount:
      return (
        <HideOrUnhideButton
          accountId={parseInt(accountId)}
          onDrawerClose={onDrawerClose}
        />
      );
    case DrawerButtonType.NavigateToSav:
      return <NavigateToSavButton accountId={parseInt(accountId)} />;
  }
};

type DrawerButtonProps = {
  accountId: number;
  onDrawerClose: () => void;
};

const AddToListPopover = ({ accountId, onDrawerClose }: DrawerButtonProps) => {
  const apolloClient = useApolloClient();
  const toast = useToast();
  const addToListInteractionModal = useDisclosure();
  const { data: allAccountGroupsData } = useGetAllAccountGroupsQuery();
  const { lists: userListsToAddTo } = groupAccountGroups({
    accountGroups: allAccountGroupsData?.accountGroups,
    klearlyUser: AuthManager.klearlyUser,
    showOnlyLoggedInUsersGroups: true,
  });
  const [selectedList, setSelectedList] = useState<string>('');
  const yourListOptions = useMemo(
    () =>
      userListsToAddTo?.map((list) => ({
        label: list?.title ?? '',
        value: list?.id.toString() ?? '',
      })) ?? [],
    [userListsToAddTo],
  );

  const addToExistingList = async () => {
    const groupId = parseInt(selectedList);
    const accountGroup = await apolloClient.query({
      query: GetAccountGroupDocument,
      variables: { id: groupId },
    });
    const idsAlreadyOnList = accountGroup.data.accountGroup.accounts.map(
      (acct) => acct.id,
    );
    const mergedIds = Array.from(new Set([...idsAlreadyOnList, accountId]));
    await apolloClient.mutate({
      mutation: UpdateAccountGroupAccountsDocument,
      variables: {
        input: {
          accountIds: mergedIds,
          id: groupId,
        },
      },
    });
    setSelectedList('');
    addToListInteractionModal.onClose();
    onDrawerClose();
    toast({
      title: `Successfully added selected account to list "${accountGroup.data.accountGroup.title}"`,
      status: 'success',
      position: 'top',
      isClosable: true,
    });
  };
  return (
    <BannerPopover
      interactionModal={addToListInteractionModal}
      popoverTitle={'Add to existing list'}
      body={
        <>
          {!userListsToAddTo || !userListsToAddTo.length ? (
            <Text pb={4}>You have no lists. Create a new one instead.</Text>
          ) : (
            <>
              <Select
                placeholder={'Select from your lists'}
                value={selectedList}
                onChange={(e) => setSelectedList(e.target.value)}
                pb={4}
              >
                {yourListOptions.map((opt) => (
                  <option value={opt.value} key={opt.value}>
                    {opt.label}
                  </option>
                ))}
              </Select>
              <Button
                onClick={addToExistingList}
                text={'Add to List'}
                isDisabled={!selectedList}
              />
            </>
          )}
        </>
      }
    >
      <Button
        text={'Add to list'}
        onClick={addToListInteractionModal.onOpen}
        leftIcon={<AddIcon />}
      />
    </BannerPopover>
  );
};

const HideOrUnhideButton = ({
  accountId,
  onDrawerClose,
}: DrawerButtonProps) => {
  const toast = useToast();
  const { globalFilter, updateExcludedIds } = useContext(AggregateDataContext);
  const currentlyExcludedIds = globalFilter?.excludedAccountIds ?? [];
  const isCurrentlyHidden = currentlyExcludedIds.includes(accountId);
  const firebaseUserUID = AuthManager.currentUser?.uid ?? '';
  const companyName = AuthManager.klearlyUser?.companyName ?? '';
  const userSettingsDocId = `${firebaseUserUID}#${companyName}`;

  const hideSelectedAccount = async () => {
    try {
      if (userSettingsDocId) {
        const userSettingsDoc = await DatabaseManager.UserSettingsModel.get(
          userSettingsDocId,
        );
        if (userSettingsDoc) {
          // merge in the selected ID
          await userSettingsDoc.ref.update({
            excludedAccountIds: FieldValue.arrayUnion(accountId),
          });
        } else {
          // no doc, so create the doc with the new ids
          await DatabaseManager.UserSettingsModel.create(
            {
              excludedAccountIds: [accountId],
              labCardFavorites: [],
              filterFavorites: [],
              listFavorites: [],
              quickAccessFilters: null,
            },
            userSettingsDocId,
          );
        }
        // update the current global filter to reflect
        if (globalFilter) {
          updateExcludedIds(
            Array.from(
              new Set([...(globalFilter.excludedAccountIds ?? []), accountId]),
            ),
          );
        }
        onDrawerClose();
      }
    } catch (err) {
      console.log(err);
      onDrawerClose();
      toast({
        title: `Error hiding companies; please check your connection & try again`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  };

  const unhideSelectedAccount = async () => {
    try {
      if (userSettingsDocId) {
        const userSettingsDoc = await DatabaseManager.UserSettingsModel.get(
          userSettingsDocId,
        );
        if (userSettingsDoc) {
          // merge in the selected ID
          await userSettingsDoc.ref.update({
            excludedAccountIds: FieldValue.arrayRemove(accountId),
          });
        }
        // update the current global filter to reflect
        if (
          userSettingsDoc?.data &&
          globalFilter &&
          userSettingsDoc.data.excludedAccountIds
        ) {
          updateExcludedIds(
            _difference(userSettingsDoc.data.excludedAccountIds, [accountId]),
          );
        }
        onDrawerClose();
      }
    } catch (err) {
      console.log(err);
      onDrawerClose();
      toast({
        title: `Error unhiding companies; please check your connection & try again`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  };

  return !isCurrentlyHidden ? (
    <Button
      text={'Hide'}
      onClick={hideSelectedAccount}
      leftIcon={<ViewOffIcon />}
    />
  ) : (
    <Button
      text={'Unhide'}
      onClick={unhideSelectedAccount}
      leftIcon={<ViewIcon />}
    />
  );
};

const NavigateToSavButton = ({ accountId }: { accountId: number }) => {
  return (
    <Button
      asLink
      href={`/companies/${accountId}`}
      text={'View Full Details'}
    />
  );
};
