import FirebaseApp from '../initializeFirebase';
import { UserSettingsModel } from '../models/UserSettingsModel';
import { UiConfigModel } from '../models/UiConfigModel';
import { SavedComponentModel } from '../models/SavedComponentModel';
import { SavedPageModel } from '../models/SavedPageModel';
import { SavedStructureModel } from '../models/SavedStructureModel';
import { LabCardFeedbackModel } from '../models/LabCardFeedbackModel';
import { Firestore } from '../types/firebaseTypes';

export class FirestoreManagerClass {
  readonly firestore: Firestore;
  public LabCardFeedbackModel: LabCardFeedbackModel;
  public SavedComponentModel: SavedComponentModel;
  public SavedPageModel: SavedPageModel;
  public SavedStructureModel: SavedStructureModel;
  public UiConfigModel: UiConfigModel;
  public UserSettingsModel: UserSettingsModel;

  public constructor(firestore: Firestore) {
    this.firestore = firestore;
    this.LabCardFeedbackModel = new LabCardFeedbackModel(this.firestore);
    this.SavedComponentModel = new SavedComponentModel(this.firestore);
    this.SavedPageModel = new SavedPageModel(this.firestore);
    this.SavedStructureModel = new SavedStructureModel(this.firestore);
    this.UiConfigModel = new UiConfigModel(this.firestore);
    this.UserSettingsModel = new UserSettingsModel(this.firestore);
  }

  private static instance: FirestoreManagerClass;

  static getInstance(firestore: Firestore): FirestoreManagerClass {
    if (!firestore) {
      //@ts-ignore
      return;
    }
    if (!this.instance) {
      this.instance = new FirestoreManagerClass(firestore);
    }
    return this.instance;
  }
}

export const initializedFirestore = FirebaseApp.firestore();
export default FirestoreManagerClass.getInstance(initializedFirestore);
