import { Text } from '@chakra-ui/react';

const CenterText = ({ children, ...props }) => (
  <Text color={'brand.black'} fontWeight={'bold'} fontSize={'2xl'} {...props}>
    {children}
  </Text>
);
const BottomText = ({ children, ...props }) => (
  <Text color={'brand.gray-700'} {...props} fontSize={'sm'}>
    {children}
  </Text>
);

export const FormattedText = ({ rowType, children, ...props }) => {
  if (rowType === 'center') {
    return <CenterText children={children} {...props} />;
  }
  return <BottomText children={children} {...props} />;
};
