import {
  RecommendationsTableDataTypeWithFilter,
  RecommendationTableIndices,
  RecommendedActionFilterOptions,
} from '../types/homeTypes';
import { ApiGetRecommendedActionsQuery } from 'shared/graphql/generatedApiTypes';

export const mapRecommendationsTableData = (
  data: ApiGetRecommendedActionsQuery,
): RecommendationsTableDataTypeWithFilter[] => {
  if (!data.recommendedActions) {
    return [];
  }

  return data.recommendedActions.reduce(
    (accum: RecommendationsTableDataTypeWithFilter[], item) => {
      if (item) {
        accum.push({
          [RecommendationTableIndices.SIGNAL_TYPE_HEADER]: item.signalType,
          [RecommendationTableIndices.ACCOUNTS_HEADER]: item.accountCount,
          [RecommendationTableIndices.ACTUAL_HEADER]: item.actualCount,
          [RecommendationTableIndices.CHANGE_HEADER]:
            item.goalCount - item.actualCount,
          [RecommendationTableIndices.GOAL_HEADER]: item.goalCount,
          [RecommendationTableIndices.AVERAGE_WINFLUENCE_HEADER]:
            item.averageRank,
          filterType: item.action as RecommendedActionFilterOptions,
        });
      }
      return accum;
    },
    [],
  );
};

type FilterTableDataParams = {
  data: RecommendationsTableDataTypeWithFilter[];
  filterType: RecommendedActionFilterOptions;
};

export const filterRecommendationsTableData = ({
  data,
  filterType,
}: FilterTableDataParams): RecommendationsTableDataTypeWithFilter[] => {
  return data.filter((item) => item.filterType === filterType);
};
