import { useContext } from 'react';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { Flex, Text, useDisclosure, useToast } from '@chakra-ui/react';
import Button from 'shared/components/Core/Button';
import { navbarSizeInt } from 'app/src/constants/navbarSize';
import { useWindowSize } from 'app/src/hooks/useWindowSize';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { useAddAccountGroupMutation } from 'shared/graphql/generatedApiTypes';
import { getAdaptedFilter } from 'app/src/components/GlobalFilters/helpers/adaptFilterForQuery';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import { BannerPopover } from 'app/src/components/TableSection/components/BannerPopover';
import { Input } from 'shared/components/Core';
import { FilterChipSet } from 'app/src/components/GlobalFilters/components/filterBar/FilterChipSet';

export const FilterStatsSummaryBar = () => {
  const { accountGroupStats, resetFilter } = useContext(AggregateDataContext);
  const { width: windowWidth } = useWindowSize();
  const newFilterInteractionModal = useDisclosure();
  const toast = useToast();
  const [addAccountGroup, { loading: addAccountGroupLoading }] =
    useAddAccountGroupMutation();

  const {
    bind: bindAccountGroupTitle,
    setValue: setAccountGroupTitle,
    value: accountGroupTitle,
  } = useInput('', {
    label: 'Name',
    placeholder: 'Name this filter',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindAccountGroupDescription,
    setValue: setAccountGroupDescription,
    value: accountGroupDescription,
  } = useInput('', {
    label: 'Description',
    placeholder: 'Describe this filter',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;

  const saveCurrentFilter = async () => {
    try {
      // adapt it into the correct string
      const adapted = getAdaptedFilter([
        FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS,
        FilterIndices.EXCLUDED_ACCOUNT_IDS,
        FilterIndices.ACCOUNTS_FULL_FILTER,
      ]);
      await addAccountGroup({
        variables: {
          input: {
            accountIds: undefined,
            description: accountGroupDescription,
            title: accountGroupTitle,
            filterString: adapted,
          },
        },
      });
      newFilterInteractionModal.onClose();
      setAccountGroupDescription('');
      setAccountGroupTitle('');

      toast({
        title: `Filter successfully created`,
        status: 'success',
        position: 'top',
        isClosable: true,
      });
    } catch {
      toast({
        title: `Error saving filter; please check your connection & try again`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  };

  return accountGroupStats.length ? (
    <Flex
      ml={2}
      pb={2}
      justifyContent={'space-between'}
      maxW={`${windowWidth - navbarSizeInt}px`}
      align={'center'}
      bg={'brand.white'}
    >
      <Flex flexWrap={'wrap'} mr={2} gap={2} mt={2}>
        <Button
          text={'Reset filters'}
          variant={'mutedOutline'}
          color={'brand.lightBlue'}
          borderWidth={0}
          fontWeight={'normal'}
          onClick={resetFilter}
        />
        {accountGroupStats.map((stat, i) => (
          <FilterChipSet stat={stat} key={i} />
        ))}
      </Flex>
      <BannerPopover
        interactionModal={newFilterInteractionModal}
        popoverTitle={'Create new filter'}
        body={
          addAccountGroupLoading ? (
            <Text>Creating filter...</Text>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                saveCurrentFilter();
              }}
            >
              <Input {...bindAccountGroupTitle} />
              <Input {...bindAccountGroupDescription} />
              <Button type={'submit'} text={'Save filter'} />
            </form>
          )
        }
      >
        <Button
          text={'Save filter'}
          mr={4}
          my={2}
          borderWidth={2}
          borderRadius={24}
          borderColor={'#ECF2FA'}
          fontWeight={'normal'}
          maxWidth={140}
          py={4}
          variant={'actionOutline'}
          rightIcon={<ChevronDownIcon />}
          onClick={newFilterInteractionModal.onOpen}
        />
      </BannerPopover>
    </Flex>
  ) : null;
};
