import { createIcon } from '@chakra-ui/icon';

export const PardotLogo = createIcon({
  displayName: 'PardotLogo',
  viewBox: '0 0 1500 545',
  path: (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      width='1500px'
      height='545px'
      viewBox='0 0 1500 545'
      enableBackground='new 0 0 1500 545'
    >
      <g>
        <path
          fill='#139CD8'
          d='M339.119,64.282c50.969-53.354,135.537-55.288,188.892-4.321c9.97,9.525,18.403,20.535,25.006,32.641
		c20.876-9.332,43.499-14.113,66.366-14.022c91.469,0,165.618,74.15,165.618,165.619c0,91.468-74.148,165.618-165.618,165.618
		c-10.849-0.021-21.669-1.14-32.291-3.335c-21.255,37.896-61.286,61.396-104.735,61.479c-18.16,0.079-36.087-4.094-52.342-12.196
		c-29.69,69.612-110.189,101.974-179.799,72.287c-34.603-14.762-61.614-43.095-74.704-78.358c-8.603,1.841-17.374,2.762-26.172,2.74
		c-71.03-0.919-127.868-59.239-126.95-130.27c0.579-45.048,24.688-86.512,63.553-109.298c-8.132-18.588-12.273-38.677-12.15-58.966
		C73.653,72.316,139.675,6.07,221.255,5.932c46.179-0.078,89.734,21.443,117.729,58.168'
        />
        <path
          fill='#FFFFFF'
          d='M132.851,282.563c-0.324,0.611-0.192,1.362,0.321,1.826c1.506,0.913,2.74,1.827,4.247,2.74
		c7.029,3.688,14.904,5.469,22.837,5.164c15.53,0,25.258-8.222,25.258-21.607v-0.319c0-12.469-10.962-16.762-21.328-20.097
		l-1.143-0.457c-7.629-2.419-14.295-4.567-14.295-9.728v-0.319c0-4.248,3.972-7.628,10.045-7.628
		c6.915,0.09,13.703,1.863,19.78,5.161c0.632,0.449,1.506,0.306,1.962-0.319v-0.319c0.321-0.914,3.062-7.903,3.062-8.542
		c0.185-0.673-0.046-1.394-0.596-1.827c-6.812-4.039-14.596-6.139-22.517-6.076h-1.507c-14.298,0-24.347,8.541-24.347,21.011v0.319
		c0,13.109,10.962,17.357,21.332,20.099l1.506,0.592c7.629,2.421,14.024,4.248,14.024,9.455v0.321c0,4.888-4.25,8.543-10.962,8.543
		c-7.123,0.065-14.107-1.954-20.098-5.803c-1.234-0.595-1.827-1.234-2.419-1.508c-0.632-0.45-1.509-0.306-1.966,0.319v0.319
		L132.851,282.563z'
        />
        <path
          fill='#FFFFFF'
          d='M361.183,282.563c-0.327,0.611-0.195,1.362,0.318,1.826c1.511,0.913,2.74,1.827,4.25,2.74
		c7.03,3.688,14.904,5.469,22.834,5.164c15.53,0,25.261-8.222,25.261-21.607v-0.319c0-12.469-10.962-16.762-21.332-20.097
		l-1.097-0.457c-7.628-2.419-14.295-4.567-14.295-9.728v-0.319c0-4.248,3.971-7.628,10.049-7.628
		c6.911,0.09,13.699,1.863,19.776,5.161c0.633,0.449,1.506,0.306,1.962-0.319v-0.319c0.321-0.914,3.063-7.903,3.063-8.542
		c0.185-0.673-0.046-1.394-0.595-1.827c-6.813-4.037-14.598-6.137-22.518-6.076h-1.506c-14.299,0-24.343,8.541-24.343,21.011v0.319
		c0,13.109,10.958,17.357,21.328,20.099l1.506,0.592c7.629,2.421,14.023,4.248,14.023,9.455v0.321c0,4.888-4.246,8.543-10.962,8.543
		c-7.119,0.065-14.106-1.954-20.097-5.803c-1.231-0.595-1.827-1.234-2.419-1.508c-0.633-0.45-1.511-0.306-1.967,0.319v0.319
		L361.183,282.563z'
        />
        <path
          fill='#FFFFFF'
          d='M530.59,242.37c-1.185-4.1-3.155-7.931-5.8-11.281c-2.8-3.197-6.219-5.792-10.048-7.628
		c-8.977-3.643-19.023-3.643-27.999,0c-3.833,1.835-7.252,4.431-10.048,7.628c-2.761,3.274-4.743,7.131-5.803,11.281
		c-2.422,9.175-2.422,18.824,0,27.998c1.185,4.102,3.155,7.931,5.803,11.282c2.796,3.197,6.216,5.792,10.048,7.628
		c8.976,3.645,19.021,3.645,27.999,0c3.895-1.724,7.338-4.338,10.048-7.628c2.761-3.271,4.743-7.129,5.8-11.282
		c1.243-4.568,1.859-9.287,1.827-14.021c0.055-4.737-0.56-9.458-1.827-14.023 M517.482,256.346
		c0.312,6.177-1.164,12.314-4.246,17.677c-2.808,4.243-7.732,6.58-12.791,6.077c-5.026,0.317-9.862-1.98-12.789-6.077
		c-5.683-11.083-5.683-24.225,0-35.308c2.803-4.241,7.728-6.58,12.789-6.076c5.025-0.321,9.861,1.976,12.791,6.076
		c3.121,5.348,4.597,11.495,4.246,17.677'
        />
        <path
          fill='#FFFFFF'
          d='M637.469,278.315c-0.155-0.652-0.804-1.055-1.459-0.913l0,0c-1.966,0.788-4.001,1.399-6.073,1.826
		c-2.315,0.361-4.65,0.561-6.991,0.593c-5.522,0.318-10.929-1.667-14.935-5.479c-4.104-5.036-6.07-11.48-5.48-17.95
		c-0.233-6.055,1.479-12.026,4.885-17.038c3.482-4.462,8.977-6.867,14.617-6.396c4.632-0.074,9.25,0.542,13.702,1.829
		c0.663,0.122,1.31-0.271,1.509-0.915c1.231-3.06,2.145-5.48,3.334-8.813c0.178-0.643-0.187-1.312-0.825-1.509l0,0
		c-2.86-0.997-5.812-1.718-8.813-2.146c-3.228-0.381-6.479-0.579-9.728-0.594c-5.008-0.089-9.983,0.845-14.617,2.74
		c-4.068,1.724-7.706,4.328-10.641,7.628c-2.837,3.316-5.009,7.146-6.396,11.281c-1.502,4.521-2.227,9.262-2.147,14.023
		c-0.421,9.386,2.618,18.603,8.543,25.9c6.795,6.871,16.257,10.427,25.897,9.725c6.35,0.076,12.66-1.057,18.591-3.334
		c0.492-0.326,0.728-0.93,0.592-1.506L637.469,278.315z'
        />
        <path
          fill='#FFFFFF'
          d='M704.157,239.949c-1.152-3.838-3.131-7.377-5.803-10.368c-2.313-2.764-5.237-4.953-8.54-6.395
		c-4.061-1.646-8.407-2.47-12.789-2.421c-5.011-0.119-9.99,0.815-14.617,2.74c-3.879,1.757-7.314,4.364-10.049,7.628
		c-2.731,3.437-4.792,7.356-6.074,11.556c-1.248,4.568-1.862,9.286-1.827,14.023c-0.103,4.762,0.623,9.507,2.145,14.023
		c1.146,4.242,3.343,8.121,6.396,11.282c3.15,3.115,6.874,5.597,10.962,7.309c5.134,1.977,10.638,2.803,16.126,2.419
		c7.653,0.457,15.294-1.002,22.242-4.247c0.592-0.321,0.915-0.593,0.318-2.147l-3.058-8.222c-0.133-0.536-0.679-0.867-1.218-0.734
		c-0.003,0-0.01,0.003-0.017,0.006h-0.318c-5.759,2.479-12.008,3.617-18.271,3.333c-5.534,0.229-10.959-1.612-15.209-5.16
		c-3.555-4.018-5.404-9.259-5.162-14.619h44.166c0.657,0.027,1.208-0.485,1.235-1.14c0,0,0,0,0-0.001l0,0
		c0.914-0.319,2.419-9.134-0.592-18.863 M660.354,249.084c0.411-3.691,1.661-7.237,3.653-10.368
		c2.672-4.256,7.455-6.71,12.468-6.395c4.992-0.2,9.722,2.227,12.472,6.395c1.87,3.145,2.922,6.709,3.058,10.368H660.354z'
        />
        <path
          fill='#FFFFFF'
          d='M350.677,239.949c-1.151-3.838-3.134-7.377-5.802-10.368c-2.314-2.764-5.236-4.953-8.54-6.395
		c-4.061-1.646-8.411-2.47-12.79-2.421c-5.011-0.118-9.989,0.816-14.616,2.74c-3.879,1.757-7.314,4.364-10.049,7.628
		c-2.73,3.437-4.793,7.358-6.074,11.556c-1.248,4.57-1.86,9.286-1.827,14.023c-0.103,4.762,0.622,9.507,2.145,14.023
		c1.142,4.242,3.339,8.124,6.395,11.282c3.151,3.115,6.875,5.597,10.962,7.309c5.134,1.977,10.638,2.803,16.123,2.419
		c7.652,0.457,15.298-1.002,22.246-4.247c0.593-0.321,0.914-0.593,0.318-2.147l-3.426-8.13c-0.132-0.538-0.675-0.866-1.215-0.733
		c-0.007,0-0.014,0-0.017,0.003h-0.321c-5.759,2.479-12.005,3.62-18.271,3.332c-5.535,0.233-10.956-1.607-15.209-5.159
		c-3.554-4.017-5.405-9.258-5.16-14.616h44.167c0.655,0.025,1.208-0.485,1.231-1.14c0-0.001,0-0.001,0-0.001l0,0
		c0.913-0.321,2.422-9.135-0.593-18.864 M306.507,249.175c0.414-3.689,1.662-7.235,3.654-10.368
		c2.671-4.256,7.457-6.711,12.472-6.395c4.988-0.2,9.718,2.227,12.469,6.395c1.873,3.145,2.926,6.711,3.062,10.368H306.507z'
        />
        <path
          fill='#FFFFFF'
          d='M228.907,246.938c-1.828,0-3.976-0.321-6.988-0.321c-3.703,0.052-7.384,0.56-10.962,1.507
		c-3.276,0.855-6.372,2.294-9.135,4.248c-2.741,1.83-4.948,4.35-6.395,7.308c-1.585,3.113-2.417,6.557-2.422,10.049
		c-0.152,3.478,0.589,6.936,2.147,10.05c1.314,2.779,3.311,5.184,5.803,6.986c2.747,1.921,5.855,3.271,9.132,3.973
		c3.783,0.909,7.669,1.324,11.558,1.234c4.492,0.056,8.976-0.357,13.382-1.234c4.247-0.592,9.728-1.828,11.284-2.146
		c1.042-0.221,2.065-0.525,3.058-0.913c0.652-0.158,1.056-0.808,0.914-1.463V245.75c0.615-7.207-1.937-14.328-6.987-19.503
		c-5.866-4.389-13.107-6.544-20.419-6.076c-3.992-0.042-7.978,0.372-11.875,1.234c-4.81,0.845-9.443,2.495-13.7,4.888
		c-0.566,0.433-0.705,1.225-0.321,1.827l3.062,8.542c0.132,0.537,0.674,0.867,1.214,0.734c0.007-0.001,0.011-0.003,0.017-0.004
		h0.321c0.229-0.03,0.44-0.145,0.593-0.319c5.951-2.858,12.445-4.416,19.048-4.568c3.85-0.267,7.689,0.692,10.959,2.742
		c2.423,1.827,3.654,4.567,3.654,10.643v1.827l-6.987-0.594 M211.504,278.18c-1-0.724-1.824-1.661-2.42-2.74
		c-2.73-4.73-1.714-10.731,2.42-14.298c3.75-2.543,8.298-3.633,12.789-3.06c3.976,0.012,7.944,0.318,11.876,0.914v20.097
		c-3.916,0.802-7.884,1.305-11.876,1.508C219.881,281.265,215.369,280.411,211.504,278.18'
        />
        <path
          fill='#FFFFFF'
          d='M585.127,224.099c0.264-0.542,0.039-1.194-0.503-1.461l0,0c-2.367-0.745-4.827-1.16-7.309-1.232
		c-3.854-0.391-7.746,0.239-11.28,1.827c-2.84,1.216-5.346,3.095-7.308,5.482v-5.482c-0.043-0.661-0.573-1.189-1.236-1.234h-11.875
		c-0.661,0.045-1.188,0.573-1.231,1.234v66.045c0.043,0.663,0.57,1.189,1.231,1.236h11.559c0.661-0.047,1.188-0.573,1.231-1.236
		v-32.885c-0.07-3.906,0.44-7.799,1.509-11.556c0.701-2.461,2.079-4.676,3.971-6.395c1.513-1.561,3.4-2.708,5.482-3.334
		c1.886-0.557,3.835-0.863,5.803-0.912c1.645,0.02,3.283,0.219,4.885,0.593c0.742,0.081,1.413-0.448,1.509-1.188l0,0
		c0.913-2.146,3.06-8.221,3.655-9.455'
        />
        <path
          fill='#FFFFFF'
          d='M476.1,193.68l-4.248-0.914c-2.003-0.379-4.034-0.578-6.073-0.594c-6.868-0.491-13.625,1.948-18.591,6.715
		c-4.827,5.701-7.892,12.684-8.814,20.097l-0.592,3.06h-10.049c-0.725,0.024-1.345,0.528-1.51,1.233l-1.506,9.135
		c-0.112,0.704,0.351,1.368,1.049,1.507h10.231l-10.049,55.083c-0.546,3.737-1.465,7.408-2.74,10.964
		c-0.649,2.289-1.682,4.455-3.058,6.394c-0.951,1.43-2.348,2.509-3.976,3.06c-1.668,0.516-3.419,0.715-5.159,0.596
		c-0.914,0-2.421-0.318-3.655-0.318c-0.851-0.172-1.667-0.483-2.419-0.913c-0.579-0.186-1.212,0.06-1.509,0.592
		c-0.318,0.914-3.058,8.223-3.334,8.814c-0.333,0.669-0.068,1.482,0.593,1.827c1.298,0.5,2.625,0.91,3.976,1.234
		c2.096,0.473,4.246,0.672,6.395,0.593c3.733,0.069,7.451-0.55,10.962-1.827c3.164-1.252,5.98-3.237,8.222-5.8
		c2.518-2.852,4.488-6.156,5.798-9.73c1.695-4.871,3.025-9.864,3.976-14.934l10.049-56.319h14.431
		c0.725-0.022,1.344-0.527,1.51-1.233l1.506-9.135c0.112-0.703-0.351-1.369-1.049-1.507h-14.481
		c0.417-3.433,1.229-6.803,2.422-10.049c0.609-1.761,1.663-3.333,3.06-4.568c1.069-1.01,2.306-1.83,3.654-2.419
		c1.605-0.367,3.243-0.567,4.889-0.594c1.231,0,2.74,0.319,3.653,0.319c0.745,0.064,1.473,0.266,2.145,0.594
		c1.509,0.319,1.509,0,1.827-0.594l3.337-9.135c0.318-0.594-0.596-0.913-0.914-1.232'
        />
        <path
          fill='#FFFFFF'
          d='M278.965,289.277c-0.042,0.663-0.569,1.189-1.231,1.236h-11.557c-0.663-0.047-1.189-0.573-1.231-1.236
		v-94.273c0.042-0.661,0.569-1.188,1.231-1.233h11.283c0.662,0.045,1.188,0.572,1.231,1.233v94.363L278.965,289.277z'
        />
        <path
          fill='#7D888E'
          d='M866.897,200.577c-1.218,1.208-2.499,2.349-3.836,3.425v-18.133h-16.169v141.139h16.215v-51.753
		c23.338,26.859,50.24,24.162,67.644,13.291c21.329-13.199,30.466-41.654,22.839-67.918
		C941.762,181.942,893.297,168.65,866.897,200.577z M896.542,280.646c-21.33,0-34.394-18.271-34.576-41.108
		c-0.185-22.837,17.539-41.704,38.778-41.886c21.236-0.181,38.044,17.768,38.044,41.793c-0.198,22.905-18.929,41.31-41.833,41.112
		h-0.003h-0.457L896.542,280.646z'
        />
        <path
          fill='#7D888E'
          d='M1443.592,152.984v31.973h34.029v14.616h-33.162c-0.229,3.516-0.593,6.074-0.593,9.135
		c0,16.809,0.639,33.662,0,50.242c-1.006,19.779,9.136,23.981,27.403,20.326c1.549-0.423,3.074-0.927,4.568-1.506
		c3.792,12.377,1.416,16.123-10.918,17.675c-25.713,3.058-38.047-7.812-38.09-33.798v-61.389l-14.615-0.685v-13.704l14.522-0.912
		v-31.973H1443.592z'
        />
        <path
          fill='#7D888E'
          d='M1108.199,293.479h-16.352v-107.61h16.033v24.848c11.28-17.036,23.794-27.907,43.802-27.085v16.763
		c-32.43,6.076-43.527,19.823-43.527,53.44C1108.199,266.668,1108.199,279.732,1108.199,293.479z'
        />
        <path
          fill='#7D888E'
          d='M1252.029,141.063v62.575c-28.499-25.533-54.263-27.406-76.095-7.173
		c-21.831,20.236-23.752,56.64-5.295,80.345c7.384,9.563,18.035,16.066,29.915,18.271c20.689,4.158,36.905-3.928,51.385-21.879
		v19.96h15.666V141.016h-15.576V141.063z M1212.794,281.332c-21.329-0.503-37.862-19.367-37.405-42.8
		c0.457-23.431,16.397-41.106,38.047-40.832c21.651,0.273,39.05,18.863,38.732,42.614c-0.183,22.609-18.317,41.474-39.328,41.018
		H1212.794z'
        />
        <path
          fill='#7D888E'
          d='M1063.574,217.843c-0.959-16.489-11.098-27.406-26.902-31.653c-19.446-4.936-40.027-2.546-57.823,6.714
		l4.566,13.381c19.138-7.124,29.276-8.54,41.792-6.986c14.069,1.781,26.538,15.208,21.742,31.149l-4.932-1.049
		c-18.271-3.425-36.314-5.437-53.716,2.603c-16.422,6.961-24.087,25.915-17.126,42.336c2.449,5.776,6.526,10.713,11.736,14.208
		c9.092,6.064,20.002,8.792,30.878,7.72c13.114-1.003,25.366-6.924,34.301-16.58l0.777,13.704h15.21c0-2.42,0.592-4.568,0.546-6.306
		C1064.488,263.929,1064.944,240.68,1063.574,217.843z M1032.378,278.315c-13.381,6.441-27.038,7.398-39.599-1.916
		c-8.288-6.764-9.522-18.963-2.762-27.251c1.236-1.509,2.688-2.824,4.313-3.901c15.278-7.058,32.646-8.149,48.689-3.061
		c2.724,1.248,4.7,3.701,5.345,6.624c2.326,12.332-4.333,24.604-15.941,29.37L1032.378,278.315z'
        />
        <path
          fill='#7D888E'
          d='M1346.897,182.581c-31.526-0.525-57.513,24.609-58.035,56.135c-0.526,31.528,24.607,57.514,56.136,58.039
		c31.502,0.523,57.476-24.572,58.035-56.075v-0.366c0.585-31.224-24.252-57.011-55.478-57.595c-0.022,0-0.043-0.001-0.063-0.001
		L1346.897,182.581z M1346.897,281.649c-22.243,0-39.875-18.771-39.782-42.614c0.09-23.844,17.036-41.61,39.601-41.429
		c22.563,0.185,39.599,18.638,39.599,41.977c0,23.34-17.311,41.978-39.463,42.066H1346.897z'
        />
      </g>
    </svg>
  ),
});
