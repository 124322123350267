import React from "react";
export const acqleastSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#8454D2' />
  </svg>
);
export const acqlessSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#8454D2' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#8454D2' />
  </svg>
);
export const acqtypicalSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#8454D2' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#8454D2' />
    <rect opacity='0.6' x='10' width='6' height='16' rx='2' fill='#8454D2' />
  </svg>
);
export const acqmoreSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#8454D2' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#8454D2' />
    <rect opacity='0.6' x='10' width='6' height='16' rx='2' fill='#8454D2' />
    <rect opacity='0.8' x='18' width='8' height='16' rx='2' fill='#8454D2' />
  </svg>
);
export const acqmostSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#8454D2' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#8454D2' />
    <rect opacity='0.6' x='10' width='6' height='16' rx='2' fill='#8454D2' />
    <rect opacity='0.8' x='18' width='8' height='16' rx='2' fill='#8454D2' />
    <rect x='28' width='10' height='16' rx='2' fill='#8454D2' />
  </svg>
);

export const veryWeakSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#2A96FC' />
  </svg>
);

export const weakSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#2A96FC' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#2A96FC' />
  </svg>
);

export const moderateSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#2A96FC' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#2A96FC' />
    <rect opacity='0.6' x='10' width='6' height='16' rx='2' fill='#2A96FC' />
  </svg>
);

export const strongSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#2A96FC' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#2A96FC' />
    <rect opacity='0.6' x='10' width='6' height='16' rx='2' fill='#2A96FC' />
    <rect opacity='0.8' x='18' width='8' height='16' rx='2' fill='#2A96FC' />
  </svg>
);

export const veryStrong = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#2A96FC' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#2A96FC' />
    <rect opacity='0.6' x='10' width='6' height='16' rx='2' fill='#2A96FC' />
    <rect opacity='0.8' x='18' width='8' height='16' rx='2' fill='#2A96FC' />
    <rect x='28' width='10' height='16' rx='2' fill='#2A96FC' />
  </svg>
);

export const retentionleastSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#F97D2F' />
  </svg>
);
export const retentionlessSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#F97D2F' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#F97D2F' />
  </svg>
);
export const retentiontypicalSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#F97D2F' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#F97D2F' />
    <rect opacity='0.6' x='10' width='6' height='16' rx='2' fill='#F97D2F' />
  </svg>
);
export const retentionmoreSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#F97D2F' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#F97D2F' />
    <rect opacity='0.6' x='10' width='6' height='16' rx='2' fill='#F97D2F' />
    <rect opacity='0.8' x='18' width='8' height='16' rx='2' fill='#F97D2F' />
  </svg>
);
export const retentionmostSvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='38'
    height='16'
    viewBox='0 0 38 16'
    fill='none'
  >
    <rect opacity='0.2' width='2' height='16' rx='1' fill='#F97D2F' />
    <rect opacity='0.4' x='4' width='4' height='16' rx='2' fill='#F97D2F' />
    <rect opacity='0.6' x='10' width='6' height='16' rx='2' fill='#F97D2F' />
    <rect opacity='0.8' x='18' width='8' height='16' rx='2' fill='#F97D2F' />
    <rect x='28' width='10' height='16' rx='2' fill='#F97D2F' />
  </svg>
);

export const PredictionFitIconJSON = {
  0: veryWeakSvg,
  1: weakSvg,
  2: moderateSvg,
  3: strongSvg,
  4: veryStrong,
};

export const PredictionAcquisitionIconJSON = {
  0: acqleastSvg,
  1: acqlessSvg,
  2: acqtypicalSvg,
  3: acqmoreSvg,
  4: acqmostSvg,
};
export const PredictionRetentionIconJSON = {
  0: retentionleastSvg,
  1: retentionlessSvg,
  2: retentiontypicalSvg,
  3: retentionmoreSvg,
  4: retentionmostSvg,
};

export const AllPredictionRenderJSON = {
  fitPredictions: PredictionFitIconJSON,
  acquisitionPredictions: PredictionAcquisitionIconJSON,
  retentionPredictions: PredictionRetentionIconJSON,
};
