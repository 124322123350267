import { zIndexValues } from 'shared/constants/zIndexValues';
import { GlobalFilterReducerState } from '../reducers/globalFilterReducer';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { ApiGetAggregateDataWithFilterVarQuery } from 'shared/graphql/generatedApiTypes';

export const getCheckboxSelectFilterItemStyles = (
  itemSource?: 'bar' | 'panel' | 'wideMenu',
) => {
  return {
    container: (styles) => ({
      ...styles,
      display: 'inline-block',
      width: itemSource === 'bar' ? '200px' : '350px',
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      borderColor: 'brand.gray-300',
      borderWidth: 1,
      display: 'flex',
      cursor: 'pointer',
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: zIndexValues.popoverContainer,
      width: '400px',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    dropdownIndicator: (styles) => ({ ...styles, color: 'brand.gray-800' }),
    placeholder: (styles) => ({
      ...styles,
      color: 'brand.gray-800',
      fontWeight: '500',
    }),
    valueContainer: (styles) => ({
      ...styles,
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  };
};

type GetSelectedOptionsInitialStateParams = {
  filterItemAggregatedAccountsData:
    | ApiGetAggregateDataWithFilterVarQuery
    | undefined;
  filterIndices: FilterIndices[];
  globalFilter: GlobalFilterReducerState;
  tableAccessor: string;
};

export const getSelectedOptionsInitialState = ({
  filterItemAggregatedAccountsData,
  filterIndices,
  globalFilter,
  tableAccessor,
}: GetSelectedOptionsInitialStateParams) => {
  if (
    !filterItemAggregatedAccountsData ||
    !globalFilter ||
    !filterItemAggregatedAccountsData.aggregateData
  ) {
    return [];
  }
  return filterIndices
    .map((filterIndex) => {
      if (filterItemAggregatedAccountsData.aggregateData[filterIndex]) {
        return filterItemAggregatedAccountsData.aggregateData[filterIndex]
          .filter(
            (option) =>
              globalFilter[filterIndex] &&
              globalFilter[filterIndex].find(
                (item) => item === option[tableAccessor],
              ),
          )
          .map((option) => option[tableAccessor]);
      }
    })
    .flat();
};

export const getGroupedSelectedOptionsInitialState = ({
  filterItemAggregatedAccountsData,
  filterIndices,
  globalFilter,
  tableAccessor,
}: GetSelectedOptionsInitialStateParams) => {
  if (
    !filterItemAggregatedAccountsData ||
    !globalFilter ||
    !filterItemAggregatedAccountsData.aggregateData
  ) {
    return [];
  }
  return filterIndices
    .map((filterIndex) => {
      if (filterItemAggregatedAccountsData.aggregateData[filterIndex]) {
        return filterItemAggregatedAccountsData.aggregateData[filterIndex]
          .filter(
            (option) =>
              globalFilter[filterIndex] &&
              globalFilter[filterIndex].find(
                (item) => item === option[tableAccessor],
              ),
          )
          .map((option) => `${option[tableAccessor]}:${filterIndex}`);
      }
    })
    .flat();
};
