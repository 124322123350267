import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import _map from 'lodash/map';
import apolloClientHelper from 'shared/graphql/apolloClientHelper';
import Honeybadger from '@honeybadger-io/js';
import fetch from 'cross-fetch';
import {
  accountReadFromReferenceTypePolicy,
  aggregateAccountsDataTypePolicy,
  filterStringTypePolicy,
  filterStringWithoutEngagementStatusTypePolicy,
  mergeAcceptIncomingTypePolicy,
  mergeExistingAndIncomingTypePolicy,
  mergeObjectsTypePolicy,
} from './typePolicies';
import { ApiSignalIntensity } from 'shared/graphql/generatedApiTypes';

export const defaultQueryFetchPolicy: { fetchPolicy: WatchQueryFetchPolicy } = {
  fetchPolicy: 'cache-first',
};

const cache = new InMemoryCache({
  typePolicies: {
    Account: {
      ...mergeObjectsTypePolicy,
      fields: {
        signalActivityIntensity: {
          read(value) {
            // convert it to 'no activities' if it's 'none'
            return value === ApiSignalIntensity.None ? 'No Activities' : value;
          },
        },
        signalResponseIntensity: {
          read(value) {
            // convert it to 'no responses' if it's 'none'
            return value === ApiSignalIntensity.None ? 'No Responses' : value;
          },
        },
      },
    },
    AccountGroup: {
      fields: {
        accounts: mergeAcceptIncomingTypePolicy,
      },
    },
    // override ID as the key field since sometimes users can have the same ID if they are in different companies
    CompanyUser: {
      keyFields: ['email'],
    },
    // merge these because some queries don't have all fields
    Signal: mergeExistingAndIncomingTypePolicy,
    Query: {
      fields: {
        account: accountReadFromReferenceTypePolicy,
        accountGroups: mergeAcceptIncomingTypePolicy,
        accountGroupsForAccount: mergeAcceptIncomingTypePolicy,
        aggregateAccountsData: aggregateAccountsDataTypePolicy,
        aggregateData: aggregateAccountsDataTypePolicy,
        filterString: filterStringTypePolicy,
        filterStringWithoutEngagementStatus:
          filterStringWithoutEngagementStatusTypePolicy,
      },
    },
  },
});

export const apolloClient = (authUser) => {
  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_SERVER}/graphql`,
    fetch,
  });
  const authLink = setContext(async () => {
    // set token
    const idToken = await authUser?.getIdToken();
    // Use the setContext method to set the HTTP headers.
    return {
      headers: {
        authorization: idToken ? `Bearer ${idToken}` : '',
      },
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    // setup errors
    const errors: Array<string> = [];
    _map(graphQLErrors, (error) => errors.push(error.message));
    if (networkError) {
      errors.push(
        'A network error has occurred. Please check out your connection.',
      );
    }
    const errorObj = errors.reduce(
      (obj, cur) => ({ ...obj, message: cur }),
      {},
    );
    // notify honeybadger of error
    Honeybadger.notify(errorObj);
  });
  return new ApolloClient({
    ...apolloClientHelper,
    cache,
    link: authLink.concat(errorLink).concat(httpLink),
  });
};
