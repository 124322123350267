import { Children, ReactNode } from 'react';
import classnames from 'classnames';

import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import {
  allSizeOptionsLiteral,
  AnalyticsAttrType,
  ClassNameType,
  HeightType,
  horizontalFlexOptionsLiteral,
  verticalFlexOptionsLiteral,
  WidthType,
} from 'shared/types/coreTypes.d';

type InlineType = AnalyticsAttrType &
  ClassNameType & {
    alignContent?: horizontalFlexOptionsLiteral;
    alignItems?: verticalFlexOptionsLiteral;
    children?: ReactNode;
    display?: 'flex' | 'grid';
    gap?: allSizeOptionsLiteral;
    height?: HeightType;
    justifyContent?: horizontalFlexOptionsLiteral;
    justifyItems?: verticalFlexOptionsLiteral;
    ph?: allSizeOptionsLiteral;
    pv?: allSizeOptionsLiteral;
    rowGap?: allSizeOptionsLiteral;
    width?: WidthType;
  };

// Notes:

// - You only need `<Inline display="flex" />` when you need the item to wrap... this is rare
//   - Or when you want to justifyContent between, which is less rare
// - You only need `rowGap` if you specifically override an inline item to have two rows, or use flex
// - Especially when using the flex version, resist the urge to add `h-m-` and `h-p-` helpers to inline
//   - Use a wrapper div with those values instead. i.e. `<div class="h-mv-md"><Inline...`
//   - Setting helper values will muck up the overflow settings and child sizes

function Inline({
  alignContent,
  alignItems,
  analyticsAttr = undefined,
  children,
  className,
  display = 'grid',
  gap,
  height,
  justifyContent = 'start',
  justifyItems = 'start',
  ph,
  pv,
  rowGap,
  width,
}: InlineType) {
  // setup classes
  const inlineClasses = classnames({
    'c-inline': true,
    [`c-inline--${display}`]: display,
    [`h-height-${height}`]: height,
    [`h-width-${width}`]: width,
    // align-items & justify-content are shared between grid & flex
    [`h-flex-align-items-${alignItems}`]: alignItems,
    [`h-flex-justify-content-${justifyContent}`]: justifyContent,
    // row-gap and column-gap set vars for flex styling too
    [`h-grid-column-gap-${gap}`]: gap,
    [`h-grid-row-gap-${rowGap}`]: rowGap,
    [`h-grid-align-content-${alignContent}`]: alignContent,
    [`h-grid-justify-items-${justifyItems}`]: justifyItems,
    // padding help
    [`h-ph-${ph}`]: ph,
    [`h-pv-${pv}`]: pv,
    [`${className}`]: className,
  });
  // render
  if (display === 'flex') {
    return (
      <div className={inlineClasses}>
        {Children.map(children, (child, key) => (
          <div key={key}>{child}</div>
        ))}
      </div>
    );
  }
  return (
    <div
      analytics-attr={cleanAnalyticsStringList([analyticsAttr])}
      className={inlineClasses}
    >
      {children}
    </div>
  );
}

export default Inline;
