import { createIcon } from '@chakra-ui/icon';

export const SelectedBarChartIcon = createIcon({
  displayName: 'BarChart',
  viewBox: '0 0 48 48',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='48'
      width='48'
      viewBox='0 0 48 48'
    >
      <g
        strokeLinecap='square'
        strokeWidth='2'
        fill='none'
        stroke='#ffffff'
        strokeLinejoin='miter'
        className='nc-icon-wrapper'
        strokeMiterlimit='10'
      >
        <rect x='20' y='28' width='8' height='18' fill={'#ffffff'} />
        <rect x='2' y='38' width='8' height='8' fill={'#ffffff'} />
        <rect x='38' y='18' width='8' height='28' fill={'#ffffff'} />
        <polyline
          points='6,18 16,8 24,16 38,2 '
          strokeLinecap='butt'
          stroke='#ffffff'
        />
        <polyline points=' 29,2 38,2 38,11 ' stroke='#ffffff' />{' '}
      </g>
    </svg>
  ),
});

export const BarChartIcon = createIcon({
  displayName: 'BarChart',
  viewBox: '0 0 48 48',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='48'
      width='48'
      viewBox='0 0 48 48'
    >
      <g
        strokeLinecap='square'
        strokeWidth='2'
        fill='none'
        stroke='#D4DAE1'
        strokeLinejoin='miter'
        className='nc-icon-wrapper'
        strokeMiterlimit='10'
      >
        <rect x='20' y='28' width='8' height='18' />
        <rect x='2' y='38' width='8' height='8' />
        <rect x='38' y='18' width='8' height='28' />
        <polyline
          points='6,18 16,8 24,16 38,2 '
          strokeLinecap='butt'
          stroke='#D4DAE1'
        />
        <polyline points=' 29,2 38,2 38,11 ' stroke='#D4DAE1' />{' '}
      </g>
    </svg>
  ),
});
