import _round from 'lodash/round';

type SectionText = {
  text?: string;
  subText?: string;
  tooltip?: string;
};

type GetScoresCircleBaseParams = {
  currentScore: number | null | undefined;
  currentScoreText: SectionText;
  expectedScoreLow: number | null | undefined;
  expectedScoreHigh: number | null | undefined;
  expectedScoreText: SectionText;
};

export const getScoresCircleBase = ({
  currentScore,
  currentScoreText,
  expectedScoreLow,
  expectedScoreHigh,
  expectedScoreText,
}: GetScoresCircleBaseParams) => [
  {
    backgroundColor: 'brand.gray-800',
    score: currentScore ?? 0,
    subtitle: currentScoreText.subText ?? '',
    title: currentScoreText.text ?? '',
    tooltip: currentScoreText.tooltip,
  },
  {
    backgroundColor: 'brand.gray-400',
    score: `${_round(expectedScoreLow ?? 0)}-${_round(expectedScoreHigh ?? 0)}`,
    subtitle: expectedScoreText.subText ?? '',
    title: expectedScoreText.text ?? '',
    tooltip: expectedScoreText.tooltip,
  },
];

type GetPossibleScoreCircleParams = {
  possibleScore: number | null | undefined;
  possibleScoreText: SectionText;
  isPriority: boolean;
};

export const getPossibleScoreCircleBase = ({
  possibleScore = 0,
  possibleScoreText,
  isPriority = true,
}: GetPossibleScoreCircleParams) => [
  {
    backgroundColor: isPriority ? 'brand.blue' : 'brand.green',
    score: possibleScore ?? 0,
    subtitle: possibleScoreText.subText ?? '',
    title: possibleScoreText.text ?? '',
    tooltip: possibleScoreText.tooltip ?? '',
  },
];

export const getLineChartBaseData = ({ currentValues, expectedValues }) => ({
  currentLine: {
    dataStyles: { color: 'brand.gray-800' },
    values: currentValues,
  },
  expectedLine: {
    dataStyles: { color: 'brand.gray-400' },
    values: expectedValues,
  },
});

export const getPrioritySignalGoalLine = (values) => ({
  prioritySignalGoalLine: {
    dataStyles: { color: 'brand.blue' },
    values,
  },
});

export const getMaxImpactGoalLine = (values) => ({
  maxImpactGoalLine: {
    dataStyles: { color: 'brand.green' },
    values,
  },
});
