import { memo } from 'react';
import _filter from 'lodash/filter';
import _findLastIndex from 'lodash/findLastIndex';
import _flatten from 'lodash/flatten';
import _flattenDeep from 'lodash/flattenDeep';
import _includes from 'lodash/includes';
import _isArray from 'lodash/isArray';
import _map from 'lodash/map';
import _max from 'lodash/max';
import _merge from 'lodash/merge';
import _min from 'lodash/min';
import _range from 'lodash/range';
import _size from 'lodash/size';

import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceLine,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { RechartsBase } from './index';
import { cleanStringCase } from '../../helpers/formatHelpers';

export type LineChartDataItemType = {
  currentLine: {
    dataStyles: { color: string };
    values: Array<{
      currentLine: number;
      expectedLine: [number, number];
      prioritySignalGoalLine: number;
      x: string;
    }>;
  };
  expectedLine: {
    dataStyles: { color: string };
    values: Array<{
      expectedLine: [number, number];
      prioritySignalGoalLine?: number;
      x: string;
    }>;
  };
  prioritySignalGoalLine: {
    dataStyles: { color: string };
    values: Array<{
      prioritySignalGoalLine: number;
      x: string;
    }>;
  };
  maxImpactGoalLine: {
    dataStyles: { color: string };
    values: Array<{
      maxImpactGoalLine: number;
      x: string;
    }>;
  };
};

type LineChartType = {
  chartValuesIndexes?: Array<string>;
  data: LineChartDataItemType;
  width?: string | number;
};

type MergeLineChartDataType = Array<{
  currentLine?: number;
  expectedLine?: [number, number];
  maxImpactGoalLine?: number;
  prioritySignalGoalLine?: number;
  x: string;
}>;

const EXTRA_Y_AXIS_PADDING: number = 5;
const MAX_DOMAIN_VALUE: number = 10;
const MIN_DOMAIN_VALUE: number = 0;

const mergeLineChartData = (data: MergeLineChartDataType = []) => {
  const xAxisItemsDone: Array<string> = [];
  return _filter(
    _map(data, (d) => {
      if (_includes(xAxisItemsDone, d.x)) {
        return;
      }
      const allItemsWithXAxis = _filter(data, ['x', d.x]);
      xAxisItemsDone.push(d.x);
      // @ts-ignore
      return _merge(...allItemsWithXAxis);
    }),
  );
};

const getLineChartData = (data: LineChartType['data']) => {
  const lineData = [
    {
      dataKey: 'currentLine',
      displayTooltip: true,
      stroke: 'var(--color-gray-800)',
    },
    {
      dataKey: 'prioritySignalGoalLine',
      displayTooltip: true,
      stroke: 'var(--primary-action-color)',
      strokeDasharray: [4],
    },
    {
      dataKey: 'maxImpactGoalLine',
      displayTooltip: true,
      stroke: 'var(--all-actions-color)',
      strokeDasharray: [4],
    },
  ];
  const areaData = [
    {
      dataKey: 'expectedLine',
      displayTooltip: true,
      fill: 'var(--color-gray-400)',
      stroke: 'var(--color-gray-400)',
    },
  ];
  const cleanedData: MergeLineChartDataType = [
    ...data.currentLine.values,
    ...data.expectedLine.values,
  ];
  if (
    _size(data?.prioritySignalGoalLine?.values) > 0 ||
    _size(data?.maxImpactGoalLine?.values) > 0
  ) {
    const valueToPush =
      data?.prioritySignalGoalLine?.values || data?.maxImpactGoalLine?.values;
    // @ts-ignore
    cleanedData.push(valueToPush);
  }
  const mergedData = mergeLineChartData(_flatten(cleanedData));
  const lastIndex = _findLastIndex(mergedData);
  if (mergedData[3]) {
    mergedData[3] = {
      ...mergedData[3],
      // @ts-ignore
      currentLineScatter: mergedData?.[3]?.currentLine,
    };
  }
  if (lastIndex !== -1) {
    mergedData[lastIndex] = {
      ...mergedData[lastIndex],
      // @ts-ignore
      maxImpactGoalLineScatter: mergedData?.[lastIndex]?.maxImpactGoalLine,
      prioritySignalGoalLineScatter:
        mergedData?.[lastIndex]?.prioritySignalGoalLine,
    };
  }
  const scatterData = [
    {
      dataKey: 'maxImpactGoalLineScatter',
      fill: 'var(--all-actions-color)',
      fillOpacity: 0.7,
      stroke: 'var(--all-actions-color)',
      strokeWidth: 2,
    },
  ];
  return {
    areaData,
    lineData,
    mergedData,
    scatterData,
  };
};

const LineChart = memo(
  ({
    chartValuesIndexes = [
      'currentLine',
      'expectedLine',
      'maxImpactGoalLine',
      'prioritySignalGoalLine',
    ],
    data,
    width = '100%',
  }: LineChartType) => {
    const {
      areaData = [],
      lineData = [],
      mergedData = [],
      scatterData = [],
    } = getLineChartData(data);
    const allYValues: Array<number> = _filter(
      _flattenDeep(
        _map(mergedData, (d) => _map(chartValuesIndexes, (cv) => d?.[cv] || 0)),
      ),
    );
    const maxYValue: number =
      (_max(allYValues) || 0) + EXTRA_Y_AXIS_PADDING || MAX_DOMAIN_VALUE;
    const minYValue: number =
      (_min(allYValues) || 0) - EXTRA_Y_AXIS_PADDING || MIN_DOMAIN_VALUE;
    const yAxisTicks: Array<number> = _range(minYValue, maxYValue, 5);
    return (
      <RechartsBase height={400} width={width}>
        <ComposedChart
          data={mergedData}
          margin={{ top: 20, right: 20, bottom: 5, left: 0 }}
        >
          {_map(areaData, (d, idx) => (
            <Area
              dataKey={d.dataKey}
              fill={d.fill}
              fillOpacity={0.5}
              key={`${idx}_area`}
              stroke={d.stroke}
              strokeWidth={2}
              type={'monotone'}
            />
          ))}
          {_map(scatterData, (d, idx) => (
            <Scatter
              dataKey={d.dataKey}
              fill={d.fill}
              fillOpacity={d.fillOpacity}
              key={`${idx}_scatter`}
              stroke={d.stroke}
              strokeWidth={d.strokeWidth}
              type={'monotone'}
            />
          ))}
          {_map(lineData, (d, idx) => (
            <Line
              dataKey={d.dataKey}
              dot={false}
              key={`${idx}_line`}
              stroke={d.stroke}
              strokeDasharray={d.strokeDasharray?.join(' ')}
              strokeWidth={4}
              type={'monotone'}
            />
          ))}
          <CartesianGrid stroke={'#ccc'} strokeDasharray={'5 5'} />
          <XAxis
            dataKey={'x'}
            tickFormatter={(val) => val.toLocaleString()}
            tickMargin={5}
          />
          <YAxis
            domain={[minYValue, maxYValue]}
            scale={'linear'}
            ticks={yAxisTicks}
            tickFormatter={(val) => val.toLocaleString()}
            tickMargin={5}
          />
          <Tooltip
            formatter={(value, name) => [
              _isArray(value)
                ? `${value[0].toLocaleString()}-${value[1].toLocaleString()}`
                : value.toLocaleString(),
              cleanStringCase(name),
            ]}
            labelStyle={{ borderBottom: '1px solid #d9e2e9', paddingBottom: 4 }}
            separator={': '}
          />
          <ReferenceLine
            label={{
              fill: 'var(--color-red)',
              position: 'top',
              value: 'TODAY',
            }}
            stroke={'var(--color-red)'}
            strokeDasharray={'4'}
            strokeWidth={2}
            x={mergedData?.[3]?.x}
          />
        </ComposedChart>
      </RechartsBase>
    );
  },
);

export default LineChart;
