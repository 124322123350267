import { CheckCircleIcon } from '@chakra-ui/icons';
import { Divider, Icon, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'shared/components/Core';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import {
  useGetAccountQuery,
  useGetAccountWithPredictionsLazyQuery,
} from 'shared/graphql/generatedApiTypes';
import {
  PREDICTION_NAME_LEVEL_FILTER_ITEM,
  PREDICTION_NAME_LEVEL_FIT_FILTER_ITEM,
} from '../../GlobalFilters/types/filterTypes';
import { AllPredictionRenderJSON } from '../../TableSection/renderers/PredictionIconRender';

export default function SingleAccountPredictionsOverview() {
  const { id: selectedAccountId } = useParams<{ id: string }>();

  // state for active tab in prediction section
  const [isProfitActive, setProfitActive] = useState<any>();
  const [isAcquistionActive, setAcquistionActive] = useState<any>();
  const [fitDiff, setFitDiff] = useState<any>();
  const [acqDiff, setAcqDiff] = useState<any>();
  const [fitLevel, setFitLevel] = useState<any>();
  const [acqLevel, setAcqLevel] = useState<any>();
  const [levelDifference, setLevelDifference] = useState<boolean>(false);
  const [predictionLoading, setPredictionLoading] = useState<boolean>(true);

  // prediction types
  enum PredictionForType {
    Acquisition = 'ACQUISITION',
    Retention = 'RETENTION',
    Volume = 'VOLUME',
    Fit = 'FIT',
  }

  // Fetch data for Account
  const { data } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });

  const accountInfo = data?.account;
  // Fetch details for predictions
  const [getAccountWithPrediction] = useGetAccountWithPredictionsLazyQuery({
    variables: {
      id: parseInt(selectedAccountId),
      predictionTypes: [PredictionForType?.Acquisition, PredictionForType?.Fit],
      predictionNames: ['any'],
      recentPredictionDate: moment().format('YYYY-MM-DD'),
    },
  });

  useEffect(() => {
    (async () => {
      setPredictionLoading(true);
      await getAccountWithPrediction().then((res) => {
        let predictionData = res?.data;
        if (
          predictionData?.account?.currentPredictions?.length !== 0 &&
          predictionData?.account?.recentPredictions?.length !== 0
        ) {
          let fitCurrentpredictionData =
            predictionData?.account?.currentPredictions?.filter(
              (item) => item?.calculatedFor?.type === 'FIT',
            )[0];
          let fitRecentpredictionData =
            predictionData?.account?.recentPredictions?.filter(
              (item) => item?.calculatedFor?.type === 'FIT',
            )[0];
          let acqCurrentpredictionData =
            predictionData?.account?.currentPredictions?.filter(
              (item) => item?.calculatedFor?.type === 'ACQUISITION',
            )[0];
          let acqRecentpredictionData =
            predictionData?.account?.recentPredictions?.filter(
              (item) => item?.calculatedFor?.type === 'ACQUISITION',
            )[0];
          if (
            acqCurrentpredictionData?.level !== null &&
            acqCurrentpredictionData?.level !== undefined &&
            acqCurrentpredictionData?.level < 5 &&
            acqRecentpredictionData?.level !== null &&
            acqRecentpredictionData?.level !== undefined &&
            acqRecentpredictionData?.level < 5
          ) {
            let acqlevel = 4 - acqCurrentpredictionData?.level;
            setAcquistionActive(acqlevel);
            setAcqLevel(acqCurrentpredictionData?.level);
            let diffAcq =
              acqCurrentpredictionData?.level - acqRecentpredictionData?.level;
            setAcqDiff(diffAcq);
          }
          if (acqCurrentpredictionData?.level === undefined) {
            setAcquistionActive(undefined);
            setAcqDiff(undefined);
          }
          if (fitCurrentpredictionData?.level === undefined) {
            setProfitActive(undefined);
            setFitDiff(undefined);
            setLevelDifference(false);
          }
          if (
            fitCurrentpredictionData?.level !== null &&
            fitCurrentpredictionData?.level !== undefined &&
            fitCurrentpredictionData?.level < 5 &&
            fitRecentpredictionData?.level !== null &&
            fitRecentpredictionData?.level !== undefined &&
            fitRecentpredictionData?.level < 5
          ) {
            let acqlevel = 4 - fitCurrentpredictionData?.level;
            setProfitActive(acqlevel);
            setFitLevel(fitCurrentpredictionData?.level);
            let diffAcq =
              fitCurrentpredictionData?.level - fitRecentpredictionData?.level;
            if (diffAcq === 0) {
              setLevelDifference(true);
            }
            setFitDiff(diffAcq);
          }
        }
      });
      setPredictionLoading(false);
    })();
  }, [selectedAccountId]);

  // Profile Fit object
  const profileFitObject = [
    {
      tabTitle: 'Very Strong',
      description: (
        <p>
          {accountInfo?.accountName} has a Very Strong Profile Fit. It is
          similar to companies that have become customers in the past.
        </p>
      ),
    },
    {
      tabTitle: 'Strong',
      description: (
        <p>
          {accountInfo?.accountName} has a Strong Profile Fit. It is similar to
          companies that have become customers in the past.
        </p>
      ),
    },
    {
      tabTitle: 'Moderate',
      description: (
        <p>
          {accountInfo?.accountName} has a Moderate Profile Fit. It is somewhat
          similar to companies that have become customers in the past.
        </p>
      ),
    },
    {
      tabTitle: 'Weak',
      description: (
        <p>
          {accountInfo?.accountName} has a Weak Profile Fit. It is not similar
          to companies that have become customers in the past.
        </p>
      ),
    },
    {
      tabTitle: 'Very Weak',
      description: (
        <p>
          {accountInfo?.accountName} has a Very Weak Profile Fit. It is not
          similar to companies that have become customers in the past.
        </p>
      ),
    },
  ];

  // Acquisition object
  const acquisitionProbabilityObject = [
    'Most',
    'More',
    'Median',
    'Less',
    'Least',
  ];

  return !accountInfo ? null : (
    <>
      <div style={{ gap: '16px', display: 'flex' }}>
        <Card isLoading={predictionLoading} className='prediction-card'>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Icon
              x='0px'
              y='0px'
              width='24px'
              height='24px'
              viewBox='0 0 24 24'
            >
              <path
                fill='none'
                stroke='#37A1FD'
                strokeWidth='2'
                strokeLinecap='square'
                strokeMiterlimit='10'
                d='M20,11 c-0.353,0-0.686,0.072-1,0.184V5h-6.184C12.928,4.686,13,4.353,13,4c0-1.657-1.343-3-3-3S7,2.343,7,4c0,0.353,0.072,0.686,0.184,1H1 v6c1.657,0,3,1.343,3,3s-1.343,3-3,3v6h6c0-1.657,1.343-3,3-3s3,1.343,3,3h6v-6.184C19.314,16.928,19.647,17,20,17 c1.657,0,3-1.343,3-3S21.657,11,20,11z'
                transform='translate(0, 0)'
                strokeLinejoin='miter'
              />
            </Icon>

            <span
              style={{
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              Profile fit
            </span>
          </div>
          {accountInfo?.customerStatus !== 'CUSTOMER' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '16px 0px 0px 10px',
              }}
            >
              <span>
                {AllPredictionRenderJSON['fitPredictions']?.[fitLevel]}
              </span>
              <span
                style={{
                  margin: '3px',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontWeight: '700',
                  color: '#323F5A',
                }}
              >
                {PREDICTION_NAME_LEVEL_FIT_FILTER_ITEM[fitLevel]}
              </span>
            </div>
          )}
          <Tabs
            variant='enclosed'
            orientation='vertical'
            onChange={(index) => {
              setProfitActive(index);
            }}
            index={isProfitActive}
            style={{ marginTop: 10 }}
          >
            <TabPanels>
              {isProfitActive !== undefined &&
              accountInfo?.customerStatus !== 'CUSTOMER' ? (
                profileFitObject.map((item, index) => {
                  return (
                    <TabPanel
                      key={index}
                      style={{ padding: '5px 0px 0px 10px' }}
                    >
                      <div style={{ display: 'flex' }}>
                        <div>
                          <CheckCircleIcon
                            className='circle-icon'
                            style={{
                              color: '#2995FB',
                            }}
                          />
                        </div>{' '}
                        <div style={{ marginLeft: '22px' }}>
                          {item?.description}
                        </div>
                      </div>
                      {!levelDifference && (
                        <>
                          <Divider />
                          <div
                            style={{
                              display: 'flex',
                              // gap: '15px',
                              marginTop: '20px',
                            }}
                          >
                            <div>
                              <CheckCircleIcon
                                style={{
                                  color: '#2995FB',
                                }}
                              />
                            </div>
                            <div style={{ marginLeft: '15px' }}>
                              <p>
                                {' '}
                                Profile Fit has{' '}
                                <strong>increased {fitDiff}</strong>{' '}
                                {fitDiff === 1 ? 'space' : 'spaces'} since last
                                week{' '}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </TabPanel>
                  );
                })
              ) : (
                <div
                  style={{
                    height: '207px',
                    textAlign: 'center',
                  }}
                >
                  <svg
                    width='96'
                    height='134'
                    viewBox='0 0 99 99'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    style={{ margin: 'auto' }}
                  >
                    <g clip-path='url(#clip0_4675_23694)'>
                      <path
                        d='M62.7602 21.5889H36.2596C35.6557 21.5896 35.0768 21.8299 34.6498 22.2569C34.2227 22.6839 33.9825 23.2629 33.9818 23.8668V82.7645L33.6781 82.8571L27.1771 84.848C26.869 84.9419 26.5362 84.9098 26.2518 84.7586C25.9673 84.6074 25.7545 84.3496 25.6601 84.0416L6.32254 20.8767C6.22844 20.5686 6.26051 20.2357 6.41171 19.9513C6.56292 19.6668 6.82088 19.454 7.12891 19.3597L17.1469 16.2921L46.1896 7.40244L56.2076 4.33492C56.36 4.288 56.5202 4.27161 56.679 4.28667C56.8378 4.30174 56.9921 4.34798 57.133 4.42273C57.2739 4.49749 57.3987 4.5993 57.5002 4.72234C57.6017 4.84537 57.678 4.98722 57.7246 5.13977L62.6675 21.2852L62.7602 21.5889Z'
                        fill='#F5F8F9'
                      />
                      <path
                        d='M68.5415 21.2851L62.5841 1.82618C62.485 1.50196 62.323 1.20045 62.1073 0.93887C61.8916 0.677293 61.6265 0.460781 61.3271 0.301704C61.0277 0.142627 60.6999 0.0441033 60.3624 0.0117656C60.0249 -0.0205722 59.6843 0.0139101 59.3602 0.113238L45.2754 4.42446L16.2344 13.3157L2.14964 17.6284C1.49533 17.8293 0.947477 18.2817 0.626321 18.8861C0.305164 19.4905 0.236946 20.1977 0.436643 20.8524L20.7976 87.3551C20.9599 87.8835 21.2872 88.3461 21.7316 88.6749C22.176 89.0037 22.7141 89.1814 23.2669 89.1819C23.5227 89.182 23.7771 89.1436 24.0216 89.068L33.6766 86.1129L33.9803 86.0187V85.7013L33.6766 85.794L23.932 88.778C23.3544 88.954 22.7307 88.8938 22.1974 88.6106C21.6642 88.3273 21.2651 87.8441 21.0877 87.267L0.728271 20.7628C0.640359 20.4768 0.60972 20.1763 0.638106 19.8785C0.666492 19.5807 0.753343 19.2914 0.893692 19.0272C1.03404 18.763 1.22513 18.529 1.45601 18.3388C1.6869 18.1486 1.95304 18.0057 2.2392 17.9185L16.3239 13.6057L45.3651 4.71603L59.4498 0.403288C59.6669 0.337048 59.8925 0.303273 60.1195 0.303062C60.6065 0.304155 61.0804 0.461169 61.4717 0.751104C61.863 1.04104 62.1512 1.44866 62.2941 1.91426L68.2241 21.2851L68.3183 21.5889H68.6341L68.5415 21.2851Z'
                        fill='#323F5A'
                      />
                      <path
                        d='M18.9525 19.4112C18.6598 19.4109 18.3749 19.3169 18.1395 19.1429C17.9042 18.9689 17.7308 18.7241 17.6448 18.4443L15.6888 12.0554C15.6363 11.8838 15.618 11.7035 15.6351 11.5249C15.6523 11.3462 15.7044 11.1727 15.7886 11.0142C15.8728 10.8556 15.9874 10.7153 16.1259 10.6011C16.2644 10.4869 16.424 10.401 16.5956 10.3485L43.3131 2.16894C43.6597 2.06318 44.0341 2.09925 44.3542 2.26924C44.6742 2.43923 44.9137 2.72924 45.0201 3.07563L46.9761 9.4646C47.0818 9.81119 47.0457 10.1856 46.8757 10.5056C46.7057 10.8256 46.4158 11.0651 46.0694 11.1716L19.3518 19.3512C19.2224 19.3909 19.0878 19.4111 18.9525 19.4112Z'
                        fill='#2995FB'
                      />
                      <path
                        d='M29.2012 6.82818C30.8786 6.82818 32.2383 5.46841 32.2383 3.79105C32.2383 2.11368 30.8786 0.753906 29.2012 0.753906C27.5238 0.753906 26.1641 2.11368 26.1641 3.79105C26.1641 5.46841 27.5238 6.82818 29.2012 6.82818Z'
                        fill='#2995FB'
                      />
                      <path
                        d='M29.2006 5.71361C30.2627 5.71361 31.1238 4.85256 31.1238 3.7904C31.1238 2.72824 30.2627 1.86719 29.2006 1.86719C28.1384 1.86719 27.2773 2.72824 27.2773 3.7904C27.2773 4.85256 28.1384 5.71361 29.2006 5.71361Z'
                        fill='white'
                      />
                      <path
                        d='M91.6184 88.0836H40.2908C39.9486 88.0833 39.6205 87.9471 39.3785 87.7051C39.1365 87.4632 39.0004 87.1351 39 86.7929V25.2908C39.0004 24.9486 39.1365 24.6205 39.3785 24.3785C39.6205 24.1365 39.9486 24.0004 40.2908 24H91.6184C91.9607 24.0004 92.2887 24.1365 92.5307 24.3785C92.7727 24.6205 92.9088 24.9486 92.9092 25.2908V86.7929C92.9088 87.1351 92.7727 87.4632 92.5307 87.7051C92.2887 87.9471 91.9606 88.0832 91.6184 88.0836Z'
                        fill='#F5F8F9'
                      />
                      <path
                        d='M68.2232 21.2852H36.2573C35.573 21.2861 34.9169 21.5584 34.433 22.0424C33.949 22.5263 33.6767 23.1823 33.6758 23.8667V85.794L33.9795 85.7014V23.8667C33.9802 23.2628 34.2205 22.6839 34.6475 22.2568C35.0745 21.8298 35.6535 21.5896 36.2573 21.5889H68.3174L68.2232 21.2852ZM96.089 21.2852H36.2573C35.573 21.2861 34.9169 21.5584 34.433 22.0424C33.949 22.5263 33.6767 23.1823 33.6758 23.8667V93.4172C33.6767 94.1016 33.949 94.7577 34.433 95.2416C34.9169 95.7255 35.573 95.9978 36.2573 95.9988H96.089C96.7734 95.9978 97.4294 95.7255 97.9134 95.2416C98.3973 94.7577 98.6696 94.1016 98.6706 93.4172V23.8667C98.6696 23.1823 98.3973 22.5263 97.9134 22.0424C97.4294 21.5584 96.7734 21.2861 96.089 21.2852ZM98.3668 93.4172C98.3661 94.0211 98.1259 94.6001 97.6989 95.0271C97.2718 95.4541 96.6929 95.6943 96.089 95.6951H36.2573C35.6535 95.6943 35.0745 95.4541 34.6475 95.0271C34.2205 94.6001 33.9802 94.0211 33.9795 93.4172V23.8667C33.9802 23.2628 34.2205 22.6839 34.6475 22.2568C35.0745 21.8298 35.6535 21.5896 36.2573 21.5889H96.089C96.6929 21.5896 97.2718 21.8298 97.6989 22.2568C98.1259 22.6839 98.3661 23.2628 98.3668 23.8667V93.4172Z'
                        fill='#323F5A'
                      />
                      <path
                        d='M80.1443 27.9698H52.2026C51.8403 27.9694 51.4929 27.8253 51.2367 27.5691C50.9805 27.3128 50.8363 26.9655 50.8359 26.6031V19.9214C50.8363 19.5591 50.9805 19.2117 51.2367 18.9554C51.4929 18.6992 51.8403 18.5551 52.2026 18.5547H80.1443C80.5067 18.5551 80.8541 18.6992 81.1103 18.9554C81.3665 19.2117 81.5106 19.5591 81.511 19.9214V26.6031C81.5106 26.9655 81.3665 27.3128 81.1103 27.5691C80.8541 27.8253 80.5067 27.9694 80.1443 27.9698Z'
                        fill='#2995FB'
                      />
                      <path
                        d='M66.1739 19.0079C67.8512 19.0079 69.211 17.6481 69.211 15.9707C69.211 14.2934 67.8512 12.9336 66.1739 12.9336C64.4965 12.9336 63.1367 14.2934 63.1367 15.9707C63.1367 17.6481 64.4965 19.0079 66.1739 19.0079Z'
                        fill='#2995FB'
                      />
                      <path
                        d='M66.1741 17.8209C67.1958 17.8209 68.024 16.9927 68.024 15.971C68.024 14.9493 67.1958 14.1211 66.1741 14.1211C65.1525 14.1211 64.3242 14.9493 64.3242 15.971C64.3242 16.9927 65.1525 17.8209 66.1741 17.8209Z'
                        fill='white'
                      />
                      <g opacity='0.2' clip-path='url(#clip1_4675_23694)'>
                        <path
                          d='M66 67C72.0751 67 77 62.0751 77 56C77 49.9249 72.0751 45 66 45C59.9249 45 55 49.9249 55 56C55 62.0751 59.9249 67 66 67Z'
                          stroke='#323F5A'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          fill='none'
                        />
                        <path
                          d='M66 55V61'
                          stroke='#323F5A'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          fill='none'
                        />
                        <path
                          d='M66 52C66.5523 52 67 51.5523 67 51C67 50.4477 66.5523 50 66 50C65.4477 50 65 50.4477 65 51C65 51.5523 65.4477 52 66 52Z'
                          fill='#323F5A'
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id='clip0_4675_23694'>
                        <rect
                          width='98.3481'
                          height='96'
                          fill='white'
                          transform='translate(0.324219)'
                        />
                      </clipPath>
                      <clipPath id='clip1_4675_23694'>
                        <rect
                          width='24'
                          height='24'
                          fill='white'
                          transform='translate(54 44)'
                        />
                      </clipPath>
                    </defs>
                  </svg>{' '}
                  <div>
                    {accountInfo?.customerStatus === 'CUSTOMER' ? (
                      <>
                        {' '}
                        <span style={{ fontWeight: 600 }}>
                          Already a customer.{' '}
                        </span>
                        <br />
                        <span style={{ fontWeight: 400, color: 'grey' }}>
                          Profile fit is not available for this company.{' '}
                        </span>
                      </>
                    ) : isProfitActive === undefined ? (
                      <>
                        <span style={{ fontWeight: 600 }}>
                          We need more information to make a prediction.
                        </span>
                        <br />
                        <span style={{ fontWeight: 400, color: 'grey' }}>
                          Try enriching this company with industry, revenue and
                          size information.
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
              )}
            </TabPanels>
          </Tabs>
        </Card>

        <Card isLoading={predictionLoading} className='prediction-card'>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Icon
              x='0px'
              y='0px'
              width='24px'
              height='24px'
              viewBox='0 0 24 24'
            >
              <path
                stroke='#8755D4'
                d='M14,8l-2.172,2.172a4,4,0,0,1-5.656,0L6,10l4.929-4.929a7.072,7.072,0,0,1,10,0h0A7.074,7.074,0,0,1,22,13.7'
                fill='none'
                strokeLinecap='square'
                strokeMiterlimit='10'
                strokeWidth='2'
              />{' '}
              <path
                data-color='color-2'
                d='M15.266,11.266l3.613,3.613a3,3,0,0,1,0,4.242l-1.636,1.636a6,6,0,0,1-8.486,0L3.071,15.071a7.072,7.072,0,0,1,0-10h0A7.048,7.048,0,0,1,8,3'
                fill='none'
                stroke='#8755D4'
                strokeLinecap='square'
                strokeMiterlimit='10'
                strokeWidth='2'
              />{' '}
              <line
                data-cap='butt'
                data-color='color-2'
                x1='18.391'
                y1='19.609'
                x2='14.5'
                y2='15.719'
                fill='none'
                stroke='#8755D4'
                strokeMiterlimit='10'
                strokeWidth='2'
              />{' '}
              <line
                data-cap='butt'
                data-color='color-2'
                x1='15.513'
                y1='21.965'
                x2='11.883'
                y2='18.335'
                fill='none'
                stroke='#8755D4'
                strokeMiterlimit='10'
                strokeWidth='2'
              />{' '}
            </Icon>

            <span
              style={{
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              Acquisition Likelihood
            </span>
          </div>
          {accountInfo?.customerStatus !== 'CUSTOMER' && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '16px 0px 0px 10px',
              }}
            >
              <span>
                {AllPredictionRenderJSON['acquisitionPredictions']?.[acqLevel]}
              </span>
              <span
                style={{
                  margin: '3px',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '14px',
                  fontWeight: '700',
                  color: '#323F5A',
                }}
              >
                {PREDICTION_NAME_LEVEL_FILTER_ITEM[acqLevel]}
              </span>
            </div>
          )}
          <Tabs
            variant='enclosed'
            orientation='vertical'
            onChange={(index) => {
              setAcquistionActive(index);
            }}
            style={{ marginTop: 16 }}
            index={isAcquistionActive}
          >
            <TabPanels>
              {isAcquistionActive !== undefined &&
              accountInfo?.customerStatus !== 'CUSTOMER' ? (
                acquisitionProbabilityObject.map((item, index) => {
                  return (
                    <TabPanel
                      key={index}
                      style={{ padding: '5px 0px 0px 10px' }}
                    >
                      <div style={{ display: 'flex', gap: '15px' }}>
                        <div>
                          <CheckCircleIcon
                            style={{
                              color: '#8454D2',
                            }}
                          />
                        </div>{' '}
                        <div>
                          <p>
                            {' '}
                            {accountInfo?.accountName} is {item} Likely to
                            become a customer (compared to the median
                            likelihood).
                          </p>
                        </div>
                      </div>
                      <Divider />
                      <div
                        style={{
                          display: 'flex',
                          gap: '15px',
                          marginTop: '20px',
                        }}
                      >
                        <div>
                          <CheckCircleIcon
                            style={{
                              color: '#8454D2',
                            }}
                          />
                        </div>
                        <div>
                          <p>
                            {' '}
                            Likelihood has{' '}
                            {acqDiff === 0 ? (
                              'not changed '
                            ) : (
                              <>
                                <strong>increased {acqDiff}</strong>{' '}
                                {acqDiff === 1 ? 'space ' : 'spaces '}
                              </>
                            )}
                            since last week{' '}
                          </p>
                        </div>
                      </div>
                    </TabPanel>
                  );
                })
              ) : (
                <div
                  style={{
                    height: '207px',
                    textAlign: 'center',
                  }}
                >
                  <svg
                    width='96'
                    height='134'
                    viewBox='0 0 99 99'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    style={{ margin: 'auto' }}
                  >
                    <g clip-path='url(#clip0_4675_23705)'>
                      <path
                        d='M62.7602 21.5889H36.2596C35.6557 21.5897 35.0768 21.8299 34.6498 22.2569C34.2227 22.6839 33.9825 23.2629 33.9818 23.8668V82.7645L33.6781 82.8571L27.1771 84.848C26.869 84.9419 26.5362 84.9098 26.2518 84.7586C25.9673 84.6074 25.7545 84.3496 25.6601 84.0416L6.32254 20.8767C6.22844 20.5686 6.26051 20.2357 6.41171 19.9513C6.56292 19.6668 6.82088 19.454 7.12891 19.3597L17.1469 16.2921L46.1896 7.40244L56.2075 4.33492C56.36 4.288 56.5202 4.27161 56.679 4.28667C56.8378 4.30174 56.9921 4.34798 57.133 4.42273C57.2739 4.49749 57.3987 4.5993 57.5002 4.72234C57.6017 4.84537 57.678 4.98722 57.7246 5.13977L62.6676 21.2852L62.7602 21.5889Z'
                        fill='#F5F8F9'
                      />
                      <path
                        d='M68.5415 21.2851L62.5841 1.82618C62.485 1.50196 62.323 1.20045 62.1073 0.93887C61.8916 0.677293 61.6265 0.460781 61.3271 0.301704C61.0277 0.142627 60.6999 0.0441033 60.3624 0.0117656C60.0249 -0.0205722 59.6843 0.0139101 59.3602 0.113238L45.2754 4.42446L16.2344 13.3157L2.14964 17.6284C1.49533 17.8293 0.947477 18.2817 0.626321 18.8861C0.305164 19.4905 0.236946 20.1977 0.436643 20.8523L20.7976 87.3551C20.9599 87.8835 21.2872 88.3461 21.7316 88.6749C22.176 89.0037 22.7141 89.1814 23.2669 89.1819C23.5227 89.182 23.7771 89.1436 24.0216 89.068L33.6766 86.1129L33.9803 86.0187V85.7014L33.6766 85.794L23.932 88.778C23.3544 88.954 22.7307 88.8938 22.1974 88.6106C21.6642 88.3273 21.2651 87.8441 21.0877 87.267L0.728271 20.7628C0.640359 20.4768 0.60972 20.1763 0.638106 19.8785C0.666492 19.5807 0.753343 19.2914 0.893692 19.0272C1.03404 18.763 1.22513 18.529 1.45601 18.3388C1.6869 18.1486 1.95304 18.0057 2.2392 17.9185L16.3239 13.6057L45.3651 4.71603L59.4498 0.403288C59.6669 0.337048 59.8925 0.303273 60.1195 0.303062C60.6065 0.304155 61.0804 0.461169 61.4717 0.751104C61.863 1.04104 62.1512 1.44866 62.2941 1.91426L68.2241 21.2851L68.3183 21.5889H68.6341L68.5415 21.2851Z'
                        fill='#323F5A'
                      />
                      <path
                        d='M18.9525 19.4112C18.6598 19.4109 18.3749 19.3169 18.1395 19.1429C17.9042 18.9689 17.7308 18.7241 17.6448 18.4443L15.6888 12.0554C15.6363 11.8838 15.618 11.7035 15.6351 11.5249C15.6523 11.3462 15.7044 11.1727 15.7886 11.0142C15.8728 10.8556 15.9874 10.7153 16.1259 10.6011C16.2644 10.4869 16.424 10.401 16.5956 10.3485L43.3131 2.16894C43.6597 2.06318 44.0341 2.09925 44.3542 2.26924C44.6742 2.43923 44.9137 2.72924 45.0201 3.07563L46.9761 9.4646C47.0818 9.8112 47.0457 10.1856 46.8757 10.5056C46.7057 10.8256 46.4158 11.0651 46.0694 11.1716L19.3518 19.3512C19.2224 19.3909 19.0878 19.4111 18.9525 19.4112V19.4112Z'
                        fill='#8454D2'
                      />
                      <path
                        d='M29.2012 6.82818C30.8786 6.82818 32.2383 5.46841 32.2383 3.79105C32.2383 2.11368 30.8786 0.753906 29.2012 0.753906C27.5238 0.753906 26.1641 2.11368 26.1641 3.79105C26.1641 5.46841 27.5238 6.82818 29.2012 6.82818Z'
                        fill='#8454D2'
                      />
                      <path
                        d='M29.2006 5.71361C30.2627 5.71361 31.1238 4.85256 31.1238 3.7904C31.1238 2.72824 30.2627 1.86719 29.2006 1.86719C28.1384 1.86719 27.2773 2.72824 27.2773 3.7904C27.2773 4.85256 28.1384 5.71361 29.2006 5.71361Z'
                        fill='white'
                      />
                      <path
                        d='M91.6184 88.0836H40.2908C39.9486 88.0832 39.6205 87.9471 39.3785 87.7051C39.1365 87.4632 39.0004 87.1351 39 86.7929V25.2908C39.0004 24.9486 39.1365 24.6205 39.3785 24.3785C39.6205 24.1365 39.9486 24.0004 40.2908 24H91.6184C91.9607 24.0004 92.2887 24.1365 92.5307 24.3785C92.7727 24.6205 92.9088 24.9486 92.9092 25.2908V86.7929C92.9088 87.1351 92.7727 87.4632 92.5307 87.7051C92.2887 87.9471 91.9606 88.0832 91.6184 88.0836V88.0836Z'
                        fill='#F5F8F9'
                      />
                      <g opacity='0.2' clip-path='url(#clip1_4675_23705)'>
                        <path
                          d='M66 67C72.0751 67 77 62.0751 77 56C77 49.9249 72.0751 45 66 45C59.9249 45 55 49.9249 55 56C55 62.0751 59.9249 67 66 67Z'
                          stroke='#323F5A'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          fill='none'
                        />
                        <path
                          d='M66 55V61'
                          stroke='#323F5A'
                          strokeWidth='2'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M66 52C66.5523 52 67 51.5523 67 51C67 50.4477 66.5523 50 66 50C65.4477 50 65 50.4477 65 51C65 51.5523 65.4477 52 66 52Z'
                          fill='#323F5A'
                        />
                      </g>
                      <path
                        d='M68.2232 21.2852H36.2573C35.573 21.2861 34.9169 21.5584 34.433 22.0424C33.949 22.5263 33.6767 23.1823 33.6758 23.8667V85.794L33.9795 85.7014V23.8667C33.9802 23.2628 34.2205 22.6839 34.6475 22.2568C35.0745 21.8298 35.6535 21.5896 36.2573 21.5889H68.3174L68.2232 21.2852ZM96.089 21.2852H36.2573C35.573 21.2861 34.9169 21.5584 34.433 22.0424C33.949 22.5263 33.6767 23.1823 33.6758 23.8667V93.4172C33.6767 94.1016 33.949 94.7577 34.433 95.2416C34.9169 95.7255 35.573 95.9978 36.2573 95.9988H96.089C96.7734 95.9978 97.4294 95.7255 97.9134 95.2416C98.3973 94.7577 98.6696 94.1016 98.6706 93.4172V23.8667C98.6696 23.1823 98.3973 22.5263 97.9134 22.0424C97.4294 21.5584 96.7734 21.2861 96.089 21.2852V21.2852ZM98.3668 93.4172C98.3661 94.0211 98.1259 94.6001 97.6989 95.0271C97.2718 95.4541 96.6929 95.6943 96.089 95.6951H36.2573C35.6535 95.6943 35.0745 95.4541 34.6475 95.0271C34.2205 94.6001 33.9802 94.0211 33.9795 93.4172V23.8667C33.9802 23.2628 34.2205 22.6839 34.6475 22.2568C35.0745 21.8298 35.6535 21.5896 36.2573 21.5889H96.089C96.6929 21.5896 97.2718 21.8298 97.6989 22.2568C98.1259 22.6839 98.3661 23.2628 98.3668 23.8667V93.4172Z'
                        fill='#323F5A'
                      />
                      <path
                        d='M80.1443 27.9698H52.2026C51.8403 27.9694 51.4929 27.8253 51.2367 27.5691C50.9805 27.3128 50.8363 26.9655 50.8359 26.6031V19.9214C50.8363 19.5591 50.9805 19.2117 51.2367 18.9554C51.4929 18.6992 51.8403 18.5551 52.2026 18.5547H80.1443C80.5067 18.5551 80.8541 18.6992 81.1103 18.9554C81.3665 19.2117 81.5106 19.5591 81.511 19.9214V26.6031C81.5106 26.9655 81.3665 27.3128 81.1103 27.5691C80.8541 27.8253 80.5067 27.9694 80.1443 27.9698V27.9698Z'
                        fill='#8454D2'
                      />
                      <path
                        d='M66.1739 19.0079C67.8512 19.0079 69.211 17.6481 69.211 15.9707C69.211 14.2934 67.8512 12.9336 66.1739 12.9336C64.4965 12.9336 63.1367 14.2934 63.1367 15.9707C63.1367 17.6481 64.4965 19.0079 66.1739 19.0079Z'
                        fill='#8454D2'
                      />
                      <path
                        d='M66.1741 17.8209C67.1958 17.8209 68.024 16.9927 68.024 15.971C68.024 14.9493 67.1958 14.1211 66.1741 14.1211C65.1525 14.1211 64.3242 14.9493 64.3242 15.971C64.3242 16.9927 65.1525 17.8209 66.1741 17.8209Z'
                        fill='white'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_4675_23705'>
                        <rect
                          width='98.3481'
                          height='96'
                          fill='white'
                          transform='translate(0.324219)'
                        />
                      </clipPath>
                      <clipPath id='clip1_4675_23705'>
                        <rect
                          width='24'
                          height='24'
                          fill='white'
                          transform='translate(54 44)'
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  {accountInfo?.customerStatus === 'CUSTOMER' ? (
                    <>
                      {' '}
                      <span style={{ fontWeight: 600 }}>
                        Already a customer.{' '}
                      </span>
                      <br />
                      <span style={{ fontWeight: 400, color: 'grey' }}>
                        Acquisition likelihood is not available for this
                        company.{' '}
                      </span>
                    </>
                  ) : isAcquistionActive === undefined ? (
                    <>
                      <span style={{ fontWeight: 600 }}>
                        We need more information to make a prediction.
                      </span>
                      <br />
                      <span style={{ fontWeight: 400, color: 'grey' }}>
                        Try engaging with this company.
                      </span>
                    </>
                  ) : null}
                </div>
              )}
            </TabPanels>
          </Tabs>
        </Card>
      </div>
    </>
  );
}
