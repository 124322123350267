import { MouseEvent, useState } from 'react';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { Box } from '@chakra-ui/react';

type PaginationType = {
  analyticsAttr?: string;
  gotoPage: (page: number) => void;
  pageCount: number;
  pageOptions: Array<any>;
  pageSize: number;
};

const LEFT_PAGE: number = 0;
const RIGHT_PAGE: number = 100;

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from: number, to: number, step: number = 1) => {
  let i = from;
  const range: Array<number> = [];
  while (i <= to) {
    range.push(i);
    i += step;
  }
  return range;
};

const Pagination = ({
  analyticsAttr = undefined,
  gotoPage = () => {},
  pageCount = 3,
  pageOptions = [],
  pageSize = 30,
}: PaginationType) => {
  // setup consts
  const pageLimit = typeof pageSize === 'number' ? pageSize : 30;
  const totalRecords =
    typeof pageCount === 'number' ? pageOptions.length * pageLimit : 0;
  const pageNeighbours =
    typeof 1 === 'number' ? Math.max(0, Math.min(1, 2)) : 0; // pageNeighbours can be: 0, 1 or 2
  const totalPages = Math.ceil(totalRecords / pageLimit);
  // setup state
  const [currentPage, setCurrentPage] = useState(1);
  // setup functions
  const _gotoPage = (page: number) => {
    const currentPage = Math.max(0, Math.min(page, totalPages));
    // const paginationData = {
    //   currentPage,
    //   totalPages: totalPages,
    //   pageLimit: pageLimit,
    //   totalRecords: totalRecords
    // };
    setCurrentPage(currentPage);
    gotoPage(currentPage - 1);
  };
  const handleClick = (page: number) => (evt: MouseEvent) => {
    evt.preventDefault();
    _gotoPage(page);
  };
  const handleMoveLeft = (evt: MouseEvent) => {
    evt.preventDefault();
    _gotoPage(currentPage - 1 - pageNeighbours * 2 - 1);
  };
  const handleMoveRight = (evt: MouseEvent) => {
    evt.preventDefault();
    _gotoPage(currentPage + pageNeighbours * 2 + 1);
  };
  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const fetchPageNumbers = () => {
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;
    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages: Array<number> = range(startPage, endPage);
      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);
      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }
        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }
        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      return [1, ...pages, totalPages];
    }
    return range(1, totalPages);
  };
  // render
  if (!totalRecords || totalPages === 1) {
    return null;
  }
  const pages = fetchPageNumbers();
  return (
    <Box
      as={'nav'}
      aria-label={'pagination'}
      m={'0 auto'}
      w={'fit-content'}
      pt={6}
      className={'pagination-wrapper h-pt-lg'}
    >
      <ul className={'pagination'}>
        {pages.map((page, index) => {
          if (page === LEFT_PAGE)
            return (
              <li className={'page-item'} key={index}>
                <button
                  analytics-attr={cleanAnalyticsStringList([
                    'pagination previous',
                    'button',
                  ])}
                  aria-label={'Previous'}
                  className={'page-link'}
                  onClick={handleMoveLeft}
                >
                  <span aria-hidden={'true'}>&laquo;</span>
                </button>
              </li>
            );
          if (page === RIGHT_PAGE)
            return (
              <li className={'page-item'} key={index}>
                <button
                  analytics-attr={cleanAnalyticsStringList([
                    'pagination next',
                    'button',
                  ])}
                  aria-label={'Next'}
                  className={'page-link'}
                  onClick={handleMoveRight}
                >
                  <span aria-hidden={'true'}>&raquo;</span>
                </button>
              </li>
            );
          return (
            <li
              className={`page-item${currentPage === page ? ' active' : ''}`}
              key={index}
            >
              <button
                analytics-attr={cleanAnalyticsStringList([
                  analyticsAttr,
                  `pagination`,
                  page,
                  'button',
                ])}
                className={'page-link'}
                onClick={handleClick(page)}
              >
                {page}
              </button>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export default Pagination;
