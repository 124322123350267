import { Card } from '../../../../../../shared/components/Core';
import ClosedAccounts from './ClosedAccounts';

export const AccountsWithoutSignals = ({
  accountsWithoutSignals,
}: {
  accountsWithoutSignals: Array<{}>;
}) => {
  return !!accountsWithoutSignals.length ? (
    <Card minH={30}>
      <ClosedAccounts
        accounts={accountsWithoutSignals}
        title={'Accounts w/o Signals'}
      />
    </Card>
  ) : null;
};
