import {
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  SmallAddIcon
} from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select as PredicitonSelect
} from '@chakra-ui/react';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import _uniqWith from 'lodash/uniqWith';
import { useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useGetAggregateDataWithFilterVarLazyQuery } from 'shared/graphql/generatedApiTypes';
import { AnalyticsAttrType } from 'shared/types/coreTypes.d';
import { Icon, Inline } from '../../../../../../shared/components/Core';
import { adaptDictAsString } from '../../helpers/adaptFilterForQuery';
import {
  getCheckboxSelectFilterItemStyles,
  getSelectedOptionsInitialState
} from '../../helpers/checkboxSelectFilterItem';
import { getFilterItemConsts } from '../../helpers/getFilterItemConsts';
import { sortSelectFilterOptions } from '../../helpers/sortSelectFilterOptions';
import {
  FilterIndices,
  PREDICTION_NAME_LEVEL_FILTER_ITEM,
  PREDICTION_NAME_LEVEL_FIT_FILTER_ITEM
} from '../../types/filterTypes';

export type CheckboxSelectFilterItemProps = AnalyticsAttrType & {
  filterIndex: FilterIndices;
  itemSource?: 'panel' | 'bar' | 'wideMenu';
  initialItems: any[];
  removeValues?: any[];
  tableAccessor: string;
  title?: string;
  width?: string;
};

export const CheckboxSelectFilterItem = ({
  analyticsAttr = '',
  filterIndex,
  initialItems,
  itemSource,
  removeValues,
  tableAccessor,
  title,
}: CheckboxSelectFilterItemProps) => {
  const {
    data: initialAggregatedAccountsData,
    loading: initialAggregatedAccountsDataLoading,
    fullAggregateLoading,
    globalFilter,
    updateFilter,
  } = useContext(AggregateDataContext);
  // this are the state for prediction level filter
  const [predictionArray, setPredictionArray] = useState<any>([]);
  const [strategyOptions, setStrategyOptions] = useState<any>([]);
  const [productOption, setProductOption] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  let predictionDropDownValue = { Strategy: '', Product: '', Level: '' };

  const [predictionSelection, setPredictionSelection] = useState<any>([]);
  const [allSelection, setAllSelection] = useState<any>([]);
  const [
    getAggregateAccountsDataWithFilterVar,
    {
      data: filterItemAggregatedAccountsData,
      loading: filterItemAggregatedAccountsDataLoading,
    },
  ] = useGetAggregateDataWithFilterVarLazyQuery();

  const { aggregatedItemsAccountData, areFiltersApplied, isSelectedDisabled } =
    getFilterItemConsts({
      filterIndices: [filterIndex],
      filterItemAggregatedAccountsData,
      filterItemAggregatedAccountsDataLoading,
      globalFilter,
      initialAggregatedAccountsData,
      initialAggregatedAccountsDataLoading:
        initialAggregatedAccountsDataLoading || fullAggregateLoading,
    });
  let itemsSorted = sortSelectFilterOptions({
    aggregatedItemsAccountData,
    areFiltersApplied,
    filterIndex,
    initialItems,
    removeValues,
    tableAccessor,
  });
  const initialSelectedOptions = getSelectedOptionsInitialState({
    filterIndices: [filterIndex],
    filterItemAggregatedAccountsData,
    globalFilter,
    tableAccessor,
  });

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    getSelectedOptionsInitialState({
      filterIndices: [filterIndex],
      filterItemAggregatedAccountsData,
      globalFilter,
      tableAccessor,
    }),
  );

  useEffect(() => {
    getAggregateAccountsDataWithFilterVar({
      variables: {
        filterString: adaptDictAsString(_omit(globalFilter, filterIndex)) ?? '',
        type: 'account',
      },
    });
    if (globalFilter && globalFilter[filterIndex]) {
      setSelectedOptions(globalFilter[filterIndex]);
    } else {
      setSelectedOptions([]);
    }
  }, [globalFilter, filterIndex]);

  const addFilterLocally = (itemValue) => {
    if (
      selectedOptions.find((existingItem) => itemValue === existingItem) !==
      undefined
    ) {
      setSelectedOptions((prevState) =>
        prevState.filter((existingItem) => itemValue !== existingItem),
      );
    } else {
      setSelectedOptions((prevState) => [...prevState, itemValue]);
    }
  };

  const handleMenuClose = (filterType) => {
    if (filterType === 'PredictionModel') {
      if (!_isEqual(initialSelectedOptions, predictionArray)) {
        let temp: any = [];
        predictionArray.map((item) => {
          if (!item.split(':').includes('')) {
            temp.push(item);
          }
        });
        updateFilter({
          index: filterIndex,
          value: _uniq(temp),
        });
      }
    } else {
      if (!_isEqual(initialSelectedOptions, selectedOptions)) {
        updateFilter({
          index: filterIndex,
          value: selectedOptions,
        });
      }
    }
  };

  const getIconName = (value: string) => {
    return selectedOptions.find((existingItem) => value === existingItem) !==
      undefined
      ? 'checkbox-checked'
      : 'checkbox';
  };

  const setPredictionFIlterOptions = () => {
    // Prediction sorting
    if (filterIndex === 'predictionTypeNameLevel' && itemsSorted.length > 0) {
      let tempStrategyOptions: any = [...strategyOptions];
      let tempProductOption: any = [...productOption];

      itemsSorted.forEach((item) => {
        let data = String(item?.analyticsAttr).split(':');
        tempStrategyOptions.push({ label: data[0], value: data[0] });
        tempProductOption.push({ label: data[1], value: data[1] });
      });

      setStrategyOptions(_uniqWith(tempStrategyOptions, _isEqual));
      setProductOption(_uniqWith(tempProductOption, _isEqual));
      let tempArray: any = [];
      initialSelectedOptions.forEach((item) => {
        tempArray.push({
          Strategy: item.split(':')[0],
          Product: item.split(':')[1],
          Level: item.split(':')[2],
        });
      });
      setPredictionSelection(tempArray);
      setAllSelection(initialSelectedOptions);
      setPredictionArray(initialSelectedOptions);
    }
  };

  // A reference for this https://julietonyekaoha.medium.com/customizing-reusable-react-select-menu-options-components-7642190caa73
  // https://github.com/esotericjules/custom-react-select-menu-component/blob/master/src/custom-select-menu/custom-select.jsx
  const Option = (props) => (
    <components.Option
      {...props}
      analytics-attr={`${analyticsAttr}-${props.data.value}`}
    >
      <Inline>
        <span className={'h-flex h-flex-align-items-center'}>
          <span className={'flag-icon-wrapper'}>
            <Icon
              color={'blue'}
              name={getIconName(props.data.value)}
              size={'md'}
            />
          </span>
          <span className={'h-ml-md'}>{props.data.label}</span>
        </span>
      </Inline>
    </components.Option>
  );

  const handlePredictionSelect = (value, index, field) => {
    let temp = [...predictionSelection];
    temp[index][field] = value;
    let tempPre: any = [];
    temp.forEach((item) => {
      let tempValue = Object.values(item).join(':');
      tempPre.push(tempValue);
    });
    setPredictionSelection(temp);
    setPredictionArray(tempPre);
  };

  const handleAddDisable = () => {
    let disable = false;
    if (predictionSelection.length === allSelection.length) {
      disable = false;
    } else {
      predictionSelection.forEach((item) => {
        let data = allSelection.filter(
          (el) => el === Object.values(item).join(':'),
        );
        if (Object.values(item).includes('') || data.length > 0) {
          disable = true;
        } else {
          disable = false;
        }
      });
    }
    return disable;
  };

  const HandleSelectDisable = (item, value, key) => {
    if (item === 'Strategy') {
      return false;
    } else {
      if (item === 'Product' && predictionSelection[key]['Strategy'] !== '') {
        return false;
      } else if (
        item === 'Level' &&
        predictionSelection[key]['Product'] !== ''
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const handleApplyDisabled = () => {
    if (predictionSelection.length > 0) {
      let data = predictionSelection.filter((item) =>
        Object.values(item).includes(''),
      );
      if (data.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return filterIndex === 'predictionTypeNameLevel' ? (
    <>
      <Popover
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
      >
        <Flex alignItems={'center'} justify={'space-between'}>
          <Flex
            align={'center'}
            flexDirection={['column', null, null, 'row', null]}
          >
            <PopoverTrigger>
              <Button
                onClick={() => {
                  setPredictionFIlterOptions();
                  setIsOpen(true);
                }}
                onInputChange={(newValue, { action }) => {
                  action === 'menu-close' && handleMenuClose('PredictionModel');
                }}
                className='prediction-filter-btn'
                style={{ pointerEvents: isSelectedDisabled ? 'none' : 'auto' }}
              >
                <div
                >
                  <span>{isSelectedDisabled ? 'Loading...' : title} </span>
                  <ChevronDownIcon />
                </div>
              </Button>
            </PopoverTrigger>
          </Flex>
        </Flex>
        <PopoverContent w={700}>
          <PopoverArrow />
          <PopoverBody>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
            >
              {predictionSelection.map((select, key) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                      alignItems: 'center',
                      position: 'relative',
                      left: Object.values(select).includes('') ? '0px' : '50px',
                    }}
                  >
                    {Object.values(select).includes('') && (
                      <Button
                        className='prediction-selectd-cancel-button'
                        size='xs'
                        style={{
                          top: key === 0 ? '12px' : '0px',
                          position: 'relative',
                        }}
                        onClick={() => {
                          let temp: any = [...predictionSelection];
                          if (key > -1) {
                            temp.splice(key, 1);
                          }
                          setPredictionSelection(temp);
                          setPredictionArray(
                            predictionArray.filter(
                              (item, index) => index !== key,
                            ),
                          );
                        }}
                      >
                        <CloseIcon />
                      </Button>
                    )}

                    {key === 0 ? (
                      <span style={{ top: '12px', position: 'relative' }}>
                        Filter by :{' '}
                      </span>
                    ) : (
                      <span
                        style={{
                          marginLeft: '12px',
                        }}
                      >
                        OR by :{' '}
                      </span>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        gap: 10,
                        marginLeft: key === 0 ? '0px' : '4px',
                      }}
                    >
                      {Object.entries(select).map(([item, value], index) => {
                        return (
                          <div style={{ width: '135px' }}>
                            {key === 0 && (
                              <span style={{ color: '#8C8C8C' }}>{item}</span>
                            )}

                            <PredicitonSelect
                              key={key}
                              placeholder={item}
                              style={{ textTransform: 'capitalize' }}
                              onChange={(e) => {
                                e.target.value !== '' &&
                                  handlePredictionSelect(
                                    e.target.value,
                                    key,
                                    item,
                                  );
                              }}
                              value={predictionSelection[key][item]}
                              mr={3}
                              disabled={
                                HandleSelectDisable(item, value, key)
                                  ? true
                                  : false
                              }
                            >
                              {item === 'Level'
                                ? Object.values(select)[0] === 'fit'
                                  ? Object.entries(
                                      PREDICTION_NAME_LEVEL_FIT_FILTER_ITEM,
                                    ).map(([key, value], index) => {
                                      return (
                                        <option value={key} key={index}>
                                          {value}
                                        </option>
                                      );
                                    })
                                  : Object.entries(
                                      PREDICTION_NAME_LEVEL_FILTER_ITEM,
                                    ).map(([key, value], index) => {
                                      return (
                                        <option value={key} key={index}>
                                          {value}
                                        </option>
                                      );
                                    })
                                : item === 'Strategy'
                                ? strategyOptions.map((item, index) => {
                                    return (
                                      <option value={item.value} key={index}>
                                        {item.label}
                                      </option>
                                    );
                                  })
                                : productOption.map((item, index) => {
                                    return (
                                      <option value={item.value} key={index}>
                                        {item.label}
                                      </option>
                                    );
                                  })}
                            </PredicitonSelect>
                          </div>
                        );
                      })}
                    </div>
                    {select['Strategy'] === '' ? (
                      <span
                        style={{
                          top: key === 0 ? '12px' : '0px',
                          position: 'relative',
                        }}
                      >
                        Select Strategy
                      </span>
                    ) : select['Product'] === '' ? (
                      <span
                        style={{
                          top: key === 0 ? '12px' : '0px',
                          position: 'relative',
                        }}
                      >
                        Select Product
                      </span>
                    ) : select['Level'] === '' ? (
                      <span
                        style={{
                          top: key === 0 ? '12px' : '0px',
                          position: 'relative',
                        }}
                      >
                        Select Level
                      </span>
                    ) : (
                      <span
                        style={{
                          top: key === 0 ? '12px' : '0px',
                          position: 'relative',
                        }}
                      >
                        {' '}
                        {initialSelectedOptions.includes(
                          Object.values(select).join(':'),
                        ) ? (
                          <>
                            <CheckIcon style={{ width: '13px' }} /> Applied!
                          </>
                        ) : (
                          'Ready!'
                        )}
                      </span>
                    )}
                  </div>
                );
              })}
            </div>
            <Button
              className='prediction-filter-add-button'
              onClick={() => {
                let tempPre: any = [];
                let temp: any = [...predictionSelection];
                temp.map((item) => {
                  let abc = Object.values(item).join(':');
                  tempPre.push(abc);
                });
                setAllSelection(tempPre);
                setPredictionArray(tempPre);
                setPredictionSelection((previous) => [
                  ...previous,
                  predictionDropDownValue,
                ]);
              }}
              style={{ marginTop: '10px' }}
              leftIcon={<SmallAddIcon />}
              isDisabled={handleAddDisable() ? true : false}
            >
              Add Filter
            </Button>

            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                className='prediction-model-cancle-button'
                mr={3}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className='prediction-filter-add-button'
                onClick={() => {
                  handleMenuClose('PredictionModel');
                  setIsOpen(false);
                }}
                mr={3}
                disabled={handleApplyDisabled() ? true : false}
              >
                Apply
              </Button>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  ) : (
    <Select
      analytics-attr={`${analyticsAttr}-checkbox-select`}
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      components={{ Option }}
      isSearchable={true}
      isDisabled={isSelectedDisabled}
      isMulti={true}
      maxMenuHeight={200}
      onChange={(selectedOptions, { action }) => {
        action === 'select-option' &&
          addFilterLocally(selectedOptions[0].value);
      }}
      onInputChange={(newValue, { action }) => {
        action === 'menu-close' && handleMenuClose('checkbox');
      }}
      options={itemsSorted}
      placeholder={isSelectedDisabled ? 'Loading...' : title}
      styles={getCheckboxSelectFilterItemStyles(itemSource)}
      value={null}
    />
  );
};
