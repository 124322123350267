import { useCallback, useState } from 'react';

export type UseAsyncReturn<T> = {
  execute: (values?: any) => void;
  pending: boolean;
  value: T | null;
  error: string | null;
  clearError: () => void;
};

export function useAsync<T>(
  asyncFunction: (values?: any) => Promise<T>,
  errorMessage = 'Error fetching your data',
): UseAsyncReturn<T> {
  const [pending, setPending] = useState(false);
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (values?: any) => {
      setPending(true);
      setValue(null);
      setError(null);
      if (values) {
        return asyncFunction(values)
          .then((response) => setValue(response))
          .catch(() => {
            setError(errorMessage);
          })
          .finally(() => setPending(false));
      }
      return asyncFunction()
        .then((response) => setValue(response))
        .catch(() => {
          setError(errorMessage);
        })
        .finally(() => setPending(false));
    },
    [asyncFunction],
  );

  return { execute, pending, value, error, clearError: () => setError(null) };
}
