import { Text } from '@chakra-ui/react';

export const GaugeLabelText = ({ label }) => {
  return (
    <Text
      color={'brand.gray-600'}
      fontWeight={'extrabold'}
      fontSize={'xs'}
      letterSpacing={'0.05em'}
      textTransform={'uppercase'}
    >
      {label}
    </Text>
  );
};
