import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _size from 'lodash/size';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import { Avatar, Inline, Txt } from '../../../../../shared/components/Core';
import { KlearlyUserType } from 'shared/firebase/types/klearlyUserType';
import { formatDateString, truncateString } from 'shared/helpers/formatHelpers';
import AuthManager from 'shared/firebase/classes/AuthManager';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { useAsync } from 'app/src/hooks/useAsync';
import { LabCardComment } from 'shared/firebase/schemas/LabCardFeedback';
import { Box } from '@chakra-ui/react';

const SendIcon = require('../../../assets/images/send_button.svg');

const LabCardComments = ({ id = '' }: { id: string }) => {
  const klearlyUser = AuthManager.klearlyUser || null;
  const [triggerRefresh, setTriggerRefresh] = useState<boolean>(true);
  return (
    <div className={'comments-wrapper h-height-full'}>
      <Box bg={'brand.gray-200'} p={4}>
        Comments
      </Box>
      <Comments
        id={id}
        triggerRefresh={triggerRefresh}
        setTriggerRefresh={setTriggerRefresh}
      />
      <Sender
        id={id}
        klearlyUser={klearlyUser as KlearlyUserType}
        setTriggerRefresh={setTriggerRefresh}
      />
    </div>
  );
};

export default LabCardComments;

const Comments = ({
  id = '',
  triggerRefresh,
  setTriggerRefresh,
}: {
  id: string;
  triggerRefresh: boolean;
  setTriggerRefresh: Dispatch<SetStateAction<boolean>>;
}) => {
  const companyName = AuthManager.klearlyUser?.companyName ?? '';
  const commentsEndRef = useRef<HTMLDivElement>(null);

  const firebaseUserUID = AuthManager.currentUser?.uid;
  const getFeedbackAsync = async () => {
    return DatabaseManager.LabCardFeedbackModel.getSingleCardFeedback(
      companyName,
      id,
    );
  };

  const getFeedback = useAsync<{
    // lab card id
    comments: LabCardComment[];
    likes: string[]; // auth user ids who have liked this card
  }>(getFeedbackAsync);

  useEffect(() => {
    if (triggerRefresh) {
      getFeedback.execute();
      setTriggerRefresh(false);
    }
  }, [triggerRefresh]);

  const comments = useMemo(
    () => (getFeedback.value && getFeedback.value.comments) ?? [],
    [getFeedback.value],
  );

  const scrollToBottom = () => {
    if (commentsEndRef.current && commentsEndRef.current.parentElement) {
      commentsEndRef.current.parentElement.scrollTop =
        commentsEndRef.current.offsetTop;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  return (
    <div className={'comments-body h-border-bottom'}>
      <div className={'comments-body-wrapper'}>
        {comments.map((comment) => {
          const {
            comment: commentComment,
            createdAt,
            id,
            user,
          }: LabCardComment = {
            ...comment,
          };
          const {
            firstName,
            isAdminOrSuperUser,
            lastName,
            uid,
          }: LabCardComment['user'] = { ...user };
          const isLoggedInUser = uid === firebaseUserUID;
          const commentClasses = classnames({
            'comments-comment': true,
            'comments-comment__active': isLoggedInUser,
          });
          return (
            <div className={commentClasses} key={id} ref={commentsEndRef}>
              <CommentHeader
                createdAt={createdAt}
                firstName={firstName.trim()}
                isAdminOrSuperUser={isAdminOrSuperUser}
                isLoggedInUser={isLoggedInUser}
                lastName={lastName.trim()}
              />
              <Txt
                align={isLoggedInUser ? 'right' : 'left'}
                className={'h-mt-2xs comments-comment-text'}
              >
                {commentComment}
              </Txt>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CommentHeader = ({
  firstName = '',
  isAdminOrSuperUser = false,
  isLoggedInUser = false,
  lastName = '',
  createdAt,
}: {
  firstName: string;
  lastName: string;
  isAdminOrSuperUser: boolean;
  isLoggedInUser: boolean;
  createdAt: string;
}) => (
  <Inline
    alignItems={'center'}
    gap={'md'}
    justifyContent={isLoggedInUser ? 'end' : 'start'}
  >
    {!isLoggedInUser && <Avatar text={`${firstName} ${lastName}`} />}
    <div>
      <Txt align={isLoggedInUser ? 'right' : 'left'} weight={'bold'}>
        {`${truncateString(`${firstName} ${lastName}`, 18)}${
          isAdminOrSuperUser && ' (Admin)'
        }`}
      </Txt>
      <Txt
        align={isLoggedInUser ? 'right' : 'left'}
        as={'i'}
        className={'h-mt-2xs'}
        size={'xs'}
      >
        {formatDateString(new Date(createdAt), 'PPpp')}
      </Txt>
    </div>
    {isLoggedInUser && <Avatar text={`${firstName} ${lastName}`} />}
  </Inline>
);

const Sender = ({
  id = '',
  klearlyUser,
  setTriggerRefresh,
}: {
  id: string;
  klearlyUser: KlearlyUserType;
  setTriggerRefresh: Dispatch<SetStateAction<boolean>>;
}) => {
  const currentUser = AuthManager.currentUser;
  const uid = currentUser?.uid ?? '';
  const email = currentUser?.email ?? '';
  const [value, setValue] = useState('');

  const onSubmit = useCallback(async () => {
    const isKlearlyAdminOrCustomerAdminUser =
      klearlyUser?.isKlearlyAdmin || klearlyUser?.isAdmin;
    const commentObj: LabCardComment = {
      comment: value,
      id: uuidv4(),
      createdAt: new Date().toISOString(),
      user: {
        email,
        firstName: klearlyUser?.firstName ?? '',
        isAdminOrSuperUser: !!isKlearlyAdminOrCustomerAdminUser,
        lastName: klearlyUser?.lastName ?? '',
        uid,
      },
    };
    await DatabaseManager.LabCardFeedbackModel.updateLabCardComment(
      klearlyUser?.companyName ?? '',
      id,
      commentObj,
    );
    setValue('');
    setTriggerRefresh(true);
  }, [
    email,
    id,
    klearlyUser?.firstName,
    klearlyUser?.isAdmin,
    klearlyUser?.isKlearlyAdmin,
    klearlyUser?.lastName,
    uid,
    value,
  ]);
  const keyPressFunction = useCallback(
    (event) => {
      if (event.which === 13 && _size(value) > 0) {
        onSubmit();
      }
    },
    [onSubmit, value],
  );
  // setup effects on keyboard press
  useEffect(() => {
    document.addEventListener('keydown', keyPressFunction, false);
    return () =>
      document.removeEventListener('keydown', keyPressFunction, false);
  }, [keyPressFunction]);

  return (
    <div className={'comments-sender'}>
      <input
        autoComplete={'off'}
        className={'comments-new-message'}
        name={'message'}
        onChange={(ev) => setValue(ev.target.value)}
        placeholder={'Type a message...'}
        type={'text'}
        value={value}
      />
      <button
        className={'comments-send'}
        disabled={_size(value) === 0}
        onClick={onSubmit}
        type={'button'}
      >
        <img alt={'Send'} className={'comments-send-icon'} src={SendIcon} />
      </button>
    </div>
  );
};
