import { useContext, useMemo } from 'react';
import { AppNavigationalStructureContext } from 'app/src/context/AppNavigationalStructureContext';
import { isTopLevelNavLink } from 'shared/helpers/navigationHelpers';
import { FullSavedPageSchema } from 'shared/firebase/schemas/SavedPage';

export const useTopLevelLinks = () => {
  const { structure } = useContext(AppNavigationalStructureContext);

  const sortTopLevelLinks = (
    a: FullSavedPageSchema,
    b: FullSavedPageSchema,
  ) => {
    if (
      a &&
      b &&
      isTopLevelNavLink(a.navStructure) &&
      isTopLevelNavLink(b.navStructure) &&
      a.navStructure.order &&
      b.navStructure.order &&
      a.navStructure?.order < b.navStructure?.order
    ) {
      return -1;
    }
    return 1;
  };

  return useMemo(() => {
    if (structure) {
      return structure.pages
        .filter((page) => isTopLevelNavLink(page.navStructure))
        .sort(sortTopLevelLinks);
    }
    return [];
  }, [structure]);
};
