import { useMediaQuery } from 'react-responsive';

type MediaQueryValues = {
  isLargerScreen: boolean;
  isSmallerViewport: boolean;
};

export function useDefinedMediaQuery(): MediaQueryValues {
  const isLargerScreen = useMediaQuery({ minWidth: 957 });
  const isSmallerViewport = useMediaQuery({ maxWidth: 999 });

  return {
    isLargerScreen,
    isSmallerViewport,
  };
}
