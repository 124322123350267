import { ApiAccountCustomerStatus } from 'shared/graphql/generatedApiTypes';
import { DottedGrayCircle } from '../../components/Core/Icon/dottedGrayCircle';
import { StatusCircleGradient } from '../../components/Core/Icon/statusCircleGradient';
import { GreenCircleIcon } from '../../components/Core/GreenCircleIcon';
import { ProspectIcon } from 'shared/components/Core/Icon/propspect';
import { CustomerIcon } from 'shared/components/Core/Icon/customer';
import { PastCustomerIcon } from 'shared/components/Core/Icon/past_customer';

export const getStatusCircleIcon = (value: ApiAccountCustomerStatus) => {
  switch (value) {
    case ApiAccountCustomerStatus.Customer:
      return CustomerIcon;
    case ApiAccountCustomerStatus.PastCustomer:
      return PastCustomerIcon;
    case ApiAccountCustomerStatus.Prospect:
      return ProspectIcon;
    default:
      return null;
  }
};
