import ReactDOM from 'react-dom';
import ErrorBoundary from '@honeybadger-io/react';
import _includes from 'lodash/includes';
import _lowerCase from 'lodash/lowerCase';
import 'shared/assets/css/index.scss';
import Honeybadger from '@honeybadger-io/js';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from 'shared/theming';
import reportWebVitals from 'app/src/reportWebVitals';
import { CustomErrorComponent } from 'shared/components/Core/CustomErrorComponent';
import AppWrapper from 'app/src/components/App';

// setup consts
// const firebase = new Firebase();
const honeybadgerConfig = {
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.NODE_ENV,
} as {
  apiKey: string;
  environment: string;
};
Honeybadger.configure(honeybadgerConfig);

Honeybadger.beforeNotify((notice) => {
  if (
    _includes(_lowerCase(notice!.message), 'resizeobserver') ||
    _includes(_lowerCase(notice!.message), 'chunkloaderror')
  ) {
    return false;
  }
});

ReactDOM.render(
  <ChakraProvider theme={theme} portalZIndex={12000}>
    <ErrorBoundary
      ErrorComponent={() => <CustomErrorComponent />}
      honeybadger={Honeybadger}
    >
      {/*<FirebaseContext.Provider value={firebase as unknown as FirebaseType}>*/}
      <AppWrapper />
      {/*</FirebaseContext.Provider>*/}
    </ErrorBoundary>
  </ChakraProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
