export const getAccountSearchStyles = () => ({
  dropdownIndicator: () => ({ display: 'none' }),
  indicatorSeparator: () => null,
  menu: (styles) => ({
    ...styles,
    fontSize: '13px',
    textAlign: 'left',
  }),
  container: (styles) => ({
    ...styles,
    zIndex: 1,
    minWidth: '200px',
  }),
});
