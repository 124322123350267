import { WithLoadingType } from '../types/appTypes';
import { Children, cloneElement, isValidElement } from 'react';
import { Loading } from '../../../../../shared/components/Core';

export const WithLoading = ({
  children,
  isLoading,
  ...rest
}: WithLoadingType) => {
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...rest });
    }
    return child;
  });
  return (
    <>
      {isLoading ? (
        <Loading
          dotColor={'color'}
          w={'100vw'}
          h={'100vh'}
          text={'Loading...'}
        />
      ) : (
        childrenWithProps
      )}
    </>
  );
};
