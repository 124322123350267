import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

import {
  Button,
  Container,
  Input,
  Txt,
} from '../../../../shared/components/Core';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import { Stack } from '@chakra-ui/react';
import { isEmailValid } from 'shared/helpers/validationHelpers';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import AuthManager from 'shared/firebase/classes/AuthManager';

const PasswordForgotPage = () => {
  useEffect(() => {
    updateAppHeaders('Forgot Password');
  }, []);
  return (
    <Container width={'xs'} pv={'4xl'} ph={'xs'}>
      <Stack spacing={10}>
        <Stack spacing={8}>
          <Txt align={'center'} size={'3xl'} weight={'heavy'}>
            {'Welcome to Klearly'}
          </Txt>
          <Txt align={'center'} size={'md'} theme={'muted'}>
            {
              "Please enter the email address you'd like your password reset information sent to."
            }
          </Txt>
        </Stack>
        <PasswordForgotForm />
        <BackToLogInLink />
      </Stack>
    </Container>
  );
};

const PasswordForgotFormBase = () => {
  // setup state and base items
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>();
  // setup consts
  const {
    bind: bindEmail,
    reset: resetEmail,
    value: email,
  } = useInput('', {
    autoComplete: 'email',
    error: error?.message,
    id: 'emailAddress',
    label: 'Email Address',
    name: 'email',
    placeholder: 'ex. seeing@klearly.com',
    success: successText,
    type: 'email',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const isSendResetEmailButtonDisabled = !isEmailValid(email);
  // setup functions
  const _onSubmit = (event) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);
    setSuccessText(undefined);
    if (!isEmailValid(email)) {
      setError({
        code: 'auth/error',
        message: 'Valid email required.',
      });
      setLoading(false);
      return;
    }
    return AuthManager.sendPasswordResetEmail(email)
      .then(() => {
        resetEmail();
        setError(undefined);
        setLoading(false);
        setSuccessText(
          'Email Successfully Sent! Check your inbox to reset your password.',
        );
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };
  // render
  return (
    <form onSubmit={_onSubmit} className={'c-form h-pv-lg'}>
      <Stack spacing={6}>
        <Input {...bindEmail} />
        <Button
          isDisabled={isSendResetEmailButtonDisabled}
          isLoading={loading}
          type={'submit'}
          text={'Send Reset Email'}
          variant={'active'}
        />
      </Stack>
    </form>
  );
};

const PasswordForgotLink = () => (
  <Txt size={'sm'} align={'center'} theme={'active'}>
    <Link to={ROUTES.PASSWORD_FORGOT} className={'c-link c-link--active'}>
      {'Forgot Password?'}
    </Link>
  </Txt>
);

const BackToLogInLink = () => (
  <Txt size={'sm'} align={'center'} theme={'active'}>
    <Link to={ROUTES.LOG_IN} className={'c-link c-link--active'}>
      {'Back to Log In'}
    </Link>
  </Txt>
);

export default PasswordForgotPage;

const PasswordForgotForm = PasswordForgotFormBase;

export { BackToLogInLink, PasswordForgotForm, PasswordForgotLink };
