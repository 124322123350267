import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { animated, useTransition } from 'react-spring';
import { CloseIcon } from '@chakra-ui/icons';

type ModalType = {
  ariaLabel: string;
  body?: ReactNode;
  close?: () => void;
  extraBodyStyles?: CSSProperties;
  footer?: ReactNode;
  header?: ReactNode;
  // isLoading?: boolean;
  modalWidth?: string;
  submit?: () => void;
};

interface ModalContentStyleType {
  [key: string]: CSSProperties;
}

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

const Modal = ({
  ariaLabel = 'Modal',
  body = undefined,
  close = () => {},
  extraBodyStyles = {},
  footer = undefined,
  header = undefined,
  // isLoading = false,
  modalWidth = '75%',
  submit = () => {},
}: ModalType) => {
  // setup state
  const [isModalOpen, setIsModalOpen] = useState(true);
  // setup transitions
  const transitions = useTransition(isModalOpen, {
    enter: { opacity: 1, y: 0 },
    from: { opacity: 0, y: -10 },
    leave: { opacity: 0, y: 10 },
  });
  // setup functions
  const _onClose = useCallback(() => {
    setIsModalOpen(false);
    setTimeout(() => close(), 500);
  }, [close]);
  const keyPressFunction = useCallback(
    (event) => {
      if (event.which === 27) {
        _onClose();
      } else if (event.which === 13) {
        submit();
      }
    },
    [_onClose, submit],
  );
  // setup effects on keyboard press
  useEffect(() => {
    document.addEventListener('keydown', keyPressFunction, false);
    return () =>
      document.removeEventListener('keydown', keyPressFunction, false);
  }, [keyPressFunction]);
  return (
    <>
      {transitions(
        (style, item, state, key) =>
          item && (
            <AnimatedDialogOverlay
              className={'c-modal-backdrop'}
              isOpen={true}
              key={key}
              onDismiss={_onClose}
            >
              <AnimatedDialogContent
                aria-label={ariaLabel}
                className={'c-modal'}
                style={
                  {
                    '--modal-width': modalWidth,
                  } as ModalContentStyleType
                }
              >
                <div className={'c-modal__wrapper'}>
                  {/*{isLoading && <Loading dotColor={'color'} />}*/}
                  <div className={'c-modal__close'} onClick={_onClose}>
                    <CloseIcon />
                  </div>
                  {header && <div className={'c-modal__header'}>{header}</div>}
                  {body && (
                    <div className={'c-modal__body'} style={extraBodyStyles}>
                      {body}
                    </div>
                  )}
                  {footer && <div className={'c-modal__footer'}>{footer}</div>}
                </div>
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          ),
      )}
    </>
  );
};

export default Modal;
