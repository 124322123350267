import { Container } from 'shared/components/Core';
import { useEffect, useState } from 'react';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import * as ROUTES from 'app/src/constants/routes';
import {
  Box,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Wrap,
  WrapItem,
  Center,
} from '@chakra-ui/react';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SalesforceLogo } from 'shared/components/Core/Icon/salesforceLogo';
import { HubSpotLogo } from 'shared/components/Core/Icon/hubSpotLogo';
import { IntegrationButton, OAuthButton } from './IntegrationButton';
import {
  HUBSPOT_INSTALL_URL,
  HUBSPOT_LEARN_MORE_LINK,
  MARKETO_LEARN_MORE_LINK,
  PARDOT_LEARN_MORE_LINK,
  PENDO_LEARN_MORE_LINK,
  SALESFORCE_INSTALL_URL,
  SALESFORCE_LEARN_MORE_LINK,
} from '../constants/integrationUrls';
import { ApiCrmTypeEnum } from 'shared/graphql/generatedApiTypes';
import { MarketoLogoIcon } from 'shared/components/Core/Icon/marketoLogo';
import { useHistory } from 'react-router-dom';
import { PendoLogo } from 'shared/components/Core/Icon/pendoLogo';
import { PardotLogo } from 'shared/components/Core/Icon/pardotLogo';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { MarketoIntegrationPage } from './MarketoIntegrationPage';
import { PendoIntegrationPage } from './PendoIntegrationPage';
import { PardotIntegrationPage } from './PardotIntegrationPage';

const integration_icon = require('../../../assets/images/integration_icon.svg');

export const IntegrationRequestPage = (props) => {
  useEffect(() => {
    updateAppHeaders(ROUTES.INTEGRATIONS_PAGE.name);
  }, []);
  const queryParams = useQueryParams();
  const companyName =
    queryParams.get('company') !== null
      ? queryParams.get('company')
      : props?.companyName;
  const history = useHistory();
  const klearlyUser = AuthManager.klearlyUser;
  const isAuthenticated = !!AuthManager.currentUser;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modelName, setModelName] = useState<string>('');
  // Define company's data souces state
  const [dataSource, setDataSource] = useState<any>({
    hubspot: [],
    pardot: [],
    marketo: [],
    salesforce: [],
    pendo: [],
  });

  const onclose = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Filter company's data sources
    if (klearlyUser?.company?.dataSources) {
      let hubspot = klearlyUser?.company?.dataSources.filter(
        (item) => item!.source === 'HUBSPOT',
      );
      let pardot = klearlyUser?.company?.dataSources.filter(
        (item) => item!.source === 'PARDOT',
      );
      let marketo = klearlyUser?.company?.dataSources.filter(
        (item) => item!.source === 'MARKETO',
      );
      let salesforce = klearlyUser?.company?.dataSources.filter(
        (item) => item!.source === 'SALESFORCE',
      );
      let pendo = klearlyUser?.company?.dataSources.filter(
        (item) => item!.source === 'PENDO',
      );
      let data = {
        hubspot: hubspot.length > 0 ? hubspot : [],
        pardot: pardot.length > 0 ? pardot : [],
        marketo: marketo.length > 0 ? marketo : [],
        salesforce: salesforce.length > 0 ? salesforce : [],
        pendo: pendo.length > 0 ? pendo : [],
      };
      setDataSource(data);
    }
  }, [klearlyUser]);

  return (
    <>
      <Container>
        <Box px={4} mb={8} style={{ marginTop: '20px' }}>
          {!companyName ? (
            <Text textAlign={'center'} fontSize={'xl'}>
              An error has occurred with loading your connectors. Please double
              check your data connector link and reach out to
              support@klearly.com if you need help.
            </Text>
          ) : (
            <Stack spacing={10} align={'center'}>
              <div
                style={{
                  background: '#F2F9FF',
                  width: '61%',
                  height: '50%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: 7,
                }}
              >
                <div style={{ margin: '25px 0 0 28px' }}>
                  <Text
                    style={{
                      fontFamily: 'lato',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '22px',
                      color: '#143351',
                      marginBottom: '10px',
                    }}
                  >
                    Klearly Data Source Integration
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'lato',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '18px',
                      color: '#303030',
                    }}
                  >
                    Select tool from below to start integrating
                    <br />
                    with your system{' '}
                  </Text>
                </div>
                <div>
                  <img src={integration_icon} />
                </div>
              </div>

              <div style={{ width: '63%' }}>
                <Wrap spacing='30px'>
                  <WrapItem>
                    <Center w='265px' h='150px'>
                      <IntegrationButton
                        ariaLabel={'pendo'}
                        isDisabled={false}
                        icon={<PendoLogo boxSize={40} />}
                        learnMoreLink={PENDO_LEARN_MORE_LINK}
                        onClick={() => {
                          if (isAuthenticated) {
                            setIsOpen(true);
                            setModelName('pendo');
                          } else {
                            history.push(
                              `/pendo_integration?company=${companyName}`,
                            );
                          }
                        }}
                        showTable={
                          dataSource?.pendo.length > 0
                            ? dataSource?.pendo?.[0]
                            : null
                        }
                      />
                    </Center>
                  </WrapItem>
                  <WrapItem>
                    <Center w='265px' h='150px'>
                      <IntegrationButton
                        ariaLabel={'marketo'}
                        icon={<MarketoLogoIcon boxSize={60} />}
                        isDisabled={false}
                        learnMoreLink={MARKETO_LEARN_MORE_LINK}
                        onClick={() => {
                          if (isAuthenticated) {
                            setIsOpen(true);
                            setModelName('marketo');
                          } else {
                            history.push(
                              `/marketo_integration?company=${companyName}`,
                            );
                          }
                        }}
                        showTable={
                          dataSource?.marketo.length > 0
                            ? dataSource?.marketo?.[0]
                            : null
                        }
                      />
                    </Center>
                  </WrapItem>
                  <WrapItem>
                    <Center w='265px' h='150px'>
                      <OAuthButton
                        ariaLabel={'hubspot'}
                        isDisabled={!HUBSPOT_INSTALL_URL}
                        icon={<HubSpotLogo boxSize={40} />}
                        learnMoreLink={HUBSPOT_LEARN_MORE_LINK}
                        link={HUBSPOT_INSTALL_URL}
                        locationState={{
                          companyName,
                          dataSource: ApiCrmTypeEnum.Hubspot,
                        }}
                        showTable={
                          dataSource?.hubspot.length > 0
                            ? dataSource?.hubspot?.[0]
                            : null
                        }
                      />
                    </Center>
                  </WrapItem>

                  <WrapItem>
                    <Center w='265px' h='150px'>
                      <OAuthButton
                        ariaLabel={'salesforce'}
                        isDisabled={!SALESFORCE_INSTALL_URL}
                        icon={<SalesforceLogo boxSize={40} />}
                        learnMoreLink={SALESFORCE_LEARN_MORE_LINK}
                        link={SALESFORCE_INSTALL_URL}
                        locationState={{
                          companyName,
                          dataSource: ApiCrmTypeEnum.Salesforce,
                        }}
                        showTable={
                          dataSource?.salesforce.length > 0
                            ? dataSource?.salesforce?.[0]
                            : null
                        }
                      />
                    </Center>
                  </WrapItem>

                  <WrapItem>
                    <Center w='265px' h='150px'>
                      <IntegrationButton
                        ariaLabel={'pardot'}
                        isDisabled={false}
                        icon={<PardotLogo boxSize={40} />}
                        learnMoreLink={PARDOT_LEARN_MORE_LINK}
                        onClick={() => {
                          if (isAuthenticated) {
                            setIsOpen(true);
                            setModelName('pardot');
                          } else {
                            history.push(
                              `/pardot_integration?company=${companyName}`,
                            );
                          }
                        }}
                        showTable={
                          dataSource?.pardot.length > 0
                            ? dataSource?.pardot?.[0]
                            : null
                        }
                      />
                    </Center>
                  </WrapItem>
                </Wrap>
              </div>
            </Stack>
          )}
        </Box>
      </Container>

      <Modal isOpen={isOpen} onClose={onclose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {modelName === 'marketo' && (
              <MarketoIntegrationPage
                companyName={companyName}
                setIsOpen={setIsOpen}
              />
            )}
            {modelName === 'pardot' && (
              <PardotIntegrationPage
                companyName={companyName}
                setIsOpen={setIsOpen}
              />
            )}
            {modelName === 'pendo' && (
              <PendoIntegrationPage
                companyName={companyName}
                setIsOpen={setIsOpen}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
