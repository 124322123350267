import { EngagementLineChartType } from '../types/homeTypes';
import { RechartsBase, RechartsTooltip } from 'shared/components/Core';
import { ComposedChart, Legend, Line, Tooltip, XAxis, YAxis } from 'recharts';
import { useEffect, useState } from 'react';
import { RechartsTooltipPayloadType } from 'shared/components/Core/RechartsBase';
import { formatDateString } from 'shared/helpers/formatHelpers';

export const EngagementLineChart = ({
  data,
  maxYLeftValue,
  maxYRightValue,
}: EngagementLineChartType) => {
  // Force an immediate re-render to ensure the canvas is drawn w/the legend in the right place
  // https://github.com/recharts/recharts/issues/511#issuecomment-572086288
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 0);
  }, []);

  if (!loaded) {
    return null;
  }

  const renderTooltipContent = (dataPayload: RechartsTooltipPayloadType) => {
    const {
      accountCount = '',
      signalCount = '',
      startDate = '',
      endDate = '',
    } = dataPayload;
    const formattedDateRange = `${formatDateString(startDate, 'MM/dd/yy')} -
      ${formatDateString(endDate, 'MM/dd/yy')}`;
    return (
      <>
        <p
          style={{ color: 'var(--color-black)' }}
        >{`Date: ${formattedDateRange}`}</p>
        <p
          style={{ color: 'var(--color-blue)' }}
        >{`Signals: ${signalCount}`}</p>
        <p
          style={{ color: 'var(--color-klearly-blue)' }}
        >{`Accounts: ${accountCount}`}</p>
      </>
    );
  };

  return (
    <RechartsBase height={400} width={'95%'}>
      <ComposedChart
        data={data}
        margin={{ top: 0, right: 30, bottom: 5, left: 30 }}
      >
        <Legend verticalAlign='top' height={30} iconType={'circle'} />
        <Line
          name={'Number of Signals'}
          dataKey='signalCount'
          stroke={'var(--color-blue)'}
          dot={{ stroke: 'var(--color-blue)', strokeWidth: 5 }}
          strokeWidth={3}
        />
        <Line
          name={'Number of Accounts'}
          dataKey='accountCount'
          stroke={'var(--color-klearly-blue)'}
          dot={{ stroke: 'var(--color-klearly-blue)', strokeWidth: 5 }}
          strokeWidth={3}
        />
        <XAxis
          dataKey={'endDate'}
          tickFormatter={(val) => val.toLocaleString()}
          tickMargin={5}
        />
        <YAxis
          orientation={'left'}
          domain={[0, Math.max(maxYLeftValue, maxYRightValue)]}
          scale={'linear'}
          tickFormatter={(val) => val.toLocaleString()}
          tickMargin={5}
        />
        <Tooltip
          content={
            <RechartsTooltip
              lightBackground
              renderContent={renderTooltipContent}
            />
          }
        />
      </ComposedChart>
    </RechartsBase>
  );
};
