import _orderBy from 'lodash/orderBy';
import { LabFirestoreValuesType } from '../types/firestoreValuesTypes';

type BuildLabAccordionSectionsParams = {
  labCardIds: string[];
  tableValues: LabFirestoreValuesType[];
  tagsValues: object | undefined;
};

export const buildLabAccordionSections = ({
  labCardIds,
  tableValues,
  tagsValues,
}: BuildLabAccordionSectionsParams) => {
  // Sort the tags so that we build the sections in the right order
  const orderedTags: Array<{ index: string; order: number }> = _orderBy(
    tagsValues,
    ['order'],
    ['asc'],
  );
  // Add favorites to the front
  orderedTags.unshift({ order: 0, index: 'Favorites' });

  // Build the sections based on the tags
  const initialSections = orderedTags.reduce((accum, current) => {
    return { ...accum, [current.index]: [] };
  }, {});
  const accordionSections = tableValues.reduce((accum, current) => {
    if (labCardIds.includes(current.id)) {
      return { ...accum, Favorites: [...accum['Favorites'], current] };
    } else {
      return {
        ...accum,
        [current.LabsTags]: [...accum[current.LabsTags], current].sort((a, b) =>
          a.cardOrder > b.cardOrder ? 1 : -1,
        ),
      };
    }
  }, initialSections);

  return {
    orderedTags,
    accordionSections,
  };
};
