import React, { useEffect, useState } from 'react';
import { Card } from 'shared/components/Core';
import { useContactsLazyQuery } from 'shared/graphql/generatedApiTypes';
import PeopleListTable from '../peopleListTable';

export default function index() {
  const [allContacts, setAllContacts] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectIds, setSelectIds] = React.useState<any>([]);
  const [allSelected, setAllSelected] = React.useState<boolean>(false);
  const [currentSortsInForm, setCurrentSortsInForm] = useState<any>([
    {
      desc: false,
      id: 'FIRST_NAME',
    },
  ]);
  const [selectedEngagementStatus, setSelectedEngagementStatus] = useState<any>(
    [],
  );
  const [selectedOutreachStatus, setSelectedOutreachStatus] = useState<any>([]);

  enum Sort {
    Asc = 'ASC',
    Desc = 'DESC',
  }

  const [sortArray, setCurrentSorts] = useState<any>([
    {
      field: 'FIRST_NAME',
      direction: Sort['Asc'],
    },
  ]);

  const [pageInfo, setPageInfo] = React.useState<any>({
    pageLimit: 15,
    from: 1,
    to: 15,
    page: 1,
  });

  const pageDropdown = [15, 30, 45];

  let filterVariable: any = {
    page: pageInfo.page,
    limit: parseInt(pageInfo.pageLimit),
    sort: sortArray,
    filterString: JSON.stringify({
      engagementStatus: selectedEngagementStatus,
      outreachStatus: selectedOutreachStatus,
    }),
  };

  const [getMultipleAccountPeopleList] = useContactsLazyQuery({
    variables: filterVariable,
  });

  //This function For handle pagination
  const hanlePagination = (arrow) => {
    if (arrow === 'left') {
      setPageInfo({
        ...pageInfo,
        page: parseInt(pageInfo.page) - 1,
        from: parseInt(pageInfo?.from) - parseInt(pageInfo?.pageLimit),
        to: parseInt(pageInfo?.to) - parseInt(pageInfo?.pageLimit),
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page: parseInt(pageInfo.page) + 1,
        from: parseInt(pageInfo?.from) + parseInt(pageInfo?.pageLimit),
        to: parseInt(pageInfo?.to) + parseInt(pageInfo?.pageLimit),
      });
    }
  };

  useEffect(() => {
    (async () => {
      await getMultipleAccountPeopleList().then((res) => {
        let contactData = res?.data;
        if (
          contactData?.contacts !== null &&
          contactData?.contacts !== undefined
        ) {
          if (contactData?.contacts?.['contacts'] !== undefined) {
            let pageInfoData: any = contactData?.contacts?.['pageInfo'];
            setPageInfo({ ...pageInfo, ...pageInfoData });
            let temp: any = [];
            contactData?.contacts?.['contacts'].map((contact) => {
              temp.push({
                ...contact,
                companyName: contact?.accounts[0]?.accountName,
                name: contact?.firstName + ' ' + contact?.lastName,
              });
            });
            setAllContacts(temp);
          }
        }
        setLoading(false);
      });
    })();
  }, [
    sortArray,
    pageInfo.from,
    pageInfo.pageLimit,
    pageInfo.to,
    pageInfo.pageLimit,
    selectedEngagementStatus,
    selectedOutreachStatus,
  ]);

  return (
    <Card
      isLoading={loading}
      style={{ background: 'none', border: 'none', padding: 0 }}
    >
      <PeopleListTable
        allContacts={allContacts}
        selectIds={selectIds}
        setSelectIds={setSelectIds}
        allSelected={allSelected}
        setAllSelected={setAllSelected}
        pageInfo={pageInfo}
        setPageInfo={setPageInfo}
        hanlePagination={hanlePagination}
        pageDropdown={pageDropdown}
        section={'peopleMenu'}
        currentSortsInForm={currentSortsInForm}
        setCurrentSortsInForm={setCurrentSortsInForm}
        setCurrentSorts={setCurrentSorts}
        setSelectedEngagementStatus={setSelectedEngagementStatus}
        setSelectedOutreachStatus={setSelectedOutreachStatus}
        selectedEngagementStatus={selectedEngagementStatus}
        selectedOutreachStatus={selectedOutreachStatus}
        filterVariable={filterVariable}
      />
    </Card>
  );
}
