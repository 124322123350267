import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  borderBottom: '3px solid transparent',
  borderTop: '3px solid transparent',
  fontWeight: 'black',
  letterSpacing: 'widest',
  lineHeight: 'tall',
  mx: 5,
  py: 4,
  textTransform: 'uppercase',
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    textDecoration: 'none',
    color: 'brand.crimson',
  },
};

const sizes = {
  normal: {
    fontSize: 'xs',
    h: 'full',
    mx: 5,
    py: 4,
  },
  mobileMenu: {
    alignSelf: 'flex-start',
    fontSize: 'lg',
    position: 'relative',
  },
};

const variants = {
  active: {
    color: 'brand.gray-800',
    borderBottomColor: 'brand.crimson',
  },
  inactive: {
    color: 'brand.gray-600',
  },
};

const defaultProps = {
  size: 'normal',
  variant: 'inactive',
};

export const Link = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
