import { ExternalLinkIcon } from '@chakra-ui/icons';
import { List, ListItem, Text } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'shared/components/Core';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { useGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { numberFormatter } from 'shared/helpers/formatHelpers';
import { buildGoogleMapsLink } from '../../helpers/buildGoogleMapsLink';
import { getFormattedAddressString } from '../../helpers/getFormattedAddressString';
import { getFormattedEmployeeCount } from '../../helpers/getFormattedEmployeeCount';

export default function index() {
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const AboutInfoFields = [
    { label: 'Industry', filed: 'industry' },
    { label: 'Revenue', filed: 'annualRevenue' },
    { label: 'Size', filed: 'employeeCount' },
  ];
  // Fetch data for Account
  const { data, loading } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });
  const accountInfo = data?.account;
  const googleMapsLink = buildGoogleMapsLink({
    addressStreet: accountInfo?.addressStreet,
    addressCity: accountInfo?.addressCity,
    addressRegion: accountInfo?.addressRegion,
    addressZipCode: accountInfo?.addressZipCode,
  });

  const AccountInfoFileds = {
    industry: 'industry',
    annualRevenue: 'annualRevenue',
    employeeCount: 'employeeCount',
    addressCity: 'addressCity',
  };

  const getValues = (item) => {
    switch (item.filed) {
      case AccountInfoFileds?.industry:
        return accountInfo?.industry ?? '-';
      case AccountInfoFileds?.annualRevenue:
        return accountInfo?.annualRevenue !== null
          ? numberFormatter(accountInfo?.annualRevenue)
          : '-';
      case AccountInfoFileds?.employeeCount:
        return accountInfo?.employeeCount
          ? getFormattedEmployeeCount(accountInfo.employeeCount)
          : '-';
    }
  };

  return (
    <Card
      style={{minHeight: 'fit-content'}}
      isLoading={loading}
    >
      <span style={{ fontSize: 24, fontWeight: 'bold' }}>
        About this company
      </span>
      <List spacing={3} marginTop={8}>
        {AboutInfoFields.map((item, index) => {
          return (
            <ListItem>
              <Text fontSize='md' color={'#70798B'} fontWeight={'600'}>
                {item?.label}
              </Text>
              <Text fontSize='lg' color={'#152F45'} fontWeight={'600'}>
                {getValues(item)}
              </Text>
            </ListItem>
          );
        })}
        <ListItem>
          <Text fontSize='md' color={'#70798B'} fontWeight={'600'}>
            Location
          </Text>
          {accountInfo?.addressCity ? (
            <Text
              _hover={{ cursor: 'pointer' }}
              analytics-attr={`single-account-external-map-link`}
              as={'a'}
              color={'#152F45'}
              fontSize={'lg'}
              fontWeight={'bold'}
              href={googleMapsLink}
              mr={2}
              display={'flex'}
              alignItems={'center'}
              rel={'noopener noreferrer'}
              target={'_blank'}
            >
              {getFormattedAddressString({
                addressCity: accountInfo?.addressCity,
                addressRegion: accountInfo?.addressRegion,
                addressZipCode: accountInfo?.addressZipCode,
              })}
              <ExternalLinkIcon ml={2} fontSize={'xl'} />
            </Text>
          ) : (
            <Text fontSize='lg' color={'#152F45'} fontWeight={'600'}>
              -
            </Text>
          )}
        </ListItem>
      </List>
    </Card>
  );
}
