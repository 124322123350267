import { PageIndexOptions } from '../../../types/PageIndexOptions';
import {
  ApiCurrentPrediction,
  ApiGetAccountPredictionsQuery,
  ApiGetAccountQuery,
  useGetAccountPredictionsQuery,
} from 'shared/graphql/generatedApiTypes';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _round from 'lodash/round';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isNull from 'lodash/isNull';
import _size from 'lodash/size';
import { Container } from 'shared/components/Core';
import { Stack } from '@chakra-ui/react';
import SectionHeader from '../../../../../../../shared/components/Core/SectionHeader';
import RecommendationsButtonGroup from './RecommendationsButtonGroup';
import { LineChartDataItemType } from 'shared/components/Core/LineChart';
import { AccountScoreBody } from './AccountScoreBody';
import {
  getLineChartBaseData,
  getMaxImpactGoalLine,
  getPossibleScoreCircleBase,
  getPrioritySignalGoalLine,
  getScoresCircleBase,
} from '../../../helpers/accountScoreHelpers';
import { formatDateString } from 'shared/helpers/formatHelpers';

type AccountScoreSectionProps = {
  accountInfo: NonNullable<ApiGetAccountQuery['account']>;
  pageIndex: PageIndexOptions;
  setPageIndex: (newIndex: PageIndexOptions) => void;
};

export const AccountScoreSection = ({
  accountInfo,
  pageIndex = PageIndexOptions.primary,
  setPageIndex = () => {},
}: AccountScoreSectionProps) => {
  const { data, loading } = useGetAccountPredictionsQuery({
    ...defaultQueryFetchPolicy,
    variables: {
      accountId: accountInfo.id,
      timeRange: { daysFuture: 90, daysPast: 90, intervalDays: 30 },
    },
  });

  const cleanedXAxisLabel = (calculatedFor: string | undefined): string => {
    if (!calculatedFor) return '';
    const cleanedDate = new Date(calculatedFor.replace(/-/g, '/'));
    return formatDateString(cleanedDate, 'LLL d');
  };

  const isPrioritySelected = pageIndex === PageIndexOptions.primary;

  // Set some defaults to deal with lodash typing issues below
  const currentPredictions = !!data?.accountPredictions?.currentPredictions
    ?.length
    ? data?.accountPredictions?.currentPredictions
    : [{ calculatedFor: undefined, winProbability: 0 }];
  const expectedPredictions: NonNullable<
    ApiGetAccountPredictionsQuery['accountPredictions']
  >['expectedPredictions'] = !!data?.accountPredictions?.expectedPredictions
    ?.length
    ? data?.accountPredictions?.expectedPredictions
    : [
        {
          calculatedFor: undefined,
          valueLow: 0,
          valueMiddle: 0,
          valueHigh: 0,
        },
      ];
  const goalPredictions: NonNullable<
    ApiGetAccountPredictionsQuery['accountPredictions']
  >['goalPredictions'] = !!data?.accountPredictions?.goalPredictions?.length
    ? data?.accountPredictions?.goalPredictions
    : [{ calculatedFor: undefined, value: 0, isPriority: false }];

  const currentTableValues = _map(
    _orderBy(currentPredictions, ['calculatedFor'], ['asc']),
    (c) => ({
      x: cleanedXAxisLabel((c as ApiCurrentPrediction)?.calculatedFor),
      currentLine: _round((c as ApiCurrentPrediction)?.value ?? 0),
    }),
  );
  const expectedTableValues = _map(
    _orderBy(expectedPredictions, ['calculatedFor'], ['asc']),
    (e) => ({
      x: cleanedXAxisLabel(e?.calculatedFor),
      expectedLine: [_round(e?.valueLow ?? 0), _round(e?.valueHigh ?? 0)],
    }),
  );
  const priorityGoalTableValues = _map(
    _filter(_orderBy(goalPredictions, ['calculatedFor'], ['asc']), [
      'isPriority',
      true,
    ]),
    (g) => ({
      x: cleanedXAxisLabel(g?.calculatedFor),
      prioritySignalGoalLine: _round(g?.value ?? 0),
    }),
  );
  const nonPriorityGoalTableValues = _map(
    _filter(_orderBy(goalPredictions, ['calculatedFor'], ['asc']), [
      'isPriority',
      false,
    ]),
    (g) => ({
      x: cleanedXAxisLabel(g?.calculatedFor),
      maxImpactGoalLine: _round(g?.value ?? 0),
    }),
  );
  const { currentValue, expectedValue, goalValues, id } = { ...accountInfo };
  const selectedGoalWinProbabilities = _find(goalValues, [
    'isPriority',
    isPrioritySelected,
  ]);
  const scoresCircleData = [
    ...getScoresCircleBase({
      currentScore: currentValue,
      currentScoreText: {
        text: 'Current Account Score',
        subText: '(as of today)',
        tooltip: '',
      },
      expectedScoreLow: expectedValue?.low,
      expectedScoreHigh: expectedValue?.high,
      expectedScoreText: {
        text: 'Expected Account Score',
        subText: '(90 days from now)',
        tooltip: '',
      },
    }),
    ...getPossibleScoreCircleBase({
      possibleScore: selectedGoalWinProbabilities?.value,
      isPriority: isPrioritySelected,
      possibleScoreText: {
        text: 'Possible Account Score',
        subText: '(90 days from now)',
        tooltip: '',
      },
    }),
  ];

  const lineChartBaseData = getLineChartBaseData({
    currentValues: currentTableValues,
    expectedValues: expectedTableValues,
  });
  const lineChartData =
    pageIndex === PageIndexOptions.primary
      ? {
          ...lineChartBaseData,
          ...getPrioritySignalGoalLine(priorityGoalTableValues),
        }
      : {
          ...lineChartBaseData,
          ...getMaxImpactGoalLine(nonPriorityGoalTableValues),
        };

  const needsProcessing =
    !_isNull(expectedPredictions) && _size(expectedPredictions) < 7;

  return (
    <Container ph={'xl'} pv={'lg'} width={'full'}>
      <Stack spacing={4}>
        <SectionHeader
          rightComponent={
            <RecommendationsButtonGroup
              id={id}
              maxImpactButtonLabel={'All Actions'}
              pageIndex={pageIndex}
              prioritySignalButtonLabel={'Primary Action'}
              setPageIndex={setPageIndex}
            />
          }
          subtitle={
            'Probability of an account ever becoming a customer based on signal performance and other account attributes.'
          }
          title={'Account Score'}
        />
        <AccountScoreBody
          data={scoresCircleData}
          isLoading={loading}
          lineChartData={lineChartData as LineChartDataItemType}
          needsProcessing={needsProcessing}
        />
      </Stack>
    </Container>
  );
};
