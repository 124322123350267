import {createIcon} from '@chakra-ui/icon';

export const TrendingFlatIcon = createIcon({
  displayName: 'TrendingFlat',
  viewBox: '0 0 17 8',
  path: (
    <svg
      width='17'
      height='8'
      viewBox='0 0 17 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.6668 3.99984L13.3335 0.666504V3.1665H0.833496V4.83317H13.3335V7.33317L16.6668 3.99984Z'
        fill='#2997FC'
      />
    </svg>
  ),
});
