export const COLOR_PALETTE: Array<string> = [
  '#7E2A98',
  '#FF2827',
  '#F9A22F',
  '#C1295D',
  '#4DDE95',
  '#2997FC',
  '#1A0B8C',
  '#DF2944',
  '#FBBC04',
  '#FF5A5F',
  '#62D1A6',
  '#274A67',
  '#942A86',
  '#FE282D',
  '#F09A00',
  '#BA2A65',
  '#71C287',
  '#489ADE',
  '#0253A3',
  '#7E2A98',
  '#FF2827',
  '#F9A22F',
  '#C1295D',
  '#4DDE95',
  '#2997FC',
  '#1A0B8C',
  '#DF2944',
  '#FBBC04',
  '#FF5A5F',
  '#62D1A6',
  '#274A67',
  '#942A86',
  '#FE282D',
  '#F09A00',
  '#BA2A65',
  '#71C287',
  '#489ADE',
  '#0253A3',
  '#7E2A98',
  '#FF2827',
  '#F9A22F',
  '#C1295D',
  '#4DDE95',
  '#2997FC',
  '#1A0B8C',
  '#DF2944',
  '#FBBC04',
  '#FF5A5F',
  '#62D1A6',
  '#274A67',
  '#942A86',
  '#FE282D',
  '#F09A00',
  '#BA2A65',
  '#71C287',
  '#489ADE',
  '#0253A3',
  '#7E2A98',
  '#FF2827',
  '#F9A22F',
  '#C1295D',
  '#4DDE95',
  '#2997FC',
  '#1A0B8C',
  '#DF2944',
  '#FBBC04',
  '#FF5A5F',
  '#62D1A6',
  '#274A67',
  '#942A86',
  '#FE282D',
  '#F09A00',
  '#BA2A65',
  '#71C287',
  '#489ADE',
  '#0253A3',
];

export const PIE_CHART_DATA_VALUE: string = 'value';
export const PIE_CHART_NAME_VALUE: string = 'key';
export const BAR_CHART_DATA_VALUE: string = 'value';
export const BAR_CHART_NAME_VALUE: string = 'label';
