import {
  Granularity,
  SignalHistorySectionFilter,
  SignalSectionUnitType,
  SingleAccountSignalsTimePeriod,
} from 'app/src/components/Account/types/SingleAccountSignalsTypes';
import {
  SignalDirections,
  SignalSources,
} from 'app/src/components/Home/types/homeTypes';

export const signalHistoryPanelDefaultFilters = {
  [SignalHistorySectionFilter.typeDetail]: {
    value: 'All',
    stateKey: 'currentTypeDetail',
  },
  [SignalHistorySectionFilter.type]: { value: 'All', stateKey: 'currentType' },
  [SignalHistorySectionFilter.direction]: {
    value: SignalDirections.All,
    stateKey: 'currentDirection',
  },
  [SignalHistorySectionFilter.source]: {
    value: SignalSources.All,
    stateKey: 'currentSource',
  },
  [SignalHistorySectionFilter.summarizeBy]: {
    value: SignalSectionUnitType.quantity,
    stateKey: 'currentUnitType',
  },
  [SignalHistorySectionFilter.granularity]: {
    value: Granularity.monthly,
    stateKey: 'currentGranularity',
  },
  [SignalHistorySectionFilter.timePeriod]: {
    value: SingleAccountSignalsTimePeriod.sixMonths,
    stateKey: '',
  },
};
