import { useReactiveVar } from '@apollo/client';
import { ChevronDownIcon, CloseIcon, SmallAddIcon } from '@chakra-ui/icons';
import {
  Box,
  ButtonGroup,
  Flex,
  FormLabel,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@chakra-ui/react';
import { inTemporaryGlobalFilterStateVar } from 'app/src/apollo/rootReactiveVariables';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import {
  createRef,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { Button, RadioInput, Select } from 'shared/components/Core';
import { SortIcon } from 'shared/components/Core/Icon/sort';
import { XCircleIcon } from 'shared/components/Core/Icon/xCircle';
import { OnChangeSelectChangeEventType } from 'shared/components/Core/Select';
import { ApiSort } from 'shared/graphql/generatedApiTypes';
import { pluralizeCompaniesString } from 'shared/helpers/formatHelpers';

type SortPopoverProps = {
  onClose: () => void;
  legend;
  sortInteractionModal;
  currentSortsInForm;
  setCurrentSortsInForm;
  sortableColumnOptions;
  disableSortApply;
  applySorts;
  showingHiddenCompanies: boolean;
  setShowingHiddenCompanies: Dispatch<SetStateAction<boolean>>;
  totalCompanies: number;
};

export const CompaniesTableTopRow = ({
  onClose,
  legend,
  sortInteractionModal,
  currentSortsInForm,
  setCurrentSortsInForm,
  sortableColumnOptions,
  disableSortApply,
  applySorts,
  showingHiddenCompanies,
  setShowingHiddenCompanies,
  totalCompanies,
}: SortPopoverProps) => {
  const sortPopoverTriggerRef = createRef();
  const popoverTop = sortPopoverTriggerRef?.current
    ? ((sortPopoverTriggerRef?.current as HTMLButtonElement)
        ?.offsetTop as number)
    : 0;
  const { globalFilter, overrideFilter } = useContext(AggregateDataContext);

  const inTemporaryGlobalFilterState = useReactiveVar(
    inTemporaryGlobalFilterStateVar,
  );

  const excludedAccountsNumber = useMemo(() => {
    if (globalFilter?.excludedAccountIds) {
      return ` (${globalFilter.excludedAccountIds.length})`;
    }
    return '';
  }, [globalFilter]);

  const removeTemporaryState = () => {
    if (inTemporaryGlobalFilterState) {
      overrideFilter(inTemporaryGlobalFilterState);
      inTemporaryGlobalFilterStateVar(null);
    }
  };

  useEffect(() => {
    return () => removeTemporaryState();
  }, []);

  return (
    <Popover onClose={onClose} isOpen={sortInteractionModal.isOpen}>
      <Flex alignItems={'center'} justify={'space-between'} mb={2}>
        <Flex flexDirection={'column'}>
          <Text
            fontWeight={'bold'}
            mb={1}
          >{`${totalCompanies.toLocaleString()} ${pluralizeCompaniesString(
            totalCompanies,
          )}`}</Text>
          {/* <Flex flexDirection={['column', null, null, 'row', null]}>
            {legend}
          </Flex> */}
          {inTemporaryGlobalFilterState && (
            <Button
              fontProps={{
                fontSize: 'sm',
                color: 'brand.black',
                fontWeight: 'normal',
              }}
              p={2}
              h={8}
              borderRadius={16}
              text={'Related to Summary Message'}
              variant={'transparent'}
              ml={2}
              mb={0.5}
              rightIcon={<XCircleIcon />}
              bg={`rgba(42, 150, 252, 0.1)`}
              borderColor={'brand.klarityBlue'}
              borderWidth={2}
              onClick={removeTemporaryState}
            />
          )}
        </Flex>
        <Flex
          align={'center'}
          flexDirection={['column', null, null, 'row', null]}
        >
          {/* {!!excludedAccountsNumber && (
            <Flex ml={2} align={'center'}>
              <Text
                fontSize={'sm'}
                mr={1}
                fontWeight={'bold'}
                textAlign={'right'}
              >
                Show hidden companies?{excludedAccountsNumber}
              </Text>
              <Switch
                checked={showingHiddenCompanies}
                onChange={(e) => setShowingHiddenCompanies(e.target.checked)}
              />
            </Flex>
          )} */}
          <PopoverTrigger>
            <Button
              onClick={sortInteractionModal.onOpen}
              ref={sortPopoverTriggerRef}
              leftIcon={<SortIcon />}
              rightIcon={<ChevronDownIcon />}
              text={`Sort by`}
              maxW={40}
              ml={4}
              my={2}
              borderWidth={2}
              borderRadius={24}
              borderColor={'#ECF2FA'}
              fontWeight={'normal'}
              maxWidth={140}
              py={4}
              variant={'actionOutline'}
            />
          </PopoverTrigger>
        </Flex>
      </Flex>
      <PopoverContent right={16} top={popoverTop} w={500}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          {currentSortsInForm.map((sort, index) => (
            <Flex
              alignItems={'center'}
              h={20}
              justify={'space-between'}
              key={index}
              mt={4}
            >
              <IconButton
                aria-label={'remove-sort'}
                icon={<CloseIcon boxSize={3} />}
                mx={2}
                mb={6}
                onClick={() =>
                  setCurrentSortsInForm(
                    currentSortsInForm.filter(
                      (_, indexToFilter) => indexToFilter !== index,
                    ),
                  )
                }
                size={'sm'}
                variant={'activeOutline'}
              />
              <Flex mx={4} alignItems={'center'}>
                <FormLabel fontSize={'sm'} mb={6}>
                  {index === 0 ? 'Sort by:' : 'Then by:'}
                </FormLabel>
                <Select
                  extraFieldSetStyles={{
                    fontSize: '12px',
                    minWidth: '200px',
                  }}
                  options={sortableColumnOptions}
                  //@ts-ignore
                  onChange={(e: OnChangeSelectChangeEventType) => {
                    const copy = [...currentSortsInForm];
                    copy[index].id = e.target.value;
                    setCurrentSortsInForm(copy);
                  }}
                  //@ts-ignore
                  value={sort.id}
                />
              </Flex>
              <Box pt={4}>
                <RadioInput
                  onChange={(e) => {
                    const copy = [...currentSortsInForm];
                    copy[index].desc = e.target.value === ApiSort.Desc;
                    setCurrentSortsInForm(copy);
                  }}
                  options={[
                    { label: 'A-Z', value: ApiSort.Asc },
                    { label: 'Z-A', value: ApiSort.Desc },
                  ]}
                  value={sort.desc ? ApiSort.Desc : ApiSort.Asc}
                />
              </Box>
            </Flex>
          ))}
          <Box>
            <Button
              leftIcon={<SmallAddIcon />}
              text={'Add Sort'}
              p={2}
              m={2}
              maxH={8}
              onClick={() =>
                setCurrentSortsInForm([
                  ...currentSortsInForm,
                  { desc: false, id: '' },
                ])
              }
            />
          </Box>
          <ButtonGroup
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
          >
            <Button
              text={'Cancel'}
              onClick={onClose}
              variant={'actionOutline'}
            />
            <Button
              text={'Apply'}
              isDisabled={disableSortApply}
              onClick={applySorts}
            />
          </ButtonGroup>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
