export const CustomerIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='33'
    height='32'
    viewBox='0 0 33 32'
    fill='none'
  >
    <rect
      x='0.857422'
      width='32'
      height='32'
      rx='4'
      fill='#4BDF95'
      fill-opacity='0.1'
    />
    <g clip-path='url(#clip0_313_2331)'>
      <path
        d='M16.8574 8C12.4574 8 8.85742 11.6 8.85742 16C8.85742 20.4 12.4574 24 16.8574 24C21.2574 24 24.8574 20.4 24.8574 16C24.8574 11.6 21.2574 8 16.8574 8ZM15.8574 19.4L12.4574 16L13.8574 14.6L15.8574 16.6L19.8574 12.6L21.2574 14L15.8574 19.4Z'
        fill='#4BDF95'
      />
    </g>
    <defs>
      <clipPath id='clip0_313_2331'>
        <rect
          width='16'
          height='16'
          fill='white'
          transform='translate(8.85742 8)'
        />
      </clipPath>
    </defs>
  </svg>
);
