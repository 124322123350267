import {AnalyticsAttrType} from 'shared/types/coreTypes.d';
import {Badge, BadgeProps, Box, Icon, IconProps} from '@chakra-ui/react';
import {ReactNode} from 'react';

type IconBadgeProps = AnalyticsAttrType & {
  badgeProps?: BadgeProps;
  iconComponent?: ReactNode;
  iconName?: string;
  iconProps?: IconProps;
  text: string;
};

const IconBadge = ({
  analyticsAttr,
  badgeProps,
  iconComponent,
  iconName,
  iconProps,
  text,
}: IconBadgeProps) => (
  <Box analytics-attr={analyticsAttr}>
    <Badge {...badgeProps}>{text}</Badge>
    {iconName && <Icon name={iconName} {...iconProps} />}
    {iconComponent}
  </Box>
);

export default IconBadge;
