import { useEffect, Dispatch, SetStateAction } from 'react';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import { Button, Container, Input } from 'shared/components/Core';
import { Flex, Stack, Text, useToast } from '@chakra-ui/react';
import * as ROUTES from 'app/src/constants/routes';
import { Redirect, useHistory } from 'react-router-dom';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import {
  ApiCrmTypeEnum,
  ApiDataSourceConfigInput,
  useCreateDataSourceConfigMutation,
} from 'shared/graphql/generatedApiTypes';
import { PENDO_LEARN_MORE_LINK } from 'app/src/components/Integrations/constants/integrationUrls';
import { jsonToBase64 } from 'app/src/components/Integrations/helpers/base64helpers';
import { IntegrationLocationState } from 'app/src/components/Integrations/components/IntegrationButton';
import { PendoLogo } from 'shared/components/Core/Icon/pendoLogo';
import AuthManager from 'shared/firebase/classes/AuthManager';

export const PendoIntegrationPage = (props) => {
  const queryParams = useQueryParams();
  const companyName =
    queryParams.get('company') !== null
      ? queryParams.get('company')
      : props.companyName;
  const klearlyUser = AuthManager.klearlyUser;
  useEffect(() => {
    updateAppHeaders(ROUTES.PENDO_INTEGRATION_PAGE.name);
  }, []);

  if (!companyName) {
    return <Redirect to={ROUTES.INTEGRATIONS_PAGE.path} />;
  }

  return (
    <Container ph={'xs'} pv={'4xl'} width={'sm'}>
      <Stack spacing={8}>
        <Flex align={'center'} flexDir={'column'}>
          <PendoLogo boxSize={40} />
          <Text
            textAlign={'center'}
            fontSize={'3xl'}
            fontWeight={'extrabold'}
            mt={-12}
          >
            Pendo Integration for {companyName}
          </Text>
        </Flex>
        <a
          href={PENDO_LEARN_MORE_LINK}
          rel={'noreferrer noopener'}
          target={'_blank'}
        >
          <Text
            fontSize={'sm'}
            mt={-8}
            textAlign={'center'}
            textDecoration={'underline'}
          >
            Learn more about this integration
          </Text>
        </a>
      </Stack>
      <FormBase companyName={companyName} setIsOpen={props.setIsOpen} />
    </Container>
  );
};

const FormBase = ({
  companyName,
  setIsOpen,
}: {
  companyName: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const toast = useToast();
  const history = useHistory();

  const locationState: IntegrationLocationState = {
    companyName,
    dataSource: ApiCrmTypeEnum.Pendo,
  };

  const [createDataSourceConfigMutation, { loading }] =
    useCreateDataSourceConfigMutation({
      onCompleted: () =>
        history.push(
          `${ROUTES.INTEGRATION_SUCCESS_PAGE.path}?state=${jsonToBase64(
            locationState,
          )}`,
        ),
      onError: () =>
        toast({
          title:
            'Error submitting integration data; please check your connection and try again.',
          isClosable: true,
          status: 'error',
          duration: null,
        }),
    });

  const { bind: bindPendoIntegrationKey, value: pendoIntegrationKey } =
    useInput('', {
      id: 'integration-key',
      label: 'Integration Key',
      name: 'integration-key',
      placeholder: 'Pendo Integration Key',
      required: true,
      type: 'text',
    }) as UseInputHookReturnType & UseInputHookStringValueTypes;

  const isSubmitButtonDisabled = !pendoIntegrationKey;

  const _onSubmit = async (event) => {
    event.preventDefault();
    const dataSourceConfig: Omit<
      ApiDataSourceConfigInput,
      | 'hubspotConfig'
      | 'salesforceConfig'
      | 'oauthCode'
      | 'marketoConfig'
      | 'pardotConfig'
    > = {
      companyName,
      dataSource: ApiCrmTypeEnum.Pendo,
      pendoConfig: {
        integrationKey: pendoIntegrationKey.trim(),
      },
    };
    createDataSourceConfigMutation({
      variables: {
        //@ts-ignore - don't have all params
        dataSourceConfig,
      },
    });
    if (setIsOpen !== undefined) {
      setIsOpen(false);
    }
  };

  return (
    <form onSubmit={_onSubmit} className={'c-form h-pv-lg'}>
      <Stack spacing={4}>
        <>
          <Input {...bindPendoIntegrationKey} />
        </>
        <Button
          isDisabled={isSubmitButtonDisabled}
          isLoading={loading}
          type={'submit'}
          text={'Submit'}
        />
      </Stack>
    </form>
  );
};
