import { ApiCrmTypeEnum } from 'shared/graphql/generatedApiTypes';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

export const getCapitalizedCrmTypeString = (
  dataSource: ApiCrmTypeEnum | string,
) => {
  switch (dataSource) {
    // separate out names with unusual casing
    case ApiCrmTypeEnum.Hubspot:
      return 'HubSpot';
    case ApiCrmTypeEnum.Salesforce:
      return 'SalesForce';
    // otherwise just capitalize like normal
    default:
      return cleanStringCase(dataSource);
  }
};
