import {Avatar, AvatarProps} from '@chakra-ui/react';
import {AnalyticsAttrType} from 'shared/types/coreTypes.d';

type AvatarType = AnalyticsAttrType &
  AvatarProps & {
    image?: string;
    text?: string;
  };

const StyledAvatar = ({
  image = undefined,
  text = undefined,
  ...props
}: AvatarType) => <Avatar {...props} src={image} name={text} />;

export default StyledAvatar;
