import { convertWinfluenceRankToText } from 'app/src/helpers/convertWinfluenceRankToText';
import { StatSummaryItem } from 'shared/firebase/schemas/SavedPage';
import { ApiGetAggregateDataQuery } from 'shared/graphql/generatedApiTypes';

export const statSummaryItemRenderer = (
  item: StatSummaryItem,
  accountsData: NonNullable<ApiGetAggregateDataQuery['aggregateData']>,
) => {
  const { avgWinfluence, totalIndustryCount, totalAccounts } = accountsData;

  switch (item) {
    case StatSummaryItem.totalAccounts:
      return {
        value: totalAccounts?.toLocaleString() ?? '',
        title: 'Total Companies',
        bottomText: '',
      };
    case StatSummaryItem.avgWinfluence:
      return {
        value: convertWinfluenceRankToText(avgWinfluence),
        title: 'Average Winfluence',
        lowerText: 'average',
      };
    case StatSummaryItem.totalAccountsWithoutClosedOpps:
      return {
        value: totalAccounts?.toLocaleString() ?? '0',
        title: 'Companies w/o Closed Opps',
        bottomText: '',
      };
    case StatSummaryItem.totalIndustries:
      return {
        value: totalIndustryCount?.toLocaleString(),
        title: 'Number of Industries',
        lowerText: '',
      };
    default:
      return null;
  }
};
