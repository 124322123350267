import { ApiGetAccountSignalsQuery } from 'shared/graphql/generatedApiTypes';

export const getQtrXeominTotal = (
  qtrAccountSignals: ApiGetAccountSignalsQuery | undefined,
) => {
  const filteredSignals = qtrAccountSignals?.accountSignals?.filter(
    (signal) =>
      signal &&
      signal.topicDetail === 'Xeomin' &&
      (signal.typeDetail === 'new' || signal.typeDetail === 'renewal'),
  );
  return filteredSignals?.reduce(
    (accum, item) => (item && item.quantity ? accum + item.quantity : accum),
    0,
  );
};
