import {Box, Flex} from '@chakra-ui/react';

export const PercentageBar = ({ value }: { value: any }) => {
  let adjustedValue = value;

  // handle if the value is a string to be able to multiply it
  if (typeof adjustedValue === 'string') {
    adjustedValue = parseFloat(value);
  }

  // now if it's not a number, it won't work so just return null
  if (Number.isNaN(adjustedValue) || typeof adjustedValue !== 'number') {
    return null;
  }

  return (
    <Flex align={'center'} mr={1} data-testid={'percentage-bar-icon'}>
      <Box>
        <Box
          borderRadius={100}
          h={3}
          w={`${adjustedValue * 80}px`}
          position={'absolute'}
          bg={'brand.gradient'}
        />
        <Box borderRadius={100} h={3} w={`80px`} bg={'brand.gray-400'} />
      </Box>
    </Flex>
  );
};
