import {memo} from 'react';
import _max from 'lodash/max';

import {Bar, BarChart, ReferenceLine, Tooltip, XAxis, YAxis} from 'recharts';
import {RechartsBase, RechartsTooltip} from './index';
import {RechartsTooltipPayloadType} from './RechartsBase';

type BulletChartType = {
  goal: number;
  high: number;
  low: number;
};

function getHighestY(goal: number, high: number, low: number) {
  const maxHighEndValue = 100;
  if (goal === 0 && high === 0 && low === 0) {
    return maxHighEndValue;
  }
  const maxValue = _max([goal, high, low]) || 0;
  const addOnValue = 3;
  if (maxValue + addOnValue >= maxHighEndValue) {
    return maxHighEndValue;
  }
  return maxValue + addOnValue;
}

const renderTooltipContent = (dataPayload: RechartsTooltipPayloadType) => {
  const expectedRange = dataPayload?.expectedRange;
  const recommendedGoal = dataPayload?.recommendedGoal;
  return (
    <>
      <p>{`Expected Range: ${expectedRange?.[0].toLocaleString()}-${expectedRange?.[1].toLocaleString()}`}</p>
      <p>{`Recommended Goal: ${recommendedGoal}`}</p>
    </>
  );
};

const BulletChart = memo(({ goal, high, low }: BulletChartType) => (
  <RechartsBase className={'bullet-chart'} height={75} width={'75%'}>
    <BarChart
      data={[{ name: '', expectedRange: [low, high], recommendedGoal: goal }]}
      layout={'vertical'}
    >
      <XAxis
        axisLine={false}
        domain={[0, getHighestY(goal, high, low)]}
        tickMargin={5}
        type={'number'}
      />
      <YAxis dataKey={'name'} hide type={'category'} />
      <Bar
        background
        dataKey={'expectedRange'}
        fill={'var(--color-gray-800)'}
      />
      <Tooltip
        content={<RechartsTooltip renderContent={renderTooltipContent} />}
      />
      <ReferenceLine
        isFront={true}
        stroke={'var(--color-klearly-blue)'}
        strokeWidth={2}
        x={goal}
      />
    </BarChart>
  </RechartsBase>
));

export default BulletChart;
