import { ApiGetAccountsWithQueryStringQuery } from 'shared/graphql/generatedApiTypes';
import _map from 'lodash/map';

export function cleanAccountSelectOptions(
  accounts: ApiGetAccountsWithQueryStringQuery['accountsForSearch'],
) {
  return _map(accounts, (account) => ({
    label: `${account?.accountName} (${account?.crmAccountId})`,
    value: account?.id ?? '',
  }));
}
