export const DownloadIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
  >
    <rect width='40' height='40' rx='20' fill='white' />
    <rect width='40' height='40' rx='20' fill='#2A96FC' fillOpacity='0.15' />
    <path
      d='M10 22H16V25H24V22H30'
      stroke='#323F5A'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M25 10H28C28.5304 10 29.0391 10.2107 29.4142 10.5858C29.7893 10.9609 30 11.4696 30 12V28C30 28.5304 29.7893 29.0391 29.4142 29.4142C29.0391 29.7893 28.5304 30 28 30H12C11.4696 30 10.9609 29.7893 10.5858 29.4142C10.2107 29.0391 10 28.5304 10 28V12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10H15'
      stroke='#323F5A'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20 9V19'
      stroke='#2A96FC'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24 15L20 19L16 15'
      stroke='#2A96FC'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
