import { FilterDictType } from '../types/filterDict';
import { OptionsType } from 'shared/types/coreTypes.d';
import { globalFilterVar } from 'app/src/apollo/rootReactiveVariables';
import _map from 'lodash/map';
import { RangerFiltersMinAndMax } from '../types/rangerFiltersMinAndMax';
import { FilterIndices } from '../types/filterTypes';
import _size from 'lodash/size';
import _isArray from 'lodash/isArray';
import {
  acquisitionBins,
  retentionBins,
  volumeBins,
} from 'app/src/components/GaugeChart/constants/gaugeBins';

// turns the account list into just an array of IDs on the filter, then adapts it to the backend
export const getAdaptedFilter = (keysToRemove?: FilterIndices[]) => {
  const globalFilterDict: FilterDictType & { accounts?: OptionsType[] } = {
    ...globalFilterVar(),
  };
  return adaptDictAsString(globalFilterDict, keysToRemove);
};

export const adaptDictAsString = (
  globalFilterDict: FilterDictType & { accounts?: OptionsType[] },
  keysToRemove?: FilterIndices[],
) => {
  const updatedDict = adaptDictToBackend({ ...globalFilterDict }, keysToRemove);
  return filterStringForBackendFromDict(updatedDict);
};

export const adaptDictAsDict = (
  globalFilterDict: FilterDictType & { accounts?: OptionsType[] },
  keysToRemove?: FilterIndices[],
) => adaptDictToBackend({ ...globalFilterDict }, keysToRemove);

const adaptDictToBackend = (
  globalFilterDict: FilterDictType & { accounts?: OptionsType[] },
  keysToRemove?: FilterIndices[],
) => {
  globalFilterDict = adaptPredictionsToPredictionTypeLevel(globalFilterDict);
  globalFilterDict = convertAccountsToIdsOnly(globalFilterDict);
  globalFilterDict = convertOtherSearchablesToIdsOnly(globalFilterDict);
  globalFilterDict = removeSpecifiedKeys(globalFilterDict, keysToRemove);
  globalFilterDict = adaptRangerFields(globalFilterDict);
  globalFilterDict = adaptSignalIntensityFields(globalFilterDict);
  globalFilterDict = removeEmptyArraysFromFilter(
    [
      FilterIndices.COUNTRY,
      FilterIndices.ENGAGEMENT_INTENSITY,
      FilterIndices.ENGAGEMENT_STATUS,
      FilterIndices.INDUSTRY,
      FilterIndices.OUTREACH_INTENSITY,
      FilterIndices.SIGNAL_TYPE_FILTER,
      FilterIndices.WINFLUENCE_ACTUAL,
      FilterIndices.PREDICTED_XEOMIN_PURCHASE_QUANTITY,
      FilterIndices.PREDICTED_XEOMIN_PURCHASE_PROPENSITY,
      FilterIndices.PREDICTED_XEOMIN_REORDER_PROPENSITY,
      FilterIndices.TERRITORY,
      FilterIndices.REGION,
      FilterIndices.AREA,
      FilterIndices.XEOMIN_TREND,
      FilterIndices.XEOMIN_PREDICTION_SUMMARY,
    ],
    globalFilterDict,
  );
  globalFilterDict = removeUndefinedValuesFromFilter(
    [
      FilterIndices.REVENUES_FILTER,
      FilterIndices.SIZES_FILTER,
      FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE,
    ],
    globalFilterDict,
  );
  globalFilterDict = removeNullValuesFromFilter(
    [FilterIndices.RECOMMENDED_ACTION],
    globalFilterDict,
  );
  globalFilterDict = cleanCountryFilter(globalFilterDict);
  return globalFilterDict;
};

const adaptPredictionsToPredictionTypeLevel = (
  globalFilterDict: FilterDictType,
) => {
  let predictionTypeLevel: string[] = [];
  if (
    globalFilterDict[FilterIndices.PREDICTED_XEOMIN_REORDER_PROPENSITY] &&
    globalFilterDict[FilterIndices.PREDICTED_XEOMIN_REORDER_PROPENSITY].length
  ) {
    globalFilterDict[FilterIndices.PREDICTED_XEOMIN_REORDER_PROPENSITY].forEach(
      (item) => {
        const foundKey = Object.keys(retentionBins).find(
          (key) => retentionBins[key] === item,
        );
        if (foundKey) {
          predictionTypeLevel.push(`retention:${foundKey}`);
        }
      },
    );
    delete globalFilterDict[FilterIndices.PREDICTED_XEOMIN_REORDER_PROPENSITY];
  }
  if (
    globalFilterDict[FilterIndices.PREDICTED_XEOMIN_PURCHASE_PROPENSITY] &&
    globalFilterDict[FilterIndices.PREDICTED_XEOMIN_PURCHASE_PROPENSITY].length
  ) {
    globalFilterDict[
      FilterIndices.PREDICTED_XEOMIN_PURCHASE_PROPENSITY
    ].forEach((item) => {
      const foundKey = Object.keys(acquisitionBins).find(
        (key) => acquisitionBins[key] === item,
      );
      if (foundKey) {
        predictionTypeLevel.push(`acquisition:${foundKey}`);
      }
    });
    delete globalFilterDict[FilterIndices.PREDICTED_XEOMIN_PURCHASE_PROPENSITY];
  }
  if (
    globalFilterDict[FilterIndices.PREDICTED_XEOMIN_PURCHASE_QUANTITY] &&
    globalFilterDict[FilterIndices.PREDICTED_XEOMIN_PURCHASE_QUANTITY].length
  ) {
    globalFilterDict[FilterIndices.PREDICTED_XEOMIN_PURCHASE_QUANTITY].forEach(
      (item) => {
        const foundKey = Object.keys(volumeBins).find(
          (key) => volumeBins[key] === item,
        );
        if (foundKey) {
          predictionTypeLevel.push(`volume:${foundKey}`);
        }
      },
    );
    delete globalFilterDict[FilterIndices.PREDICTED_XEOMIN_PURCHASE_QUANTITY];
  }
  if (predictionTypeLevel.length) {
    globalFilterDict.predictionTypeLevel = predictionTypeLevel;
  }
  return globalFilterDict;
};

export const convertAccountsToIdsOnly = (globalFilterDict: FilterDictType) => {
  const accountIds = globalFilterDict.accounts?.map((acct) => acct.value);
  if (accountIds?.length) {
    globalFilterDict.accountIds = accountIds;
  }
  delete globalFilterDict.accounts;
  return globalFilterDict;
};

export const convertOtherSearchablesToIdsOnly = (
  globalFilterDict: FilterDictType,
) => {
  // zip codes
  const zipCodes = globalFilterDict[FilterIndices.ADDRESS_ZIP_CODE] ?? [];
  if (zipCodes?.length) {
    globalFilterDict[FilterIndices.ADDRESS_ZIP_CODE] = zipCodes;
  }
  return globalFilterDict;
};

export const removeSpecifiedKeys = (
  globalFilterDict: FilterDictType,
  keysToRemove?: FilterIndices[],
) => {
  if (keysToRemove?.length) {
    keysToRemove.forEach((key) => {
      delete globalFilterDict[key];
    });
  }
  return globalFilterDict;
};

export const adaptRangerFields = (globalFilterDict: FilterDictType) => {
  if (globalFilterDict[FilterIndices.REVENUES_FILTER]) {
    if (
      globalFilterDict[FilterIndices.REVENUES_FILTER].max ===
      RangerFiltersMinAndMax.MAX
    ) {
      delete globalFilterDict[FilterIndices.REVENUES_FILTER].max;
    }
    if (
      globalFilterDict[FilterIndices.REVENUES_FILTER].min ===
      RangerFiltersMinAndMax.CLEAR
    ) {
      delete globalFilterDict[FilterIndices.REVENUES_FILTER].min;
    }
    if (!Object.keys(globalFilterDict[FilterIndices.REVENUES_FILTER]).length) {
      delete globalFilterDict[FilterIndices.REVENUES_FILTER];
    }
  }

  if (globalFilterDict[FilterIndices.SIZES_FILTER]) {
    if (
      globalFilterDict[FilterIndices.SIZES_FILTER].max ===
      RangerFiltersMinAndMax.MAX
    ) {
      delete globalFilterDict[FilterIndices.SIZES_FILTER].max;
    }
    if (
      globalFilterDict[FilterIndices.SIZES_FILTER].min ===
      RangerFiltersMinAndMax.CLEAR
    ) {
      delete globalFilterDict[FilterIndices.SIZES_FILTER].min;
    }
    if (!Object.keys(globalFilterDict[FilterIndices.SIZES_FILTER]).length) {
      delete globalFilterDict[FilterIndices.SIZES_FILTER];
    }
  }

  if (
    globalFilterDict[FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE] &&
    globalFilterDict[FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE]
      .min === 0 &&
    globalFilterDict[FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE]
      .max === 100
  ) {
    delete globalFilterDict[
      FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE
    ];
  }
  if (
    globalFilterDict[FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE] &&
    !Object.keys(
      globalFilterDict[FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE],
    ).length
  ) {
    delete globalFilterDict[
      FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE
    ];
  }
  if (
    globalFilterDict[FilterIndices.XEOMIN_AVERAGE_QUANTITY] &&
    globalFilterDict[FilterIndices.XEOMIN_AVERAGE_QUANTITY].min === 0 &&
    globalFilterDict[FilterIndices.XEOMIN_AVERAGE_QUANTITY].max === 100
  ) {
    delete globalFilterDict[FilterIndices.XEOMIN_AVERAGE_QUANTITY];
  }
  if (
    globalFilterDict[FilterIndices.XEOMIN_AVERAGE_QUANTITY] &&
    !Object.keys(globalFilterDict[FilterIndices.XEOMIN_AVERAGE_QUANTITY]).length
  ) {
    delete globalFilterDict[FilterIndices.XEOMIN_AVERAGE_QUANTITY];
  }
  if (
    globalFilterDict[FilterIndices.XEOMIN_PORTFOLIO_SPEND] &&
    globalFilterDict[FilterIndices.XEOMIN_PORTFOLIO_SPEND].min === 0 &&
    globalFilterDict[FilterIndices.XEOMIN_PORTFOLIO_SPEND].max === 100
  ) {
    delete globalFilterDict[FilterIndices.XEOMIN_PORTFOLIO_SPEND];
  }
  if (
    globalFilterDict[FilterIndices.XEOMIN_PORTFOLIO_SPEND] &&
    !Object.keys(globalFilterDict[FilterIndices.XEOMIN_PORTFOLIO_SPEND]).length
  ) {
    delete globalFilterDict[FilterIndices.XEOMIN_PORTFOLIO_SPEND];
  }
  return globalFilterDict;
};

export const adaptSignalIntensityFields = (
  globalFilterDict: FilterDictType,
) => {
  if (
    globalFilterDict[FilterIndices.ENGAGEMENT_INTENSITY] &&
    globalFilterDict[FilterIndices.ENGAGEMENT_INTENSITY].includes(
      'No Responses',
    )
  ) {
    const engagementIntensityValue =
      globalFilterDict[FilterIndices.ENGAGEMENT_INTENSITY];
    const noneIndex =
      _isArray(engagementIntensityValue) &&
      engagementIntensityValue.findIndex((item) => item === 'No Responses');
    if (noneIndex !== undefined && noneIndex !== false && noneIndex > -1) {
      engagementIntensityValue[noneIndex] = 'none';
    }
  }
  if (
    globalFilterDict[FilterIndices.OUTREACH_INTENSITY] &&
    globalFilterDict[FilterIndices.OUTREACH_INTENSITY].includes('No Activities')
  ) {
    const outreachIntensityValue =
      globalFilterDict[FilterIndices.OUTREACH_INTENSITY];
    const noneIndex =
      _isArray(outreachIntensityValue) &&
      outreachIntensityValue.findIndex((item) => item === 'No Activities');
    if (noneIndex !== undefined && noneIndex !== false && noneIndex > -1) {
      outreachIntensityValue[noneIndex] = 'none';
    }
  }
  return globalFilterDict;
};

export const removeEmptyArraysFromFilter = (
  indicesToRemove: FilterIndices[],
  globalFilterDict: FilterDictType,
) => {
  indicesToRemove.forEach((index) => {
    if (globalFilterDict[index] && globalFilterDict[index].length === 0) {
      delete globalFilterDict[index];
    }
  });
  return globalFilterDict;
};

export const removeUndefinedValuesFromFilter = (
  indicesToRemove: FilterIndices[],
  globalFilterDict: FilterDictType,
) => {
  indicesToRemove.forEach((index) => {
    if (globalFilterDict[index] === undefined) {
      delete globalFilterDict[index];
    }
  });
  return globalFilterDict;
};

export const removeNullValuesFromFilter = (
  indicesToRemove: FilterIndices[],
  globalFilterDict: FilterDictType,
) => {
  indicesToRemove.forEach((index) => {
    if (globalFilterDict[index] === null) {
      delete globalFilterDict[index];
    }
  });
  return globalFilterDict;
};

export const cleanCountryFilter = (globalFilterDict: FilterDictType) => {
  const countryFilterDictIndex = globalFilterDict[FilterIndices.COUNTRY];
  if (countryFilterDictIndex) {
    globalFilterDict[FilterIndices.COUNTRY] = _map(
      countryFilterDictIndex,
      (c) => c.replace(/_/g, ' '),
    );
  }
  return globalFilterDict;
};

export function filterStringForBackendFromDict(
  filterDict: object = {},
): string | undefined {
  if (_size(filterDict) === 0) {
    return undefined;
  }
  return JSON.stringify(filterDict);
}
