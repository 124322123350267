import { ReactElement } from 'react';
import { IconButton, Stack, Text, Box, Tooltip } from '@chakra-ui/react';
import { jsonToBase64 } from 'app/src/components/Integrations/helpers/base64helpers';
import { ApiCrmTypeEnum } from 'shared/graphql/generatedApiTypes';
import { InfoOutlineIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
const check = require('../../../assets/images/greentickmark.svg');

import moment from 'moment';

export type IntegrationLocationState = {
  businessUnitId?: string;
  companyName: string;
  dataSource: ApiCrmTypeEnum;
};

export type DataSourceState = {
  id?: number;
  companyId: number;
  createdByUserId: string;
  database: string;
  insertedAt: Date;
  portalDomain: string;
  portalId: string;
  prefix: string;
  source: string;
  stitchId: number;
  updatedAt: Date;
};

type OAuthButtonProps = {
  ariaLabel: string;
  icon: ReactElement;
  isDisabled: boolean;
  learnMoreLink: string;
  link: string;
  locationState: IntegrationLocationState;
  showTable: DataSourceState;
};

export const OAuthButton = ({
  ariaLabel,
  icon,
  isDisabled,
  learnMoreLink,
  link,
  locationState,
  showTable,
}: OAuthButtonProps) => {
  const encodedState = jsonToBase64(locationState);
  return (
    <Stack w={'26%'} align={'center'} mb={8}>
      <Box style={{ position: 'relative', borderBottom: 10 }}>
        <div>
          <a rel={'noreferrer noopener'} href={`${link}&state=${encodedState}`}>
            <IconButton
              _hover={{ bg: 'brand.gray-200' }}
              aria-label={ariaLabel}
              bg={'white'}
              borderColor={'#D1E1F1'}
              borderWidth={1}
              h={32}
              icon={icon}
              isDisabled={isDisabled}
              w={60}
              mb={0}
              borderRadius={'6px 6px 0 0'}
              borderBottom={'0px'}
            />
          </a>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 10,
              background: '#F1F9FE',
              borderRadius: '0 0 6px 6px',
              border: '1px solid #D1E1F1',
              borderTop: 'none',
            }}
          >
            {showTable !== null ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                  }}
                >
                  <img
                    alt={'Send'}
                    className={'comments-send-icon'}
                    src={check}
                  />

                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontWeight: '600',
                      fontSize: '16px',
                      lineHeight: '16px',
                      margin: 'auto',
                      color: '#25B291',
                    }}
                  >
                    Connected
                  </span>
                </div>
                <Tooltip
                  style={{ borderRadius: '5px' }}
                  label={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        gap: '5px',
                      }}
                    >
                      <Text textAlign={'center'} fontSize={'xl'}>
                        <span style={{ color: '#ABBED0' }}>Portal ID :</span>
                        {showTable?.portalId}
                      </Text>
                      <Text textAlign={'center'} fontSize={'xl'}>
                        <span style={{ color: '#ABBED0' }}>Last updated :</span>
                        {moment(showTable?.updatedAt).format('MM-DD-YYYY')}
                      </Text>
                      <Text textAlign={'center'} fontSize={'xl'}>
                        <span style={{ color: '#ABBED0' }}>Established :</span>{' '}
                        {moment(showTable?.insertedAt).format('MM-DD-YYYY')}
                      </Text>
                    </div>
                  }
                >
                  <InfoOutlineIcon w={5} h={5} color='black.500' />
                </Tooltip>
              </>
            ) : (
              <>
                <span
                  style={{
                    fontFamily: 'Lato',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '16px',
                    margin: 'auto',
                    color: '#143351',
                  }}
                >
                  Connect
                </span>
                <Tooltip
                  style={{ borderRadius: '5px' }}
                  label={'To a knowledge based article'}
                >
                  <QuestionOutlineIcon
                    w={5}
                    h={5}
                    color='black.500'
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(learnMoreLink);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </Box>
    </Stack>
  );
};

type IntegrationButtonProps = Pick<
  OAuthButtonProps,
  'ariaLabel' | 'icon' | 'isDisabled' | 'learnMoreLink' | 'showTable'
> & { onClick: () => void };

export const IntegrationButton = ({
  ariaLabel,
  icon,
  isDisabled,
  learnMoreLink,
  onClick,
  showTable,
}: IntegrationButtonProps) => {
  return (
    <Stack w={'26%'} align={'center'} mb={8}>
      <Box style={{ position: 'relative', borderBottom: 10 }}>
        <div>
          <IconButton
            _hover={{ bg: 'brand.gray-200' }}
            aria-label={ariaLabel}
            bg={'white'}
            borderColor={'#D1E1F1'}
            borderWidth={1}
            h={32}
            icon={icon}
            isDisabled={isDisabled}
            w={60}
            mb={0}
            borderRadius={'6px 6px 0 0'}
            borderBottom={'0px'}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 10,
              background: '#F1F9FE',
              borderRadius: '0 0 6px 6px',
              border: '1px solid #D1E1F1',
              borderTop: 'none',
            }}
          >
            {showTable !== null ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: '5px',
                  }}
                >
                  <img
                    alt={'Send'}
                    className={'comments-send-icon'}
                    src={check}
                  />
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontWeight: '600',
                      fontSize: '16px',
                      lineHeight: '16px',
                      margin: 'auto',

                      color: '#25B291',
                    }}
                  >
                    Connected
                  </span>
                </div>
                <Tooltip
                  label={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}
                    >
                      <Text textAlign={'center'} fontSize={'xl'}>
                        <span style={{ color: '#ABBED0' }}>Portal ID :</span>
                        {showTable?.portalId}
                      </Text>
                      <Text textAlign={'center'} fontSize={'xl'}>
                        <span style={{ color: '#ABBED0' }}>Last updated :</span>
                        {moment(showTable?.updatedAt).format('MM-DD-YYYY')}
                      </Text>
                      <Text textAlign={'center'} fontSize={'xl'}>
                        <span style={{ color: '#ABBED0' }}>Established :</span>{' '}
                        {moment(showTable?.insertedAt).format('MM-DD-YYYY')}
                      </Text>
                    </div>
                  }
                >
                  <InfoOutlineIcon w={5} h={5} color='black.500' />
                </Tooltip>
              </>
            ) : (
              <>
                <span
                  style={{
                    fontFamily: 'Lato',
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '16px',
                    margin: 'auto',
                    color: '#143351',
                  }}
                >
                  Connect
                </span>
                <Tooltip
                  style={{ borderRadius: '5px' }}
                  label={'To a knowledge based article'}
                >
                  <QuestionOutlineIcon
                    w={5}
                    h={5}
                    color='black.500'
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(learnMoreLink);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </Box>
    </Stack>
  );
};
