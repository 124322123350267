import { Redirect, Route } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import AuthManager from 'shared/firebase/classes/AuthManager';

export const ProtectedRoute = (props) => {
  const isAuthenticated = !!AuthManager.currentUser;
  return isAuthenticated ? (
    <Route {...props} render={props.render} />
  ) : (
    <Redirect to={ROUTES.LOG_IN} />
  );
};
