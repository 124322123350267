import _map from 'lodash/map';
import _maxBy from 'lodash/maxBy';
import _size from 'lodash/size';
import { Card } from '../../../../../shared/components/Core';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { EngagementItem } from './EngagementItem';
import { mapEngagementStatusDataToChart } from '../helpers/engagementStatusHelpers';
import SectionHeader from '../../../../../shared/components/Core/SectionHeader';
import { useReactiveVar } from '@apollo/client';
import { globalFilterVar } from 'app/src/apollo/rootReactiveVariables';
import { Box, Flex } from '@chakra-ui/react';
import { useGetAggregateDataWithoutEngagementStatusQuery } from 'shared/graphql/generatedApiTypes';

export type EngagementStatusProps = {
  sectionHeaderText: string;
  sectionHeaderSubtext?: string;
  sectionHeaderTooltip?: string;
  engagedTooltip: string;
  disengagingTooltip: string;
  disengagedTooltip: string;
  unengagedTooltip: string;
};

export const EngagementStatus = ({
  sectionHeaderText,
  sectionHeaderSubtext,
  sectionHeaderTooltip,
  ...tooltips
}: EngagementStatusProps) => {
  useReactiveVar(globalFilterVar);

  const { data, loading } = useGetAggregateDataWithoutEngagementStatusQuery({
    ...defaultQueryFetchPolicy,
    notifyOnNetworkStatusChange: true,
    //@ts-ignore: filter string is handled as a client export
    variables: {
      type: 'account',
    },
  });

  const mappedData = mapEngagementStatusDataToChart(data, tooltips);
  const maxYValue = _maxBy(mappedData, 'y')?.y || 100;

  return (
    <Card isLoading={loading}>
      <Box analytics-attr={'engagement-status'} minH={'380px'} pb={8}>
        <SectionHeader
          title={sectionHeaderText}
          subtitle={sectionHeaderSubtext}
          tooltip={sectionHeaderTooltip}
          showBackgroundColor={false}
          addHeaderLeftMargin
        />
        <Flex justify={'space-between'} flexWrap={'wrap'} px={6}>
          {_size(data) > 0 &&
            !loading &&
            _map(mappedData, (d, idx) => (
              <EngagementItem {...d} key={idx} maxYValue={maxYValue} />
            ))}
        </Flex>
      </Box>
    </Card>
  );
};
