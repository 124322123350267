import {InfoOutlineIcon} from '@chakra-ui/icons';
import {PercentageBar} from '../components/Core/PercentageBar';
import {getTrendIcon} from './helpers/getTrendIcon';
import {getPurchaseTrendIcon} from './helpers/getPurchaseTrendIcon';
import {getStatusCircleIcon} from './helpers/getStatusCircleIcon';

export enum IconRendererIcons {
  none = 'none',
  INFO = 'info',
  TREND = 'trend',
  PURCHASE_TREND = 'purchase_trend',
  PERCENTAGE_BAR = 'percentage_bar',
  STATUS_CIRCLE = 'status_circle',
}

type IconRendererProps = {
  iconName: IconRendererIcons;
  iconColor?: string;
  value: any;
};

export const IconRenderer = ({
  iconColor,
  iconName,
  value,
}: IconRendererProps) => {
  switch (iconName) {
    case IconRendererIcons.PURCHASE_TREND:
      return getPurchaseTrendIcon(value, iconColor);
    case IconRendererIcons.TREND:
      return getTrendIcon(value, iconColor);
    case IconRendererIcons.INFO:
      return <InfoOutlineIcon color={iconColor} data-testid={'info-icon'} />;
    case IconRendererIcons.PERCENTAGE_BAR:
      return <PercentageBar value={value} />;
    case IconRendererIcons.STATUS_CIRCLE:
      return getStatusCircleIcon(value);
    case IconRendererIcons.none:
      return null;
    default:
      return null;
  }
};
