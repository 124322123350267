import { HeatMap } from '../../../../../../shared/components/Core';
import { LabHeatMapProps } from '../../types/labChartTypes';
import { getLabsHeatMapDataValues } from '../../helpers/labChartHelpers';
import { pluralizeAccountsString } from 'shared/helpers/formatHelpers';

export const LabHeatMap = ({
  columns,
  isSmallerViewport = false,
  rows,
}: LabHeatMapProps) => {
  const {
    columns: heatMapCols,
    data: heatMapData,
    rows: heatMapRows,
  } = getLabsHeatMapDataValues({
    columns,
    rows,
  });
  return (
    <HeatMap
      columns={heatMapCols}
      columnsFormatter={(props) => props.label!.replace(' ', '\n')}
      data={heatMapData}
      gridHeight={isSmallerViewport ? 80 : 100}
      rows={heatMapRows}
      tooltipFormatter={(props) =>
        `${props.value?.toLocaleString()} ${pluralizeAccountsString(
          props.value?.toString(),
        )}`
      }
    />
  );
};
