import { useMemo, useState } from 'react';
import _size from 'lodash/size';

import {
  Icon,
  Inline,
  Table,
  Txt,
} from '../../../../../../shared/components/Core';
import { FilterIndices } from '../../../GlobalFilters/types/filterTypes';
import { Stack } from '@chakra-ui/react';
import { cleanStringCase, numberFormatter } from 'shared/helpers/formatHelpers';

type ClosedAccountsType = {
  accounts: Array<{}>;
  title: string;
};

const ClosedAccounts = ({ accounts = [], title = '' }: ClosedAccountsType) => {
  // setup state
  const [showAccounts, setShowAccounts] = useState(false);
  // setup table columns and data
  const tableColumns = useMemo(
    () => [
      {
        Header: 'All Accounts',
        columns: [
          {
            Cell: ({ value }) => (value && _size(value) > 0 ? value : '-'),
            Header: 'Account Name',
            accessor: FilterIndices.ACCOUNT_NAME,
            analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
          },
          {
            Cell: ({ value }) => (value ? cleanStringCase(value) : '-'),
            Header: 'Industry',
            accessor: FilterIndices.INDUSTRY,
            analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
          },
          {
            Cell: ({ value }) => numberFormatter(value, true),
            Header: 'Revenue',
            accessor: FilterIndices.REVENUES_FILTER,
            analyticsAttrIndex: FilterIndices.ACCOUNT_NAME,
          },
        ],
      },
    ],
    [],
  );
  const tableData = useMemo(() => accounts, [accounts]);
  // render
  return (
    <Stack spacing={4}>
      <Inline alignItems={'center'} gap={'sm'}>
        <Txt as={'h1'} className={'h-color-bold'} size={'xl'} weight={'bold'}>
          {title}
        </Txt>
        <div
          className={'h-pointer'}
          onClick={() => setShowAccounts(!showAccounts)}
        >
          <Icon name={showAccounts ? 'io-minus' : 'io-add'} />
        </div>
      </Inline>
      {showAccounts && (
        <Table
          columns={tableColumns}
          data={tableData}
          initialDefaultSortByColumnId={'accountName'}
          tableVPadding={'sm'}
        />
      )}
    </Stack>
  );
};

export default ClosedAccounts;
