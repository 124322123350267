import {
  CompaniesIcon,
  SelectedCompaniesIcon,
} from '../components/Core/Icon/companies';
import {
  BarChartIcon,
  SelectedBarChartIcon,
} from '../components/Core/Icon/barChart';
import {
  LibraryIcon,
  SelectedLibraryIcon,
} from '../components/Core/Icon/library';
import {
  MessagesIcon,
  SelectedMessagesIcon,
} from '../components/Core/Icon/messages';
import {
  PeopleIcon,
  SelectedPeopleIcon,
} from 'shared/components/Core/Icon/people';
import { HomeIcon, SelectedHomeIcon } from '../components/Core/Icon/home';

export enum NavbarIcon {
  none = 'none',
  COMPANIES = 'companies',
  REPORTS = 'reports',
  LISTS = 'lists',
  MESSAGES = 'messages',
  PEOPLE = 'people',
  HOME = 'home',
}

export const NavbarIconRenderer = (iconName: NavbarIcon, isActive: boolean) => {
  switch (iconName) {
    case NavbarIcon.COMPANIES:
      return isActive ? (
        <SelectedCompaniesIcon boxSize={7} />
      ) : (
        <CompaniesIcon boxSize={7} />
      );
    case NavbarIcon.REPORTS:
      return isActive ? (
        <SelectedBarChartIcon boxSize={7} />
      ) : (
        <BarChartIcon boxSize={7} />
      );
    case NavbarIcon.LISTS:
      return isActive ? (
        <SelectedLibraryIcon boxSize={7} />
      ) : (
        <LibraryIcon boxSize={7} />
      );
    case NavbarIcon.MESSAGES:
      return isActive ? (
        <SelectedMessagesIcon boxSize={7} />
      ) : (
        <MessagesIcon boxSize={7} />
      );
    case NavbarIcon.PEOPLE:
      return isActive ? (
        <SelectedPeopleIcon boxSize={7} />
      ) : (
        <PeopleIcon boxSize={7} />
      );
    case NavbarIcon.HOME:
      return isActive ? (
        <SelectedHomeIcon boxSize={7} />
      ) : (
        <HomeIcon boxSize={7} />
      );
    default:
      return null;
  }
};
