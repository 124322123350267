export const PastCustomerIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='33'
    height='32'
    viewBox='0 0 33 32'
    fill='none'
  >
    <rect
      x='0.857422'
      width='32'
      height='32'
      rx='4'
      fill='#FD405C'
      fill-opacity='0.1'
    />
    <g clip-path='url(#clip0_313_2379)'>
      <path
        d='M16.8574 23C15.473 23 14.1196 22.5895 12.9684 21.8203C11.8173 21.0511 10.9201 19.9579 10.3903 18.6788C9.86045 17.3997 9.72183 15.9922 9.99193 14.6344C10.262 13.2765 10.9287 12.0292 11.9077 11.0503C12.8866 10.0713 14.1339 9.4046 15.4918 9.13451C16.8497 8.86441 18.2571 9.00303 19.5362 9.53285C20.8153 10.0627 21.9085 10.9599 22.6777 12.111C23.4469 13.2622 23.8574 14.6155 23.8574 16C23.8553 17.8559 23.1171 19.6351 21.8048 20.9474C20.4925 22.2597 18.7133 22.9979 16.8574 23ZM16.8574 11C15.8685 11 14.9018 11.2932 14.0796 11.8427C13.2573 12.3921 12.6165 13.173 12.238 14.0866C11.8596 15.0002 11.7606 16.0055 11.9535 16.9755C12.1464 17.9454 12.6226 18.8363 13.3219 19.5355C14.0212 20.2348 14.9121 20.711 15.882 20.9039C16.8519 21.0969 17.8572 20.9978 18.7708 20.6194C19.6845 20.241 20.4654 19.6001 21.0148 18.7779C21.5642 17.9556 21.8574 16.9889 21.8574 16C21.8558 14.6744 21.3285 13.4036 20.3912 12.4662C19.4539 11.5289 18.183 11.0016 16.8574 11Z'
        fill='#FD405C'
      />
      <path
        d='M9.85721 23.9999C9.65946 23.9999 9.46616 23.9412 9.30175 23.8313C9.13734 23.7214 9.0092 23.5653 8.93353 23.3826C8.85786 23.1999 8.83806 22.9988 8.87663 22.8049C8.91519 22.6109 9.0104 22.4328 9.15021 22.2929L23.1502 8.29292C23.3388 8.11076 23.5914 8.00997 23.8536 8.01224C24.1158 8.01452 24.3666 8.11969 24.552 8.3051C24.7374 8.49051 24.8426 8.74132 24.8449 9.00352C24.8472 9.26571 24.7464 9.51832 24.5642 9.70692L10.5642 23.7069C10.3767 23.8945 10.1224 23.9999 9.85721 23.9999Z'
        fill='#FD405C'
      />
    </g>
    <defs>
      <clipPath id='clip0_313_2379'>
        <rect
          width='16'
          height='16'
          fill='white'
          transform='translate(8.85742 8)'
        />
      </clipPath>
    </defs>
  </svg>
);
