import _size from 'lodash/size';
import _omit from 'lodash/omit';
import { FilterIndices } from '../types/filterTypes';
import {
  ApiGetAggregateDataQuery,
  ApiGetAggregateDataWithFilterVarQuery,
} from 'shared/graphql/generatedApiTypes';
import { GlobalFilterReducerState } from '../reducers/globalFilterReducer';
import { adaptDictAsDict } from './adaptFilterForQuery';
import { FilterDictType } from '../types/filterDict';

type GetFilterItemConstsParams = {
  filterIndices: FilterIndices[];
  filterItemAggregatedAccountsData:
    | ApiGetAggregateDataWithFilterVarQuery
    | undefined;
  filterItemAggregatedAccountsDataLoading: boolean;
  globalFilter: GlobalFilterReducerState;
  initialAggregatedAccountsData: ApiGetAggregateDataQuery | undefined;
  initialAggregatedAccountsDataLoading: boolean;
};

export const getFilterItemConsts = ({
  filterIndices,
  filterItemAggregatedAccountsData,
  filterItemAggregatedAccountsDataLoading,
  globalFilter,
  initialAggregatedAccountsDataLoading,
}: GetFilterItemConstsParams) => {
  const cleanedFilterDict = _omit(
    adaptDictAsDict(globalFilter as FilterDictType),
    [
      FilterIndices.ACCOUNTS_FILTER_TYPE,
      FilterIndices.EXCLUDED_ACCOUNT_IDS,
      ...filterIndices,
    ],
  );

  const aggregatedItemsAccountData =
    filterItemAggregatedAccountsData?.aggregateData;

  const isSelectedDisabled =
    initialAggregatedAccountsDataLoading ||
    filterItemAggregatedAccountsDataLoading;
  const areFiltersApplied = _size(cleanedFilterDict) > 0;
  return {
    aggregatedItemsAccountData,
    areFiltersApplied,
    isSelectedDisabled,
  };
};
