import { AccountInfoProps } from '../types/AccountInfoProps';
import _clamp from 'lodash/clamp';

export const getWinfluencePriorityDetails = ({
  accountInfo,
}: AccountInfoProps) => {
  if (!accountInfo) {
    return {
      title: '',
      subText: '',
      priorityLift: 0,
      nonPriorityLift: 0,
    };
  }

  const priorityLift = accountInfo?.rankPriority || 0;
  const nonPriorityLift = accountInfo?.rankAll || 0;
  const nonPriorityValueDifference = Math.max(
    0,
    nonPriorityLift - priorityLift,
  );
  const title =
    winfluencePriorityDetailsTitles.find(
      (item) =>
        item.index ===
        `title_${_clamp(priorityLift + nonPriorityValueDifference, 5)}`,
    )?.text ?? '';
  const subText =
    winfluencePriorityDetailsSubtitles.find(
      (item) =>
        item.index === `subtext_${priorityLift}_${nonPriorityValueDifference}`,
    )?.subtext ?? '';

  return {
    title,
    subText,
    priorityLift,
    nonPriorityLift,
  };
};

const winfluencePriorityDetailsTitles = [
  {
    text: 'None',
    index: 'title_0',
  },
  {
    index: 'title_1',
    text: 'Very Low',
  },
  {
    index: 'title_2',
    text: 'Low',
  },
  {
    text: 'Moderate',
    index: 'title_3',
  },
  {
    text: 'High',
    index: 'title_4',
  },
  {
    text: 'Very High',
    index: 'title_5',
  },
];

const winfluencePriorityDetailsSubtitles = [
  {
    index: 'subtext_0_0',
    subtext:
      "You cannot influence this account's win probability by taking any actions.",
  },
  {
    index: 'subtext_0_1',
    subtext:
      "You can very minimally influence this account's win probability by taking all recommended actions.",
  },
  {
    subtext:
      "You can minimally influence this account's win probability by taking all recommended actions.",
    index: 'subtext_0_2',
  },
  {
    subtext:
      "You can moderately influence this account's win probability by taking all recommended actions.",
    index: 'subtext_0_3',
  },
  {
    subtext:
      "You can strongly influence this account's win probability by taking all recommended actions.",
    index: 'subtext_0_4',
  },
  {
    index: 'subtext_0_5',
    subtext:
      "You can very strongly influence this account's win probability by taking all recommended actions.",
  },
  {
    subtext:
      "You can very minimally influence this account's win probability by taking the primary action.",
    index: 'subtext_1_0',
  },
  {
    subtext:
      "You can minimally influence this account's win probability by taking all recommended actions.",
    index: 'subtext_1_1',
    text: '',
  },
  {
    subtext:
      "You can moderately influence this account's win probability by taking all recommended actions.",
    index: 'subtext_1_2',
  },
  {
    subtext:
      "You can strongly influence this account's win probability by taking all recommended actions.",
    index: 'subtext_1_3',
  },
  {
    subtext:
      "You can very strongly influence this account's win probability by taking all recommended actions.",
    index: 'subtext_1_4',
  },
  {
    index: 'subtext_2_0',
    subtext:
      "You can minimally influence this account's win probability by taking the primary action.",
  },
  {
    index: 'subtext_2_1',
    subtext:
      "You can moderately influence this account's win probability by taking all recommended actions.",
  },
  {
    subtext:
      "You can strongly influence this account's win probability by taking all recommended actions.",
    index: 'subtext_2_2',
    text: '',
  },
  {
    index: 'subtext_2_3',
    subtext:
      "You can very strongly influence this account's win probability by taking all recommended actions.",
  },
  {
    index: 'subtext_3_0',
    subtext:
      "You can moderately influence this account's win probability by taking the primary action.",
  },
  {
    index: 'subtext_3_1',
    subtext:
      "You can strongly influence this account's win probability by taking all recommended actions.",
  },
  {
    subtext:
      "You can very strongly influence this account's win probability by taking all recommended actions.",
    index: 'subtext_3_2',
  },
  {
    index: 'subtext_4_0',
    subtext:
      "You can strongly influence this account's win probability by taking the primary action.",
  },
  {
    index: 'subtext_4_1',
    subtext:
      "You can very strongly influence this account's win probability by taking all recommended actions.",
  },
  {
    subtext:
      "You can very strongly influence this account's win probability by taking the primary action.",
    index: 'subtext_5_0',
  },
];
