import { createIcon } from '@chakra-ui/icon';

export const NewLogo = createIcon({
  displayName: 'NewLogo',
  viewBox: '0 0 33 16',
  path: (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 180.8 91.62'>
      <defs />
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            id='Path_18560'
            data-name='Path 18560'
            fill='#FD5B5E'
            d='M14.76,86.77a4.7,4.7,0,1,1,9.39-.27v.1h0a4.7,4.7,0,0,1-4.61,4.78h-.08a4.7,4.7,0,0,1-4.7-4.62Z'
          />
          <path
            fill='#FD5B5E'
            d='M94.25,1.82A1.81,1.81,0,0,1,96.05,0h20.44a1.82,1.82,0,0,1,1.81,1.81V31.74a1.81,1.81,0,0,0,1.8,1.81,1.77,1.77,0,0,0,1.39-.64L148.4.66A1.8,1.8,0,0,1,149.78,0h26.4A1.82,1.82,0,0,1,178,1.83a1.78,1.78,0,0,1-.46,1.2L147.91,36.09a1.81,1.81,0,0,0-.17,2.2l32.77,50.53a1.81,1.81,0,0,1-1.52,2.8H150.85a1.8,1.8,0,0,1-1.52-.84L129.75,59.91a1.82,1.82,0,0,0-2.49-.57,2,2,0,0,0-.38.33l-8.11,9a1.8,1.8,0,0,0-.47,1.21v20a1.79,1.79,0,0,1-1.8,1.81H96.06a1.82,1.82,0,0,1-1.81-1.82h0Z'
          />
          <path
            id='Path_18562'
            data-name='Path 18562'
            fill='#FD5B5E'
            d='M61.13,57.17A14.2,14.2,0,1,1,76.57,44.33v0h0a14.18,14.18,0,0,1-14.1,12.88,12.34,12.34,0,0,1-1.35-.07Z'
          />
          <path
            id='Path_18563'
            data-name='Path 18563'
            fill='#FD5B5E'
            d='M63.07,80.94a10.85,10.85,0,0,1,10.65-11h0a10.85,10.85,0,0,1,11,10.67h0A10.85,10.85,0,0,1,74.11,91.62h-.2A10.86,10.86,0,0,1,63.07,80.94Z'
          />
          <path
            id='Path_18564'
            data-name='Path 18564'
            fill='#FD5B5E'
            d='M64.57,11.63A9.59,9.59,0,0,1,71.87.22h0A9.58,9.58,0,0,1,76,18.94h0a9.55,9.55,0,0,1-11.37-7.28v0Z'
          />
          <path
            id='Path_18565'
            data-name='Path 18565'
            fill='#FD5B5E'
            d='M35.35,21.12a6.93,6.93,0,1,1,7.81,5.93h0a6,6,0,0,1-.95.07,7,7,0,0,1-6.86-6Z'
          />
          <path
            id='Path_18566'
            data-name='Path 18566'
            fill='#FD5B5E'
            d='M25.3,67.1A11.76,11.76,0,0,1,35.91,54.29h0A11.75,11.75,0,0,1,48.69,64.92h0a11.77,11.77,0,0,1-10.62,12.8h0c-.37,0-.73.05-1.09.05A11.76,11.76,0,0,1,25.29,67.1Z'
          />
          <path
            id='Path_18567'
            data-name='Path 18567'
            fill='#FD5B5E'
            d='M17.92,40.29a7.8,7.8,0,0,1,7.65-7.95h0A7.81,7.81,0,0,1,33.52,40h0a7.81,7.81,0,0,1-7.67,8h-.14a7.8,7.8,0,0,1-7.79-7.69Z'
          />
          <path
            id='Path_18568'
            data-name='Path 18568'
            fill='#FD5B5E'
            d='M5.85,12.2a7.1,7.1,0,0,1,7-7.23h0a7.1,7.1,0,0,1,.25,14.2h-.13a7.09,7.09,0,0,1-7.07-7Z'
          />
          <path
            id='Union_2'
            data-name='Union 2'
            fill='#FD5B5E'
            d='M.05,55.3a5.69,5.69,0,0,1,4.86-6.4h0a5.67,5.67,0,0,1,6.39,4.85h0a5.68,5.68,0,0,1-4.85,6.41,6.81,6.81,0,0,1-.78,0A5.67,5.67,0,0,1,.05,55.3Z'
          />
        </g>
      </g>
    </svg>
  ),
});
