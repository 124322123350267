import {
  ApiAccountGroup,
  ApiGetAllAccountGroupsQuery,
  ApiUser,
  Maybe,
} from 'shared/graphql/generatedApiTypes';
import { KlearlyUserType } from 'shared/firebase/types/klearlyUserType';
import _filter from 'lodash/filter';
import _map from 'lodash/map';

export type GroupAccountGroupsParams = {
  accountGroups: ApiGetAllAccountGroupsQuery['accountGroups'];
  klearlyUser: KlearlyUserType;
  showOnlyLoggedInUsersGroups?: boolean;
};

export type AccountGroupWithLoggedInUser = Array<
  Maybe<
    Pick<
      ApiAccountGroup,
      | 'id'
      | 'title'
      | 'description'
      | 'filterString'
      | 'createdAt'
      | 'accountsUpdatedAt'
      | 'isHidden'
      | 'accountCount'
    > & {
      user: Pick<ApiUser, 'id' | 'email' | 'firstName' | 'lastName'>;
      isLoggedInUsers: boolean;
    }
  >
>;

export function groupAccountGroups({
  accountGroups = [],
  klearlyUser,
  showOnlyLoggedInUsersGroups = false,
}: GroupAccountGroupsParams): {
  deleted: AccountGroupWithLoggedInUser;
  groups: AccountGroupWithLoggedInUser;
  lists: AccountGroupWithLoggedInUser;
} {
  // the check here allows us to assert non nullable
  const nonNullableAccountGroups = accountGroups
    ? (accountGroups as NonNullable<
        ApiGetAllAccountGroupsQuery['accountGroups']
      >)
    : [];
  const filteredDeletedGroups = _filter(
    nonNullableAccountGroups,
    (g) => g?.isHidden && g?.user?.id === klearlyUser?.id,
  ) as AccountGroupWithLoggedInUser;
  const filteredGroupGroups = _filter(
    nonNullableAccountGroups,
    (g) => !g?.isHidden && g?.filterString,
  ) as AccountGroupWithLoggedInUser;
  const filteredListGroups = _filter(
    nonNullableAccountGroups,
    (g) => !g?.isHidden && !g?.filterString,
  ) as AccountGroupWithLoggedInUser;
  if (showOnlyLoggedInUsersGroups) {
    return {
      deleted: filteredDeletedGroups,
      groups: _filter(
        filteredGroupGroups,
        (g) => g?.user?.id === klearlyUser?.id,
      ).map((group) => ({
        ...group,
        isLoggedInUsers: true,
      })) as AccountGroupWithLoggedInUser,
      lists: _filter(
        filteredListGroups,
        (g) => g?.user?.id === klearlyUser?.id,
      ).map((group) => ({
        ...group,
        isLoggedInUsers: true,
      })) as AccountGroupWithLoggedInUser,
    };
  }

  return {
    deleted: filteredDeletedGroups,
    groups: _map(filteredGroupGroups, (g) => ({
      ...g,
      isLoggedInUsers: g?.user?.id === klearlyUser?.id,
    })) as AccountGroupWithLoggedInUser,
    lists: _map(filteredListGroups, (l) => ({
      ...l,
      isLoggedInUsers: l?.user?.id === klearlyUser?.id,
    })) as AccountGroupWithLoggedInUser,
  };
}
