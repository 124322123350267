import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import _includes from 'lodash/includes';
import { BackToLogInLink } from '../PasswordForgot';
import * as ROUTES from '../../constants/routes';

import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';

import {
  Button,
  Container,
  Input,
  Txt,
} from '../../../../shared/components/Core';
import { Box, Stack, Text } from '@chakra-ui/react';
import { isNewPasswordValid } from 'shared/helpers/validationHelpers';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import AuthManager from 'shared/firebase/classes/AuthManager';

const PasswordResetPage = () => {
  useEffect(() => {
    updateAppHeaders('Reset Password');
  }, []);
  return (
    <Container width={'xs'} pv={'4xl'} ph={'xs'}>
      <Stack spacing={10}>
        <Txt align={'center'} size={'3xl'} weight={'heavy'}>
          {'Set your Password'}
        </Txt>
        <ResetForm />
      </Stack>
    </Container>
  );
};

const ResetForm = () => {
  const params = useLocation();
  const search = new URLSearchParams(params.search);
  const oobCode = search.get('oobCode') || '';
  const history = useHistory();
  if (!oobCode) {
    history.push('/');
  }
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>();
  const [isExpiredCodeError, setIsExpiredCodeError] = useState<boolean>(false);
  const {
    bind: bindPassword,
    reset: resetPassword,
    value: password,
  } = useInput('', {
    autoComplete: 'new-password',
    error: error?.message,
    id: 'password',
    label: 'Password',
    name: 'password',
    placeholder: 'ex. ••••••••••',
    type: 'password',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const isSetPasswordButtonDisabled = !isNewPasswordValid(password);

  const _onSubmit = (event) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);
    setSuccessText(undefined);
    setIsExpiredCodeError(false);
    return AuthManager.confirmPasswordReset(oobCode, password)
      .then(() => {
        resetPassword();
        setError(undefined);
        setLoading(false);
        setSuccessText('Setting your password was successful.');
      })
      .catch((error) => {
        if (
          _includes(
            ['auth/expired-action-code', 'auth/invalid-action-code'],
            error.code,
          )
        ) {
          setIsExpiredCodeError(true);
        } else {
          setError(error);
          setLoading(false);
        }
      });
  };

  if (isExpiredCodeError) {
    return (
      <Stack spacing={4}>
        <Txt>
          {
            'Your link has expired. Please enter your email address so we can send you a new link.'
          }
        </Txt>
        <Txt size={'sm'} align={'center'} theme={'active'}>
          <Link to={ROUTES.PASSWORD_FORGOT} className={'c-link c-link--active'}>
            {'Click here to request a new code.'}
          </Link>
        </Txt>
      </Stack>
    );
  }

  if (successText) {
    return (
      <Stack spacing={4}>
        <Txt align={'center'}>{successText}</Txt>
        <Txt size={'sm'} align={'center'} theme={'active'}>
          <Link to={ROUTES.LOG_IN} className={'c-link c-link--active'}>
            {'Click here to login.'}
          </Link>
        </Txt>
      </Stack>
    );
  }

  return (
    <Box textAlign={'center'}>
      <form onSubmit={_onSubmit} className={'c-form h-pv-lg'}>
        <Input {...bindPassword} />
        <Text fontSize={'md'} mb={2}>
          Password must contain a lowercase letter, an uppercase letter, a
          number, and at least one of the following characters (@$!%*?#&).
          Minimum length 8.
        </Text>
        <Button
          isDisabled={isSetPasswordButtonDisabled}
          isLoading={loading}
          text={'Set Password'}
          type={'submit'}
          variant={'active'}
          mt={4}
        />
      </form>
      <BackToLogInLink />
    </Box>
  );
};

export default PasswordResetPage;
