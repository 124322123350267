import { useMemo } from 'react';
import {
  IndeterminateCheckbox,
  Tooltip,
  Txt,
} from '../../../../../shared/components/Core';
import {
  RecommendationTableIndices,
  RecommendedActionsTableText,
} from '../types/homeTypes';
import _startCase from 'lodash/startCase';
import { Text } from '@chakra-ui/react';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { convertWinfluenceRankToText } from 'app/src/helpers/convertWinfluenceRankToText';

export const useRecommendationsTableColumns = (
  text: RecommendedActionsTableText,
) =>
  useMemo(
    () => [
      {
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to render a checkbox
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            analyticsAttr={cleanAnalyticsStringList([
              'Recommended Actions',
              row.original[RecommendationTableIndices.SIGNAL_TYPE_HEADER].value,
            ])}
          />
        ),
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            {...getToggleAllRowsSelectedProps()}
            analyticsAttr={'Recommended Actions'}
          />
        ),
        accessor: 'id',
        analyticsAttrIndex: RecommendationTableIndices.SIGNAL_TYPE_HEADER,
        disableSortBy: true,
      },
      {
        Header: text.sectionHeaderText,
        columns: [
          {
            Cell: ({ value }) => _startCase(value),
            Header: (
              <Tooltip
                analyticsAttr={text.signalTypeHeaderText}
                content={
                  text.signalTypeHeaderTooltip ? (
                    <Text fontSize={'sm'}>{text.signalTypeHeaderTooltip}</Text>
                  ) : null
                }
                text={text.signalTypeHeaderText}
              />
            ),
            accessor: RecommendationTableIndices.SIGNAL_TYPE_HEADER,
            analyticsAttrIndex: RecommendationTableIndices.SIGNAL_TYPE_HEADER,
          },
          {
            Cell: ({ value }) => value.toLocaleString(),
            Header: (
              <Tooltip
                analyticsAttr={text.accountsHeaderText}
                content={
                  text.accountsHeaderTooltip ? (
                    <Txt>{text.accountsHeaderTooltip}</Txt>
                  ) : null
                }
                text={text.accountsHeaderText}
              />
            ),
            accessor: RecommendationTableIndices.ACCOUNTS_HEADER,
            analyticsAttrIndex: RecommendationTableIndices.ACCOUNTS_HEADER,
          },
          {
            Cell: ({ value }) => value.toLocaleString(),
            Header: (
              <Tooltip
                analyticsAttr={text.actionHeaderText}
                content={
                  text.actionHeaderTooltip ? (
                    <Txt>{text.actionHeaderTooltip}</Txt>
                  ) : null
                }
                text={text.actionHeaderText}
              />
            ),
            accessor: RecommendationTableIndices.ACTUAL_HEADER,
            analyticsAttrIndex: RecommendationTableIndices.ACTUAL_HEADER,
          },
          {
            Cell: ({ value }) => value.toLocaleString(),
            Header: (
              <Tooltip
                analyticsAttr={text.goalHeaderText}
                content={
                  text.goalHeaderTooltip ? (
                    <Txt>{text.goalHeaderTooltip}</Txt>
                  ) : null
                }
                text={text.goalHeaderText}
              />
            ),
            accessor: RecommendationTableIndices.GOAL_HEADER,
            analyticsAttrIndex: RecommendationTableIndices.GOAL_HEADER,
          },
          {
            Cell: ({ value }) =>
              value > 0 ? `+${value.toFixed(0)}` : value.toFixed(0),
            Header: (
              <Tooltip
                analyticsAttr={text.changeHeaderText}
                content={
                  text.changeHeaderTooltip ? (
                    <Txt>{text.changeHeaderTooltip}</Txt>
                  ) : null
                }
                text={text.changeHeaderText}
              />
            ),
            accessor: RecommendationTableIndices.CHANGE_HEADER,
            analyticsAttrIndex: RecommendationTableIndices.CHANGE_HEADER,
          },
          {
            Cell: ({ value }) => convertWinfluenceRankToText(value),
            Header: (
              <Tooltip
                analyticsAttr={text.averageWinfluenceText}
                content={
                  text.averageWinfluenceTooltip ? (
                    <Txt>{text.averageWinfluenceTooltip}</Txt>
                  ) : null
                }
                text={text.averageWinfluenceText}
              />
            ),
            accessor: RecommendationTableIndices.AVERAGE_WINFLUENCE_HEADER,
            analyticsAttrIndex:
              RecommendationTableIndices.AVERAGE_WINFLUENCE_HEADER,
            sortType: 'number',
          },
        ],
      },
    ],
    [text],
  );
