import { Card, Tooltip } from 'shared/components/Core';
import SectionHeader from 'shared/components/Core/SectionHeader';
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { GaugeByPredictionType } from './GaugeByPredictionType';
import { useContext, useEffect, useMemo } from 'react';
import { AppNavigationalStructureContext } from 'app/src/context/AppNavigationalStructureContext';
import { getChartNarratives } from '../helpers/getChartNarratives';
import { getQtrXeominTotal } from '../helpers/getQtrXeominTotal';
import { mapDropdownTimeInputToDates } from '../../Account/helpers/singleAccountSignalHelpers';
import { SingleAccountSignalsTimePeriod } from '../../Account/types/SingleAccountSignalsTypes';
import {
  ApiPredictionForType,
  useGetAccountQuery,
  useGetAccountSignalsLazyQuery,
} from 'shared/graphql/generatedApiTypes';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import {
  cleanStringCase,
  formatDateString,
} from 'shared/helpers/formatHelpers';
import { useRemark } from 'react-remark';
import { VolumeBarChart } from 'app/src/components/GaugeChart/components/VolumeBarChart';
import { CheckIcon, InfoIcon } from '@chakra-ui/icons';

export type SingleProductPredictionsSectionProps = {
  title: string;
  subtitle: string;
  tooltip?: string;
};

export const SingleProductPredictionsSection = ({
  title,
  subtitle,
  tooltip,
}: SingleProductPredictionsSectionProps) => {
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const { structure } = useContext(AppNavigationalStructureContext);
  const fiscalYearStartingMonth = structure?.fiscalYearStartingMonth ?? 0;
  const { data, loading } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });
  const [predictionsContent, setPredictionsMarkdownSource] = useRemark({
    rehypeReactOptions: {
      components: {
        ul: (props) => (
          <ul
            style={{
              listStyleType: 'disc',
              paddingLeft: '20px',
              fontSize: '18px',
              display: 'inline-block',
              verticalAlign: 'top',
            }}
          >
            {props.children}
          </ul>
        ),
      },
    },
  });

  const { endDate } = mapDropdownTimeInputToDates(
    SingleAccountSignalsTimePeriod.last5Qtrs,
    fiscalYearStartingMonth,
  );

  const [getPrevQtrAccountSignals, { data: prevQtrAccountSignals }] =
    useGetAccountSignalsLazyQuery();
  const [getCurrentQtrAccountSignals, { data: currentQtrAccountSignals }] =
    useGetAccountSignalsLazyQuery();

  useEffect(() => {
    if (fiscalYearStartingMonth !== undefined) {
      const { startDate, endDate } = mapDropdownTimeInputToDates(
        SingleAccountSignalsTimePeriod.previousQtr,
        fiscalYearStartingMonth,
      );
      getPrevQtrAccountSignals({
        variables: {
          accountId: parseInt(selectedAccountId),
          startDate,
          endDate,
        },
      });
    }
  }, [fiscalYearStartingMonth, getPrevQtrAccountSignals, selectedAccountId]);

  useEffect(() => {
    if (fiscalYearStartingMonth !== undefined) {
      const { startDate, endDate } = mapDropdownTimeInputToDates(
        SingleAccountSignalsTimePeriod.currentQtr,
        fiscalYearStartingMonth,
      );
      getCurrentQtrAccountSignals({
        variables: {
          accountId: parseInt(selectedAccountId),
          startDate,
          endDate,
        },
      });
    }
  }, [
    fiscalYearStartingMonth,
    getPrevQtrAccountSignals,
    selectedAccountId,
    getCurrentQtrAccountSignals,
  ]);

  const prevQtrXeominTotal = useMemo(() => {
    return getQtrXeominTotal(prevQtrAccountSignals);
  }, [prevQtrAccountSignals]);

  const currentQtrXeominTotal = useMemo(() => {
    return getQtrXeominTotal(currentQtrAccountSignals);
  }, [currentQtrAccountSignals]);

  const accountInfo = data?.account;

  const narratives = getChartNarratives(
    accountInfo?.predictionCalculatedForType,
  );

  const gridColumnsForTablet = 2;

  useEffect(() => {
    if (accountInfo?.narratives?.xeominPrediction) {
      setPredictionsMarkdownSource(
        accountInfo.narratives.xeominPrediction[0] ?? '',
      );
    }
  }, [accountInfo?.narratives]);

  return (
    <Card isLoading={loading}>
      <SectionHeader
        title={title}
        subtitle={subtitle}
        tooltip={tooltip}
        addHeaderLeftMargin
        showBackgroundColor={false}
        rightComponent={
          !accountInfo?.xeominStalePrediction &&
          accountInfo?.predictionCalculatedForType && (
            <Tooltip
              content={
                <>
                  <Text fontWeight={'bold'} mb={4}>
                    This section is displayed because of the following:
                  </Text>
                  <ul
                    style={{
                      listStyleType: 'none',
                      paddingLeft: '20px',
                      fontSize: '12px',
                      display: 'inline-block',
                      verticalAlign: 'top',
                    }}
                  >
                    {narratives.map((narrative, i) => (
                      <li key={i}>
                        <Flex alignItems={'center'}>
                          <CheckIcon
                            color={'brand.lightBlue'}
                            mr={2}
                            mb={1}
                            boxSize={4}
                          />
                          <Text fontSize={'md'} mb={2}>
                            {narrative}
                          </Text>
                        </Flex>
                      </li>
                    ))}
                  </ul>
                </>
              }
              bg={'brand.white'}
              color={'brand.black'}
              iconColor={'brand.lightBlue'}
              minW={'500px'}
              hideIcon
            >
              <Flex align={'center'}>
                <InfoIcon color={'brand.lightBlue'} boxSize={4} mr={1} />
                <Text color={'brand.lightBlue'}>Why am I seeing this?</Text>
              </Flex>
            </Tooltip>
          )
        }
      />
      {!accountInfo ? (
        <Text color={'brand.crimson'}>
          Error getting account data for this account, please refresh to try
          again.
        </Text>
      ) : accountInfo.xeominStalePrediction ||
        !accountInfo.predictionCalculatedForType ? (
        <Box ml={8} p={8}>
          {predictionsContent}
        </Box>
      ) : (
        <>
          <Flex>
            <Text mb={4} ml={8} fontWeight={'normal'}>
              Summary:&nbsp;
            </Text>
            <Text fontWeight={'bold'}>
              {cleanStringCase(accountInfo.xeominPredictionSummary)}
            </Text>
          </Flex>
          <SimpleGrid columns={[gridColumnsForTablet, null, null, null, 4]}>
            {accountInfo.predictionLevel && (
              <Box gridColumn={'span 2'} mx={8}>
                <GaugeByPredictionType
                  predictionType={accountInfo.predictionCalculatedForType}
                  predictionLevel={accountInfo.predictionLevel}
                />
                {accountInfo.predictionCalculatedForType ===
                  ApiPredictionForType.Volume && predictionsContent}
              </Box>
            )}
            <Flex
              flexDir={'column'}
              justify={'space-between'}
              gridColumn={'span 2'}
              ml={4}
              px={8}
              borderLeftWidth={2}
              borderLeftColor={'brand.crimson'}
            >
              <Text color={'brand.crimson'} fontWeight={'bold'} mb={8}>
                PREDICTION ANALYSIS
              </Text>
              {accountInfo.predictionCalculatedForType ===
              ApiPredictionForType.Volume ? (
                <>
                  <VolumeBarChart
                    currentQtrXeominTotal={currentQtrXeominTotal}
                    endDate={endDate}
                    prevQtrXeominTotal={prevQtrXeominTotal}
                    predictedTotal={0}
                    volumeNarratives={
                      accountInfo.narratives?.xeominVolume ?? []
                    }
                  />
                  {predictionsContent}
                  {accountInfo?.goalValues?.length &&
                    accountInfo.goalValues[0]!.calculatedAt && (
                      <Text fontSize={'sm'} mt={8}>
                        Predictions were last updated on&nbsp;
                        {formatDateString(
                          accountInfo.goalValues[0]!.calculatedAt,
                          'MMMM dd, yyyy',
                        )}
                        .
                      </Text>
                    )}
                </>
              ) : !!accountInfo.narratives?.xeominPrediction?.length ? (
                <>
                  {predictionsContent}
                  {accountInfo?.goalValues?.length &&
                    accountInfo.goalValues[0]!.calculatedAt && (
                      <Text fontSize={'sm'} mt={8}>
                        Predictions were last updated on&nbsp;
                        {formatDateString(
                          accountInfo.goalValues[0]!.calculatedAt,
                          'MMMM dd, yyyy',
                        )}
                        .
                      </Text>
                    )}
                </>
              ) : null}
            </Flex>
          </SimpleGrid>
        </>
      )}
    </Card>
  );
};