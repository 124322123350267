import { CSSProperties, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import {
  colorOptionsLiteral,
  iconOptionsLiteral,
  sizeOptionsLiteral,
  SizeType,
} from 'shared/types/coreTypes.d';

type IconType = {
  analyticsAttr?: string;
  bgColor?: colorOptionsLiteral;
  className?: string;
  color?: colorOptionsLiteral;
  hoverBgColor?: colorOptionsLiteral;
  hoverColor?: colorOptionsLiteral;
  name: iconOptionsLiteral;
  size?: SizeType;
  textSize?: sizeOptionsLiteral;
  theme?:
    | 'action'
    | 'action-hover'
    | 'active'
    | 'active-hover'
    | 'muted'
    | 'success'
    | 'error'
    | 'warning';
  title?: string;
};

type IcontStyleType = CSSProperties & {
  '--icon-fg-hover-fill'?: string | undefined;
  '--icon-fg-hover-stroke'?: string | undefined;
  '--icon-fg-fill'?: string | undefined;
  '--icon-fg-stroke'?: string | undefined;
  '--icon-bg-hover-fill'?: string | undefined;
  '--icon-bg-fill'?: string | undefined;
};

function Icon({
  analyticsAttr,
  bgColor,
  className,
  color,
  hoverBgColor,
  hoverColor,
  name,
  size,
  textSize,
  theme,
  title,
}: IconType) {
  const [IconComponent, setIconComponent] = useState(null);
  let isRendered = useRef(false);

  useEffect(() => {
    isRendered.current = true;
    (async function getComponent() {
      const component = await import(`./${name}`);
      if (isRendered.current) {
        setIconComponent(component.default);
      }
    })();
    return () => {
      isRendered.current = false;
    };
  }, [name]);

  const iconClasses = classnames({
    'c-icon': true,
    [`c-icon--size-${size}`]: size,
    [`c-icon--text-size-${textSize}`]: textSize,
    [`c-icon--theme-${theme}`]: theme,
    ...(typeof className === 'string' ? { [className]: className } : {}),
  });
  const iconStyle = {
    ...(bgColor && {
      '--icon-bg-fill': `var(--color-${bgColor})`,
    }),
    ...(hoverBgColor && {
      '--icon-bg-hover-fill': `var(--color-${hoverBgColor})`,
    }),
    ...(color && {
      '--icon-fg-fill': `var(--color-${color})`,
      '--icon-fg-stroke': `var(--color-${color})`,
    }),
    ...(hoverColor && {
      '--icon-fg-hover-fill': `var(--color-${hoverColor})`,
      '--icon-fg-hover-stroke': `var(--color-${hoverColor})`,
    }),
  };

  return (
    <svg
      analytics-attr={cleanAnalyticsStringList([analyticsAttr, 'icon'])}
      className={iconClasses}
      style={iconStyle as IcontStyleType}
      viewBox={'0 0 32 32'}
    >
      {title && <title>{title}</title>}
      {IconComponent}
    </svg>
  );
}

export default Icon;
