export const ProspectIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='33'
    height='32'
    viewBox='0 0 33 32'
    fill='none'
  >
    <rect
      x='0.857422'
      width='32'
      height='32'
      rx='4'
      fill='#2A96FC'
      fill-opacity='0.1'
    />
    <g clip-path='url(#clip0_313_2323)'>
      <path
        d='M16.8574 8C12.4574 8 8.85742 11.6 8.85742 16C8.85742 20.4 12.4574 24 16.8574 24C21.2574 24 24.8574 20.4 24.8574 16C24.8574 11.6 21.2574 8 16.8574 8ZM17.8574 21.9V20H15.8574V21.9C13.3574 21.5 11.3574 19.5 10.9574 17H12.8574V15H10.9574C11.3574 12.5 13.3574 10.5 15.8574 10.1V12H17.8574V10.1C20.3574 10.5 22.3574 12.5 22.7574 15H20.8574V17H22.7574C22.3574 19.5 20.3574 21.5 17.8574 21.9Z'
        fill='#2A96FC'
      />
      <path
        d='M16.8574 18C17.962 18 18.8574 17.1046 18.8574 16C18.8574 14.8954 17.962 14 16.8574 14C15.7529 14 14.8574 14.8954 14.8574 16C14.8574 17.1046 15.7529 18 16.8574 18Z'
        fill='#2A96FC'
      />
    </g>
    <defs>
      <clipPath id='clip0_313_2323'>
        <rect
          width='16'
          height='16'
          fill='white'
          transform='translate(8.85742 8)'
        />
      </clipPath>
    </defs>
  </svg>
);
