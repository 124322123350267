import { useMemo } from 'react';
import { Table } from 'shared/components/Core';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Granularity,
  SignalHistorySectionTableColumn,
  SignalSectionUnitType,
} from '../../../types/SingleAccountSignalsTypes';
import {
  SINGLE_ACCOUNT_SIGNAL_INBOUND_COLOR,
  SINGLE_ACCOUNT_SIGNAL_OUTBOUND_COLOR,
} from 'app/src/components/Account/constants/singleAccountSignalColors';
import { ApiSignal } from 'shared/graphql/generatedApiTypes';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

type SingleAccountSignalDetailsTableProps = {
  currentGranularity: Granularity;
  currentUnitType: SignalSectionUnitType;
  tableData: ApiSignal[];
  columns: SignalHistorySectionTableColumn[];
};

export const SingleAccountSignalDetailsTable = ({
  currentGranularity,
  currentUnitType,
  tableData,
  columns,
}: SingleAccountSignalDetailsTableProps) => {
  const mappedData = useMemo(() => {
    return tableData.map((tableItem) =>
      Object.values(tableItem).reduce((accum, item) => {
        //If "Granularity" = "Daily" display each row in the expanded table as a distinct, atomic signal and not an aggregation
        const existingSignalTypeItem =
          currentGranularity !== Granularity.daily &&
          accum.find(
            (row) =>
              row.type === item.type &&
              row.typeDetail === item.typeDetail &&
              row.topicDetail === item.topicDetail &&
              row.topic === item.topic &&
              row.groupKey === item.groupKey &&
              row.source === item.source,
          );
        if (!existingSignalTypeItem && item.type && item.quantity) {
          accum.push({
            type: item.type,
            typeDetail: item.typeDetail ?? null,
            quantity: item.quantity,
            count: 1,
            source: item.source,
            topic: item.topic,
            topicDetail: item.topicDetail,
            groupKey: item.groupKey,
            direction: item.direction,
            action: item.action,
          });
        } else if (existingSignalTypeItem) {
          existingSignalTypeItem.quantity += item.quantity;
          existingSignalTypeItem.count++;
        }
        return accum;
      }, [] as Partial<ApiSignal>[]),
    );
  }, [tableData, currentGranularity]);

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Activity Details',
        columns: mapTableColumns(columns, currentUnitType),
      },
    ],
    [currentUnitType, columns],
  );

  const unitTypeString =
    currentUnitType === SignalSectionUnitType.quantity ? 'Qty' : 'Count';

  return (
    <Accordion allowToggle allowMultiple>
      {mappedData.map((row, index) => (
        <AccordionItem key={index}>
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton>
                  <Flex flex='1' textAlign='left' justify={'space-between'}>
                    <Flex align={'center'}>
                      {isExpanded ? (
                        <ChevronDownIcon boxSize={6} />
                      ) : (
                        <ChevronRightIcon boxSize={6} />
                      )}
                      <Text color={'brand.black'}>
                        {`${tableData[index].occurredAt}`}
                      </Text>
                    </Flex>
                    <Text>{`Total ${unitTypeString}: ${row
                      .reduce((accum, item) => {
                        return accum + item.quantity;
                      }, 0)
                      .toLocaleString()}`}</Text>
                    <Text>{`Marketing ${unitTypeString}: ${row
                      .reduce((accum, item) => {
                        if (item.source === 'marketing') {
                          return accum + item.quantity;
                        }
                        return accum;
                      }, 0)
                      .toLocaleString()}`}</Text>
                    <Text>{`Sales ${unitTypeString}: ${row
                      .reduce((accum, item) => {
                        if (item.source === 'sales') {
                          return accum + item.quantity;
                        }
                        return accum;
                      }, 0)
                      .toLocaleString()}`}</Text>
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <Table columns={tableColumns} data={row} perPage={20} classname="signal-table" />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const mapTableColumns = (
  columns: SignalHistorySectionTableColumn[],
  currentUnitType: SignalSectionUnitType,
) => {
  return columns.map((col) => {
    switch (col) {
      case SignalHistorySectionTableColumn.action:
        return {
          Header: 'Action',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'action',
        };
      case SignalHistorySectionTableColumn.direction:
        return {
          Header: 'Direction',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'direction',
        };
      case SignalHistorySectionTableColumn.directionWithDot:
        return {
          Header: 'Direction',
          Cell: ({ value }) => {
            return (
              <Flex align={'center'}>
                <DirectionDot direction={value} />
                {value === 'activity' ? 'Outbound' : 'Inbound'}
              </Flex>
            );
          },
          accessor: 'direction',
        };
      case SignalHistorySectionTableColumn.type:
        return {
          Header: 'Type',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'type',
        };
      case SignalHistorySectionTableColumn.typeWithDot:
        return {
          Header: 'Type',
          Cell: ({ row, value }) => {
            return (
              <Flex align={'center'}>
                <DirectionDot direction={row.original.direction} />
                {cleanStringCase(value)}
              </Flex>
            );
          },
          accessor: 'type',
        };
      case SignalHistorySectionTableColumn.typeDetail:
        return {
          Header: 'Type Detail',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'typeDetail',
        };
      case SignalHistorySectionTableColumn.topic:
        return {
          Header: 'Topic',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'Topic',
        };
      case SignalHistorySectionTableColumn.topicDetail:
        return {
          Header: 'Topic Detail',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'topicDetail',
        };
      case SignalHistorySectionTableColumn.orderNumber:
        return {
          Header: 'Order Number',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'groupKey',
        };
      case SignalHistorySectionTableColumn.quantity:
        return {
          Header:
            currentUnitType === SignalSectionUnitType.quantity
              ? 'Total Qty'
              : 'Total Count',
          accessor: 'quantity',
          Cell: ({ value }) => value.toLocaleString(),
          isNumeric: true,
        };
      case SignalHistorySectionTableColumn.sourceWithDot:
        return {
          Header: 'Source',
          Cell: ({ value }) => {
            return (
              <Flex align={'center'}>
                <SourceDot source={value} />
                {cleanStringCase(value)}
              </Flex>
            );
          },
          accessor: 'source',
        };
      case SignalHistorySectionTableColumn.source:
        return {
          Header: 'Source',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'source',
        };
      case SignalHistorySectionTableColumn.count:
        return {
          Header: 'Count',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'count',
        };
      default:
        return {
          Header: 'Unknown',
          Cell: ({ value }) => cleanStringCase(value),
          accessor: 'unknown',
        }  
    }
  });
};

const DirectionDot = ({
  direction,
}: {
  direction: 'activity' | 'response';
}) => (
  <Box
    bg={
      direction === 'response'
        ? SINGLE_ACCOUNT_SIGNAL_INBOUND_COLOR
        : SINGLE_ACCOUNT_SIGNAL_OUTBOUND_COLOR
    }
    borderRadius={6}
    w={3}
    h={3}
    mr={1}
  />
);

const SourceDot = ({ source }: { source: 'marketing' | 'sales' }) => (
  <Box
    bg={
      source === 'marketing'
        ? SINGLE_ACCOUNT_SIGNAL_INBOUND_COLOR
        : SINGLE_ACCOUNT_SIGNAL_OUTBOUND_COLOR
    }
    borderRadius={6}
    w={3}
    h={3}
    mr={1}
  />
);
