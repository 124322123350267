import {
  Granularity,
  SignalHistorySectionFilter,
  SignalSectionUnitType,
  SingleAccountSignalsTimePeriod,
} from 'app/src/components/Account/types/SingleAccountSignalsTypes';
import { Box, FormLabel, Select } from '@chakra-ui/react';
import {
  SingleAccountSignalAction,
  SingleAccountSignalActionType,
  SingleAccountSignalReducerState,
} from 'app/src/components/Account/reducers/singleAccountSignalReducer';
import {
  SignalDirections,
  SignalSources,
} from 'app/src/components/Home/types/homeTypes';
import { signalDirectionOptions } from 'app/src/hooks/useSignalDirectionButtons';
import React, { Dispatch } from 'react';
import { cleanStringCase } from 'shared/helpers/formatHelpers';
import { signalSourceOptions } from 'app/src/hooks/useSignalSourceButtons';
import { mapDropdownTimeInputToDates } from 'app/src/components/Account/helpers/singleAccountSignalHelpers';
import { singleAccountSignalHistoryTimeOptions } from 'app/src/components/Account/constants/singleAccountSignalHistoryTimeOptions';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { OptionsType } from 'shared/types/coreTypes.d';

type GetSignalHistoryFilterParams = {
  dispatch: Dispatch<SingleAccountSignalAction>;
  filter: SignalHistorySectionFilter;
  fiscalYearStartingMonth: number;
  selectedTimePeriod: SingleAccountSignalsTimePeriod;
  setSelectedTimePeriod: (
    newTimePeriod: SingleAccountSignalsTimePeriod,
  ) => void;
  state: SingleAccountSignalReducerState;
  typeDetailOptions: Array<{ value: string; label: string }>;
  typeOptions: Array<{ value: string; label: string }>;
};

export const getSignalHistoryFilter = ({
  dispatch,
  filter,
  fiscalYearStartingMonth,
  selectedTimePeriod,
  setSelectedTimePeriod,
  state,
  typeDetailOptions,
  typeOptions,
}: GetSignalHistoryFilterParams) => {
  switch (filter) {
    case SignalHistorySectionFilter.direction:
      return (
        <Box>
          <FormLabel fontSize='sm'>DIRECTION</FormLabel>
          <Select
            analytics-attr={`signal-section-signal-direction-select`}
            onChange={(e) =>
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_DIRECTION,
                payload: {
                  newDirection: e.target.value as SignalDirections,
                },
              })
            }
            value={state.currentDirection}
            size='xs'
          >
            {signalDirectionOptions.map((item) => (
              <option
                analytics-attr={`signal-section--signal-direction-select-${item.label}`}
                key={item.value}
                label={item.label}
                value={item.value}
              >
                {item.label}
              </option>
            ))}
          </Select>
        </Box>
      );
    case SignalHistorySectionFilter.granularity:
      return (
        <Box>
          <FormLabel fontSize='sm'>GROUP BY</FormLabel>
          <Select
            analytics-attr={`signals-section-granularity-select`}
            onChange={(e) =>
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_GRANULARITY,
                payload: {
                  newGranularity: e.target.value as Granularity,
                },
              })
            }
            value={state.currentGranularity}
            color={'brand.black'}
            size='xs'
          >
            {Object.values(Granularity)
              .map((item) => ({
                value: item,
                label: cleanStringCase(item),
              }))
              .map((item) => (
                <option
                  analytics-attr={`signals-section-granularity-select-${item.label}`}
                  style={{ color: 'brand.black' }}
                  key={item.value}
                  label={item.label}
                  value={item.value}
                >
                  {item.label}
                </option>
              ))}
          </Select>
        </Box>
      );
    case SignalHistorySectionFilter.source:
      return (
        <Box>
          <FormLabel fontSize='sm'>SOURCE</FormLabel>
          <Select
            analytics-attr={`signal-section-signal-source-select`}
            onChange={(e) =>
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_SOURCE,
                payload: { newSource: e.target.value as SignalSources },
              })
            }
            value={state.currentSource}
            color={'brand.black'}
            size='xs'
          >
            {signalSourceOptions.map((item) => (
              <option
                analytics-attr={`signal-section-signal-source-select-${item.label}`}
                style={{ color: 'brand.black' }}
                key={item.value}
                label={item.label}
                value={item.value}
              >
                {item.label}
              </option>
            ))}
          </Select>
        </Box>
      );
    case SignalHistorySectionFilter.summarizeBy:
      return (
        <Box>
          <FormLabel fontSize='sm'>SUMMARIZE BY</FormLabel>
          <Select
            analytics-attr={`signals-section-unit-select`}
            onChange={(e) =>
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_UNIT_TYPE,
                payload: {
                  newUnitType: e.target.value as SignalSectionUnitType,
                },
              })
            }
            value={state.currentUnitType}
            color={'brand.black'}
            size='xs'
          >
            {Object.values(SignalSectionUnitType)
              .map((item) => ({
                value: item,
                label: cleanStringCase(item),
              }))
              .map((item) => (
                <option
                  analytics-attr={`signals-section-unit-select-${item.label}`}
                  style={{ color: 'brand.black' }}
                  key={item.value}
                  label={item.label}
                  value={item.value}
                >
                  {item.label}
                </option>
              ))}
          </Select>
        </Box>
      );
    case SignalHistorySectionFilter.timePeriod:
      return (
        <Box>
          <FormLabel fontSize='sm'>TIME PERIOD</FormLabel>
          <Select
            analytics-attr={`activity-explorer-time-select`}
            onChange={(e) => {
              const { startDate, endDate } = mapDropdownTimeInputToDates(
                e.target.value as SingleAccountSignalsTimePeriod,
                fiscalYearStartingMonth,
              );
              setSelectedTimePeriod(
                e.target.value as SingleAccountSignalsTimePeriod,
              );
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_START_AND_END_DATES,
                payload: {
                  newStartDate: startDate,
                  newEndDate: endDate,
                },
              });
            }}
            value={selectedTimePeriod}
            size='xs'
          >
            {singleAccountSignalHistoryTimeOptions.map((item) => (
              <option
                analytics-attr={cleanAnalyticsStringList([
                  'activity-explorer-time-select',
                  item.label,
                ])}
                key={item.value}
                label={item.label}
                value={item.value}
              >
                {item.label}
              </option>
            ))}
          </Select>
        </Box>
      );
    case SignalHistorySectionFilter.type:
      return (
        <Box>
          <FormLabel fontSize='sm'>TYPE</FormLabel>
          <Select
            analytics-attr={`signals-section-type-select`}
            onChange={(e) =>
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_TYPE,
                payload: {
                  newType: e.target.value,
                },
              })
            }
            value={state.currentType}
            color={'brand.black'}
            size='xs'
          >
            {typeOptions &&
              typeOptions.map((item: OptionsType) => (
                <option
                  analytics-attr={`signals-section-type-detail-select-${item.label}`}
                  style={{ color: 'brand.black' }}
                  key={item.value}
                  label={item.label}
                  value={item.value}
                >
                  {item.label}
                </option>
              ))}
          </Select>
        </Box>
      );
    case SignalHistorySectionFilter.typeDetail:
      return (
        typeDetailOptions &&
        typeDetailOptions.length > 1 && (
          <Box>
            <FormLabel fontSize='sm'>TYPE DETAIL</FormLabel>
            <Select
              analytics-attr={`signals-section-type-detail-select`}
              onChange={(e) =>
                dispatch({
                  type: SingleAccountSignalActionType.UPDATE_TYPE_DETAIL,
                  payload: {
                    newTypeDetail: e.target.value,
                  },
                })
              }
              value={state.currentTypeDetail}
              color={'brand.black'}
              size='xs'
            >
              {typeDetailOptions &&
                typeDetailOptions.map((item: OptionsType) => (
                  <option
                    analytics-attr={`signals-section-type-detail-select-${item.label}`}
                    style={{ color: 'brand.black' }}
                    key={item.value}
                    label={item.label}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                ))}
            </Select>
          </Box>
        )
      );
  }
};
