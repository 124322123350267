import { useEffect, Dispatch, SetStateAction } from 'react';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import { Button, Container, Input } from 'shared/components/Core';
import { Flex, Stack, Text, useToast } from '@chakra-ui/react';
import * as ROUTES from 'app/src/constants/routes';
import { Redirect, useHistory } from 'react-router-dom';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import { MarketoLogoIcon } from 'shared/components/Core/Icon/marketoLogo';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import {
  ApiCrmTypeEnum,
  ApiDataSourceConfigInput,
  useCreateDataSourceConfigMutation,
} from 'shared/graphql/generatedApiTypes';
import { MARKETO_LEARN_MORE_LINK } from 'app/src/components/Integrations/constants/integrationUrls';
import { jsonToBase64 } from 'app/src/components/Integrations/helpers/base64helpers';
import { IntegrationLocationState } from 'app/src/components/Integrations/components/IntegrationButton';

export const MarketoIntegrationPage = (props) => {
  const queryParams = useQueryParams();
  const companyName =
    queryParams.get('company') !== null
      ? queryParams.get('company')
      : props.companyName;
  useEffect(() => {
    updateAppHeaders(ROUTES.MARKETO_INTEGRATION_PAGE.name);
  }, []);

  if (!companyName) {
    return <Redirect to={ROUTES.INTEGRATIONS_PAGE.path} />;
  }

  return (
    <Container ph={'xs'} pv={'4xl'} width={'sm'}>
      <Stack spacing={8}>
        <Flex align={'center'} flexDir={'column'}>
          <MarketoLogoIcon boxSize={40} />
          <Text
            textAlign={'center'}
            fontSize={'3xl'}
            fontWeight={'extrabold'}
            mt={-12}
          >
            Marketo Integration for {companyName}
          </Text>
        </Flex>
        <a
          href={MARKETO_LEARN_MORE_LINK}
          rel={'noreferrer noopener'}
          target={'_blank'}
        >
          <Text
            fontSize={'sm'}
            mt={-8}
            textAlign={'center'}
            textDecoration={'underline'}
          >
            Learn more about this integration
          </Text>
        </a>
      </Stack>
      <FormBase companyName={companyName} setIsOpen={props.setIsOpen} />
    </Container>
  );
};

const FormBase = ({
  companyName,
  setIsOpen,
}: {
  companyName: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const toast = useToast();
  const history = useHistory();

  const locationState: IntegrationLocationState = {
    companyName,
    dataSource: ApiCrmTypeEnum.Marketo,
  };

  const [createDataSourceConfigMutation, { loading }] =
    useCreateDataSourceConfigMutation({
      onCompleted: () =>
        history.push(
          `${ROUTES.INTEGRATION_SUCCESS_PAGE.path}?state=${jsonToBase64(
            locationState,
          )}`,
        ),
      onError: () =>
        toast({
          title:
            'Error submitting integration data; please check your connection and try again.',
          isClosable: true,
          status: 'error',
          duration: null,
        }),
    });

  const { bind: bindEndpointUrl, value: endpointUrl } = useInput('', {
    id: 'end-point-url',
    label: 'Endpoint URL',
    name: 'end-point-url',
    placeholder:
      'Marketo REST Endpoint URL (e.g. https://xyz.mktorest.com/rest)',
    required: true,
    type: 'text',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const { bind: bindIdentityUrl, value: identityUrl } = useInput('', {
    id: 'identity-url',
    label: 'Identity URL',
    name: 'identity-url',
    placeholder:
      'Marketo Identity URL (e.g. https://xyz.mktorest.com/identity)',
    required: true,
    type: 'text',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const { bind: bindClientId, value: clientId } = useInput('', {
    id: 'client-id',
    label: 'Client ID',
    name: 'client-id',
    placeholder: 'Marketo Client ID',
    required: true,
    type: 'text',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const { bind: bindClientSecret, value: clientSecret } = useInput('', {
    id: 'client-secret',
    label: 'Client Secret',
    name: 'client-secret',
    placeholder: 'Marketo Client Secret',
    required: true,
    type: 'password',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;

  const isSubmitButtonDisabled =
    !endpointUrl || !identityUrl || !clientId || !clientSecret;

  const _onSubmit = async (event) => {
    event.preventDefault();
    const dataSourceConfig: Omit<
      ApiDataSourceConfigInput,
      | 'hubspotConfig'
      | 'salesforceConfig'
      | 'oauthCode'
      | 'pendoConfig'
      | 'pardotConfig'
    > = {
      companyName,
      dataSource: ApiCrmTypeEnum.Marketo,
      marketoConfig: {
        clientId: clientId.trim(),
        clientSecret: clientSecret.trim(),
        endpoint: endpointUrl.trim(),
        identity: identityUrl.trim(),
      },
    };
    createDataSourceConfigMutation({
      variables: {
        //@ts-ignore - don't have all params
        dataSourceConfig,
      },
    });
    if (setIsOpen !== undefined) {
      setIsOpen(false);
    }
  };

  return (
    <form onSubmit={_onSubmit} className={'c-form h-pv-lg'}>
      <Stack spacing={4}>
        <>
          <Input {...bindEndpointUrl} />
          <Input {...bindIdentityUrl} />
          <Input {...bindClientId} />
          <Input {...bindClientSecret} />
        </>
        <Button
          isDisabled={isSubmitButtonDisabled}
          isLoading={loading}
          type={'submit'}
          text={'Submit'}
        />
      </Stack>
    </form>
  );
};
