import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import {
  formatAsPercentage,
  formatDateString,
  numberFormatter,
} from 'shared/helpers/formatHelpers';
import _map from 'lodash/map';
import { FilterDictType } from 'app/src/components/GlobalFilters/types/filterDict';
import _omit from 'lodash/omit';

export function returnMinMaxFilterValue(
  value: { min?: number | string; max?: number | string },
  key: FilterIndices,
  isPercentage: boolean,
) {
  if (
    [
      FilterIndices.LAST_INBOUND_SIGNAL_DATE_FILTER,
      FilterIndices.LAST_OUTBOUND_SIGNAL_DATE_FILTER,
    ].includes(key)
  ) {
    // beat race condition where values are removed slightly before keys
    return `${formatDateString(
      `${value?.min}T00:00:00`,
      'P',
    )} - ${formatDateString(`${value?.max}T00:00:00`, 'P')}`;
  } else if (typeof value.max === 'number' || typeof value.min === 'number') {
    if (value.min === 0 && !value.max) {
      return '';
    }
    const minString =
      isPercentage && typeof value?.min === 'number' && value.min !== 0
        ? formatAsPercentage(value.min, { precision: 0 })
        : numberFormatter(value?.min, key === FilterIndices.REVENUES_FILTER);
    const maxString =
      isPercentage && typeof value?.max === 'number'
        ? ` - ${formatAsPercentage(value.max, { precision: 0 })}`
        : value?.max
        ? ` - ${numberFormatter(
            value?.max,
            key === FilterIndices.REVENUES_FILTER,
          )}`
        : '+';
    return `${minString}${maxString}`;
  }
}

export function getAccountGroupStats(
  filterDict: FilterDictType | undefined | null,
) {
  return _map(
    _omit(filterDict, [
      FilterIndices.ACCOUNTS_FILTER_TYPE,
      FilterIndices.ACCOUNTS_FILTER,
      FilterIndices.ACCOUNTS_FULL_FILTER,
      FilterIndices.EXCLUDED_ACCOUNT_IDS,
      FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS,
    ]),
    (value, key) => ({ value: value as any, key: key as FilterIndices }),
  ).filter(
    (stat) =>
      stat.value !== '' && stat.value !== '0 - 100' && stat.value !== [],
  );
}
