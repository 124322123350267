import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetAccountSignalsLazyQuery } from 'shared/graphql/generatedApiTypes';
import {
  SignalHistorySectionFilter,
  SignalHistorySectionTableColumn,
  SingleAccountSignalHistorySectionProps,
  SingleAccountSignalsTimePeriod,
} from '../../../types/SingleAccountSignalsTypes';
import { AppNavigationalStructureContext } from 'app/src/context/AppNavigationalStructureContext';
import { mapDropdownTimeInputToDates } from '../../../helpers/singleAccountSignalHelpers';
import SectionHeader from 'shared/components/Core/SectionHeader';
import { ButtonGroup, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { Button, Card } from 'shared/components/Core';
import {
  initialSingleAccountSignalReducerState,
  SingleAccountSignalActionType,
  singleAccountSignalReducer,
} from '../../../reducers/singleAccountSignalReducer';
import { SingleAccountSignalHistoryChart } from './SingleAccountSignalHistoryChart';
import { SingleAccountSignalDetailsTable } from './SingleAccountSignalDetailsTable';
import {
  cleanStringCase,
  formatDateString,
} from 'shared/helpers/formatHelpers';
import { FilterIcon } from 'shared/components/Core/Icon/filter';
import { SingleAccountSignalHistoryFilterDrawer } from 'app/src/components/Account/components/sav/singleAccountSignalHistory/SingleAccountSignalHistoryFilterDrawer';
import { signalHistoryPanelDefaultFilters } from 'app/src/components/Account/constants/signalHistoryPanelDefaultFilters';
import { getSignalHistoryFilter } from 'app/src/components/Account/helpers/getSignalHistoryFilter';

export const SingleAccountSignalHistorySection = ({
  sectionHeaderText,
  sectionHeaderSubtext,
  sectionHeaderTooltip,
  sectionTableColumns,
  sectionPanelFilters = {
    filters: [
      SignalHistorySectionFilter.summarizeBy,
      SignalHistorySectionFilter.type,
      SignalHistorySectionFilter.typeDetail,
    ],
    lineBreakAfter: SignalHistorySectionFilter.summarizeBy,
  },
  sectionQuickFilters = [
    SignalHistorySectionFilter.source,
    SignalHistorySectionFilter.direction,
    SignalHistorySectionFilter.granularity,
    SignalHistorySectionFilter.timePeriod,
  ],
}: SingleAccountSignalHistorySectionProps) => {
  const { structure } = useContext(AppNavigationalStructureContext);
  const { pathname } = useLocation();
  const fiscalYearStartingMonth = structure?.fiscalYearStartingMonth ?? 0;
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(
    singleAccountSignalReducer,
    initialSingleAccountSignalReducerState,
  );

  const [selectedTimePeriod, setSelectedTimePeriod] =
    useState<SingleAccountSignalsTimePeriod>(
      SingleAccountSignalsTimePeriod.sixMonths,
    );

  const [isCurrentlyZoomedIn, setIsCurrentlyZoomedIn] =
    useState<boolean>(false);

  const {
    onOpen: drawerOnOpen,
    onClose: drawerOnClose,
    isOpen: drawerIsOpen,
  } = useDisclosure();

  const [getAccountSignals, { loading }] = useGetAccountSignalsLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const typeOptions = useMemo(() => {
    const options = state.fullData?.reduce((accum, item) => {
      if (
        item &&
        item.type &&
        !accum.find((accumItem) => accumItem.value === item.type)
      ) {
        accum.push({
          value: item.type,
          label: cleanStringCase(item.type),
        });
      }
      return accum;
    }, [] as any[]);
    options?.unshift({
      value: '',
      label: 'All',
    });
    return options;
  }, [state.fullData]);

  const typeDetailOptions = useMemo(() => {
    const options = state.fullData?.reduce((accum, item) => {
      if (
        item &&
        item.typeDetail &&
        // filter out return type detail since we don't want to show it here
        item.typeDetail !== 'return' &&
        !accum.find((accumItem) => accumItem.value === item.typeDetail)
      ) {
        accum.push({
          value: item.typeDetail,
          label: cleanStringCase(item.typeDetail),
        });
      }
      return accum;
    }, [] as any[]);
    options?.unshift({
      value: '',
      label: 'All',
    });
    return options;
  }, [state.fullData]);

  useEffect(() => {
    (async () => {
      if (getAccountSignals && !loading) {
        const minAndMaxDates = mapDropdownTimeInputToDates(
          SingleAccountSignalsTimePeriod.sixMonths,
          fiscalYearStartingMonth,
        );
        const newData = await getAccountSignals({
          variables: {
            accountId: parseInt(id),
            startDate: minAndMaxDates.startDate,
            endDate: minAndMaxDates.endDate,
          },
        });
        dispatch({
          type: SingleAccountSignalActionType.SET_FULL_DATA,
          payload: {
            data: newData?.data?.accountSignals ?? [],
            fiscalYearStartingMonth,
          },
        });
      }
    })();
  }, [getAccountSignals, pathname]);

  const count = useMemo(
    () =>
      sectionPanelFilters.filters.reduce((accum, current) => {
        const selectedFilter = signalHistoryPanelDefaultFilters[current];
        const currentValue =
          current === SignalHistorySectionFilter.timePeriod
            ? selectedTimePeriod
            : state[selectedFilter.stateKey];
        if (!currentValue || currentValue === selectedFilter.value) {
          return accum;
        }
        return accum + 1;
      }, 0),
    [state],
  );

  // sectionTableColumns = ['source', 'type', 'action', 'directionWithDot', 'quantity'];

  return (
    <Card isLoading={loading}>
      <Stack px={6}>
        <SectionHeader
          title={sectionHeaderText ?? 'Signals'}
          subtitle={sectionHeaderSubtext}
          tooltip={sectionHeaderTooltip}
          showBackgroundColor={false}
          addHeaderLeftMargin
          pb={0}
          rightComponent={
            <ButtonGroup alignItems={'flex-end'}>
              <Button
                onClick={() => {
                  dispatch({
                    type: SingleAccountSignalActionType.RESET,
                    payload: null,
                  });
                  setIsCurrentlyZoomedIn(false);
                }}
                text={'Clear all'}
                variant={'mutedOutline'}
                color={'brand.lightBlue'}
                borderWidth={0}
                fontWeight={'normal'}
                size='xs'
              />
              {sectionQuickFilters?.map((filter) =>
                getSignalHistoryFilter({
                  dispatch,
                  filter,
                  fiscalYearStartingMonth,
                  selectedTimePeriod,
                  setSelectedTimePeriod,
                  state,
                  typeOptions: typeOptions ?? [],
                  typeDetailOptions: typeDetailOptions ?? [],
                }),
              )}
              <Button
                text={count ? `More filters (${count})` : 'More filters'}
                onClick={drawerOnOpen}
                ml={4}
                bg={count ? 'brand.gray-100' : 'brand.white'}
                borderWidth={2}
                borderRadius={24}
                borderColor={'#ECF2FA'}
                fontWeight={'normal'}
                w={150}
                py={2}
                color={'brand.darkGrayBlue'}
                variant={'actionOutline'}
                rightIcon={<FilterIcon boxSize={5} />}
                size='xs'
              />
            </ButtonGroup>
          }
        />
        <Text pl={6} fontSize={'sm'}>
          {state.currentStartDate &&
            formatDateString(state.currentStartDate, 'MMM dd, yyyy')}
          -
          {state.currentEndDate &&
            formatDateString(state.currentEndDate, 'MMM dd, yyyy')}
        </Text>
        {state.chartData && (
          <SingleAccountSignalHistoryChart
            chartData={state.chartData}
            isCurrentlyZoomedIn={isCurrentlyZoomedIn}
            setIsCurrentlyZoomedIn={setIsCurrentlyZoomedIn}
            updateStartAndEndDates={(newStartDate, newEndDate) =>
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_START_AND_END_DATES,
                payload: { newStartDate, newEndDate },
              })
            }
            currentTimePeriod={selectedTimePeriod}
            currentGranularity={state.currentGranularity}
            zoomOut={() => {
              const { startDate, endDate } = mapDropdownTimeInputToDates(
                selectedTimePeriod,
                fiscalYearStartingMonth,
              );
              dispatch({
                type: SingleAccountSignalActionType.UPDATE_START_AND_END_DATES,
                payload: { newStartDate: startDate, newEndDate: endDate },
              });
            }}
          />
        )}
        {state.tableData && (
          <SingleAccountSignalDetailsTable
            currentGranularity={state.currentGranularity}
            currentUnitType={state.currentUnitType}
            tableData={state.tableData}
            columns={
              sectionTableColumns ?? [
                SignalHistorySectionTableColumn.typeWithDot,
                SignalHistorySectionTableColumn.typeDetail,
                SignalHistorySectionTableColumn.topic,
                SignalHistorySectionTableColumn.topicDetail,
                SignalHistorySectionTableColumn.orderNumber,
                SignalHistorySectionTableColumn.quantity,
              ]
            }
          />
        )}
        <SingleAccountSignalHistoryFilterDrawer
          dispatch={dispatch}
          drawerIsOpen={drawerIsOpen}
          drawerOnClose={drawerOnClose}
          fiscalYearStartingMonth={fiscalYearStartingMonth}
          sectionPanelFilters={sectionPanelFilters}
          selectedTimePeriod={selectedTimePeriod}
          setSelectedTimePeriod={setSelectedTimePeriod}
          state={state}
          typeDetailOptions={typeDetailOptions ?? []}
          typeOptions={typeOptions ?? []}
        />
      </Stack>
    </Card>
  );
};
