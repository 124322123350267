import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { AccountHeaderProps } from 'app/src/components/Account/components/sav/header/AccountHeader';
import { AccountHeaderItemRenderer } from 'app/src/components/Account/components/sav/header/AccountHeaderItemRenderer';
import { ApiGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { cleanStringCase } from 'shared/helpers/formatHelpers';
import { getStatusCircleIcon } from 'shared/renderers/helpers/getStatusCircleIcon';

type AccountHeaderContentProps = {
  accountInfo: ApiGetAccountQuery['account'];
  dataProcessedAt: string;
  userCompanyId: any;
} & AccountHeaderProps;

export const AccountHeaderContent = ({
  accountInfo,
  dataProcessedAt,
  items,
  userCompanyId,
}: AccountHeaderContentProps) => (
  <Box
    bg={'brand.white'}
    color={'brand.black'}
    p={6}
    mb={4}
    fontWeight={'bold'}
    borderBottomWidth={1}
    borderBottomStyle={'solid'}
    borderBottomColor={'brand.gray-300'}
  >
    <AccountHeaderTextSection
      accountInfo={accountInfo}
      dataProcessedAt={dataProcessedAt}
      items={items}
      userCompanyId={userCompanyId}
    />
  </Box>
);

const AccountHeaderTextSection = ({
  accountInfo,
  dataProcessedAt,
  items,
  userCompanyId,
}: AccountHeaderContentProps) => (
  <>
    <Flex
      justifyContent={'space-between'}
      direction={['column', null, null, null, 'row']}
    >
      <Box>
        <Flex
          mr={12}
          flexWrap={'nowrap'}
          mb={[4, null, null, null, 0]}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text as={'h1'} fontSize={'2xl'} fontWeight={'bold'}>
            {accountInfo?.accountName ?? ''}
          </Text>
          <div style={{ display: 'flex', gap: '10px' }}>
            <span>
              {accountInfo?.customerStatus
                ? getStatusCircleIcon(accountInfo.customerStatus)
                : ''}
            </span>
            <Text
              color={'#70798B'}
              fontSize={'xs'}
              fontWeight={'200'}
              display={'flex'}
              alignItems={'center'}
              gap={'8px'}
            >
              {accountInfo?.customerStatus
                ? cleanStringCase(accountInfo.customerStatus)
                : ''}{' '}
            </Text>
          </div>
          <Text
            style={{
              color: '#70798B',
              fontSize: '12px',
              fontWeight: '600',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginTop: '10px',
            }}
          >
            {accountInfo ? (
              userCompanyId === 10 ? (
                `SHIP TO PARTY ID : ${accountInfo?.crmAccountId}`
              ) : (
                <>
                  ID :{' '}
                  {accountInfo?.['crmAccountId'].split(',')?.length > 1
                    ? accountInfo?.['crmAccountId']
                        .split(',')
                        ?.map((item, index) => {
                          return (
                            <>
                              {item.split(':')[item.split(':').length - 1]}
                              {index !==
                              accountInfo?.['crmAccountId'].split(',').length -
                                1
                                ? ','
                                : ''}
                            </>
                          );
                        })
                    : accountInfo?.['crmAccountId']}
                </>
              )
            ) : null}
          </Text>
        </Flex>
      </Box>
      <SimpleGrid
        columns={[
          1,
          2,
          null,
          items?.length ? Math.ceil(items.length / 2) : 4,
          items?.length ?? 5,
        ]}
        gap={6}
        alignItems={'flex-start'}
      >
        {items.map((item) => (
          <Box key={item.label}>
            {AccountHeaderItemRenderer(item, accountInfo)}
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
    <Text color={'brand.white'} mt={2} fontSize={'xs'} fontWeight={'normal'}>
      Data was last updated on {dataProcessedAt}.
    </Text>
  </>
);
