import { useContext, useEffect, useState } from 'react';
import { FilterIndices } from '../../../types/filterTypes';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { OptionsType } from 'shared/types/coreTypes.d';
import classnames from 'classnames';
import { Text } from '@chakra-ui/react';
import { pluralizeAccountsString } from 'shared/helpers/formatHelpers';

export const SelectedAccountItems = ({
  isVertical = false,
  filterText = '',
  filterIndex,
}: {
  isVertical?: boolean;
  filterText?: string;
  filterIndex: FilterIndices;
}) => {
  const { globalFilter } = useContext(AggregateDataContext);
  const [filteredItems, setFilteredItems] = useState<OptionsType[]>([]);

  useEffect(() => {
    if (globalFilter && globalFilter[filterIndex])
      if (isVertical) {
        setFilteredItems(
          globalFilter[filterIndex].filter((item) => {
            const valToCompare = item.label.slice(0, filterText.length);
            return valToCompare.toLowerCase() === filterText.toLowerCase();
          }),
        );
      } else {
        setFilteredItems(globalFilter[filterIndex]);
      }
  }, [filterText, globalFilter]);
  const filteredLengthDiff =
    globalFilter &&
    globalFilter[filterIndex] &&
    globalFilter[filterIndex].length - filteredItems.length;

  const wrapperClassnames = classnames({
    'h-flex': true,
    'h-flex-wrap': true,
    'h-flex-column': isVertical,
    'filter-bar-wrapper__accounts-wrapper': true,
  });

  return (
    <div className={wrapperClassnames}>
      {!!filteredItems?.length &&
        filteredItems.map((account) => (
          <AccountItem
            account={account}
            key={account.value}
            filterIndex={filterIndex}
          />
        ))}
      {!!filteredLengthDiff && isVertical && (
        <Text ml={2} fontSize={'13px'}>
          {`+ ${filteredLengthDiff} ${pluralizeAccountsString(
            filteredLengthDiff,
          )}`}
        </Text>
      )}
    </div>
  );
};

const AccountItem = ({
  account,
  filterIndex,
}: {
  account: OptionsType;
  filterIndex: FilterIndices;
}) => {
  // const [isHoveringOverRemove, setIsHoveringOverRemove] =
  //   useState<boolean>(false);
  const { globalFilter, updateFilter } = useContext(AggregateDataContext);

  const removeAccount = (itemId) => {
    if (filterIndex === FilterIndices.ACCOUNTS_FULL_FILTER) {
      updateFilter({
        index: FilterIndices.ACCOUNTS_FILTER,
        value: globalFilter?.accountIds?.filter(
          (id) => id.toString() !== itemId.toString(),
        ),
      });
    }
    updateFilter({
      index: filterIndex,
      value: globalFilter![filterIndex].filter((item) => item.value !== itemId),
    });
  };

  return (
    <span
      className={'filter-bar-wrapper__account-item-wrapper'}
      key={account.value}
    >
      <span
        className={'h-ml-md filter-bar-wrapper__account-item-text'}
        style={{ fontSize: '13px' }}
      >
        {account.label}
      </span>
      <span
        className={'h-p-xs filter-bar-wrapper__account-item-icon'}
        onClick={() => removeAccount(account.value)}
        // onMouseEnter={() => {
        //   setIsHoveringOverRemove(true);
        // }}
        // onMouseLeave={() => setIsHoveringOverRemove(false)}
      >
        {/*<Icon*/}
        {/*  // Don't use hoverColor prop so that we can have some hit slop padding for mobile*/}
        {/*  color={isHoveringOverRemove ? 'red' : 'gray-800'}*/}
        {/*  name={'io-x'}*/}
        {/*  size={'md'}*/}
        {/*/>*/}
      </span>
    </span>
  );
};
