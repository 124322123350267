import { useCallback, useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import _debounce from 'lodash/debounce';
import _size from 'lodash/size';
import AsyncSelect from 'react-select/async';
import {
  useInput,
  UseInputHookArrayValueTypes,
  UseInputHookReturnType,
} from 'shared/hooks/inputHook';
import { addOptionComponentDataAcceptance } from 'app/src/helpers/analyticsHelpers';
import { GetAggregateDataWithQueryDocument } from 'shared/graphql/generatedApiTypes';
import { OptionsType } from 'shared/types/coreTypes.d';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { OptionTypeBase, ValueType } from 'react-select';
import { useKeyPress } from 'app/src/hooks/useKeyPress';
import {
  filterIndexToString,
  FilterIndices,
} from 'app/src/components/GlobalFilters/types/filterTypes';
import { getAccountsFilterItemStyles } from 'app/src/components/GlobalFilters/helpers/getAccountsFilterItemStyles';
import _omit from 'lodash/omit';
import { adaptDictAsDict } from 'app/src/components/GlobalFilters/helpers/adaptFilterForQuery';
import { FilterDictType } from 'app/src/components/GlobalFilters/types/filterDict';
import { pluralizeAccountsString } from 'shared/helpers/formatHelpers';

type AggregateSearchFilterItemProps = {
  filterIndex: FilterIndices;
  itemType: 'panel' | 'bar' | 'wideMenu';
};

type AggregateSearchFilterItemState = {
  currentInputValue: string;
  defaultOptions: Array<{ value: string | number; label: string }>;
  isMenuOpen: boolean;
};

const initialState: AggregateSearchFilterItemState = {
  currentInputValue: '',
  defaultOptions: [],
  isMenuOpen: false,
};

export const AggregateSearchFilterItem = ({
  itemType,
  filterIndex,
}: AggregateSearchFilterItemProps) => {
  const { globalFilter, updateFilter, loading, fullAggregateLoading } =
    useContext(AggregateDataContext);
  const apolloClient = useApolloClient();
  const isFilterApplied =
    globalFilter &&
    globalFilter[filterIndex] &&
    globalFilter[filterIndex].length;

  const isDisabled = loading || fullAggregateLoading;

  // make sure that the filter bar accounts item closes on ESC key press
  const escapePress = useKeyPress('Escape');
  useEffect(() => {
    if (escapePress && itemType === 'bar') {
      handleMenuClose();
    }
  }, [escapePress, itemType]);

  const [{ defaultOptions, isMenuOpen }, setState] =
    useState<AggregateSearchFilterItemState>(initialState);

  const _handleLoadOptions = useCallback(
    async (inputValue, callback) => {
      try {
        const res = await apolloClient.query({
          query: GetAggregateDataWithQueryDocument,
          variables: {
            query: inputValue,
            type: 'account',
            field: filterIndex,
          },
        });
        const cleanedFilterDict = _omit(
          adaptDictAsDict(globalFilter as FilterDictType, [filterIndex]),
          [FilterIndices.ACCOUNTS_FILTER_TYPE, ...filterIndex],
        );
        const areFiltersApplied = _size(cleanedFilterDict) > 0;
        const cleanedSelectOptions = res.data.aggregateDataSearch.map(
          (item) => ({
            value: item.value,
            label: areFiltersApplied
              ? `${item.value} (${item.filteredCount} of ${
                  item.totalCount
                } ${pluralizeAccountsString(item.totalCount)})`
              : `${item.value} (${item.totalCount} ${pluralizeAccountsString(
                  item.totalCount,
                )}) `,
          }),
        );
        setState((prevState) => ({
          ...prevState,
          defaultOptions: cleanedSelectOptions,
        }));
        return callback(cleanedSelectOptions);
      } catch {
        return callback(null);
      }
    },
    [apolloClient, globalFilter],
  );

  const handleSelection = (
    selectedOptions: ValueType<Array<OptionTypeBase>, false>,
  ) => {
    if (!selectedOptions || !selectedOptions.length) return;
    setItems(selectedOptions);
    // Make sure it's not in the list already
    if (
      !globalFilter ||
      !globalFilter[filterIndex] ||
      !globalFilter[filterIndex].find(
        (acct) => acct.value === selectedOptions[0].value,
      )
    ) {
      updateFilter({
        index: filterIndex,
        value: globalFilter![filterIndex]
          ? [
              ...globalFilter![filterIndex],
              ...(selectedOptions as OptionsType[]).map((item) => item.value),
            ]
          : [...(selectedOptions as OptionsType[]).map((item) => item.value)],
      });
    }
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      defaultOptions: [],
      isMenuOpen: false,
    }));
  };

  // setup input bind
  const { bind: bindItems, setValue: setItems } = useInput([], {
    defaultValue: [],
    label: undefined,
    options: [],
    placeholder: `${filterIndexToString[filterIndex]} (${
      (globalFilter &&
        globalFilter[filterIndex] &&
        globalFilter[filterIndex].length) ??
      0
    } selected)`,
  }) as UseInputHookReturnType & UseInputHookArrayValueTypes;

  return (
    <fieldset className={'filter-bar-item c-text-field h-pb-none'}>
      <label className={'c-text-field__wrapper'} htmlFor={filterIndex}>
        <div className={'c-text-field__input-block'}>
          <div className={'h-relative'}>
            <AsyncSelect
              {...bindItems}
              backspaceRemovesValue={false}
              className={
                'c-text-field__input analytics-attr-aggregate-search-filter-bar-select-control'
              }
              closeMenuOnSelect={false}
              components={{
                Option: addOptionComponentDataAcceptance(
                  'aggregate-search-filter-bar-select-option',
                ),
              }}
              defaultOptions={defaultOptions}
              inputId={'aggregate-search-filter-bar-react-select'}
              isDisabled={isDisabled}
              isMulti={true}
              loadOptions={_debounce(_handleLoadOptions, 500, {
                leading: false,
                trailing: true,
              })}
              menuIsOpen={isMenuOpen}
              noOptionsMessage={({ inputValue }) =>
                _size(inputValue) > 0 ? 'No options' : 'Start typing to add'
              }
              onChange={(selectedOptions, { action }) =>
                action === 'select-option' && handleSelection(selectedOptions)
              }
              onInputChange={(val) =>
                setState((prevState) => ({
                  ...prevState,
                  currentInputValue: val,
                }))
              }
              onMenuClose={itemType === 'panel' ? handleMenuClose : undefined}
              onMenuOpen={() =>
                setState((prevState) => ({ ...prevState, isMenuOpen: true }))
              }
              options={bindItems.options as readonly any[]}
              styles={getAccountsFilterItemStyles(
                itemType,
                isMenuOpen,
                isFilterApplied,
              )}
              value={null}
            />
          </div>
        </div>
      </label>
    </fieldset>
  );
};
