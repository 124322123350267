export const sortByDate = (array: any[], fieldToSortBy: string) => {
  const arrayCopy = [...array];
  return arrayCopy.sort((a, b) => {
    if (!a[fieldToSortBy] || !b[fieldToSortBy]) return 1;
    const parsedA = Date.parse(a[fieldToSortBy]);
    const parsedB = Date.parse(b[fieldToSortBy]);
    if (!parsedA || !parsedB) return 1;
    return parsedA - parsedB;
  });
};
