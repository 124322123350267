import { createContext, useContext, useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'shared/graphql';
import { Loading } from 'shared/components/Core';
import { FirebaseInvokedAuth } from 'shared/firebase/types/firebaseTypes';
import AuthManager from 'shared/firebase/classes/AuthManager';

const withAuthentication = (Component) => {
  return (props) => {
    const [authUser, setAuthUser] = useState<
      FirebaseInvokedAuth['currentUser'] | null
    >(null);
    const [isAuthenticationLoading, setIsAuthenticationLoading] =
      useState<boolean>(true);
    const client = apolloClient(authUser);

    useEffect(() => {
      if (AuthManager.invokedAuth) {
        AuthManager.invokedAuth.onAuthStateChanged((newAuthUser) => {
          AuthManager.setCurrentUser(newAuthUser);
          setAuthUser(newAuthUser ?? null);
          setIsAuthenticationLoading(false);
        });
      }
    });

    return (
      <AuthUserContext.Provider value={authUser}>
        <ApolloProvider client={client}>
          {isAuthenticationLoading ? (
            <Loading
              dotColor={'color'}
              w={'100vw'}
              h={'100vh'}
              text={'Loading...'}
            />
          ) : (
            <Component {...props} />
          )}
        </ApolloProvider>
      </AuthUserContext.Provider>
    );
  };
};

export default withAuthentication;

const AuthUserContext = createContext<
  FirebaseInvokedAuth['currentUser'] | null
>(null);

export function useAuthContext() {
  return useContext(AuthUserContext);
}
