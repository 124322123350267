import { Button } from 'shared/components/Core';
import {
  appliedFilterVar,
  appliedListsVar,
  inTemporaryGlobalFilterStateVar,
  showingFilterPanelVar,
} from 'app/src/apollo/rootReactiveVariables';
import { useContext, useEffect, useMemo } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { AppNavigationalStructureContext } from 'app/src/context/AppNavigationalStructureContext';
import { FilterItem } from 'app/src/components/GlobalFilters/components/filterItems/FilterItem';
import { FilterIcon } from 'shared/components/Core/Icon/filter';
import { useWindowSize } from 'app/src/hooks/useWindowSize';
import { navbarSizeInt } from 'app/src/constants/navbarSize';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { groupAccountGroups } from 'app/src/components/GlobalFilters/helpers/groupAccountGroups';
import { useGetAllAccountGroupsQuery } from 'shared/graphql/generatedApiTypes';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { filterStringFromBackendToDict } from 'app/src/components/GlobalFilters/helpers/filterDictAndFilterStringHelpers';
import { FilterDictType } from 'app/src/components/GlobalFilters/types/filterDict';
import { OptionsType } from 'shared/types/coreTypes.d';
import { FilterItemOption } from 'shared/firebase/schemas/SavedStructure';
import Select, { components } from 'react-select';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { UserSettingsDocument } from 'shared/firebase/documents/UserSettingsDocument';

export const MainFilterBar = ({
  getUserSettingsValue,
  getUserSettingsExecute,
  getUserSettingsPending,
}: {
  getUserSettingsValue: UserSettingsDocument | null;
  getUserSettingsExecute: () => void;
  getUserSettingsPending: boolean;
}) => {
  const showingPanel = useReactiveVar(showingFilterPanelVar);
  const { structure } = useContext(AppNavigationalStructureContext);
  const {
    updateFilter,
    overrideFilter,
    globalFilter,
    loading,
    fullAggregateLoading,
  } = useContext(AggregateDataContext);
  const globalFiltersStructure = structure?.filters;
  const { width: windowWidth } = useWindowSize();
  const { data: accountGroupData } = useGetAllAccountGroupsQuery();
  const appliedLists = useReactiveVar(appliedListsVar);
  const inTemporaryGlobalFilterState = useReactiveVar(
    inTemporaryGlobalFilterStateVar,
  );
  const isLoading = loading || fullAggregateLoading;

  useEffect(() => {
    getUserSettingsExecute();
  }, []);

  const { favoriteListOptions, favoriteFilterOptions } = useMemo(() => {
    if (accountGroupData && getUserSettingsValue) {
      const { lists, groups } = groupAccountGroups({
        accountGroups: accountGroupData?.accountGroups ?? [],
        klearlyUser: AuthManager.klearlyUser,
        showOnlyLoggedInUsersGroups: false,
      });
      const favoriteListOptions = lists
        .filter((list) =>
          getUserSettingsValue?.data?.listFavorites?.find(
            (listFavorite) => listFavorite === list?.id,
          ),
        )
        .map((list) => ({ label: list?.title, value: `${list?.id}#list` }));
      const favoriteFilterOptions = groups
        .filter((filter) =>
          getUserSettingsValue?.data?.filterFavorites?.find(
            (filterFavorite) => filterFavorite === filter?.id,
          ),
        )
        .map((filter) => ({
          label: filter?.title,
          value: `${filter?.id}#filter`,
        }));

      return { favoriteListOptions, favoriteFilterOptions };
    }
    return { favoriteListOptions: [], favoriteFilterOptions: [] };
  }, [accountGroupData, getUserSettingsValue]);

  const applySelection = (selectedValue: string) => {
    const splitValue = selectedValue.split('#');
    if (splitValue[splitValue.length - 1] === 'list') {
      if (!appliedLists.includes(parseInt(selectedValue))) {
        updateFilter({
          index: FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS,
          value: [...appliedLists, parseInt(selectedValue)],
        });
      } else {
        updateFilter({
          index: FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS,
          value: appliedLists.filter(
            (list) => list !== parseInt(selectedValue),
          ),
        });
      }
    } else {
      const selectedFilter = accountGroupData?.accountGroups?.find(
        (group) => group?.id === parseInt(splitValue[0]),
      );
      if (globalFilter) {
        const selectedDict = filterStringFromBackendToDict(
          selectedFilter?.filterString ?? '',
        );
        appliedFilterVar({
          id: parseInt(splitValue[0]),
          title: selectedFilter?.title ?? '',
        });
        const newFilter: FilterDictType & { accounts: Array<OptionsType> } = {
          [FilterIndices.EXCLUDED_ACCOUNT_IDS]:
            globalFilter[FilterIndices.EXCLUDED_ACCOUNT_IDS],
          [FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS]:
            globalFilter[FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS],
          ...selectedDict,
        };
        overrideFilter(newFilter);
      }
    }
  };

  // use these if they exist, otherwise use the company defaults
  const favoritedFilters = useMemo(() => {
    const allAvailableFilters =
      globalFiltersStructure?.panel.map((item) => item.filters).flat() ?? [];
    return getUserSettingsValue?.data?.quickAccessFilters?.map(
      (userFavoriteField) =>
        allAvailableFilters.find(
          (panelFilter) => panelFilter.field === userFavoriteField,
        ),
    );
  }, [getUserSettingsValue]);

  return (
    <Flex
      px={4}
      py={2}
      justifyContent={'space-between'}
      maxW={`${windowWidth + 4 - navbarSizeInt}px`}
      align={'center'}
      bg={'#F5F8F9'}
    >
      <Flex align={'center'}>
        <Button
          analyticsAttr={'more-filters-button'}
          isDisabled={isLoading || !!inTemporaryGlobalFilterState}
          leftIcon={<FilterIcon boxSize={5} />}
          borderWidth={2}
          borderRadius={24}
          borderColor={'#ECF2FA'}
          fontWeight={'normal'}
          minW={'200px'}
          onClick={() => showingFilterPanelVar(!showingPanel)}
          p={4}
          ml={2}
          text={showingPanel ? 'Hide filters' : 'Show filters'}
          variant={'actionOutline'}
        />
        <HStack align={'center'} spacing={2} gap={1} mx={6} flexWrap={'wrap'}>
          {(inTemporaryGlobalFilterState || getUserSettingsPending
            ? []
            : favoritedFilters
            ? favoritedFilters
            : globalFiltersStructure?.defaultQuickAccessFilters ?? []
          ).map((item, index) =>
            item ? (
              [
                FilterItemOption.checkboxDropdown,
                FilterItemOption.checkboxes,
                FilterItemOption.groupedCheckboxDropdown,
                FilterItemOption.searchableDropdown,
                FilterItemOption.quickFiltersSelect,
              ].includes(item.type) ? (
                <Box key={index} ml={index === 0 ? 2 : 0}>
                  <FilterItem
                    filterItem={
                      item.type === FilterItemOption.checkboxes
                        ? { ...item, type: FilterItemOption.checkboxDropdown }
                        : item
                    }
                    itemSource={'bar'}
                  />
                </Box>
              ) : (
                <Popover key={index}>
                  <PopoverTrigger>
                    <Button
                      text={item.title}
                      minW={'200px'}
                      rightIcon={<ChevronDownIcon boxSize={6} />}
                      color={'brand.black'}
                      fontWeight={'normal'}
                      bg={'transparent'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      borderWidth={1}
                      borderColor={'brand.gray-300'}
                      py={1}
                      px={2}
                      variant={'transparent'}
                      ml={index === 0 ? 2 : 0}
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    minW={
                      item.type === FilterItemOption.calendar
                        ? '700px'
                        : '500px'
                    }
                    p={1}
                  >
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <FilterItem
                        filterItem={item}
                        itemSource={'bar'}
                        key={index}
                      />
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )
            ) : null,
          )}
        </HStack>
      </Flex>
      {!inTemporaryGlobalFilterState && (
        <Select
          value={null}
          components={{ Option }}
          placeholder={'Your favorites'}
          styles={{
            menu: (styles) => ({
              ...styles,
              width: '200px',
            }),
            control: (styles) => ({
              ...styles,
              width: '200px',
              backgroundColor: '',
              borderWidth: 1,
              color: '#30384a',
              marginRight: '8px',
            }),
            placeholder: (styles) => ({
              ...styles,

              color: '#30384a',
            }),
            indicatorSeparator: () => ({ display: 'none' }),
            option: (styles) => ({
              ...styles,
            }),
          }}
          options={[
            { label: 'Lists', options: favoriteListOptions },
            { label: 'Filters', options: favoriteFilterOptions },
          ]}
          onChange={(newVal) => {
            applySelection(newVal ? newVal.value : '');
          }}
        />
      )}
    </Flex>
  );
};

const Option = (props: any) => {
  const appliedLists = useReactiveVar(appliedListsVar);
  const value = props.value.split('#')[0];
  const isSelected = appliedLists.includes(parseInt(value));
  return (
    <Flex align={'center'} justify={'space-around'}>
      <Box
        w={2}
        h={2}
        ml={2}
        borderRadius={16}
        bg={isSelected ? 'brand.gray-800' : 'brand-white'}
      />
      <components.Option {...props} />
    </Flex>
  );
};
