import {LabBarChartProps} from '../../types/labChartTypes';
import {BarChart} from '../../../../../../shared/components/Core';
import {getBarChartLabelValue} from '../../helpers/labChartHelpers';

export const LabBarChart = ({
  columns,
  context = undefined,
  isAnswerTypePercent = false,
  isChartClickable = false,
  isDollars = false,
  isSmallerViewport = false,
  monoColor = undefined,
  rows,
  totalNumber = 0,
}: LabBarChartProps) => (
  <BarChart
    data={getBarChartLabelValue({
      columns,
      context,
      isDollars,
      rows,
      totalNumber,
    })}
    events={undefined}
    isChartClickable={isChartClickable}
    isPercent={isAnswerTypePercent}
    isSmallerViewport={isSmallerViewport}
    monoColor={monoColor}
    height={300}
    width={'100%'}
  />
);
