import {Fragment, ReactNode} from 'react';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';

import DataGrid from './DataGrid';
import {HeatMapDefaultItems, HeatMapPropsType} from './HeatMap';

type ColumnsType = HeatMapDefaultItems & {
  columnsFormatter: (props: HeatMapPropsType) => ReactNode;
  data: Array<HeatMapPropsType>;
  onClick: ((props: HeatMapPropsType) => ReactNode | void) | undefined;
  rowsId: string;
};

const Columns = ({
  columns = [],
  columnsFormatter = (props) => props.label,
  columnsId = 'columnId',
  data = [],
  gridHeight = 100,
  gridLabelFormatter = (props) => props.label,
  onClick = undefined,
  rows = [],
  rowsId = 'rowId',
  showTooltip = true,
  tooltipFormatter = (props) => props.label,
}: ColumnsType) => (
  <>
    {_map(columns, (column, index) => {
      const grids = _filter(data, (d) => d[columnsId] === column.id);
      const orderedGrids: Array<any> = _map(rows, (row) =>
        _find(grids, (grid) => grid[rowsId] === row.id),
      );
      return (
        <Fragment key={index}>
          <span
            className={'heatmap-column-label'}
            style={{ height: `${gridHeight}px` }}
          >
            {columnsFormatter(column)}
          </span>
          <DataGrid
            columns={columns}
            columnsId={columnsId}
            gridHeight={gridHeight}
            gridLabelFormatter={gridLabelFormatter}
            grids={orderedGrids}
            onClick={onClick}
            rows={rows}
            showTooltip={showTooltip}
            tooltipFormatter={tooltipFormatter}
          />
        </Fragment>
      );
    })}
  </>
);

export default Columns;
