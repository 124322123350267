import { ReactNode } from 'react';
import { Card } from '../../../../../../../../../shared/components/Core';

export const SingleAccountQuickStatWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Card
      minH={36}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      {children}
    </Card>
  );
};
