import { Timestamps } from './Timestamps';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { FullSavedPageSchema } from './SavedPage';

export interface SavedStructureSchema extends Timestamps {
  name: string;
  fiscalYearStartingMonth: number;
  filters: GlobalFiltersStructure;
  pages: string[]; // reference saved page schema
}

export type FullSavedStructureSchema = Omit<
  SavedStructureSchema,
  'pages' | 'createdAt' | 'updatedAt'
> & {
  pages: FullSavedPageSchema[];
};

export type GlobalFiltersStructure = {
  defaultAppliedFilter: string;
  panel: FilterPanelSection[];
  defaultQuickAccessFilters: FilterItemType[];
};

export type FilterPanelSection = {
  filters: FilterItemType[];
  label: string;
  order: number;
};

export type FilterItemType = {
  additionalFields?: FilterIndices[];
  field: FilterIndices;
  removeValues?: any[];
  title: string;
  tooltip?: string;
  type: FilterItemOption;
};

export enum FilterItemOption {
  searchableDropdown = 'searchableDropdown',
  checkboxDropdown = 'checkboxDropdown',
  groupedCheckboxDropdown = 'groupedCheckboxDropdown',
  quickFiltersSelect = 'quickFiltersSelect',
  ranger = 'ranger',
  dollarRanger = 'dollarRanger',
  hundredPointScaleRanger = 'hundredPointScaleRanger',
  percentageScaleRanger = 'percentageScaleRanger',
  checkboxes = 'checkboxes',
  radio = 'radio',
  calendar = 'calendar',
}
