import Calendar from 'react-calendar';

type CalendarRangeType = {
  endDate: Date | undefined;
  setEndDate: (date: Date | null) => void;
  setStartDate: (date: Date | null) => void;
  startDate: Date | undefined;
};

const CalendarRange = ({
  endDate,
  setEndDate,
  setStartDate,
  startDate,
}: CalendarRangeType) => {
  const dateRange = startDate && endDate ? [startDate, endDate] : null;

  return (
    <Calendar
      showDoubleView
      locale={'en-US'}
      maxDate={new Date()}
      onChange={(val) => {
        setStartDate(val[0]);
        setEndDate(val[1]);
      }}
      selectRange
      value={dateRange}
    />
  );
};

export { CalendarRange };
