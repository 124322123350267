import { memo, ReactNode, useRef, useState } from 'react';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import _maxBy from 'lodash/maxBy';
import _size from 'lodash/size';
import _sum from 'lodash/sum';

import { Pie, PieChart as RechartsPieChart, Sector, Tooltip } from 'recharts';
import { RechartsBase, RechartsTooltip, Txt } from './index';

import {
  COLOR_PALETTE,
  PIE_CHART_DATA_VALUE,
  PIE_CHART_NAME_VALUE,
} from 'app/src/constants/chartConstants';

type PieChartTooltipType = {
  data: PieChartProps['data'];
  plotToolText: string;
  useActiveIndex: boolean;
};

type PieChartLegendType = {
  activeChartDataItem: { fill?: string };
  text: Array<string>;
  useActiveIndex: boolean;
};

type PieChartWrapperType = {
  activeChartDataItem: { fill?: string };
  chartClickableType?: string;
  children: ReactNode;
  data: PieChartProps['data'];
  height: string;
  isChartClickable: boolean;
  isSmallerViewport: boolean;
  plotToolText: string;
  text: Array<string>;
  useActiveIndex: boolean;
  width: string;
};

type PieChartLabelType = {
  cx?: number;
  cy?: number;
  fill?: string;
  innerRadius?: number;
  isLargerDataset: boolean;
  isPercent: boolean;
  isSmallerViewport: boolean;
  largestItem: { key: string; value: number };
  midAngle?: number;
  outerRadius?: number;
  payload?: { key: string; value: string };
};

type PieChartActiveShapeType = {
  item: {
    cx: number;
    cy: number;
    endAngle: number;
    fill: string;
    innerRadius: number;
    midAngle: number;
    outerRadius: number;
    payload: {
      fill?: string;
      key?: string;
      name?: string;
      value?: number;
    };
    startAngle: number;
  };
  data: PieChartProps['data'];
};

export type PieChartProps = {
  data: Array<{ key: string; value: number }>;
  events:
    | {
        dataPlotClick?: ({ index: number }) => void;
      }
    | undefined;
  height: string;
  isChartClickable: boolean;
  isPercent: boolean;
  isSmallerViewport: boolean;
  plotToolText?: string;
  width: string;
};

const tooltipTextItems = (
  dataPayload: PieChartActiveShapeType['item']['payload'],
  plotToolText: string = '',
  data: PieChartProps['data'],
) => {
  const totalValue = _sum(_map(data, PIE_CHART_DATA_VALUE));
  const { key = '', value = 0 } = { ...dataPayload };
  const percentValue = ((100 * value) / totalValue).toFixed(2);
  return plotToolText
    .replace('$label', key)
    .replace('$dataValue', value.toLocaleString())
    .replace('$percentValue', `${percentValue}%`)
    .split(', ');
};

const renderTooltipContent = (
  dataPayload: PieChartActiveShapeType['item']['payload'],
  plotToolText: string,
  data: PieChartProps['data'],
  showColor: boolean,
) => {
  const tooltipText = tooltipTextItems(dataPayload, plotToolText, data);
  return _map(tooltipText, (text, idx) => (
    <p key={idx} style={{ color: showColor ? dataPayload?.fill : undefined }}>
      {text}
    </p>
  ));
};

const PieChartLabel = ({
  cx = 0,
  cy = 0,
  fill,
  innerRadius = 0,
  isLargerDataset = false,
  isPercent = false,
  isSmallerViewport = false,
  largestItem,
  midAngle = 0,
  outerRadius = 0,
  payload,
}: PieChartLabelType) => {
  const { key = '', value = 0 } = { ...payload };
  const hideItem = isLargerDataset && largestItem?.key !== key;
  if (hideItem) {
    return <></>;
  }
  const RADIAN = Math.PI / 180;
  const radius = 25 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      className={isSmallerViewport ? 'svg-text-medium-font' : ''}
      dominantBaseline={'central'}
      fill={fill}
      textAnchor={x > cx ? 'start' : 'end'}
      x={x}
      y={y}
    >
      <tspan>{key}</tspan>
      {!isLargerDataset && (
        <tspan dy={20} x={x}>{`${value.toLocaleString()}${
          isPercent ? '%' : ''
        }`}</tspan>
      )}
    </text>
  );
};

const renderActiveShape = (
  {
    cx,
    cy,
    endAngle,
    fill,
    innerRadius,
    midAngle,
    outerRadius,
    payload,
    startAngle,
  }: PieChartActiveShapeType['item'],
  data: PieChartActiveShapeType['data'],
  plotToolText: string,
  showText: boolean = false,
) => {
  const RADIAN = Math.PI / 180;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  const tooltipText = tooltipTextItems(payload, plotToolText, data);
  return (
    <g>
      <text dy={8} fill={fill} textAnchor={'middle'} x={cx} y={cy}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
      />
      <Sector
        cx={cx}
        cy={cy}
        endAngle={endAngle}
        fill={fill}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        startAngle={startAngle}
      />
      {showText && (
        <>
          <path d={`M${sx},${sy}L${mx},${my}`} fill={'none'} stroke={fill} />
          <circle cx={mx} cy={my} fill={fill} r={2} stroke={'none'} />
          {_map(tooltipText, (text, idx) => (
            <text
              dy={15 * idx}
              fill={fill}
              key={idx}
              textAnchor={textAnchor}
              x={mx + (cos >= 0 ? 1 : -1) * 12}
              y={my}
            >
              {text}
            </text>
          ))}
        </>
      )}
    </g>
  );
};

const PieChartTooltip = ({
  data = [],
  plotToolText = '',
  useActiveIndex = false,
}: PieChartTooltipType) => (
  <>
    {!useActiveIndex && (
      <Tooltip
        content={
          <RechartsTooltip
            renderContent={(dataPayload) =>
              renderTooltipContent(dataPayload, plotToolText, data, false)
            }
          />
        }
      />
    )}
  </>
);

const PieChartLegend = ({
  activeChartDataItem = {},
  text = [],
  useActiveIndex = false,
}: PieChartLegendType) => (
  <>
    {useActiveIndex && (
      <div style={{ margin: '0 auto', width: '75%' }}>
        {_map(text, (t, idx) => (
          <p
            className={'c-text h-text-align-center'}
            key={idx}
            style={{ color: activeChartDataItem?.fill }}
          >
            {t}
          </p>
        ))}
      </div>
    )}
  </>
);

const PieChartWrapper = ({
  activeChartDataItem = {},
  chartClickableType = 'slice',
  children,
  data = [],
  height = '100%',
  isChartClickable = false,
  isSmallerViewport = false,
  plotToolText = '',
  text = [],
  useActiveIndex = false,
  width = '100%',
}: PieChartWrapperType) => {
  const baseRef = useRef(null);
  if (isSmallerViewport) {
    const element = document.getElementById('pie-chart-wrapper');

    const bounds = element?.getBoundingClientRect();
    const boundsWidth = isSmallerViewport
      ? bounds?.width || parseInt(width)
      : parseInt(width);
    return (
      <div id={'pie-chart-wrapper'}>
        <Txt align={'center'} as={'i'} className={'h-mb-sm'}>
          {isChartClickable ? '(click on slice to see details)' : ''}
        </Txt>
        <RechartsPieChart height={250} width={boundsWidth}>
          {children}
          <PieChartTooltip
            data={data}
            plotToolText={plotToolText}
            useActiveIndex={useActiveIndex}
          />
        </RechartsPieChart>
        <PieChartLegend
          activeChartDataItem={activeChartDataItem}
          text={text}
          useActiveIndex={useActiveIndex}
        />
      </div>
    );
  }
  return (
    <>
      <RechartsBase
        chartClickableType={chartClickableType}
        height={height}
        isChartClickable={isChartClickable}
        ref={baseRef}
        width={width}
      >
        <RechartsPieChart>
          {children}
          <PieChartTooltip
            data={data}
            plotToolText={plotToolText}
            useActiveIndex={useActiveIndex}
          />
        </RechartsPieChart>
      </RechartsBase>
      <PieChartLegend
        activeChartDataItem={activeChartDataItem}
        text={text}
        useActiveIndex={useActiveIndex}
      />
    </>
  );
};

const PieChart = memo(
  ({
    data = [],
    events = {},
    height = '100%',
    isChartClickable = false,
    isPercent = true,
    isSmallerViewport = false,
    plotToolText = '',
    width = '99%',
  }: PieChartProps) => {
    // setup consts
    const isLargerDataset = _size(data) > 2;
    const largestItem = _maxBy(data, 'value') || { key: '', value: 0 };
    const useActiveIndex = isLargerDataset || isSmallerViewport;
    const chartData = _map(data, (d, idx) => ({
      ...d,
      fill: COLOR_PALETTE[idx],
    }));
    // setup state
    const [activeIndex, setActiveIndex] = useState(useActiveIndex && 0);
    // setup functions
    const onPieMouseOver = (_, index) =>
      useActiveIndex && setActiveIndex(index);
    // render
    const activeChartDataItem =
      activeIndex || activeIndex === 0 ? chartData[activeIndex] : {};
    const text = tooltipTextItems(activeChartDataItem, plotToolText, chartData);
    return (
      <PieChartWrapper
        activeChartDataItem={activeChartDataItem}
        data={data}
        height={height}
        isChartClickable={isChartClickable}
        isSmallerViewport={isSmallerViewport}
        plotToolText={plotToolText}
        text={text}
        useActiveIndex={useActiveIndex}
        width={width}
      >
        <Pie
          activeIndex={
            activeIndex || activeIndex === 0 ? activeIndex : undefined
          }
          activeShape={(props) =>
            renderActiveShape({ ...props }, data, plotToolText)
          }
          className={isChartClickable ? 'h-pointer' : undefined}
          data={chartData}
          dataKey={PIE_CHART_DATA_VALUE}
          isAnimationActive={false}
          label={
            !useActiveIndex && (
              <PieChartLabel
                isLargerDataset={isLargerDataset}
                isPercent={isPercent}
                isSmallerViewport={isSmallerViewport}
                largestItem={largestItem}
              />
            )
          }
          labelLine={!useActiveIndex}
          nameKey={PIE_CHART_NAME_VALUE}
          onClick={({ name }) => {
            if (isChartClickable) {
              const index = _findIndex(data, [PIE_CHART_NAME_VALUE, name]);
              if (events.dataPlotClick) {
                events.dataPlotClick({ index });
              }
            }
          }}
          onMouseOver={onPieMouseOver}
          outerRadius={isSmallerViewport ? '50%' : '75%'}
        />
      </PieChartWrapper>
    );
  },
);

export default PieChart;
