import { useEffect } from 'react';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import { Button, Container, Input } from 'shared/components/Core';
import { Flex, Stack, Text } from '@chakra-ui/react';
import * as ROUTES from 'app/src/constants/routes';
import { Redirect } from 'react-router-dom';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { ApiCrmTypeEnum } from 'shared/graphql/generatedApiTypes';
import {
  PARDOT_INSTALL_URL,
  PARDOT_LEARN_MORE_LINK,
} from 'app/src/components/Integrations/constants/integrationUrls';
import { jsonToBase64 } from 'app/src/components/Integrations/helpers/base64helpers';
import { PardotLogo } from 'shared/components/Core/Icon/pardotLogo';

export const PardotIntegrationPage = (props) => {
  const queryParams = useQueryParams();
  const companyName =
    queryParams.get('company') !== null
      ? queryParams.get('company')
      : props.companyName;
  useEffect(() => {
    updateAppHeaders(ROUTES.PARDOT_INTEGRATION_PAGE.name);
  }, []);

  if (!companyName) {
    return <Redirect to={ROUTES.INTEGRATIONS_PAGE.path} />;
  }

  return (
    <Container ph={'xs'} pv={'4xl'} width={'sm'}>
      <Stack spacing={8}>
        <Flex align={'center'} flexDir={'column'}>
          <PardotLogo boxSize={40} />
          <Text
            textAlign={'center'}
            fontSize={'3xl'}
            fontWeight={'extrabold'}
            mt={-12}
          >
            Pardot Integration for {companyName}
          </Text>
        </Flex>
        <a
          href={PARDOT_LEARN_MORE_LINK}
          rel={'noreferrer noopener'}
          target={'_blank'}
        >
          <Text
            fontSize={'sm'}
            mt={-8}
            textAlign={'center'}
            textDecoration={'underline'}
          >
            Learn more about this integration
          </Text>
        </a>
      </Stack>
      <FormBase companyName={companyName} />
    </Container>
  );
};

const FormBase = ({ companyName }: { companyName: string }) => {
  const { bind: bindPardotBusinessUnitId, value: pardotBusinessUnitId } =
    useInput('', {
      id: 'business-unit-id',
      label: 'Business Unit Id',
      name: 'business-unit-id',
      placeholder: 'Pardot Business Unit Id',
      required: true,
      type: 'text',
    }) as UseInputHookReturnType & UseInputHookStringValueTypes;

  return (
    <form className={'c-form h-pv-lg'}>
      <Stack spacing={4}>
        <>
          <Input {...bindPardotBusinessUnitId} />
        </>
        <Button
          asLink
          rel={'noreferrer noopener'}
          href={`${PARDOT_INSTALL_URL}&state=${jsonToBase64({
            companyName,
            dataSource: ApiCrmTypeEnum.Pardot,
            businessUnitId: pardotBusinessUnitId.trim(),
          })}`}
          isDisabled={!PARDOT_INSTALL_URL || !pardotBusinessUnitId}
          text={'Authorize'}
        />
      </Stack>
    </form>
  );
};
