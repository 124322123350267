import { useCallback, useMemo, useState } from 'react';
import { ApolloError, useApolloClient } from '@apollo/client';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _startCase from 'lodash/startCase';
import {
  Button,
  Card,
  Checkbox,
  IndeterminateCheckbox,
  Inline,
  Input,
  Modal,
  Select,
  Table,
  Tabs,
  Txt,
} from 'shared/components/Core';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { KlearlyUserType } from 'shared/firebase/types/klearlyUserType';
import {
  ApiGetAllUsersQuery,
  useGetAllUsersQuery,
} from 'shared/graphql/generatedApiTypes';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import {
  AddIcon,
  CheckIcon,
  EditIcon,
  EmailIcon,
  PlusSquareIcon,
} from '@chakra-ui/icons';
import {
  useInput,
  UseInputHookBooleanValueTypes,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import { CSVReader } from 'react-papaparse';
import {
  CREATE_NEW_USER,
  CREATE_NEW_USERS,
  UPDATE_USER,
} from 'shared/graphql/gqlMutations';
import {
  ButtonGroup,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { asyncForEach } from 'shared/helpers/asyncForEach';
import { BooleanValueType, OptionsType } from 'shared/types/coreTypes.d';
import { isEmailValid } from 'shared/helpers/validationHelpers';
import { userRoles } from 'shared/constants/userRoles';
import AuthManager from 'shared/firebase/classes/AuthManager';

const ACCEPTED_USERS_STRING = 'accepted-users';
const REJECTED_USERS_STRING = 'rejected-users';

type AddEditUserFormBaseType = {
  companiesSelectOptions: { options: object; placeholder: string };
  onClose: () => void;
  onSubmitSuccessfully: (string: string) => void;
  rolesSelectOptions: {
    options: object | undefined;
    placeholder: string;
  };
  userToEdit: (KlearlyUserType & { role?: string }) | undefined;
};

type BulkAddUserFormBaseType = {
  companiesSelectOptions: { options: OptionsType[]; placeholder: string };
  currentUsers: ApiGetAllUsersQuery['companyUsers'];
  isKlearlyAdminUser: boolean;
  onClose: () => void;
  onSubmitSuccessfully: (string: string) => void;
};

type TabPageType = typeof ACCEPTED_USERS_STRING | typeof REJECTED_USERS_STRING;

type AcceptedRejectedUsersTableType = {
  id: TabPageType;
  isErrorUsers?: boolean;
  users: Array<KlearlyUserType> | AddUsersErrorsType;
};

type UserListTableWrapperType = {
  data: ApiGetAllUsersQuery['companyUsers'];
  error: ApolloError | undefined;
  isKlearlyAdminUser?: boolean;
  loading: boolean;
  selectedRows?: Array<any>;
  setSelectedRows?: (newRows: Array<any>) => void;
  setShowBulkUsersModal: (value: boolean) => void;
  setShowModal: (value: boolean) => void;
  setUserToEdit: (user: KlearlyUserType) => void;
};

type AddUsersErrorsType = Array<{ email: string; message: string }> | undefined;

export const AdminUserList = () => {
  // setup different state items
  const [showModal, setShowModal] = useState(false);
  const [showBulkUsersModal, setShowBulkUsersModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState<KlearlyUserType | undefined>(
    undefined,
  );
  const [newCreatedUser, setNewCreatedUser] = useState<string>();
  const [isNewUserCreated, setIsNewUserCreated] = useState(false);
  // grab all users and companies
  const {
    data,
    error,
    loading,
    refetch: refetchAllUsers,
  } = useGetAllUsersQuery({
    ...defaultQueryFetchPolicy,
  });
  const klearlyUser = AuthManager.klearlyUser;

  // setup variables
  const companiesPlaceholder = 'Select a Company';
  const companiesOptions =  klearlyUser?.companyName ?? ''
    ? [
        {
          label: klearlyUser?.companyName ?? '',
          value: klearlyUser?.companyName ?? '',
        },
      ]
    : [];
  const rolesPlaceholder = 'Select a User Role';
  const rolesOptions = _map(userRoles, ({ role }) => ({
    label: role,
    value: role,
  }));
  // render
  return (
    <>
      {newCreatedUser && isNewUserCreated && (
        <div className={'h-mb-lg h-new-message-alert-wrapper'}>
          <Txt align={'left'} size={'xl'} theme={'action'}>
            {newCreatedUser}
          </Txt>
        </div>
      )}
      <Card isLoading={loading} loadingText={'Loading...'}>
        <UserListTableWrapper
          data={data?.companyUsers ?? []}
          error={error}
          isKlearlyAdminUser={false}
          loading={loading}
          setShowBulkUsersModal={(value) => setShowBulkUsersModal(value)}
          setShowModal={(value) => setShowModal(value)}
          setUserToEdit={setUserToEdit}
        />
      </Card>
      {showModal && (
        <AddEditUserFormBase
          companiesSelectOptions={{
            options: companiesOptions,
            placeholder: companiesPlaceholder,
          }}
          onClose={() => {
            setShowModal(false);
            setUserToEdit(undefined);
          }}
          onSubmitSuccessfully={(message) => {
            setIsNewUserCreated(true);
            setShowModal(false);
            setNewCreatedUser(message);
            setUserToEdit(undefined);
            setTimeout(() => {
              setIsNewUserCreated(false);
              setNewCreatedUser(undefined);
            }, 10000);
            refetchAllUsers();
          }}
          rolesSelectOptions={{
            options: rolesOptions,
            placeholder: rolesPlaceholder,
          }}
          userToEdit={userToEdit as KlearlyUserType & { role?: string }}
        
        />
      )}
      {showBulkUsersModal && (
        <BulkAddUsersModal
          companiesSelectOptions={{
            options: companiesOptions,
            placeholder: companiesPlaceholder,
          }}
          currentUsers={data?.companyUsers ?? []}
          isKlearlyAdminUser={false}
          onClose={() => setShowBulkUsersModal(false)}
          onSubmitSuccessfully={(message) => {
            setShowBulkUsersModal(false);
            setNewCreatedUser(message);
            setUserToEdit(undefined);
            setTimeout(() => {
              setIsNewUserCreated(false);
              setNewCreatedUser(undefined);
            }, 10000);
            refetchAllUsers();
          }}
        />
      )}
    </>
  );
};

const UserListTableWrapper = (props: UserListTableWrapperType) =>
  props.isKlearlyAdminUser ? (
    <KlearlyAdminUserTable {...props} />
  ) : (
    <CustomerAdminUserTable {...props} />
  );

const KlearlyAdminUserTable = ({
  data = [],
  error,
  loading,
  setShowBulkUsersModal,
  setShowModal,
  setUserToEdit,
}: UserListTableWrapperType) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const toast = useToast();

  const sendPasswordEmails = async () => {
    const erroredEmails: string[] = [];
    await asyncForEach(selectedRows, async (row) => {
      try {
        await AuthManager.sendPasswordResetEmail(row.original.email);
      } catch (err) {
        erroredEmails.push(row.original.email);
      }
    });
    onClose();
    if (!erroredEmails.length) {
      toast({
        title: `${selectedRows.length} reset password sent`,
        status: 'success',
        position: 'top',
      });
    } else {
      toast({
        title: `Some emails failed to send: ${selectedRows.map(
          (row) => `${row.original.email}, `,
        )}`,
        status: 'error',
        position: 'top',
        duration: null,
      });
    }
    setSelectedRows([]);
  };

  const tableColumns = useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: 'expander', // It needs an ID
        Cell: ({ row }: { row: any }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? '👇' : '👉'}
          </span>
        ),
      },
      {
        Header: 'User List',
        columns: [
          { Header: 'Company Name', accessor: 'companyName' },
          { Header: '# of Users', accessor: 'size' },
        ],
      },
    ],
    [],
  );
  const tableData = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return _map(_groupBy(data, 'companyName'), (val, idx) => ({
      companyName: idx,
      size: _size(val),
      users: val,
    }));
  }, [error, data, loading]);
  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <CustomerAdminUserTable
        data={row.original.users}
        error={error}
        hideHeaderButtons={true}
        loading={false}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setShowBulkUsersModal={setShowBulkUsersModal}
        setShowModal={setShowModal}
        setUserToEdit={setUserToEdit}
      />
    ),
    [error, setShowBulkUsersModal, setShowModal, setUserToEdit, selectedRows],
  );

  return (
    <Table
      analyticsAttr={'Admin User List'}
      columns={tableColumns}
      data={tableData}
      leftHeader={
        <ButtonGroup>
          <Button
            analyticsAttr={'Bulk Add Users'}
            leftIcon={<PlusSquareIcon />}
            onClick={() => setShowBulkUsersModal(true)}
            text={'Bulk Add Users'}
            variant={'active'}
          />
          <Button
            analyticsAttr={'New User'}
            onClick={() => setShowModal(true)}
            leftIcon={<AddIcon />}
            text={'New User'}
            variant={'active'}
          />
          <Button
            analyticsAttr={'send-password-email'}
            isDisabled={!selectedRows.length}
            leftIcon={<EmailIcon />}
            onClick={onOpen}
            text={'Send Password Emails'}
            variant={'action'}
          />
          <ChakraModal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalCloseButton />
            <ModalContent>
              <ModalHeader>Send Set Password Emails</ModalHeader>
              <ModalBody>
                <Text>
                  You are about to send emails to the following addresses to set
                  passwords (note that only 1 company's users can be selected at
                  a time):
                </Text>
                {selectedRows.map((row) => (
                  <Text>{row.original.email}</Text>
                ))}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button
                    variant={'activeOutline'}
                    text={'Cancel'}
                    onClick={() => {
                      setSelectedRows([]);
                      onClose();
                    }}
                  />
                  <Button text={'Confirm'} onClick={sendPasswordEmails} />
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </ChakraModal>
        </ButtonGroup>
      }
      perPage={30}
      renderRowSubComponent={renderRowSubComponent}
      showGlobalFilter={true}
      tableVPadding={'sm'}
    />
  );
};

const CustomerAdminUserTable = ({
  data = [],
  error,
  hideHeaderButtons = false,
  loading,
  selectedRows,
  setSelectedRows,
  setShowBulkUsersModal,
  setShowModal,
  setUserToEdit,
}: UserListTableWrapperType & { hideHeaderButtons?: boolean }) => {
  const tableColumns = useMemo(() => {
    const _editUserClicked = (user: KlearlyUserType) => {
      setUserToEdit(user);
      setShowModal(true);
    };
    return [
      {
        Header: 'User List',
        columns: [
          {
            Cell: ({ row }) => (
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                value={row.original.email}
              />
            ),
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
                analyticsAttr={'Accounts All Accounts'}
              />
            ),
            accessor: 'selector',
            disableSortBy: true,
          },
          { Header: 'id', accessor: 'id' },
          { Header: 'First Name', accessor: 'firstName' },
          { Header: 'Last Name', accessor: 'lastName' },
          { Header: 'Email', accessor: 'email' },
          { Header: 'Role', accessor: 'role' },
          {
            Cell: ({ value }: BooleanValueType) => value && <CheckIcon />,
            Header: 'Is Admin?',
            accessor: 'isAdmin',
          },
          {
            Cell: ({ value }: BooleanValueType) => value && <CheckIcon />,
            Header: 'Disabled',
            accessor: 'disabled',
          },
          {
            Cell: ({ row }: { row: { original: KlearlyUserType } }) => (
              <Button
                analytics-attr={cleanAnalyticsStringList([
                  `Edit User`,
                  row.original?.id,
                ])}
                rightIcon={<EditIcon />}
                onClick={() => _editUserClicked(row.original)}
                size={'sm'}
                text={'Edit'}
                variant={'actionOutline'}
              />
            ),
            Header: 'Edit User',
            accessor: 'edit',
            disableSortBy: true,
          },
        ],
      },
    ];
  }, [setUserToEdit, setShowModal]);
  const tableData = useMemo(() => {
    if (loading || error || !data) {
      return [];
    }
    return data;
  }, [error, data, loading]);

  return (
    <Table
      analyticsAttr={'Admin User List'}
      columns={tableColumns}
      data={tableData as object[]}
      initialSelectedRows={selectedRows?.reduce(
        (accum, next) => ({ ...accum, [next.id]: true }),
        {},
      )}
      leftHeader={
        hideHeaderButtons ? undefined : (
          <ButtonGroup>
            <Button
              analyticsAttr={'Bulk Add Users'}
              leftIcon={<PlusSquareIcon />}
              onClick={() => setShowBulkUsersModal(true)}
              text={'Bulk Add Users'}
              variant={'active'}
            />
            <Button
              analyticsAttr={'New User'}
              onClick={() => setShowModal(true)}
              leftIcon={<AddIcon />}
              text={'New User'}
              variant={'active'}
            />
          </ButtonGroup>
        )
      }
      perPage={30}
      setSelectedFlatRows={(newRows) => {
        if (setSelectedRows) {
          setSelectedRows(newRows);
        }
      }}
      showGlobalFilter={true}
      tableVPadding={'sm'}
    />
  );
};

const BulkAddUsersModal = ({
  currentUsers,
  onClose,
}: BulkAddUserFormBaseType) => {
  // creat apollo client const
  const apolloClient = useApolloClient();
  const headers = [
    'firstName',
    'lastName',
    'email',
    'role',
    'isAdmin',
    'preventEmailSend',
  ];
  // state required states
  const [creatingNewUsers, setCreatingNewUsers] = useState<boolean>(false);
  const [errorCreatingUsers, setErrorCreatingUsers] = useState<
    string | undefined
  >(undefined);
  const [acceptedUsers, setAcceptedUsers] = useState<Array<KlearlyUserType>>();
  const [rejectedUsers, setRejectedUsers] = useState<Array<KlearlyUserType>>();
  const [tabPage, setTabPage] = useState<TabPageType>(ACCEPTED_USERS_STRING);
  const [isAPISuccessful, setIsAPISuccessful] = useState<boolean>(false);
  const [successfulUsers, setSuccessfulUsers] =
    useState<Array<KlearlyUserType>>();
  const [errorUsers, setErrorUsers] = useState<AddUsersErrorsType>();

  // setup button click functions for submit, edit, and reset
  const _onSubmit = () => {
    setCreatingNewUsers(true);
    setErrorCreatingUsers(undefined);
    return apolloClient
      .mutate({
        mutation: CREATE_NEW_USERS,
        variables: { users: acceptedUsers },
      })
      .then((res) => {
        setCreatingNewUsers(false);
        setIsAPISuccessful(true);
        setSuccessfulUsers(res.data.addUsers.users);
        setErrorUsers(res.data.addUsers.errors);
      })
      .catch((err) => {
        setCreatingNewUsers(false);
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          setErrorCreatingUsers(err.graphQLErrors[0].message);
        } else {
          setErrorCreatingUsers(err.message);
        }
      });
  };
  const _onReset = () => {
    setAcceptedUsers(undefined);
    setRejectedUsers(undefined);
    setCreatingNewUsers(false);
    setErrorCreatingUsers(undefined);
    setIsAPISuccessful(false);
    setSuccessfulUsers(undefined);
    setErrorUsers(undefined);
  };
  const _onFileDrop = (data: any) => {
    const cleanedData = _filter(
      _map(data, (d) => {
        const items = _map(headers, (value, idx) => ({
          [value]: d.data[idx],
        }));
        const response = Object.assign(
          {},
          ...items.map((item) => {
            // handle these string booleans in their own blocks to avoid them being overwritten
            if (item.isAdmin) {
              return {
                ...item,
                isAdmin: item.isAdmin === 'TRUE',
              };
            }
            if (item.preventEmailSend) {
              return {
                ...item,
                sendEmail: item.preventEmailSend === 'FALSE',
              };
            }
            return {
              ...item,
              companyName: AuthManager.klearlyUser?.companyName ?? '',
              disabled: false,
            };
          }),
        );
        delete response.preventEmailSend;
        return response;
      }),
      (i, idx) =>
        idx !== 0 &&
        _size(i.firstName) > 0 &&
        _size(i.lastName) > 0 &&
        _size(i.email) > 0,
    );
    const checkIfUserExists = (user: KlearlyUserType) =>
      _find(currentUsers, (u) => u?.email === user?.email);
    const acceptedRows = _filter(cleanedData, (d) => !checkIfUserExists(d));
    const rejectedRows = _filter(cleanedData, (d) => checkIfUserExists(d));
    setAcceptedUsers(acceptedRows as unknown as Array<KlearlyUserType>);
    setRejectedUsers(rejectedRows as unknown as Array<KlearlyUserType>);
  };
  // setup consts
  const title = 'Bulk Add Users';
  const isModalSubmitButtonDisabled = _size(acceptedUsers) === 0;
  const shouldPreventSubmit = creatingNewUsers || isModalSubmitButtonDisabled;
  // render
  return (
    <Modal
      ariaLabel={'Add User Modal'}
      body={
        isAPISuccessful ? (
          <>
            <div className={'h-mb-lg h-new-message-alert-wrapper'}>
              <Txt align={'left'} size={'xl'} theme={'action'}>
                {`Successfully added ${_size(successfulUsers)} users${
                  _size(errorUsers) > 0
                    ? ` and ${_size(errorUsers)} users failed to create.`
                    : '! - The new users will receive an email if you selected that option.'
                }`}
              </Txt>
            </div>
            <Tabs
              activeLabel={tabPage}
              className={'h-border-bottom'}
              onTabChange={(newTab) => setTabPage(newTab as TabPageType)}
            >
              <div key={ACCEPTED_USERS_STRING}>
                <AcceptedRejectedUsersTable
                  id={ACCEPTED_USERS_STRING}
                  users={successfulUsers as Array<KlearlyUserType>}
                />
              </div>
              <div key={REJECTED_USERS_STRING}>
                <AcceptedRejectedUsersTable
                  id={REJECTED_USERS_STRING}
                  isErrorUsers={true}
                  users={errorUsers as AddUsersErrorsType}
                />
              </div>
            </Tabs>
          </>
        ) : (
          <form
            analytics-attr={`${title} form`}
            className={'c-form'}
            onSubmit={() => _onSubmit()}
          >
            {errorCreatingUsers && (
              <div className={'c-text-field--error'}>
                <div
                  className={'c-text-field__message h-mb-md'}
                  style={{ position: 'relative' }}
                >
                  <div className={'c-text h-text-size-md h-text-align-left'}>
                    <b>{'Error creating user, try again!'}</b>
                    {errorCreatingUsers}
                  </div>
                </div>
              </div>
            )}
            <Txt className={'h-mb-md'}>
              {'Download sample '}
              <a
                target={'_blank'}
                href={
                  'https://docs.google.com/spreadsheets/d/1dpOAfUAATdukEBRqQMpB9DT41D5DJdzmo1Q-xXr3TCs/edit?usp=sharing'
                }
              >
                {'bulkUsersSample.xlsx'}
              </a>
              {' file. NOTE: export file as CSV before uploading.'}
            </Txt>
            <CSVReader
              addRemoveButton
              noProgressBar
              onDrop={_onFileDrop}
              onRemoveFile={_onReset}
            >
              <Txt>{'Drag and Drop CSV file here (or click) to upload'}</Txt>
            </CSVReader>
            {(acceptedUsers || rejectedUsers) && (
              <Tabs
                activeLabel={tabPage}
                className={'h-border-bottom'}
                onTabChange={(newTab) => setTabPage(newTab as TabPageType)}
              >
                <div key={ACCEPTED_USERS_STRING}>
                  <AcceptedRejectedUsersTable
                    id={ACCEPTED_USERS_STRING}
                    users={acceptedUsers as Array<KlearlyUserType>}
                  />
                </div>
                <div key={REJECTED_USERS_STRING}>
                  <AcceptedRejectedUsersTable
                    id={REJECTED_USERS_STRING}
                    users={rejectedUsers as Array<KlearlyUserType>}
                  />
                </div>
              </Tabs>
            )}
          </form>
        )
      }
      close={creatingNewUsers ? () => {} : () => onClose()}
      footer={
        <>
          <div />
          <Button
            analyticsAttr={`${title} submit`}
            onClick={shouldPreventSubmit ? () => {} : () => _onSubmit()}
            isDisabled={isModalSubmitButtonDisabled}
            isLoading={!isModalSubmitButtonDisabled && creatingNewUsers}
            text={'Submit'}
            type={'submit'}
            variant={'active'}
          />
        </>
      }
      header={
        <Inline justifyContent={'between'} pv={'2xl'}>
          <Txt size={'lg'} text={title} weight={'bold'} />
        </Inline>
      }
      submit={shouldPreventSubmit ? () => {} : () => _onSubmit()}
    />
  );
};

const AcceptedRejectedUsersTable = ({
  id = ACCEPTED_USERS_STRING,
  isErrorUsers = false,
  users = [],
}: AcceptedRejectedUsersTableType) => {
  // setup table items
  const title = `${_startCase(id)} List`;
  const tableColumns = useMemo(
    () => [
      {
        Header: title,
        columns: isErrorUsers
          ? [
              { Header: 'Email', accessor: 'email' },
              { Header: 'Message', accessor: 'message' },
            ]
          : [
              { Header: 'First Name', accessor: 'firstName' },
              { Header: 'Last Name', accessor: 'lastName' },
              { Header: 'Email', accessor: 'email' },
              { Header: 'Role', accessor: 'role' },
              {
                Cell: ({ value }: BooleanValueType) =>
                  value ? <CheckIcon /> : 'FALSE',
                Header: 'Is Admin?',
                accessor: 'isAdmin',
              },
              {
                Cell: ({ value }: BooleanValueType) =>
                  // backwards b/c UI is the opposite of the GraphQL field
                  value ? 'FALSE' : 'TRUE',
                Header: 'Preventing email send',
                accessor: 'sendEmail',
              },
            ],
      },
    ],
    [isErrorUsers, title],
  );
  const tableData = useMemo(() => users, [users]);
  // setup consts
  const dataSize = _size(tableData);
  // render
  return (
    <Table
      analyticsAttr={title}
      columns={tableColumns}
      data={tableData as object[]}
      leftHeader={<Txt>{`${title} (${dataSize})`}</Txt>}
      perPage={30}
      showGlobalFilter={true}
      tableVPadding={'sm'}
      // topHeaderWrapperVPadding={'xs'}
    />
  );
};

const AddEditUserFormBase = ({
  companiesSelectOptions,
  onClose,
  onSubmitSuccessfully,
  rolesSelectOptions,
  userToEdit,

}: AddEditUserFormBaseType) => {
  // creat apollo client const
  const apolloClient = useApolloClient();
  // setup select variables
  const { options: companyOptions, placeholder: companyPlaceholder } =
    companiesSelectOptions;
  const { options: roleOptions, placeholder: rolePlaceholder } =
    rolesSelectOptions;
  // state required states
  const [creatingNewUser, setCreatingNewUser] = useState(false);
  const [errorCreatingUser, setErrorCreatingUser] = useState(null);
  // setup input state items
  const {
    bind: bindFirstName,
    reset: resetFirstName,
    value: firstName,
  } = useInput(userToEdit?.firstName || '', {
    id: 'firstName',
    label: 'First Name',
    placeholder: 'First Name',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindLastName,
    reset: resetLastName,
    value: lastName,
  } = useInput(userToEdit?.lastName || '', {
    id: 'lastName',
    label: 'Last Name',
    placeholder: 'Last Name',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindEmail,
    reset: resetEmail,
    value: email,
  } = useInput(userToEdit?.email || '', {
    id: 'email',
    label: 'Email',
    placeholder: 'Email',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindCompanyName,
    reset: resetCompanyName,
    value: companyName,
  } = useInput(userToEdit?.companyName || '', {
    defaultValue: userToEdit?.companyName || '',
    id: 'companyName',
    label: 'Company Name',
    options: companyOptions,
    placeholder: companyPlaceholder,
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindRole,
    reset: resetRole,
    value: role,
  } = useInput(userToEdit?.role || '', {
    defaultValue: userToEdit?.role || '',
    id: 'role',
    label: 'Role',
    options: roleOptions,
    placeholder: rolePlaceholder,
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindIsSuperUser,
    reset: resetIsSuperUser,
    value: isAdmin,
  } = useInput(userToEdit?.isAdmin || false, {
    id: 'isAdmin',
    isCheckbox: true,
    text: 'Is Admin?',
  }) as UseInputHookReturnType & UseInputHookBooleanValueTypes;
  const {
    bind: bindDisabled,
    reset: resetDisabled,
    value: disabled,
  } = useInput(userToEdit?.disabled || false, {
    id: 'disableUser',
    isCheckbox: true,
    text: 'Disable User?',
  }) as UseInputHookReturnType & UseInputHookBooleanValueTypes;
  const {
    bind: bindDoNotSendEmail,
    reset: resetDoNotSendEmail,
    value: doNotSendEmail,
  } = useInput(false, {
    id: 'doNotSendEmail',
    isCheckbox: true,
    text: 'Do not send email',
  }) as UseInputHookReturnType & UseInputHookBooleanValueTypes;
  // setup variables
  const isModalSubmitButtonDisabled =
    _size(firstName) === 0 ||
    _size(lastName) === 0 ||
    _size(companyName) === 0 ||
    !isEmailValid(email);
  const isEditingUser = _size(userToEdit) > 0;
  const shouldPreventSubmit = creatingNewUser || isModalSubmitButtonDisabled;
  // setup button click functions for submit, edit, and reset
  const _onSubmit = () => {
    setCreatingNewUser(true);
    setErrorCreatingUser(null);
    const newUser: Pick<
      NonNullable<KlearlyUserType>,
      | 'companyName'
      | 'disabled'
      | 'email'
      | 'firstName'
      | 'isAdmin'
      | 'lastName'
    > & { role?: string; sendEmail: boolean } = {
      companyName,
      disabled,
      email,
      firstName,
      isAdmin,
      lastName,
      role,
      sendEmail: !doNotSendEmail,
    };
    return apolloClient
      .mutate({
        mutation: CREATE_NEW_USER,
        variables: { user: newUser },
      })
      .then((res) =>
        onSubmitSuccessfully(
          `New user created! ${res.data.addUser.firstName} ${res.data.addUser.lastName} ${res.data.addUser.email} - The new user will receive an email unless you selected to send later.`,
        ),
      )
      .catch((err) => {
        setCreatingNewUser(false);
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          setErrorCreatingUser(err.graphQLErrors[0].message);
        } else {
          setErrorCreatingUser(err.message);
        }
      });
  };
  const _onEditSubmit = () => {
    setCreatingNewUser(true);
    setErrorCreatingUser(null);
    const updatedUser: Pick<
      NonNullable<KlearlyUserType>,
      | 'companyName'
      | 'disabled'
      | 'email'
      | 'firstName'
      | 'isAdmin'
      | 'lastName'
    > & { role?: string } = {
      companyName,
      disabled,
      email,
      firstName,
      isAdmin,
      lastName,
      role,
    };
    return apolloClient
      .mutate({
        mutation: UPDATE_USER,
        variables: { user: updatedUser },
      })
      .then((res) =>
        onSubmitSuccessfully(
          `User updated! ${res.data.updateUser.firstName} ${res.data.updateUser.lastName} ${res.data.updateUser.email}`,
        ),
      )
      .catch((err) => {
        setCreatingNewUser(false);
        if (err.graphQLErrors && err.graphQLErrors.length > 0) {
          setErrorCreatingUser(err.graphQLErrors[0].message);
        } else {
          setErrorCreatingUser(err.message);
        }
      });
  };
  const _onReset = useCallback(() => {
    resetFirstName();
    resetLastName();
    resetEmail();
    resetCompanyName();
    resetRole();
    resetIsSuperUser();
    resetDisabled();
    resetDoNotSendEmail();
    setErrorCreatingUser(null);
  }, [
    resetCompanyName,
    resetDisabled,
    resetEmail,
    resetFirstName,
    resetIsSuperUser,
    resetLastName,
    resetRole,
    resetDoNotSendEmail,
  ]);
  const chooseSubmitFunction = () =>
    isEditingUser ? () => _onEditSubmit() : () => _onSubmit();
  const title = `${isEditingUser ? 'Edit ' : 'Add New'} User`;
  return (
    <Modal
      ariaLabel={'Add User Modal'}
      body={
        <form
          analytics-attr={`${title} form`}
          className={'c-form'}
          onSubmit={() => _onSubmit()}
        >
          {errorCreatingUser && (
            <div className={'c-text-field--error'}>
              <div
                className={'c-text-field__message h-mb-md'}
                style={{ position: 'relative' }}
              >
                <div className={'c-text h-text-size-md h-text-align-left'}>
                  <b>{'Error creating user, try again! '}</b>
                  {errorCreatingUser}
                </div>
              </div>
            </div>
          )}
          <Input {...bindFirstName} analyticsAttr={`${title} first name`} />
          <Input {...bindLastName} analyticsAttr={`${title} last name`} />
          <Input
            {...bindEmail}
            analyticsAttr={`${title} email`}
            disabled={isEditingUser}
          />
          {/* @ts-ignore */}
          <Select
            {...bindCompanyName}
            defaultValue={userToEdit?.companyName}
            analyticsAttr={`${title} company name`}
            className={'h-pb-sm'}
            disabled={isEditingUser}
          />
          {/* @ts-ignore */}
          <Select
            {...bindRole}
            analyticsAttr={`${title} role`}
            className={'h-pb-sm'}
          />
          <Stack>
            <Checkbox
              {...bindIsSuperUser}
              analyticsAttr={`${title} is admin user`}
              checked={isAdmin}
            />
            <Checkbox {...bindDisabled} checked={disabled} />
            {!isEditingUser && (
              <Checkbox
                {...bindDoNotSendEmail}
                analyticsAttr={`${title} disabled`}
                checked={doNotSendEmail}
              />
            )}
          </Stack>
        </form>
      }
      close={creatingNewUser ? () => {} : () => onClose()}
      footer={
        <ButtonGroup>
          <Button
            analyticsAttr={`${title} reset`}
            onClick={_onReset}
            isDisabled={creatingNewUser || isEditingUser}
            text={'Reset'}
            type={'reset'}
            variant={'activeOutline'}
          />
          <Button
            analyticsAttr={`${title} submit`}
            onClick={shouldPreventSubmit ? () => {} : chooseSubmitFunction()}
            isDisabled={isModalSubmitButtonDisabled}
            isLoading={!isModalSubmitButtonDisabled && creatingNewUser}
            text={'Submit'}
            type={'submit'}
            variant={'active'}
          />
        </ButtonGroup>
      }
      header={
        <Inline justifyContent={'between'} pv={'2xl'}>
          <Txt size={'lg'} text={title} weight={'bold'} />
        </Inline>
      }
      submit={shouldPreventSubmit ? () => {} : chooseSubmitFunction()}
    />
  );
};
