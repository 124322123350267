import { createIcon } from '@chakra-ui/icon';

export const ExportIcon = createIcon({
  displayName: 'SelectedCompaniesIcon',
  viewBox: '0 0 64 64',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='64'
      width='64'
      viewBox='0 0 64 64'
    >
      <title>lightning</title>
      <g
        strokeLinecap='square'
        strokeWidth='2'
        fill='none'
        stroke='#ffffff'
        strokeLinejoin='miter'
        className='nc-icon-wrapper'
        strokeMiterlimit='10'
      >
        <polygon points='38 5 9 38 30 38 26 59 55 26 34 26 38 5' />
      </g>
    </svg>
  ),
});
