import { FirestoreAdapter } from '../classes/FirebaseAdapter';
import { DocumentSnapshot } from '../types/firebaseTypes';
import { Timestamps } from '../schemas/Timestamps';
import { FirestoreManagerClass } from '../classes/FirestoreManager';

export abstract class BaseDocument<
  Schema extends Timestamps,
> extends FirestoreAdapter {
  get id(): string {
    return this.snapshot.id;
  }
  get ref() {
    return this.snapshot.ref;
  }
  get path() {
    return this.ref.path;
  }

  data: Schema | undefined;
  protected db = FirestoreManagerClass.getInstance(this.firestore);

  protected constructor(
    public snapshot: DocumentSnapshot<Schema>,
    documentType: string,
  ) {
    super(snapshot.ref.firestore, documentType);
    this.checkForExistence(this.snapshot);
    this.data = snapshot.data();
  }

  protected checkForExistence(snapshot: DocumentSnapshot): void {
    if (!snapshot.exists) {
      // todo throw custom error
      throw `"${this.snapshot.id}" does not exist.`;
    }
  }

  async save() {
    try {
      await this.ref.update({
        ...this.data,
        updatedAt: new Date().toISOString(),
      });
      // we must manually set the updatedTime for our local copy until we fetch from the database again
      this.data ? (this.data.updatedAt = new Date().toISOString()) : {};
    } catch (error) {
      console.error('ERROR: ');
      console.dir({
        method: 'BaseDocument.save()',
        error,
        path: this.path,
      });
      throw error;
    }
  }

  async update(data: Partial<Schema>): Promise<this> {
    try {
      this.data = { ...this.data!, ...data };
      await this.save();
      return this;
    } catch (error) {
      console.error('ERROR: ');
      console.dir({
        method: 'BaseDocument.update()',
        error,
        path: this.path,
        documentType: this.documentType,
      });
      throw error;
    }
  }
}
