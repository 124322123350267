import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import { subDays } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'shared/components/Core';
import { AcquisitionIcon } from 'shared/components/Core/Icon/handShake';
import {
  ApiAccountField,
  ApiGetAccountsListQuery,
  ApiSort,
  useGetAccountsListLazyQuery
} from 'shared/graphql/generatedApiTypes';
import { formatDateString } from 'shared/helpers/formatHelpers';
import { getStatusCircleIcon } from 'shared/renderers/helpers/getStatusCircleIcon';
import { RetentionIcon } from '../../../../../shared/components/Core/Icon/stayHome';
import { predictionTooltip, reguralityMapping } from '../../GaugeChart/constants/gaugeBins';
import {
  CompanyTooltip,
  LastInboundLogic,
  LastInOutboundTooltip,
  LastOutboundLogic,
  PredictionColumnTooltip,
  RecommendetionTooltip
} from '../../TableSection/renderers/AccountDrawerItemRenderer';
import { AllPredictionRenderJSON } from '../../TableSection/renderers/PredictionIconRender';
import { SignalIntensity } from '../../TableSection/renderers/SignalintensityIcon';

export const RecommendationJSON = {
  q_call: 'Make Call',
  q_call_action_item_sample: 'Provide Product Sample',
  q_call_basic_training: 'Make Basic Training Call',
  q_call_business_review: 'Make Business Review Call',
  q_call_clinical_discussion: 'Make Clinical Discussion Call',
  q_call_marketing_discussion: 'Make Marketing Discussion Call',
  q_call_product_event: 'Make Product Event Call',
  q_event_training: 'Hold Training Event',
  q_order_line_item_promo_material: 'Provide Promo Item',
  q_order_line_item_sample: 'Provide Product Sample',
  q_promo_code: 'Provide Promo Code',
  '-': 'Do Nothing',
};

export const TooltipText = {
  company:
    "A company's current relationship status to your business based on its purchase history.",
  lastOutboundActivity:
    'A brief description of the most recent outbound activity to a company (e.g., made call, sent sample, etc.) and when it occurred relative to today.',
  lastInboundResponse:
    'A brief description of the most recent inbound response from a company (e.g., purchased product) and when it occurred relative to today.',
  recommendation:
    'The recommended action you could take that would increase the likelihood of a company becoming a customer.',
};

export default function TopCompanyList() {
  const history = useHistory();

  const productButtonJson = [
    {
      label: 'BELOTERO',
      acquisitionValue: ['acquisition:Belotero:4'],
      retentionValue: ['retention:Belotero:4'],
      value: ['acquisition:Belotero:4', 'retention:Belotero:4'],
    },
    {
      label: 'NEOCUTIS',
      acquisitionValue: ['acquisition:Neocutis:4'],
      retentionValue: ['retention:Neocutis:4'],
      value: ['acquisition:Neocutis:4', 'retention:Neocutis:4'],
    },
    {
      label: 'RADIESSE',
      acquisitionValue: ['acquisition:Radiesse:4'],
      retentionValue: ['retention:Radiesse:4'],
      value: ['acquisition:Radiesse:4', 'retention:Radiesse:4'],
    },
    {
      label: 'TRANSDUCER',
      acquisitionValue: ['acquisition:Transducer:4'],
      retentionValue: ['retention:Transducer:4'],
      value: ['acquisition:Transducer:4', 'retention:Transducer:4'],
    },
    {
      label: 'XEOMIN',
      acquisitionValue: ['acquisition:Xeomin:4'],
      retentionValue: ['retention:Xeomin:4'],
      value: ['acquisition:Xeomin:4', 'retention:Xeomin:4'],
    },
  ];

  const last10Days = {
    min: formatDateString(subDays(new Date(), 10)),
    max: formatDateString(new Date()),
  };
  const last30Days = {
    min: formatDateString(subDays(new Date(), 30)),
    max: formatDateString(new Date()),
  };
  const last60Days = {
    min: formatDateString(subDays(new Date(), 60)),
    max: formatDateString(new Date()),
  };
  const last90Days = {
    min: formatDateString(subDays(new Date(), 90)),
    max: formatDateString(new Date()),
  };
  const last120Days = {
    min: formatDateString(subDays(new Date(), 120)),
    max: formatDateString(new Date()),
  };
  const last180Days = {
    min: formatDateString(subDays(new Date(), 180)),
    max: formatDateString(new Date()),
  };
  const last360Days = {
    min: formatDateString(subDays(new Date(), 360)),
    max: formatDateString(new Date()),
  };

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<any>(
    JSON.stringify(last90Days),
  );

  const [acqPredictionValue, setAcqPredicitionValue] = useState<any>([
    'acquisition:Xeomin:4',
  ]);
  const [retentionPredictionValue, setRetentionPredicitionValue] =
    useState<any>(['retention:Xeomin:4']);

  const [predictionData, setPredictionData] = useState<any>('XEOMIN');
  const [companyLimits, setCompanyLimits] = useState<number>(5);

  // Function for get the data of acquisiiton prediction table
  const [getAcquisitionAccountsData, { data: acquisitionData, loading }] =
    useGetAccountsListLazyQuery({
      variables: {
        messageCountDaysAgo: 7,
        page: 1,
        limit: companyLimits,
        filterStringAlternate: JSON.stringify({
          accountFilterType: 'and',
          predictionTypeNameLevel: acqPredictionValue,
          lastOutboundSignalDate: JSON.parse(selectedTimePeriod),
        }),
        productName: acqPredictionValue[0].split(':')[1],
        filterString: JSON.stringify({}),
        sort: [
          {
            field: ApiAccountField.LastOutboundSignalOccurredAt,
            direction: ApiSort.Asc,
          },
        ],
      },
    });

  // Function for get the data of retention prediction table
  const [getRetentionAccountsData, { data: retentionData }] =
    useGetAccountsListLazyQuery({
      variables: {
        messageCountDaysAgo: 7,
        page: 1,
        limit: companyLimits,
        filterStringAlternate: JSON.stringify({
          accountFilterType: 'and',
          predictionTypeNameLevel: retentionPredictionValue,
          lastOutboundSignalDate: JSON.parse(selectedTimePeriod),
        }),
        filterString: JSON.stringify({}),
        productName: retentionPredictionValue[0].split(':')[1],
        sort: [
          {
            field: ApiAccountField.LastOutboundSignalOccurredAt,
            direction: ApiSort.Asc,
          },
        ],
      },
    });

  // Days option JSON
  const daysOption = [
    {
      label: '10',
      value: JSON.stringify(last10Days),
    },
    {
      label: '30',
      value: JSON.stringify(last30Days),
    },
    {
      label: '60',
      value: JSON.stringify(last60Days),
    },
    {
      label: '90',
      value: JSON.stringify(last90Days),
    },

    {
      label: '120',
      value: JSON.stringify(last120Days),
    },
    {
      label: '180',
      value: JSON.stringify(last180Days),
    },
    {
      label: '360',
      value: JSON.stringify(last360Days),
    },
  ];

  const PredictionTable = (
    icon: {} | null | undefined,
    predicitonType: string,
    tabledata: ApiGetAccountsListQuery | undefined,
    backgroundColor: string,
    predictionField: string,
    predictionValue: any,
  ) => {
    const recommendationLabel = (data) => {
      let product = predictionValue[0].split(':')[1];
      let productObj = data?.[predictionField].filter(
        (item) => item?.calculatedFor?.name === product,
      )[0]?.['recommendedPriorityAction'];
      if (productObj !== null) {
        return RecommendationJSON[productObj];
      } else {
        return 'Do Nothing';
      }
    };

    const capitalize = (s) =>
      s && s[0].toUpperCase() + s.slice(1).toLowerCase();

    return (
      <>
        <div style={{ display: 'flex' }}>
          {icon}

          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {predicitonType}{' '}
          </span>
        </div>
        <span
          style={{ fontSize: '16px', marginLeft: 20, display: 'inline-block' }}
        >
          Top companies based on the highest {predicitonType} Likelihood and
          sorted by Last Outbound Activity (oldest to newest)
        </span>
        <TableContainer
          style={{
            width: '100%',
            padding: '10px',
            border: '2px solid #EDF2F7',
            borderRadius: '10px',
          }}
        >
          {tabledata?.accounts?.accounts !== undefined &&
          tabledata?.accounts?.accounts.length > 0 ? (
            <>
              <Table variant='simple' className='product-prediction'>
                <Thead>
                  <Tr>
                    <Th className='table-header'>
                      {CompanyTooltip(TooltipText.company, 'Company')}
                    </Th>
                    <Th
                      className='table-header'
                      // style={{ width: 'fit-content', minWidth: 'auto' }}
                    >
                      {PredictionColumnTooltip(
                        predictionTooltip[predicitonType],
                        predicitonType,
                        'Likelihood',
                      )}
                    </Th>
                    <Th className='table-header'>
                      {LastInOutboundTooltip(
                        TooltipText.lastInboundResponse,
                        'Engagement',
                        'lastInboundResponse',
                        '',
                      )}
                    </Th>
                    <Th className='table-header'>
                      {LastInOutboundTooltip(
                        TooltipText.lastOutboundActivity,
                        'Outreach',
                        'lastOutboundActivity',
                        '',
                      )}
                    </Th>
                    <Th className='table-header'>
                      {RecommendetionTooltip(
                        TooltipText.recommendation,
                        'Recommendation',
                      )}
                    </Th>
                    {predictionField === 'retentionPredictions' && (
                      <Th className='table-header'>PROJECTED ORDER DATE</Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {tabledata?.accounts?.accounts.map((item: any, index) => {
                    return (
                      <Tr key={index} className='table-display'>
                        <Td>
                          <Button
                            type='button'
                            className='people-message-btn'
                            style={{ height: 'auto' }}
                            onClick={() =>
                              history.push(`/companies/${item?.['id']}`)
                            }
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '5px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '10px',
                                }}
                              >
                                <div style={{ width: '36px' }}>
                                  {item?.['customerStatus'] 
                                    && getStatusCircleIcon(
                                        item?.['customerStatus'],
                                      )
                                    }
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <p
                                    style={{
                                      color: '#2A96FC',
                                      fontWeight: '700',
                                      fontSize: '14px',
                                      textDecoration: 'none',
                                    }}
                                  >
                                    {item?.['accountName'].length > 15
                                      ? item?.['accountName'].slice(0, 15) +
                                        '...'
                                      : item?.['accountName']}
                                  </p>
                                  <p
                                    style={{
                                      color: '#70798B',
                                      fontWeight: '600',
                                      fontSize: '12px',
                                      textDecoration: 'none',
                                    }}
                                  >
                                    {capitalize(item?.['customerStatus'])}
                                  </p>
                                  <p
                                    style={{
                                      color: '#70798B',
                                      fontSize: '12px',
                                      textDecoration: 'none',
                                    }}
                                  >
                                    Ship to Party ID : {item?.['crmAccountId']}
                                  </p>
                                </div>
                              </div>
                              {predictionField === 'retentionPredictions' && (
                                <div>
                                  {item?.orderFrequencies.length > 0 && (
                                    <div>
                                      <p
                                        style={{
                                          color: '#70798B',
                                          fontSize: '12px',
                                          textDecoration: 'none',
                                        }}
                                      >
                                        Last Order Date :{' '}
                                        {moment(
                                          item?.orderFrequencies[0]
                                            ?.lastOrderDate,
                                        ).format('ll')}
                                      </p>
                                      <p
                                        style={{
                                          color: '#70798B',
                                          fontSize: '12px',
                                          textDecoration: 'none',
                                        }}
                                      >
                                        Order Frequency : Every{' '}
                                        {
                                          item?.orderFrequencies[0]
                                            ?.orderFrequency
                                        }
                                      </p>
                                      <p
                                        style={{
                                          color: '#70798B',
                                          fontSize: '12px',
                                          textDecoration: 'none',
                                        }}
                                      >
                                        Order Regularity :{' '}
                                        {reguralityMapping(
                                          item?.orderFrequencies[0]?.regularity,
                                        )}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </Button>
                        </Td>
                        <Td>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <span>
                              {AllPredictionRenderJSON[predictionField]?.[4]}
                            </span>
                            <span
                              style={{
                                margin: '3px',
                                display: 'flex',
                                justifyContent: 'center',
                                fontSize: '14px',
                                fontWeight: '700',
                                color: '#323F5A',
                              }}
                            >
                              Most
                            </span>
                          </div>
                        </Td>

                        <Td>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <span className='intensity-span'>
                              {capitalize(item?.['engagementStatus'])}
                              {SignalIntensity[item?.signalActivityIntensity]}
                            </span>
                            <span
                              style={{
                                color: '#70798B',
                                fontWeight: '600',
                                fontSize: '12px',
                              }}
                            >
                              {LastInboundLogic(item)}
                            </span>{' '}
                          </div>
                        </Td>
                        <Td>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <span className='intensity-span'>
                              {capitalize(item?.['outreachStatus'])}
                              {SignalIntensity[item?.signalResponseIntensity]}
                            </span>
                            <span
                              style={{
                                color: '#70798B',
                                fontWeight: '600',
                                fontSize: '12px',
                              }}
                            >
                              {LastOutboundLogic(item)}
                            </span>{' '}
                          </div>
                        </Td>
                        <Td>{recommendationLabel(item)}</Td>
                        {predictionField === 'retentionPredictions' && (
                          <Td>
                            {item?.orderFrequencies.length > 0
                              ? `${moment(
                                  item?.orderFrequencies[0]?.minDate,
                                ).format('MMM YY')}` +
                                ' - ' +
                                `${moment(
                                  item?.orderFrequencies[0]?.maxDate,
                                ).format('MMM YY')}`
                              : ' - '}
                          </Td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </>
          ) : (
            <>
              <span
                style={{
                  fontWeight: 'bold',
                  fontSize: 24,
                  margin: 'auto',
                }}
              >
                No data available for {predicitonType} <br />
                Please refine your filters
              </span>
            </>
          )}
        </TableContainer>
      </>
    );
  };

  useEffect(() => {
    (async () => {
      await getAcquisitionAccountsData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acqPredictionValue, selectedTimePeriod, companyLimits]);

  useEffect(() => {
    (async () => {
      await getRetentionAccountsData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retentionPredictionValue, selectedTimePeriod, companyLimits]);

  return (
    <>
      <Grid gap={4}>
        <GridItem>
          <HStack spacing='10px'>
            {productButtonJson.map((item, index) => {
              return (
                <Button
                  key={index}
                  style={{
                    border:
                      item?.label === predictionData
                        ? '2px solid #EA6664'
                        : '2px solid #152F45',
                    background: 'white',
                    color:
                      item?.label === predictionData ? '#EA6664' : '#152F45',
                  }}
                  value={item?.value}
                  onClick={() => {
                    setAcqPredicitionValue(item?.acquisitionValue);
                    setRetentionPredicitionValue(item?.retentionValue);
                    setPredictionData(item?.label);
                  }}
                >
                  {item?.label}
                </Button>
              );
            })}
          </HStack>
        </GridItem>
        <GridItem>
          <HStack spacing='10px'>
            <Box>
              <Select
                rightIcon={<ChevronDownIcon />}
                borderWidth={2}
                borderRadius={8}
                borderColor={'#EA6664'}
                fontWeight={'bold'}
                maxW={100}
                color={'#EA6664'}
                variant={'actionOutline'}
                value={selectedTimePeriod}
                onChange={(e) => setSelectedTimePeriod(e.target.value)}
              >
                {daysOption.map((period) => (
                  <option key={period.value} value={period.value}>
                    {period.label}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <FormLabel mt={2}>Days since Last Outbound Activity </FormLabel>
            </Box>
            <Box>
              <Select
                rightIcon={<ChevronDownIcon />}
                borderWidth={2}
                borderRadius={8}
                borderColor={'#EA6664'}
                fontWeight={'bold'}
                maxW={100}
                color={'#EA6664'}
                variant={'actionOutline'}
                value={companyLimits}
                onChange={(e) => setCompanyLimits(parseInt(e.target.value))}
              >
                {[5, 10, 15].map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <FormLabel mt={2}>Companies per table </FormLabel>
            </Box>
          </HStack>
        </GridItem>
      </Grid>
      <Card isLoading={loading}>
        <Grid className='prediction-grid'>
          <GridItem w='100%' h='100%' className='prediction-grid-item'>
            {PredictionTable(
              RetentionIcon,
              'Retention',
              retentionData,
              '#2A97FB',
              'retentionPredictions',
              retentionPredictionValue,
            )}
          </GridItem>

          <GridItem w='100%' h='100%' className='prediction-grid-item'>
            {PredictionTable(
              AcquisitionIcon,
              'Acquisition',
              acquisitionData,
              '#8354D2',
              'acquisitionPredictions',
              acqPredictionValue,
            )}
          </GridItem>
        </Grid>
      </Card>
    </>
  );
}
