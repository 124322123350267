import _omit from 'lodash/omit';
import { FilterDictType } from '../types/filterDict';
import { FilterIndices } from '../types/filterTypes';
import { OptionsType } from 'shared/types/coreTypes.d';
import {
  acquisitionBins,
  retentionBins,
  volumeBins,
} from 'app/src/components/GaugeChart/constants/gaugeBins';

export const cleanFilterDict = ({
  filterDict,
  filterIndex,
}: {
  filterDict: FilterDictType;
  filterIndex: string;
}) =>
  _omit(
    filterDict,
    filterDict?.[FilterIndices.ACCOUNTS_FILTER_TYPE] ===
      FilterIndices.ACCOUNTS_FILTER_TYPE_EXCLUSIVE
      ? [
          filterIndex,
          FilterIndices.ACCOUNTS_FILTER,
          FilterIndices.ACCOUNTS_FULL_FILTER,
        ]
      : [filterIndex],
  );

export function filterStringFromBackendToDict(
  filterString: string = '',
): FilterDictType & { accounts: OptionsType[] } {
  if (!filterString.trim()) {
    return {
      [FilterIndices.ACCOUNTS_FILTER_TYPE]: [
        FilterIndices.ACCOUNTS_FILTER_TYPE_INCLUSIVE,
      ],
      accounts: [],
    };
  }
  const parsedFilter = JSON.parse(filterString);

  // adapt to xeomin prediction level
  let reducedSplitPredictionTypeLevel = {};
  if (parsedFilter.predictionTypeLevel) {
    const splitPredictionTypeLevel = parsedFilter.predictionTypeLevel.map((level) => {
      const splitLevel = level.split(':');
      if (splitLevel[0] === 'retention') {
        return {
          key: FilterIndices.PREDICTED_XEOMIN_REORDER_PROPENSITY,
          value: retentionBins[splitLevel[1]],
        };
      } else if (splitLevel[0] === 'acquisition') {
        return {
          key: FilterIndices.PREDICTED_XEOMIN_PURCHASE_PROPENSITY,
          value: acquisitionBins[splitLevel[1]],
        };
      }
      return {
        key: FilterIndices.PREDICTED_XEOMIN_PURCHASE_QUANTITY,
        value: volumeBins[splitLevel[1]],
      };
    });
    reducedSplitPredictionTypeLevel = splitPredictionTypeLevel.reduce(
      (accum, current) => {
        accum[current.key].push(current.value);
        return accum;
      },
      {
        [FilterIndices.PREDICTED_XEOMIN_PURCHASE_PROPENSITY]: [],
        [FilterIndices.PREDICTED_XEOMIN_PURCHASE_QUANTITY]: [],
        [FilterIndices.PREDICTED_XEOMIN_REORDER_PROPENSITY]: [],
      } as { [key: string]: Array<string | number> },
    );
    delete parsedFilter.predictionTypeLevel;
  }

  if (parsedFilter.accounts) {
    return parsedFilter;
  }
  return { ...parsedFilter, ...reducedSplitPredictionTypeLevel, accounts: [] };
}
