import {
  CalendarFilterFormat,
  CalendarRangeState,
} from '../types/calendarTypes';

export const calendarFilterFormatOptions = [
  { label: 'Days', value: CalendarFilterFormat.DAYS },
  { label: 'Weeks', value: CalendarFilterFormat.WEEKS },
  { label: 'Months', value: CalendarFilterFormat.MONTHS },
  { label: 'Years', value: CalendarFilterFormat.YEARS },
];

export const calendarRangeStateOptions = [
  { label: 'Previous', value: CalendarRangeState.PREVIOUS },
  { label: 'Between', value: CalendarRangeState.BETWEEN },
];
