import { Maybe } from 'shared/graphql/generatedApiTypes';

export type GetFormattedAddressStringParams = {
  addressCity: Maybe<string>;
  addressRegion: Maybe<string>;
  addressZipCode: Maybe<string>;
};

export const getFormattedAddressString = ({
  addressCity,
  addressRegion,
  addressZipCode,
}: GetFormattedAddressStringParams) => {
  if (addressCity?.trim() && addressRegion?.trim() && addressZipCode?.trim()) {
    return `${addressCity.trim()}, ${addressRegion.trim()} ${addressZipCode.trim()}`;
  }

  if (addressCity?.trim() && addressRegion?.trim()) {
    return `${addressCity.trim()}, ${addressRegion.trim()}`;
  }

  if (addressCity?.trim() && addressZipCode?.trim()) {
    return `${addressCity.trim()}, ${addressZipCode.trim()}`;
  }

  if (addressRegion?.trim() && addressZipCode?.trim()) {
    return `${addressRegion.trim()}, ${addressZipCode.trim()}`;
  }

  return (
    addressCity?.trim() || addressRegion?.trim() || addressZipCode?.trim() || ''
  );
};
