import { NewLogo } from 'shared/components/Core/Icon/newLogo';
import { Box, Flex, IconButton, Spacer, Text } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { AccountSearch } from './AccountSearch';
import { useWindowSize } from 'app/src/hooks/useWindowSize';
import { navbarSizeInt, navbarSizeStr } from 'app/src/constants/navbarSize';
import { useContext, useEffect, useMemo } from 'react';
import { NavigationProps } from 'app/src/components/Navigation/components/NavigationBar';
import { NavigationMenu } from 'app/src/components/Navigation/components/NavigationMenu';
import { NavbarIconRenderer } from 'shared/renderers/NavbarIconRenderer';
import { useTopLevelLinks } from 'app/src/components/Navigation/hooks/useTopLevelLinks';
import { isTopLevelNavLink } from 'shared/helpers/navigationHelpers';
import { useHistory, useLocation } from 'react-router-dom';
import * as ROUTES from 'app/src/constants/routes';
import { homePageKey } from 'shared/constants/homePageKey';
import { MainNavbarIcon } from 'app/src/components/Navigation/components/MainNavbarIcon';
import { AppNavigationalStructureContext } from 'app/src/context/AppNavigationalStructureContext';
import { useReactiveVar } from '@apollo/client';
import {
  appliedFiltersHeightVar,
  globalFilterVar,
  showingFilterPanelVar,
  windowHeightVar,
  windowWidthVar,
} from 'app/src/apollo/rootReactiveVariables';
import queryString from 'query-string';
import {
  SelectedSettingsGearIcon,
  SettingsGearIcon,
} from 'shared/components/Core/Icon/settings';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { GlobalFilters } from 'app/src/components/GlobalFilters/components/GlobalFilters';
import { usePanelWidthAdjustments } from 'app/src/hooks/usePanelWidthAdjustments';
import { FilterPanel } from 'app/src/components/Navigation/components/FilterPanel';
import { Tooltip } from 'shared/components/Core';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { useAsync } from 'app/src/hooks/useAsync';

export const LeftNavigation = ({
  children,
  onOpenUpdateCompanyModal,
}: NavigationProps) => {
  const { pathname, search, hash } = useLocation();
  const klearlyUser = AuthManager.klearlyUser;
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const filterPanelIsShowing = useReactiveVar(showingFilterPanelVar);
  const topLevelLinks = useTopLevelLinks();
  const { structure } = useContext(AppNavigationalStructureContext);
  const history = useHistory();
  const globalFilter = useReactiveVar(globalFilterVar);
  const appliedFiltersHeight = useReactiveVar(appliedFiltersHeightVar);
  const firebaseUserUID = AuthManager.currentUser?.uid ?? '';
  const companyName = AuthManager.klearlyUser?.companyName ?? '';
  const userSettingsDocId = `${firebaseUserUID}#${companyName}`;
  const getUserSettingsAsync = async () => {
    return DatabaseManager.UserSettingsModel.get(userSettingsDocId);
  };
  const getUserSettings = useAsync(getUserSettingsAsync);

  useEffect(() => {
    getUserSettings.execute();
  }, []);
  // listen for updates to & manage the filter string at this level
  useEffect(() => {
    
    const newQueryString = queryString.stringify({
      filter: globalFilter
        ? encodeURIComponent(JSON.stringify(globalFilter))
        : '',
    });
    if (`?${newQueryString}` !== search && window.location.pathname !== "/admin") {
      history.replace(`${history.location.pathname}?${newQueryString}${hash}`);
    }
  }, [globalFilter]);

  useEffect(() => {
    windowWidthVar(windowWidth);
  }, [windowWidth]);

  useEffect(() => {
    windowHeightVar(windowHeight);
  }, [windowHeight]);

  const topBarWidth = useMemo(() => {
    return windowWidth - navbarSizeInt;
  }, [windowWidth, filterPanelIsShowing]);

  const { contentLeft } = usePanelWidthAdjustments();

  const panelHeight = useMemo(() => {
    return `${windowHeight - appliedFiltersHeight - navbarSizeInt}px`;
  }, [appliedFiltersHeight]);

  const currentTopLevelPage = useMemo(
    () =>
      topLevelLinks.find(
        (page) =>
          (pathname === '/' && page.key === homePageKey) ||
          (pathname !== '/' &&
            pathname.includes(page.navStructure.urlPaths[0].path)),
      ),
    [topLevelLinks, pathname],
  );
  const currentPage = useMemo(
    () =>
      structure?.pages.find((page) =>
        !page.navStructure.urlPaths[0].param
          ? (pathname === '/' && page.key === homePageKey) ||
            (pathname !== '/' &&
              !page.navStructure.urlParent &&
              pathname === page.navStructure.urlPaths[0].path) ||
            (page.navStructure.urlParent &&
              pathname.includes(page.navStructure.urlParent) &&
              pathname.includes(page.navStructure.urlPaths[0].path))
          : page.navStructure.urlParent &&
            pathname.includes(page.navStructure.urlParent) &&
            pathname.includes(page.navStructure.urlPaths[0].path),
      ),
    [pathname, structure],
  );

  const panelTop = useMemo(() => {
    if (currentPage?.filtersVisible) {
      return `${appliedFiltersHeight + navbarSizeInt + 24}px`;
    }
    return navbarSizeStr;
  }, [appliedFiltersHeight, pathname]);

  const backLink = useMemo(
    () =>
      currentPage?.navStructure.urlPaths[0].param
        ? currentPage.navStructure.urlPaths[0].label
        : false,
    [currentPage],
  );

  useEffect(() => {
    if (!currentPage?.filtersVisible) {
      showingFilterPanelVar(false);
    }
  }, [currentPage]);
  return (
    <>
      <Box pos={'fixed'}>
        <Box left={navbarSizeStr} w={topBarWidth} pos={'absolute'}>
          <Flex
            align={'center'}
            bg={'brand.white'}
            boxShadow={`inset 0px -1px 0px #EBF2FA`}
            direction={'row'}
            h={navbarSizeStr}
            justify={'space-between'}
          >
           
            {!backLink ? (
              currentPage ? (
                isTopLevelNavLink(currentPage.navStructure) && (
                  <Text
                    as={'h1'}
                    ml={8}
                    fontWeight={'bold'}
                    fontSize={'xl'}
                    my={4}
                  >
                    {currentPage.browserTabTitle}
                  </Text>
                )
              ) : (
                <Spacer />
              )
            ) : (
              <Flex align={'center'}>
                <IconButton
                  aria-label={'back-arrow'}
                  borderRadius={20}
                  icon={
                    <ChevronLeftIcon boxSize={6} color={'brand.gray-600'} />
                  }
                  ml={4}
                  mr={2}
                  onClick={() => history.goBack()}
                  bg={'brand.gray-100'}
                  _hover={{
                    bg: 'brand.gray-800',
                  }}
                />
              </Flex>
            )}
            <Flex align={'center'} mr={4}>
              <AccountSearch />
              <NavigationMenu
                onOpenUpdateCompanyModal={onOpenUpdateCompanyModal}
              />
            </Flex>
          </Flex>
          {currentPage?.filtersVisible && structure?.filters && (
            <GlobalFilters
              getUserSettingsValue={getUserSettings.value}
              getUserSettingsExecute={getUserSettings.execute}
              getUserSettingsPending={getUserSettings.pending}
            />
          )}
        </Box>
        <Flex>
          <Flex
            alignItems={'center'}
            boxShadow={`inset -1px 0px 0px #EBF2FA`}
            bg={'brand.midnight'}
            direction={'column'}
            h={windowHeight}
            justify={'space-between'}
            position={'fixed'}
            pt={3}
            w={navbarSizeStr}
          >
            <Box>
              <Flex
                w={navbarSizeStr}
                h={'40px'}
                align={'center'}
                justify={'center'}
              >
                <NewLogo boxSize={10} />
              </Flex>
              <Box mt={4}>
                {topLevelLinks.map(
                  (page) =>
                    isTopLevelNavLink(page.navStructure) && (
                      <Tooltip
                        content={page.navStructure.navbarTooltip || page.browserTabTitle}
                        hasArrow={false}
                        hideIcon
                        my={-2}
                        key={page.key}
                      >
                        <MainNavbarIcon
                          icon={NavbarIconRenderer(
                            page.navStructure.navbarIcon,
                            currentTopLevelPage?.key === page.key,
                          )}
                          isActive={currentTopLevelPage?.key === page.key}
                          link={page.navStructure.urlPaths[0].path}
                        />
                      </Tooltip>
                    ),
                )}
              </Box>
            </Box>
            {klearlyUser?.isAdmin && (
              <Box>
                <Tooltip
                  content={'Admin Settings'}
                  hasArrow={false}
                  hideIcon
                  my={-2}
                >
                  <MainNavbarIcon
                    icon={
                      pathname.includes(ROUTES.ADMIN[1]) ? (
                        <SelectedSettingsGearIcon boxSize={6} />
                      ) : (
                        <SettingsGearIcon boxSize={6} />
                      )
                    }
                    isActive={pathname.includes(ROUTES.ADMIN[1])}
                    link={ROUTES.ADMIN[1]}
                  />
                </Tooltip>
              </Box>
            )}
          </Flex>
          {filterPanelIsShowing && (
            <FilterPanel
              panelTop={panelTop}
              panelHeight={panelHeight}
              getUserSettings={getUserSettings}
            />
          )}
        </Flex>
      </Box>
      <Box
        bg={'brand.white'}
        position={'absolute'}
        left={contentLeft}
        top={panelTop}
        zIndex={-1}
      >
        {children}
      </Box>
    </>
  );
};
