import { makeVar } from '@apollo/client';
import { FilterDictType } from '../components/GlobalFilters/types/filterDict';
import { FilterIndices } from '../components/GlobalFilters/types/filterTypes';
import { LabCardApiResponse } from '../components/Lab/types/labApiTypes';
import { filterStringFromBackendToDict } from '../components/GlobalFilters/helpers/filterDictAndFilterStringHelpers';
import { OptionsType } from 'shared/types/coreTypes.d';

export type InitialApolloState = {
  appliedFilter: { id: number; title: string } | null;
  appliedFiltersHeight: number;
  appliedLists: Array<number>;
  filterItemsLoading: boolean;
  showingFavoritesPanel: boolean;
  getToken: () => string;
  globalFilter: (FilterDictType & { accounts: OptionsType[] }) | null;
  inTemporaryGlobalFilterState:
    | (FilterDictType & { accounts: OptionsType[] })
    | null;
  loadedLabs: { [key: string]: LabCardApiResponse };
  showingFilterPanel: boolean;
  windowHeight: number;
  windowWidth: number;
};

// default global state outside of components
export const initialApolloState: InitialApolloState = {
  appliedFilter: null,
  appliedFiltersHeight: 0,
  appliedLists: [],
  filterItemsLoading: false,
  showingFavoritesPanel: false,
  getToken: () => '',
  globalFilter: null,
  inTemporaryGlobalFilterState: null,
  loadedLabs: {},
  showingFilterPanel: false,
  windowHeight: 0,
  windowWidth: 0,
};

// accessible from inside components so that we can pass company-specific structure
export const getInitialApolloState = (defaultFilter: string) => {
  return {
    ...initialApolloState,
    globalFilter: {
      ...filterStringFromBackendToDict(defaultFilter),
      [FilterIndices.ACCOUNTS_FILTER_TYPE]:
        FilterIndices.ACCOUNTS_FILTER_TYPE_INCLUSIVE,
      accounts: [],
      [FilterIndices.EXCLUDED_ACCOUNT_IDS]: [],
    },
  };
};

export const appliedFilterVar = makeVar<InitialApolloState['appliedFilter']>(
  initialApolloState.appliedFilter,
);
export const appliedFiltersHeightVar = makeVar<
  InitialApolloState['appliedFiltersHeight']
>(initialApolloState.appliedFiltersHeight);
export const appliedListsVar = makeVar<InitialApolloState['appliedLists']>(
  initialApolloState.appliedLists,
);
export const globalFilterVar = makeVar<InitialApolloState['globalFilter']>(
  initialApolloState.globalFilter,
);
export const inTemporaryGlobalFilterStateVar = makeVar<
  InitialApolloState['inTemporaryGlobalFilterState']
>(initialApolloState.inTemporaryGlobalFilterState);
export const filterItemsLoadingVar = makeVar<
  InitialApolloState['filterItemsLoading']
>(initialApolloState.filterItemsLoading);
export const loadedLabsVar = makeVar<InitialApolloState['loadedLabs']>(
  initialApolloState.loadedLabs,
);
export const showingFilterPanelVar = makeVar<
  InitialApolloState['showingFilterPanel']
>(initialApolloState.showingFilterPanel);
export const getTokenVar = makeVar<InitialApolloState['getToken']>(
  initialApolloState.getToken,
);
export const windowHeightVar = makeVar<InitialApolloState['windowHeight']>(
  initialApolloState.windowHeight,
);
export const windowWidthVar = makeVar<InitialApolloState['windowWidth']>(
  initialApolloState.windowWidth,
);
