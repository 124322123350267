import { AbstractedTableSection } from 'app/src/components/TableSection/components/AbstractedTableSection';
import {
  ButtonActionType,
  TableColumnDisplay,
  TableColumnType,
  TableLegend,
} from 'shared/firebase/types/tableTypes';
import { DataFieldFormat } from 'shared/renderers/DataFieldRenderer';

export type AccountsTableProps = {};

export const AccountsTable = ({}: AccountsTableProps) => {
  return (
    <AbstractedTableSection
      analyticsAttr={'account-list'}
      columns={tableColumns}
      defaultSort={'accountName'}
      legendType={TableLegend.none}
    />
  );
};

const tableColumns: TableColumnType[] = [
  {
    dataFieldFormat: DataFieldFormat.UNFORMATTEDTEXT,
    isSortable: true,
    isNumeric: false,
    headerTooltip: '',
    accessor: 'accountName',
    displayType: TableColumnDisplay.Button,
    truncateAt: 30,
    header: 'Account',
    actionData: {
      type: ButtonActionType.Navigate,
      data: {
        routeToPush: '/companies',
      },
    },
    id : "1"
  },
  {
    dataFieldFormat: DataFieldFormat.WINFLUENCE,
    isSortable: true,
    isNumeric: false,
    headerTooltip: '',
    accessor: 'rankPriority',
    displayType: TableColumnDisplay.Static,
    truncateAt: 30,
    header: 'Winfluence',
    id : "2"
  },
  {
    dataFieldFormat: DataFieldFormat.TEXT,
    isSortable: true,
    isNumeric: false,
    headerTooltip: '',
    accessor: 'signalResponseIntensity',
    displayType: TableColumnDisplay.Static,
    truncateAt: 30,

    header: 'Engagement Intensity',
    id : "3"
  },
  {
    dataFieldFormat: DataFieldFormat.TEXT,
    isSortable: true,
    isNumeric: false,
    headerTooltip: '',
    accessor: 'outreachStatus',
    displayType: TableColumnDisplay.Static,
    truncateAt: 30,
    header: 'Outreach Status',
    id : "4"
  },
  {
    dataFieldFormat: DataFieldFormat.TEXT,
    isSortable: true,
    isNumeric: false,
    headerTooltip: '',
    accessor: 'signalActivityIntensity',
    displayType: TableColumnDisplay.Static,
    truncateAt: 30,
    header: 'Outreach Intensity',
    id : "5"
  },
  {
    dataFieldFormat: DataFieldFormat.UNFORMATTEDTEXT,
    isSortable: true,
    isNumeric: true,
    headerTooltip: '',
    accessor: 'messageCount',
    displayType: TableColumnDisplay.Button,
    truncateAt: 30,
    header: 'Recent Messages',
    actionData: {
      type: ButtonActionType.Navigate,
      data: {
        routeToPush: '/companies',
        hash: 'messages',
      },
    },
    id : "6"
  },
];
