import { acquisitionBins, retentionBins, volumeBins } from '../constants/gaugeBins';
import { ApiPredictionForType } from 'shared/graphql/generatedApiTypes';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

export const getGaugeProps = ({
  predictionLevel,
  predictionType,
}: {
  predictionLevel: number;
  predictionType: ApiPredictionForType;
}) => {
  switch (predictionType) {
    case ApiPredictionForType.Volume:
      const volumeKeys = Object.keys(volumeBins);
      const selectedVolumeIndex = volumeKeys.indexOf(
        predictionLevel.toString(),
      );
      return {
        topLabel: cleanStringCase(volumeBins[Math.ceil(volumeKeys.length / 2)]),
        chartLabel: 'PURCHASE QUANTITY: THIS QTR VS LAST QTR',
        leftLabel: cleanStringCase(volumeBins[1]),
        rightLabel: cleanStringCase(volumeBins[volumeKeys.length]),
        colorList: [
          '#E02a43',
          '#ED8494',
          '#f6c2c8',
          '#ececec',
          '#c2e0fe',
          '#85c4fd',
          '#2897fc',
        ],
        selectedSectionIndex: selectedVolumeIndex,
        selectedSectionLabel: cleanStringCase(
          volumeBins[selectedVolumeIndex + 1],
        ),
      };
    case ApiPredictionForType.Acquisition:
      const acquisitionKeys = Object.keys(acquisitionBins);
      const selectedAcquisitionIndex = acquisitionKeys.indexOf(
        predictionLevel.toString(),
      );
      return {
        sectionColor: 'rgb(41, 151, 252)',
        topLabel: cleanStringCase(
          acquisitionBins[Math.ceil(acquisitionKeys.length / 2)],
        ),
        chartLabel: 'First Purchase Propensity (This Qtr)',
        leftLabel: cleanStringCase(acquisitionBins[1]),
        rightLabel: cleanStringCase(acquisitionBins[acquisitionKeys.length]),
        numberOfSections: acquisitionKeys.length,
        selectedSectionIndex: selectedAcquisitionIndex,
        selectedSectionLabel: cleanStringCase(
          acquisitionBins[selectedAcquisitionIndex + 1],
        ),
      };
    case ApiPredictionForType.Retention:
      const retentionKeys = Object.keys(retentionBins);
      const selectedRetentionIndex = retentionKeys.indexOf(predictionLevel.toString());
      return {
        sectionColor: 'rgb(41, 151, 252)',
        topLabel: cleanStringCase(retentionBins[Math.ceil(retentionKeys.length / 2)]),
        chartLabel: 'Reorder Propensity (This Qtr)',
        leftLabel: cleanStringCase(retentionBins[1]),
        rightLabel: cleanStringCase(retentionBins[retentionKeys.length]),
        numberOfSections: retentionKeys.length,
        selectedSectionIndex: selectedRetentionIndex,
        selectedSectionLabel: cleanStringCase(retentionBins[selectedRetentionIndex + 1]),
      };

    default:
      return null;
  }
};
