import { Stack, Text } from '@chakra-ui/react';
import { AccountField } from 'app/src/components/Account/components/sav/header/AccountHeader';
import {
  AccountHeaderItem,
  CustomeAccountHeader,
} from 'app/src/components/Account/components/sav/header/AccountHeaderItem';
import { LocationLink } from 'app/src/components/Account/components/sav/header/LocationLink';
import { buildGoogleMapsLink } from 'app/src/components/Account/helpers/buildGoogleMapsLink';
import { getFormattedAddressString } from 'app/src/components/Account/helpers/getFormattedAddressString';
import { getFormattedEmployeeCount } from 'app/src/components/Account/helpers/getFormattedEmployeeCount';
import { capitalize } from 'lodash';
import { cleanStringCase, numberFormatter } from 'shared/helpers/formatHelpers';

export const AccountHeaderItemRenderer = (
  item: { field: AccountField; label: string; tooltip?: string },
  accountInfo,
) => {
  switch (item.field) {
    case AccountField.crmId:
      return (
        <AccountHeaderItem
          labelText={item.label.toUpperCase()}
          itemValue={accountInfo?.crmAccountId ?? ''}
          tooltip={item.tooltip}
        />
      );
    case AccountField.lastInboundResponse:
      return (
        <CustomeAccountHeader
          labelText={item.label.toUpperCase()}
          itemValue={accountInfo ?? ''}
          tooltip={item.tooltip}
          field={item.field}
        />
      );
    case AccountField.lastOutboundActivity:
      return (
        <CustomeAccountHeader
          labelText={item.label.toUpperCase()}
          itemValue={accountInfo ?? ''}
          tooltip={item.tooltip}
          field={item.field}
        />
      );
    case AccountField.engagementStatus:
      return (
        <AccountHeaderItem
          labelText={item.label.toUpperCase()}
          itemValue={
            capitalize(accountInfo?.engagementStatus.toLowerCase()) ?? '-'
          }
          tooltip={item.tooltip}
        />
      );
    case AccountField.outreachStatus:
      return (
        <AccountHeaderItem
          labelText={item.label.toUpperCase()}
          itemValue={
            capitalize(accountInfo?.outreachStatus.toLowerCase()) ?? '-'
          }
          tooltip={item.tooltip}
        />
      );
    case AccountField.industry:
      return (
        <AccountHeaderItem
          labelText={item.label.toUpperCase()}
          itemValue={cleanStringCase(accountInfo?.industry) ?? '-'}
          tooltip={item.tooltip}
        />
      );

    case AccountField.location:
      const googleMapsLink = buildGoogleMapsLink({
        addressStreet: accountInfo?.addressStreet,
        addressCity: accountInfo?.addressCity,
        addressRegion: accountInfo?.addressRegion,
        addressZipCode: accountInfo?.addressZipCode,
      });
      return (
        <Stack spacing={1}>
          <LocationLink
            googleMapsLink={googleMapsLink}
            labelText={item.label.toUpperCase()}
          />
          <Text>{accountInfo?.addressStreet ?? '-'}</Text>
          <Text>
            {getFormattedAddressString({
              addressCity: accountInfo?.addressCity,
              addressRegion: accountInfo?.addressRegion,
              addressZipCode: accountInfo?.addressZipCode,
            })}
          </Text>
        </Stack>
      );
    case AccountField.revenue:
      return (
        <AccountHeaderItem
          labelText={item.label.toUpperCase()}
          itemValue={
            accountInfo?.annualRevenue
              ? numberFormatter(accountInfo.annualRevenue)
              : '-'
          }
          tooltip={item.tooltip}
        />
      );
    case AccountField.size:
      const formattedEmployeeCount = getFormattedEmployeeCount(
        accountInfo?.employeeCount,
      );
      return (
        <AccountHeaderItem
          labelText={item.label.toUpperCase()}
          itemValue={formattedEmployeeCount}
          tooltip={item.tooltip}
        />
      );
    default:
      return null;
  }
};
