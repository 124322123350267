import { AccountGroupStat } from 'app/src/context/AggregateDataContext';
import _isArray from 'lodash/isArray';

export const accountGroupStatValueIsArray = (
  value: AccountGroupStat['value'],
): value is Array<any> => _isArray(value);

export const accountGroupStatValueIsString = (
  value: AccountGroupStat['value'],
): value is string => typeof value === 'string';
