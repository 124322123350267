import SavedFilters from './SavedFilters';
import { Card, Container } from 'shared/components/Core';
import { useGetAllAccountGroupsQuery } from 'shared/graphql/generatedApiTypes';
import { SavedLists } from 'app/src/components/ListsAndFilters/components/SavedLists';

export type SavedSegmentsOrListsProps = {
  saveType: 'savedFilter' | 'savedList';
};

export const SavedSegmentsOrLists = ({
  saveType,
}: SavedSegmentsOrListsProps) => {
  const { loading } = useGetAllAccountGroupsQuery();

  return (
    <Container width={'full'}>
      <Card isLoading={loading}>
        {saveType === 'savedList' ? <SavedLists /> : <SavedFilters />}
      </Card>
    </Container>
  );
};
