import {
  fullNavPanelWidthStr,
  navbarSizeStr,
} from 'app/src/constants/navbarSize';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { useContext, useMemo, useState } from 'react';
import { AppNavigationalStructureContext } from 'app/src/context/AppNavigationalStructureContext';
import { FilterPanelSection } from 'shared/firebase/schemas/SavedStructure';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import { FilterItem } from 'app/src/components/GlobalFilters/components/filterItems/FilterItem';
import { Button, Tooltip } from 'shared/components/Core';
import { CheckCircleIcon, EditIcon, StarIcon } from '@chakra-ui/icons';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { UseAsyncReturn } from 'app/src/hooks/useAsync';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { FieldValue } from 'shared/firebase/initializeFirebase';
import { UserSettingsDocument } from 'shared/firebase/documents/UserSettingsDocument';

type FilterPanelProps = {
  panelHeight: string;
  panelTop: string;
  getUserSettings: UseAsyncReturn<UserSettingsDocument | null>;
};

export const FilterPanel = ({
  panelHeight,
  panelTop,
  getUserSettings,
}: FilterPanelProps) => {
  const { structure } = useContext(AppNavigationalStructureContext);
  const panel: FilterPanelSection[] = structure?.filters.panel ?? [];
  const orderedPanel = panel.sort((a, b) => (a.order > b.order ? 1 : -1));
  const firebaseUserUID = AuthManager.currentUser?.uid ?? '';
  const companyName = AuthManager.klearlyUser?.companyName ?? '';
  const userSettingsDocId = `${firebaseUserUID}#${companyName}`;
  const [isEditingFavorites, setIsEditingFavorites] = useState<boolean>(false);

  const favoritedQuickAccessFilters = useMemo(
    () =>
      getUserSettings.value?.data?.quickAccessFilters ??
      structure?.filters.defaultQuickAccessFilters.map((item) => item.field) ??
      [],
    [getUserSettings?.value],
  );

  /*
    Several possible scenarios here:
    1) User doc doesn't exist at all, so create the whole document with the company defaults & the new one either
     added or filtered out
     2) User doc already exists, but quick access filters is null, so create the quick access filters with the company defaults & the new one either
     added or filtered out
     3) User doc & quick access filters both already exist, so don't worry about the company defaults and just add
      the new one or filter it out.
   */

  const toggleFavoritingQuickFilter = async (filterField: FilterIndices) => {
    const companyDefaults =
      structure?.filters.defaultQuickAccessFilters.map((item) => item.field) ??
      [];
    const companyDefaultsWithNewSelection = companyDefaults.includes(
      filterField,
    )
      ? companyDefaults.filter((item) => item !== filterField)
      : [...companyDefaults, filterField];
    // no doc
    if (!getUserSettings.value?.data) {
      await DatabaseManager.UserSettingsModel.create(
        {
          excludedAccountIds: [],
          labCardFavorites: [],
          filterFavorites: [],
          listFavorites: [],
          quickAccessFilters: companyDefaultsWithNewSelection,
        },
        userSettingsDocId,
      );
      // null filters
    } else if (getUserSettings.value.data.quickAccessFilters === null) {
      await getUserSettings.value.ref.update(
        'quickAccessFilters',
        companyDefaultsWithNewSelection,
      );
    } else {
      const { data } = getUserSettings.value;
      const isFavorited = data?.quickAccessFilters?.find(
        (id) => id === filterField,
      );
      if (isFavorited) {
        await getUserSettings.value.ref.update({
          quickAccessFilters: FieldValue.arrayRemove(filterField),
        });
      } else {
        await getUserSettings.value.ref.update({
          quickAccessFilters: FieldValue.arrayUnion(
            ...(data.quickAccessFilters ?? []),
            filterField,
          ),
        });
      }
    }
    getUserSettings.execute();
  };

  return (
    <Flex
      bg={'brand.white'}
      direction={'column'}
      h={panelHeight}
      left={navbarSizeStr}
      position={'fixed'}
      pt={3}
      top={panelTop}
      w={fullNavPanelWidthStr}
      zIndex={-1}
      overflow={'hidden'}
      pb={8}
    >
      <Flex
        overflowY={'auto'}
        overflowX='hidden'
        direction={'column'}
        height={panelHeight}
        justify={'space-between'}
        sx={{ scrollbarGutter: 'stable' }}
      >
        <Tabs>
          <>
            <TabList>
              {orderedPanel.map((panel) => (
                <Tab
                  key={panel.label}
                  borderBottomColor={'transparent'}
                  borderBottomWidth={2}
                  _selected={{
                    borderBottomColor: 'brand.coral',
                    color: '#152f45',
                  }}
                >
                  <Text fontSize={'xs'}>{panel.label}</Text>
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {orderedPanel.map((panel) => (
                <TabPanel key={panel.order}>
                  <Accordion allowMultiple allowToggle>
                    {panel.filters.map((filterItem) => (
                      <AccordionItem>
                        <Flex>
                          {isEditingFavorites && (
                            <Tooltip
                              content={
                                favoritedQuickAccessFilters.includes(
                                  filterItem.field,
                                )
                                  ? 'Unfavorite this filter'
                                  : 'Favorite this filter'
                              }
                              hideIcon
                            >
                              <IconButton
                                mr={-2}
                                aria-label={'favorite-list'}
                                icon={
                                  <StarIcon
                                    color={
                                      favoritedQuickAccessFilters.includes(
                                        filterItem.field,
                                      )
                                        ? 'orange'
                                        : 'brand.gray-600'
                                    }
                                  />
                                }
                                variant={'transparent'}
                                onClick={() =>
                                  toggleFavoritingQuickFilter(filterItem.field)
                                }
                              />
                            </Tooltip>
                          )}
                          <AccordionButton>
                            <Box
                              flex={1}
                              textAlign={'left'}
                              fontWeight={'bold'}
                            >
                              {filterItem.tooltip &&
                              filterItem.tooltip.length > 0 ? (
                                <Tooltip content={filterItem.tooltip}>
                                  <Text fontWeight={'bold'}>
                                    {filterItem.title}
                                  </Text>
                                </Tooltip>
                              ) : (
                                <Text fontWeight={'bold'}>
                                  {filterItem.title}
                                </Text>
                              )}
                            </Box>
                            <AccordionIcon
                              bg={'brand.gray-300'}
                              borderRadius={12}
                            />
                          </AccordionButton>
                        </Flex>
                        <AccordionPanel>
                          <FilterItem filterItem={filterItem} />
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </TabPanel>
              ))}
            </TabPanels>
          </>
        </Tabs>
        <Button
          text={'Favorites'}
          leftIcon={isEditingFavorites ? <CheckCircleIcon /> : <EditIcon />}
          variant={isEditingFavorites ? 'action' : 'actionOutline'}
          maxW={120}
          ml={4}
          onClick={() => setIsEditingFavorites((prevState) => !prevState)}
          borderWidth={2}
          borderRadius={24}
          borderColor={'#ECF2FA'}
          fontWeight={'normal'}
        />
      </Flex>
    </Flex>
  );
};
