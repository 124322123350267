import { navbarSizeInt } from 'app/src/constants/navbarSize';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useWindowSize } from 'app/src/hooks/useWindowSize';
import { useReactiveVar } from '@apollo/client';
import {
  appliedFilterVar,
  appliedListsVar,
  inTemporaryGlobalFilterStateVar,
} from 'app/src/apollo/rootReactiveVariables';
import { CloseIcon } from '@chakra-ui/icons';
import { useGetAllAccountGroupsQuery } from 'shared/graphql/generatedApiTypes';
import { useContext, useMemo } from 'react';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';

export const AppliedSavedGroupsBar = () => {
  const { width: windowWidth } = useWindowSize();
  const { updateFilter, resetFilter } = useContext(AggregateDataContext);
  const appliedLists = useReactiveVar(appliedListsVar);
  const appliedFilter = useReactiveVar(appliedFilterVar);
  const inTemporaryGlobalFilterState = useReactiveVar(
    inTemporaryGlobalFilterStateVar,
  );

  const { data: accountGroupData } = useGetAllAccountGroupsQuery();
  const appliedListTags = useMemo(() => {
    if (appliedLists) {
      return appliedLists.map((id) => ({
        id,
        title:
          accountGroupData?.accountGroups?.find((group) => group?.id === id)
            ?.title ?? '',
      }));
    }
    return [];
  }, [accountGroupData, appliedLists]);

  const removeAppliedSavedList = (id) => {
    updateFilter({
      index: FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS,
      value: appliedLists.filter((list) => list !== parseInt(id)),
    });
  };

  const removeAppliedSavedFilter = () => {
    resetFilter();
    appliedFilterVar(null);
  };

  return (appliedLists?.length || appliedFilter) &&
    !inTemporaryGlobalFilterState ? (
    <Flex
      px={4}
      maxW={`${windowWidth + 4 - navbarSizeInt}px`}
      align={'center'}
      bg={'#F5F8F9'}
      pb={2}
    >
      {!!appliedLists?.length && (
        <Text mr={3} fontStyle={'italic'}>
          Saved lists:
        </Text>
      )}
      {appliedListTags.map((list) => (
        <Flex
          bg={'brand.klarityBlue'}
          color={'brand.white'}
          key={list.id}
          py={1}
          px={2}
          mr={2}
          align={'center'}
        >
          <Text mr={2}>{list.title}</Text>
          <Box
            px={2}
            onClick={() => removeAppliedSavedList(list.id)}
            _hover={{ cursor: 'pointer' }}
          >
            <CloseIcon boxSize={3} />
          </Box>
        </Flex>
      ))}
      {appliedFilter && (
        <Flex align={'center'}>
          <Text mr={3} fontStyle={'italic'}>
            Saved filter:
          </Text>

          <Flex
            bg={'brand.klarityBlue'}
            color={'brand.white'}
            key={appliedFilter.id}
            py={1}
            px={2}
            mr={2}
            align={'center'}
          >
            <Text mr={2}>{appliedFilter.title}</Text>
            <Box
              px={2}
              onClick={removeAppliedSavedFilter}
              _hover={{ cursor: 'pointer' }}
            >
              <CloseIcon boxSize={3} />
            </Box>
          </Flex>
        </Flex>
      )}
    </Flex>
  ) : null;
};
