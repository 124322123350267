import {createIcon} from '@chakra-ui/icon';

export const GaugeIcon = createIcon({
  displayName: 'Gauge',
  viewBox: '0 0 57 56',
  path: (
    <svg
      width='57'
      height='56'
      viewBox='0 0 57 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M45.4295 14.0004C45.5999 14.2036 45.6826 14.4661 45.6595 14.7303C45.5782 15.6679 43.5524 37.7673 34.9076 45.0212C33.2069 46.4769 31.2329 47.5788 29.1011 48.2624C26.9694 48.946 24.7227 49.1977 22.4926 49.0025C20.2624 48.8074 18.0936 48.1695 16.1129 47.1261C14.1323 46.0827 12.3796 44.6547 10.9575 42.9258C9.50177 41.2251 8.39986 39.2511 7.71624 37.1193C7.03262 34.9876 6.781 32.7409 6.97611 30.5108C7.17123 28.2806 7.80915 26.1118 8.85256 24.1311C9.89597 22.1505 11.3239 20.3978 13.0528 18.9757C21.6977 11.7218 43.8131 13.5644 44.7506 13.6471C45.0148 13.6702 45.259 13.7973 45.4295 14.0004Z'
        fill='#274A67'
      />
      <path
        d='M28.576 28.1417C26.446 25.6032 22.6615 25.2721 20.123 27.4022C17.5846 29.5322 17.2535 33.3167 19.3835 35.8551C21.5135 38.3936 25.2981 38.7247 27.8365 36.5947C30.3749 34.4647 30.7061 30.6801 28.576 28.1417Z'
        fill='white'
      />
    </svg>
  ),
});
