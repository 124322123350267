import { useMemo, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import _startCase from 'lodash/startCase';

import {
  IndeterminateCheckbox,
  Table,
  Tooltip,
  Txt,
} from 'shared/components/Core';

import SectionHeader from 'shared/components/Core/SectionHeader';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { Text } from '@chakra-ui/react';

type PrioritySignalRecommendationsType = {
  data: Array<{}>;
  onSignalSelection: (signals: Array<string>) => void;
};

const PrioritySignalRecommendations = ({
  data = [],
  onSignalSelection = () => {},
}: PrioritySignalRecommendationsType) => {
  // setup state
  const [selectedRowSignals, setSelectedRowSignals] = useState<Array<string>>(
    [],
  );

  // setup table columns and data
  const tableColumns = useMemo(
    () => [
      {
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            {...row.getToggleRowSelectedProps()}
            analyticsAttr={cleanAnalyticsStringList([
              'Primary Actions',
              row.original['signal_type'].value,
            ])}
          />
        ),
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            {...getToggleAllRowsSelectedProps()}
            analyticsAttr={cleanAnalyticsStringList([
              'Primary Actions all actions',
            ])}
          />
        ),
        accessor: 'id',
        analyticsAttrIndex: 'signal_type',
        disableSortBy: true,
      },
      {
        Header: 'Priority Signal',
        columns: [
          {
            Cell: ({ value }) => _startCase(value),
            Header: (
              <Tooltip
                analyticsAttr={'Signal'}
                content={
                  <Text>
                    {'Either an outbound activity targeting an account or an inbound response from an' +
                      ' account.'}
                  </Text>
                }
                text={'Signal'}
              />
            ),
            accessor: 'signal_type',
            analyticsAttrIndex: 'signal_type',
          },
          {
            Cell: ({ value }) => value.toLocaleString(),
            Header: 'Total Accounts',
            accessor: 'accounts',
            analyticsAttrIndex: 'signal_type',
          },
          {
            Cell: ({ value }) => value.toLocaleString(),
            Header: (
              <Tooltip
                analyticsAttr={'Pursue'}
                content={
                  <Txt>
                    {
                      'Increase or maintain signal performance to improve the possible account score.'
                    }
                  </Txt>
                }
                text={'Pursue'}
              />
            ),
            accessor: 'pursue',
            analyticsAttrIndex: 'signal_type',
          },
          {
            Cell: ({ value }) => value.toLocaleString(),
            Header: (
              <Tooltip
                analyticsAttr={'Pause'}
                content={
                  <Txt>
                    {
                      'Decrease or stop signal performance to improve the possible account score.'
                    }
                  </Txt>
                }
                text={'Pause'}
              />
            ),
            accessor: 'pause',
            analyticsAttrIndex: 'signal_type',
          },
        ],
      },
    ],
    [],
  );
  const tableData = useMemo(() => {
    return data;
  }, [data]);

  return (
    <>
      <SectionHeader
        addHeaderLeftMargin
        showBackgroundColor={false}
        subtitle={'Number of accounts per recommended action by signal type.'}
        title={'Primary Actions'}
      />
      <div className={'h-p-lg'}>
        <Table
          analyticsAttr={'Primary Actions'}
          columns={tableColumns}
          data={tableData}
          initialDefaultSortByColumnDesc={true}
          initialDefaultSortByColumnId={'accounts'}
          selectedRowIndex={'signal_type'}
          setSelectedRows={(signalTypes) => {
            if (!_isEqual(_sortBy(signalTypes), _sortBy(selectedRowSignals))) {
              setSelectedRowSignals(signalTypes);
              onSignalSelection(signalTypes);
            }
          }}
        />
      </div>
    </>
  );
};

export default PrioritySignalRecommendations;
