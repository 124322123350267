import { ChartType, LabCardApiResponse } from '../types/labApiTypes';
import { LabPieChart } from './labCharts/LabPieChart';
import { LabBarChart } from './labCharts/LabBarChart';
import { LabHeatMap } from './labCharts/LabHeatMap';
import { LabScatterBubbleChart } from './labCharts/LabScatterBubbleChart';

type LabChartProps = {
  columns: LabCardApiResponse['columns'];
  context: string | undefined;
  displayType: ChartType;
  isAnswerTypePercent: boolean;
  isChartClickable: boolean;
  isDollars: boolean;
  isMultiClickable?: boolean;
  isSmallerViewport?: boolean;
  monoColor: string | undefined;
  rows: LabCardApiResponse['rows'];
  sortOrder: 'desc' | 'asc';
  totalNumber: number;
};

export const LabChart = ({
  rows,
  columns,
  context = undefined,
  displayType,
  isAnswerTypePercent = false,
  isChartClickable = false,
  isDollars = false,
  isSmallerViewport = false,
  monoColor = undefined,
  sortOrder = 'desc',
  totalNumber = 0,
}: LabChartProps) =>
  displayType === ChartType.pie ? (
    <LabPieChart
      columns={columns}
      context={context}
      isAnswerTypePercent={isAnswerTypePercent}
      isChartClickable={isChartClickable}
      isSmallerViewport={isSmallerViewport}
      rows={rows}
      totalNumber={totalNumber}
    />
  ) : displayType === ChartType.bar ? (
    <LabBarChart
      columns={columns}
      context={context}
      isAnswerTypePercent={isAnswerTypePercent}
      isChartClickable={isChartClickable}
      isDollars={isDollars}
      isSmallerViewport={isSmallerViewport}
      monoColor={monoColor}
      rows={rows}
      sortOrder={sortOrder}
      totalNumber={totalNumber}
    />
  ) : displayType === ChartType.heat ? (
    <LabHeatMap
      columns={columns}
      isSmallerViewport={isSmallerViewport}
      rows={rows}
    />
  ) : [ChartType.bubble, ChartType.scatter].includes(displayType) ? (
    <LabScatterBubbleChart
      columns={columns}
      handleBubbleSize={displayType === 'bubble'}
      isAnswerTypePercent={isAnswerTypePercent}
      rows={rows}
    />
  ) : null;
