import { ApiGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import _isNull from 'lodash/isNull';
import { formatDateString } from 'shared/helpers/formatHelpers';

export function getClosedWonBannerText(
  accountInfo: ApiGetAccountQuery['account'],
) {
  const {
    expectedValue,
    goalValues,
    lostOpportunityCount,
    wonOpportunityCount,
  } = { ...accountInfo };
  let text: string | boolean = false;
  const isPredictionPayloadEmpty =
    _isNull(expectedValue) && _isNull(goalValues);
  const wonOpportunityCountAsNumber = wonOpportunityCount ?? 0;
  const lostOpportunityCountAsNumber = lostOpportunityCount ?? 0;
  const hasClosedOpportunity =
    wonOpportunityCountAsNumber + lostOpportunityCountAsNumber > 0;
  const dateFormatString = 'd LLLL, y';
  const predictionsLastMadeOnDate = goalValues?.[0]
    ? formatDateString(
        new Date(goalValues?.[0]?.calculatedAt.replace(/-/g, '/')),
        dateFormatString,
      )
    : '';
  const hasWonOpportunityCount =
    wonOpportunityCount === 0 || wonOpportunityCountAsNumber > 0;
  const wonOrLost =
    hasClosedOpportunity && wonOpportunityCountAsNumber > 0
      ? 'won'
      : hasClosedOpportunity && wonOpportunityCount === 0
      ? 'lost'
      : null;
  // setup text logic
  const klearlyPredictionsMessage =
    'Klearly does not make predictions for accounts with closed opportunities.';
  if (
    isPredictionPayloadEmpty &&
    hasClosedOpportunity &&
    hasWonOpportunityCount
  ) {
    text = `Attention: An opportunity for this account was ${wonOrLost} before Klearly began making predictions for your company. ${klearlyPredictionsMessage}`;
  }
  if (
    !isPredictionPayloadEmpty &&
    hasClosedOpportunity &&
    hasWonOpportunityCount
  ) {
    text = `Attention: An opportunity for this account was ${wonOrLost} after Klearly began making predictions for your company. The following predictions for this account were last made on ${predictionsLastMadeOnDate}. ${klearlyPredictionsMessage}`;
  }
  // return value
  return text;
}
