import {LabScatterBubbleChartProps} from '../../types/labChartTypes';
import {ScatterChart} from '../../../../../../shared/components/Core';
import {getLabsScatterBubbleChartValues} from '../../helpers/labChartHelpers';

export const LabScatterBubbleChart = ({
  columns,
  handleBubbleSize = true,
  isChartClickable = false,
  rows,
}: LabScatterBubbleChartProps) => {
  const {
    data,
    xAxisLabel,
    yAxisLabel,
    zAxisLabel,
  } = getLabsScatterBubbleChartValues({ columns, rows });
  return (
    <ScatterChart
      data={data}
      handleBubbleSize={handleBubbleSize}
      isChartClickable={isChartClickable}
      isPercent={true}
      xAxisLabel={xAxisLabel}
      yAxisLabel={yAxisLabel}
      zAxisLabel={zAxisLabel}
    />
  );
};
