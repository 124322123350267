import { OptionsType } from 'shared/types/coreTypes.d';
import { SingleAccountSignalsTimePeriod } from '../types/SingleAccountSignalsTypes';

export const singleAccountSignalHistoryTimeOptions: OptionsType[] = [
  {
    label: '1 Week',
    value: SingleAccountSignalsTimePeriod.oneWeek  
  },
  {
    label: '1 Month',
    value: SingleAccountSignalsTimePeriod.oneMonth  
  },
  {
    label: '6 Months',
    value: SingleAccountSignalsTimePeriod.sixMonths  
  },
  {
    label: 'Year-to-date',
    value: SingleAccountSignalsTimePeriod.yearToDate  
  },
  {
    label: '1 Year',
    value: SingleAccountSignalsTimePeriod.oneYear  
  }
]