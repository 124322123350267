import { BaseModel } from './BaseModel';
import { UserSettingsSchema } from '../schemas/UserSettings';
import { Firestore } from '../types/firebaseTypes';
import { UserSettingsDocument } from '../documents/UserSettingsDocument';
import { firebaseEnvPrefix } from '../constants/constants';

export class UserSettingsModel extends BaseModel<
  UserSettingsSchema,
  UserSettingsDocument
> {
  constructor(firestore: Firestore | null) {
    super(
      firestore,
      'UserSettings',
      `${firebaseEnvPrefix}_user_settings`,
      UserSettingsDocument,
    );
  }
}
