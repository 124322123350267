import { Sankey, Tooltip } from 'recharts';
import { SankeyLink } from './SankeyLink';
import { SankeyNode } from './SankeyNode';
import { Card } from '../../../../../shared/components/Core';
import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from '../../../../../shared/components/Core/SectionHeader';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
  ApiAccountCountGroupBy,
  useGetAccountCountByGroupingQuery,
} from 'shared/graphql/generatedApiTypes';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { mapAccountCountToSankey } from '../helpers/mapAccountCountToSankey';

export type SankeyChartProps = {
  title: string;
  subtitle: string;
  tooltip: string;
  colorsByScoreBucket: { [key: string]: string };
};

export const SankeyChart = ({
  title,
  subtitle,
  tooltip,
  colorsByScoreBucket,
}: SankeyChartProps) => {
  const { data: accountCountData, loading: accountCountLoading } =
    useGetAccountCountByGroupingQuery({
      ...defaultQueryFetchPolicy,
      notifyOnNetworkStatusChange: true,
      //@ts-ignore - filter string isn't required because it comes client side but TS doesn't know
      variables: {
        groupBy: [
          ApiAccountCountGroupBy.CurrentAccountScoreBucket,
          ApiAccountCountGroupBy.PossibleAccountScoreBucket,
          ApiAccountCountGroupBy.SignalType,
        ],
      },
    });

  const mappedData = mapAccountCountToSankey(
    accountCountData,
    colorsByScoreBucket,
  );
  const { colorGradients, colors, links, nodes } = mappedData;

  const cardRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number>(0);
  const [areLinksFaded, setAreLinksFaded] = useState<boolean>(false);
  const height = 500;

  useEffect(() => {
    if (cardRef.current) {
      setWidth(cardRef.current.clientWidth * 0.95);
    }
  }, [cardRef.current?.clientWidth]);

  return (
    <Card ref={cardRef} isLoading={accountCountLoading}>
      <SectionHeader
        title={title}
        subtitle={subtitle}
        tooltip={tooltip}
        showBackgroundColor={false}
        addHeaderLeftMargin
      />
      <Box borderWidth={1} borderColor={'brand.gray-200'} mb={2} />
      <Flex mx={8} justify={'space-between'}>
        <Text fontWeight={'bold'}>Current</Text>
        <Text fontWeight={'bold'}>Recommendation</Text>
        <Text fontWeight={'bold'}>Possible</Text>
      </Flex>
      <Flex justify={'center'}>
        {!!mappedData.links.length && (
          <Sankey
            width={width}
            height={height}
            margin={{ top: 20, bottom: 0 }}
            data={{ links, nodes }}
            nodeWidth={0}
            nodePadding={10}
            linkCurvature={0.3}
            iterations={0}
            link={
              <SankeyLink
                colorGradients={
                  areLinksFaded
                    ? colorGradients.map(() => ({
                        source: '#eef2f5',
                        target: '#eef2f5',
                      }))
                    : colorGradients
                }
                setAreLinksFaded={setAreLinksFaded}
              />
            }
            node={(props) => (
              <SankeyNode {...props} containerWidth={width} colors={colors} />
            )}
          >
            <Tooltip />
          </Sankey>
        )}
      </Flex>
      <Flex justify={'space-between'} mx={8}>
        <Text fontWeight={'bold'}>Today</Text>
        <Text fontWeight={'bold'}>+ 90 days</Text>
      </Flex>
      <Box borderWidth={1} borderColor={'brand.gray-200'} mt={2} />
    </Card>
  );
};
