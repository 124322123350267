import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { MessageIcon } from './MessageIcon';
import { useContext, useMemo } from 'react';
import {
  ApiMessageTag,
  ApiMessageType,
} from 'shared/graphql/generatedApiTypes';
import { getMessageLabel } from 'app/src/components/MessageFeed/helpers/getMessageLabel';
import { Button } from 'shared/components/Core';
import { SelectedCompaniesIcon } from 'shared/components/Core/Icon/companies';
import {
  globalFilterVar,
  inTemporaryGlobalFilterStateVar,
} from 'app/src/apollo/rootReactiveVariables';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { useHistory } from 'react-router-dom';

export const MessageItem = ({
  messageType,
  message,
  date,
  accountsToLink,
  tags,
}: {
  messageType: ApiMessageType;
  message: string;
  date?: string;
  accountsToLink?: Array<number | null | undefined>;
  tags?: Array<ApiMessageTag | null | undefined> | null;
}) => {
  const icon = useMemo(
    () => <MessageIcon messageType={messageType} />,
    [messageType],
  );
  const { globalFilter } = useContext(AggregateDataContext);

  const label = getMessageLabel(messageType);
  const history = useHistory();

  const setFilterAndNavigate = () => {
    inTemporaryGlobalFilterStateVar(globalFilter);
    globalFilterVar({
      accountIds: accountsToLink,
      accounts: [],
      accountFilterType: 'and',
    });
    history.push('/companies');
  };

  return (
    <Stack spacing={6} p={2}>
      <Flex justify={'space-between'} align={'center'}>
        <HStack spacing={4} align={'center'}>
          {icon}
          <Text fontWeight={'bold'} fontSize={'lg'}>
            {label}
          </Text>
          {date && <Box bg={'brand.gray-400'} w={1} h={1} borderRadius={4} />}
          {date && <Text fontSize={'md'}>{date}</Text>}
        </HStack>
        {accountsToLink?.length && (
          <Button
            bg={'brand.cornflower'}
            color={'white'}
            leftIcon={<SelectedCompaniesIcon />}
            text={`View ${accountsToLink.length} companies`}
            size={'sm'}
            variant={'transparent'}
            onClick={setFilterAndNavigate}
          />
        )}
      </Flex>
      <Flex justify={'space-between'}>
        <Text mr={8}>{message}</Text>
        {tags?.map((tag) => (
          <Text
            fontSize={'xs'}
            p={1}
            bg={'brand.gray-200'}
            borderWidth={1}
            borderColor={'brand.black'}
            borderRadius={8}
            maxH={8}
          >
            {tag?.toLowerCase()}
          </Text>
        ))}
      </Flex>
    </Stack>
  );
};
