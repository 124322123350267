import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { AccountDrawerButtonRenderer } from 'app/src/components/TableSection/renderers/AccountDrawerButtonRenderer';
import { AccountDrawerItemRenderer } from 'app/src/components/TableSection/renderers/AccountDrawerItemRenderer';
import _size from 'lodash/size';
import React from 'react';
import {
  ActionDrawerData,
  ButtonActionType,
  TableColumnDisplay,
  TableColumnType
} from 'shared/firebase/types/tableTypes';
import { cleanStringCase } from 'shared/helpers/formatHelpers';
import {
  DataFieldFormat,
  DataFieldRenderer
} from 'shared/renderers/DataFieldRenderer';
import { IconRenderer } from 'shared/renderers/IconRenderer';
import { Tooltip } from '../../../../../shared/components/Core';
import Button from '../../../../../shared/components/Core/Button';

export const getTableCell = ({
  column,
  history,
}: {
  column: TableColumnType;
  history: any;
}) => {
  switch (column.displayType) {
    case TableColumnDisplay.Button:
      return ({ row, value }) => {
        const iconValue = column.iconAccessor
          ? row.original[column.iconAccessor]
          : null;
        switch (column.actionData.type) {
          case ButtonActionType.Navigate:
            const navigationData = column.actionData.data as {
              routeToPush: string;
              hash?: string;
            };
            return value !== null &&
              value !== undefined &&
              value.toString().length > 0 ? (
              <Button
                analyticsAttr={`${column.header}-${value}`}
                onClick={() => {
                  history.push(
                    `${navigationData.routeToPush}/${row.original.id}#${
                      navigationData?.hash ?? ''
                    }`,
                  );
                  window.scrollTo(0, 0);
                }}
                leftIcon={
                  column.leftIcon && iconValue ? (
                    <Tooltip content={cleanStringCase(iconValue)} hideIcon>
                      {IconRenderer({
                        iconName: column.leftIcon,
                        iconColor: 'brand.blue',
                        value: iconValue,
                      })}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
                maxW={72}
                // w={"100%"}
                ml={0}
                text={
                  column.truncateAt
                    ? DataFieldRenderer(
                        value,
                        column.dataFieldFormat,
                        column.truncateAt,
                      )
                    : DataFieldRenderer(value, column.dataFieldFormat)
                }
                fontProps={{
                  color: 'brand.lightBlue',
                  fontWeight: 'normal',
                  textDecoration: 'underline',
                }}
                variant={'transparent'}
              />
            ) : (
              '-'
            );
          case ButtonActionType.Drawer:
            const drawerData = column.actionData.data as ActionDrawerData;
            const actionDrawer = useDisclosure();
            return value && _size(value) > 0 ? (
              <>
                <Button
                  analyticsAttr={`${column.header}-${value}`}
                  onClick={actionDrawer.onOpen}
                  leftIcon={
                    column.leftIcon && iconValue ? (
                      <Tooltip content={cleanStringCase(iconValue)} hideIcon>
                        {IconRenderer({
                          iconName: column.leftIcon,
                          iconColor: 'brand.blue',
                          value: iconValue,
                        })}
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                  maxW={72}
                  text={
                    column.truncateAt
                      ? DataFieldRenderer(
                          value,
                          column.dataFieldFormat,
                          column.truncateAt,
                        )
                      : DataFieldRenderer(value, column.dataFieldFormat)
                  }
                  fontProps={{
                    color: 'brand.lightBlue',
                    fontWeight: 'normal',
                    textDecoration: 'underline',
                  }}
                  variant={'transparent'}
                />
                <Drawer
                  isOpen={actionDrawer.isOpen}
                  placement='right'
                  onClose={actionDrawer.onClose}
                  size={'md'}
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='1px'>
                      <Text>{drawerData.title.label}</Text>
                    </DrawerHeader>
                    <DrawerBody>
                      <Text fontSize={'lg'}>
                        {row.original[drawerData.title.dataField]}
                      </Text>
                      <Stack spacing='24px'>
                        {drawerData.sections.map((section) => (
                          <>
                            <Text fontSize={'lg'} fontWeight={'bold'}>
                              {section.title}
                            </Text>
                            {section.fields.map((field) => (
                              <>
                                {AccountDrawerItemRenderer({
                                  accountInfo: row.original,
                                  drawerSectionField: field,
                                })}
                              </>
                            ))}
                          </>
                        ))}
                      </Stack>
                      <Flex gap={2} mt={4} justifyContent={'center'}>
                        {drawerData.buttons?.map((buttonType) => (
                          <>
                            {AccountDrawerButtonRenderer({
                              accountId: row.original.id,
                              buttonType,
                              onDrawerClose: actionDrawer.onClose,
                            })}
                          </>
                        ))}
                      </Flex>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            ) : (
              '-'
            );
        }
      };
    case TableColumnDisplay.Static:
      return ({ value }) =>
        column.leftIcon ? (
          <Flex align={'center'}>
            <Box mr={1}>
              {IconRenderer({
                iconName: column.leftIcon,
                iconColor: 'brand.black',
                value,
              })}
            </Box>
            {DataFieldRenderer(
              value,
              column.dataFieldFormat,
              column.truncateAt,
            )}
          </Flex>
        ) : (
          DataFieldRenderer(value, column.dataFieldFormat, column.truncateAt)
        );
    case TableColumnDisplay.NonData:
      return () =>
        column.leftIcon ? (
          <Flex align={'center'}>
            {IconRenderer({
              iconName: column.leftIcon,
              iconColor: 'brand.black',
              value: column.value,
            })}
            {DataFieldRenderer(column.value, DataFieldFormat.UNFORMATTEDTEXT)}
          </Flex>
        ) : (
          DataFieldRenderer(column.value, DataFieldFormat.UNFORMATTEDTEXT)
        );
    case TableColumnDisplay.Expander:
      return ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <ChevronDownIcon fontSize={'4xl'} minW={3} />
          ) : (
            <ChevronRightIcon fontSize={'4xl'} minW={3} />
          )}
        </span>
      );
    default:
      return <></>;
  }
};
