import { Button, IconButton } from '@chakra-ui/react';
import { subYears } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'shared/components/Core';
import { DownloadIcon } from 'shared/components/Core/Icon/download';
import { MoneyIcon } from 'shared/components/Core/Icon/money';
import { UploadIcon } from 'shared/components/Core/Icon/upload';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { useGetAccountSignalsLazyQuery } from 'shared/graphql/generatedApiTypes';
import { formatDateString } from 'shared/helpers/formatHelpers';
import {
  LastInboundLogic,
  LastOutboundLogic,
  purchaseEventLogic
} from '../TableSection/renderers/AccountDrawerItemRenderer';

export default function SignalTimeline() {
  const [getAccountSignals, { data, loading }] = useGetAccountSignalsLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const [timelineData, setTimeLineData] = useState<any>([]);
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const [showMore, setShowMore] = useState<boolean>(false);
  const companyId = AuthManager.klearlyUser?.company?.id;

  const RenderIcon = (item) => {
    if (item?.direction === 'response') {
      if (item?.action === 'sell') {
        return (
          <div>
            <IconButton
              isRound={true}
              colorScheme='white'
              aria-label='Search database'
              className='activity-btn'
              icon={MoneyIcon}
              size='sm'
              style={{ cursor: 'none' }}
            />
            {item?.type === 'order_line_item' && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{purchaseEventLogic(item)}</span>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <IconButton
              isRound={true}
              colorScheme='white'
              aria-label='Search database'
              className='activity-btn'
              icon={DownloadIcon}
              size='sm'
              style={{ cursor: 'none' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{LastInboundLogic({ lastInboundSignal: item })}</span>
            </div>
          </div>
        );
      }
    } else {
      if (item?.direction === 'activity') {
        return (
          <div>
            <IconButton
              isRound={true}
              colorScheme='white'
              aria-label='Search database'
              className='activity-btn'
              icon={UploadIcon}
              size='sm'
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{LastOutboundLogic({ lastOutboundSignal: item })}</span>
            </div>
          </div>
        );
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (getAccountSignals && !loading) {
        await getAccountSignals({
          variables: {
            accountId: parseInt(selectedAccountId),
            startDate: formatDateString(subYears(new Date(), 1)),
            endDate: formatDateString(new Date()),
          },
        }).then((res) => {
          if (
            res?.data !== undefined &&
            res?.data?.accountSignals !== undefined &&
            res?.data?.accountSignals !== null
          ) {
            let temp: any = [...res?.data?.accountSignals];
            temp.sort(
              (d1, d2) =>
                new Date(d2.occurredAt).getTime() -
                new Date(d1.occurredAt).getTime(),
            );
            setTimeLineData(temp);
          }
        });
      }
    })();
  }, [getAccountSignals]);

  return (
    <Card
      isLoading={loading}
      style={{minHeight: 'fit-content'}}
    >
      <span style={{ fontSize: 24, fontWeight: 'bold' }}>Recent History</span>
      <div className='signal-recent-activity'>
        {timelineData.length ? (
          <>
            {!showMore
              ? timelineData.slice(0, 10).map((item, index) => {
                  return RenderIcon(item);
                })
              : timelineData.map((item, index) => {
                  return RenderIcon(item);
                })}
            {timelineData.length > 10 && (
              <>
                {!showMore ? (
                  <Button onClick={(e) => setShowMore(true)}>Show More</Button>
                ) : (
                  <Button onClick={(e) => setShowMore(false)}>Show Less</Button>
                )}
              </>
            )}
          </>
        ) : (
          <span style={{ fontSize: 20 }}>There is no history available</span>
        )}
      </div>
    </Card>
  );
}
