import { useEffect, useState } from 'react';
import { EngagementLineChartType } from '../types/homeTypes';
import { Card } from '../../../../../shared/components/Core';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import {
  ApiGetSignalHistoryQuery,
  useGetSignalHistoryQuery,
} from 'shared/graphql/generatedApiTypes';
import { useDefinedMediaQuery } from 'shared/hooks/mediaQueryHook';
import { getMaxYValue } from '../helpers/engagementLineChartHelpers';
import { useReactiveVar } from '@apollo/client';
import { globalFilterVar } from 'app/src/apollo/rootReactiveVariables';
import { useSignalSourceButtons } from 'app/src/hooks/useSignalSourceButtons';
import { useSignalDirectionButtons } from 'app/src/hooks/useSignalDirectionButtons';
import SectionHeader from '../../../../../shared/components/Core/SectionHeader';
import { EngagementLineChart } from './EngagementLineChart';
import { Box, Flex } from '@chakra-ui/react';
import { sortByDate } from 'app/src/components/Home/helpers/sortByDate';

export type EngagementLineChartState = {
  lineChartData: ApiGetSignalHistoryQuery['signalHistory'];
  selectedData: EngagementLineChartType;
};

const initialEngagementLineChartState: EngagementLineChartState = {
  lineChartData: null,
  selectedData: {
    data: [],
    maxYLeftValue: 0,
    maxYRightValue: 0,
  },
};

export type EngagementLineChartWrapperProps = {
  sectionHeaderText: string;
  sectionHeaderSubtext?: string;
  sectionHeaderTooltip?: string;
  notesHeaderText: string;
  notesHeaderSubtext?: string;
  notesHeaderTooltip?: string;
};

export const EngagementLineChartWrapper = ({
  sectionHeaderSubtext,
  sectionHeaderTooltip,
  sectionHeaderText,
  notesHeaderSubtext,
  notesHeaderText,
  notesHeaderTooltip,
}: EngagementLineChartWrapperProps) => {
  useReactiveVar(globalFilterVar);
  const [state, setState] = useState<EngagementLineChartState>(
    initialEngagementLineChartState,
  );

  const { signalSourceComponent, signalSource } = useSignalSourceButtons(
    'engagement-intensity',
  );
  const { signalDirectionComponent, signalDirection } =
    useSignalDirectionButtons('engagement-intensity');

  const { isSmallerViewport } = useDefinedMediaQuery();

  const { data, loading } = useGetSignalHistoryQuery({
    ...defaultQueryFetchPolicy,
    notifyOnNetworkStatusChange: true,
    //@ts-ignore - TODO - clean up these type issues w/filter string
    variables: {
      timeRange: {
        daysFuture: 0,
        daysPast: 182,
        intervalDays: 7,
      },
    },
  });

  useEffect(() => {
    if (data?.signalHistory) {
      setState((prevState) => ({
        ...prevState,
        lineChartData: data.signalHistory,
      }));
    }
  }, [data]);

  useEffect(() => {
    // It's ok to assert the existence of line chart data throughout the setState b/c of this check
    if (state.lineChartData) {
      setState((prevState) => ({
        ...prevState,
        selectedData: {
          data: state.lineChartData![`${signalSource}${signalDirection}`]
            .signalHistoryItems
            ? sortByDate(
                state.lineChartData![`${signalSource}${signalDirection}`]
                  .signalHistoryItems,
                'endDate',
              )
            : [],
          maxYLeftValue: getMaxYValue({
            //@ts-ignore
            data:
              state.lineChartData![`${signalSource}${signalDirection}`]
                .signalHistoryItems ?? [],
            propName: 'signalCount',
          }),
          maxYRightValue: getMaxYValue({
            //@ts-ignore
            data:
              state.lineChartData![`${signalSource}${signalDirection}`]
                .signalHistoryItems ?? [],
            propName: 'accountCount',
          }),
        },
      }));
    }
  }, [signalDirection, signalSource, state.lineChartData]);

  return (
    <Card isLoading={loading}>
      <div
        style={{
          display: 'flex',
          flexDirection: isSmallerViewport ? 'column' : 'row',
        }}
        className={'h-p-md'}
      >
        <div style={{ flex: 2 }}>
          <SectionHeader
            title={sectionHeaderText}
            subtitle={sectionHeaderSubtext}
            tooltip={sectionHeaderTooltip}
            showBackgroundColor={false}
            addHeaderLeftMargin
          />
          <Box className={'h-border-bottom h-p-lg'}>
            <Flex justify={'space-between'}>
              <Box>{signalSourceComponent}</Box>
              <Box>{signalDirectionComponent}</Box>
            </Flex>
          </Box>
          {!loading && state.selectedData?.data.length && (
            <EngagementLineChart
              data={state.selectedData.data}
              maxYRightValue={state.selectedData.maxYRightValue}
              maxYLeftValue={state.selectedData.maxYLeftValue}
            />
          )}
        </div>
        <div style={{ flex: 1 }}>
          <SectionHeader
            title={notesHeaderText}
            subtitle={notesHeaderSubtext}
            tooltip={notesHeaderTooltip}
            showBackgroundColor={false}
            addHeaderLeftMargin
          />
          <ul
            style={{
              listStyleType: 'disc',
              paddingLeft: '60px',
              fontSize: '18px',
              display: 'inline-block',
              verticalAlign: 'top',
            }}
          >
            {state.lineChartData &&
              state.lineChartData[
                `${signalSource}${signalDirection}`
              ].narratives.map((narrative, i) => <li key={i}>{narrative}</li>)}
          </ul>
        </div>
      </div>
    </Card>
  );
};
