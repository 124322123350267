import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
  /**
   * The `Json` scalar type represents arbitrary json string data, represented as UTF-8
   * character sequences. The Json type is most often used to represent a free-form
   * human-readable json string.
   */
  Json: any;
  /**
   * The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
   */
  NaiveDateTime: any;
};

export type ApiAccount = {
  accountName: Maybe<Scalars['String']>;
  addressCity: Maybe<Scalars['String']>;
  addressRegion: Maybe<Scalars['String']>;
  addressStreet: Maybe<Scalars['String']>;
  addressZipCode: Maybe<Scalars['String']>;
  annualRevenue: Maybe<Scalars['String']>;
  contactCount: Maybe<Scalars['Int']>;
  contacts: Maybe<Array<Maybe<ApiContact>>>;
  country: Maybe<Scalars['String']>;
  crmAccountId: Scalars['String'];
  currentAccountScore: Maybe<Scalars['Int']>;
  currentValue: Maybe<Scalars['Float']>;
  customerStatus: Maybe<ApiAccountCustomerStatus>;
  employeeCount: Maybe<Scalars['String']>;
  engagementStatus: Maybe<ApiEngagementStatus>;
  expectedValue: Maybe<ApiPredictionExpected>;
  firstClosedOpportunityAt: Maybe<Scalars['DateTime']>;
  goalValues: Maybe<Array<Maybe<ApiPredictionGoal>>>;
  hidden: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  industry: Maybe<Scalars['String']>;
  lastInboundSignal: Maybe<ApiSignal>;
  lastOutboundSignal: Maybe<ApiSignal>;
  lostOpportunityCount: Maybe<Scalars['Int']>;
  messageCount: Maybe<Scalars['Int']>;
  messages: Maybe<Array<Maybe<ApiMessage>>>;
  narratives: Maybe<ApiNarrative>;
  openOpportunityCount: Maybe<Scalars['Int']>;
  outreachStatus: Maybe<ApiOutreachStatus>;
  predictionCalculatedForName: Maybe<Scalars['String']>;
  predictionCalculatedForType: Maybe<ApiPredictionForType>;
  predictionLevel: Maybe<Scalars['Int']>;
  predictions: Maybe<Array<Maybe<ApiAccountPrediction>>>;
  rankAll: Maybe<Scalars['Int']>;
  rankPriority: Maybe<Scalars['Int']>;
  signalActivityIntensity: Maybe<ApiSignalIntensity>;
  signalResponseIntensity: Maybe<ApiSignalIntensity>;
  wonOpportunityCount: Maybe<Scalars['Int']>;
  xeominAverageQuantity: Maybe<Scalars['Float']>;
  xeominPredictionSummary: Maybe<ApiXeominPredictionSummary>;
  xeominPurchaseTrendLong: Maybe<Scalars['String']>;
  xeominPurchaseTrendShort: Maybe<ApiXeominPurchaseTrend>;
  xeominSpendFraction: Maybe<Scalars['Float']>;
  xeominStalePrediction: Maybe<Scalars['Boolean']>;
};

export type ApiAccountContactsArgs = {
  page: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
  sort: Maybe<Array<Maybe<ApiContactSortItem>>>;
};

export type ApiAccountMessagesArgs = {
  daysAgo: Maybe<Scalars['Int']>;
};

export type ApiAccountPredictionsArgs = {
  current: Maybe<Scalars['Boolean']>;
  filter: Maybe<ApiPredictionFilter>;
};

export enum ApiAccountCountGroupBy {
  CurrentAccountScoreBucket = 'CURRENT_ACCOUNT_SCORE_BUCKET',
  PossibleAccountScoreBucket = 'POSSIBLE_ACCOUNT_SCORE_BUCKET',
  SignalType = 'SIGNAL_TYPE',
}

export type ApiAccountCountGrouping = {
  accountCount: Maybe<Scalars['Int']>;
  currentAccountScoreBucket: Maybe<ApiAccountScoreBucket>;
  possibleAccountScoreBucket: Maybe<ApiAccountScoreBucket>;
  signalType: Maybe<Scalars['String']>;
};

export enum ApiAccountCustomerStatus {
  Customer = 'CUSTOMER',
  PastCustomer = 'PAST_CUSTOMER',
  Prospect = 'PROSPECT',
}

export enum ApiAccountField {
  AccountName = 'ACCOUNT_NAME',
  CustomerStatus = 'CUSTOMER_STATUS',
  EngagementStatus = 'ENGAGEMENT_STATUS',
  MessageCount = 'MESSAGE_COUNT',
  OutreachStatus = 'OUTREACH_STATUS',
  RankPriority = 'RANK_PRIORITY',
  SignalActivityIntensity = 'SIGNAL_ACTIVITY_INTENSITY',
  SignalResponseIntensity = 'SIGNAL_RESPONSE_INTENSITY',
  LastInboundSignalOccurredAt = 'LAST_INBOUND_SIGNAL_OCCURRED_AT',
  LastOutboundSignalOccurredAt = 'LAST_OUTBOUND_SIGNAL_OCCURRED_AT',
  XeominAverageQuantity = 'XEOMIN_AVERAGE_QUANTITY',
  XeominPredictionSummary = 'XEOMIN_PREDICTION_SUMMARY',
  XeominPurchaseTrendLong = 'XEOMIN_PURCHASE_TREND_LONG',
  XeominPurchaseTrendShort = 'XEOMIN_PURCHASE_TREND_SHORT',
  XeominSpendFraction = 'XEOMIN_SPEND_FRACTION',
  ContactCount = 'CONTACT_COUNT',
}

export type ApiAccountForSearchPayload = {
  contactCount: Scalars['Int'];
  crmAccountId: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type ApiAccountGroup = {
  accountCount: Maybe<Scalars['Int']>;
  accounts: Array<Maybe<ApiAccount>>;
  accountsUpdatedAt: Maybe<Scalars['NaiveDateTime']>;
  createdAt: Scalars['NaiveDateTime'];
  description: Maybe<Scalars['String']>;
  filterString: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isHidden: Scalars['Boolean'];
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['NaiveDateTime'];
  user: ApiUser;
};

export type ApiAccountGroupPayload = {
  accountGroup: Maybe<ApiAccountGroup>;
};

export enum ApiAccountGroupType {
  All = 'ALL',
  List = 'LIST',
  Segment = 'SEGMENT',
}

export type ApiAccountPrediction = {
  accountId: Maybe<Scalars['Int']>;
  calculatedAtDate: Maybe<Scalars['Date']>;
  calculatedFor: Maybe<ApiCalculatedFor>;
  currentValue: Maybe<Scalars['Float']>;
  expectedSignalPriorityCount: Maybe<ApiLowMiddleHighInteger>;
  expectedValue: Maybe<ApiLowMiddleHighFloat>;
  id: Maybe<Scalars['Int']>;
  insertedAt: Maybe<Scalars['NaiveDateTime']>;
  level: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['NaiveDateTime']>;
};

export type ApiAccountPredictions = {
  currentPredictions: Maybe<Array<Maybe<ApiCurrentPrediction>>>;
  dateFormat: Scalars['String'];
  expectedPredictions: Maybe<Array<Maybe<ApiExpectedPrediction>>>;
  goalPredictions: Maybe<Array<Maybe<ApiGoalPrediction>>>;
};

export enum ApiAccountScoreBucket {
  Likely = 'LIKELY',
  Tossup = 'TOSSUP',
  Unlikely = 'UNLIKELY',
  VeryLikely = 'VERY_LIKELY',
  VeryUnlikely = 'VERY_UNLIKELY',
}

/** Input object for sorting an account list */
export type ApiAccountSortItem = {
  direction: ApiSort;
  field: ApiAccountField;
};

/** Input object for adding an account group */
export type ApiAddAccountGroupInput = {
  accountIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  description: Maybe<Scalars['String']>;
  filterString: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ApiAddUserError = {
  email: Scalars['String'];
  message: Scalars['String'];
};

export type ApiAddUsersPayload = {
  errors: Array<Maybe<ApiAddUserError>>;
  users: Array<Maybe<ApiUser>>;
};

export type ApiAggregateDataSearchPayload = {
  filteredCount: Scalars['Int'];
  totalCount: Scalars['Int'];
  value: Scalars['String'];
};

export type ApiAggregateMessage = {
  accounts: Maybe<Array<Maybe<ApiAccount>>>;
  body: Scalars['String'];
  count: Scalars['Int'];
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  name: Scalars['String'];
  tags: Maybe<Array<Maybe<ApiMessageTag>>>;
  type: ApiMessageType;
};

export type ApiCalculatedFor = {
  date: Maybe<Scalars['Date']>;
  name: Maybe<Scalars['String']>;
  type: Maybe<ApiPredictionForType>;
};

export type ApiCompany = {
  canSubmitCredentials: Scalars['Boolean'];
  companyName: Scalars['String'];
  crmTypes: Maybe<Array<Maybe<Scalars['String']>>>;
  dataProcessedAt: Maybe<Scalars['NaiveDateTime']>;
  dataSources: Maybe<Array<Maybe<ApiDataSource>>>;
  id: Scalars['Int'];
  insertedAt: Scalars['NaiveDateTime'];
  updatedAt: Scalars['NaiveDateTime'];
};

/** Input object for company */
export type ApiCompanyInput = {
  /** Name of Can Submit Credentials */
  canSubmitCredentials: Maybe<Scalars['Boolean']>;
  /** Name for the company */
  companyName: Scalars['String'];
  /** Name of CRM Types */
  crmTypes: Array<Maybe<Scalars['String']>>;
};

/** Input object for company update */
export type ApiCompanyInputUpdate = {
  /** Name of Can Submit Credentials */
  canSubmitCredentials: Maybe<Scalars['Boolean']>;
  /** Name for the company */
  companyName: Scalars['String'];
  /** Name of CRM Types */
  crmTypes: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApiContact = {
  id: Scalars['Int'];
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  role: Maybe<Scalars['String']>;
  insertedAt: Scalars['NaiveDateTime'];
  updatedAt: Scalars['NaiveDateTime'];
  contactSources: Maybe<Array<Maybe<ApiContactSource>>>;
  accounts: Maybe<Array<Maybe<ApiAccount>>>;
};

export enum ApiContactField {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Title = 'TITLE',
}

/** Input object for sorting a contact list */
export type ApiContactSortItem = {
  field: ApiContactField;
  direction: ApiSort;
};

export type ApiContactSource = {
  contactId: Scalars['Int'];
  sourceRecordId: Scalars['String'];
  crmCreatedAt: Maybe<Scalars['NaiveDateTime']>;
  crmAccountId: Maybe<Scalars['String']>;
  accountId: Maybe<Scalars['Int']>;
  insertedAt: Scalars['NaiveDateTime'];
  updatedAt: Scalars['NaiveDateTime'];
  source: ApiSource;
};

/** Return object after creating a data source */
export type ApiCreateDataSourcePayload = {
  sourceId: Maybe<Scalars['String']>;
};

/** CRM Type Enum */
export enum ApiCrmTypeEnum {
  Hubspot = 'HUBSPOT',
  Marketo = 'MARKETO',
  Pardot = 'PARDOT',
  Pendo = 'PENDO',
  Salesforce = 'SALESFORCE',
  Sftp = 'SFTP',
}

export type ApiCurrentPrediction = {
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  value: Scalars['Float'];
};

export type ApiDataReport = {
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  queryText: Maybe<Scalars['String']>;
  data: Maybe<ApiDataReportData>;
};

export type ApiDataReportData = {
  columns: Maybe<Array<Maybe<Scalars['String']>>>;
  rows: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};

export type ApiDataSource = {
  company: Maybe<ApiCompany>;
  companyId: Maybe<Scalars['Int']>;
  createdByUserId: Maybe<Scalars['Int']>;
  database: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  insertedAt: Maybe<Scalars['NaiveDateTime']>;
  portalDomain: Maybe<Scalars['String']>;
  portalId: Maybe<Scalars['String']>;
  prefix: Maybe<Scalars['String']>;
  source: Maybe<ApiCrmTypeEnum>;
  stitchId: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['NaiveDateTime']>;
};

/** Input object for creating a data source */
export type ApiDataSourceConfigInput = {
  companyName: Maybe<Scalars['String']>;
  dataSource: ApiCrmTypeEnum;
  hubspotConfig: Maybe<ApiHubspotInput>;
  marketoConfig: Maybe<ApiMarketoInput>;
  pardotConfig: Maybe<ApiPardotInput>;
  pendoConfig: Maybe<ApiPendoInput>;
  salesforceConfig: Maybe<ApiSalesforceInput>;
};

export enum ApiEngagementStatus {
  Disengaged = 'DISENGAGED',
  Disengaging = 'DISENGAGING',
  Engaged = 'ENGAGED',
  Unengaged = 'UNENGAGED',
}

export type ApiExpectedPrediction = {
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  valueHigh: Maybe<Scalars['Float']>;
  valueLow: Maybe<Scalars['Float']>;
  valueMiddle: Maybe<Scalars['Float']>;
};

export type ApiFilterConfigItem = {
  aggregateType: Maybe<Scalars['String']>;
  enum: Maybe<Scalars['Boolean']>;
  field: Maybe<Scalars['String']>;
  fields: Maybe<Scalars['Json']>;
  key: Scalars['String'];
  ranges: Maybe<Array<Maybe<Scalars['Float']>>>;
  table: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ApiGoalPrediction = {
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  isPriority: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['Float']>;
};

export type ApiGoogleSecret = {
  message: Scalars['String'];
};

/** Input object for google secrets */
export type ApiGoogleSecretInput = {
  /** Company for the credentials */
  companyName: Scalars['String'];
  /** CRM contact person's email */
  contactPersonEmail: Maybe<Scalars['String']>;
  /** CRM contact person's name */
  contactPersonName: Maybe<Scalars['String']>;
  /** CRM Type */
  crmType: ApiCrmTypeEnum;
  /** CRM user's email */
  email: Scalars['String'];
  /** Marketo Client ID */
  marketoClientId: Maybe<Scalars['String']>;
  /** Marketo Client Secret */
  marketoClientSecret: Maybe<Scalars['String']>;
  /** Marketo Endpoint Base URL */
  marketoEndpointBaseUrl: Maybe<Scalars['String']>;
  /** CRM user's password */
  password: Scalars['String'];
};

/** Input object for marking a list of objects as hidden */
export type ApiHideObjectsInput = {
  companyName: Scalars['String'];
  hidden: Maybe<Scalars['Boolean']>;
  ids: Maybe<Array<Maybe<Scalars['Int']>>>;
  objectType: Scalars['String'];
};

export type ApiHideObjectsPayload = {
  companyName: Maybe<Scalars['String']>;
  count: Maybe<Scalars['Int']>;
  hidden: Maybe<Scalars['Boolean']>;
  objectType: Maybe<Scalars['String']>;
};

export type ApiHubspotInput = {
  oauthCode: Scalars['String'];
};

export type ApiIndustry = {
  name: Scalars['String'];
};

/** Enqueue object for internal ETL */
export type ApiInternalEtlInput = {
  /** The company to do internal ETL on */
  company: Scalars['String'];
  /** The earliest date to get data for. Format YYYY-0M-0D */
  dateFrom: Scalars['String'];
  /** The tables (optional) to do internal ETL for */
  tables: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApiLowMiddleHighFloat = {
  high: Maybe<Scalars['Float']>;
  low: Maybe<Scalars['Float']>;
  middle: Maybe<Scalars['Float']>;
};

export type ApiLowMiddleHighInteger = {
  high: Maybe<Scalars['Int']>;
  low: Maybe<Scalars['Int']>;
  middle: Maybe<Scalars['Int']>;
};

export type ApiMarketoInput = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  endpoint: Scalars['String'];
  identity: Scalars['String'];
};

export type ApiMessage = {
  accountId: Scalars['Int'];
  body: Scalars['String'];
  date: Scalars['Date'];
  id: Scalars['Int'];
  name: Scalars['String'];
  occurredAt: Scalars['NaiveDateTime'];
  tags: Maybe<Array<Maybe<ApiMessageTag>>>;
  type: ApiMessageType;
};

export enum ApiMessageTag {
  Alert = 'ALERT',
  Change = 'CHANGE',
  Decrease = 'DECREASE',
  Engagement = 'ENGAGEMENT',
  Increase = 'INCREASE',
  Intensity = 'INTENSITY',
  Outreach = 'OUTREACH',
  Pause = 'PAUSE',
  Pursue = 'PURSUE',
  Status = 'STATUS',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export enum ApiMessageType {
  Insight = 'INSIGHT',
}

/** Schedule object for ML prediction */
export type ApiMlPredictionInput = {
  /** Optional account ids to limit to */
  accountIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The company to do ML prediction on */
  company: Scalars['String'];
  /** The start date for ML prediction */
  dateFrom: Scalars['String'];
  /** The end date for ML prediction */
  dateTo: Scalars['String'];
};

export type ApiNarrative = {
  primaryAction: Scalars['String'];
  xeominPrediction: Maybe<Array<Maybe<Scalars['String']>>>;
  xeominVolume: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApiOauthToken = {
  expiresAt: Maybe<Scalars['NaiveDateTime']>;
  expiresIn: Maybe<Scalars['Int']>;
  token: Scalars['String'];
  tokenType: Scalars['String'];
};

export type ApiObanJob = {
  args: Scalars['String'];
  attempt: Scalars['Int'];
  attemptedAt: Scalars['DateTime'];
  breakdown: Maybe<Scalars['String']>;
  completedAt: Scalars['DateTime'];
  errors: Scalars['String'];
  executionTimeMs: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  insertedAt: Scalars['DateTime'];
  rows: Maybe<Scalars['Int']>;
  scheduledAt: Scalars['DateTime'];
  state: Scalars['String'];
  worker: Scalars['String'];
};

export enum ApiOutreachStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Deactivating = 'DEACTIVATING',
  Inactivated = 'INACTIVATED',
}

export type ApiPageInfo = {
  totalEntries: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type ApiPaginatedAccounts = {
  accounts: Array<Maybe<ApiAccount>>;
  pageInfo: ApiPageInfo;
};

export type ApiPardotInput = {
  oauthCode: Scalars['String'];
  pardotBusinessUnitId: Scalars['String'];
};

export type ApiPendoInput = {
  integrationKey: Scalars['String'];
};

/** Enqueue object for entire ETL pipeline */
export type ApiPipelineInput = {
  /** The company to do internal ETL on */
  company: Scalars['String'];
  /** The upper bound date. Format YYYY-0M-0D. Defaults to today if not provided */
  dateFrom: Maybe<Scalars['String']>;
  /** The lower bound date. Format YYYY-0M-0D. Defaults to today if not provided */
  dateTo: Maybe<Scalars['String']>;
};

export type ApiPredictionExpected = {
  high: Maybe<Scalars['Float']>;
  low: Maybe<Scalars['Float']>;
  middle: Maybe<Scalars['Float']>;
};

export type ApiPredictionFilter = {
  /** Most recent predictions calculated before a given date */
  calculatedAtBefore: Maybe<Scalars['Date']>;
  /** Filter by a list of names */
  calculatedForName: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Filter by a list of types */
  calculatedForType: Maybe<Array<Maybe<ApiPredictionForType>>>;
};

export enum ApiPredictionForType {
  Acquisition = 'ACQUISITION',
  Fit = 'FIT',
  Retention = 'RETENTION',
  Volume = 'VOLUME',
}

export type ApiPredictionGoal = {
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  isPriority: Scalars['Boolean'];
  lift: Maybe<Scalars['Float']>;
  signalPredictions: Maybe<Array<Maybe<ApiSignalPrediction>>>;
  value: Maybe<Scalars['Float']>;
};

export type ApiRecommendedAction = {
  accountCount: Scalars['Int'];
  action: Scalars['String'];
  actualCount: Scalars['Int'];
  averageRank: Scalars['Float'];
  goalCount: Scalars['Int'];
  signalType: Scalars['String'];
};

export type ApiRootMutationType = {
  addAccountGroup: Maybe<ApiAccountGroupPayload>;
  addCompany: Maybe<ApiCompany>;
  addUser: Maybe<ApiUser>;
  addUsers: Maybe<ApiAddUsersPayload>;
  createDataSource: Maybe<ApiCreateDataSourcePayload>;
  createGoogleSecretVersion: Maybe<ApiGoogleSecret>;
  enqueueEtlPipeline: Maybe<ApiObanJob>;
  enqueueInternalEtl: Maybe<ApiObanJob>;
  enqueueMlPrediction: Maybe<ApiObanJob>;
  hideObjects: Maybe<ApiHideObjectsPayload>;
  updateAccountGroupAccounts: Maybe<ApiAccountGroupPayload>;
  updateAccountGroupDescriptors: Maybe<ApiAccountGroupPayload>;
  updateAccountGroupFilter: Maybe<ApiAccountGroupPayload>;
  updateAccountGroupIsHidden: Maybe<ApiAccountGroupPayload>;
  updateCompany: Maybe<ApiCompany>;
  updateUser: Maybe<ApiUser>;
  updateUserCompany: Maybe<ApiUser>;
};

export type ApiRootMutationTypeAddAccountGroupArgs = {
  input: ApiAddAccountGroupInput;
};

export type ApiRootMutationTypeAddCompanyArgs = {
  company: ApiCompanyInput;
};

export type ApiRootMutationTypeAddUserArgs = {
  user: ApiUserInput;
};

export type ApiRootMutationTypeAddUsersArgs = {
  users: Array<Maybe<ApiUserInput>>;
};

export type ApiRootMutationTypeCreateDataSourceArgs = {
  dataSourceConfig: ApiDataSourceConfigInput;
};

export type ApiRootMutationTypeCreateGoogleSecretVersionArgs = {
  googleSecret: ApiGoogleSecretInput;
};

export type ApiRootMutationTypeEnqueueEtlPipelineArgs = {
  pipelineInput: ApiPipelineInput;
};

export type ApiRootMutationTypeEnqueueInternalEtlArgs = {
  internalEtlInput: ApiInternalEtlInput;
};

export type ApiRootMutationTypeEnqueueMlPredictionArgs = {
  mlPredictionInput: ApiMlPredictionInput;
};

export type ApiRootMutationTypeHideObjectsArgs = {
  input: ApiHideObjectsInput;
};

export type ApiRootMutationTypeUpdateAccountGroupAccountsArgs = {
  input: ApiUpdateAccountGroupAccountsInput;
};

export type ApiRootMutationTypeUpdateAccountGroupDescriptorsArgs = {
  input: ApiUpdateAccountGroupDescriptorsInput;
};

export type ApiRootMutationTypeUpdateAccountGroupFilterArgs = {
  input: ApiUpdateAccountGroupFilterInput;
};

export type ApiRootMutationTypeUpdateAccountGroupIsHiddenArgs = {
  input: ApiUpdateAccountGroupIsHiddenInput;
};

export type ApiRootMutationTypeUpdateCompanyArgs = {
  company: ApiCompanyInputUpdate;
};

export type ApiRootMutationTypeUpdateUserArgs = {
  user: ApiUserInputUpdate;
};

export type ApiRootMutationTypeUpdateUserCompanyArgs = {
  companyName: Scalars['String'];
};

export type ApiRootQueryType = {
  /** Account & prediction data for a single account */
  account: Maybe<ApiAccount>;
  /** The account count given the set of group by fields */
  accountCountByGrouping: Maybe<Array<Maybe<ApiAccountCountGrouping>>>;
  /** A single account group by id */
  accountGroup: Maybe<ApiAccountGroup>;
  /** All account groups for a company */
  accountGroups: Maybe<Array<Maybe<ApiAccountGroup>>>;
  /** Account groups for the given account */
  accountGroupsForAccount: Maybe<Array<Maybe<ApiAccountGroup>>>;
  /** Predictions for a given account and a given timeline */
  accountPredictions: Maybe<ApiAccountPredictions>;
  /** Returns all signals for the given account id for a given start and end date */
  accountSignals: Maybe<Array<Maybe<ApiSignal>>>;
  /** Multiple accounts without prediction data */
  accounts: Maybe<ApiPaginatedAccounts>;
  /** Accounts returned when searching for one */
  accountsForSearch: Array<Maybe<ApiAccountForSearchPayload>>;
  /** Multiple accounts with prediction data */
  accountsWithPredictions: Maybe<Array<Maybe<ApiAccount>>>;
  /** Aggregate data */
  aggregateData: Maybe<Scalars['Json']>;
  /** Aggregate data results for a single field */
  aggregateDataForField: Array<Maybe<ApiAggregateDataSearchPayload>>;
  /** Aggregate data results returned from search */
  aggregateDataSearch: Array<Maybe<ApiAggregateDataSearchPayload>>;
  aggregateMessage: Maybe<ApiAggregateMessage>;
  aggregateMessages: Maybe<Array<Maybe<ApiAggregateMessage>>>;
  /** Companies aka Klearly's customers */
  companies: Maybe<Array<Maybe<ApiCompany>>>;
  /** Users who can log into Klearly */
  companyUsers: Maybe<Array<Maybe<ApiUser>>>;
  /** Get a single contact */
  contact: Maybe<ApiContact>;
  /** List multiple contacts */
  contacts: Maybe<Array<Maybe<ApiContact>>>;
  /** Contacts returned when searching for one */
  contactsForSearch: Array<Maybe<ApiContact>>;
  dataAssessment: Maybe<Array<Maybe<ApiDataReport>>>;
  dataSources: Maybe<Array<Maybe<ApiDataSource>>>;
  /** Filter configuration details for the company of the logged in user */
  filterConfig: Maybe<Array<Maybe<ApiFilterConfigItem>>>;
  /** Client-only field that contains the current filter string to apply */
  filterString: Maybe<Scalars['String']>;
  /** Client-only field that contains the current filter string without engagement status */
  filterStringWithoutEngagementStatus: Maybe<Scalars['String']>;
  /** All industries for the current user's company */
  industries: Maybe<Array<Maybe<ApiIndustry>>>;
  /** Information about logged in user */
  me: Maybe<ApiUser>;
  /** Returns all signals for a list of given account ids for a given start and end date */
  multiAccountSignals: Maybe<Array<Maybe<ApiSignal>>>;
  oauthToken: Maybe<ApiOauthToken>;
  /** List of recommended actions and corresponding signal actuals */
  recommendedActions: Maybe<Array<Maybe<ApiRecommendedAction>>>;
  /** Returns signal history for the given dates grouped by source and direction */
  signalHistory: Maybe<ApiSignalHistory>;
  /** Returns signal history for the given account id, start and dates, and grouping parameters. By default the results are grouped by signal date, source, and direction */
  signalHistoryForAccount: Maybe<ApiSignalHistoryForAccount>;
};

export type ApiRootQueryTypeAccountArgs = {
  id: Scalars['Int'];
};

export type ApiRootQueryTypeAccountCountByGroupingArgs = {
  filterString: Maybe<Scalars['String']>;
  groupBy: Maybe<Array<Maybe<ApiAccountCountGroupBy>>>;
};

export type ApiRootQueryTypeAccountGroupArgs = {
  id: Scalars['Int'];
};

export type ApiRootQueryTypeAccountGroupsArgs = {
  filterHidden: Maybe<Scalars['Boolean']>;
};

export type ApiRootQueryTypeAccountGroupsForAccountArgs = {
  accountGroupType: Maybe<ApiAccountGroupType>;
  accountId: Scalars['Int'];
};

export type ApiRootQueryTypeAccountPredictionsArgs = {
  accountId: Scalars['Int'];
  timeRange: ApiTimeRange;
};

export type ApiRootQueryTypeAccountSignalsArgs = {
  accountId: Scalars['Int'];
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type ApiRootQueryTypeAccountsArgs = {
  filterString: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  messageCountDaysAgo: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  sort: Maybe<Array<Maybe<ApiAccountSortItem>>>;
  filterStringAlternate: Maybe<Scalars['String']>;
  productName: Maybe<Scalars['String']>;
};

export type ApiRootQueryTypeAccountsForSearchArgs = {
  limitToOpenAccountsWithPredictions: Maybe<Scalars['Boolean']>;
  query: Scalars['String'];
};

export type ApiRootQueryTypeAccountsWithPredictionsArgs = {
  filterString: Maybe<Scalars['String']>;
};

export type ApiRootQueryTypeAggregateDataArgs = {
  filterString: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ApiRootQueryTypeAggregateDataForFieldArgs = {
  field: Scalars['String'];
  filterString: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ApiRootQueryTypeAggregateDataSearchArgs = {
  field: Scalars['String'];
  filterString: Maybe<Scalars['String']>;
  query: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type ApiRootQueryTypeAggregateMessageArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Maybe<Scalars['Date']>;
  name: Scalars['String'];
};

export type ApiRootQueryTypeAggregateMessagesArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Maybe<Scalars['Date']>;
  interval: Maybe<Scalars['Int']>;
};

export type ApiRootQueryTypeContactArgs = {
  id: Scalars['Int'];
};

export type ApiRootQueryTypeContactsArgs = {
  page: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
  sort: Maybe<Array<Maybe<ApiContactSortItem>>>;
  filterString: Maybe<Scalars['String']>;
  accountId: Maybe<Scalars['Int']>;
};

export type ApiRootQueryTypeContactsForSearchArgs = {
  query: Scalars['String'];
};

export type ApiRootQueryTypeDataAssessmentArgs = {
  company: Maybe<Scalars['String']>;
};

export type ApiRootQueryTypeFilterConfigArgs = {
  type: Maybe<Scalars['String']>;
};

export type ApiRootQueryTypeMultiAccountSignalsArgs = {
  accountIds: Array<Maybe<Scalars['Int']>>;
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type ApiRootQueryTypeOauthTokenArgs = {
  companyName: Scalars['String'];
  dataSource: ApiCrmTypeEnum;
};

export type ApiRootQueryTypeRecommendedActionsArgs = {
  filterString: Maybe<Scalars['String']>;
};

export type ApiRootQueryTypeSignalHistoryArgs = {
  filterString: Maybe<Scalars['String']>;
  timeRange: ApiTimeRange;
};

export type ApiRootQueryTypeSignalHistoryForAccountArgs = {
  accountId: Scalars['Int'];
  endDate: Scalars['Date'];
  groupBy: Array<Maybe<ApiSignalGroupByType>>;
  startDate: Scalars['Date'];
};

export type ApiSalesforceInput = {
  oauthCode: Scalars['String'];
};

export type ApiSignal = {
  accountId: Scalars['Int'];
  action: Scalars['String'];
  crmAccountId: Scalars['String'];
  direction: Scalars['String'];
  groupKey: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  insertedAt: Scalars['DateTime'];
  occurredAt: Maybe<Scalars['DateTime']>;
  quantity: Maybe<Scalars['Float']>;
  source: Scalars['String'];
  topic: Maybe<Scalars['String']>;
  topicDetail: Maybe<Scalars['String']>;
  type: Scalars['String'];
  typeDetail: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  value: Maybe<Scalars['Float']>;
};

export type ApiSignalDetailsForAccountItem = {
  count: Scalars['Int'];
  signalDate: Maybe<Scalars['Date']>;
  signalSource: Maybe<Scalars['String']>;
  signalTopic: Maybe<Scalars['String']>;
  signalTopicDetail: Maybe<Scalars['String']>;
  signalType: Maybe<Scalars['String']>;
  signalTypeDetail: Maybe<Scalars['String']>;
};

export enum ApiSignalGroupByType {
  SignalTopic = 'SIGNAL_TOPIC',
  SignalTopicDetail = 'SIGNAL_TOPIC_DETAIL',
  SignalType = 'SIGNAL_TYPE',
  SignalTypeDetail = 'SIGNAL_TYPE_DETAIL',
}

export type ApiSignalHistory = {
  allActivity: ApiSignalHistoryCategory;
  allAll: ApiSignalHistoryCategory;
  allResponse: ApiSignalHistoryCategory;
  dateFormat: Scalars['String'];
  marketingActivity: ApiSignalHistoryCategory;
  marketingAll: ApiSignalHistoryCategory;
  marketingResponse: ApiSignalHistoryCategory;
  salesActivity: ApiSignalHistoryCategory;
  salesAll: ApiSignalHistoryCategory;
  salesResponse: ApiSignalHistoryCategory;
};

export type ApiSignalHistoryCategory = {
  narratives: Array<Maybe<Scalars['String']>>;
  signalHistoryItems: Array<Maybe<ApiSignalHistoryItem>>;
};

export type ApiSignalHistoryForAccount = {
  allActivity: ApiSignalHistoryForAccountItem;
  allAll: ApiSignalHistoryForAccountItem;
  allResponse: ApiSignalHistoryForAccountItem;
  dateFormat: Scalars['String'];
  marketingActivity: ApiSignalHistoryForAccountItem;
  marketingAll: ApiSignalHistoryForAccountItem;
  marketingResponse: ApiSignalHistoryForAccountItem;
  salesActivity: ApiSignalHistoryForAccountItem;
  salesAll: ApiSignalHistoryForAccountItem;
  salesResponse: ApiSignalHistoryForAccountItem;
};

export type ApiSignalHistoryForAccountItem = {
  signalDetails: Array<Maybe<ApiSignalDetailsForAccountItem>>;
  signalTotalByDate: Array<Maybe<ApiSignalTotalForAccountByDate>>;
};

export type ApiSignalHistoryItem = {
  accountCount: Scalars['Int'];
  endDate: Scalars['Date'];
  signalCount: Scalars['Int'];
  startDate: Scalars['Date'];
};

export enum ApiSignalIntensity {
  AboveAverage = 'ABOVE_AVERAGE',
  Average = 'AVERAGE',
  BelowAverage = 'BELOW_AVERAGE',
  None = 'NONE',
  WellAboveAverage = 'WELL_ABOVE_AVERAGE',
  WellBelowAverage = 'WELL_BELOW_AVERAGE',
}

export type ApiSignalPrediction = {
  calculatedAtDate: Scalars['Date'];
  calculatedForDate: Scalars['Date'];
  expectedHigh: Maybe<Scalars['Int']>;
  expectedLow: Maybe<Scalars['Int']>;
  expectedMiddle: Maybe<Scalars['Int']>;
  goal: Maybe<Scalars['Int']>;
  recommendation: Maybe<Scalars['String']>;
  signalType: Maybe<Scalars['String']>;
};

export type ApiSignalTotalForAccountByDate = {
  date: Scalars['Date'];
  total: Scalars['Int'];
};

export enum ApiSort {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ApiSource = {
  id: Scalars['Int'];
  database: Scalars['String'];
  prefix: Scalars['String'];
  table: Scalars['String'];
  column: Scalars['String'];
  dataSource: Scalars['String'];
  uniqueKeyFields: Maybe<Array<Maybe<Scalars['String']>>>;
  insertedAt: Scalars['NaiveDateTime'];
  updatedAt: Scalars['NaiveDateTime'];
};

export type ApiTimeRange = {
  daysFuture: Scalars['Int'];
  daysPast: Scalars['Int'];
  intervalDays: Scalars['Int'];
};

/** Input object for updating the accounts associated to the account group */
export type ApiUpdateAccountGroupAccountsInput = {
  /** The associated account ids */
  accountIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The account group id */
  id: Scalars['Int'];
};

/** Input object for updating the account group's title and description */
export type ApiUpdateAccountGroupDescriptorsInput = {
  /** Description of the account group */
  description: Scalars['String'];
  /** The account group id */
  id: Scalars['Int'];
  /** Title of the account group */
  title: Scalars['String'];
};

/** Input object for updating an account group filter and the corresponding accounts */
export type ApiUpdateAccountGroupFilterInput = {
  /** The associated account ids */
  accountIds: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The filter string */
  filterString: Scalars['String'];
  /** The account group id */
  id: Scalars['Int'];
};

/** Input object for updating the account group's is_hidden field */
export type ApiUpdateAccountGroupIsHiddenInput = {
  /** The account group id */
  id: Scalars['Int'];
  /** Value of is hidden */
  isHidden: Scalars['Boolean'];
};

export type ApiUser = {
  authUserId: Scalars['String'];
  company: Maybe<ApiCompany>;
  companyName: Scalars['String'];
  disabled: Scalars['Boolean'];
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isAdmin: Maybe<Scalars['Boolean']>;
  isKlearlyAdmin: Maybe<Scalars['Boolean']>;
  lastName: Maybe<Scalars['String']>;
  role: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

/** Input object for user */
export type ApiUserInput = {
  /** Company name for the company the user is associated to */
  companyName: Scalars['String'];
  /** If the user disabled */
  disabled: Maybe<Scalars['Boolean']>;
  /** Email of the user */
  email: Scalars['String'];
  /** First name of the user */
  firstName: Scalars['String'];
  /** If the user is an admin */
  isAdmin: Maybe<Scalars['Boolean']>;
  /** If the user is a Klearly admin */
  isKlearlyAdmin: Maybe<Scalars['Boolean']>;
  /** Last name of the user */
  lastName: Scalars['String'];
  /** Role of the user */
  role: Maybe<Scalars['String']>;
  /** Send email */
  sendEmail: Maybe<Scalars['Boolean']>;
};

/** Input object for user update */
export type ApiUserInputUpdate = {
  /** Company name for the company the user is associated to */
  companyName: Scalars['String'];
  /** If the user disabled */
  disabled: Maybe<Scalars['Boolean']>;
  /** Email of the user */
  email: Scalars['String'];
  /** First name of the user */
  firstName: Maybe<Scalars['String']>;
  /** If the user is an admin */
  isAdmin: Maybe<Scalars['Boolean']>;
  /** If the user is a Klearly admin */
  isKlearlyAdmin: Maybe<Scalars['Boolean']>;
  /** Last name of the user */
  lastName: Maybe<Scalars['String']>;
  /** Role of the user */
  role: Maybe<Scalars['String']>;
};

export enum ApiXeominPredictionSummary {
  HighCrossSell = 'HIGH_CROSS_SELL',
  HighProspect = 'HIGH_PROSPECT',
  LikelyReorder = 'LIKELY_REORDER',
  LowCrossSell = 'LOW_CROSS_SELL',
  LowProspect = 'LOW_PROSPECT',
  MediumCrossSell = 'MEDIUM_CROSS_SELL',
  MediumProspect = 'MEDIUM_PROSPECT',
  Retain = 'RETAIN',
  SomewhatLikelyReorder = 'SOMEWHAT_LIKELY_REORDER',
  UnlikelyReorder = 'UNLIKELY_REORDER',
  UpSell = 'UP_SELL',
}

export enum ApiXeominPurchaseTrend {
  FirstRecentSale = 'FIRST_RECENT_SALE',
  NeverPurchased = 'NEVER_PURCHASED',
  NewAccount = 'NEW_ACCOUNT',
  NoRecentSale = 'NO_RECENT_SALE',
  Steady = 'STEADY',
  TrendingDown = 'TRENDING_DOWN',
  TrendingFlat = 'TRENDING_FLAT',
  TrendingUp = 'TRENDING_UP',
}

export type ApiAddAccountGroupMutationVariables = Exact<{
  input: ApiAddAccountGroupInput;
}>;

export type ApiAddAccountGroupMutation = {
  addAccountGroup: Maybe<{ accountGroup: Maybe<Pick<ApiAccountGroup, 'id'>> }>;
};

export type ApiCreateDataSourceConfigMutationVariables = Exact<{
  dataSourceConfig: ApiDataSourceConfigInput;
}>;

export type ApiCreateDataSourceConfigMutation = {
  createDataSource: Maybe<Pick<ApiCreateDataSourcePayload, 'sourceId'>>;
};

export type ApiCreateGoogleSecretVersionMutationVariables = Exact<{
  googleSecret: ApiGoogleSecretInput;
}>;

export type ApiCreateGoogleSecretVersionMutation = {
  createGoogleSecretVersion: Maybe<Pick<ApiGoogleSecret, 'message'>>;
};

export type ApiCreateNewCompanyMutationVariables = Exact<{
  company: ApiCompanyInput;
}>;

export type ApiCreateNewCompanyMutation = {
  addCompany: Maybe<
    Pick<
      ApiCompany,
      'id' | 'companyName' | 'canSubmitCredentials' | 'insertedAt' | 'updatedAt'
    > & { CRMTypes: ApiCompany['crmTypes'] }
  >;
};

export type ApiCreateNewUserMutationVariables = Exact<{
  user: ApiUserInput;
}>;

export type ApiCreateNewUserMutation = {
  addUser: Maybe<Pick<ApiUser, 'email' | 'firstName' | 'lastName'>>;
};

export type ApiCreateNewUsersMutationVariables = Exact<{
  users: Array<Maybe<ApiUserInput>> | Maybe<ApiUserInput>;
}>;

export type ApiCreateNewUsersMutation = {
  addUsers: Maybe<{
    users: Array<
      Maybe<Pick<ApiUser, 'firstName' | 'lastName' | 'email' | 'companyName'>>
    >;
    errors: Array<Maybe<Pick<ApiAddUserError, 'email' | 'message'>>>;
  }>;
};

export type ApiUpdateAccountGroupAccountsMutationVariables = Exact<{
  input: ApiUpdateAccountGroupAccountsInput;
}>;

export type ApiUpdateAccountGroupAccountsMutation = {
  updateAccountGroupAccounts: Maybe<{
    accountGroup: Maybe<Pick<ApiAccountGroup, 'title' | 'id'>>;
  }>;
};

export type ApiUpdateAccountGroupDescriptorsMutationVariables = Exact<{
  input: ApiUpdateAccountGroupDescriptorsInput;
}>;

export type ApiUpdateAccountGroupDescriptorsMutation = {
  updateAccountGroupDescriptors: Maybe<{
    accountGroup: Maybe<Pick<ApiAccountGroup, 'title' | 'id'>>;
  }>;
};

export type ApiUpdateAccountGroupIsHiddenMutationVariables = Exact<{
  input: ApiUpdateAccountGroupIsHiddenInput;
}>;

export type ApiUpdateAccountGroupIsHiddenMutation = {
  updateAccountGroupIsHidden: Maybe<{
    accountGroup: Maybe<Pick<ApiAccountGroup, 'title' | 'id'>>;
  }>;
};

export type ApiUpdateCompanyMutationVariables = Exact<{
  company: ApiCompanyInputUpdate;
}>;

export type ApiUpdateCompanyMutation = {
  updateCompany: Maybe<
    Pick<ApiCompany, 'companyName' | 'canSubmitCredentials'> & {
      CRMTypes: ApiCompany['crmTypes'];
    }
  >;
};

export type ApiUpdateCurrentUserCompanyMutationVariables = Exact<{
  companyName: Scalars['String'];
}>;

export type ApiUpdateCurrentUserCompanyMutation = {
  updateUserCompany: Maybe<Pick<ApiUser, 'companyName'>>;
};

export type ApiUpdateUserMutationVariables = Exact<{
  user: ApiUserInputUpdate;
}>;

export type ApiUpdateUserMutation = {
  updateUser: Maybe<
    Pick<
      ApiUser,
      | 'companyName'
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'isAdmin'
      | 'disabled'
    >
  >;
};

export type ApiGetAccountQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ApiGetAccountQuery = {
  account: Maybe<
    Pick<
      ApiAccount,
      | 'id'
      | 'accountName'
      | 'addressCity'
      | 'addressRegion'
      | 'addressStreet'
      | 'addressZipCode'
      | 'annualRevenue'
      | 'contactCount'
      | 'crmAccountId'
      | 'currentValue'
      | 'customerStatus'
      | 'employeeCount'
      | 'engagementStatus'
      | 'firstClosedOpportunityAt'
      | 'industry'
      | 'lostOpportunityCount'
      | 'openOpportunityCount'
      | 'outreachStatus'
      | 'predictionCalculatedForName'
      | 'predictionCalculatedForType'
      | 'predictionLevel'
      | 'rankAll'
      | 'rankPriority'
      | 'signalActivityIntensity'
      | 'signalResponseIntensity'
      | 'wonOpportunityCount'
      | 'xeominPredictionSummary'
      | 'xeominStalePrediction'
    > & {
      expectedValue: Maybe<
        Pick<ApiPredictionExpected, 'low' | 'middle' | 'high'>
      >;
      goalValues: Maybe<
        Array<
          Maybe<
            Pick<
              ApiPredictionGoal,
              'calculatedAt' | 'calculatedFor' | 'isPriority' | 'lift' | 'value'
            > & {
              signalPredictions: Maybe<
                Array<
                  Maybe<
                    Pick<
                      ApiSignalPrediction,
                      'expectedLow' | 'expectedHigh' | 'goal' | 'recommendation'
                    > & { signal_type: ApiSignalPrediction['signalType'] }
                  >
                >
              >;
            }
          >
        >
      >;
      lastInboundSignal: Maybe<
        Pick<ApiSignal, 'action' | 'occurredAt' | 'type'>
      >;
      lastOutboundSignal: Maybe<
        Pick<ApiSignal, 'action' | 'occurredAt' | 'type'>
      >;
      narratives: Maybe<
        Pick<
          ApiNarrative,
          'primaryAction' | 'xeominPrediction' | 'xeominVolume'
        >
      >;
    }
  >;
};

export type ApiGetAccountMessagesQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ApiGetAccountMessagesQuery = {
  account: Maybe<{
    messages: Maybe<
      Array<Maybe<Pick<ApiMessage, 'body' | 'occurredAt' | 'type'>>>
    >;
  }>;
};

export type ApiGetAccountsListQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<ApiAccountSortItem>> | Maybe<ApiAccountSortItem>>;
  messageCountDaysAgo: Maybe<Scalars['Int']>;
  filterStringAlternate: Maybe<Scalars['String']>;
  productName: Maybe<Scalars['String']>;
}>;

export type ApiGetAccountsListQuery = Pick<ApiRootQueryType, 'filterString'> & {
  accounts: Maybe<{
    accounts: Array<
      Maybe<
        Pick<
          ApiAccount,
          | 'id'
          | 'crmAccountId'
          | 'accountName'
          | 'addressStreet'
          | 'addressCity'
          | 'addressRegion'
          | 'addressZipCode'
          | 'industry'
          | 'employeeCount'
          | 'annualRevenue'
          | 'customerStatus'
          | 'signalActivityIntensity'
          | 'signalResponseIntensity'
          | 'engagementStatus'
          | 'outreachStatus'
          | 'xeominAverageQuantity'
          | 'xeominPredictionSummary'
          | 'xeominPurchaseTrendShort'
          | 'xeominSpendFraction'
          | 'rankPriority'
          | 'messageCount'
        > & {
          lastInboundSignal: Maybe<Pick<ApiSignal, 'occurredAt'>>;
          lastOutboundSignal: Maybe<Pick<ApiSignal, 'occurredAt'>>;
        }
      >
    >;
    pageInfo: Pick<ApiPageInfo, 'totalEntries' | 'totalPages'>;
  }>;
};

export type ApiGetAccountsListWithFilterVarQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<ApiAccountSortItem>> | Maybe<ApiAccountSortItem>>;
  messageCountDaysAgo: Maybe<Scalars['Int']>;
  filterStringAlternate: Maybe<Scalars['String']>;
  productName: Maybe<Scalars['String']>;
}>;

export type ApiGetAccountsListWithFilterVarQuery = {
  accounts: Maybe<{
    accounts: Array<
      Maybe<
        Pick<
          ApiAccount,
          | 'id'
          | 'crmAccountId'
          | 'country'
          | 'accountName'
          | 'addressStreet'
          | 'addressCity'
          | 'addressRegion'
          | 'addressZipCode'
          | 'annualRevenue'
          | 'customerStatus'
          | 'employeeCount'
          | 'industry'
          | 'rankPriority'
          | 'signalActivityIntensity'
          | 'signalResponseIntensity'
          | 'outreachStatus'
          | 'engagementStatus'
          | 'xeominAverageQuantity'
          | 'xeominPredictionSummary'
          | 'xeominPurchaseTrendShort'
          | 'xeominSpendFraction'
        >
      >
    >;
    pageInfo: Pick<ApiPageInfo, 'totalEntries' | 'totalPages'>;
  }>;
};

export type ApiGetAccountsListOfIdsWithFilterVarQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  page: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<ApiAccountSortItem>> | Maybe<ApiAccountSortItem>>;
  filterStringAlternate: Maybe<Scalars['String']>;
  productName: Maybe<Scalars['String']>;
}>;

export type ApiGetAccountsListOfIdsWithFilterVarQuery = {
  accounts: Maybe<{
    accounts: Array<Maybe<Pick<ApiAccount, 'id'>>>;
    pageInfo: Pick<ApiPageInfo, 'totalEntries'>;
  }>;
};

export type ApiGetAccountWithIncludeDirectivesQueryVariables = Exact<{
  id: Scalars['Int'];
  currentValue?: Scalars['Boolean'];
  engagementStatus?: Scalars['Boolean'];
  firstClosedOpportunityAt?: Scalars['Boolean'];
  lastInboundSignal?: Scalars['Boolean'];
  lastOutboundSignal?: Scalars['Boolean'];
  lostOpportunityCount?: Scalars['Boolean'];
  openOpportunityCount?: Scalars['Boolean'];
  signalActivityIntensity?: Scalars['Boolean'];
  signalResponseIntensity?: Scalars['Boolean'];
  wonOpportunityCount?: Scalars['Boolean'];
  xeominAverageQuantity?: Scalars['Boolean'];
  xeominPurchaseTrendLong?: Scalars['Boolean'];
  xeominPurchaseTrendShort?: Scalars['Boolean'];
  xeominSpendFraction?: Scalars['Boolean'];
}>;

export type ApiGetAccountWithIncludeDirectivesQuery = {
  account: Maybe<
    MakeMaybe<
      Pick<
        ApiAccount,
        | 'id'
        | 'currentValue'
        | 'engagementStatus'
        | 'firstClosedOpportunityAt'
        | 'lostOpportunityCount'
        | 'openOpportunityCount'
        | 'signalActivityIntensity'
        | 'signalResponseIntensity'
        | 'wonOpportunityCount'
        | 'xeominAverageQuantity'
        | 'xeominPurchaseTrendLong'
        | 'xeominPurchaseTrendShort'
        | 'xeominSpendFraction'
      >,
      | 'currentValue'
      | 'engagementStatus'
      | 'firstClosedOpportunityAt'
      | 'lostOpportunityCount'
      | 'openOpportunityCount'
      | 'signalActivityIntensity'
      | 'signalResponseIntensity'
      | 'wonOpportunityCount'
      | 'xeominAverageQuantity'
      | 'xeominPurchaseTrendLong'
      | 'xeominPurchaseTrendShort'
      | 'xeominSpendFraction'
    > & {
      lastInboundSignal?: Maybe<Pick<ApiSignal, 'occurredAt'>>;
      lastOutboundSignal?: Maybe<Pick<ApiSignal, 'occurredAt'>>;
    }
  >;
};

export type ApiGetAccountCountByGroupingQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
  groupBy: Maybe<
    Array<Maybe<ApiAccountCountGroupBy>> | Maybe<ApiAccountCountGroupBy>
  >;
}>;

export type ApiGetAccountCountByGroupingQuery = Pick<
  ApiRootQueryType,
  'filterString'
> & {
  accountCountByGrouping: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccountCountGrouping,
          | 'accountCount'
          | 'currentAccountScoreBucket'
          | 'possibleAccountScoreBucket'
          | 'signalType'
        >
      >
    >
  >;
};

export type ApiGetAccountGroupQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type ApiGetAccountGroupQuery = {
  accountGroup: Maybe<
    Pick<
      ApiAccountGroup,
      | 'id'
      | 'title'
      | 'description'
      | 'createdAt'
      | 'accountsUpdatedAt'
      | 'filterString'
    > & {
      accounts: Array<
        Maybe<
          Pick<
            ApiAccount,
            | 'id'
            | 'crmAccountId'
            | 'accountName'
            | 'industry'
            | 'currentValue'
            | 'annualRevenue'
            | 'firstClosedOpportunityAt'
            | 'rankAll'
            | 'rankPriority'
          > & {
            goalValues: Maybe<
              Array<
                Maybe<
                  Pick<ApiPredictionGoal, 'isPriority' | 'lift'> & {
                    signalPredictions: Maybe<
                      Array<
                        Maybe<
                          Pick<ApiSignalPrediction, 'recommendation'> & {
                            signal_type: ApiSignalPrediction['signalType'];
                          }
                        >
                      >
                    >;
                  }
                >
              >
            >;
          }
        >
      >;
      user: Pick<ApiUser, 'id' | 'email' | 'firstName' | 'lastName'>;
    }
  >;
};

export type ApiGetAccountGroupsByAccountQueryVariables = Exact<{
  accountId: Scalars['Int'];
  accountGroupType: Maybe<ApiAccountGroupType>;
}>;

export type ApiGetAccountGroupsByAccountQuery = {
  accountGroupsForAccount: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccountGroup,
          | 'id'
          | 'title'
          | 'description'
          | 'createdAt'
          | 'accountsUpdatedAt'
          | 'filterString'
        >
      >
    >
  >;
};

export type ApiGetAccountPredictionsQueryVariables = Exact<{
  accountId: Scalars['Int'];
  timeRange: ApiTimeRange;
}>;

export type ApiGetAccountPredictionsQuery = {
  accountPredictions: Maybe<
    Pick<ApiAccountPredictions, 'dateFormat'> & {
      currentPredictions: Maybe<
        Array<Maybe<Pick<ApiCurrentPrediction, 'calculatedFor' | 'value'>>>
      >;
      expectedPredictions: Maybe<
        Array<
          Maybe<
            Pick<
              ApiExpectedPrediction,
              'calculatedFor' | 'valueMiddle' | 'valueLow' | 'valueHigh'
            >
          >
        >
      >;
      goalPredictions: Maybe<
        Array<
          Maybe<
            Pick<ApiGoalPrediction, 'calculatedFor' | 'value' | 'isPriority'>
          >
        >
      >;
    }
  >;
};

export type ApiGetAccountSignalsQueryVariables = Exact<{
  accountId: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
}>;

export type ApiGetAccountSignalsQuery = {
  accountSignals: Maybe<
    Array<
      Maybe<
        Pick<
          ApiSignal,
          | 'id'
          | 'crmAccountId'
          | 'type'
          | 'typeDetail'
          | 'action'
          | 'direction'
          | 'source'
          | 'occurredAt'
          | 'topic'
          | 'topicDetail'
          | 'quantity'
          | 'value'
          | 'groupKey'
        >
      >
    >
  >;
};

export type ApiGetAccountsQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
}>;

export type ApiGetAccountsQuery = Pick<ApiRootQueryType, 'filterString'> & {
  accountsWithPredictions: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccount,
          | 'id'
          | 'crmAccountId'
          | 'accountName'
          | 'industry'
          | 'employeeCount'
          | 'annualRevenue'
          | 'currentValue'
          | 'firstClosedOpportunityAt'
          | 'contactCount'
          | 'signalActivityIntensity'
          | 'signalResponseIntensity'
          | 'rankAll'
          | 'rankPriority'
        > & {
          goalValues: Maybe<
            Array<
              Maybe<
                Pick<ApiPredictionGoal, 'isPriority' | 'lift'> & {
                  signalPredictions: Maybe<
                    Array<
                      Maybe<
                        Pick<ApiSignalPrediction, 'recommendation'> & {
                          signal_type: ApiSignalPrediction['signalType'];
                        }
                      >
                    >
                  >;
                }
              >
            >
          >;
        }
      >
    >
  >;
};

export type ApiGetAccountsWithFilterVarQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
}>;

export type ApiGetAccountsWithFilterVarQuery = {
  accountsWithPredictions: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccount,
          | 'id'
          | 'crmAccountId'
          | 'accountName'
          | 'industry'
          | 'employeeCount'
          | 'annualRevenue'
          | 'currentValue'
          | 'firstClosedOpportunityAt'
          | 'contactCount'
          | 'rankAll'
          | 'rankPriority'
          | 'xeominPredictionSummary'
          | 'xeominSpendFraction'
          | 'xeominPurchaseTrendShort'
        > & {
          goalValues: Maybe<
            Array<
              Maybe<
                Pick<ApiPredictionGoal, 'isPriority' | 'lift'> & {
                  signalPredictions: Maybe<
                    Array<
                      Maybe<
                        Pick<ApiSignalPrediction, 'recommendation'> & {
                          signal_type: ApiSignalPrediction['signalType'];
                        }
                      >
                    >
                  >;
                }
              >
            >
          >;
        }
      >
    >
  >;
};

export type ApiGetAccountsWithQueryStringQueryVariables = Exact<{
  queryString: Scalars['String'];
  limit: Maybe<Scalars['Boolean']>;
}>;

export type ApiGetAccountsWithQueryStringQuery = {
  accountsForSearch: Array<
    Maybe<
      Pick<ApiAccountForSearchPayload, 'id' | 'crmAccountId'> & {
        accountName: ApiAccountForSearchPayload['name'];
      }
    >
  >;
};

export type ApiGetAggregateDataQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
  type: Scalars['String'];
}>;

export type ApiGetAggregateDataQuery = Pick<
  ApiRootQueryType,
  'filterString' | 'aggregateData'
>;

export type ApiGetAggregateDataWithFilterVarQueryVariables = Exact<{
  filterString: Scalars['String'];
  type: Scalars['String'];
}>;

export type ApiGetAggregateDataWithFilterVarQuery = Pick<
  ApiRootQueryType,
  'aggregateData'
>;

export type ApiGetAggregateDataWithoutEngagementStatusQueryVariables = Exact<{
  filterString: Scalars['String'];
  type: Scalars['String'];
}>;

export type ApiGetAggregateDataWithoutEngagementStatusQuery = Pick<
  ApiRootQueryType,
  'filterStringWithoutEngagementStatus' | 'aggregateData'
>;

export type ApiGetAggregateDataWithQueryQueryVariables = Exact<{
  type: Scalars['String'];
  field: Scalars['String'];
  query: Maybe<Scalars['String']>;
  filterString: Maybe<Scalars['String']>;
}>;

export type ApiGetAggregateDataWithQueryQuery = Pick<
  ApiRootQueryType,
  'filterString'
> & {
  aggregateDataSearch: Array<
    Maybe<
      Pick<
        ApiAggregateDataSearchPayload,
        'value' | 'filteredCount' | 'totalCount'
      >
    >
  >;
};

export type ApiGetAllAccountGroupsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApiGetAllAccountGroupsQuery = {
  accountGroups: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAccountGroup,
          | 'id'
          | 'title'
          | 'description'
          | 'filterString'
          | 'createdAt'
          | 'accountsUpdatedAt'
          | 'isHidden'
          | 'accountCount'
        > & { user: Pick<ApiUser, 'id' | 'email' | 'firstName' | 'lastName'> }
      >
    >
  >;
};

export type ApiGetAllCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type ApiGetAllCompaniesQuery = {
  companies: Maybe<
    Array<
      Maybe<
        Pick<
          ApiCompany,
          'id' | 'companyName' | 'canSubmitCredentials' | 'crmTypes'
        >
      >
    >
  >;
};

export type ApiGetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ApiGetAllUsersQuery = {
  companyUsers: Maybe<
    Array<
      Maybe<
        Pick<
          ApiUser,
          | 'id'
          | 'firstName'
          | 'lastName'
          | 'email'
          | 'companyName'
          | 'isKlearlyAdmin'
          | 'isAdmin'
          | 'disabled'
          | 'role'
        >
      >
    >
  >;
};

export type ApiGetDataAssessmentPerCompanyQueryVariables = Exact<{
  company: Scalars['String'];
}>;

export type ApiGetDataAssessmentPerCompanyQuery = {
  dataAssessment: Maybe<
    Array<
      Maybe<
        Pick<ApiDataReport, 'title' | 'description' | 'queryText'> & {
          data: Maybe<Pick<ApiDataReportData, 'columns' | 'rows'>>;
        }
      >
    >
  >;
};

export type ApiGetKlearlyUserQueryVariables = Exact<{ [key: string]: never }>;

export type ApiGetKlearlyUserQuery = {
  me: Maybe<
    Pick<
      ApiUser,
      | 'id'
      | 'firstName'
      | 'lastName'
      | 'companyName'
      | 'email'
      | 'isAdmin'
      | 'isKlearlyAdmin'
      | 'disabled'
    > & {
      company: Maybe<
        Pick<
          ApiCompany,
          'id' | 'canSubmitCredentials' | 'crmTypes' | 'dataProcessedAt'
        > & {
          dataSources: Maybe<
            Array<
              Maybe<
                Pick<
                  ApiDataSource,
                  | 'source'
                  | 'portalId'
                  | 'portalDomain'
                  | 'database'
                  | 'prefix'
                  | 'stitchId'
                  | 'companyId'
                  | 'createdByUserId'
                  | 'insertedAt'
                  | 'updatedAt'
                >
              >
            >
          >;
        }
      >;
    }
  >;
};

export type ApiSignalHistoryItemsFragment = Pick<
  ApiSignalHistoryCategory,
  'narratives'
> & {
  signalHistoryItems: Array<
    Maybe<
      Pick<
        ApiSignalHistoryItem,
        'startDate' | 'endDate' | 'accountCount' | 'signalCount'
      >
    >
  >;
};

export type ApiGetSignalHistoryQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
  timeRange: ApiTimeRange;
}>;

export type ApiGetSignalHistoryQuery = Pick<
  ApiRootQueryType,
  'filterString'
> & {
  signalHistory: Maybe<
    Pick<ApiSignalHistory, 'dateFormat'> & {
      allAll: ApiSignalHistoryItemsFragment;
      allResponse: ApiSignalHistoryItemsFragment;
      allActivity: ApiSignalHistoryItemsFragment;
      marketingAll: ApiSignalHistoryItemsFragment;
      marketingResponse: ApiSignalHistoryItemsFragment;
      marketingActivity: ApiSignalHistoryItemsFragment;
      salesAll: ApiSignalHistoryItemsFragment;
      salesResponse: ApiSignalHistoryItemsFragment;
      salesActivity: ApiSignalHistoryItemsFragment;
    }
  >;
};

export type ApiGetRecommendedActionsQueryVariables = Exact<{
  filterString: Maybe<Scalars['String']>;
}>;

export type ApiGetRecommendedActionsQuery = Pick<
  ApiRootQueryType,
  'filterString'
> & {
  recommendedActions: Maybe<
    Array<
      Maybe<
        Pick<
          ApiRecommendedAction,
          | 'signalType'
          | 'action'
          | 'accountCount'
          | 'goalCount'
          | 'actualCount'
          | 'averageRank'
        >
      >
    >
  >;
};

export type ApiSignalHistoryFragment = {
  signalDetails: Array<
    Maybe<
      Pick<
        ApiSignalDetailsForAccountItem,
        | 'count'
        | 'signalDate'
        | 'signalSource'
        | 'signalTopicDetail'
        | 'signalType'
        | 'signalTypeDetail'
      >
    >
  >;
  signalTotalByDate: Array<
    Maybe<Pick<ApiSignalTotalForAccountByDate, 'date' | 'total'>>
  >;
};

export type ApiGetSignalHistoryForAccountQueryVariables = Exact<{
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  accountId: Scalars['Int'];
  groupBy: Array<Maybe<ApiSignalGroupByType>> | Maybe<ApiSignalGroupByType>;
}>;

export type ApiGetSignalHistoryForAccountQuery = {
  signalHistoryForAccount: Maybe<{
    allAll: ApiSignalHistoryFragment;
    allActivity: ApiSignalHistoryFragment;
    allResponse: ApiSignalHistoryFragment;
    marketingAll: ApiSignalHistoryFragment;
    marketingActivity: ApiSignalHistoryFragment;
    marketingResponse: ApiSignalHistoryFragment;
    salesAll: ApiSignalHistoryFragment;
    salesActivity: ApiSignalHistoryFragment;
    salesResponse: ApiSignalHistoryFragment;
  }>;
};

export type ApiGetAggregateMessagesQueryVariables = Exact<{
  dateFrom: Scalars['Date'];
  dateTo: Maybe<Scalars['Date']>;
  interval: Maybe<Scalars['Int']>;
}>;

export type ApiGetAggregateMessagesQuery = {
  aggregateMessages: Maybe<
    Array<
      Maybe<
        Pick<
          ApiAggregateMessage,
          'body' | 'count' | 'dateFrom' | 'dateTo' | 'name' | 'type' | 'tags'
        > & { accounts: Maybe<Array<Maybe<Pick<ApiAccount, 'id'>>>> }
      >
    >
  >;
};

export const SignalHistoryItemsFragmentDoc = gql`
  fragment signalHistoryItems on SignalHistoryCategory {
    signalHistoryItems {
      startDate
      endDate
      accountCount
      signalCount
    }
    narratives
  }
`;
export const SignalHistoryFragmentDoc = gql`
  fragment signalHistory on SignalHistoryForAccountItem {
    signalDetails {
      count
      signalDate
      signalSource
      signalTopicDetail
      signalType
      signalTypeDetail
    }
    signalTotalByDate {
      date
      total
    }
  }
`;
export const AddAccountGroupDocument = gql`
  mutation AddAccountGroup($input: AddAccountGroupInput!) {
    addAccountGroup(input: $input) {
      accountGroup {
        id
      }
    }
  }
`;
export type ApiAddAccountGroupMutationFn = Apollo.MutationFunction<
  ApiAddAccountGroupMutation,
  ApiAddAccountGroupMutationVariables
>;

/**
 * __useAddAccountGroupMutation__
 *
 * To run a mutation, you first call `useAddAccountGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountGroupMutation, { data, loading, error }] = useAddAccountGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAccountGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiAddAccountGroupMutation,
    ApiAddAccountGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiAddAccountGroupMutation,
    ApiAddAccountGroupMutationVariables
  >(AddAccountGroupDocument, options);
}
export type AddAccountGroupMutationHookResult = ReturnType<
  typeof useAddAccountGroupMutation
>;
export type AddAccountGroupMutationResult =
  Apollo.MutationResult<ApiAddAccountGroupMutation>;
export type AddAccountGroupMutationOptions = Apollo.BaseMutationOptions<
  ApiAddAccountGroupMutation,
  ApiAddAccountGroupMutationVariables
>;
export const CreateDataSourceConfigDocument = gql`
  mutation CreateDataSourceConfig($dataSourceConfig: DataSourceConfigInput!) {
    createDataSource(dataSourceConfig: $dataSourceConfig) {
      sourceId
    }
  }
`;
export type ApiCreateDataSourceConfigMutationFn = Apollo.MutationFunction<
  ApiCreateDataSourceConfigMutation,
  ApiCreateDataSourceConfigMutationVariables
>;

/**
 * __useCreateDataSourceConfigMutation__
 *
 * To run a mutation, you first call `useCreateDataSourceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataSourceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataSourceConfigMutation, { data, loading, error }] = useCreateDataSourceConfigMutation({
 *   variables: {
 *      dataSourceConfig: // value for 'dataSourceConfig'
 *   },
 * });
 */
export function useCreateDataSourceConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiCreateDataSourceConfigMutation,
    ApiCreateDataSourceConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiCreateDataSourceConfigMutation,
    ApiCreateDataSourceConfigMutationVariables
  >(CreateDataSourceConfigDocument, options);
}
export type CreateDataSourceConfigMutationHookResult = ReturnType<
  typeof useCreateDataSourceConfigMutation
>;
export type CreateDataSourceConfigMutationResult =
  Apollo.MutationResult<ApiCreateDataSourceConfigMutation>;
export type CreateDataSourceConfigMutationOptions = Apollo.BaseMutationOptions<
  ApiCreateDataSourceConfigMutation,
  ApiCreateDataSourceConfigMutationVariables
>;
export const CreateGoogleSecretVersionDocument = gql`
  mutation CreateGoogleSecretVersion($googleSecret: GoogleSecretInput!) {
    createGoogleSecretVersion(googleSecret: $googleSecret) {
      message
    }
  }
`;
export type ApiCreateGoogleSecretVersionMutationFn = Apollo.MutationFunction<
  ApiCreateGoogleSecretVersionMutation,
  ApiCreateGoogleSecretVersionMutationVariables
>;

/**
 * __useCreateGoogleSecretVersionMutation__
 *
 * To run a mutation, you first call `useCreateGoogleSecretVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoogleSecretVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoogleSecretVersionMutation, { data, loading, error }] = useCreateGoogleSecretVersionMutation({
 *   variables: {
 *      googleSecret: // value for 'googleSecret'
 *   },
 * });
 */
export function useCreateGoogleSecretVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiCreateGoogleSecretVersionMutation,
    ApiCreateGoogleSecretVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiCreateGoogleSecretVersionMutation,
    ApiCreateGoogleSecretVersionMutationVariables
  >(CreateGoogleSecretVersionDocument, options);
}
export type CreateGoogleSecretVersionMutationHookResult = ReturnType<
  typeof useCreateGoogleSecretVersionMutation
>;
export type CreateGoogleSecretVersionMutationResult =
  Apollo.MutationResult<ApiCreateGoogleSecretVersionMutation>;
export type CreateGoogleSecretVersionMutationOptions =
  Apollo.BaseMutationOptions<
    ApiCreateGoogleSecretVersionMutation,
    ApiCreateGoogleSecretVersionMutationVariables
  >;
export const CreateNewCompanyDocument = gql`
  mutation CreateNewCompany($company: CompanyInput!) {
    addCompany(company: $company) {
      id
      companyName
      canSubmitCredentials
      CRMTypes: crmTypes
      insertedAt
      updatedAt
    }
  }
`;
export type ApiCreateNewCompanyMutationFn = Apollo.MutationFunction<
  ApiCreateNewCompanyMutation,
  ApiCreateNewCompanyMutationVariables
>;

/**
 * __useCreateNewCompanyMutation__
 *
 * To run a mutation, you first call `useCreateNewCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewCompanyMutation, { data, loading, error }] = useCreateNewCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useCreateNewCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiCreateNewCompanyMutation,
    ApiCreateNewCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiCreateNewCompanyMutation,
    ApiCreateNewCompanyMutationVariables
  >(CreateNewCompanyDocument, options);
}
export type CreateNewCompanyMutationHookResult = ReturnType<
  typeof useCreateNewCompanyMutation
>;
export type CreateNewCompanyMutationResult =
  Apollo.MutationResult<ApiCreateNewCompanyMutation>;
export type CreateNewCompanyMutationOptions = Apollo.BaseMutationOptions<
  ApiCreateNewCompanyMutation,
  ApiCreateNewCompanyMutationVariables
>;
export const CreateNewUserDocument = gql`
  mutation CreateNewUser($user: UserInput!) {
    addUser(user: $user) {
      email
      firstName
      lastName
    }
  }
`;
export type ApiCreateNewUserMutationFn = Apollo.MutationFunction<
  ApiCreateNewUserMutation,
  ApiCreateNewUserMutationVariables
>;

/**
 * __useCreateNewUserMutation__
 *
 * To run a mutation, you first call `useCreateNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewUserMutation, { data, loading, error }] = useCreateNewUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateNewUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiCreateNewUserMutation,
    ApiCreateNewUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiCreateNewUserMutation,
    ApiCreateNewUserMutationVariables
  >(CreateNewUserDocument, options);
}
export type CreateNewUserMutationHookResult = ReturnType<
  typeof useCreateNewUserMutation
>;
export type CreateNewUserMutationResult =
  Apollo.MutationResult<ApiCreateNewUserMutation>;
export type CreateNewUserMutationOptions = Apollo.BaseMutationOptions<
  ApiCreateNewUserMutation,
  ApiCreateNewUserMutationVariables
>;
export const CreateNewUsersDocument = gql`
  mutation CreateNewUsers($users: [UserInput]!) {
    addUsers(users: $users) {
      users {
        firstName
        lastName
        email
        companyName
      }
      errors {
        email
        message
      }
    }
  }
`;
export type ApiCreateNewUsersMutationFn = Apollo.MutationFunction<
  ApiCreateNewUsersMutation,
  ApiCreateNewUsersMutationVariables
>;

/**
 * __useCreateNewUsersMutation__
 *
 * To run a mutation, you first call `useCreateNewUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewUsersMutation, { data, loading, error }] = useCreateNewUsersMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useCreateNewUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiCreateNewUsersMutation,
    ApiCreateNewUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiCreateNewUsersMutation,
    ApiCreateNewUsersMutationVariables
  >(CreateNewUsersDocument, options);
}
export type CreateNewUsersMutationHookResult = ReturnType<
  typeof useCreateNewUsersMutation
>;
export type CreateNewUsersMutationResult =
  Apollo.MutationResult<ApiCreateNewUsersMutation>;
export type CreateNewUsersMutationOptions = Apollo.BaseMutationOptions<
  ApiCreateNewUsersMutation,
  ApiCreateNewUsersMutationVariables
>;
export const UpdateAccountGroupAccountsDocument = gql`
  mutation UpdateAccountGroupAccounts(
    $input: UpdateAccountGroupAccountsInput!
  ) {
    updateAccountGroupAccounts(input: $input) {
      accountGroup {
        title
        id
      }
    }
  }
`;
export type ApiUpdateAccountGroupAccountsMutationFn = Apollo.MutationFunction<
  ApiUpdateAccountGroupAccountsMutation,
  ApiUpdateAccountGroupAccountsMutationVariables
>;

/**
 * __useUpdateAccountGroupAccountsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountGroupAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountGroupAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountGroupAccountsMutation, { data, loading, error }] = useUpdateAccountGroupAccountsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountGroupAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateAccountGroupAccountsMutation,
    ApiUpdateAccountGroupAccountsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateAccountGroupAccountsMutation,
    ApiUpdateAccountGroupAccountsMutationVariables
  >(UpdateAccountGroupAccountsDocument, options);
}
export type UpdateAccountGroupAccountsMutationHookResult = ReturnType<
  typeof useUpdateAccountGroupAccountsMutation
>;
export type UpdateAccountGroupAccountsMutationResult =
  Apollo.MutationResult<ApiUpdateAccountGroupAccountsMutation>;
export type UpdateAccountGroupAccountsMutationOptions =
  Apollo.BaseMutationOptions<
    ApiUpdateAccountGroupAccountsMutation,
    ApiUpdateAccountGroupAccountsMutationVariables
  >;
export const UpdateAccountGroupDescriptorsDocument = gql`
  mutation UpdateAccountGroupDescriptors(
    $input: UpdateAccountGroupDescriptorsInput!
  ) {
    updateAccountGroupDescriptors(input: $input) {
      accountGroup {
        title
        id
      }
    }
  }
`;
export type ApiUpdateAccountGroupDescriptorsMutationFn =
  Apollo.MutationFunction<
    ApiUpdateAccountGroupDescriptorsMutation,
    ApiUpdateAccountGroupDescriptorsMutationVariables
  >;

/**
 * __useUpdateAccountGroupDescriptorsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountGroupDescriptorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountGroupDescriptorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountGroupDescriptorsMutation, { data, loading, error }] = useUpdateAccountGroupDescriptorsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountGroupDescriptorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateAccountGroupDescriptorsMutation,
    ApiUpdateAccountGroupDescriptorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateAccountGroupDescriptorsMutation,
    ApiUpdateAccountGroupDescriptorsMutationVariables
  >(UpdateAccountGroupDescriptorsDocument, options);
}
export type UpdateAccountGroupDescriptorsMutationHookResult = ReturnType<
  typeof useUpdateAccountGroupDescriptorsMutation
>;
export type UpdateAccountGroupDescriptorsMutationResult =
  Apollo.MutationResult<ApiUpdateAccountGroupDescriptorsMutation>;
export type UpdateAccountGroupDescriptorsMutationOptions =
  Apollo.BaseMutationOptions<
    ApiUpdateAccountGroupDescriptorsMutation,
    ApiUpdateAccountGroupDescriptorsMutationVariables
  >;
export const UpdateAccountGroupIsHiddenDocument = gql`
  mutation UpdateAccountGroupIsHidden(
    $input: UpdateAccountGroupIsHiddenInput!
  ) {
    updateAccountGroupIsHidden(input: $input) {
      accountGroup {
        title
        id
      }
    }
  }
`;
export type ApiUpdateAccountGroupIsHiddenMutationFn = Apollo.MutationFunction<
  ApiUpdateAccountGroupIsHiddenMutation,
  ApiUpdateAccountGroupIsHiddenMutationVariables
>;

/**
 * __useUpdateAccountGroupIsHiddenMutation__
 *
 * To run a mutation, you first call `useUpdateAccountGroupIsHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountGroupIsHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountGroupIsHiddenMutation, { data, loading, error }] = useUpdateAccountGroupIsHiddenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountGroupIsHiddenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateAccountGroupIsHiddenMutation,
    ApiUpdateAccountGroupIsHiddenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateAccountGroupIsHiddenMutation,
    ApiUpdateAccountGroupIsHiddenMutationVariables
  >(UpdateAccountGroupIsHiddenDocument, options);
}
export type UpdateAccountGroupIsHiddenMutationHookResult = ReturnType<
  typeof useUpdateAccountGroupIsHiddenMutation
>;
export type UpdateAccountGroupIsHiddenMutationResult =
  Apollo.MutationResult<ApiUpdateAccountGroupIsHiddenMutation>;
export type UpdateAccountGroupIsHiddenMutationOptions =
  Apollo.BaseMutationOptions<
    ApiUpdateAccountGroupIsHiddenMutation,
    ApiUpdateAccountGroupIsHiddenMutationVariables
  >;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($company: CompanyInputUpdate!) {
    updateCompany(company: $company) {
      companyName
      canSubmitCredentials
      CRMTypes: crmTypes
    }
  }
`;
export type ApiUpdateCompanyMutationFn = Apollo.MutationFunction<
  ApiUpdateCompanyMutation,
  ApiUpdateCompanyMutationVariables
>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateCompanyMutation,
    ApiUpdateCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateCompanyMutation,
    ApiUpdateCompanyMutationVariables
  >(UpdateCompanyDocument, options);
}
export type UpdateCompanyMutationHookResult = ReturnType<
  typeof useUpdateCompanyMutation
>;
export type UpdateCompanyMutationResult =
  Apollo.MutationResult<ApiUpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  ApiUpdateCompanyMutation,
  ApiUpdateCompanyMutationVariables
>;
export const UpdateCurrentUserCompanyDocument = gql`
  mutation UpdateCurrentUserCompany($companyName: String!) {
    updateUserCompany(companyName: $companyName) {
      companyName
    }
  }
`;
export type ApiUpdateCurrentUserCompanyMutationFn = Apollo.MutationFunction<
  ApiUpdateCurrentUserCompanyMutation,
  ApiUpdateCurrentUserCompanyMutationVariables
>;

/**
 * __useUpdateCurrentUserCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserCompanyMutation, { data, loading, error }] = useUpdateCurrentUserCompanyMutation({
 *   variables: {
 *      companyName: // value for 'companyName'
 *   },
 * });
 */
export function useUpdateCurrentUserCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateCurrentUserCompanyMutation,
    ApiUpdateCurrentUserCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateCurrentUserCompanyMutation,
    ApiUpdateCurrentUserCompanyMutationVariables
  >(UpdateCurrentUserCompanyDocument, options);
}
export type UpdateCurrentUserCompanyMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserCompanyMutation
>;
export type UpdateCurrentUserCompanyMutationResult =
  Apollo.MutationResult<ApiUpdateCurrentUserCompanyMutation>;
export type UpdateCurrentUserCompanyMutationOptions =
  Apollo.BaseMutationOptions<
    ApiUpdateCurrentUserCompanyMutation,
    ApiUpdateCurrentUserCompanyMutationVariables
  >;
export const UpdateUserDocument = gql`
  mutation UpdateUser($user: UserInputUpdate!) {
    updateUser(user: $user) {
      companyName
      email
      firstName
      lastName
      isAdmin
      disabled
    }
  }
`;
export type ApiUpdateUserMutationFn = Apollo.MutationFunction<
  ApiUpdateUserMutation,
  ApiUpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApiUpdateUserMutation,
    ApiUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApiUpdateUserMutation,
    ApiUpdateUserMutationVariables
  >(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<ApiUpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  ApiUpdateUserMutation,
  ApiUpdateUserMutationVariables
>;
export const GetAccountDocument = gql`
  query GetAccount($id: Int!) {
    account(id: $id) {
      id
      accountName
      addressCity
      addressRegion
      addressStreet
      addressZipCode
      annualRevenue
      contactCount
      crmAccountId
      currentValue
      customerStatus
      employeeCount
      engagementStatus
      expectedValue {
        low
        middle
        high
      }
      firstClosedOpportunityAt
      goalValues {
        calculatedAt
        calculatedFor
        isPriority
        lift
        value
        signalPredictions {
          expectedLow
          expectedHigh
          goal
          recommendation
          signal_type: signalType
        }
      }
      industry
      lastInboundSignal {
        action
        direction
        occurredAt
        source
        type
        typeDetail
        topic
        topicDetail
      }
      lastOutboundSignal {
        action
        direction
        occurredAt
        source
        type
        typeDetail
        topic
        topicDetail
      }
      lostOpportunityCount
      narratives {
        primaryAction
        xeominPrediction
        xeominVolume
      }
      openOpportunityCount
      outreachStatus
      predictionCalculatedForName
      predictionCalculatedForType
      predictionLevel
      rankAll
      rankPriority
      signalActivityIntensity
      signalResponseIntensity
      wonOpportunityCount
      xeominPredictionSummary
      xeominStalePrediction
    }
  }
`;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountQuery,
    ApiGetAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiGetAccountQuery, ApiGetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export function useGetAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountQuery,
    ApiGetAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiGetAccountQuery, ApiGetAccountQueryVariables>(
    GetAccountDocument,
    options,
  );
}
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<
  typeof useGetAccountLazyQuery
>;
export type GetAccountQueryResult = Apollo.QueryResult<
  ApiGetAccountQuery,
  ApiGetAccountQueryVariables
>;
export function refetchGetAccountQuery(
  variables?: ApiGetAccountQueryVariables,
) {
  return { query: GetAccountDocument, variables: variables };
}
export const GetAccountMessagesDocument = gql`
  query GetAccountMessages($id: Int!) {
    account(id: $id) {
      messages {
        body
        occurredAt
        type
      }
    }
  }
`;

/**
 * __useGetAccountMessagesQuery__
 *
 * To run a query within a React component, call `useGetAccountMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountMessagesQuery,
    ApiGetAccountMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountMessagesQuery,
    ApiGetAccountMessagesQueryVariables
  >(GetAccountMessagesDocument, options);
}
export function useGetAccountMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountMessagesQuery,
    ApiGetAccountMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountMessagesQuery,
    ApiGetAccountMessagesQueryVariables
  >(GetAccountMessagesDocument, options);
}
export type GetAccountMessagesQueryHookResult = ReturnType<
  typeof useGetAccountMessagesQuery
>;
export type GetAccountMessagesLazyQueryHookResult = ReturnType<
  typeof useGetAccountMessagesLazyQuery
>;
export type GetAccountMessagesQueryResult = Apollo.QueryResult<
  ApiGetAccountMessagesQuery,
  ApiGetAccountMessagesQueryVariables
>;
export function refetchGetAccountMessagesQuery(
  variables?: ApiGetAccountMessagesQueryVariables,
) {
  return { query: GetAccountMessagesDocument, variables: variables };
}
export const GetAccountsListDocument = gql`
  query GetAccountsList(
    $filterString: String
    $filterStringAlternate: String
    $limit: Int
    $page: Int
    $sort: [AccountSortItem] = []
    $acqType: [PredictionForType]
    $retentionType: [PredictionForType]
    $fitType: [PredictionForType]
    $messageCountDaysAgo: Int
    $productName: String
  ) {
    filterString @client @export(as: "filterString")
    accounts(
      filterString: $filterString
      limit: $limit
      page: $page
      sort: $sort
      messageCountDaysAgo: $messageCountDaysAgo
      filterStringAlternate: $filterStringAlternate
    ) {
      accounts {
        id
        crmAccountId
        accountName
        acquisitionPredictions: predictions(
          current: true
          filter: { calculatedForType: $acqType }
        ) {
          recommendedPriorityAction
          level
          calculatedFor {
            type
            name
            date
          }
        }
        retentionPredictions: predictions(
          current: true
          filter: { calculatedForType: $retentionType }
        ) {
          recommendedPriorityAction
          level
          calculatedFor {
            type
            name
            date
          }
        }
        fitPredictions: predictions(
          current: true
          filter: { calculatedForType: $fitType }
        ) {
          level
          calculatedFor {
            type
            name
            date
          }
        }
        addressStreet
        addressCity
        addressRegion
        addressZipCode
        industry
        employeeCount
        annualRevenue
        customerStatus
        contactCount
        signalActivityIntensity
        signalResponseIntensity
        lastInboundSignal {
          action
          direction
          occurredAt
          source
          type
          typeDetail
          topic
          topicDetail
        }
        lastOutboundSignal {
          action
          direction
          occurredAt
          source
          type
          typeDetail
          topic
          topicDetail
        }
        engagementStatus
        outreachStatus
        xeominAverageQuantity
        xeominPredictionSummary
        xeominPurchaseTrendShort
        xeominSpendFraction
        orderFrequencies(calculatedForName: [$productName]) {
          accountId
          calculatedForName
          regularity
          lastOrderDate
          minDate
          maxDate
          meanDate
          medianDate
          contMedianDate
          orderFrequency
        }
        rankPriority
        messageCount
      }
      pageInfo {
        totalEntries
        totalPages
      }
    }
  }
`;

/**
 * __useGetAccountsListQuery__
 *
 * To run a query within a React component, call `useGetAccountsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsListQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      messageCountDaysAgo: // value for 'messageCountDaysAgo'
 *   },
 * });
 */
export function useGetAccountsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountsListQuery,
    ApiGetAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountsListQuery,
    ApiGetAccountsListQueryVariables
  >(GetAccountsListDocument, options);
}
export function useGetAccountsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsListQuery,
    ApiGetAccountsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountsListQuery,
    ApiGetAccountsListQueryVariables
  >(GetAccountsListDocument, options);
}
export type GetAccountsListQueryHookResult = ReturnType<
  typeof useGetAccountsListQuery
>;
export type GetAccountsListLazyQueryHookResult = ReturnType<
  typeof useGetAccountsListLazyQuery
>;
export type GetAccountsListQueryResult = Apollo.QueryResult<
  ApiGetAccountsListQuery,
  ApiGetAccountsListQueryVariables
>;
export function refetchGetAccountsListQuery(
  variables?: ApiGetAccountsListQueryVariables,
) {
  return { query: GetAccountsListDocument, variables: variables };
}
export const GetAccountsListWithFilterVarDocument = gql`
  query GetAccountsListWithFilterVar(
    $filterString: String
    $limit: Int
    $page: Int
    $sort: [AccountSortItem] = []
    $messageCountDaysAgo: Int
    $acqType: [PredictionForType]
    $retentionType: [PredictionForType]
    $fitType: [PredictionForType]
  ) {
    accounts(
      filterString: $filterString
      limit: $limit
      page: $page
      sort: $sort
      messageCountDaysAgo: $messageCountDaysAgo
    ) {
      accounts {
        id
        crmAccountId
        country
        accountName
        acquisitionPredictions: predictions(
          current: true
          filter: { calculatedForType: $acqType }
        ) {
          level
          recommendedPriorityAction
          calculatedFor {
            type
            name
            date
          }
        }
        retentionPredictions: predictions(
          current: true
          filter: { calculatedForType: $retentionType }
        ) {
          level
          recommendedPriorityAction
          calculatedFor {
            type
            name
            date
          }
        }
        fitPredictions: predictions(
          current: true
          filter: { calculatedForType: $fitType }
        ) {
          level
          calculatedFor {
            type
            name
            date
          }
        }
        addressStreet
        addressCity
        addressRegion
        addressZipCode
        annualRevenue
        customerStatus
        contactCount
        employeeCount
        industry
        rankPriority
        signalActivityIntensity
        signalResponseIntensity
        outreachStatus
        engagementStatus
        xeominAverageQuantity
        xeominPredictionSummary
        xeominPurchaseTrendShort
        xeominSpendFraction
      }
      pageInfo {
        totalEntries
        totalPages
      }
    }
  }
`;

/**
 * __useGetAccountsListWithFilterVarQuery__
 *
 * To run a query within a React component, call `useGetAccountsListWithFilterVarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsListWithFilterVarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsListWithFilterVarQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      messageCountDaysAgo: // value for 'messageCountDaysAgo'
 *   },
 * });
 */
export function useGetAccountsListWithFilterVarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountsListWithFilterVarQuery,
    ApiGetAccountsListWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountsListWithFilterVarQuery,
    ApiGetAccountsListWithFilterVarQueryVariables
  >(GetAccountsListWithFilterVarDocument, options);
}
export function useGetAccountsListWithFilterVarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsListWithFilterVarQuery,
    ApiGetAccountsListWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountsListWithFilterVarQuery,
    ApiGetAccountsListWithFilterVarQueryVariables
  >(GetAccountsListWithFilterVarDocument, options);
}
export type GetAccountsListWithFilterVarQueryHookResult = ReturnType<
  typeof useGetAccountsListWithFilterVarQuery
>;
export type GetAccountsListWithFilterVarLazyQueryHookResult = ReturnType<
  typeof useGetAccountsListWithFilterVarLazyQuery
>;
export type GetAccountsListWithFilterVarQueryResult = Apollo.QueryResult<
  ApiGetAccountsListWithFilterVarQuery,
  ApiGetAccountsListWithFilterVarQueryVariables
>;
export function refetchGetAccountsListWithFilterVarQuery(
  variables?: ApiGetAccountsListWithFilterVarQueryVariables,
) {
  return { query: GetAccountsListWithFilterVarDocument, variables: variables };
}
export const GetAccountsListOfIdsWithFilterVarDocument = gql`
  query GetAccountsListOfIdsWithFilterVar(
    $filterString: String
    $limit: Int
    $page: Int
    $sort: [AccountSortItem] = []
  ) {
    accounts(
      filterString: $filterString
      limit: $limit
      page: $page
      sort: $sort
    ) {
      accounts {
        id
      }
      pageInfo {
        totalEntries
      }
    }
  }
`;

/**
 * __useGetAccountsListOfIdsWithFilterVarQuery__
 *
 * To run a query within a React component, call `useGetAccountsListOfIdsWithFilterVarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsListOfIdsWithFilterVarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsListOfIdsWithFilterVarQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useGetAccountsListOfIdsWithFilterVarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountsListOfIdsWithFilterVarQuery,
    ApiGetAccountsListOfIdsWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountsListOfIdsWithFilterVarQuery,
    ApiGetAccountsListOfIdsWithFilterVarQueryVariables
  >(GetAccountsListOfIdsWithFilterVarDocument, options);
}
export function useGetAccountsListOfIdsWithFilterVarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsListOfIdsWithFilterVarQuery,
    ApiGetAccountsListOfIdsWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountsListOfIdsWithFilterVarQuery,
    ApiGetAccountsListOfIdsWithFilterVarQueryVariables
  >(GetAccountsListOfIdsWithFilterVarDocument, options);
}
export type GetAccountsListOfIdsWithFilterVarQueryHookResult = ReturnType<
  typeof useGetAccountsListOfIdsWithFilterVarQuery
>;
export type GetAccountsListOfIdsWithFilterVarLazyQueryHookResult = ReturnType<
  typeof useGetAccountsListOfIdsWithFilterVarLazyQuery
>;
export type GetAccountsListOfIdsWithFilterVarQueryResult = Apollo.QueryResult<
  ApiGetAccountsListOfIdsWithFilterVarQuery,
  ApiGetAccountsListOfIdsWithFilterVarQueryVariables
>;
export function refetchGetAccountsListOfIdsWithFilterVarQuery(
  variables?: ApiGetAccountsListOfIdsWithFilterVarQueryVariables,
) {
  return {
    query: GetAccountsListOfIdsWithFilterVarDocument,
    variables: variables,
  };
}
export const GetAccountWithIncludeDirectivesDocument = gql`
  query GetAccountWithIncludeDirectives(
    $id: Int!
    $currentValue: Boolean! = false
    $engagementStatus: Boolean! = false
    $firstClosedOpportunityAt: Boolean! = false
    $lastInboundSignal: Boolean! = false
    $lastOutboundSignal: Boolean! = false
    $lostOpportunityCount: Boolean! = false
    $openOpportunityCount: Boolean! = false
    $signalActivityIntensity: Boolean! = false
    $signalResponseIntensity: Boolean! = false
    $wonOpportunityCount: Boolean! = false
    $xeominAverageQuantity: Boolean! = false
    $xeominPurchaseTrendLong: Boolean! = false
    $xeominPurchaseTrendShort: Boolean! = false
    $xeominSpendFraction: Boolean! = false
  ) {
    account(id: $id) {
      id
      currentValue @include(if: $currentValue)
      engagementStatus @include(if: $engagementStatus)
      firstClosedOpportunityAt @include(if: $firstClosedOpportunityAt)
      lastInboundSignal @include(if: $lastInboundSignal) {
        occurredAt
      }
      lastOutboundSignal @include(if: $lastOutboundSignal) {
        occurredAt
      }
      lostOpportunityCount @include(if: $lostOpportunityCount)
      openOpportunityCount @include(if: $openOpportunityCount)
      signalActivityIntensity @include(if: $signalActivityIntensity)
      signalResponseIntensity @include(if: $signalResponseIntensity)
      wonOpportunityCount @include(if: $wonOpportunityCount)
      xeominAverageQuantity @include(if: $xeominAverageQuantity)
      xeominPurchaseTrendLong @include(if: $xeominPurchaseTrendLong)
      xeominPurchaseTrendShort @include(if: $xeominPurchaseTrendShort)
      xeominSpendFraction @include(if: $xeominSpendFraction)
    }
  }
`;

/**
 * __useGetAccountWithIncludeDirectivesQuery__
 *
 * To run a query within a React component, call `useGetAccountWithIncludeDirectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountWithIncludeDirectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountWithIncludeDirectivesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      currentValue: // value for 'currentValue'
 *      engagementStatus: // value for 'engagementStatus'
 *      firstClosedOpportunityAt: // value for 'firstClosedOpportunityAt'
 *      lastInboundSignal: // value for 'lastInboundSignal'
 *      lastOutboundSignal: // value for 'lastOutboundSignal'
 *      lostOpportunityCount: // value for 'lostOpportunityCount'
 *      openOpportunityCount: // value for 'openOpportunityCount'
 *      signalActivityIntensity: // value for 'signalActivityIntensity'
 *      signalResponseIntensity: // value for 'signalResponseIntensity'
 *      wonOpportunityCount: // value for 'wonOpportunityCount'
 *      xeominAverageQuantity: // value for 'xeominAverageQuantity'
 *      xeominPurchaseTrendLong: // value for 'xeominPurchaseTrendLong'
 *      xeominPurchaseTrendShort: // value for 'xeominPurchaseTrendShort'
 *      xeominSpendFraction: // value for 'xeominSpendFraction'
 *   },
 * });
 */
export function useGetAccountWithIncludeDirectivesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountWithIncludeDirectivesQuery,
    ApiGetAccountWithIncludeDirectivesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountWithIncludeDirectivesQuery,
    ApiGetAccountWithIncludeDirectivesQueryVariables
  >(GetAccountWithIncludeDirectivesDocument, options);
}
export function useGetAccountWithIncludeDirectivesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountWithIncludeDirectivesQuery,
    ApiGetAccountWithIncludeDirectivesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountWithIncludeDirectivesQuery,
    ApiGetAccountWithIncludeDirectivesQueryVariables
  >(GetAccountWithIncludeDirectivesDocument, options);
}
export type GetAccountWithIncludeDirectivesQueryHookResult = ReturnType<
  typeof useGetAccountWithIncludeDirectivesQuery
>;
export type GetAccountWithIncludeDirectivesLazyQueryHookResult = ReturnType<
  typeof useGetAccountWithIncludeDirectivesLazyQuery
>;
export type GetAccountWithIncludeDirectivesQueryResult = Apollo.QueryResult<
  ApiGetAccountWithIncludeDirectivesQuery,
  ApiGetAccountWithIncludeDirectivesQueryVariables
>;
export function refetchGetAccountWithIncludeDirectivesQuery(
  variables?: ApiGetAccountWithIncludeDirectivesQueryVariables,
) {
  return {
    query: GetAccountWithIncludeDirectivesDocument,
    variables: variables,
  };
}
export const GetAccountCountByGroupingDocument = gql`
  query GetAccountCountByGrouping(
    $filterString: String
    $groupBy: [AccountCountGroupBy]
  ) {
    filterString @client @export(as: "filterString")
    accountCountByGrouping(filterString: $filterString, groupBy: $groupBy) {
      accountCount
      currentAccountScoreBucket
      possibleAccountScoreBucket
      signalType
    }
  }
`;

/**
 * __useGetAccountCountByGroupingQuery__
 *
 * To run a query within a React component, call `useGetAccountCountByGroupingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountCountByGroupingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountCountByGroupingQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useGetAccountCountByGroupingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountCountByGroupingQuery,
    ApiGetAccountCountByGroupingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountCountByGroupingQuery,
    ApiGetAccountCountByGroupingQueryVariables
  >(GetAccountCountByGroupingDocument, options);
}
export function useGetAccountCountByGroupingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountCountByGroupingQuery,
    ApiGetAccountCountByGroupingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountCountByGroupingQuery,
    ApiGetAccountCountByGroupingQueryVariables
  >(GetAccountCountByGroupingDocument, options);
}
export type GetAccountCountByGroupingQueryHookResult = ReturnType<
  typeof useGetAccountCountByGroupingQuery
>;
export type GetAccountCountByGroupingLazyQueryHookResult = ReturnType<
  typeof useGetAccountCountByGroupingLazyQuery
>;
export type GetAccountCountByGroupingQueryResult = Apollo.QueryResult<
  ApiGetAccountCountByGroupingQuery,
  ApiGetAccountCountByGroupingQueryVariables
>;
export function refetchGetAccountCountByGroupingQuery(
  variables?: ApiGetAccountCountByGroupingQueryVariables,
) {
  return { query: GetAccountCountByGroupingDocument, variables: variables };
}
export const GetAccountGroupDocument = gql`
  query GetAccountGroup($id: Int!) {
    accountGroup(id: $id) {
      id
      title
      description
      createdAt
      accountsUpdatedAt
      filterString
      accounts {
        id
        crmAccountId
        accountName
        industry
        currentValue
        annualRevenue
        firstClosedOpportunityAt
        goalValues {
          isPriority
          lift
          signalPredictions {
            recommendation
            signal_type: signalType
          }
        }
        rankAll
        rankPriority
      }
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useGetAccountGroupQuery__
 *
 * To run a query within a React component, call `useGetAccountGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccountGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountGroupQuery,
    ApiGetAccountGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountGroupQuery,
    ApiGetAccountGroupQueryVariables
  >(GetAccountGroupDocument, options);
}
export function useGetAccountGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountGroupQuery,
    ApiGetAccountGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountGroupQuery,
    ApiGetAccountGroupQueryVariables
  >(GetAccountGroupDocument, options);
}
export type GetAccountGroupQueryHookResult = ReturnType<
  typeof useGetAccountGroupQuery
>;
export type GetAccountGroupLazyQueryHookResult = ReturnType<
  typeof useGetAccountGroupLazyQuery
>;
export type GetAccountGroupQueryResult = Apollo.QueryResult<
  ApiGetAccountGroupQuery,
  ApiGetAccountGroupQueryVariables
>;
export function refetchGetAccountGroupQuery(
  variables?: ApiGetAccountGroupQueryVariables,
) {
  return { query: GetAccountGroupDocument, variables: variables };
}
export const GetAccountGroupsByAccountDocument = gql`
  query GetAccountGroupsByAccount(
    $accountId: Int!
    $accountGroupType: AccountGroupType
  ) {
    accountGroupsForAccount(
      accountId: $accountId
      accountGroupType: $accountGroupType
    ) {
      id
      title
      description
      createdAt
      accountsUpdatedAt
      filterString
    }
  }
`;

/**
 * __useGetAccountGroupsByAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountGroupsByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountGroupsByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountGroupsByAccountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      accountGroupType: // value for 'accountGroupType'
 *   },
 * });
 */
export function useGetAccountGroupsByAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountGroupsByAccountQuery,
    ApiGetAccountGroupsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountGroupsByAccountQuery,
    ApiGetAccountGroupsByAccountQueryVariables
  >(GetAccountGroupsByAccountDocument, options);
}
export function useGetAccountGroupsByAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountGroupsByAccountQuery,
    ApiGetAccountGroupsByAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountGroupsByAccountQuery,
    ApiGetAccountGroupsByAccountQueryVariables
  >(GetAccountGroupsByAccountDocument, options);
}
export type GetAccountGroupsByAccountQueryHookResult = ReturnType<
  typeof useGetAccountGroupsByAccountQuery
>;
export type GetAccountGroupsByAccountLazyQueryHookResult = ReturnType<
  typeof useGetAccountGroupsByAccountLazyQuery
>;
export type GetAccountGroupsByAccountQueryResult = Apollo.QueryResult<
  ApiGetAccountGroupsByAccountQuery,
  ApiGetAccountGroupsByAccountQueryVariables
>;
export function refetchGetAccountGroupsByAccountQuery(
  variables?: ApiGetAccountGroupsByAccountQueryVariables,
) {
  return { query: GetAccountGroupsByAccountDocument, variables: variables };
}
export const GetAccountPredictionsDocument = gql`
  query GetAccountPredictions($accountId: Int!, $timeRange: TimeRange!) {
    accountPredictions(accountId: $accountId, timeRange: $timeRange) {
      currentPredictions {
        calculatedFor
        value
      }
      expectedPredictions {
        calculatedFor
        valueMiddle
        valueLow
        valueHigh
      }
      goalPredictions {
        calculatedFor
        value
        isPriority
      }
      dateFormat
    }
  }
`;

/**
 * __useGetAccountPredictionsQuery__
 *
 * To run a query within a React component, call `useGetAccountPredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountPredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountPredictionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      timeRange: // value for 'timeRange'
 *   },
 * });
 */
export function useGetAccountPredictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountPredictionsQuery,
    ApiGetAccountPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountPredictionsQuery,
    ApiGetAccountPredictionsQueryVariables
  >(GetAccountPredictionsDocument, options);
}
export function useGetAccountPredictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountPredictionsQuery,
    ApiGetAccountPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountPredictionsQuery,
    ApiGetAccountPredictionsQueryVariables
  >(GetAccountPredictionsDocument, options);
}
export type GetAccountPredictionsQueryHookResult = ReturnType<
  typeof useGetAccountPredictionsQuery
>;
export type GetAccountPredictionsLazyQueryHookResult = ReturnType<
  typeof useGetAccountPredictionsLazyQuery
>;
export type GetAccountPredictionsQueryResult = Apollo.QueryResult<
  ApiGetAccountPredictionsQuery,
  ApiGetAccountPredictionsQueryVariables
>;
export function refetchGetAccountPredictionsQuery(
  variables?: ApiGetAccountPredictionsQueryVariables,
) {
  return { query: GetAccountPredictionsDocument, variables: variables };
}
export const GetAccountSignalsDocument = gql`
  query GetAccountSignals(
    $accountId: Int!
    $startDate: Date!
    $endDate: Date!
  ) {
    accountSignals(
      accountId: $accountId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      crmAccountId
      type
      typeDetail
      action
      direction
      source
      occurredAt
      topic
      topicDetail
      quantity
      value
      groupKey
    }
  }
`;

/**
 * __useGetAccountSignalsQuery__
 *
 * To run a query within a React component, call `useGetAccountSignalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountSignalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountSignalsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAccountSignalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountSignalsQuery,
    ApiGetAccountSignalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountSignalsQuery,
    ApiGetAccountSignalsQueryVariables
  >(GetAccountSignalsDocument, options);
}
export function useGetAccountSignalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountSignalsQuery,
    ApiGetAccountSignalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountSignalsQuery,
    ApiGetAccountSignalsQueryVariables
  >(GetAccountSignalsDocument, options);
}
export type GetAccountSignalsQueryHookResult = ReturnType<
  typeof useGetAccountSignalsQuery
>;
export type GetAccountSignalsLazyQueryHookResult = ReturnType<
  typeof useGetAccountSignalsLazyQuery
>;
export type GetAccountSignalsQueryResult = Apollo.QueryResult<
  ApiGetAccountSignalsQuery,
  ApiGetAccountSignalsQueryVariables
>;
export function refetchGetAccountSignalsQuery(
  variables?: ApiGetAccountSignalsQueryVariables,
) {
  return { query: GetAccountSignalsDocument, variables: variables };
}
export const GetAccountsDocument = gql`
  query GetAccounts($filterString: String) {
    filterString @client @export(as: "filterString")
    accountsWithPredictions(filterString: $filterString) {
      id
      crmAccountId
      accountName
      industry
      employeeCount
      annualRevenue
      currentValue
      firstClosedOpportunityAt
      goalValues {
        isPriority
        lift
        signalPredictions {
          recommendation
          signal_type: signalType
        }
      }
      contactCount
      signalActivityIntensity
      signalResponseIntensity
      rankAll
      rankPriority
    }
  }
`;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountsQuery,
    ApiGetAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiGetAccountsQuery, ApiGetAccountsQueryVariables>(
    GetAccountsDocument,
    options,
  );
}
export function useGetAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsQuery,
    ApiGetAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiGetAccountsQuery, ApiGetAccountsQueryVariables>(
    GetAccountsDocument,
    options,
  );
}
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAccountsLazyQuery
>;
export type GetAccountsQueryResult = Apollo.QueryResult<
  ApiGetAccountsQuery,
  ApiGetAccountsQueryVariables
>;
export function refetchGetAccountsQuery(
  variables?: ApiGetAccountsQueryVariables,
) {
  return { query: GetAccountsDocument, variables: variables };
}
export const GetAccountsWithFilterVarDocument = gql`
  query GetAccountsWithFilterVar($filterString: String) {
    accountsWithPredictions(filterString: $filterString) {
      id
      crmAccountId
      accountName
      industry
      employeeCount
      annualRevenue
      currentValue
      firstClosedOpportunityAt
      goalValues {
        isPriority
        lift
        signalPredictions {
          recommendation
          signal_type: signalType
        }
      }
      contactCount
      rankAll
      rankPriority
      xeominPredictionSummary
      xeominSpendFraction
      xeominPurchaseTrendShort
    }
  }
`;

/**
 * __useGetAccountsWithFilterVarQuery__
 *
 * To run a query within a React component, call `useGetAccountsWithFilterVarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsWithFilterVarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsWithFilterVarQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetAccountsWithFilterVarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAccountsWithFilterVarQuery,
    ApiGetAccountsWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountsWithFilterVarQuery,
    ApiGetAccountsWithFilterVarQueryVariables
  >(GetAccountsWithFilterVarDocument, options);
}
export function useGetAccountsWithFilterVarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsWithFilterVarQuery,
    ApiGetAccountsWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountsWithFilterVarQuery,
    ApiGetAccountsWithFilterVarQueryVariables
  >(GetAccountsWithFilterVarDocument, options);
}
export type GetAccountsWithFilterVarQueryHookResult = ReturnType<
  typeof useGetAccountsWithFilterVarQuery
>;
export type GetAccountsWithFilterVarLazyQueryHookResult = ReturnType<
  typeof useGetAccountsWithFilterVarLazyQuery
>;
export type GetAccountsWithFilterVarQueryResult = Apollo.QueryResult<
  ApiGetAccountsWithFilterVarQuery,
  ApiGetAccountsWithFilterVarQueryVariables
>;
export function refetchGetAccountsWithFilterVarQuery(
  variables?: ApiGetAccountsWithFilterVarQueryVariables,
) {
  return { query: GetAccountsWithFilterVarDocument, variables: variables };
}
export const GetAccountsWithQueryStringDocument = gql`
  query GetAccountsWithQueryString($queryString: String!, $limit: Boolean) {
    accountsForSearch(
      query: $queryString
      limitToOpenAccountsWithPredictions: $limit
    ) {
      id
      accountName: name
      crmAccountId
    }
  }
`;

/**
 * __useGetAccountsWithQueryStringQuery__
 *
 * To run a query within a React component, call `useGetAccountsWithQueryStringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsWithQueryStringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsWithQueryStringQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAccountsWithQueryStringQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAccountsWithQueryStringQuery,
    ApiGetAccountsWithQueryStringQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAccountsWithQueryStringQuery,
    ApiGetAccountsWithQueryStringQueryVariables
  >(GetAccountsWithQueryStringDocument, options);
}
export function useGetAccountsWithQueryStringLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAccountsWithQueryStringQuery,
    ApiGetAccountsWithQueryStringQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAccountsWithQueryStringQuery,
    ApiGetAccountsWithQueryStringQueryVariables
  >(GetAccountsWithQueryStringDocument, options);
}
export type GetAccountsWithQueryStringQueryHookResult = ReturnType<
  typeof useGetAccountsWithQueryStringQuery
>;
export type GetAccountsWithQueryStringLazyQueryHookResult = ReturnType<
  typeof useGetAccountsWithQueryStringLazyQuery
>;
export type GetAccountsWithQueryStringQueryResult = Apollo.QueryResult<
  ApiGetAccountsWithQueryStringQuery,
  ApiGetAccountsWithQueryStringQueryVariables
>;
export function refetchGetAccountsWithQueryStringQuery(
  variables?: ApiGetAccountsWithQueryStringQueryVariables,
) {
  return { query: GetAccountsWithQueryStringDocument, variables: variables };
}
export const GetAggregateDataDocument = gql`
  query GetAggregateData($filterString: String, $type: String!) {
    filterString @client @export(as: "filterString")
    aggregateData(filterString: $filterString, type: $type)
  }
`;

/**
 * __useGetAggregateDataQuery__
 *
 * To run a query within a React component, call `useGetAggregateDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateDataQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAggregateDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAggregateDataQuery,
    ApiGetAggregateDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAggregateDataQuery,
    ApiGetAggregateDataQueryVariables
  >(GetAggregateDataDocument, options);
}
export function useGetAggregateDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAggregateDataQuery,
    ApiGetAggregateDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAggregateDataQuery,
    ApiGetAggregateDataQueryVariables
  >(GetAggregateDataDocument, options);
}
export type GetAggregateDataQueryHookResult = ReturnType<
  typeof useGetAggregateDataQuery
>;
export type GetAggregateDataLazyQueryHookResult = ReturnType<
  typeof useGetAggregateDataLazyQuery
>;
export type GetAggregateDataQueryResult = Apollo.QueryResult<
  ApiGetAggregateDataQuery,
  ApiGetAggregateDataQueryVariables
>;
export function refetchGetAggregateDataQuery(
  variables?: ApiGetAggregateDataQueryVariables,
) {
  return { query: GetAggregateDataDocument, variables: variables };
}
export const GetAggregateDataWithFilterVarDocument = gql`
  query GetAggregateDataWithFilterVar($filterString: String!, $type: String!) {
    aggregateData(filterString: $filterString, type: $type)
  }
`;

/**
 * __useGetAggregateDataWithFilterVarQuery__
 *
 * To run a query within a React component, call `useGetAggregateDataWithFilterVarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateDataWithFilterVarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateDataWithFilterVarQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAggregateDataWithFilterVarQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAggregateDataWithFilterVarQuery,
    ApiGetAggregateDataWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAggregateDataWithFilterVarQuery,
    ApiGetAggregateDataWithFilterVarQueryVariables
  >(GetAggregateDataWithFilterVarDocument, options);
}
export function useGetAggregateDataWithFilterVarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAggregateDataWithFilterVarQuery,
    ApiGetAggregateDataWithFilterVarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAggregateDataWithFilterVarQuery,
    ApiGetAggregateDataWithFilterVarQueryVariables
  >(GetAggregateDataWithFilterVarDocument, options);
}
export type GetAggregateDataWithFilterVarQueryHookResult = ReturnType<
  typeof useGetAggregateDataWithFilterVarQuery
>;
export type GetAggregateDataWithFilterVarLazyQueryHookResult = ReturnType<
  typeof useGetAggregateDataWithFilterVarLazyQuery
>;
export type GetAggregateDataWithFilterVarQueryResult = Apollo.QueryResult<
  ApiGetAggregateDataWithFilterVarQuery,
  ApiGetAggregateDataWithFilterVarQueryVariables
>;
export function refetchGetAggregateDataWithFilterVarQuery(
  variables?: ApiGetAggregateDataWithFilterVarQueryVariables,
) {
  return { query: GetAggregateDataWithFilterVarDocument, variables: variables };
}
export const GetAggregateDataWithoutEngagementStatusDocument = gql`
  query GetAggregateDataWithoutEngagementStatus(
    $filterString: String!
    $type: String!
  ) {
    filterStringWithoutEngagementStatus @client @export(as: "filterString")
    aggregateData(filterString: $filterString, type: $type)
  }
`;

/**
 * __useGetAggregateDataWithoutEngagementStatusQuery__
 *
 * To run a query within a React component, call `useGetAggregateDataWithoutEngagementStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateDataWithoutEngagementStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateDataWithoutEngagementStatusQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetAggregateDataWithoutEngagementStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAggregateDataWithoutEngagementStatusQuery,
    ApiGetAggregateDataWithoutEngagementStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAggregateDataWithoutEngagementStatusQuery,
    ApiGetAggregateDataWithoutEngagementStatusQueryVariables
  >(GetAggregateDataWithoutEngagementStatusDocument, options);
}
export function useGetAggregateDataWithoutEngagementStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAggregateDataWithoutEngagementStatusQuery,
    ApiGetAggregateDataWithoutEngagementStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAggregateDataWithoutEngagementStatusQuery,
    ApiGetAggregateDataWithoutEngagementStatusQueryVariables
  >(GetAggregateDataWithoutEngagementStatusDocument, options);
}
export type GetAggregateDataWithoutEngagementStatusQueryHookResult = ReturnType<
  typeof useGetAggregateDataWithoutEngagementStatusQuery
>;
export type GetAggregateDataWithoutEngagementStatusLazyQueryHookResult =
  ReturnType<typeof useGetAggregateDataWithoutEngagementStatusLazyQuery>;
export type GetAggregateDataWithoutEngagementStatusQueryResult =
  Apollo.QueryResult<
    ApiGetAggregateDataWithoutEngagementStatusQuery,
    ApiGetAggregateDataWithoutEngagementStatusQueryVariables
  >;
export function refetchGetAggregateDataWithoutEngagementStatusQuery(
  variables?: ApiGetAggregateDataWithoutEngagementStatusQueryVariables,
) {
  return {
    query: GetAggregateDataWithoutEngagementStatusDocument,
    variables: variables,
  };
}
export const GetAggregateDataWithQueryDocument = gql`
  query GetAggregateDataWithQuery(
    $type: String!
    $field: String!
    $query: String
    $filterString: String
  ) {
    filterString @client @export(as: "filterString")
    aggregateDataSearch(
      type: $type
      field: $field
      query: $query
      filterString: $filterString
    ) {
      value
      filteredCount
      totalCount
    }
  }
`;

/**
 * __useGetAggregateDataWithQueryQuery__
 *
 * To run a query within a React component, call `useGetAggregateDataWithQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateDataWithQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateDataWithQueryQuery({
 *   variables: {
 *      type: // value for 'type'
 *      field: // value for 'field'
 *      query: // value for 'query'
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetAggregateDataWithQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAggregateDataWithQueryQuery,
    ApiGetAggregateDataWithQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAggregateDataWithQueryQuery,
    ApiGetAggregateDataWithQueryQueryVariables
  >(GetAggregateDataWithQueryDocument, options);
}
export function useGetAggregateDataWithQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAggregateDataWithQueryQuery,
    ApiGetAggregateDataWithQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAggregateDataWithQueryQuery,
    ApiGetAggregateDataWithQueryQueryVariables
  >(GetAggregateDataWithQueryDocument, options);
}
export type GetAggregateDataWithQueryQueryHookResult = ReturnType<
  typeof useGetAggregateDataWithQueryQuery
>;
export type GetAggregateDataWithQueryLazyQueryHookResult = ReturnType<
  typeof useGetAggregateDataWithQueryLazyQuery
>;
export type GetAggregateDataWithQueryQueryResult = Apollo.QueryResult<
  ApiGetAggregateDataWithQueryQuery,
  ApiGetAggregateDataWithQueryQueryVariables
>;
export function refetchGetAggregateDataWithQueryQuery(
  variables?: ApiGetAggregateDataWithQueryQueryVariables,
) {
  return { query: GetAggregateDataWithQueryDocument, variables: variables };
}
export const GetAllAccountGroupsDocument = gql`
  query GetAllAccountGroups {
    accountGroups {
      id
      title
      description
      filterString
      createdAt
      accountsUpdatedAt
      user {
        id
        email
        firstName
        lastName
      }
      isHidden
      accountCount
    }
  }
`;

/**
 * __useGetAllAccountGroupsQuery__
 *
 * To run a query within a React component, call `useGetAllAccountGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAccountGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAccountGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAccountGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAllAccountGroupsQuery,
    ApiGetAllAccountGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAllAccountGroupsQuery,
    ApiGetAllAccountGroupsQueryVariables
  >(GetAllAccountGroupsDocument, options);
}
export function useGetAllAccountGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAllAccountGroupsQuery,
    ApiGetAllAccountGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAllAccountGroupsQuery,
    ApiGetAllAccountGroupsQueryVariables
  >(GetAllAccountGroupsDocument, options);
}
export type GetAllAccountGroupsQueryHookResult = ReturnType<
  typeof useGetAllAccountGroupsQuery
>;
export type GetAllAccountGroupsLazyQueryHookResult = ReturnType<
  typeof useGetAllAccountGroupsLazyQuery
>;
export type GetAllAccountGroupsQueryResult = Apollo.QueryResult<
  ApiGetAllAccountGroupsQuery,
  ApiGetAllAccountGroupsQueryVariables
>;
export function refetchGetAllAccountGroupsQuery(
  variables?: ApiGetAllAccountGroupsQueryVariables,
) {
  return { query: GetAllAccountGroupsDocument, variables: variables };
}
export const GetAllCompaniesDocument = gql`
  query GetAllCompanies {
    companies {
      id
      companyName
      canSubmitCredentials
      crmTypes
    }
  }
`;

/**
 * __useGetAllCompaniesQuery__
 *
 * To run a query within a React component, call `useGetAllCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAllCompaniesQuery,
    ApiGetAllCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAllCompaniesQuery,
    ApiGetAllCompaniesQueryVariables
  >(GetAllCompaniesDocument, options);
}
export function useGetAllCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAllCompaniesQuery,
    ApiGetAllCompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAllCompaniesQuery,
    ApiGetAllCompaniesQueryVariables
  >(GetAllCompaniesDocument, options);
}
export type GetAllCompaniesQueryHookResult = ReturnType<
  typeof useGetAllCompaniesQuery
>;
export type GetAllCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetAllCompaniesLazyQuery
>;
export type GetAllCompaniesQueryResult = Apollo.QueryResult<
  ApiGetAllCompaniesQuery,
  ApiGetAllCompaniesQueryVariables
>;
export function refetchGetAllCompaniesQuery(
  variables?: ApiGetAllCompaniesQueryVariables,
) {
  return { query: GetAllCompaniesDocument, variables: variables };
}
export const GetAllUsersDocument = gql`
  query GetAllUsers {
    companyUsers {
      id
      firstName
      lastName
      email
      companyName
      isKlearlyAdmin
      isAdmin
      disabled
      role
    }
  }
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetAllUsersQuery,
    ApiGetAllUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ApiGetAllUsersQuery, ApiGetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options,
  );
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAllUsersQuery,
    ApiGetAllUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ApiGetAllUsersQuery, ApiGetAllUsersQueryVariables>(
    GetAllUsersDocument,
    options,
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<
  typeof useGetAllUsersLazyQuery
>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  ApiGetAllUsersQuery,
  ApiGetAllUsersQueryVariables
>;
export function refetchGetAllUsersQuery(
  variables?: ApiGetAllUsersQueryVariables,
) {
  return { query: GetAllUsersDocument, variables: variables };
}
export const GetDataAssessmentPerCompanyDocument = gql`
  query GetDataAssessmentPerCompany($company: String!) {
    dataAssessment(company: $company) {
      title
      description
      queryText
      data {
        columns
        rows
      }
    }
  }
`;

/**
 * __useGetDataAssessmentPerCompanyQuery__
 *
 * To run a query within a React component, call `useGetDataAssessmentPerCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataAssessmentPerCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataAssessmentPerCompanyQuery({
 *   variables: {
 *      company: // value for 'company'
 *   },
 * });
 */
export function useGetDataAssessmentPerCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetDataAssessmentPerCompanyQuery,
    ApiGetDataAssessmentPerCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetDataAssessmentPerCompanyQuery,
    ApiGetDataAssessmentPerCompanyQueryVariables
  >(GetDataAssessmentPerCompanyDocument, options);
}
export function useGetDataAssessmentPerCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetDataAssessmentPerCompanyQuery,
    ApiGetDataAssessmentPerCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetDataAssessmentPerCompanyQuery,
    ApiGetDataAssessmentPerCompanyQueryVariables
  >(GetDataAssessmentPerCompanyDocument, options);
}
export type GetDataAssessmentPerCompanyQueryHookResult = ReturnType<
  typeof useGetDataAssessmentPerCompanyQuery
>;
export type GetDataAssessmentPerCompanyLazyQueryHookResult = ReturnType<
  typeof useGetDataAssessmentPerCompanyLazyQuery
>;
export type GetDataAssessmentPerCompanyQueryResult = Apollo.QueryResult<
  ApiGetDataAssessmentPerCompanyQuery,
  ApiGetDataAssessmentPerCompanyQueryVariables
>;
export function refetchGetDataAssessmentPerCompanyQuery(
  variables?: ApiGetDataAssessmentPerCompanyQueryVariables,
) {
  return { query: GetDataAssessmentPerCompanyDocument, variables: variables };
}
export const GetKlearlyUserDocument = gql`
  query GetKlearlyUser {
    me {
      id
      firstName
      lastName
      companyName
      email
      isAdmin
      isKlearlyAdmin
      disabled
      company {
        id
        canSubmitCredentials
        crmTypes
        dataProcessedAt
        dataSources {
          source
          portalId
          portalDomain
          database
          prefix
          stitchId
          companyId
          createdByUserId
          insertedAt
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useGetKlearlyUserQuery__
 *
 * To run a query within a React component, call `useGetKlearlyUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKlearlyUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKlearlyUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKlearlyUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetKlearlyUserQuery,
    ApiGetKlearlyUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetKlearlyUserQuery,
    ApiGetKlearlyUserQueryVariables
  >(GetKlearlyUserDocument, options);
}
export function useGetKlearlyUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetKlearlyUserQuery,
    ApiGetKlearlyUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetKlearlyUserQuery,
    ApiGetKlearlyUserQueryVariables
  >(GetKlearlyUserDocument, options);
}
export type GetKlearlyUserQueryHookResult = ReturnType<
  typeof useGetKlearlyUserQuery
>;
export type GetKlearlyUserLazyQueryHookResult = ReturnType<
  typeof useGetKlearlyUserLazyQuery
>;
export type GetKlearlyUserQueryResult = Apollo.QueryResult<
  ApiGetKlearlyUserQuery,
  ApiGetKlearlyUserQueryVariables
>;
export function refetchGetKlearlyUserQuery(
  variables?: ApiGetKlearlyUserQueryVariables,
) {
  return { query: GetKlearlyUserDocument, variables: variables };
}
export const GetSignalHistoryDocument = gql`
  query GetSignalHistory($filterString: String, $timeRange: TimeRange!) {
    filterString @client @export(as: "filterString")
    signalHistory(filterString: $filterString, timeRange: $timeRange) {
      allAll {
        ...signalHistoryItems
      }
      allResponse {
        ...signalHistoryItems
      }
      allActivity {
        ...signalHistoryItems
      }
      marketingAll {
        ...signalHistoryItems
      }
      marketingResponse {
        ...signalHistoryItems
      }
      marketingActivity {
        ...signalHistoryItems
      }
      salesAll {
        ...signalHistoryItems
      }
      salesResponse {
        ...signalHistoryItems
      }
      salesActivity {
        ...signalHistoryItems
      }
      dateFormat
    }
  }
  ${SignalHistoryItemsFragmentDoc}
`;

/**
 * __useGetSignalHistoryQuery__
 *
 * To run a query within a React component, call `useGetSignalHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignalHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignalHistoryQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *      timeRange: // value for 'timeRange'
 *   },
 * });
 */
export function useGetSignalHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetSignalHistoryQuery,
    ApiGetSignalHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetSignalHistoryQuery,
    ApiGetSignalHistoryQueryVariables
  >(GetSignalHistoryDocument, options);
}
export function useGetSignalHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetSignalHistoryQuery,
    ApiGetSignalHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetSignalHistoryQuery,
    ApiGetSignalHistoryQueryVariables
  >(GetSignalHistoryDocument, options);
}
export type GetSignalHistoryQueryHookResult = ReturnType<
  typeof useGetSignalHistoryQuery
>;
export type GetSignalHistoryLazyQueryHookResult = ReturnType<
  typeof useGetSignalHistoryLazyQuery
>;
export type GetSignalHistoryQueryResult = Apollo.QueryResult<
  ApiGetSignalHistoryQuery,
  ApiGetSignalHistoryQueryVariables
>;
export function refetchGetSignalHistoryQuery(
  variables?: ApiGetSignalHistoryQueryVariables,
) {
  return { query: GetSignalHistoryDocument, variables: variables };
}
export const GetRecommendedActionsDocument = gql`
  query GetRecommendedActions($filterString: String) {
    filterString @client @export(as: "filterString")
    recommendedActions(filterString: $filterString) {
      signalType
      action
      accountCount
      goalCount
      actualCount
      averageRank
    }
  }
`;

/**
 * __useGetRecommendedActionsQuery__
 *
 * To run a query within a React component, call `useGetRecommendedActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendedActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendedActionsQuery({
 *   variables: {
 *      filterString: // value for 'filterString'
 *   },
 * });
 */
export function useGetRecommendedActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiGetRecommendedActionsQuery,
    ApiGetRecommendedActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetRecommendedActionsQuery,
    ApiGetRecommendedActionsQueryVariables
  >(GetRecommendedActionsDocument, options);
}
export function useGetRecommendedActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetRecommendedActionsQuery,
    ApiGetRecommendedActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetRecommendedActionsQuery,
    ApiGetRecommendedActionsQueryVariables
  >(GetRecommendedActionsDocument, options);
}
export type GetRecommendedActionsQueryHookResult = ReturnType<
  typeof useGetRecommendedActionsQuery
>;
export type GetRecommendedActionsLazyQueryHookResult = ReturnType<
  typeof useGetRecommendedActionsLazyQuery
>;
export type GetRecommendedActionsQueryResult = Apollo.QueryResult<
  ApiGetRecommendedActionsQuery,
  ApiGetRecommendedActionsQueryVariables
>;
export function refetchGetRecommendedActionsQuery(
  variables?: ApiGetRecommendedActionsQueryVariables,
) {
  return { query: GetRecommendedActionsDocument, variables: variables };
}
export const GetSignalHistoryForAccountDocument = gql`
  query GetSignalHistoryForAccount(
    $startDate: Date!
    $endDate: Date!
    $accountId: Int!
    $groupBy: [SignalGroupByType]!
  ) {
    signalHistoryForAccount(
      accountId: $accountId
      startDate: $startDate
      endDate: $endDate
      groupBy: $groupBy
    ) {
      allAll {
        ...signalHistory
      }
      allActivity {
        ...signalHistory
      }
      allResponse {
        ...signalHistory
      }
      marketingAll {
        ...signalHistory
      }
      marketingActivity {
        ...signalHistory
      }
      marketingResponse {
        ...signalHistory
      }
      salesAll {
        ...signalHistory
      }
      salesActivity {
        ...signalHistory
      }
      salesResponse {
        ...signalHistory
      }
    }
  }
  ${SignalHistoryFragmentDoc}
`;

/**
 * __useGetSignalHistoryForAccountQuery__
 *
 * To run a query within a React component, call `useGetSignalHistoryForAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignalHistoryForAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignalHistoryForAccountQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      accountId: // value for 'accountId'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useGetSignalHistoryForAccountQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetSignalHistoryForAccountQuery,
    ApiGetSignalHistoryForAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetSignalHistoryForAccountQuery,
    ApiGetSignalHistoryForAccountQueryVariables
  >(GetSignalHistoryForAccountDocument, options);
}
export function useGetSignalHistoryForAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetSignalHistoryForAccountQuery,
    ApiGetSignalHistoryForAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetSignalHistoryForAccountQuery,
    ApiGetSignalHistoryForAccountQueryVariables
  >(GetSignalHistoryForAccountDocument, options);
}
export type GetSignalHistoryForAccountQueryHookResult = ReturnType<
  typeof useGetSignalHistoryForAccountQuery
>;
export type GetSignalHistoryForAccountLazyQueryHookResult = ReturnType<
  typeof useGetSignalHistoryForAccountLazyQuery
>;
export type GetSignalHistoryForAccountQueryResult = Apollo.QueryResult<
  ApiGetSignalHistoryForAccountQuery,
  ApiGetSignalHistoryForAccountQueryVariables
>;
export function refetchGetSignalHistoryForAccountQuery(
  variables?: ApiGetSignalHistoryForAccountQueryVariables,
) {
  return { query: GetSignalHistoryForAccountDocument, variables: variables };
}
export const GetAggregateMessagesDocument = gql`
  query GetAggregateMessages($dateFrom: Date!, $dateTo: Date, $interval: Int) {
    aggregateMessages(
      dateFrom: $dateFrom
      dateTo: $dateTo
      interval: $interval
    ) {
      accounts {
        id
      }
      body
      count
      dateFrom
      dateTo
      name
      type
      tags
    }
  }
`;

/**
 * __useGetAggregateMessagesQuery__
 *
 * To run a query within a React component, call `useGetAggregateMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateMessagesQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useGetAggregateMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ApiGetAggregateMessagesQuery,
    ApiGetAggregateMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ApiGetAggregateMessagesQuery,
    ApiGetAggregateMessagesQueryVariables
  >(GetAggregateMessagesDocument, options);
}
export function useGetAggregateMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiGetAggregateMessagesQuery,
    ApiGetAggregateMessagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ApiGetAggregateMessagesQuery,
    ApiGetAggregateMessagesQueryVariables
  >(GetAggregateMessagesDocument, options);
}
export type GetAggregateMessagesQueryHookResult = ReturnType<
  typeof useGetAggregateMessagesQuery
>;
export type GetAggregateMessagesLazyQueryHookResult = ReturnType<
  typeof useGetAggregateMessagesLazyQuery
>;
export type GetAggregateMessagesQueryResult = Apollo.QueryResult<
  ApiGetAggregateMessagesQuery,
  ApiGetAggregateMessagesQueryVariables
>;
export function refetchGetAggregateMessagesQuery(
  variables?: ApiGetAggregateMessagesQueryVariables,
) {
  return { query: GetAggregateMessagesDocument, variables: variables };
}

export enum MessageTag {
  Alert = 'ALERT',
  Change = 'CHANGE',
  Decrease = 'DECREASE',
  Engagement = 'ENGAGEMENT',
  Increase = 'INCREASE',
  Intensity = 'INTENSITY',
  Outreach = 'OUTREACH',
  Pause = 'PAUSE',
  Pursue = 'PURSUE',
  Status = 'STATUS',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}
export type AccountCountGrouping = {
  __typename?: 'AccountCountGrouping';
  currentAccountScoreBucket?: Maybe<AccountScoreBucket>;
  possibleAccountScoreBucket?: Maybe<AccountScoreBucket>;
  signalType?: Maybe<Scalars['String']>;
  accountCount?: Maybe<Scalars['Int']>;
};
export type TimeRange = {
  intervalDays: Scalars['Int'];
  daysPast: Scalars['Int'];
  daysFuture: Scalars['Int'];
};
export type DataSource = {
  __typename?: 'DataSource';
  source?: Maybe<CrmTypeEnum>;
  portalId?: Maybe<Scalars['String']>;
  portalDomain?: Maybe<Scalars['String']>;
  database?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  stitchId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  createdByUserId?: Maybe<Scalars['Int']>;
  insertedAt?: Maybe<Scalars['NaiveDateTime']>;
  updatedAt?: Maybe<Scalars['NaiveDateTime']>;
};
export type DataReport = {
  __typename?: 'DataReport';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  queryText?: Maybe<Scalars['String']>;
  data?: Maybe<DataReportData>;
};

/** CRM Type Enum */
export enum CrmTypeEnum {
  Hubspot = 'HUBSPOT',
  Marketo = 'MARKETO',
  Pardot = 'PARDOT',
  Pendo = 'PENDO',
  Salesforce = 'SALESFORCE',
  Sftp = 'SFTP',
}
/** Input object for creating a data source */
export type DataSourceConfigInput = {
  companyName?: InputMaybe<Scalars['String']>;
  dataSource: CrmTypeEnum;
  hubspotConfig?: InputMaybe<HubspotInput>;
  salesforceConfig?: InputMaybe<SalesforceInput>;
  marketoConfig?: InputMaybe<MarketoInput>;
  pendoConfig?: InputMaybe<PendoInput>;
  pardotConfig?: InputMaybe<PardotInput>;
};
/** Input object for updating an account group filter and the corresponding accounts */
export type UpdateAccountGroupFilterInput = {
  /** The account group id */
  id: Scalars['Int'];
  /** The filter string */
  filterString: Scalars['String'];
  /** The associated account ids */
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};
export type AccountGroupPayload = {
  __typename?: 'AccountGroupPayload';
  accountGroup?: Maybe<AccountGroup>;
};
/** Input object for google secrets */
export type GoogleSecretInput = {
  /** Company for the credentials */
  companyName: Scalars['String'];
  /** CRM user's email */
  email: Scalars['String'];
  /** CRM user's password */
  password: Scalars['String'];
  /** CRM Type */
  crmType: CrmTypeEnum;
  /** Marketo Client ID */
  marketoClientId?: InputMaybe<Scalars['String']>;
  /** Marketo Client Secret */
  marketoClientSecret?: InputMaybe<Scalars['String']>;
  /** Marketo Endpoint Base URL */
  marketoEndpointBaseUrl?: InputMaybe<Scalars['String']>;
  /** CRM contact person's name */
  contactPersonName?: InputMaybe<Scalars['String']>;
  /** CRM contact person's email */
  contactPersonEmail?: InputMaybe<Scalars['String']>;
};
export type AddUserError = {
  __typename?: 'AddUserError';
  email: Scalars['String'];
  message: Scalars['String'];
};
export enum AccountGroupType {
  All = 'ALL',
  Segment = 'SEGMENT',
  List = 'LIST',
}
export type SignalTotalForAccountByDate = {
  __typename?: 'SignalTotalForAccountByDate';
  date: Scalars['Date'];
  total: Scalars['Int'];
};
export type HubspotInput = {
  oauthCode: Scalars['String'];
};
/** Input object for updating the accounts associated to the account group */
export type UpdateAccountGroupAccountsInput = {
  /** The account group id */
  id: Scalars['Int'];
  /** The associated account ids */
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};
export type AccountPredictions = {
  __typename?: 'AccountPredictions';
  dateFormat: Scalars['String'];
  currentPredictions?: Maybe<Array<Maybe<CurrentPrediction>>>;
  expectedPredictions?: Maybe<Array<Maybe<ExpectedPrediction>>>;
  goalPredictions?: Maybe<Array<Maybe<GoalPrediction>>>;
};
export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  authUserId: Scalars['String'];
  companyName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isKlearlyAdmin?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  disabled: Scalars['Boolean'];
  company?: Maybe<Company>;
};
export type PageInfo = {
  __typename?: 'PageInfo';
  totalPages: Scalars['Int'];
  totalEntries: Scalars['Int'];
};
export type GoalPrediction = {
  __typename?: 'GoalPrediction';
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  value?: Maybe<Scalars['Float']>;
  isPriority?: Maybe<Scalars['Boolean']>;
};
export type RecommendedAction = {
  __typename?: 'RecommendedAction';
  signalType: Scalars['String'];
  action: Scalars['String'];
  accountCount: Scalars['Int'];
  goalCount: Scalars['Int'];
  actualCount: Scalars['Int'];
  averageRank: Scalars['Float'];
};
export type FilterConfigItem = {
  __typename?: 'FilterConfigItem';
  key: Scalars['String'];
  type: Scalars['String'];
  aggregateType?: Maybe<Scalars['String']>;
  table?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['Json']>;
  ranges?: Maybe<Array<Maybe<Scalars['Float']>>>;
  enum?: Maybe<Scalars['Boolean']>;
};
/** Input object for sorting an account list */
export type AccountSortItem = {
  field: AccountField;
  direction: Sort;
};
export type SignalPrediction = {
  __typename?: 'SignalPrediction';
  calculatedAtDate: Scalars['Date'];
  calculatedForDate: Scalars['Date'];
  expectedLow?: Maybe<Scalars['Int']>;
  expectedMiddle?: Maybe<Scalars['Int']>;
  expectedHigh?: Maybe<Scalars['Int']>;
  goal?: Maybe<Scalars['Int']>;
  signalType?: Maybe<Scalars['String']>;
  recommendation?: Maybe<Scalars['String']>;
};
export type SignalHistoryItem = {
  __typename?: 'SignalHistoryItem';
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  accountCount: Scalars['Int'];
  signalCount: Scalars['Int'];
};
export type Message = {
  __typename?: 'Message';
  id: Scalars['Int'];
  accountId: Scalars['Int'];
  type: MessageType;
  date: Scalars['Date'];
  occurredAt: Scalars['NaiveDateTime'];
  name: Scalars['String'];
  body: Scalars['String'];
  tags?: Maybe<Array<Maybe<MessageTag>>>;
};
export type PardotInput = {
  oauthCode: Scalars['String'];
  pardotBusinessUnitId: Scalars['String'];
};
/** Input object for updating the account group's is_hidden field */
export type UpdateAccountGroupIsHiddenInput = {
  /** The account group id */
  id: Scalars['Int'];
  /** Value of is hidden */
  isHidden: Scalars['Boolean'];
};
export type PredictionExpected = {
  __typename?: 'PredictionExpected';
  low?: Maybe<Scalars['Float']>;
  middle?: Maybe<Scalars['Float']>;
  high?: Maybe<Scalars['Float']>;
};
export enum AccountScoreBucket {
  VeryUnlikely = 'VERY_UNLIKELY',
  Unlikely = 'UNLIKELY',
  Tossup = 'TOSSUP',
  Likely = 'LIKELY',
  VeryLikely = 'VERY_LIKELY',
}
export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** List of recommended actions and corresponding signal actuals */
  recommendedActions?: Maybe<Array<Maybe<RecommendedAction>>>;
  /** All industries for the current user's company */
  industries?: Maybe<Array<Maybe<Industry>>>;
  dataAssessment?: Maybe<Array<Maybe<DataReport>>>;
  oauthToken?: Maybe<OauthToken>;
  metabaseEmbedUrl?: Maybe<Scalars['String']>;
  /** Returns signal history for the given dates grouped by source and direction */
  signalHistory?: Maybe<SignalHistory>;
  /** Returns signal history for the given account id, start and dates, and grouping parameters. By default the results are grouped by signal date, source, and direction */
  signalHistoryForAccount?: Maybe<SignalHistoryForAccount>;
  /** Returns all signals for the given account id for a given start and end date */
  accountSignals?: Maybe<Array<Maybe<Signal>>>;
  /** Returns all signals for a list of given account ids for a given start and end date */
  multiAccountSignals?: Maybe<Array<Maybe<Signal>>>;
  /** Account groups for the given account */
  accountGroupsForAccount?: Maybe<Array<Maybe<AccountGroup>>>;
  /** The account count given the set of group by fields */
  accountCountByGrouping?: Maybe<Array<Maybe<AccountCountGrouping>>>;
  /** Account & prediction data for a single account */
  account?: Maybe<Account>;
  /** Multiple accounts with prediction data */
  accountsWithPredictions?: Maybe<Array<Maybe<Account>>>;
  /** Multiple accounts without prediction data */
  accounts?: Maybe<PaginatedAccounts>;
  /** Accounts returned when searching for one */
  accountsForSearch: Array<Maybe<AccountForSearchPayload>>;
  /** Predictions for a given account and a given timeline */
  accountPredictions?: Maybe<AccountPredictions>;
  /** A single account group by id */
  accountGroup?: Maybe<AccountGroup>;
  /** All account groups for a company */
  accountGroups?: Maybe<Array<Maybe<AccountGroup>>>;
  dataSources?: Maybe<Array<Maybe<DataSource>>>;
  /** Information about logged in user */
  me?: Maybe<User>;
  /** Users who can log into Klearly */
  companyUsers?: Maybe<Array<Maybe<User>>>;
  /** Companies aka Klearly's customers */
  companies?: Maybe<Array<Maybe<Company>>>;
  /** Aggregate data */
  aggregateData?: Maybe<Scalars['Json']>;
  /** Aggregate data results for a single field */
  aggregateDataForField: Array<Maybe<AggregateDataSearchPayload>>;
  /** Aggregate data results returned from search */
  aggregateDataSearch: Array<Maybe<AggregateDataSearchPayload>>;
  /** Filter configuration details for the company of the logged in user */
  filterConfig?: Maybe<Array<Maybe<FilterConfigItem>>>;
  aggregateMessages?: Maybe<Array<Maybe<AggregateMessage>>>;
  aggregateMessage?: Maybe<AggregateMessage>;
};
export type RootQueryTypeRecommendedActionsArgs = {
  filterString?: InputMaybe<Scalars['String']>;
};
export type RootQueryTypeDataAssessmentArgs = {
  company?: InputMaybe<Scalars['String']>;
};
export type RootQueryTypeOauthTokenArgs = {
  companyName: Scalars['String'];
  dataSource: CrmTypeEnum;
};
export type RootQueryTypeSignalHistoryArgs = {
  timeRange: TimeRange;
  filterString?: InputMaybe<Scalars['String']>;
};
export type RootQueryTypeSignalHistoryForAccountArgs = {
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  accountId: Scalars['Int'];
  groupBy: Array<InputMaybe<SignalGroupByType>>;
};
export type RootQueryTypeAccountSignalsArgs = {
  accountId: Scalars['Int'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};
export type RootQueryTypeMultiAccountSignalsArgs = {
  accountIds: Array<InputMaybe<Scalars['Int']>>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};
export type RootQueryTypeAccountGroupsForAccountArgs = {
  accountId: Scalars['Int'];
  accountGroupType?: InputMaybe<AccountGroupType>;
};
export type RootQueryTypeAccountCountByGroupingArgs = {
  filterString?: InputMaybe<Scalars['String']>;
  groupBy?: InputMaybe<Array<InputMaybe<AccountCountGroupBy>>>;
};
export type RootQueryTypeAccountArgs = {
  id: Scalars['Int'];
};
export type RootQueryTypeAccountsWithPredictionsArgs = {
  filterString?: InputMaybe<Scalars['String']>;
};
export type RootQueryTypeAccountsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<AccountSortItem>>>;
  filterString?: InputMaybe<Scalars['String']>;
  messageCountDaysAgo?: InputMaybe<Scalars['Int']>;
  filterStringAlternate?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
};
export type RootQueryTypeAccountsForSearchArgs = {
  query: Scalars['String'];
  limitToOpenAccountsWithPredictions?: InputMaybe<Scalars['Boolean']>;
};
export type RootQueryTypeAccountPredictionsArgs = {
  accountId: Scalars['Int'];
  timeRange: TimeRange;
};
export type RootQueryTypeAccountGroupArgs = {
  id: Scalars['Int'];
};
export type RootQueryTypeAccountGroupsArgs = {
  filterHidden?: InputMaybe<Scalars['Boolean']>;
};
export type RootQueryTypeAggregateDataArgs = {
  type: Scalars['String'];
  filterString?: InputMaybe<Scalars['String']>;
};
export type RootQueryTypeAggregateDataForFieldArgs = {
  type: Scalars['String'];
  field: Scalars['String'];
  filterString?: InputMaybe<Scalars['String']>;
};
export type RootQueryTypeAggregateDataSearchArgs = {
  type: Scalars['String'];
  field: Scalars['String'];
  query?: InputMaybe<Scalars['String']>;
  filterString?: InputMaybe<Scalars['String']>;
};
export type RootQueryTypeFilterConfigArgs = {
  type?: InputMaybe<Scalars['String']>;
};
export type RootQueryTypeAggregateMessagesArgs = {
  dateFrom: Scalars['Date'];
  dateTo?: InputMaybe<Scalars['Date']>;
  interval?: InputMaybe<Scalars['Int']>;
};
export type RootQueryTypeAggregateMessageArgs = {
  name: Scalars['String'];
  dateFrom: Scalars['Date'];
  dateTo?: InputMaybe<Scalars['Date']>;
};
export type Industry = {
  __typename?: 'Industry';
  name: Scalars['String'];
};
export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC',
}
/** Input object for user update */
export type UserInputUpdate = {
  /** Company name for the company the user is associated to */
  companyName: Scalars['String'];
  /** Email of the user */
  email: Scalars['String'];
  /** First name of the user */
  firstName?: InputMaybe<Scalars['String']>;
  /** Last name of the user */
  lastName?: InputMaybe<Scalars['String']>;
  /** If the user is an admin */
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  /** If the user is a Klearly admin */
  isKlearlyAdmin?: InputMaybe<Scalars['Boolean']>;
  /** If the user disabled */
  disabled?: InputMaybe<Scalars['Boolean']>;
  /** Role of the user */
  role?: InputMaybe<Scalars['String']>;
};
/** Enqueue object for internal ETL */
export type InternalEtlInput = {
  /** The company to do internal ETL on */
  company: Scalars['String'];
  /** The tables (optional) to do internal ETL for */
  tables?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The earliest date to get data for. Format YYYY-0M-0D */
  dateFrom: Scalars['String'];
};
export type LowMiddleHighFloat = {
  __typename?: 'LowMiddleHighFloat';
  low?: Maybe<Scalars['Float']>;
  middle?: Maybe<Scalars['Float']>;
  high?: Maybe<Scalars['Float']>;
};
export type PaginatedAccounts = {
  __typename?: 'PaginatedAccounts';
  accounts: Array<Maybe<Account>>;
  pageInfo: PageInfo;
};
export type Signal = {
  __typename?: 'Signal';
  id: Scalars['Int'];
  accountId: Scalars['Int'];
  crmAccountId: Scalars['String'];
  type: Scalars['String'];
  typeDetail?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  topicDetail?: Maybe<Scalars['String']>;
  groupKey?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
  action: Scalars['String'];
  direction: Scalars['String'];
  source: Scalars['String'];
  occurredAt?: Maybe<Scalars['DateTime']>;
  insertedAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};
/** Input object for company update */
export type CompanyInputUpdate = {
  /** Name for the company */
  companyName: Scalars['String'];
  /** Name of CRM Types */
  crmTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Name of Can Submit Credentials */
  canSubmitCredentials?: InputMaybe<Scalars['Boolean']>;
};
export type SalesforceInput = {
  oauthCode: Scalars['String'];
};
export type PendoInput = {
  integrationKey: Scalars['String'];
};
export type AccountForSearchPayload = {
  __typename?: 'AccountForSearchPayload';
  id: Scalars['Int'];
  name: Scalars['String'];
  contactCount: Scalars['Int'];
  crmAccountId?: Maybe<Scalars['String']>;
};
export type AddUsersPayload = {
  __typename?: 'AddUsersPayload';
  users: Array<Maybe<User>>;
  errors: Array<Maybe<AddUserError>>;
};
export type CalculatedFor = {
  __typename?: 'CalculatedFor';
  type?: Maybe<PredictionForType>;
  name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
};
export type SignalHistoryForAccountItem = {
  __typename?: 'SignalHistoryForAccountItem';
  signalDetails: Array<Maybe<SignalDetailsForAccountItem>>;
  signalTotalByDate: Array<Maybe<SignalTotalForAccountByDate>>;
};
/** Schedule object for ML prediction */
export type MlPredictionInput = {
  /** The company to do ML prediction on */
  company: Scalars['String'];
  /** The start date for ML prediction */
  dateFrom: Scalars['String'];
  /** The end date for ML prediction */
  dateTo: Scalars['String'];
  /** Optional account ids to limit to */
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};
/** Return object after creating a data source */
export type CreateDataSourcePayload = {
  __typename?: 'CreateDataSourcePayload';
  sourceId?: Maybe<Scalars['String']>;
};
export enum XeominPurchaseTrend {
  NeverPurchased = 'NEVER_PURCHASED',
  NewAccount = 'NEW_ACCOUNT',
  NoRecentSale = 'NO_RECENT_SALE',
  FirstRecentSale = 'FIRST_RECENT_SALE',
  TrendingUp = 'TRENDING_UP',
  TrendingDown = 'TRENDING_DOWN',
  TrendingFlat = 'TRENDING_FLAT',
  Steady = 'STEADY',
}
export type HideObjectsPayload = {
  __typename?: 'HideObjectsPayload';
  companyName?: Maybe<Scalars['String']>;
  objectType?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['Int']>;
};
export type PredictionFilter = {
  /** Filter by a list of types */
  calculatedForType?: InputMaybe<Array<InputMaybe<PredictionForType>>>;
  /** Filter by a list of names */
  calculatedForName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Most recent predictions calculated before a given date */
  calculatedAtBefore?: InputMaybe<Scalars['Date']>;
};
export type DataReportData = {
  __typename?: 'DataReportData';
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  rows?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};
export enum XeominPredictionSummary {
  LowProspect = 'LOW_PROSPECT',
  MediumProspect = 'MEDIUM_PROSPECT',
  HighProspect = 'HIGH_PROSPECT',
  LowCrossSell = 'LOW_CROSS_SELL',
  MediumCrossSell = 'MEDIUM_CROSS_SELL',
  HighCrossSell = 'HIGH_CROSS_SELL',
  UnlikelyReorder = 'UNLIKELY_REORDER',
  SomewhatLikelyReorder = 'SOMEWHAT_LIKELY_REORDER',
  LikelyReorder = 'LIKELY_REORDER',
  UpSell = 'UP_SELL',
  Retain = 'RETAIN',
}
export type SignalHistory = {
  __typename?: 'SignalHistory';
  allAll: SignalHistoryCategory;
  allActivity: SignalHistoryCategory;
  allResponse: SignalHistoryCategory;
  marketingResponse: SignalHistoryCategory;
  marketingActivity: SignalHistoryCategory;
  marketingAll: SignalHistoryCategory;
  salesResponse: SignalHistoryCategory;
  salesActivity: SignalHistoryCategory;
  salesAll: SignalHistoryCategory;
  dateFormat: Scalars['String'];
};
export type AggregateDataSearchPayload = {
  __typename?: 'AggregateDataSearchPayload';
  value: Scalars['String'];
  filteredCount: Scalars['Int'];
  totalCount: Scalars['Int'];
};
export type SignalHistoryForAccount = {
  __typename?: 'SignalHistoryForAccount';
  allAll: SignalHistoryForAccountItem;
  allActivity: SignalHistoryForAccountItem;
  allResponse: SignalHistoryForAccountItem;
  marketingResponse: SignalHistoryForAccountItem;
  marketingActivity: SignalHistoryForAccountItem;
  marketingAll: SignalHistoryForAccountItem;
  salesResponse: SignalHistoryForAccountItem;
  salesActivity: SignalHistoryForAccountItem;
  salesAll: SignalHistoryForAccountItem;
  dateFormat: Scalars['String'];
};
export enum PredictionForType {
  Acquisition = 'ACQUISITION',
  Fit = 'FIT',
  Retention = 'RETENTION',
  Volume = 'VOLUME',
}
export type Narrative = {
  __typename?: 'Narrative';
  primaryAction: Scalars['String'];
  xeominPrediction?: Maybe<Array<Maybe<Scalars['String']>>>;
  xeominVolume?: Maybe<Array<Maybe<Scalars['String']>>>;
};
export type LowMiddleHighInteger = {
  __typename?: 'LowMiddleHighInteger';
  low?: Maybe<Scalars['Int']>;
  middle?: Maybe<Scalars['Int']>;
  high?: Maybe<Scalars['Int']>;
};
export type Company = {
  __typename?: 'Company';
  id: Scalars['Int'];
  companyName: Scalars['String'];
  updatedAt: Scalars['NaiveDateTime'];
  insertedAt: Scalars['NaiveDateTime'];
  canSubmitCredentials: Scalars['Boolean'];
  crmTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataProcessedAt?: Maybe<Scalars['NaiveDateTime']>;
};
export enum AccountCountGroupBy {
  PossibleAccountScoreBucket = 'POSSIBLE_ACCOUNT_SCORE_BUCKET',
  CurrentAccountScoreBucket = 'CURRENT_ACCOUNT_SCORE_BUCKET',
  SignalType = 'SIGNAL_TYPE',
}
export type AggregateMessage = {
  __typename?: 'AggregateMessage';
  type: MessageType;
  name: Scalars['String'];
  body: Scalars['String'];
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  count: Scalars['Int'];
  tags?: Maybe<Array<Maybe<MessageTag>>>;
  accounts?: Maybe<Array<Maybe<Account>>>;
};
export type OrderFrequency = {
  __typename?: 'OrderFrequency';
  accountId?: Maybe<Scalars['Int']>;
  calculatedForName?: Maybe<Scalars['String']>;
  regularity?: Maybe<Scalars['String']>;
  lastOrderDate?: Maybe<Scalars['Date']>;
  minDate?: Maybe<Scalars['NaiveDateTime']>;
  maxDate?: Maybe<Scalars['NaiveDateTime']>;
  meanDate?: Maybe<Scalars['NaiveDateTime']>;
  medianDate?: Maybe<Scalars['NaiveDateTime']>;
  contMedianDate?: Maybe<Scalars['NaiveDateTime']>;
  orderFrequency?: Maybe<Scalars['String']>;
};
export type Account = {
  __typename?: 'Account';
  id: Scalars['Int'];
  crmAccountId: Scalars['String'];
  accountName?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  industry?: Maybe<Scalars['String']>;
  employeeCount?: Maybe<Scalars['String']>;
  annualRevenue?: Maybe<Scalars['String']>;
  addressStreet?: Maybe<Scalars['String']>;
  addressCity?: Maybe<Scalars['String']>;
  addressRegion?: Maybe<Scalars['String']>;
  addressZipCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  customerStatus?: Maybe<AccountCustomerStatus>;
  xeominPurchaseTrendShort?: Maybe<XeominPurchaseTrend>;
  xeominPurchaseTrendLong?: Maybe<Scalars['String']>;
  xeominAverageQuantity?: Maybe<Scalars['Float']>;
  xeominSpendFraction?: Maybe<Scalars['Float']>;
  xeominPredictionSummary?: Maybe<XeominPredictionSummary>;
  predictions?: Maybe<Array<Maybe<AccountPrediction>>>;
  lastInboundSignal?: Maybe<Signal>;
  lastOutboundSignal?: Maybe<Signal>;
  currentValue?: Maybe<Scalars['Float']>;
  currentAccountScore?: Maybe<Scalars['Int']>;
  engagementStatus?: Maybe<EngagementStatus>;
  outreachStatus?: Maybe<OutreachStatus>;
  signalResponseIntensity?: Maybe<SignalIntensity>;
  signalActivityIntensity?: Maybe<SignalIntensity>;
  firstClosedOpportunityAt?: Maybe<Scalars['DateTime']>;
  contactCount?: Maybe<Scalars['Int']>;
  openOpportunityCount?: Maybe<Scalars['Int']>;
  wonOpportunityCount?: Maybe<Scalars['Int']>;
  lostOpportunityCount?: Maybe<Scalars['Int']>;
  expectedValue?: Maybe<PredictionExpected>;
  predictionCalculatedForType?: Maybe<PredictionForType>;
  predictionCalculatedForName?: Maybe<Scalars['String']>;
  goalValues?: Maybe<Array<Maybe<PredictionGoal>>>;
  rankAll?: Maybe<Scalars['Int']>;
  rankPriority?: Maybe<Scalars['Int']>;
  predictionLevel?: Maybe<Scalars['Int']>;
  xeominStalePrediction?: Maybe<Scalars['Boolean']>;
  messageCount?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Maybe<Message>>>;
  narratives?: Maybe<Narrative>;
  orderFrequencies?: Maybe<Array<Maybe<OrderFrequency>>>;
};
export type AccountPredictionsArgs = {
  current?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<PredictionFilter>;
};
export type AccountMessagesArgs = {
  daysAgo?: InputMaybe<Scalars['Int']>;
};
export enum EngagementStatus {
  Unengaged = 'UNENGAGED',
  Engaged = 'ENGAGED',
  Disengaging = 'DISENGAGING',
  Disengaged = 'DISENGAGED',
}
export type CurrentPrediction = {
  __typename?: 'CurrentPrediction';
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  value: Scalars['Float'];
};
export type ExpectedPrediction = {
  __typename?: 'ExpectedPrediction';
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  valueLow?: Maybe<Scalars['Float']>;
  valueMiddle?: Maybe<Scalars['Float']>;
  valueHigh?: Maybe<Scalars['Float']>;
};
export type AccountPrediction = {
  __typename?: 'AccountPrediction';
  id?: Maybe<Scalars['Int']>;
  accountId?: Maybe<Scalars['Int']>;
  currentValue?: Maybe<Scalars['Float']>;
  calculatedAtDate?: Maybe<Scalars['Date']>;
  level?: Maybe<Scalars['Int']>;
  calculatedFor?: Maybe<CalculatedFor>;
  expectedValue?: Maybe<LowMiddleHighFloat>;
  expectedSignalPriorityCount?: Maybe<LowMiddleHighInteger>;
  insertedAt?: Maybe<Scalars['NaiveDateTime']>;
  updatedAt?: Maybe<Scalars['NaiveDateTime']>;
  recommendedPriorityAction?: Maybe<Scalars['String']>;
};
export type MarketoInput = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  endpoint: Scalars['String'];
  identity: Scalars['String'];
};
export enum AccountCustomerStatus {
  Customer = 'CUSTOMER',
  PastCustomer = 'PAST_CUSTOMER',
  Prospect = 'PROSPECT',
}
export type RootMutationType = {
  __typename?: 'RootMutationType';
  createGoogleSecretVersion?: Maybe<GoogleSecret>;
  hideObjects?: Maybe<HideObjectsPayload>;
  addAccountGroup?: Maybe<AccountGroupPayload>;
  updateAccountGroupFilter?: Maybe<AccountGroupPayload>;
  updateAccountGroupAccounts?: Maybe<AccountGroupPayload>;
  updateAccountGroupDescriptors?: Maybe<AccountGroupPayload>;
  updateAccountGroupIsHidden?: Maybe<AccountGroupPayload>;
  addUser?: Maybe<User>;
  addUsers?: Maybe<AddUsersPayload>;
  updateUser?: Maybe<User>;
  updateUserCompany?: Maybe<User>;
  createDataSource?: Maybe<CreateDataSourcePayload>;
  addCompany?: Maybe<Company>;
  updateCompany?: Maybe<Company>;
  enqueueInternalEtl?: Maybe<ObanJob>;
  enqueueEtlPipeline?: Maybe<ObanJob>;
  enqueueMlPrediction?: Maybe<ObanJob>;
};
export type RootMutationTypeCreateGoogleSecretVersionArgs = {
  googleSecret: GoogleSecretInput;
};
export type RootMutationTypeHideObjectsArgs = {
  input: HideObjectsInput;
};
export type RootMutationTypeAddAccountGroupArgs = {
  input: AddAccountGroupInput;
};
export type RootMutationTypeUpdateAccountGroupFilterArgs = {
  input: UpdateAccountGroupFilterInput;
};
export type RootMutationTypeUpdateAccountGroupAccountsArgs = {
  input: UpdateAccountGroupAccountsInput;
};
export type RootMutationTypeUpdateAccountGroupDescriptorsArgs = {
  input: UpdateAccountGroupDescriptorsInput;
};
export type RootMutationTypeUpdateAccountGroupIsHiddenArgs = {
  input: UpdateAccountGroupIsHiddenInput;
};
export type RootMutationTypeAddUserArgs = {
  user: UserInput;
};
export type RootMutationTypeAddUsersArgs = {
  users: Array<InputMaybe<UserInput>>;
};
export type RootMutationTypeUpdateUserArgs = {
  user: UserInputUpdate;
};
export type RootMutationTypeUpdateUserCompanyArgs = {
  companyName: Scalars['String'];
};
export type RootMutationTypeCreateDataSourceArgs = {
  dataSourceConfig: DataSourceConfigInput;
};
export type RootMutationTypeAddCompanyArgs = {
  company: CompanyInput;
};
export type RootMutationTypeUpdateCompanyArgs = {
  company: CompanyInputUpdate;
};
export type RootMutationTypeEnqueueInternalEtlArgs = {
  internalEtlInput: InternalEtlInput;
};
export type RootMutationTypeEnqueueEtlPipelineArgs = {
  pipelineInput: PipelineInput;
};
export type RootMutationTypeEnqueueMlPredictionArgs = {
  mlPredictionInput: MlPredictionInput;
};
export type OauthToken = {
  __typename?: 'OauthToken';
  tokenType: Scalars['String'];
  token: Scalars['String'];
  expiresAt?: Maybe<Scalars['NaiveDateTime']>;
  expiresIn?: Maybe<Scalars['Int']>;
};
export enum MessageType {
  Insight = 'INSIGHT',
}
export enum AccountField {
  AccountName = 'ACCOUNT_NAME',
  CustomerStatus = 'CUSTOMER_STATUS',
  EngagementStatus = 'ENGAGEMENT_STATUS',
  OutreachStatus = 'OUTREACH_STATUS',
  SignalResponseIntensity = 'SIGNAL_RESPONSE_INTENSITY',
  SignalActivityIntensity = 'SIGNAL_ACTIVITY_INTENSITY',
  RankPriority = 'RANK_PRIORITY',
  MessageCount = 'MESSAGE_COUNT',
  XeominAverageQuantity = 'XEOMIN_AVERAGE_QUANTITY',
  XeominPurchaseTrendShort = 'XEOMIN_PURCHASE_TREND_SHORT',
  XeominPurchaseTrendLong = 'XEOMIN_PURCHASE_TREND_LONG',
  XeominSpendFraction = 'XEOMIN_SPEND_FRACTION',
  XeominPredictionSummary = 'XEOMIN_PREDICTION_SUMMARY',
}
export type GoogleSecret = {
  __typename?: 'GoogleSecret';
  message: Scalars['String'];
};
/** Input object for marking a list of objects as hidden */
export type HideObjectsInput = {
  companyName: Scalars['String'];
  objectType: Scalars['String'];
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  hidden?: InputMaybe<Scalars['Boolean']>;
};
export enum SignalIntensity {
  WellBelowAverage = 'WELL_BELOW_AVERAGE',
  BelowAverage = 'BELOW_AVERAGE',
  Average = 'AVERAGE',
  AboveAverage = 'ABOVE_AVERAGE',
  WellAboveAverage = 'WELL_ABOVE_AVERAGE',
  None = 'NONE',
}
/** Input object for adding an account group */
export type AddAccountGroupInput = {
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  filterString?: InputMaybe<Scalars['String']>;
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};
/** Input object for user */
export type UserInput = {
  /** Company name for the company the user is associated to */
  companyName: Scalars['String'];
  /** Email of the user */
  email: Scalars['String'];
  /** First name of the user */
  firstName: Scalars['String'];
  /** Last name of the user */
  lastName: Scalars['String'];
  /** If the user is an admin */
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  /** If the user is a Klearly admin */
  isKlearlyAdmin?: InputMaybe<Scalars['Boolean']>;
  /** If the user disabled */
  disabled?: InputMaybe<Scalars['Boolean']>;
  /** Role of the user */
  role?: InputMaybe<Scalars['String']>;
  /** Send email */
  sendEmail?: InputMaybe<Scalars['Boolean']>;
};
export type ObanJob = {
  __typename?: 'ObanJob';
  id: Scalars['Int'];
  args: Scalars['String'];
  errors: Scalars['String'];
  insertedAt: Scalars['DateTime'];
  attemptedAt: Scalars['DateTime'];
  scheduledAt: Scalars['DateTime'];
  completedAt: Scalars['DateTime'];
  state: Scalars['String'];
  worker: Scalars['String'];
  attempt: Scalars['Int'];
  executionTimeMs?: Maybe<Scalars['Int']>;
  rows?: Maybe<Scalars['Int']>;
  breakdown?: Maybe<Scalars['String']>;
};
export type GetAccountWithPredictionsQueryVariables = Exact<{
  id: Scalars['Int'];
  predictionTypes?: InputMaybe<Array<PredictionForType> | PredictionForType>;
  predictionNames?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >;
  recentPredictionDate?: InputMaybe<Scalars['Date']>;
}>;
export type GetAccountWithPredictionsQuery = {
  __typename?: 'RootQueryType';
  account?: {
    __typename: 'Account';
    id: number;
    crmAccountId: string;
    accountName?: string | null;
    orderFrequencies?: Array<{
      __typename?: 'OrderFrequency';
      accountId?: number | null;
      calculatedForName?: string | null;
      regularity?: any | null;
      lastOrderDate?: any | null;
      minDate?: any | null;
      maxDate?: any | null;
      meanDate?: any | null;
      medianDate?: any | null;
      contMedianDate?: any | null;
      orderFrequency?: string | null;
    } | null> | null;
    engagementStatus?: EngagementStatus | null;
    currentPredictions?: Array<{
      __typename?: 'AccountPrediction';
      id?: number | null;
      accountId?: number | null;
      currentValue?: number | null;
      level?: number | null;
      recommendedPriorityAction?: string | null;
      calculatedAtDate?: any | null;
      insertedAt?: any | null;
      updatedAt?: any | null;
      calculatedFor?: {
        __typename?: 'CalculatedFor';
        type?: PredictionForType | null;
        name?: string | null;
        date?: any | null;
      } | null;
      expectedValue?: {
        __typename?: 'LowMiddleHighFloat';
        low?: number | null;
        middle?: number | null;
        high?: number | null;
      } | null;
    } | null> | null;
    recentPredictions?: Array<{
      __typename?: 'AccountPrediction';
      id?: number | null;
      accountId?: number | null;
      currentValue?: number | null;
      level?: number | null;
      recommendedPriorityAction?: string | null;
      calculatedAtDate?: any | null;
      insertedAt?: any | null;
      updatedAt?: any | null;
      calculatedFor?: {
        __typename?: 'CalculatedFor';
        type?: PredictionForType | null;
        name?: string | null;
        date?: any | null;
      } | null;
      expectedValue?: {
        __typename?: 'LowMiddleHighFloat';
        low?: number | null;
        middle?: number | null;
        high?: number | null;
      } | null;
    } | null> | null;
  } | null;
};
export const GetAccountWithPredictionsDocument = gql`
  query GetAccountWithPredictions(
    $id: Int!
    $predictionTypes: [PredictionForType!]
    $predictionNames: [String]
    $recentPredictionDate: Date
  ) {
    account(id: $id) {
      id
      crmAccountId
      accountName
      engagementStatus
      currentPredictions: predictions(
        current: true
        filter: {
          calculatedForType: $predictionTypes
          calculatedForName: $predictionNames
        }
      ) {
        id
        accountId
        currentValue
        level
        recommendedPriorityAction
        calculatedAtDate
        calculatedFor {
          type
          name
          date
        }
        expectedValue {
          low
          middle
          high
        }
        insertedAt
        updatedAt
      }
      recentPredictions: predictions(
        current: true
        filter: {
          calculatedForType: $predictionTypes
          calculatedForName: $predictionNames
          calculatedAtBefore: $recentPredictionDate
        }
      ) {
        id
        accountId
        currentValue
        level
        recommendedPriorityAction
        calculatedAtDate
        calculatedFor {
          type
          name
          date
        }
        expectedValue {
          low
          middle
          high
        }
        insertedAt
        updatedAt
      }
      orderFrequencies {
        accountId
        calculatedForName
        regularity
        lastOrderDate
        minDate
        maxDate
        meanDate
        medianDate
        contMedianDate
        orderFrequency
      }
      __typename
    }
  }
`;
/**
 * __useGetAccountWithPredictionsQuery__
 *
 * To run a query within a React component, call `useGetAccountWithPredictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountWithPredictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountWithPredictionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      predictionTypes: // value for 'predictionTypes'
 *      predictionNames: // value for 'predictionNames'
 *      recentPredictionDate: // value for 'recentPredictionDate'
 *   },
 * });
 */
export function useGetAccountWithPredictionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountWithPredictionsQuery,
    GetAccountWithPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountWithPredictionsQuery,
    GetAccountWithPredictionsQueryVariables
  >(GetAccountWithPredictionsDocument, options);
}
export function useGetAccountWithPredictionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountWithPredictionsQuery,
    GetAccountWithPredictionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountWithPredictionsQuery,
    GetAccountWithPredictionsQueryVariables
  >(GetAccountWithPredictionsDocument, options);
}
export type GetAccountWithPredictionsQueryHookResult = ReturnType<
  typeof useGetAccountWithPredictionsQuery
>;
export type GetAccountWithPredictionsLazyQueryHookResult = ReturnType<
  typeof useGetAccountWithPredictionsLazyQuery
>;
export type GetAccountWithPredictionsQueryResult = Apollo.QueryResult<
  GetAccountWithPredictionsQuery,
  GetAccountWithPredictionsQueryVariables
>;

export type InputMaybe<T> = Maybe<T>;

export type PredictionGoal = {
  __typename?: 'PredictionGoal';
  calculatedAt: Scalars['Date'];
  calculatedFor: Scalars['Date'];
  isPriority: Scalars['Boolean'];
  value?: Maybe<Scalars['Float']>;
  signalPredictions?: Maybe<Array<Maybe<SignalPrediction>>>;
  lift?: Maybe<Scalars['Float']>;
};

export enum SignalGroupByType {
  SignalType = 'SIGNAL_TYPE',
  SignalTypeDetail = 'SIGNAL_TYPE_DETAIL',
  SignalTopic = 'SIGNAL_TOPIC',
  SignalTopicDetail = 'SIGNAL_TOPIC_DETAIL',
}

/** Enqueue object for entire ETL pipeline */
export type PipelineInput = {
  /** The company to do internal ETL on */
  company: Scalars['String'];
  /** The upper bound date. Format YYYY-0M-0D. Defaults to today if not provided */
  dateFrom?: InputMaybe<Scalars['String']>;
  /** The lower bound date. Format YYYY-0M-0D. Defaults to today if not provided */
  dateTo?: InputMaybe<Scalars['String']>;
};

/** Input object for company */
export type CompanyInput = {
  /** Name for the company */
  companyName: Scalars['String'];
  /** Name of CRM Types */
  crmTypes: Array<InputMaybe<Scalars['String']>>;
  /** Name of Can Submit Credentials */
  canSubmitCredentials?: InputMaybe<Scalars['Boolean']>;
};

/** Input object for updating the account group's title and description */
export type UpdateAccountGroupDescriptorsInput = {
  /** The account group id */
  id: Scalars['Int'];
  /** Title of the account group */
  title: Scalars['String'];
  /** Description of the account group */
  description: Scalars['String'];
};

export type AccountGroup = {
  __typename?: 'AccountGroup';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  filterString?: Maybe<Scalars['String']>;
  accountCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['NaiveDateTime'];
  updatedAt: Scalars['NaiveDateTime'];
  accountsUpdatedAt?: Maybe<Scalars['NaiveDateTime']>;
  user: User;
  isHidden: Scalars['Boolean'];
  accounts: Array<Maybe<Account>>;
};

export type SignalDetailsForAccountItem = {
  __typename?: 'SignalDetailsForAccountItem';
  count: Scalars['Int'];
  signalDate?: Maybe<Scalars['Date']>;
  signalSource?: Maybe<Scalars['String']>;
  signalType?: Maybe<Scalars['String']>;
  signalTypeDetail?: Maybe<Scalars['String']>;
  signalTopic?: Maybe<Scalars['String']>;
  signalTopicDetail?: Maybe<Scalars['String']>;
};

export type SignalHistoryCategory = {
  __typename?: 'SignalHistoryCategory';
  signalHistoryItems: Array<Maybe<SignalHistoryItem>>;
  narratives: Array<Maybe<Scalars['String']>>;
};

export enum ContactField {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Email = 'EMAIL',
  Phone = 'PHONE',
  Title = 'TITLE',
}

export enum OutreachStatus {
  Inactivated = 'INACTIVATED',
  Activated = 'ACTIVATED',
  Deactivating = 'DEACTIVATING',
  Deactivated = 'DEACTIVATED',
}

export type ContactSource = {
  __typename?: 'ContactSource';
  contactId: Scalars['Int'];
  sourceRecordId: Scalars['String'];
  crmCreatedAt?: Maybe<Scalars['NaiveDateTime']>;
  crmAccountId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  insertedAt: Scalars['NaiveDateTime'];
  updatedAt: Scalars['NaiveDateTime'];
  source: Source;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  insertedAt: Scalars['NaiveDateTime'];
  updatedAt: Scalars['NaiveDateTime'];
  contactSources?: Maybe<Array<Maybe<ContactSource>>>;
  accounts?: Maybe<Array<Maybe<Account>>>;
};

export type RootQueryTypeContactArgs = {
  id: Scalars['Int'];
};

export type RootQueryTypeContactsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ContactSortItem>>>;
  filterString: InputMaybe<Scalars['String']>;
  accountId: InputMaybe<Scalars['Int']>;
};

export type RootQueryTypeContactsForSearchArgs = {
  query: Scalars['String'];
};

export type Source = {
  __typename?: 'Source';
  id: Scalars['Int'];
  database: Scalars['String'];
  prefix: Scalars['String'];
  table: Scalars['String'];
  column: Scalars['String'];
  dataSource: Scalars['String'];
  uniqueKeyFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  insertedAt: Scalars['NaiveDateTime'];
  updatedAt: Scalars['NaiveDateTime'];
};

/** Input object for sorting a contact list */
export type ContactSortItem = {
  field: ContactField;
  direction: Sort;
};

export type AccountContactsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<InputMaybe<ContactSortItem>>>;
  filterString: InputMaybe<Scalars['String']>;
  accountId: InputMaybe<Scalars['Int']>;
};

export type GetAccountWithContactsQueryVariables = Exact<{
  id: Scalars['Int'];
  contactsLimit?: InputMaybe<Scalars['Int']>;
  contactsPage?: InputMaybe<Scalars['Int']>;
  contactsSort?: InputMaybe<
    Array<InputMaybe<ContactSortItem>> | InputMaybe<ContactSortItem>
  >;
  filterString: InputMaybe<Scalars['String']>;
}>;

export type GetAccountWithContactsQuery = {
  __typename?: 'RootQueryType';
  account?: {
    __typename: 'Account';
    id: number;
    crmAccountId: string;
    accountName?: string | null;
    contacts?: Array<{
      __typename?: 'Contact';
      id: number;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
      title?: string | null;
      role?: string | null;
      insertedAt: any;
      updatedAt: any;
      contactSources?: Array<{
        __typename?: 'ContactSource';
        contactId: number;
        sourceRecordId: string;
        crmCreatedAt?: any | null;
        crmAccountId?: string | null;
        accountId?: number | null;
        source: {
          __typename?: 'Source';
          id: number;
          database: string;
          prefix: string;
          table: string;
          column: string;
          dataSource: string;
        };
      } | null> | null;
    } | null> | null;
  } | null;
};

export const GetAccountWithContactsDocument = gql`
  query GetAccountWithContacts(
    $id: Int!
    $contactsLimit: Int
    $contactsPage: Int
    $contactsSort: [ContactSortItem] = []
  ) {
    account(id: $id) {
      id
      crmAccountId
      accountName
      contacts(
        page: $contactsPage
        limit: $contactsLimit
        sort: $contactsSort
      ) {
        id

        firstName
        lastName
        email
        phone
        title
        role
        insertedAt
        updatedAt
        engagementStatus
        outreachStatus
        contactSources {
          contactId
          sourceRecordId
          crmCreatedAt
          crmAccountId
          accountId
          source {
            id
            database
            prefix
            table
            column
            dataSource
          }
        }
      }
      __typename
    }
  }
`;

/**
 * __useGetAccountWithContactsQuery__
 *
 * To run a query within a React component, call `useGetAccountWithContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountWithContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountWithContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      contactsLimit: // value for 'contactsLimit'
 *      contactsPage: // value for 'contactsPage'
 *      contactsSort: // value for 'contactsSort'
 *   },
 * });
 */
export function useGetAccountWithContactsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountWithContactsQuery,
    GetAccountWithContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAccountWithContactsQuery,
    GetAccountWithContactsQueryVariables
  >(GetAccountWithContactsDocument, options);
}
export function useGetAccountWithContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountWithContactsQuery,
    GetAccountWithContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAccountWithContactsQuery,
    GetAccountWithContactsQueryVariables
  >(GetAccountWithContactsDocument, options);
}
export type GetAccountWithContactsQueryHookResult = ReturnType<
  typeof useGetAccountWithContactsQuery
>;
export type GetAccountWithContactsLazyQueryHookResult = ReturnType<
  typeof useGetAccountWithContactsLazyQuery
>;
export type GetAccountWithContactsQueryResult = Apollo.QueryResult<
  GetAccountWithContactsQuery,
  GetAccountWithContactsQueryVariables
>;

export type ContactsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<
    Array<InputMaybe<ContactSortItem>> | InputMaybe<ContactSortItem>
  >;
  filterString: InputMaybe<Scalars['String']>;
}>;

export type AccountWithContactsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<
    Array<InputMaybe<ContactSortItem>> | InputMaybe<ContactSortItem>
  >;
  filterString: InputMaybe<Scalars['String']>;
  accountId: InputMaybe<Scalars['Int']>;
}>;

export type ContactsQuery = {
  __typename?: 'RootQueryType';
  contacts?: Array<{
    __typename?: 'Contact';
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    title?: string | null;
    role?: string | null;
    accounts?: Array<{
      __typename?: 'Account';
      id: number;
      accountName?: string | null;
    } | null> | null;
  } | null> | null;
};

export const ContactsDocument = gql`
  query contacts(
    $filterString: String
    $limit: Int
    $page: Int
    $sort: [ContactSortItem] = []
    $accountId: Int
  ) {
    contacts(
      page: $page
      limit: $limit
      sort: $sort
      accountId: $accountId
      filterString: $filterString
    ) {
      contacts {
        id
        firstName
        lastName
        email
        phone
        title
        role
        engagementStatus
        outreachStatus
        accounts {
          id
          accountName
        }
      }
      pageInfo {
        pageNumber
        pageSize
        totalEntries
        totalPages
        __typename
      }
    }
  }
`;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options,
  );
}
export function useContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactsQuery,
    ContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(
    ContactsDocument,
    options,
  );
}
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<
  typeof useContactsLazyQuery
>;
export type ContactsQueryResult = Apollo.QueryResult<
  ContactsQuery,
  ContactsQueryVariables
>;

export function useAccountWithContactsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ContactsQuery,
    AccountWithContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactsQuery, AccountWithContactsQueryVariables>(
    ContactsDocument,
    options,
  );
}
export function useAccountWithContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactsQuery,
    AccountWithContactsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactsQuery, AccountWithContactsQueryVariables>(
    ContactsDocument,
    options,
  );
}
export type AccountWithContactsQueryHookResult = ReturnType<
  typeof useContactsQuery
>;
export type AccountWithContactsLazyQueryHookResult = ReturnType<
  typeof useContactsLazyQuery
>;
export type AccountWithContactsQueryResult = Apollo.QueryResult<
  ContactsQuery,
  AccountWithContactsQueryVariables
>;

export type MetabaseUrlQueryVariables = Exact<{ [key: string]: never }>;

export type MetabaseUrlQuery = {
  __typename?: 'RootQueryType';
  metabaseEmbedUrl?: string | null;
};

export const Document = gql`
  {
    metabaseEmbedUrl
  }
`;

/**
 * __useQuery__
 *
 * To run a query within a React component, call `useQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MetabaseUrlQuery,
    MetabaseUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MetabaseUrlQuery, MetabaseUrlQueryVariables>(
    Document,
    options,
  );
}
export function useLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MetabaseUrlQuery,
    MetabaseUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MetabaseUrlQuery, MetabaseUrlQueryVariables>(
    Document,
    options,
  );
}
export type QueryHookResult = ReturnType<typeof useQuery>;
export type LazyQueryHookResult = ReturnType<typeof useLazyQuery>;
export type QueryResult = Apollo.QueryResult<
  MetabaseUrlQuery,
  MetabaseUrlQueryVariables
>;
