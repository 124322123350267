import { Inline } from '../../../../../../../shared/components/Core';
import _map from 'lodash/map';
import _range from 'lodash/range';

const NUMBER_OF_CIRCLES = 5;

const filledCircleStyles = {
  backgroundColor: 'var(--primary-action-color)',
  borderRadius: 'var(--spacing-xs)',
  height: 'var(--spacing-md)',
  width: 'var(--spacing-md)',
};

const extraFilledCircle = {
  backgroundColor: 'var(--all-actions-color)',
  borderColor: 'var(--primary-action-color)',
  borderRadius: 'var(--spacing-xs)',
  borderStyle: 'solid',
  borderWidth: 'var(--border-radius-tight)',
  height: 'var(--spacing-md)',
  width: 'var(--spacing-md)',
};

const unfilledCircleStyles = {
  borderColor: 'var(--primary-action-color)',
  borderRadius: 'var(--spacing-xs)',
  borderStyle: 'solid',
  borderWidth: 'var(--border-radius-tight)',
  height: 'var(--spacing-md)',
  width: 'var(--spacing-md)',
};

type WinfluenceScoreProps = {
  nonPriorityScore: number;
  totalCircles?: number;
  winfluenceScore: number;
};

export const WinfluenceScore = ({
  nonPriorityScore = 0,
  totalCircles = NUMBER_OF_CIRCLES,
  winfluenceScore = 0,
}: WinfluenceScoreProps) => (
  <Inline alignItems={'center'} gap={'2xs'}>
    {_map(_range(1, totalCircles + 1), (value) => (
      <div
        key={value}
        style={
          value <= winfluenceScore
            ? filledCircleStyles
            : value <= nonPriorityScore
            ? extraFilledCircle
            : unfilledCircleStyles
        }
      />
    ))}
  </Inline>
);
