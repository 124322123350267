import { useContext, useMemo } from 'react';
import { useInput, UseInputHookReturnType } from 'shared/hooks/inputHook';
import MultiSelectCheckbox from '../../../../../../shared/components/Core/MultiSelectCheckbox';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { FilterItemProps } from '../../types/filterItemProps';
import { sortSelectFilterOptions } from '../../helpers/sortSelectFilterOptions';
import { getFilterItemConsts } from '../../helpers/getFilterItemConsts';
import { adaptDictAsString } from '../../helpers/adaptFilterForQuery';
import _omit from 'lodash/omit';
import { OptionsType } from 'shared/types/coreTypes.d';
import { useGetAggregateDataWithFilterVarQuery } from 'shared/graphql/generatedApiTypes';

const CheckboxFilterItem = ({
  analyticsAttr = undefined,
  initialItems = [],
  filterIndex,
  label = '',
  labelStringTooltip = '',
  tableAccessor = '',
}: FilterItemProps) => {
  const { globalFilter } = useContext(AggregateDataContext);

  const {
    data: initialAggregatedAccountsData,
    loading: initialAggregatedAccountsDataLoading,
    fullAggregateLoading,
  } = useContext(AggregateDataContext);

  const {
    data: filterItemAggregatedAccountsData,
    loading: filterItemAggregatedAccountsDataLoading,
  } = useGetAggregateDataWithFilterVarQuery({
    variables: {
      filterString: adaptDictAsString(_omit(globalFilter, filterIndex)) ?? '',
      type: 'account',
    },
  });

  const { aggregatedItemsAccountData, areFiltersApplied, isSelectedDisabled } =
    getFilterItemConsts({
      filterIndices: [filterIndex],
      filterItemAggregatedAccountsData,
      filterItemAggregatedAccountsDataLoading,
      globalFilter,
      initialAggregatedAccountsData,
      initialAggregatedAccountsDataLoading:
        initialAggregatedAccountsDataLoading || fullAggregateLoading,
    });

  const itemsSorted = sortSelectFilterOptions({
    aggregatedItemsAccountData,
    areFiltersApplied,
    filterIndex,
    initialItems,
    tableAccessor,
  });

  const defaultValue = useMemo(() => [], []);
  const { bind: bindItems } = useInput(
    globalFilter?.[filterIndex] || defaultValue,
    {
      defaultValue: defaultValue,
      label: label,
      labelTooltip: labelStringTooltip,
      options: itemsSorted,
    },
  ) as UseInputHookReturnType;

  return (
    <MultiSelectCheckbox
      {...bindItems}
      analyticsAttr={analyticsAttr}
      disabled={isSelectedDisabled}
      extraFieldSetStyles={{ marginTop: 0 }}
      filterIndex={filterIndex}
      isFilterBar={true}
      options={bindItems.options as OptionsType[]}
    />
  );
};

export default CheckboxFilterItem;
