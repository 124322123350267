import {Badge, BadgeProps} from '@chakra-ui/react';
import {AnalyticsAttrType} from 'shared/types/coreTypes.d';

type BadgeType = AnalyticsAttrType &
  BadgeProps & {
    text: string;
  };

const StyledBadge = ({ text, ...props }: BadgeType) => (
  <Badge {...props}>{text}</Badge>
);
// <div className={'c-badge-wrapper'}>
//   {showCount && count > 0 && <span className={badgeClasses}>{count}</span>}
//   {content}
// </div>

export default StyledBadge;
