import { forwardRef, ReactNode } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import Loading from './Loading';

type CardProps = BoxProps & {
  children: ReactNode;
  isLoading?: boolean;
  loadingText?: string;
};

const Card = forwardRef<HTMLDivElement | null, CardProps>(
  ({ children, isLoading, loadingText, minH = 400, ...props }, ref) => {
    return (
      <Box
        bg={'brand.white'}
        borderColor={'brand.gray-300'}
        borderRadius={6}
        borderWidth={1}
        my={4}
        minH={minH}
        overflow={'hidden'}
        p={isLoading ? 0 : 4}
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <Loading
            dotColor={'color'}
            h={minH}
            text={loadingText ?? 'Loading...'}
          />
        ) : (
          children
        )}
      </Box>
    );
  },
);

export default Card;
