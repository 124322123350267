import { BaseModel } from './BaseModel';
import { Firestore } from '../types/firebaseTypes';
import {
  LabCardComment,
  LabCardFeedbackSchema,
} from '../schemas/LabCardFeedback';
import { LabCardFeedbackDocument } from '../documents/LabCardFeedbackDocument';
import { firebaseEnvPrefix } from '../constants/constants';

export class LabCardFeedbackModel extends BaseModel<
  LabCardFeedbackSchema,
  LabCardFeedbackDocument
> {
  constructor(firestore: Firestore | null) {
    super(
      firestore,
      'LabCardFeedback',
      `${firebaseEnvPrefix}_lab_card_feedback`,
      LabCardFeedbackDocument,
    );
  }

  async getSingleCardFeedback(
    companyName: string,
    labCardId: string,
  ): Promise<{ comments: LabCardComment[]; likes: string[] }> {
    const companyDoc = await this.get(companyName);
    const matchingLabCardKey =
      companyDoc?.data &&
      Object.keys(companyDoc.data).find((key) => key === labCardId);
    if (matchingLabCardKey && companyDoc.data) {
      return companyDoc.data[matchingLabCardKey];
    }
    return { comments: [], likes: [] };
  }

  async updateLabCardComment(
    companyName: string,
    labCardId: string,
    commentObj: LabCardComment,
  ) {
    const companyDoc = await this.get(companyName);
    if (companyDoc) {
      const matchingLabCardKey =
        companyDoc.data &&
        Object.keys(companyDoc.data).find((key) => key === labCardId);

      if (matchingLabCardKey) {
        const matchingLabCard =
          companyDoc.data && companyDoc.data[matchingLabCardKey];
        let newComments: LabCardComment[];
        if (matchingLabCard?.comments) {
          newComments = [...matchingLabCard.comments, commentObj];
        } else {
          newComments = [commentObj];
        }
        await companyDoc.update({
          [matchingLabCardKey]: {
            likes: matchingLabCard?.likes ?? [],
            comments: newComments,
          },
        });
      }
    } else {
      await this.create(
        {
          [labCardId]: {
            comments: [commentObj],
            likes: [],
          },
        },
        companyName,
      );
    }
  }
}
