import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'shared/components/Core';
import { AcquisitionIcon } from 'shared/components/Core/Icon/handShake';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import {
  useGetAccountQuery,
  useGetAccountWithPredictionsLazyQuery,
} from 'shared/graphql/generatedApiTypes';
import { RetentionIcon } from '../../../../../shared/components/Core/Icon/stayHome';
import {
  PredictionColumnTooltip,
  RecommendetionTooltip,
} from '../../TableSection/renderers/AccountDrawerItemRenderer';
import {
  RecommendationJSON,
  TooltipText,
} from '../../TopCompanyList/components/TopCompanyList';
import { changetolastWeek } from '../constants/changeArrow';
import {
  LikelihoodBox,
  predictionTooltip,
  reguralityMapping,
} from '../constants/gaugeBins';
import { PredictionDescription } from '../constants/multiPredctionJSON';

export default function MultipleProductPredictionsSection() {
  const { id: selectedAccountId } = useParams<{ id: string }>();
  // State define for currentPredictions and recentPredictions
  const [currentPredictions, setCurrentPredictions] = useState<any>([]);
  const [recentPredictions, setRecentPredictions] = useState<any>([]);
  const [predictionLoading, setPredictionLoading] = useState<boolean>(true);
  // prediction types
  enum PredictionForType {
    Acquisition = 'ACQUISITION',
    Retention = 'RETENTION',
    Volume = 'VOLUME',
    Fit = 'FIT',
  }
  // Fetch data for Account
  const { data } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });
  const accountInfo = data?.account;

  // Fetch details for multi product predictions
  const [getAccountWithPrediction] = useGetAccountWithPredictionsLazyQuery({
    variables: {
      id: parseInt(selectedAccountId),
      predictionTypes: [
        PredictionForType?.Acquisition,
        PredictionForType?.Retention,
        PredictionForType?.Volume,
      ],
      predictionNames: [],
      recentPredictionDate: moment().format('YYYY-MM-DD'),
    },
  });

  useEffect(() => {
    (async () => {
      setPredictionLoading(true);
      await getAccountWithPrediction().then((res) => {
        let predictionData = res?.data;
        const capitalize = (s) =>
          s && s[0].toUpperCase() + s.slice(1).toLowerCase();

        if (predictionData?.account) {
          let currentData = predictionData?.account?.currentPredictions;
          let recentData = predictionData?.account?.recentPredictions;
          let orderFrequencies = predictionData?.account?.orderFrequencies;
          let tempCurrentData: any = [];
          let tempRecentData: any = [];
          if (
            currentData !== undefined &&
            currentData !== null &&
            recentData !== undefined &&
            recentData !== null
          ) {
            currentData.map((data) => {
              tempCurrentData.push({
                name: data?.calculatedFor?.name,
                level: data?.level,
                type: capitalize(data?.calculatedFor?.type),
                recommendedPriorityAction:
                  data?.recommendedPriorityAction === null
                    ? '-'
                    : data?.recommendedPriorityAction,
                orderFrequencies: orderFrequencies?.filter(
                  (el) => el?.calculatedForName === data?.calculatedFor?.name,
                ),
              });
              return tempCurrentData;
            });

            recentData.map((data) => {
              tempRecentData.push({
                name: data?.calculatedFor?.name,
                level: data?.level,
                type: capitalize(data?.calculatedFor?.type),
                recommendedPriorityAction:
                  data?.recommendedPriorityAction === null
                    ? '-'
                    : data?.recommendedPriorityAction,
              });
              return tempRecentData;
            });
            setRecentPredictions(
              tempRecentData.sort((a, b) => b?.level - a?.level),
            );
            setCurrentPredictions(
              tempCurrentData.sort((a, b) => b?.level - a?.level),
            );
          }
        }
      });
      setPredictionLoading(false);
    })();
  }, [selectedAccountId]);

  return (
    <Card isLoading={predictionLoading}>
      {!accountInfo ? (
        <Text>
          Error getting account data for this account, please refresh to try
          again.
        </Text>
      ) : (
        <>
          <div style={{ fontSize: 24, fontWeight: 'bold' }}>Predictions</div>
          {/* Retention section */}
          {currentPredictions.filter((item) => item?.type === 'Retention')
            .length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <TableContainer>
                <div style={{ display: 'flex' }}>
                  {/* RetentionIcon */}
                  {RetentionIcon}

                  <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    Retention
                  </span>
                </div>
                <Table variant='simple' className='product-prediction'>
                  <Thead>
                    <Tr>
                      <Th className='table-header'>Product</Th>
                      <Th className='table-header'>
                        {PredictionColumnTooltip(
                          predictionTooltip['Retention'],
                          'Retention',
                          'Likelihood',
                        )}
                      </Th>
                      <Th className='table-header'>Summary</Th>
                      <Th className='table-header'>
                        {RecommendetionTooltip(
                          TooltipText.recommendation,
                          'RECOMMENDATION',
                        )}
                      </Th>
                      <Th className='table-header'>PROJECTED ORDER DATE</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentPredictions
                      .filter(
                        (item: { type: string }) => item?.type === 'Retention',
                      )
                      .map((item, index) => {
                        return (
                          item?.name !== 'any' && (
                            <Tr key={index} className='table-display'>
                              <Td>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Text fontSize={'16'}>{item?.name}</Text>
                                  {item?.orderFrequencies.length > 0 && (
                                    <>
                                      <p
                                        style={{
                                          color: '#70798B',
                                          fontSize: '12px',
                                          textDecoration: 'none',
                                          height: '14px',
                                        }}
                                      >
                                        Last Order Date :{' '}
                                        {moment(
                                          item?.orderFrequencies[0]
                                            ?.lastOrderDate,
                                        ).format('ll')}
                                      </p>
                                      <p
                                        style={{
                                          color: '#70798B',
                                          fontSize: '12px',
                                          textDecoration: 'none',
                                          height: '14px',
                                        }}
                                      >
                                        Order Frequency : Every{' '}
                                        {
                                          item?.orderFrequencies[0]
                                            ?.orderFrequency
                                        }
                                      </p>
                                      <p
                                        style={{
                                          color: '#70798B',
                                          fontSize: '12px',
                                          textDecoration: 'none',
                                          height: '14px',
                                        }}
                                      >
                                        Order Regularity :{' '}
                                        {reguralityMapping(
                                          item?.orderFrequencies[0]?.regularity,
                                        )}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </Td>
                              <Td>
                                {LikelihoodBox(item, 'retentionPredictions')}
                                {changetolastWeek(
                                  item,
                                  recentPredictions.filter(
                                    (item: { type: string }) =>
                                      item?.type === 'Retention',
                                  )[index],
                                )}
                              </Td>
                              <Td style={{ color: '#6D7689' }}>
                                {item?.type} is
                                {item.level === 2 ? (
                                  <b style={{ color: '#323F5A' }}> Equal To</b>
                                ) : (
                                  PredictionDescription?.[item?.type]?.[
                                    item?.name
                                  ]?.[item?.level]
                                )}
                                <br />
                                {item.level === 2 ? '' : 'than'} the typical
                                company.
                              </Td>
                              <Td>
                                {
                                  RecommendationJSON[
                                    item?.recommendedPriorityAction
                                  ]
                                }
                              </Td>
                              <Td>
                                {item?.orderFrequencies.length > 0
                                  ? `${moment(
                                      item?.orderFrequencies[0]?.minDate,
                                    ).format('MMM YY')}` +
                                    ' - ' +
                                    `${moment(
                                      item?.orderFrequencies[0]?.maxDate,
                                    ).format('MMM YY')}`
                                  : ' - '}
                              </Td>
                            </Tr>
                          )
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          )}
          {/* Acquisition section */}
          {currentPredictions.filter(
            (item: { type: string }) => item?.type === 'Acquisition',
          ).length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <div style={{ display: 'flex' }}>
                {/* AcquisitionIcon */}
                {AcquisitionIcon}

                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  Acquisition
                </span>
              </div>
              <TableContainer>
                <Table variant='simple' className='product-prediction'>
                  <Thead>
                    <Tr>
                      <Th className='table-header'>Product</Th>
                      <Th className='table-header'>
                        {PredictionColumnTooltip(
                          predictionTooltip['Acquisition'],
                          'Acquisition',
                          'Likelihood',
                        )}
                      </Th>
                      <Th className='table-header'>Summary</Th>
                      <Th className='table-header'>
                        {RecommendetionTooltip(
                          TooltipText.recommendation,
                          'RECOMMENDATION',
                        )}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentPredictions
                      .filter((item) => item?.type === 'Acquisition')
                      .map((item, index) => {
                        return (
                          item?.name !== 'any' && (
                            <Tr key={index} className='table-display'>
                              <Td>{item?.name}</Td>
                              <Td>
                                {LikelihoodBox(item, 'acquisitionPredictions')}
                                {changetolastWeek(
                                  item,
                                  recentPredictions.filter(
                                    (item) => item?.type === 'Acquisition',
                                  )[index],
                                )}
                              </Td>
                              <Td style={{ color: '#6D7689' }}>
                                {item?.type} is
                                {item.level === 2 ? (
                                  <b style={{ color: '#323F5A' }}> Equal To</b>
                                ) : (
                                  PredictionDescription?.[item?.type]?.[
                                    item?.name
                                  ]?.[item?.level]
                                )}
                                <br />
                                {item.level === 2 ? '' : 'than'} the typical
                                company.
                              </Td>
                              <Td>
                                {
                                  RecommendationJSON[
                                    item?.recommendedPriorityAction
                                  ]
                                }
                              </Td>
                            </Tr>
                          )
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          )}
        </>
      )}
    </Card>
  );
}
