import { ReactNode } from 'react';
import _size from 'lodash/size';

import Columns from './Columns';
import Rows from './Rows';

import { Txt } from '../index';

export type HeatMapPropsType = {
  clickMeta?: Array<any>;
  color?: string;
  columnId?: string;
  id?: string;
  index?: number;
  isSelected?: boolean;
  label?: string;
  rowId?: string;
  value?: number;
};

export type HeatMapDefaultRows = {
  rows: Array<HeatMapPropsType>;
};

type HeatMapDefaultColumns = {
  columns: Array<HeatMapPropsType>;
};

export type HeatMapDefaultItems = HeatMapDefaultColumns &
  HeatMapDefaultRows & {
    columnsId: string;
    gridHeight: string | number;
    gridLabelFormatter: (props: HeatMapPropsType) => ReactNode;
    showTooltip: boolean;
    tooltipFormatter: (props: HeatMapPropsType) => ReactNode;
  };

export type HeatMapType = HeatMapDefaultItems & {
  columnsFormatter: (props: HeatMapPropsType) => ReactNode;
  data: Array<HeatMapPropsType>;
  height: string | number;
  invalidDataComponent?: ReactNode;
  invalidDataErrorMessage: string;
  onClick: ((props: HeatMapPropsType) => void) | undefined;
  onClickMessage?: string;
  rowsFormatter: (props: HeatMapPropsType) => ReactNode;
  rowsId: string;
  wrapperWidth: string;
  width: string | number;
  xAxisLabel?: string;
  yAxisLabel?: string;
};

const HeatMap = ({
  columns = [],
  columnsFormatter = (props) => props.label,
  columnsId = 'columnId',
  data = [],
  gridHeight = 100,
  gridLabelFormatter = (props) => props.label,
  height = '100%',
  invalidDataComponent = undefined,
  invalidDataErrorMessage = 'Data Not Valid!',
  onClick = undefined,
  onClickMessage = undefined,
  rows = [],
  rowsFormatter = (props) => props.label,
  rowsId = 'rowId',
  showTooltip = true,
  tooltipFormatter = (props) => props.label,
  wrapperWidth = '100%',
  width = '100%',
  xAxisLabel = undefined,
  yAxisLabel = undefined,
}: Partial<HeatMapType>) => {
  // checking data validity
  const columnsLength = _size(columns);
  const dataLength = _size(data);
  const rowsLength = _size(rows);
  const isDataValid =
    columnsLength === rowsLength && dataLength === columnsLength * rowsLength;
  if (!isDataValid || dataLength === 0) {
    return invalidDataComponent ? (
      <>{invalidDataComponent}</>
    ) : (
      <span>{invalidDataErrorMessage}</span>
    );
  }
  // all good, render
  return (
    <div className={'heatmap-parent-wrapper'} style={{ width: wrapperWidth }}>
      <Txt align={'center'} as={'i'} className={'h-mb-sm'}>
        {onClick
          ? `(${
              onClickMessage ? onClickMessage : 'click on cell to see details'
            })`
          : ''}
      </Txt>
      <div className={'heatmap-content-wrapper'}>
        <div className={'heatmap-wrapper'}>
          {yAxisLabel ? (
            <div className={'heatmap-y-axis-wrapper'}>
              <Txt>{yAxisLabel}</Txt>
            </div>
          ) : (
            <div />
          )}
          <div
            className={'heatmap-chart-wrapper'}
            style={{
              gridTemplateColumns: `auto repeat(${columnsLength}, 1fr)`,
              height,
              width,
            }}
          >
            <Columns
              columns={columns}
              columnsFormatter={columnsFormatter}
              columnsId={columnsId}
              data={data}
              gridHeight={gridHeight}
              gridLabelFormatter={gridLabelFormatter}
              onClick={onClick}
              rows={rows}
              rowsId={rowsId}
              showTooltip={showTooltip}
              tooltipFormatter={tooltipFormatter}
            />
            <div />
            <Rows rows={rows} rowsFormatter={rowsFormatter} />
          </div>
          {xAxisLabel && (
            <>
              <div />
              <Txt align={'center'} className={'h-mt-2xs'}>
                {xAxisLabel}
              </Txt>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeatMap;
