import { Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GetAccountWithIncludeDirectivesDocument } from 'shared/graphql/generatedApiTypes';
import { Card } from '../../../../../../../../../shared/components/Core';
import { SingleAccountQuickStatRow } from './SingleAccountQuickStatRow';
import { SingleAccountQuickStatProps } from 'shared/types/quickStatFieldOptions';
import Tooltip from 'shared/components/Core/Tooltip';

export const SingleAccountQuickStat = ({
  title,
  tooltip,
  center,
  bottom,
  externalQueryLoading,
  bgColor,
}: SingleAccountQuickStatProps) => {
  const { id: selectedAccountId } = useParams<{ id: string }>();

  const queryVars = {
    id: parseInt(selectedAccountId),
  };
  if (center?.dataField) {
    queryVars[center.dataField] = true;
  }
  if (bottom?.dataField) {
    queryVars[bottom.dataField] = true;
  }

  // only actually make the query if we have some variables in there, otherwise use cache-only
  const { data, loading, error } = useQuery(
    GetAccountWithIncludeDirectivesDocument,
    {
      fetchPolicy:
        Object.keys(queryVars).length > 1 ? 'cache-first' : 'cache-only',
      variables: selectedAccountId ? queryVars : undefined,
    },
  );

  return (
    <Card
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      isLoading={loading || externalQueryLoading}
      minH={36}
      my={0}
      bg={bgColor ? bgColor : 'brand.white'}
      py={2}
    >
      {!!error ? (
        <Text color={'brand.crimson'}>
          Error retrieving this data: refresh to reload
        </Text>
      ) : (
        <>
          {tooltip ? (
            <Tooltip content={tooltip}>
              <Text
                color={'brand.black'}
                fontWeight={'bold'}
                fontSize={['sm', null, null, 'md', 'md']}
              >
                {title}
              </Text>
            </Tooltip>
          ) : (
            <Text
              color={'brand.black'}
              fontWeight={'bold'}
              fontSize={['sm', null, null, 'md', 'md']}
            >
              {title}
            </Text>
          )}
          <SingleAccountQuickStatRow
            rowDetails={center}
            dataToShow={data?.account}
            rowType={'center'}
          />
          <SingleAccountQuickStatRow
            rowDetails={bottom}
            dataToShow={data?.account}
            rowType={'bottom'}
          />
        </>
      )}
    </Card>
  );
};
