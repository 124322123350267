import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Flex,
  FormLabel,
  Radio,
  SimpleGrid,
  Checkbox as AllExportCheckbox,
} from '@chakra-ui/react';
import { Button, RadioInput, Checkbox } from 'shared/components/Core';
import { CSVLink } from 'react-csv';
import { useContactsLazyQuery } from 'shared/graphql/generatedApiTypes';

const csvFilenamePrefix = new Date()
  .toLocaleDateString('en-US')
  .replace('/', '')
  .replace('/', '');

const contactExportFields = {
  name: 'Name',
  companyName: 'Compnay',
  email: 'Email',
  phone: 'Phone',
  title: 'Job title',
  engagementStatus: 'Engagement Status',
  outreachStatus: 'Outreach Status',
};

export const useContactCsvExport = (
  selectedFlatRows,
  filterVariable,
  pageInfo,
) => {
  const [selectedExportFields, setSelectedExportFields] = useState<string[]>(
    Object.keys(contactExportFields),
  );
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [radioSelect, setRadioSelect] = useState<any>(false);
  const csvLinkRef = useRef<{ link: { click: () => void } }>(null);
  const csvHeaders = useMemo(
    () =>
      selectedExportFields.map((field) => ({
        label: contactExportFields[field],
        key: field,
      })),
    [selectedExportFields],
  );
  const [getMultipleAccountPeopleList] = useContactsLazyQuery({
    variables: { ...filterVariable, limit: 1000 },
  });

  const handleExportData = async () => {
    if (radioSelect) {
      try {
        let contactData = await getMultipleAccountPeopleList();
        if (contactData?.data?.contacts) {
          let tempContact: any[] = [];
          contactData?.data?.contacts?.['contacts'].map((contact) => {
            tempContact.push({
              ...contact,
              companyName: contact?.accounts[0]?.accountName,
              name: contact?.firstName + ' ' + contact?.lastName,
            });
          });

          setSelectedData(tempContact);
          csvLinkRef?.current?.link?.click();
          // setRadioSelect(false);
        }
      } catch {}
    } else {
      csvLinkRef?.current?.link?.click();
    }
  };
  useEffect(() => {
    setRadioSelect(false);
  }, []);
  return {
    contactExportFieldsCheckboxGrid: (
      <>
        <SimpleGrid columns={2}>
          {Object.keys(contactExportFields).map((key) => {
            const checked = selectedExportFields.find(
              (selected) => selected === key,
            );
            return (
              <Flex align={'center'} gap={1} key={key}>
                <Checkbox
                  key={key}
                  id={key}
                  checked={!!checked}
                  onChange={() =>
                    checked
                      ? setSelectedExportFields((prevState) =>
                          prevState.filter((item) => item !== key),
                        )
                      : setSelectedExportFields((prevState) => [
                          ...prevState,
                          key,
                        ])
                  }
                />
                <FormLabel htmlFor={key} mt={1}>
                  {contactExportFields[key]}
                </FormLabel>
              </Flex>
            );
          })}
        </SimpleGrid>
        {pageInfo?.pageLimit === selectedFlatRows.length && (
          <Box>
            <AllExportCheckbox
              onChange={(e) => {
                setRadioSelect(e.target.checked);
              }}
              isDisabled={pageInfo?.totalEntries < 1000 ? true : false}
              checked={radioSelect}
              className='all-select-checkbox'
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Export all records that match the filter criteria</span>
                <span style={{ fontSize: '14px' }}>
                  If your filter criteria resulted in more than 1000 <br />{' '}
                  records, only the first 1000 will be exported.
                </span>
              </div>
            </AllExportCheckbox>
          </Box>
        )}
      </>
    ),
    contactExportButton: (
      <>
        <CSVLink
          className={'hidden'}
          data={radioSelect ? selectedData : selectedFlatRows}
          filename={`${csvFilenamePrefix}_contacts.csv`}
          headers={csvHeaders}
          ref={csvLinkRef}
          target={'_blank'}
        />
        <Button
          fontSize={['sm', null, null, 'md', null]}
          text={'Export'}
          variant={'action'}
          onClick={handleExportData}
          isDisabled={!selectedExportFields.length}
        />
      </>
    ),
  };
};
