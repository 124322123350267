import { memo, useEffect, useRef, useState } from 'react';
import _ceil from 'lodash/ceil';
import _filter from 'lodash/filter';
import _findIndex from 'lodash/findIndex';
import _flatten from 'lodash/flatten';
import _inRange from 'lodash/inRange';
import _map from 'lodash/map';
import _maxBy from 'lodash/maxBy';
import _range from 'lodash/range';
import _size from 'lodash/size';

import {
  Bar,
  BarChart as RechartsBarChart,
  Brush,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  BAR_CHART_DATA_VALUE,
  BAR_CHART_NAME_VALUE,
  COLOR_PALETTE,
} from 'app/src/constants/chartConstants';

import { RechartsBase, RechartsTooltip } from './index';
import { RechartsTooltipPayloadType } from './RechartsBase';
import { numberFormatter, truncateString } from '../../helpers/formatHelpers';

export type BarChartDataPayloadType = {
  toolText: string;
  value: number;
};

export type BarChartType = {
  data: Array<{
    index?: number;
    label: string;
    toolText?: string;
    value: number;
  }>;
  events:
    | {
        dataPlotClick?: ({ index: number }) => void;
      }
    | undefined;
  height?: string | number;
  isChartClickable: boolean;
  isPercent: boolean;
  isSmallerViewport: boolean;
  monoColor?: string;
  plotToolText?: string;
  width?: string;
};

type BarChartRefType = {
  getInfo: () => { height: number };
};

type BarChartXAxisTickType = {
  fill?: string;
  forceAxisAngle: boolean;
  payload?: { value: string };
  isLargerDataset: boolean;
  isSmallerViewport: boolean;
  x?: number;
  y?: number;
};

const renderTooltipContent = (
  dataPayload: RechartsTooltipPayloadType,
  plotToolText: string | undefined,
  isPercent: boolean,
) => {
  const { toolText = '', value = '' } = dataPayload;
  const tooltipTextTemplate = plotToolText ?? toolText;
  const tooltipText = tooltipTextTemplate
    .replace('$label', dataPayload[BAR_CHART_NAME_VALUE])
    .replace('$dataValue', `${value.toLocaleString()}${isPercent ? '%' : ''}`)
    .split(', ');
  return _map(tooltipText, (text, idx) => <p key={idx}>{text}</p>);
};

const BarChartXAxisTick = ({
  fill,
  forceAxisAngle,
  payload,
  isLargerDataset = false,
  isSmallerViewport = false,
  x,
  y,
}: BarChartXAxisTickType) => {
  const { value = '' } = { ...payload };
  const angle: number = isLargerDataset || forceAxisAngle ? -90 : 0;
  const textAnchor: string =
    isLargerDataset || forceAxisAngle ? 'end' : 'middle';
  let text: Array<string> = value
    .toString()
    .replace(' (', '$split$(')
    .split('$split$');
  if (isSmallerViewport) {
    text = _flatten(
      _map(text, (t) => t.replace('- ', '-$split$').split('$split$')),
    );
  }
  const textItems = _map(text, (t, idx) => {
    const text = idx === 0 ? (isLargerDataset ? truncateString(t) : t) : t;
    return (
      <tspan
        dy={idx !== 0 ? '20' : undefined}
        key={idx}
        textAnchor={textAnchor}
        x={'0'}
      >
        {text}
      </tspan>
    );
  });
  return (
    <g transform={`translate(${x}, ${y}) rotate(${angle})`}>
      <text
        className={
          isSmallerViewport && !isLargerDataset ? 'svg-text-small-font' : ''
        }
        dy={isLargerDataset || forceAxisAngle ? 6 : 16}
        fill={fill}
        x={0}
        y={0}
      >
        {textItems}
      </text>
    </g>
  );
};
const BarChart = memo(
  ({
    data = [],
    events = {},
    height = '100%',
    isChartClickable = false,
    isPercent = true,
    isSmallerViewport = false,
    monoColor = undefined,
    plotToolText = undefined,
    width = '99%',
  }: BarChartType) => {
    const [wrapperHeight, setWrapperHeight] = useState<number>(200);
    // setup refs
    const baseRef = useRef<BarChartRefType>(null);
    // setup consts
    const largerDatasetSize: number = isSmallerViewport ? 10 : 20;
    const isLargerDataset: boolean = _size(data) > largerDatasetSize;
    const brushHeight: number = 30;
    const brushYMultiplier: number = 1.7;
    const brushY: number = wrapperHeight - brushHeight * brushYMultiplier;
    const largestValue: number = _maxBy(data, 'value')?.value || 100;
    const yAxisRange: Array<number> = _range(0, 101, 10);
    const filteredYAxisRange: number =
      _filter(
        _map(yAxisRange, (val, idx) =>
          _inRange(largestValue, yAxisRange[idx - 1] || 0, val) ? val : null,
        ),
      )[0] || 0;
    const yAxisMax: number =
      filteredYAxisRange > 10 ? filteredYAxisRange : _ceil(largestValue);
    const yAxisDomain = isPercent ? [0, yAxisMax || 'auto'] : [0, 'auto'];
    const brushEndIndex: number = isSmallerViewport ? 10 : 25;
    const forceAxisAngle: boolean = isSmallerViewport && _size(data) >= 8;

    useEffect(() => {
      if (baseRef?.current?.getInfo) {
        setWrapperHeight(baseRef.current.getInfo().height);
      }
    }, [baseRef]);

    return (
      <RechartsBase
        chartClickableType={'bar'}
        height={height}
        isChartClickable={isChartClickable}
        ref={baseRef}
        width={width}
      >
        <RechartsBarChart
          data={_map(data, (d, idx) => ({
            ...d,
            fill: monoColor || COLOR_PALETTE[idx],
          }))}
          margin={{
            bottom:
              isLargerDataset || forceAxisAngle
                ? 120
                : isSmallerViewport && !isLargerDataset
                ? 40
                : 20,
            left: 0,
            right: 20,
            top: 5,
          }}
        >
          <CartesianGrid
            stroke={'#ccc'}
            strokeDasharray={'3 3'}
            vertical={false}
          />
          <XAxis
            dataKey={BAR_CHART_NAME_VALUE}
            interval={0}
            tick={
              <BarChartXAxisTick
                forceAxisAngle={forceAxisAngle}
                isLargerDataset={isLargerDataset}
                isSmallerViewport={isSmallerViewport}
              />
            }
            tickMargin={5}
          />
          <YAxis
            domain={yAxisDomain as any}
            scale={'linear'}
            tickFormatter={(val) =>
              `${numberFormatter(val, false)}${isPercent ? '%' : ''}`
            }
            tickMargin={5}
          />
          <Tooltip
            content={
              <RechartsTooltip
                renderContent={(dataPayload) =>
                  renderTooltipContent(dataPayload, plotToolText, isPercent)
                }
              />
            }
          />
          {isLargerDataset && (
            <Brush
              dataKey={BAR_CHART_NAME_VALUE}
              endIndex={brushEndIndex}
              height={brushHeight}
              stroke={'var(--color-klearly-blue)'}
              tickFormatter={(t) => truncateString(t)}
              y={brushY}
            />
          )}
          <Bar
            className={isChartClickable ? 'h-pointer' : ''}
            dataKey={BAR_CHART_DATA_VALUE}
            onClick={(props) => {
              if (isChartClickable) {
                const index = _findIndex(data, [
                  BAR_CHART_NAME_VALUE,
                  props[BAR_CHART_NAME_VALUE],
                ]);
                if (events.dataPlotClick) {
                  events.dataPlotClick({ index });
                }
              }
            }}
          />
        </RechartsBarChart>
      </RechartsBase>
    );
  },
);

export default BarChart;
