import { Button, Container, Loading } from 'shared/components/Core';
import { useEffect } from 'react';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import * as ROUTES from 'app/src/constants/routes';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { IntegrationLocationState } from 'app/src/components/Integrations/components/IntegrationButton';
import { base64ToJson } from 'app/src/components/Integrations/helpers/base64helpers';
import { Box, Stack, Text } from '@chakra-ui/react';
import {
  ApiCrmTypeEnum,
  ApiDataSourceConfigInput,
  useCreateDataSourceConfigMutation,
} from 'shared/graphql/generatedApiTypes';
import { getCapitalizedCrmTypeString } from 'app/src/components/Integrations/helpers/getCapitalizedCrmTypeString';
import AuthManager from 'shared/firebase/classes/AuthManager';

export const IntegrationSuccessPage = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const oauthCode = queryParams.get('code');
  const encodedState = queryParams.get('state');
  const klearlyUser = AuthManager?.klearlyUser;
  const {
    companyName,
    dataSource,
    businessUnitId = '',
  } = encodedState
    ? (base64ToJson(encodedState) as IntegrationLocationState)
    : {
        companyName: '',
        dataSource: '',
        businessUnitId: '',
      };

  const dataSourceString = getCapitalizedCrmTypeString(dataSource);
  const [createDataSourceConfigMutation, { data, loading, error }] =
    useCreateDataSourceConfigMutation();

  useEffect(() => {
    updateAppHeaders(ROUTES.INTEGRATION_SUCCESS_PAGE.name);
  }, []);
  useEffect(() => {
    if (
      companyName &&
      dataSource &&
      oauthCode &&
      isCrmTypeEnum(dataSource) &&
      !data &&
      !loading
    ) {
      const dataSourceConfig: Omit<
        ApiDataSourceConfigInput,
        'marketoConfig' | 'oauthCode' | 'pendoConfig'
      > = {
        companyName,
        dataSource,
        hubspotConfig: null,
        salesforceConfig: null,
        pardotConfig: null,
      };

      if (dataSource === ApiCrmTypeEnum.Hubspot) {
        dataSourceConfig.hubspotConfig = {
          oauthCode,
        };
        delete dataSourceConfig.salesforceConfig;
        delete dataSourceConfig.pardotConfig;
      } else if (dataSource === ApiCrmTypeEnum.Salesforce) {
        dataSourceConfig.salesforceConfig = {
          oauthCode,
        };
        delete dataSourceConfig.hubspotConfig;
        delete dataSourceConfig.pardotConfig;
      } else if (dataSource === ApiCrmTypeEnum.Pardot) {
        dataSourceConfig.pardotConfig = {
          pardotBusinessUnitId: businessUnitId,
          oauthCode,
        };
      }

      if (
        [
          ApiCrmTypeEnum.Hubspot,
          ApiCrmTypeEnum.Salesforce,
          ApiCrmTypeEnum.Pardot,
        ].includes(dataSource)
      )
        createDataSourceConfigMutation({
          variables: {
            //@ts-ignore - not including all of the available configs
            dataSourceConfig,
          },
        });
    }
  }, [companyName, dataSource, oauthCode]);

  useEffect(() => {
    (async () => {
      if (oauthCode && klearlyUser) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            code: oauthCode,
            companyId: klearlyUser?.company?.id,
          }),
        };
        await fetch(
          'https://actions-gxn6pxrd4q-uc.a.run.app/auth/saveToken',
          requestOptions,
        );
      }
    })();
  }, []);

  return (
    <Container>
      <Box mb={8} px={4}>
        {loading ? (
          <Loading />
        ) : !!error ? (
          <>
            <Text fontWeight={'bold'} textAlign={'center'} fontSize={'xl'}>
              Error connecting the data source, please try again or contact
              support@klearly.com for assistance.
            </Text>
          </>
        ) : (
          <Stack spacing={10} align={'center'}>
            <Text fontWeight={'bold'} fontSize={'xl'}>
              {dataSourceString} successfully connected.
            </Text>
            <Button
              onClick={() =>
                history.push(
                  `${ROUTES.INTEGRATIONS_PAGE.path}?company=${companyName}`,
                )
              }
              text={'Connect Another Source'}
            />
          </Stack>
        )}
      </Box>
    </Container>
  );
};

const isCrmTypeEnum = (
  dataSource: ApiCrmTypeEnum | string,
): dataSource is ApiCrmTypeEnum =>
  Object.values(ApiCrmTypeEnum).includes(dataSource as ApiCrmTypeEnum);
