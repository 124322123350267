import { BaseModel } from './BaseModel';
import { Firestore } from '../types/firebaseTypes';
import { SavedStructureDocument } from '../documents/SavedStructureDocument';
import { SavedStructureSchema } from '../schemas/SavedStructure';

export class SavedStructureModel extends BaseModel<
  SavedStructureSchema,
  SavedStructureDocument
> {
  constructor(firestore: Firestore | null) {
    super(
      firestore,
      'SavedStructure',
      `saved_structures`,
      SavedStructureDocument,
    );
  }
}
