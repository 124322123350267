import { cloneElement, ReactNode, useState } from 'react';
import classnames from 'classnames';
import _upperCase from 'lodash/upperCase';

import { Inline, Txt } from './index';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { Stack } from '@chakra-ui/react';

type TabsType = {
  activeLabel: string | null;
  children: any;
  className?: string;
  onTabChange: (tab: string) => void;
  rightContent?: ReactNode;
  showChildren?: boolean;
};

type TabType = {
  activeTab: string;
  itemKey: string;
  label: string;
  onClick: (label: string) => void;
};

const Tab = ({ activeTab, itemKey, label, onClick }: TabType) => {
  const _onClick = () => onClick(itemKey);
  const tabClasses = classnames({
    'c-tab': true,
    'c-tab--active': activeTab === itemKey,
  });
  const text = _upperCase(label);
  return (
    <div
      analytics-attr={cleanAnalyticsStringList([label, 'tab'])}
      className={tabClasses}
      key={label}
      onClick={_onClick}
    >
      <Txt text={text} uppercase size={'xs'} weight={'heavy'} />
    </div>
  );
};

const Tabs = ({
  activeLabel,
  children,
  className,
  onTabChange = () => {},
  rightContent = null,
  showChildren = true,
}: TabsType) => {
  const [activeTab, setActiveTab] = useState(activeLabel || children[0].key);
  const _onClickTabItem = (tab: string) => {
    onTabChange(tab);
    setActiveTab(tab);
  };
  const tabsWrapperClasses = classnames({
    ...(typeof className === 'string' ? { [className]: className } : {}),
  });
  return (
    <div className={'tabs'}>
      <Stack spacing={6}>
        {children && (
          <Inline
            alignItems={'center'}
            className={tabsWrapperClasses}
            justifyContent={'between'}
          >
            <div className={'c-tabs'}>
              {children.map((child) => {
                const { label } = child.props;
                return (
                  <Tab
                    activeTab={activeTab}
                    itemKey={child.key}
                    key={child.key}
                    label={label}
                    onClick={_onClickTabItem}
                  />
                );
              })}
            </div>
            {rightContent || <div />}
          </Inline>
        )}
        <div className={'tab-content'}>
          {showChildren &&
            children.map((child) => {
              if (child.key !== activeTab) return undefined;
              return cloneElement(child.props.children, {
                ...child.props.children,
                key: child.key,
              });
            })}
        </div>
      </Stack>
    </div>
  );
};

export default Tabs;
