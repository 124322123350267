import { useContext, useEffect, useMemo, useState } from 'react';
import { AnalyticsAttrType } from 'shared/types/coreTypes.d';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { FilterIndices } from '../../../types/filterTypes';
import { BindMinMaxOptionsType } from '../../../types/minToMaxFilterItemProps';
import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderTrack,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import {
  createRangerBars,
  getLabelAddOn,
} from '../../../helpers/rangerFilterHelpers';
import { Button, Tooltip } from 'shared/components/Core';
import { RangeThumb } from 'app/src/components/GlobalFilters/components/filterItems/rangerFilterItem/RangeThumb';
import { RangerBarAndLabel } from 'app/src/components/GlobalFilters/components/filterItems/rangerFilterItem/RangerBarAndLabel';
import { formatAsPercentage } from 'shared/helpers/formatHelpers';

type RangerFilterItemProps = AnalyticsAttrType & {
  filterIndex: FilterIndices;
  initialFilterItem: BindMinMaxOptionsType;
  isDollars: boolean;
  isHundredPointScale: boolean;
  isPercentageScale: boolean;
  labelString: string;
  labelStringTooltip: string;
};

type RangerFilterItemState = {
  isApplied: boolean;
  currentValueIndices: number[];
  displayedValueIndices: number[];
};

export const RangerFilterItem = ({
  analyticsAttr = undefined,
  filterIndex,
  initialFilterItem = [],
  isDollars,
  isHundredPointScale,
  isPercentageScale,
  labelStringTooltip = '',
}: RangerFilterItemProps) => {
  const {
    data: initialAggregatedAccountsData,
    loading: initialAggregatedAccountsDataLoading,
    globalFilter,
    updateFilter,
    fullAggregateLoading,
  } = useContext(AggregateDataContext);

  const aggregatedInitialData = useMemo(() => {
    return initialAggregatedAccountsData?.aggregateData
      ? initialAggregatedAccountsData?.aggregateData[filterIndex] || []
      : [];
  }, [initialAggregatedAccountsData, filterIndex]);

  const numberOfTicks = aggregatedInitialData?.length ?? 0;

  const initialMinValue =
    globalFilter?.[filterIndex]?.min !== undefined
      ? initialFilterItem.findIndex(
          (item) => item.index === globalFilter?.[filterIndex].min,
        )
      : 0;
  const initialMaxValue =
    globalFilter?.[filterIndex]?.max !== undefined
      ? initialFilterItem.findIndex(
          (item) => item.index === globalFilter?.[filterIndex].max,
        )
      : numberOfTicks;

  const initialState: RangerFilterItemState = {
    isApplied: false,
    currentValueIndices: [initialMinValue, initialMaxValue],
    displayedValueIndices: [initialMinValue, initialMaxValue],
  };

  const [{ isApplied, currentValueIndices, displayedValueIndices }, setState] =
    useState<RangerFilterItemState>(initialState);

  // Due to the way the buckets are built, need to cut off the last one to avoid an extra bar for hundred point or
  // percentage scales
  const trimmedAggregateInitialData =
    isPercentageScale || isHundredPointScale
      ? aggregatedInitialData.slice(0, aggregatedInitialData.length - 1)
      : aggregatedInitialData;

  const numberOfItemsInRange = trimmedAggregateInitialData.map((d, idx) =>
    idx === trimmedAggregateInitialData.length - 1
      ? d?.eq! + d?.gt!
      : d?.eq! + d?.gt! - trimmedAggregateInitialData[idx + 1]?.gt! || 0,
  );

  const bars = useMemo(
    () => ({
      values: createRangerBars(numberOfItemsInRange),
      selectedBarsIndex: currentValueIndices,
    }),
    [currentValueIndices, numberOfItemsInRange],
  );

  const label = useMemo(() => {
    const combinedRangeLabel = getLabelAddOn(
      aggregatedInitialData,
      [
        (initialFilterItem[currentValueIndices[0]] &&
          initialFilterItem[currentValueIndices[0]].index) ??
          0,
        (initialFilterItem[currentValueIndices[1]] &&
          initialFilterItem[currentValueIndices[1]].index) ??
          'Max',
      ],
      isDollars,
      isPercentageScale,
    );
    if (!globalFilter || (globalFilter && !globalFilter[filterIndex])) {
      return `Range (No filter applied - all accounts)`;
    }
    return `Range${combinedRangeLabel}`;
  }, [
    aggregatedInitialData,
    currentValueIndices,
    filterIndex,
    globalFilter,
    initialFilterItem,
    isDollars,
    isPercentageScale,
  ]);

  useEffect(() => {
    let cleanedValue: { min?: string | number; max?: string | number } = {};
    const currentlyHasMin =
      (currentValueIndices[0] || currentValueIndices[0] === 0) &&
      initialFilterItem[currentValueIndices[0]];
    const currentlyHasMax =
      currentValueIndices[1] &&
      currentValueIndices[1] !== numberOfTicks &&
      initialFilterItem[currentValueIndices[1]];
    if (currentlyHasMin) {
      cleanedValue = {
        min: initialFilterItem[currentValueIndices[0]].index ?? undefined,
      };
      if (currentlyHasMax) {
        cleanedValue.max =
          initialFilterItem[currentValueIndices[1]].index ?? undefined;
      }
    }
    if (Object.keys(cleanedValue).length && isApplied) {
      updateFilter({ index: filterIndex, value: cleanedValue });
    }
    //updateFilter causes infinite rerenders
    //eslint-disable-next-line
  }, [currentValueIndices, filterIndex]);

  const isLoading =
    initialAggregatedAccountsDataLoading || fullAggregateLoading;

  return (
    <Box key={filterIndex}>
      {label && (
        <Flex justify={'center'} align={'center'} mb={20}>
          {labelStringTooltip ? (
            <Tooltip content={labelStringTooltip}>
              <Text fontWeight={'bold'} textAlign={'center'}>
                {label}
              </Text>
            </Tooltip>
          ) : (
            <Text fontWeight={'bold'} textAlign={'center'}>
              {label}
            </Text>
          )}
          <Button
            h={8}
            isDisabled={!globalFilter || !globalFilter[filterIndex]}
            ml={2}
            onClick={() => {
              setState(initialState);
              updateFilter({ index: filterIndex, value: undefined });
            }}
            text={'Clear Filter'}
          />
        </Flex>
      )}
      {!isLoading && (
        <RangeSlider
          aria-label={['min', 'max']}
          defaultValue={displayedValueIndices}
          max={
            isHundredPointScale || isPercentageScale
              ? numberOfTicks - 1
              : numberOfTicks
          }
          min={0}
          mb={6}
          minStepsBetweenThumbs={1}
          onChange={(newVal) =>
            setState((prevState) => ({
              ...prevState,
              displayedValueIndices: newVal,
            }))
          }
          onChangeEnd={(newVal) =>
            setState((prevState) => ({
              ...prevState,
              isApplied: true,
              currentValueIndices: newVal,
            }))
          }
          step={1}
          value={displayedValueIndices}
          w={'96%'}
        >
          <RangeSliderTrack
            bg='brand.gray-200'
            borderColor={'brand.gray-400'}
            borderWidth={0.5}
            boxSize={2}
            // mb={13}
            pos={'absolute'}
            top={-13}
          >
            <RangeSliderFilledTrack bg='brand.gradient' />
          </RangeSliderTrack>
          {displayedValueIndices.map((value, index) => (
            <RangeThumb
              analyticsAttr={analyticsAttr}
              key={index}
              index={index}
              initialFilterItem={initialFilterItem}
              isHundredPointScale={isHundredPointScale}
              isPercentageScale={isPercentageScale}
              numberOfTicks={numberOfTicks}
              value={value}
            />
          ))}
          <SimpleGrid
            columns={
              isHundredPointScale || isPercentageScale
                ? numberOfTicks
                : numberOfTicks + 1
            }
            textAlign={'center'}
            w={'100%'}
            ml={4}
            h={4}
          >
            {initialFilterItem.map((item, index) => (
              <Box w={'100%'} key={index}>
                {bars.values && bars.values[index] && (
                  <RangerBarAndLabel
                    bars={bars}
                    currentValueIndices={currentValueIndices}
                    index={index}
                    label={
                      isPercentageScale && item.label
                        ? formatAsPercentage(parseFloat(item.label), {
                            precision: 0,
                          })
                        : item.label
                    }
                  />
                )}
              </Box>
            ))}
            {!isHundredPointScale &&
              !isPercentageScale &&
              bars.values &&
              bars.values[bars.values.length - 1] && (
                <Box
                  mt={-13}
                  transform={`translateY(${
                    bars.values[bars.values.length - 1].height
                  })`}
                >
                  <Text
                    fontSize={'3xs'}
                    mt={16}
                    mr={3}
                    transform={
                      bars.values[bars.values.length - 1].height
                        ? `translateY(-${
                            bars.values[bars.values.length - 1].height
                          })`
                        : undefined
                    }
                  >
                    Max
                  </Text>
                </Box>
              )}
          </SimpleGrid>
        </RangeSlider>
      )}
    </Box>
  );
};
