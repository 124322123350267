import { formatAsPercentage } from 'shared/helpers/formatHelpers';

export function styleNumber(
  number: number = 0,
  isCurrency: boolean = false,
  isPercent: boolean = false,
) {
  if (isCurrency) {
    return `$${number.toLocaleString()}`;
  }
  if (isPercent) {
    return formatAsPercentage(number, {});
  }
  return number.toLocaleString();
}
