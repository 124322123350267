import { Maybe } from 'shared/graphql/generatedApiTypes';

export type BuildGoogleMapsLinkParams = {
  addressStreet: Maybe<string>;
  addressCity: Maybe<string>;
  addressZipCode: Maybe<string>;
  addressRegion: Maybe<string>;
};

export const buildGoogleMapsLink = ({
  addressStreet,
  addressCity,
  addressRegion,
  addressZipCode,
}: BuildGoogleMapsLinkParams) => {
  let mapsLink = '';
  [addressStreet, addressCity, addressRegion, addressZipCode].forEach(
    (addressItem) => {
      if (addressItem && addressItem.trim()) {
        mapsLink += encodeURI(addressItem.trim()) + encodeURI(' ');
      }
    },
  );
  if (mapsLink) {
    return 'https://www.google.com/maps/search/?api=1&query=' + mapsLink;
  }
  return '';
};
