import { BaseModel } from './BaseModel';
import { Firestore } from '../types/firebaseTypes';
import { UiConfigSchema } from '../schemas/UiConfig';
import { firebaseEnvPrefix } from '../constants/constants';
import { UiConfigDocument } from '../documents/UiConfigDocument';

export class UiConfigModel extends BaseModel<UiConfigSchema, UiConfigDocument> {
  constructor(firestore: Firestore) {
    super(
      firestore,
      'UiConfig',
      `${firebaseEnvPrefix}_ui_config`,
      UiConfigDocument,
    );
  }
}
