import {createIcon} from '@chakra-ui/icon';

const IoMagnifyingGlass = createIcon({
  displayName: 'IoMagnifyingGlass',
  path: (
    <g key={1}>
      <circle
        className='c-icon__path'
        fill='none'
        cx='14.67'
        cy='14.67'
        r='8'
      />
      <line
        className='c-icon__path'
        fill='none'
        strokeWidth='1.33'
        x1='20'
        y1='20'
        x2='28'
        y2='28'
      />
    </g>
  ),
  viewBox: '0 0 28 28',
});

export default IoMagnifyingGlass;
