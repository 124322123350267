import {
  formatDateString,
  pluralizeAccountsString,
} from 'shared/helpers/formatHelpers';

type GetCalendarFilterLabelParams = {
  count: number | undefined;
  endDate: Date | null | undefined;
  label: string;
  startDate: Date | null | undefined;
};

export function getCalendarFilterLabel({
  count,
  endDate,
  startDate,
}: GetCalendarFilterLabelParams) {
  const labelStart = count;
  const accountsString = pluralizeAccountsString(count);
  const labelAddOnString = `(${
    labelStart! >= 0 ? labelStart?.toLocaleString() : 'Loading'
  } ${accountsString})`;
  const labelStartDate = startDate && formatDateString(startDate, 'PP');
  const labelEndDate = endDate && formatDateString(endDate, 'PP');
  const dateRangeAddon =
    startDate && endDate ? `${labelStartDate} - ${labelEndDate}` : null;
  return `Range${
    dateRangeAddon
      ? `: ${dateRangeAddon} ${labelAddOnString}`
      : ` ${labelAddOnString}`
  }`;
}
