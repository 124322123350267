import { Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'shared/components/Core';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import {
  useAccountWithContactsLazyQuery,
  useGetAccountQuery,
} from 'shared/graphql/generatedApiTypes';
import PeopleListTable from '../../MultipleAccountPeopleList/peopleListTable';

export default function index() {
  const [selectIds, setSelectIds] = React.useState<any>([]);
  const [allSelected, setAllSelected] = React.useState<boolean>(false);
  const [allContacts, setAllContacts] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const [pageInfo, setPageInfo] = React.useState<any>({
    pageLimit: 5,
    from: 1,
    to: 5,
    page: 1,
    totalEntries: 0,
  });
  const [selectedEngagementStatus, setSelectedEngagementStatus] =
    React.useState<any>([]);
  const [selectedOutreachStatus, setSelectedOutreachStatus] =
    React.useState<any>([]);
  const [currentSortsInForm, setCurrentSortsInForm] = React.useState<any>([
    {
      desc: false,
      id: 'FIRST_NAME',
    },
  ]);

  enum Sort {
    Asc = 'ASC',
    Desc = 'DESC',
  }
  const [currentSorts, setCurrentSorts] = React.useState<any>([
    {
      field: 'FIRST_NAME',
      direction: Sort['Asc'],
    },
  ]);

  const pageDropdown = [5, 10, 15];

  const hanlePagination = (arrow) => {
    if (arrow === 'left') {
      setPageInfo({
        ...pageInfo,
        page: parseInt(pageInfo.page) - 1,
        from: parseInt(pageInfo?.from) - parseInt(pageInfo?.pageLimit),
        to: parseInt(pageInfo?.to) - parseInt(pageInfo?.pageLimit),
      });
    } else {
      setPageInfo({
        ...pageInfo,
        page: parseInt(pageInfo.page) + 1,
        from: parseInt(pageInfo?.from) + parseInt(pageInfo?.pageLimit),
        to: parseInt(pageInfo?.to) + parseInt(pageInfo?.pageLimit),
      });
    }
  };

  // Function for fetching account details on Id
  const { data } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });
  const accountInfo = data?.account;

  useEffect(() => {
    if (accountInfo !== undefined && accountInfo !== null) {
      setPageInfo({ ...pageInfo, totalEntries: accountInfo.contactCount });
    }
  }, [accountInfo]);

  let filterVariable: any = {
    page: pageInfo.page,
    limit: parseInt(pageInfo.pageLimit),
    sort: currentSorts,
    filterString: JSON.stringify({
      engagementStatus: selectedEngagementStatus,
      outreachStatus: selectedOutreachStatus,
    }),
    accountId: parseInt(selectedAccountId),
  };

  const [getAccountContactsList] = useAccountWithContactsLazyQuery({
    variables: filterVariable,
  });

  // Function for set the contact data in state
  const fetchContactData = async () => {
    await getAccountContactsList().then((res) => {
      let contactData = res?.data;
      if (contactData?.contacts) {
        let tempContact: any[] = [];
        contactData?.contacts?.['contacts'].map((contact) => {
          tempContact.push({
            ...contact,
            companyName: contact?.accounts[0]?.accountName,
            name: contact?.firstName + ' ' + contact?.lastName,
          });
        });
        // });
        let pageInfoData: any = contactData?.contacts?.['pageInfo'];
        if (pageInfoData?.totalEntries === 0) {
          setPageInfo({ ...pageInfo, ...pageInfoData, from: 0 });
        } else {
          setPageInfo({ ...pageInfo, ...pageInfoData });
        }
        setAllContacts(tempContact);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchContactData();
  }, [
    selectedAccountId,
    pageInfo.from,
    pageInfo.pageLimit,
    pageInfo.to,
    pageInfo.pageLimit,
    currentSorts,
    selectedEngagementStatus,
    selectedOutreachStatus,
  ]);

  return (
    <Card isLoading={loading} style={{ background: 'none', border: 'none' }}>
      {!accountInfo ? (
        <Text>
          Error getting account data for this account, please refresh to try
          again.
        </Text>
      ) : (
        <PeopleListTable
          allContacts={allContacts}
          selectIds={selectIds}
          setSelectIds={setSelectIds}
          allSelected={allSelected}
          setAllSelected={setAllSelected}
          pageInfo={pageInfo}
          setPageInfo={setPageInfo}
          hanlePagination={hanlePagination}
          pageDropdown={pageDropdown}
          section={'peopleTab'}
          currentSortsInForm={currentSortsInForm}
          setCurrentSortsInForm={setCurrentSortsInForm}
          setCurrentSorts={setCurrentSorts}
          setSelectedEngagementStatus={setSelectedEngagementStatus}
          setSelectedOutreachStatus={setSelectedOutreachStatus}
          selectedEngagementStatus={selectedEngagementStatus}
          selectedOutreachStatus={selectedOutreachStatus}
          filterVariable={filterVariable}
        />
      )}
    </Card>
  );
}
