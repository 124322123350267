// @ts-nocheck
import {
  CSSProperties,
  forwardRef,
  Fragment,
  MutableRefObject,
  ReactNode,
  Ref,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import {
  Column,
  useAsyncDebounce,
  useExpanded,
  useGlobalFilter,
  useMountedLayoutEffect,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import _filter from 'lodash/filter';
import _isString from 'lodash/isString';
import {
  Flex,
  Stack,
  Table as StyledTable,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Checkbox, Container, Input, Pagination } from './index';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import {
  allSizeOptionsLiteral,
  ExtraAnalyticsAttrType,
  sizeOptionsLiteral,
} from 'shared/types/coreTypes.d';

type TableProps = {
  analyticsAttr?: string;
  autoResetPage?: boolean;
  columns: Column<object>[];
  data: Array<object>;
  hasJustSaved?: boolean;
  initialDefaultSortByColumnDesc?: boolean;
  initialDefaultSortByColumnId?: string;
  initialSelectedRows?: object;
  leftHeader?: ReactNode;
  perPage?: number;
  renderRowSubComponent?: (...args: any) => ReactNode;
  rowHeight?: sizeOptionsLiteral;
  selectedRowIndex?: string;
  serverPagination?: boolean;
  setHasJustSaved?: (newVal: boolean) => void;
  setSelectedRows?: (rows: Array<string>) => void;
  setSelectedFlatRows?: (rows: Array<any>) => void;
  showGlobalFilter?: boolean;
  showRowBorder?: boolean;
  tableVPadding?: sizeOptionsLiteral;
  topHeaderWrapperVPadding?: allSizeOptionsLiteral;
  classname?: string;
};

export interface TypeStyleType extends CSSProperties {
  '--table-v-padding': string;
}

type TopHeaderWrapperType = {
  children: Array<ReactNode>;
  topHeaderWrapperVPadding: allSizeOptionsLiteral;
};

type GlobalFilterType = {
  analyticsAttr?: string;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
};

type IndeterminateCheckboxType = ExtraAnalyticsAttrType & {
  analyticsAttr?: string;
  indeterminate?: boolean;
};

// Top Header Wrapper
function TopHeaderWrapper({
  children,
  topHeaderWrapperVPadding,
}: TopHeaderWrapperType) {
  children = _filter(children);
  return children && children.length > 1 ? (
    <Flex
      align={'center'}
      justify={'space-between'}
      px={'lg'}
      py={topHeaderWrapperVPadding}
    >
      {children}
    </Flex>
  ) : (
    <Container width={'full'}>{children}</Container>
  );
}

// Define a default UI for filtering
function GlobalFilter({
  analyticsAttr = undefined,
  globalFilter,
  setGlobalFilter,
}: GlobalFilterType) {
  const [value, setValue] = useState<string>(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <Input
      analyticsAttr={`${analyticsAttr} search`}
      hideMessage
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={'Search'}
      value={value || ''}
    />
  );
}

// Checkbox to select rows
const useCombinedRefs = (...refs): MutableRefObject<any> => {
  const targetRef = useRef();
  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;
      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);
  return targetRef;
};

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxType
>(
  (
    {
      analyticsAttr = undefined,
      extraAnalyticsAttr = undefined,
      indeterminate,
      ...rest
    },
    ref: Ref<HTMLInputElement>,
  ) => {
    const defaultRef = useRef(null);
    const resolvedRef = useCombinedRefs(ref, defaultRef);
    useEffect(() => {
      if (resolvedRef?.current) {
        resolvedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [resolvedRef, indeterminate]);
    return (
      <Checkbox
        resolvedRef={resolvedRef}
        {...rest}
        analyticsAttr={cleanAnalyticsStringList([
          analyticsAttr,
          extraAnalyticsAttr,
        ])}
      />
    );
  },
);

const Table = ({
  analyticsAttr = undefined,
  autoResetPage = false,
  columns,
  data,
  hasJustSaved = false,
  initialDefaultSortByColumnDesc = false,
  initialDefaultSortByColumnId = 'id',
  initialSelectedRows = {},
  leftHeader = undefined,
  perPage = 10,
  renderRowSubComponent,
  rowHeight = 'lg',
  selectedRowIndex = undefined,
  serverPagination = false,
  setHasJustSaved = () => {},
  setSelectedRows = () => {},
  setSelectedFlatRows,
  showGlobalFilter = false,
  showRowBorder = true,
  tableVPadding = 'lg',
  topHeaderWrapperVPadding = 'xl',
  classname,
}: TableProps) => {
  // setup ref and const
  const skipPageResetRef = useRef<boolean>();
  // After the table has updated, always remove the flag
  useEffect(() => {
    skipPageResetRef.current = false;
  }, [data]);
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    pageOptions,
    prepareRow,
    selectedFlatRows,
    setGlobalFilter,
    state: { globalFilter, pageSize, selectedRowIds = initialSelectedRows },
    toggleAllRowsSelected,
    visibleColumns,
  } = useTable(
    {
      autoResetExpanded: false,
      autoResetFilters: false,
      autoResetPage,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: perPage,
        // @ts-ignore
        selectedRowIds: initialSelectedRows,
        sortBy: serverPagination
          ? []
          : [
              {
                desc: initialDefaultSortByColumnDesc,
                id: initialDefaultSortByColumnId,
              },
            ],
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );
  // setup classes
  const tableClass = classnames({
    [classname]: true,
    'c-table': true,
    [`c-table--row-height-${rowHeight}`]: rowHeight,
  });
  const tableRowClass = classnames({
    'no-table-border': !showRowBorder,
  });
  const tableStyles: TypeStyleType = {
    '--table-v-padding': `var(--spacing-${tableVPadding})`,
  };

  useEffect(() => {
    if (setSelectedFlatRows) {
      setSelectedFlatRows(selectedFlatRows);
    }
  }, [selectedFlatRows, setSelectedFlatRows]);

  // decide if we're letting parent know of updates to selected rows
  useMountedLayoutEffect(() => {
    // If we've just saved something from this table, then we want to override & uncheck everything
    if (hasJustSaved && setHasJustSaved) {
      toggleAllRowsSelected(false);
      setHasJustSaved(false);

      // otherwise, we want to trigger selected rows update to parent when row is clicked
    } else {
      const selectedIdKeys = Object.keys(selectedRowIds);
      const selectedIds = selectedIdKeys
        .map((x) => data[parseInt(x)])
        .map(
          (x) =>
            // @ts-ignore
            x?.id || x?.[selectedRowIndex] || x?.[initialDefaultSortByColumnId],
        )
        .filter((x) => x !== null);
      setSelectedRows(selectedIds);
      // When data gets updated with this function, set a flag
      // to disable all of the auto resetting
      skipPageResetRef.current = true;
    }
  }, [selectedRowIds, setSelectedRows, hasJustSaved]);

  return (
    <>
      <TopHeaderWrapper topHeaderWrapperVPadding={topHeaderWrapperVPadding}>
        {leftHeader || null}
        {showGlobalFilter && (
          <GlobalFilter
            analyticsAttr={analyticsAttr}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
      </TopHeaderWrapper>
      <TableContainer>
        <StyledTable
          {...getTableProps()}
          analytics-attr={cleanAnalyticsStringList([analyticsAttr, 'table'])}
          className={tableClass}
          style={tableStyles}
        >
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  let inlineHeaderProps = {
                    alignItems: 'center' as const,
                    className: 'h-flex-nowrap',
                  };
                  // @ts-ignore
                  if (column.inlineHeaderProps) {
                    inlineHeaderProps = {
                      ...inlineHeaderProps,
                      // @ts-ignore
                      ...column.inlineHeaderProps,
                    };
                  }
                  const columnHeaderText = column.render('Header');
                  return (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <Flex
                        {...inlineHeaderProps}
                        analytics-attr={cleanAnalyticsStringList([
                          `${
                            // @ts-ignore
                            columnHeaderText?.props?.text ||
                            // @ts-ignore
                            columnHeaderText?.props?.column?.placeholderOf
                              ?.analyticsAttrIndex ||
                            columnHeaderText
                          }`,
                          'table header',
                          analyticsAttr,
                        ])}
                      >
                        {_isString(columnHeaderText) ? (
                          <Text
                            mr={1}
                            fontSize={['xs', null, null, 'sm', null]}
                          >
                            {columnHeaderText}
                          </Text>
                        ) : (
                          columnHeaderText
                        )}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Stack spacing={0} minW={4} mr={1}>
                              <ArrowUpIcon
                                color={'brand.black'}
                                fontSize={14}
                              />
                            </Stack>
                          ) : (
                            <Stack spacing={0} minW={4} mr={1}>
                              <ArrowDownIcon
                                color={'brand.black'}
                                fontSize={14}
                              />
                            </Stack>
                          )
                        ) : column.canSort ? (
                          <Stack spacing={0} minW={4} mr={1}>
                            <ArrowUpIcon
                              color={'brand.gray-400'}
                              fontSize={14}
                            />
                            <ArrowDownIcon
                              color={'brand.gray-400'}
                              fontSize={14}
                            />
                          </Stack>
                        ) : null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const rowProps = { ...row.getRowProps() };
              delete rowProps.role;
              return (
                <Fragment {...rowProps}>
                  <Tr className={tableRowClass}>
                    {row.cells.map((cell) => {
                      const headerText =
                        // @ts-ignore
                        cell.column.Header?.props?.text || cell.column.Header;
                      const analyticsStr =
                        // @ts-ignore
                        row?.values[cell?.column?.analyticsAttrIndex] || null;
                      return (
                        <Td
                          {...cell.getCellProps()}
                          analytics-attr={cleanAnalyticsStringList([
                            analyticsStr,
                            headerText,
                          ])}
                        >
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </Tr>
                  {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                  {row.isExpanded ? (
                    <Tr>
                      <Td colSpan={visibleColumns.length}>
                        {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                        {renderRowSubComponent!({ row })}
                      </Td>
                    </Tr>
                  ) : null}
                </Fragment>
              );
            })}
          </Tbody>
        </StyledTable>
      </TableContainer>
      {pageOptions.length > 1 && !serverPagination && (
        <Pagination
          analyticsAttr={analyticsAttr}
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageSize={pageSize}
        />
      )}
    </>
  );
};

export { IndeterminateCheckbox, Table };
