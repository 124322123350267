import { Box } from '@chakra-ui/react';
import { zIndexValues } from '../../constants/zIndexValues';

const BrandBar = () => (
  <Box
    bg={'brand.red-purple-gradient'}
    w={'full'}
    position={'fixed'}
    left={0}
    height={'3px'}
    zIndex={zIndexValues.brandAndFilterBar}
  />
);

export default BrandBar;
