import React from 'react';
import { createIcon } from '@chakra-ui/icon';

export const MarketoLogoIcon = createIcon({
  displayName: 'MarketoLogo',
  viewBox: '0 0 240 90',
  path: (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 240 90'
      enableBackground='new 0 0 240 90'
    >
      <g>
        <g id='Layer_1_4_'>
          <polygon
            fill='#5944B0'
            points='58.1,25.8 58.1,64.2 67.9,56.4 67.9,31.1 		'
          />
          <polygon
            fill='#5944B0'
            points='53.5,55.8 45.6,59.3 45.6,29.1 53.5,31.5 		'
          />
          <polygon fill='#5944B0' points='35,54.9 41,53.4 41.1,34.1 35,33.1 		' />
          <polygon
            fill='#15124B'
            points='100.3,31.1 91.9,44.8 83.4,31.1 78.9,31.1 78.9,56.4 83.2,56.4 83.2,37.9 91.8,51.8 100.5,37.8
100.5,56.4 104.6,56.4 104.6,31.1 		'
          />
          <path
            fill='#15124B'
            d='M118.7,56.4l0-2.3c-1.3,1.8-3.3,2.7-5.5,2.7c-3.2,0-5.5-2.1-5.5-5.1c0-3.3,2.1-5.2,6.2-5.9l4.6-0.7v-0.3
c0-1.9-1.4-2.9-3.8-2.9c-2.3,0-4.3,1-6,2.5v-4.1c1.5-1.3,3.7-2.1,6.3-2.1c4.9,0,7.6,2.5,7.6,6.7v11.4L118.7,56.4z M114.2,53.4
c2.6,0,4.4-1.7,4.4-4.1v-1l-3.9,0.6c-1.9,0.3-2.9,1.2-2.9,2.4C111.8,52.6,112.8,53.4,114.2,53.4'
          />
          <path
            fill='#15124B'
            d='M136.5,42.7c-0.2-0.1-0.5-0.2-1.1-0.2c-3.1,0-4.9,2.4-4.9,6v7.8h-4.2V38.7h4.1v3c0.9-2.1,3-3.4,5.3-3.4
c0.5,0,0.7,0,0.8,0.1L136.5,42.7z'
          />
          <polygon
            fill='#15124B'
            points='139.2,31.1 139.2,56.4 143.4,56.4 143.4,49.4 144.3,48.5 150.6,56.4 155.7,56.4 147.4,46.4
155.1,38.7 150,38.7 143.4,45.4 143.4,31.1 		'
          />
          <path
            fill='#15124B'
            d='M171.4,54.9c-2.1,1.2-4.4,1.9-6.8,1.9c-5.8,0-9.5-4-9.5-9.3c0-5.3,3.7-9.2,8.9-9.2c5.2,0,8.5,3.9,8.5,9.1
c0,0.5,0,1-0.1,1.4h-13.1c0.3,2.4,2.3,4.4,5.7,4.4c2.2,0,4.4-0.8,6.5-2.4L171.4,54.9L171.4,54.9z M168.4,45.7
c-0.3-2.4-2-4.1-4.5-4.1c-2.5,0-4.4,2-4.7,4.1H168.4z'
          />
          <path
            fill='#15124B'
            d='M183,53c0.7,0,1.4-0.2,2-0.5v3.7c-0.7,0.4-1.7,0.6-3.2,0.6c-3.6,0-5.7-2.2-5.7-5.9v-8.7h-3.1l7.3-9.3v5.8
h4.7v3.5h-4.7v7.9C180.2,52.1,181.1,53,183,53'
          />
          <path
            fill='#15124B'
            d='M205,47.5c0,5.2-4,9.3-9.4,9.3s-9.4-4.1-9.4-9.3s4-9.3,9.4-9.3S205,42.3,205,47.5 M190.3,47.5
c0,3,2.3,5.5,5.3,5.5c2.9,0,5.3-2.4,5.3-5.5s-2.3-5.5-5.3-5.5S190.3,44.5,190.3,47.5'
          />
        </g>
        <g id='Layer_2_1_'>
          <text
            transform='matrix(1 0 0 1 198.4479 38.2595)'
            fontFamily="'ArialMT'"
            fontSize='3.8641px'
          >
            TM
          </text>
        </g>
      </g>
    </svg>
  ),
});
