export const MessageIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
  >
    <path
      d='M6.718 7.81704C6.49761 7.93729 6.25056 8.00031 5.9995 8.00031C5.74844 8.00031 5.50139 7.93729 5.281 7.81704L0 4.93604V9.50004C0 9.89786 0.158035 10.2794 0.43934 10.5607C0.720644 10.842 1.10218 11 1.5 11H10.5C10.8978 11 11.2794 10.842 11.5607 10.5607C11.842 10.2794 12 9.89786 12 9.50004V4.93604L6.718 7.81704Z'
      fill='#323F5A'
    />
    <path
      d='M6 7C5.91649 6.99994 5.83432 6.97897 5.761 6.939L0.261 3.939C0.182056 3.89604 0.116151 3.83259 0.0702204 3.75534C0.02429 3.67808 3.23441e-05 3.58988 0 3.5L0 2.5C0 2.10218 0.158035 1.72064 0.43934 1.43934C0.720644 1.15804 1.10218 1 1.5 1H10.5C10.8978 1 11.2794 1.15804 11.5607 1.43934C11.842 1.72064 12 2.10218 12 2.5V3.5C12 3.58988 11.9757 3.67808 11.9298 3.75534C11.8838 3.83259 11.8179 3.89604 11.739 3.939L6.239 6.939C6.16568 6.97897 6.08351 6.99994 6 7Z'
      fill='#323F5A'
    />
  </svg>
);
