import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { navbarSizeStr } from 'app/src/constants/navbarSize';

type MainNavbarIconProps = {
  isActive?: boolean;
  icon: ReactNode;
  link: string;
};

export const MainNavbarIcon = ({
  icon,
  isActive,
  link,
}: MainNavbarIconProps) => {
  const history = useHistory();

  return (
    <Flex
      w={navbarSizeStr}
      h={navbarSizeStr}
      align={'center'}
      justify={'center'}
      borderLeftColor={isActive ? 'brand.crimson' : 'transparent'}
      borderLeftWidth={2}
      onClick={() => history.push(link)}
      _hover={{ cursor: 'pointer' }}
    >
      {icon}
    </Flex>
  );
};
