import {Box, chakra, Flex, FlexProps, Text} from '@chakra-ui/react';

type LoadingProps = FlexProps & {
  dotColor?: 'white' | 'color';
  overlay?: 'absolute' | 'fixed' | 'invisible' | 'none';
  text?: string;
};

function Loading({
  dotColor = 'color',
  overlay = 'absolute',
  text = undefined,
  ...props
}: LoadingProps) {
  return (
    <Flex
      align={'center'}
      bg={'rgba(39, 74, 103, 0.5)'}
      className={`c-loader--${dotColor}`}
      direction={'column'}
      justify={'center'}
      p={8}
      h={400}
      {...props}
    >
      <Box position={'absolute'}>
        <chakra.svg
          className={'c-loader__animation'}
          xmlns={'http://www.w3.org/2000/svg'}
          viewBox={'0 0 70 40'}
        >
          <chakra.circle
            className={'c-loader__ball'}
            cx={'5'}
            cy={'20'}
            r={'3.5'}
          />
          <chakra.circle
            className={'c-loader__ball'}
            cx={'20'}
            cy={'20'}
            r={'3.5'}
          />
          <chakra.circle
            className={'c-loader__ball'}
            cx={'35'}
            cy={'20'}
            r={'3.5'}
          />
          <chakra.circle
            className={'c-loader__ball'}
            cx={'50'}
            cy={'20'}
            r={'3.5'}
          />
          <chakra.circle
            className={'c-loader__ball'}
            cx={'65'}
            cy={'20'}
            r={'3.5'}
          />
        </chakra.svg>
        {text && (
          <Text color={'brand.white'} mt={8} fontSize={'lg'}>
            {text}
          </Text>
        )}
      </Box>
    </Flex>
  );
}

export default Loading;
