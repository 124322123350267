import { MIN_PASSWORD_LENGTH } from '../constants/minPasswordLength';

export const isNewPasswordValid = (password) => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*?#&]{8,}$/;
  return password.length >= MIN_PASSWORD_LENGTH && re.test(password);
};

// regex will check for:
// {text}@{text}.{text - length >= 2}
/*eslint no-useless-escape: 0*/
export function isEmailValid(email = '') {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
