import { ApiPredictionForType, Maybe } from 'shared/graphql/generatedApiTypes';

export const getChartNarratives = (
  predictionCalculatedForType: Maybe<ApiPredictionForType>,
) => {
  if (!predictionCalculatedForType) {
    return [];
  }
  switch (predictionCalculatedForType) {
    case ApiPredictionForType.Acquisition:
      return [
        'Account is a prospect, customer, or past customer of Merz',
        'Account is not a customer of Xeomin',
        'Xeomin has never been purchased',
      ];
    case ApiPredictionForType.Retention:
      return [
        'Account is a customer or past customer of Merz',
        'Account is a customer of Xeomin',
        'Xeomin has not been purchased in the last fiscal quarter',
        'Xeomin has been purchased prior to the last fiscal quarter',
      ];
    case ApiPredictionForType.Volume:
      return [
        'Account is a customer of Merz',
        'Account is a customer of Xeomin',
        'Xeomin has been purchased in the last fiscal quarter',
      ];
    default:
      return [];
  }
};
