import { EngagementStatusItemType } from '../types/homeTypes';
import { Tooltip } from '../../../../../shared/components/Core';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
  cleanStringCase,
  pluralizeAccountsString,
} from 'shared/helpers/formatHelpers';

export const EngagementItem = ({
  label,
  maxYValue,
  x,
  y,
  tooltip,
}: EngagementStatusItemType) => {
  return (
    <Tooltip
      content={tooltip}
      hideIcon={true}
      analyticsAttr={`engagement-status-${label}`}
    >
      <Box mt={4}>
        <Flex
          justify={'center'}
          flexDirection={'column'}
          bg={'brand.gray-200'}
          position={'relative'}
          minH={'200px'}
        >
          <div className={'h-ph-md engagement-item'}>
            <Text align={'center'} fontWeight={'bold'} color={'brand.black'}>
              {cleanStringCase(x)}
            </Text>
            <Text
              align={'center'}
              color={'brand.black'}
            >{`${y.toLocaleString()} ${pluralizeAccountsString(y)}`}</Text>
          </div>
          <div
            className={'progress-after'}
            style={{ height: `${(y / maxYValue) * 100}%` }}
          />
        </Flex>
        <Box bg={'brand.gray-200'} mt={2} px={4} py={1}>
          <Text align={'center'} as={'i'} color={'brand.black'}>
            {cleanStringCase(label)}
          </Text>
        </Box>
      </Box>
    </Tooltip>
  );
};
