import { Grid, GridItem } from '@chakra-ui/react';
import MerzPredictionRecommendation from 'app/src/components/GaugeChart/components/MerzPredictionRecommendation';
import MultipleProductPredictionsSection from 'app/src/components/GaugeChart/components/MultipleProductPredictionsSection';
import SignalTimeline from 'app/src/components/SignalTimeline';
import React from 'react';
import About from '../About';
export default function MerzCompanyDetailsSummmaryTab() {
  return (
    <Grid templateColumns='repeat(8, 1fr)' h='100%' gap='4'>
      <GridItem colSpan={6}>
        <div>
          <MerzPredictionRecommendation />
          <MultipleProductPredictionsSection />
        </div>
      </GridItem>
      <GridItem colSpan={2}>
        <About />
        <SignalTimeline />
      </GridItem>
    </Grid>
  );
}
