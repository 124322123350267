import { Stack, Text } from '@chakra-ui/react';
import {
  LastInboundLogic,
  LastInOutboundTooltip,
  LastOutboundLogic
} from 'app/src/components/TableSection/renderers/AccountDrawerItemRenderer';
import { SignalIntensity } from 'app/src/components/TableSection/renderers/SignalintensityIcon';
import { Tooltip } from 'shared/components/Core';

export const AccountHeaderItem = ({
  labelText,
  itemValue,
  tooltip,
}: {
  labelText: string;
  itemValue: string;
  tooltip?: string;
}) => (
  <Stack spacing={1} data-cy={'account-header'}>
    {tooltip ? (
      <Tooltip content={tooltip}>
        <Text fontSize={'12px'} color={'#152F45'}>
          {labelText}
        </Text>
      </Tooltip>
    ) : (
      <Text fontSize={'12px'} color={'#152F45'}>
        {labelText}
      </Text>
    )}
    <Text>{itemValue}</Text>
  </Stack>
);
const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1).toLowerCase();
const TooltipText = {
  company:
    "A company's current relationship status to your business based on its purchase history.",
  lastOutboundActivity:
    'A brief description of the most recent outbound activity to a company (e.g., made call, sent sample, etc.) and when it occurred relative to today.',
  lastInboundResponse:
    'A brief description of the most recent inbound response from a company (e.g., purchased product) and when it occurred relative to today.',
};

export const CustomeAccountHeader = ({
  labelText,
  itemValue,
  tooltip,
  field,
}: {
  labelText: string;
  itemValue: string;
  tooltip?: string;
  field: string;
}) => (
  <Stack spacing={1} data-cy={'account-header'}>
    {tooltip ? (
      field === 'lastInboundResponse' ? (
        LastInOutboundTooltip(
          TooltipText.lastInboundResponse,
          labelText,
          'lastInboundResponse',
          'accountHeader',
        )
      ) : (
        LastInOutboundTooltip(
          TooltipText.lastOutboundActivity,
          labelText,
          'lastInboundResponse',
          'accountHeader',
        )
      )
    ) : (
      <Text fontSize={'12px'} color={'#152F45'}>
        {labelText}
      </Text>
    )}
    <Text>
      {field === 'lastInboundResponse' ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className='intensity-span'>
            {capitalize(itemValue['engagementStatus'])}
            {SignalIntensity[itemValue['signalActivityIntensity']]}
          </span>
          <span
            style={{ color: '#70798B', fontWeight: '600', fontSize: '12px' }}
          >
            {LastInboundLogic(itemValue)}
          </span>{' '}
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className='intensity-span'>
            {capitalize(itemValue['outreachStatus'])}
            {SignalIntensity[itemValue['signalResponseIntensity']]}
          </span>
          <span
            style={{ color: '#70798B', fontWeight: '600', fontSize: '12px' }}
          >
            {LastOutboundLogic(itemValue)}
          </span>{' '}
        </div>
      )}
    </Text>
  </Stack>
);
