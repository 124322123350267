import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Container, Input, Txt } from 'shared/components/Core';
import { PasswordForgotLink } from '../PasswordForgot';

import * as ROUTES from '../../constants/routes';

import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import { Stack } from '@chakra-ui/react';
import { isEmailValid } from 'shared/helpers/validationHelpers';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import AuthManager from 'shared/firebase/classes/AuthManager';

const LogInPage = () => {
  useEffect(() => {
    updateAppHeaders('Login');
  }, []);
  return (
    <Container ph={'xs'} pv={'4xl'} width={'xs'}>
      <Stack spacing={10}>
        <Txt align={'center'} size={'3xl'} weight={'heavy'}>
          {'Welcome to Klearly'}
        </Txt>
        <LogInForm />
        <PasswordForgotLink />
      </Stack>
    </Container>
  );
};

const LogInForm = () => {
  // setup state and base items
  const history = useHistory();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  // setup consts
  const {
    bind: bindEmail,
    reset: resetEmail,
    value: email,
  } = useInput('', {
    autoComplete: 'email',
    error: error?.message,
    id: 'emailAddress',
    name: 'email',
    label: 'Email Address',
    placeholder: 'ex. seeing@klearly.com',
    type: 'email',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindPassword,
    reset: resetPassword,
    value: password,
  } = useInput('', {
    autoComplete: 'current-password',
    id: 'password',
    label: 'Password',
    name: 'password',
    placeholder: 'ex. ••••••••••',
    type: 'password',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const isLoginButtonDisabled = !isEmailValid(email);
  // setup functions
  const _onSubmit = (event) => {
    event.preventDefault();
    setError(undefined);
    setLoading(true);
    if (!isEmailValid(email)) {
      setError({
        code: 'auth/error',
        message: 'Valid email & password required.',
      });
      setLoading(false);
      return;
    }
    return AuthManager.signInWithEmailAndPassword(email, password)
      .then(() => {
        resetEmail();
        resetPassword();
        setError(undefined);
        setLoading(false);
        history.push(ROUTES.ACCOUNT[0]);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };
  // render
  return (
    <form className={'c-form h-pv-lg'} onSubmit={_onSubmit}>
      <Stack spacing={4}>
        <div>
          <Input {...bindEmail} />
          <Input {...bindPassword} />
        </div>
        <Button
          isDisabled={isLoginButtonDisabled}
          isLoading={loading}
          type={'submit'}
          text={'Log In'}
          variant={'active'}
        />
      </Stack>
    </form>
  );
};

export default LogInPage;

export { LogInForm };
