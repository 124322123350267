import { ApiMessageType } from 'shared/graphql/generatedApiTypes';

export const getMessageLabel = (type: ApiMessageType) => {
  switch (type) {
    case ApiMessageType.Insight:
      return 'Insight';
    default:
      return 'Message';
  }
};
