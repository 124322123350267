import { FirebaseInvokedAuth } from '../types/firebaseTypes';
import _isEqual from 'lodash/isEqual';
import { KlearlyUserType } from '../types/klearlyUserType';
import FirebaseApp from '../initializeFirebase';

type AuthManagerType = {
  confirmPasswordReset: (oobCode: string, password: string) => Promise<void>;
  klearlyUser: KlearlyUserType;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  setCurrentUser: (newUser: FirebaseInvokedAuth['currentUser'] | null) => void;
  setKlearlyUser: (user: KlearlyUserType | null) => void;
  signInWithEmailAndPassword: (
    email: string,
    password: string,
  ) => Promise<void>;
  signOut: () => Promise<void>;
};

class AuthManager implements AuthManagerType {
  public klearlyUser: KlearlyUserType | null = null;
  public currentUser: FirebaseInvokedAuth['currentUser'] | null = null;
  public invokedAuth: FirebaseInvokedAuth | null = null;

  private constructor(private auth: FirebaseInvokedAuth) {
    this.klearlyUser = null;
    this.invokedAuth = auth;
    this.currentUser = auth.currentUser;
  }

  private static instance: AuthManager;

  static getInstance(auth: FirebaseInvokedAuth): AuthManager {
    if (!auth) {
      //@ts-ignore
      return;
    }
    if (!this.instance) {
      this.instance = new AuthManager(auth);
    }
    return this.instance;
  }

  public confirmPasswordReset = async (code, password) =>
    this.invokedAuth?.confirmPasswordReset(code, password);

  public setCurrentUser = (
    newUser: FirebaseInvokedAuth['currentUser'] | null,
  ) => {
    this.currentUser = newUser;
  };

  public setKlearlyUser = (user: KlearlyUserType | null = null) =>
    _isEqual(user, this.klearlyUser) && !this.klearlyUser
      ? null
      : (this.klearlyUser = user);

  public sendPasswordResetEmail = async (email) => {
    this.invokedAuth?.sendPasswordResetEmail(email);
  };
  public signInWithEmailAndPassword = async (email, password) => {
    await this.invokedAuth?.signInWithEmailAndPassword(email, password);
  };

  public signOut = async () => {
    this.setKlearlyUser(null);
    return this.invokedAuth?.signOut();
  };
}

export default AuthManager.getInstance(FirebaseApp.auth());
