import { RecommendedActionFilterOptions } from '../types/homeTypes';

export const recommendationsTableRadioButtonOptions = [
  {
    label: 'Pursue',
    value: RecommendedActionFilterOptions.PURSUE,
    analyticsAttr: 'recommendations-table-pursue',
  },
  {
    label: 'Pause',
    value: RecommendedActionFilterOptions.PAUSE,
    analyticsAttr: 'recommendations-table-pause',
  },
];
