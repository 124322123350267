import {createIcon} from '@chakra-ui/icon';

export const SteadyIcon = createIcon({
  displayName: 'Steady',
  viewBox: '0 0 21 20',
  path: (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M10.3335 4.16699V19.167'
          stroke='#152F45'
          fill={'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.3337 4.16683C11.2541 4.16683 12.0003 3.42064 12.0003 2.50016C12.0003 1.57969 11.2541 0.833496 10.3337 0.833496C9.41318 0.833496 8.66699 1.57969 8.66699 2.50016C8.66699 3.42064 9.41318 4.16683 10.3337 4.16683Z'
          stroke='#152F45'
          fill={'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.8335 7.5H12.8335'
          stroke='#152F45'
          fill={'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.0003 15.105C15.7537 17.5175 13.2362 19.1666 10.3337 19.1666C7.43116 19.1666 4.91366 17.5183 3.66699 15.1058'
          stroke='#152F45'
          fill={'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.66699 18.3333V15H7.00033'
          stroke='#152F45'
          fill={'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.667 15H17.0003V18.3333'
          stroke='#152F45'
          fill={'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_761:2800'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0.333496)'
          />
        </clipPath>
      </defs>
    </svg>
  ),
});
