import {forwardRef, ReactElement, ReactNode, useImperativeHandle, useRef,} from 'react';

import {ResponsiveContainer} from 'recharts';

import {Txt} from './index';
import {BarChartDataPayloadType} from './BarChart';

export type RechartsTooltipPayloadType = {
  expectedRange?: number;
  accountCount?: number;
  signalCount?: number;
  startDate?: string;
  endDate?: string;
  fill?: string;
  key?: string;
  label?: string;
  name?: string;
  recommendedGoal?: number;
  toolText?: string;
  value?: number;
  x?: number | string;
  y?: number | string;
  z?: number | string;
};

type RechartsBaseType = {
  chartClickableType?: string;
  children: ReactElement;
  className?: string;
  height: string | number;
  isChartClickable?: boolean;
  width: string | number;
};

type RechartsTooltipType = {
  active?: boolean;
  lightBackground?: boolean; // use to show a lighter grey background
  payload?: Array<{ payload: BarChartDataPayloadType }>;
  renderContent: (
    dataPayload: RechartsTooltipPayloadType,
  ) => JSX.Element | ReactNode;
};

const RechartsTooltip = ({
  active,
  lightBackground,
  payload,
  renderContent,
}: RechartsTooltipType) => {
  if (active && payload) {
    const dataPayload = payload?.[0]?.payload;
    return (
      <div
        className={'c-tooltip__arrow'}
        style={{
          backgroundColor: lightBackground
            ? 'var(--color-gray-200)'
            : 'var(--global-tooltip-bg)',
          margin: '0px',
          whiteSpace: 'nowrap',
        }}
      >
        {renderContent(dataPayload)}
      </div>
    );
  }
  return null;
};

const RechartsBase = forwardRef(
  (
    {
      chartClickableType = 'bar',
      children,
      className = undefined,
      height = '100%',
      isChartClickable = false,
      width = '100%',
    }: RechartsBaseType,
    ref,
  ) => {
    // setup consts
    const wrapperId = 'recharts-base-wrapper';
    // setup refs
    const wrapperRef = useRef(null);
    // setup functions
    useImperativeHandle(ref, () => ({
      getInfo() {
        const element = document.getElementById(wrapperId);
        const bounds = element?.getBoundingClientRect();
        return bounds;
      },
    }));
    return (
      <div id={wrapperId} ref={wrapperRef}>
        <Txt align={'center'} as={'i'} className={'h-mb-sm'}>
          {isChartClickable
            ? `(click on ${chartClickableType} to see details)`
            : ''}
        </Txt>
        <ResponsiveContainer
          ref={ref}
          className={className}
          height={height}
          width={width}
        >
          {children}
        </ResponsiveContainer>
      </div>
    );
  },
);

export { RechartsBase, RechartsTooltip };
