import { useMemo, useRef, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  FormLabel,
  SimpleGrid,
  Checkbox as AllExportCheckbox,
} from '@chakra-ui/react';
import { useApolloClient } from '@apollo/client';

import { Button, Checkbox } from 'shared/components/Core';
import { CSVLink } from 'react-csv';
import { GetAccountsListDocument } from 'shared/graphql/generatedApiTypes';

const csvFilenamePrefix = new Date()
  .toLocaleDateString('en-US')
  .replace('/', '')
  .replace('/', '');

const accountExportFields = {
  crmAccountId: 'CRM Account ID',
  accountName: 'Name',
  addressStreet: 'Street Address',
  addressCity: 'City',
  addressRegion: 'State/Province',
  addressZipCode: 'Postal Code',
  country: 'Country',
  industry: 'Industry',
  annualRevenue: 'Annual Revenue',
  employeeCount: 'Size',
  engagementStatus: 'Engagement Status',
  outreachStatus: 'Outreach Status',
  signalResponseIntensity: 'Engagement Intensity',
  signalActivityIntensity: 'Outreach Intensity',
};

export const useAccountCsvExport = (
  selectedFlatRows: string | any[],
  pageSize: number,
  selectedSort: any,
  pageInfo: { totalEntries: number },
) => {
  const [selectedExportFields, setSelectedExportFields] = useState<string[]>(
    Object.keys(accountExportFields),
  );
  const apolloClient = useApolloClient();

  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [radioSelect, setRadioSelect] = useState<any>(false);
  const csvLinkRef = useRef<{ link: { click: () => void } }>(null);
  const csvHeaders = useMemo(
    () =>
      selectedExportFields.map((field) => ({
        label: accountExportFields[field],
        key: field,
      })),
    [selectedExportFields],
  );

  const handleExportData = async () => {
    if (radioSelect) {
      try {
        const query = {
          query: GetAccountsListDocument,
          variables: {
            messageCountDaysAgo: 7,
            page: 1,
            limit: 1000,
            sort: selectedSort,
            acqType: ['ACQUISITION'],
            retentionType: ['RETENTION'],
            fitType: ['FIT'],
          },
        };
        //@ts-ignore
        const { data: queryData } = await apolloClient.query(query);

        if (queryData) {
          setSelectedData(queryData?.accounts?.accounts ?? null);
        }
        csvLinkRef?.current?.link?.click();
      } catch (err) {
        console.log(err);
      }
    } else {
      csvLinkRef?.current?.link?.click();
    }
  };
  useEffect(() => {
    setRadioSelect(false);
  }, []);

  return {
    accountExportFieldsCheckboxGrid: (
      <>
        <SimpleGrid columns={2}>
          {Object.keys(accountExportFields).map((key) => {
            const checked = selectedExportFields.find(
              (selected) => selected === key,
            );
            return (
              <Flex align={'center'} gap={1} key={key}>
                <Checkbox
                  key={key}
                  id={key}
                  checked={!!checked}
                  onChange={() =>
                    checked
                      ? setSelectedExportFields((prevState) =>
                          prevState.filter((item) => item !== key),
                        )
                      : setSelectedExportFields((prevState) => [
                          ...prevState,
                          key,
                        ])
                  }
                />
                <FormLabel htmlFor={key} mt={1}>
                  {accountExportFields[key]}
                </FormLabel>
              </Flex>
            );
          })}
        </SimpleGrid>
        {pageSize === selectedFlatRows.length && (
          <Box>
            <AllExportCheckbox
              onChange={(e) => {
                setRadioSelect(e.target.checked);
              }}
              isDisabled={pageInfo?.totalEntries < 1000 ? true : false}
              checked={radioSelect}
              className='all-select-checkbox'
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>Export all records that match the filter criteria</span>
                <span style={{ fontSize: '14px' }}>
                  If your filter criteria resulted in more than 1000 <br />{' '}
                  records, only the first 1000 will be exported.
                </span>
              </div>
            </AllExportCheckbox>
          </Box>
        )}
      </>
    ),
    accountExportButton: (
      <>
        <CSVLink
          className={'hidden'}
          data={radioSelect ? selectedData : selectedFlatRows}
          filename={`${csvFilenamePrefix}_accounts.csv`}
          headers={csvHeaders}
          ref={csvLinkRef}
          target={'_blank'}
        />
        <Button
          fontSize={['sm', null, null, 'md', null]}
          text={'Export'}
          variant={'action'}
          onClick={handleExportData}
          isDisabled={!selectedExportFields.length}
        />
      </>
    ),
  };
};
