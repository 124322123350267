import { createIcon } from '@chakra-ui/icon';
import { CheckIcon } from '@chakra-ui/icons'

export const HubSpotLogo = createIcon({
  displayName: 'HubSpotLogo',
  viewBox: '0 0 585.44 164.79',
  path: (
    <>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 585.44 164.79'>
      <defs>
        <clipPath id='clip-path' transform='translate(0 -1.17)'>
          <rect fill={'none'} width='585.44' height='165.96' />
        </clipPath>
        <clipPath id='clip-path-3' transform='translate(0 -1.17)'>
          <rect
            fill={'none'}
            x='375.89'
            y='0.13'
            width='156.42'
            height='163.35'
          />
        </clipPath>
      </defs>
      <title>Asset 3</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Outlines_-_Full_Color' data-name='Outlines - Full Color'>
          <g clipPath={'url(#clip-path)'}>
            <polygon
              fill={'#33475b'}
              points='63.4 26.3 63.4 73.11 20.41 73.11 20.41 26.3 0 26.3 0 138.04 20.41 138.04 20.41 92.75 63.4 92.75 63.4 138.04 83.81 138.04 83.81 26.3 63.4 26.3'
            />
            <g clipPath={'url(#clip-path)'}>
              <path
                fill={'#33475b'}
                d='M149.13,103.09a16.88,16.88,0,0,1-33.77,0V55.23H96v47.86a36.21,36.21,0,0,0,72.42,0V55.23H149.13Z'
                transform='translate(0 -1.17)'
              />
              <path
                fill={'#33475b'}
                d='M292.57,60.16c0-9.81,6.49-12.92,13.6-12.92,5.73,0,13.3,4.36,18.25,9.65L337.1,41.95c-6.33-8.56-19.17-14.48-29.68-14.48-21,0-36.19,12.3-36.19,32.69,0,37.83,46.24,25.83,46.24,47,0,6.53-6.34,12.3-13.6,12.3-11.45,0-15.16-5.6-20.42-11.52l-14.07,14.63c9,11.05,20.1,16.66,33.4,16.66,19.95,0,36-12.45,36-31.91,0-42-46.24-28.95-46.24-47.16'
                transform='translate(0 -1.17)'
              />
              <path
                fill={'#33475b'}
                d='M578.63,121.36c-11.44,0-14.69-4.95-14.69-12.53V75.28h17.78v-17H563.94V35.85l-19.63,8.81V113c0,17.48,12.06,26.29,28.6,26.29a39.62,39.62,0,0,0,7.74-.62l4.79-17.63c-2.16.15-4.64.3-6.81.3'
                transform='translate(0 -1.17)'
              />
              <path
                fill={'#33475b'}
                d='M222.82,55.79c-9.59,0-16.28,2.78-22.75,9.13V28.14H180.68V96.31c0,25.52,18.45,43,39.18,43,23,0,43.23-17.79,43.23-41.75,0-23.66-18.62-41.76-40.27-41.76m-.12,64a22,22,0,1,1,22-22,22,22,0,0,1-22,22'
                transform='translate(0 -1.17)'
              />
              <path
                fill={'#33475b'}
                d='M433.36,96.54c0-24-20.19-41.75-43.23-41.75-20.73,0-39.18,17.48-39.18,43V166h19.39V129.17c6.46,6.34,13.16,9.13,22.74,9.13,21.65,0,40.27-18.09,40.27-41.76M415,96.3a22,22,0,1,1-22-22,22,22,0,0,1,22,22'
                transform='translate(0 -1.17)'
              />
            </g>
            <g clipPath={'url(#clip-path-3)'}>
              <path
                fill={'#ff7a59'}
                d='M495.81,54.48V35.2a14.84,14.84,0,0,0,8.56-13.38v-.45A14.87,14.87,0,0,0,489.54,6.54h-.45a14.87,14.87,0,0,0-14.83,14.83v.45a14.84,14.84,0,0,0,8.56,13.38V54.48a42.06,42.06,0,0,0-20,8.8L409.88,22.09a16.55,16.55,0,0,0,.6-4.17,16.73,16.73,0,1,0-16.75,16.71A16.55,16.55,0,0,0,402,32.38L454.05,72.9a42.18,42.18,0,0,0,.65,47.56L438.86,136.3a13.61,13.61,0,0,0-3.93-.64,13.75,13.75,0,1,0,13.75,13.75,13.53,13.53,0,0,0-.64-3.93l15.67-15.67a42.25,42.25,0,1,0,32.1-75.33m-6.49,63.42A21.68,21.68,0,1,1,511,96.22a21.68,21.68,0,0,1-21.68,21.68'
                transform='translate(0 -1.17)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
    

   
    </>
  ),
});
