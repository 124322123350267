import { SectionComponents } from 'shared/types/sectionComponents';
import {
  ConfiguredSingleAccountQuickStat,
  ConfiguredSingleAccountQuickStatProps,
} from 'app/src/components/Account/components/sav/overview/accountSummary/ConfiguredSingleAccountQuickStat';
import {
  HomeRecommendationsTable,
  RecommendationsTableProps,
} from 'app/src/components/Home/components/HomeRecommendationsTable';
import {
  EngagementStatus,
  EngagementStatusProps,
} from 'app/src/components/Home/components/EngagementStatus';
import { SingleAccountQuickStat } from 'app/src/components/Account/components/sav/overview/accountSummary/singleAccountQuickStat/SingleAccountQuickStat';
import {
  MultipleAccountsCutsAndList,
  MultipleAccountsCutsAndListProps,
} from 'app/src/components/Account/components/mav/MultipleAccountsCutsAndList';
import {
  LabsAccordions,
  LabsAccordionsProps,
} from 'app/src/components/Lab/components/LabsAccordions';
import { Box, SimpleGrid } from '@chakra-ui/react';
import {
  SankeyChart,
  SankeyChartProps,
} from 'app/src/components/Plan/components/SankeyChart';
import { AbstractedTableSection } from 'app/src/components/TableSection/components/AbstractedTableSection';
import {
  RecommendationsTab,
  RecommendationsTabProps,
} from 'app/src/components/Account/components/sav/recommendations/RecommendationsTab';
import {
  EngagementLineChartWrapper,
  EngagementLineChartWrapperProps,
} from 'app/src/components/Home/components/EngagementLineChartWrapper';
import {
  MessageFeed,
  MessageFeedProps,
} from 'app/src/components/MessageFeed/components/MessageFeed';
import {
  SingleProductPredictionsSection,
  SingleProductPredictionsSectionProps,
} from 'app/src/components/GaugeChart/components/SingleProductPredictionsSection';
import {
  AccountHeader,
  AccountHeaderProps,
} from 'app/src/components/Account/components/sav/header/AccountHeader';
import { SingleAccountQuickStatProps } from '../types/quickStatFieldOptions';
import {
  AccountsTable,
  AccountsTableProps,
} from 'app/src/components/Account/components/mav/AccountsTable';
import {
  SavedSegmentsOrLists,
  SavedSegmentsOrListsProps,
} from 'app/src/components/ListsAndFilters/components/SavedSegmentsOrLists';
import {
  ComponentDataType,
  ComponentType,
  GridComponentType,
  SavedComponentSchema,
  StructuredFullComponent,
} from '../firebase/schemas/SavedComponent';
import { TableComponentProps } from '../firebase/types/tableTypes';
import {
  AggregatedMessageFeed,
  AggregatedMessageFeedProps,
} from 'app/src/components/AggregatedMessageFeed/components/AggregatedMessageFeed';
import {
  SignalsOverview,
  SignalsOverviewProps,
} from 'app/src/components/Account/components/sav/overview/signalsOverview/SignalsOverview';
import { SingleAccountSignalHistorySection } from 'app/src/components/Account/components/sav/singleAccountSignalHistory/SingleAccountSignalHistorySection';
import { SingleAccountSignalHistorySectionProps } from 'app/src/components/Account/types/SingleAccountSignalsTypes';
import SingleAccountPredictionsOverview from 'app/src/components/GaugeChart/components/SingleAccountPredictionsOverview';
import MultipleProductPredictionsSection from 'app/src/components/GaugeChart/components/MultipleProductPredictionsSection';
import SingleAccountPeopleList from 'app/src/components/SingleAccountPeople/components/index';
import TopCompanyList from 'app/src/components/TopCompanyList/components/TopCompanyList';
import MultipleAccountPeopleList from 'app/src/components/MultipleAccountPeopleList/components/index';
import SignalTimeline from 'app/src/components/SignalTimeline';
import MerzCompanyDetailsSummmaryTab from 'app/src/components/Account/components/MerzCompanyDetailsSummmaryTab.tsx';
import MerzPredictionRecommendation from 'app/src/components/GaugeChart/components/MerzPredictionRecommendation';
import TurnkeyCompanyDetailsSummmaryTab from 'app/src/components/Account/components/TurnkeyCompanyDetailsSummmaryTab';
import EmbeddedMetabaseReports from 'app/src/components/EmbeddedMetabaseReports';

export const StructuredComponentRenderer = (
  component: SavedComponentSchema,
) => {
  if (component.componentType === ComponentType.fullWidth) {
    const fullComponent = component.component as StructuredFullComponent;
    return ComponentRenderer(fullComponent.key, fullComponent.data);
  } else if (component.componentType === ComponentType.partWidth) {
    return <GridComponent component={component} />;
  }
};

const ComponentRenderer = (
  componentKey: SectionComponents,
  componentData: ComponentDataType,
) => {
  switch (componentKey) {
    case SectionComponents.EngagementStatus:
      return <EngagementStatus {...(componentData as EngagementStatusProps)} />;
    case SectionComponents.AggregateSignalHistory:
      return (
        <EngagementLineChartWrapper
          {...(componentData as EngagementLineChartWrapperProps)}
        />
      );
    case SectionComponents.RecommendedActionsTable:
      return (
        <HomeRecommendationsTable
          {...(componentData as RecommendationsTableProps)}
        />
      );
    case SectionComponents.PossibleAccountScoreSankey:
      return <SankeyChart {...(componentData as SankeyChartProps)} />;
    case SectionComponents.SingleAccountRecommendations:
      return (
        <RecommendationsTab {...(componentData as RecommendationsTabProps)} />
      );
    case SectionComponents.SingleAccountSignalHistory:
      return (
        <SingleAccountSignalHistorySection
          {...(componentData as SingleAccountSignalHistorySectionProps)}
        />
      );
    // case SectionComponents.SingleAccountSignalOverview:
    //   return <SignalsOverview {...(componentData as SignalsOverviewProps)} />;

    case SectionComponents.WinfluenceAndPrimaryActionFilterableList:
      return (
        <MultipleAccountsCutsAndList
          {...(componentData as MultipleAccountsCutsAndListProps)}
        />
      );
    case SectionComponents.Labs:
      return <LabsAccordions {...(componentData as LabsAccordionsProps)} />;
    case SectionComponents.ConfiguredSingleAccountQuickStat:
      return (
        <ConfiguredSingleAccountQuickStat
          {...(componentData as ConfiguredSingleAccountQuickStatProps)}
        />
      );
    case SectionComponents.SingleAccountHeader:
      return <AccountHeader {...(componentData as AccountHeaderProps)} />;
    case SectionComponents.SingleAccountQuickStat:
      return (
        <SingleAccountQuickStat
          {...(componentData as SingleAccountQuickStatProps)}
        />
      );
    case SectionComponents.SingleAccountMessageFeed:
      return <MessageFeed {...(componentData as MessageFeedProps)} />;
    case SectionComponents.TableSection:
      return (
        <AbstractedTableSection {...(componentData as TableComponentProps)} />
      );
    case SectionComponents.SingleProductPredictionsSection:
      return (
        <SingleProductPredictionsSection
          {...(componentData as SingleProductPredictionsSectionProps)}
        />
      );
    case SectionComponents.AccountsTable:
      return <AccountsTable {...(componentData as AccountsTableProps)} />;
    case SectionComponents.FiltersOrLists:
      return (
        <SavedSegmentsOrLists
          {...(componentData as SavedSegmentsOrListsProps)}
        />
      );
    case SectionComponents.AggregatedMessageFeed:
      return (
        <AggregatedMessageFeed
          {...(componentData as AggregatedMessageFeedProps)}
        />
      );
    // case SectionComponents.SingleAccountPredictionsOverview:
    //   return <SingleAccountPredictionsOverview />;
    case SectionComponents.SingleAccountPeopleList:
      return <SingleAccountPeopleList />;
    case SectionComponents.TopCompanyList:
      return <TopCompanyList />;
    case SectionComponents.MultipleAccountPeopleList:
      return <MultipleAccountPeopleList />;
    // case SectionComponents.MultipleProductPredictionsSection:
    //   return <MultipleProductPredictionsSection />
    // case SectionComponents.SignalTimeline:
    //   return <SignalTimeline />;
    // case SectionComponents.MerzPredictionRecommendation:
    //   return <MerzPredictionRecommendation />
    case SectionComponents.MerzCompanyDetailsSummmaryTab:
      return <MerzCompanyDetailsSummmaryTab />;
    case SectionComponents.TurnkeyCompanyDetailsSummmaryTab:
      return <TurnkeyCompanyDetailsSummmaryTab />;
    case SectionComponents.EmbeddedMetabaseReports:
      return <EmbeddedMetabaseReports />;
    default:
      return null;
  }
};

export const GridComponent = ({
  component,
}: {
  component: GridComponentType;
}) => {
  return (
    <Box m={4} className='summary-tab'>
      <SimpleGrid columns={[60, null, null, null, 120]}>
        {component.subComponents.map((subcomponent, index) => (
          <Box
            className='cards'
            key={`${subcomponent.key}-${index}`}
            gridColumn={`span ${subcomponent.gridWidth}`}
            mt={[4, null, null, null, 0]}
            px={2}
          >
            {ComponentRenderer(subcomponent.key, subcomponent.data)}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};
