import React from 'react';
import { Layer, Rectangle } from 'recharts';

type SankeyNodeProps = {
  colors: string[];
  containerWidth: number;
  height: number;
  index: number;
  payload: { name: string; value: string };
  width: number;
  x: number;
  y: number;
};

export const SankeyNode = ({
  colors,
  containerWidth,
  height,
  index,
  payload,
  width,
  x,
  y,
}: SankeyNodeProps) => {
  const isOut = x + width + 6 > containerWidth;
  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={colors[index % colors.length]}
        fillOpacity='1'
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize='14'
        stroke='var(--color-black)'
        style={{ color: 'var(--color-black)' }}
      >
        {payload.name}
      </text>
    </Layer>
  );
};
