import {
  Box,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { BannerPopover } from 'app/src/components/TableSection/components/BannerPopover';
import { Button } from 'shared/components/Core';
import { CloseIcon, ViewIcon } from '@chakra-ui/icons';
import { useContext, useEffect, useState } from 'react';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { globalFilterVar } from 'app/src/apollo/rootReactiveVariables';
import { usePanelWidthAdjustments } from 'app/src/hooks/usePanelWidthAdjustments';
import AuthManager from 'shared/firebase/classes/AuthManager';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { FieldValue } from 'shared/firebase/initializeFirebase';
import _difference from 'lodash/difference';
import { GetAccountsListWithFilterVarDocument } from 'shared/graphql/generatedApiTypes';
import { ExportIcon } from 'shared/components/Core/Icon/export';
import { useAccountCsvExport } from 'app/src/components/TableSection/hooks/useAccountCsvExport';

type HiddenActionBarProps = {
  selectedRowIds: { [key: string | number]: boolean };
  toggleAllRowsSelected: (val: boolean) => void;
  pageSize: any;
  selectedSort: any;
  pageInfo: any;
};

export const HiddenActionBar = ({
  selectedRowIds,
  toggleAllRowsSelected,
  pageSize,
  selectedSort,
  pageInfo,
}: HiddenActionBarProps) => {
  const unhideAccountInteractionModal = useDisclosure();
  const exportModal = useDisclosure();
  const toast = useToast();
  const { updateExcludedIds } = useContext(AggregateDataContext);
  const { contentLeft, contentWidth } = usePanelWidthAdjustments();
  const globalFilter = useReactiveVar(globalFilterVar);
  const [selectedFlatRows, setSelectedFlatRows] = useState<any[]>([]);
  const apolloClient = useApolloClient();
  const { accountExportButton, accountExportFieldsCheckboxGrid } =
    useAccountCsvExport(selectedFlatRows, pageSize, selectedSort, pageInfo);

  useEffect(() => {
    (async () => {
      const accountQuery = await apolloClient.query({
        query: GetAccountsListWithFilterVarDocument,
        variables: {
          limit: Object.keys(selectedRowIds).length,
          filterString: JSON.stringify({
            accountFilterType: 'and',
            accountIds: Object.keys(selectedRowIds),
          }),
        },
      });
      setSelectedFlatRows(accountQuery?.data?.accounts?.accounts ?? []);
    })();
  }, [selectedRowIds]);

  const unhideSelectedCompanies = async () => {
    try {
      unhideAccountInteractionModal.onClose();
      const firebaseUserUID = AuthManager.currentUser?.uid ?? '';
      const companyName = AuthManager.klearlyUser?.companyName ?? '';
      const userSettingsDocId = `${firebaseUserUID}#${companyName}`;
      const idsToShow = selectedFlatRows.map((row) => row.id);
      if (userSettingsDocId) {
        const userSettingsDoc = await DatabaseManager.UserSettingsModel.get(
          userSettingsDocId,
        );
        if (userSettingsDoc) {
          // merge in the selected ID
          await userSettingsDoc.ref.update({
            excludedAccountIds: FieldValue.arrayRemove(...idsToShow),
          });
        }
        // update the current global filter to reflect
        if (
          userSettingsDoc?.data &&
          userSettingsDoc.data.excludedAccountIds &&
          globalFilter
        ) {
          updateExcludedIds(
            _difference(userSettingsDoc.data.excludedAccountIds, idsToShow),
          );
        }
        toggleAllRowsSelected(false);
      }
    } catch (err) {
      console.log(err);
      toast({
        title: `Error unhiding companies; please check your connection & try again`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  };

  return (
    <Box
      left={contentLeft}
      pos={'fixed'}
      w={contentWidth}
      bottom={0}
      color={'brand.white'}
    >
      <Flex
        align={'center'}
        justify={'space-between'}
        bg={'brand.klarityBlue'}
        minH={'56px'}
        p={3}
      >
        <Text ml={4} mr={2}>
          {selectedFlatRows.length} item(s) selected
        </Text>
        <Flex maxW={'60%'} gap={2} flexWrap={'wrap'} justify={'center'}>
          <BannerPopover
            popoverTitle={'Are you sure?'}
            interactionModal={unhideAccountInteractionModal}
            body={
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  unhideSelectedCompanies();
                }}
              >
                <Text>
                  Unhiding companies will include them in applied filters in the
                  future.
                </Text>
                <HStack justify={'flex-end'} spacing={2}>
                  <Button
                    onClick={() => {
                      toggleAllRowsSelected(false);
                      unhideAccountInteractionModal.onClose();
                    }}
                    variant={'actionOutline'}
                    text={'Cancel'}
                  />
                  <Button type={'submit'} text={'Confirm'} />
                </HStack>
              </form>
            }
          >
            <Button
              fontSize={['sm', null, null, 'md', null]}
              text={'Unhide'}
              onClick={unhideAccountInteractionModal.onOpen}
              variant={'banner'}
              leftIcon={<ViewIcon />}
            />
          </BannerPopover>
          <BannerPopover
            popoverTitle={'Export'}
            interactionModal={exportModal}
            body={
              <>
                <Text>Select fields:</Text>
                {accountExportFieldsCheckboxGrid}
                <HStack justify={'flex-end'} spacing={2}>
                  <Button
                    onClick={() => {
                      toggleAllRowsSelected(false);
                      exportModal.onClose();
                    }}
                    variant={'actionOutline'}
                    text={'Cancel'}
                    fontSize={['sm', null, null, 'md', null]}
                  />
                  {accountExportButton}
                </HStack>
              </>
            }
          >
            <Button
              fontSize={['sm', null, null, 'md', null]}
              text={'Export'}
              variant={'banner'}
              leftIcon={<ExportIcon />}
              onClick={exportModal.onOpen}
            />
          </BannerPopover>
        </Flex>
        <Button
          mr={24}
          ml={6}
          text={'Cancel'}
          leftIcon={<CloseIcon boxSize={3} />}
          onClick={() => {
            toggleAllRowsSelected(false);
            unhideAccountInteractionModal.onClose();
          }}
        />
      </Flex>
    </Box>
  );
};
