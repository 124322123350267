import {Link, LinkProps, Text, useBreakpointValue} from '@chakra-ui/react';
import {NavLink, useLocation} from 'react-router-dom';
import {AnalyticsAttrType} from 'shared/types/coreTypes.d';

type StyledLinkProps = AnalyticsAttrType &
  LinkProps & {
    onClick: () => void;
    secondaryText?: string;
    text: string;
    to: string;
  };

const StyledLink = ({
  onClick,
  secondaryText,
  text,
  to,
  ...props
}: StyledLinkProps) => {
  const { pathname } = useLocation();
  const size = useBreakpointValue({ base: 'mobileMenu', lg: 'normal' });

  return (
    <Link
      as={NavLink}
      {...props}
      to={to}
      onClick={onClick}
      size={size}
      variant={
        to.includes(pathname) && pathname !== '/' ? 'active' : 'inactive'
      }
    >
      {text}
      {!!secondaryText && (
        <Text as={'sup'} fontSize={'3xs'}>
          {secondaryText}
        </Text>
      )}
    </Link>
  );
};

export default StyledLink;
