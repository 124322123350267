import { createIcon } from '@chakra-ui/icon';

export const SortIcon = createIcon({
  displayName: 'SelectedCompaniesIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      width='24'
      viewBox='0 0 24 24'
    >
      <title>swap vertical</title>
      <g
        strokeLinecap='square'
        strokeWidth='2'
        fill='none'
        stroke='#2997fc'
        strokeLinejoin='miter'
        className='nc-icon-wrapper'
        strokeMiterlimit='10'
      >
        <line
          data-cap='butt'
          x1='7'
          y1='17'
          x2='7'
          y2='2'
          strokeLinecap='butt'
        />
        <polyline points='3,6 7,2 11,6 ' />
        <line
          data-cap='butt'
          x1='17'
          y1='6'
          x2='17'
          y2='22'
          strokeLinecap='butt'
          stroke='#2997fc'
        />
        <polyline points=' 13,18 17,22 21,18 ' stroke='#2997fc' />
      </g>
    </svg>
  ),
});
