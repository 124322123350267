const isDST = (d) => {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();
};

export const getTimezoneOffset = () =>
  isDST(new Date())
    ? new Date().getTimezoneOffset() + 60
    : new Date().getTimezoneOffset();
