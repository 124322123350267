import { ApiGetAccountSignalsQuery } from 'shared/graphql/generatedApiTypes';
import { SignalDirections, SignalSources } from '../../Home/types/homeTypes';
import {
  Granularity,
  SignalSectionUnitType,
  SingleAccountSignalItem,
  SingleAccountSignalsTimePeriod,
} from '../types/SingleAccountSignalsTypes';
import {
  mapDropdownTimeInputToDates,
  transformAggregatedDataToChartData,
  transformAggregatedDataToTableData,
} from '../helpers/singleAccountSignalHelpers';

export type SingleAccountSignalReducerState = {
  chartData: SingleAccountSignalItem[];
  currentDirection: SignalDirections;
  currentEndDate: string;
  currentGranularity: Granularity;
  currentSource: SignalSources;
  currentStartDate: string;
  currentType: string;
  currentTypeDetail: string;
  currentUnitType: SignalSectionUnitType;
  fiscalYearStartingMonth: number;
  fullData: ApiGetAccountSignalsQuery['accountSignals'];
  tableData: any;
};

export enum SingleAccountSignalActionType {
  RESET,
  SET_FULL_DATA,
  UPDATE_DIRECTION,
  UPDATE_GRANULARITY,
  UPDATE_SOURCE,
  UPDATE_START_AND_END_DATES,
  UPDATE_TYPE,
  UPDATE_TYPE_DETAIL,
  UPDATE_UNIT_TYPE,
}

export type SingleAccountSignalAction =
  | {
      type: SingleAccountSignalActionType.RESET;
      payload: null;
    }
  | {
      type: SingleAccountSignalActionType.SET_FULL_DATA;
      payload: {
        data: ApiGetAccountSignalsQuery['accountSignals'];
        fiscalYearStartingMonth: number;
      };
    }
  | {
      type: SingleAccountSignalActionType.UPDATE_DIRECTION;
      payload: {
        newDirection: SignalDirections;
      };
    }
  | {
      type: SingleAccountSignalActionType.UPDATE_SOURCE;
      payload: {
        newSource: SignalSources;
      };
    }
  | {
      type: SingleAccountSignalActionType.UPDATE_GRANULARITY;
      payload: {
        newGranularity: Granularity;
      };
    }
  | {
      type: SingleAccountSignalActionType.UPDATE_START_AND_END_DATES;
      payload: {
        newStartDate: string;
        newEndDate: string;
      };
    }
  | {
      type: SingleAccountSignalActionType.UPDATE_TYPE;
      payload: {
        newType: string;
      };
    }
  | {
      type: SingleAccountSignalActionType.UPDATE_TYPE_DETAIL;
      payload: {
        newTypeDetail: string;
      };
    }
  | {
      type: SingleAccountSignalActionType.UPDATE_UNIT_TYPE;
      payload: {
        newUnitType: SignalSectionUnitType;
      };
    };

export const initialSingleAccountSignalReducerState: SingleAccountSignalReducerState =
  {
    chartData: [],
    currentDirection: SignalDirections.All,
    currentEndDate: '',
    currentGranularity: Granularity.monthly,
    currentSource: SignalSources.All,
    currentStartDate: '',
    currentType: '',
    currentTypeDetail: '',
    currentUnitType: SignalSectionUnitType.quantity,
    fullData: null,
    tableData: null,
    fiscalYearStartingMonth: 0,
  };

export const singleAccountSignalReducer = (
  state = initialSingleAccountSignalReducerState,
  action: SingleAccountSignalAction,
): SingleAccountSignalReducerState => {
  const { type, payload } = action;
  switch (type) {
    case SingleAccountSignalActionType.RESET:
      const { startDate: resetStartDate, endDate: resetEndDate } =
        mapDropdownTimeInputToDates(
          SingleAccountSignalsTimePeriod.sixMonths,
          state.fiscalYearStartingMonth,
        );
      const newResetState = {
        ...initialSingleAccountSignalReducerState,
        fullData: state.fullData,
        fiscalYearStartingMonth: state.fiscalYearStartingMonth,
        currentStartDate: resetStartDate,
        currentEndDate: resetEndDate,
      };
      return {
        ...newResetState,
        chartData: transformAggregatedDataToChartData(newResetState),
        tableData: transformAggregatedDataToTableData(newResetState),
      };
    case SingleAccountSignalActionType.SET_FULL_DATA:
      const { data, fiscalYearStartingMonth } = payload as {
        data: ApiGetAccountSignalsQuery['accountSignals'];
        fiscalYearStartingMonth: number;
      };
      const { startDate: initialStartDate, endDate: initialEndDate } =
        mapDropdownTimeInputToDates(
          SingleAccountSignalsTimePeriod.sixMonths,
          fiscalYearStartingMonth,
        );
      const newState = {
        ...state,
        fullData: data,
        fiscalYearStartingMonth,
        currentStartDate: initialStartDate,
        currentEndDate: initialEndDate,
      };
      return {
        ...newState,
        chartData: transformAggregatedDataToChartData(newState),
        tableData: transformAggregatedDataToTableData(newState),
      };
    case SingleAccountSignalActionType.UPDATE_DIRECTION:
      const { newDirection } = payload as {
        newDirection: SignalDirections;
      };
      const newDirectionState = { ...state, currentDirection: newDirection };
      return {
        ...newDirectionState,
        chartData: transformAggregatedDataToChartData(newDirectionState),
        tableData: transformAggregatedDataToTableData(newDirectionState),
      };
    case SingleAccountSignalActionType.UPDATE_SOURCE:
      const { newSource } = payload as {
        newSource: SignalSources;
      };
      const newSourceState = { ...state, currentSource: newSource };
      return {
        ...newSourceState,
        chartData: transformAggregatedDataToChartData(newSourceState),
        tableData: transformAggregatedDataToTableData(newSourceState),
      };
    case SingleAccountSignalActionType.UPDATE_GRANULARITY:
      const { newGranularity } = payload as {
        newGranularity: Granularity;
      };
      const newGranularityState = {
        ...state,
        currentGranularity: newGranularity,
      };
      return {
        ...newGranularityState,
        chartData: transformAggregatedDataToChartData(newGranularityState),
        tableData: transformAggregatedDataToTableData(newGranularityState),
      };
    case SingleAccountSignalActionType.UPDATE_TYPE:
      const { newType } = payload as {
        newType: string;
      };
      const newTypeState = { ...state, currentType: newType };
      return {
        ...newTypeState,
        chartData: transformAggregatedDataToChartData(newTypeState),
        tableData: transformAggregatedDataToTableData(newTypeState),
      };
    case SingleAccountSignalActionType.UPDATE_TYPE_DETAIL:
      const { newTypeDetail } = payload as {
        newTypeDetail: string;
      };
      const newTypeDetailState = { ...state, currentTypeDetail: newTypeDetail };
      return {
        ...newTypeDetailState,
        chartData: transformAggregatedDataToChartData(newTypeDetailState),
        tableData: transformAggregatedDataToTableData(newTypeDetailState),
      };
    case SingleAccountSignalActionType.UPDATE_START_AND_END_DATES:
      const { newEndDate, newStartDate } = payload as {
        newStartDate: string;
        newEndDate: string;
      };
      const newStartAndEndDatesState = {
        ...state,
        currentStartDate: newStartDate,
        currentEndDate: newEndDate,
      };
      return {
        ...newStartAndEndDatesState,
        chartData: transformAggregatedDataToChartData(newStartAndEndDatesState),
        tableData: transformAggregatedDataToTableData(newStartAndEndDatesState),
      };
    case SingleAccountSignalActionType.UPDATE_UNIT_TYPE:
      const { newUnitType } = payload as {
        newUnitType: SignalSectionUnitType;
      };
      const newUnitTypeState = {
        ...state,
        currentUnitType: newUnitType,
      };
      return {
        ...newUnitTypeState,
        chartData: transformAggregatedDataToChartData(newUnitTypeState),
        tableData: transformAggregatedDataToTableData(newUnitTypeState),
      };
    default:
      return state;
  }
};
