import { useCallback, useContext, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import _debounce from 'lodash/debounce';
import _size from 'lodash/size';
import AsyncSelect from 'react-select/async';
import {
  useInput,
  UseInputHookArrayValueTypes,
  UseInputHookReturnType,
} from 'shared/hooks/inputHook';
import { addOptionComponentDataAcceptance } from 'app/src/helpers/analyticsHelpers';
import { getAccountsFilterItemStyles } from '../../helpers/getAccountsFilterItemStyles';
import { GetAccountsWithQueryStringDocument } from 'shared/graphql/generatedApiTypes';
import { OptionsType } from 'shared/types/coreTypes.d';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { FilterIndices } from '../../types/filterTypes';
import { SelectedAccountItems } from './itemLists/SelectedAccountItems';
import { OptionTypeBase, ValueType } from 'react-select';
import { useKeyPress } from 'app/src/hooks/useKeyPress';
import { Text } from '@chakra-ui/react';
import { cleanAccountSelectOptions } from 'app/src/helpers/cleanAccountSelectOptions';

type AccountsFilterItemProps = {
  isDisabled?: boolean;
  itemType: 'panel' | 'bar' | 'wideMenu';
};

type AccountsFilterItemState = {
  currentInputValue: string;
  defaultOptions: Array<{ value: string | number; label: string }>;
  isMenuOpen: boolean;
};

const initialState: AccountsFilterItemState = {
  currentInputValue: '',
  defaultOptions: [],
  isMenuOpen: false,
};

const AccountsFilterItem = ({
  isDisabled = false,
  itemType,
}: AccountsFilterItemProps) => {
  const { globalFilter, updateFilter } = useContext(AggregateDataContext);
  const apolloClient = useApolloClient();
  const isFilterApplied =
    globalFilter &&
    globalFilter[FilterIndices.ACCOUNTS_FULL_FILTER] &&
    globalFilter[FilterIndices.ACCOUNTS_FULL_FILTER].length;

  // make sure that the filter bar accounts item closes on ESC key press
  const escapePress = useKeyPress('Escape');
  useEffect(() => {
    if (escapePress && itemType === 'bar') {
      handleMenuClose();
    }
  }, [escapePress, itemType]);

  const [{ currentInputValue, defaultOptions, isMenuOpen }, setState] =
    useState<AccountsFilterItemState>(initialState);

  const _handleLoadOptions = useCallback(
    async (inputValue, callback) => {
      try {
        const res = await apolloClient.query({
          query: GetAccountsWithQueryStringDocument,
          variables: { queryString: inputValue, limit: true },
        });
        const cleanedSelectOptions = cleanAccountSelectOptions(
          res?.data?.accountsForSearch,
        ).filter(
          (account) =>
            !globalFilter?.accounts?.find(
              (acct) => acct.value === account.value,
            ),
        );
        setState((prevState) => ({
          ...prevState,
          defaultOptions: cleanedSelectOptions,
        }));
        return callback(cleanedSelectOptions);
      } catch {
        return callback(null);
      }
    },
    [apolloClient, globalFilter?.accounts],
  );

  const handleSelection = (
    selectedOptions: ValueType<Array<OptionTypeBase>, false>,
  ) => {
    if (!selectedOptions || !selectedOptions.length) return;
    setAccounts(selectedOptions);
    // Make sure it's not in the list already
    if (
      !globalFilter?.accounts?.find(
        (acct) => acct.value === selectedOptions[0].value,
      )
    ) {
      updateFilter({
        index: FilterIndices.ACCOUNTS_FULL_FILTER,
        value: globalFilter?.accounts
          ? [...globalFilter?.accounts, ...(selectedOptions as OptionsType[])]
          : [...(selectedOptions as OptionsType[])],
      });
    }
  };

  const handleMenuClose = () => {
    setState((prevState) => ({
      ...prevState,
      defaultOptions: [],
      isMenuOpen: false,
    }));
  };

  // setup input bind
  const { bind: bindAccounts, setValue: setAccounts } = useInput([], {
    defaultValue: [],
    label: undefined,
    options: [],
    placeholder: `${globalFilter?.accounts?.length ?? 0} Accounts Selected`,
  }) as UseInputHookReturnType & UseInputHookArrayValueTypes;

  return (
    <fieldset className={'filter-bar-item c-text-field h-pb-none'}>
      <label className={'c-text-field__wrapper'} htmlFor={'Accounts'}>
        <div className={'c-text-field__input-block'}>
          <div className={'h-relative'}>
            <AsyncSelect
              {...bindAccounts}
              backspaceRemovesValue={false}
              className={
                'c-text-field__input analytics-attr-account-search-filter-bar-select-control'
              }
              closeMenuOnSelect={false}
              components={{
                Option: addOptionComponentDataAcceptance(
                  'account-search-filter-bar-select-option',
                ),
              }}
              defaultOptions={defaultOptions}
              inputId={'accounts-search-filter-bar-react-select'}
              isDisabled={isDisabled}
              isMulti={true}
              loadOptions={_debounce(_handleLoadOptions, 500, {
                leading: false,
                trailing: true,
              })}
              menuIsOpen={isMenuOpen}
              noOptionsMessage={({ inputValue }) =>
                _size(inputValue) > 0 ? 'No options' : 'Start typing to add'
              }
              onChange={(selectedOptions, { action }) =>
                action === 'select-option' && handleSelection(selectedOptions)
              }
              onInputChange={(val) =>
                setState((prevState) => ({
                  ...prevState,
                  currentInputValue: val,
                }))
              }
              onMenuClose={itemType === 'panel' ? handleMenuClose : undefined}
              onMenuOpen={() =>
                setState((prevState) => ({ ...prevState, isMenuOpen: true }))
              }
              options={bindAccounts.options as readonly any[]}
              styles={getAccountsFilterItemStyles(
                itemType,
                isMenuOpen,
                isFilterApplied,
              )}
              value={null}
            />
            {isMenuOpen && (
              <>
                <div
                  className={'react-select-blanket'}
                  onClick={handleMenuClose}
                />
                {itemType === 'bar' && (
                  <div className={'filter-bar-wrapper__accounts'}>
                    {!!globalFilter?.accounts?.length ? (
                      <SelectedAccountItems
                        isVertical
                        filterText={currentInputValue}
                        filterIndex={FilterIndices.ACCOUNTS_FULL_FILTER}
                      />
                    ) : (
                      <Text align={'center'} mt={4} color={'brand.gray-600'}>
                        No accounts selected
                      </Text>
                    )}
                  </div>
                )}
              </>
            )}
            {itemType === 'panel' && (
              <SelectedAccountItems
                filterText={currentInputValue}
                filterIndex={FilterIndices.ACCOUNTS_FULL_FILTER}
              />
            )}
          </div>
        </div>
      </label>
    </fieldset>
  );
};

export default AccountsFilterItem;
