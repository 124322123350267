import { useDisclosure } from '@chakra-ui/react';
import { BrandBar } from 'shared/components/Core';
import { ReactNode } from 'react';
import { LeftNavigation } from 'app/src/components/Navigation/components/LeftNavigation';
import { UpdateCompanyModal } from 'app/src/components/Navigation/components/UpdateCompanyModal';

export type NavigationProps = {
  children: ReactNode;
  onOpenUpdateCompanyModal: () => void;
};

export const NavigationBar = ({ children }: { children: ReactNode }) => {
  const {
    onOpen: onOpenUpdateCompanyModal,
    isOpen: isOpenUpdateCompanyModal,
    onClose: onCloseUpdateCompanyModal,
  } = useDisclosure();

  return (
    <>
      <BrandBar />
      <LeftNavigation onOpenUpdateCompanyModal={onOpenUpdateCompanyModal}>
        {children}
      </LeftNavigation>
      <UpdateCompanyModal
        isOpen={isOpenUpdateCompanyModal}
        onClose={onCloseUpdateCompanyModal}
      />
    </>
  );
};
