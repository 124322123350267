export enum SectionComponents {
  EngagementStatus = 'engagementStatus',
  SingleAccountQuickStat = 'singleAccountQuickStat',
  AggregateSignalHistory = 'aggregateSignalHistory',
  RecommendedActionsTable = 'recommendedActionsTable',
  SingleAccountSignalHistory = 'singleAccountSignalHistory',
  SingleAccountSignalOverview = 'singleAccountSignalOverview',
  TableSection = 'tableSection',
  WinfluenceAndPrimaryActionFilterableList = 'winfluenceAndPrimaryActionFilterableList',
  Labs = 'labs',
  PossibleAccountScoreSankey = 'possibleAccountScoreSankey',
  SingleAccountRecommendations = 'singleAccountRecommendations',
  ConfiguredSingleAccountQuickStat = 'configuredSingleAccountQuickStat',
  SingleAccountHeader = 'singleAccountHeader',
  SingleAccountMessageFeed = 'singleAccountMessageFeed',
  SingleProductPredictionsSection = 'singleProductPredictionsSection',
  AccountsTable = 'accountsTableWithClosedOppsAndNoSignals',
  FiltersOrLists = 'filtersOrLists',
  AggregatedMessageFeed = 'aggregatedMessageFeed',
  SingleAccountPredictionsOverview = 'singleAccountPredictionsOverview',
  MultipleProductPredictionsSection = 'multipleProductPredictionsSection',
  SingleAccountPeopleList = 'singleAccountPeopleList',
  TopCompanyList = 'topCompanyList',
  MultipleAccountPeopleList = 'multipleAccountPeopleList',
  SignalTimeline = 'signalTimeline',
  MerzPredictionRecommendation = 'merzPredictionRecommendation',
  MerzCompanyDetailsSummmaryTab = 'merzCompanyDetailsSummmaryTab',
  TurnkeyCompanyDetailsSummmaryTab = 'turnkeyCompanyDetailsSummmaryTab',
  EmbeddedMetabaseReports = "EmbeddedMetabaseReports"
}
