import { createIcon } from '@chakra-ui/icon';

export const SelectedPeopleIcon = createIcon({
  displayName: 'SelectedPeopleIcon',
  viewBox: '0 0 12 12',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12px'
      height='12px'
      viewBox='0 0 12 12'
      version='1.1'
    >
      <g id='surface1'>
        <path
          stroke='none'
          fillRule='nonzero'
          fill='rgb(100%,100%,100%)'
          fillOpacity='1'
          d='M 6.769531 7.496094 C 5.972656 7.167969 5.042969 7 4 7 C 2.957031 7 2.027344 7.167969 1.230469 7.496094 C 0.484375 7.804688 0 8.53125 0 9.34375 L 0 12 L 8 12 L 8 9.34375 C 8 8.53125 7.515625 7.804688 6.769531 7.496094 Z M 6.769531 7.496094 '
        />
        <path
          stroke='none'
          fillRule='nonzero'
          fill='rgb(100%,100%,100%)'
          fillOpacity='1'
          d='M 4 6.5 C 5.378906 6.5 6.5 5.378906 6.5 4 C 6.5 2.621094 5.378906 1.5 4 1.5 C 2.621094 1.5 1.5 2.621094 1.5 4 C 1.5 5.378906 2.621094 6.5 4 6.5 Z M 4 6.5 '
        />
        <path
          stroke='none'
          fillRule='nonzero'
          fill='rgb(100%,100%,100%)'
          fillOpacity='1'
          d='M 11.632812 3.640625 C 11.269531 3.355469 10.640625 3 9.75 3 C 8.859375 3 8.230469 3.355469 7.867188 3.640625 C 7.632812 3.828125 7.5 4.117188 7.5 4.417969 L 7.5 5 L 12 5 L 12 4.417969 C 12 4.117188 11.867188 3.828125 11.632812 3.640625 Z M 11.632812 3.640625 '
        />
        <path
          stroke='none'
          fillRule='nonzero'
          fill='rgb(100%,100%,100%)'
          fillOpacity='1'
          d='M 9.75 2.5 C 10.441406 2.5 11 1.941406 11 1.25 C 11 0.558594 10.441406 0 9.75 0 C 9.058594 0 8.5 0.558594 8.5 1.25 C 8.5 1.941406 9.058594 2.5 9.75 2.5 Z M 9.75 2.5 '
        />
      </g>
    </svg>
  ),
});

export const PeopleIcon = createIcon({
  displayName: 'PeopleIcon',
  viewBox: '0 0 12 12',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12px'
      height='12px'
      viewBox='0 0 12 12'
      version='1.1'
    >
      <g id='surface1'>
        <path
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke='rgb(82.745098%,85.490196%,88.627451%)'
          strokeOpacity='1'
          d='M 15 23 L 1 23 L 1 18.6875 C 1 17.476562 1.726562 16.382812 2.84375 15.914062 C 3.984375 15.445312 5.671875 15 8 15 C 10.328125 15 12.015625 15.445312 13.15625 15.914062 C 14.273438 16.382812 15 17.476562 15 18.6875 Z M 15 23 '
          transform='matrix(0.5,0,0,0.5,0,0)'
        />
        <path
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke='rgb(82.745098%,85.490196%,88.627451%)'
          strokeOpacity='1'
          d='M 8 12 C 10.210938 12 12 10.210938 12 8 C 12 5.789062 10.210938 4 8 4 C 5.789062 4 4 5.789062 4 8 C 4 10.210938 5.789062 12 8 12 Z M 8 12 '
          transform='matrix(0.5,0,0,0.5,0,0)'
        />
        <path
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke='rgb(82.745098%,85.490196%,88.627451%)'
          strokeOpacity='1'
          d='M 23 11.835938 C 23 11.234375 22.734375 10.65625 22.265625 10.28125 C 21.539062 9.710938 20.28125 9 18.5 9 C 16.71875 9 15.460938 9.710938 14.734375 10.28125 C 14.265625 10.65625 14 11.234375 14 11.835938 L 14 13 L 23 13 Z M 23 11.835938 '
          transform='matrix(0.5,0,0,0.5,0,0)'
        />
        <path
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke='rgb(82.745098%,85.490196%,88.627451%)'
          strokeOpacity='1'
          d='M 18.5 6 C 19.882812 6 21 4.882812 21 3.5 C 21 2.117188 19.882812 1 18.5 1 C 17.117188 1 16 2.117188 16 3.5 C 16 4.882812 17.117188 6 18.5 6 Z M 18.5 6 '
          transform='matrix(0.5,0,0,0.5,0,0)'
        />
      </g>
    </svg>
  ),
});
