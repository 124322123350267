import { createIcon } from '@chakra-ui/icon';

export const MergeIcon = createIcon({
  displayName: 'MergeIcon',
  viewBox: '0 0 64 64',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='64'
      width='64'
      viewBox='0 0 64 64'
    >
      <g fill='#ffffff' className='nc-icon-wrapper'>
        <path
          data-color='color-2'
          d='M43,19c1.105,0,2,0.895,2,2v22h17c0.552,0,1-0.448,1-1V2c0-0.552-0.448-1-1-1H22 c-0.552,0-1,0.448-1,1v17H43z'
        />
        <path
          fill='#ffffff'
          d='M2,63h40c0.552,0,1-0.447,1-1V22c0-0.553-0.448-1-1-1H2c-0.552,0-1,0.447-1,1v40C1,62.553,1.448,63,2,63z'
        />
      </g>
    </svg>
  ),
});
