import { SectionComponents } from '../../types/sectionComponents';
import { SingleAccountQuickStatProps } from '../../types/quickStatFieldOptions';
import { EngagementStatusProps } from 'app/src/components/Home/components/EngagementStatus';
import { EngagementLineChartWrapperProps } from 'app/src/components/Home/components/EngagementLineChartWrapper';
import { LabsAccordionsProps } from 'app/src/components/Lab/components/LabsAccordions';
import { SankeyChartProps } from 'app/src/components/Plan/components/SankeyChart';
import { MultipleAccountsCutsAndListProps } from 'app/src/components/Account/components/mav/MultipleAccountsCutsAndList';
import { ConfiguredSingleAccountQuickStatProps } from 'app/src/components/Account/components/sav/overview/accountSummary/ConfiguredSingleAccountQuickStat';
import { SingleProductPredictionsSectionProps } from 'app/src/components/GaugeChart/components/SingleProductPredictionsSection';
import { RecommendationsTabProps } from 'app/src/components/Account/components/sav/recommendations/RecommendationsTab';
import { MessageFeedProps } from 'app/src/components/MessageFeed/components/MessageFeed';
import { SavedSegmentsOrListsProps } from 'app/src/components/ListsAndFilters/components/SavedSegmentsOrLists';
import { AccountsTableProps } from 'app/src/components/Account/components/mav/AccountsTable';
import { AccountHeaderProps } from 'app/src/components/Account/components/sav/header/AccountHeader';
import { TableComponentProps } from '../types/tableTypes';
import { Timestamps } from './Timestamps';
import { SingleAccountSignalHistorySectionProps } from 'app/src/components/Account/types/SingleAccountSignalsTypes';

export type SavedComponentSchema = (FullComponentType | GridComponentType) &
  Timestamps;

export type FullComponentType = {
  componentType: ComponentType.fullWidth;
  component: StructuredFullComponent;
};

export type GridComponentType = {
  componentType: ComponentType.partWidth;
  subComponents: StructuredSubComponent[];
};

export type StructuredFullComponent = {
  key: SectionComponents;
  data: ComponentDataType;
};

export enum ComponentType {
  fullWidth = 'fullWidth',
  partWidth = 'partWidth',
  tab = 'tab',
}

export type StructuredTabComponent = {
  components: string[]; // references to components
  componentType: ComponentType.tab;
  hash: string;
  order: number; // order within this set of tabComponents
  title: string;
};

export type FullStructuredTabComponent = Omit<
  StructuredTabComponent,
  'components'
> & {
  components: Array<SavedComponentSchema>;
};

export type StructuredSubComponent = {
  key: SectionComponents;
  gridWidth: number;
  data: ComponentDataType;
};

export type ComponentDataType =
  | TableComponentProps
  | SingleAccountQuickStatProps
  | EngagementStatusProps
  | EngagementLineChartWrapperProps
  | SingleAccountSignalHistorySectionProps
  | LabsAccordionsProps
  | SankeyChartProps
  | MultipleAccountsCutsAndListProps
  | ConfiguredSingleAccountQuickStatProps
  | SingleProductPredictionsSectionProps
  | RecommendationsTabProps
  | MessageFeedProps
  | SavedSegmentsOrListsProps
  | AccountsTableProps
  | AccountHeaderProps;
