import { AnalyticsAttrType } from 'shared/types/coreTypes.d';
import { BindMinMaxOptionsType } from '../../../types/minToMaxFilterItemProps';
import { RangeSliderThumb, Text } from '@chakra-ui/react';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { formatAsPercentage } from 'shared/helpers/formatHelpers';
import { useMemo } from 'react';

export type RangeThumbProps = AnalyticsAttrType & {
  index: number;
  initialFilterItem: BindMinMaxOptionsType;
  isHundredPointScale: boolean;
  isPercentageScale: boolean;
  numberOfTicks: number;
  value: number;
};

export const RangeThumb = ({
  analyticsAttr,
  index,
  value,
  numberOfTicks,
  isHundredPointScale,
  isPercentageScale,
  initialFilterItem,
}: RangeThumbProps) => {
  const rangeLabel = useMemo(() => {
    if (value === numberOfTicks) {
      if (isHundredPointScale) {
        return '100';
      } else if (isPercentageScale) {
        return '100%';
      } else {
        return 'Max';
      }
    }
    if (isPercentageScale) {
      return (
        initialFilterItem[value] &&
        initialFilterItem[value].label &&
        formatAsPercentage(parseFloat(initialFilterItem[value].label!), {
          precision: 0,
        })
      );
    }
    return (initialFilterItem[value] && initialFilterItem[value].label) ?? '';
  }, [
    isPercentageScale,
    isHundredPointScale,
    value,
    numberOfTicks,
    initialFilterItem,
  ]);

  return (
    <RangeSliderThumb
      analytics-attr={cleanAnalyticsStringList([analyticsAttr])}
      bg={'brand.lightBlue'}
      borderColor={'brand.gray-600'}
      borderWidth={1}
      boxSize={10}
      color={'brand.white'}
      index={index}
      key={index}
      // mt={-13}
    >
      <Text fontSize={'xs'}>{rangeLabel}</Text>
    </RangeSliderThumb>
  );
};
