import { ReactNode } from 'react';

import { Icon, Inline, Tooltip, Txt } from './index';
import { Box, Stack, Text, TextProps } from '@chakra-ui/react';

type SectionHeaderType = {
  rightComponent?: ReactNode;
  showBackgroundColor?: boolean;
  addHeaderLeftMargin?: boolean;
  pb?: number;
  showFilterIcon?: boolean;
  subtitle?: string;
  title: string;
  titleProps?: TextProps;
  tooltip?: string;
};

const SectionHeader = ({
  showBackgroundColor = true,
  addHeaderLeftMargin = false,
  showFilterIcon = false,
  rightComponent = null,
  pb,
  subtitle = undefined,
  title = '',
  titleProps,
  tooltip = undefined,
}: SectionHeaderType) => (
  <Box
    bg={showBackgroundColor ? 'brand.white' : undefined}
    py={6}
    px={showBackgroundColor || addHeaderLeftMargin ? 6 : undefined}
    pb={pb || pb === 0 ? pb : undefined}
  >
    <Inline alignItems={'center'} gap={'md'} justifyContent={'between'}>
      <Stack spacing={4}>
        <Inline alignItems={'center'} gap={'md'}>
          {tooltip && tooltip.length > 0 ? (
            <Tooltip
              analyticsAttr={title}
              content={<Text fontSize={'sm'}>{tooltip}</Text>}
            >
              <Text
                as={'h1'}
                fontSize={'xl'}
                fontWeight={'bold'}
                color={'brand.black'}
                {...titleProps}
              >
                {title}
              </Text>
            </Tooltip>
          ) : (
            <Text
              as={'h1'}
              fontSize={'xl'}
              fontWeight={'bold'}
              color={'brand.black'}
              {...titleProps}
            >
              {title}
            </Text>
          )}
          {showFilterIcon && (
            <Tooltip content={<Txt>{'Filter applied'}</Txt>} hideIcon={true}>
              <Icon color={'red'} name={'if-filter'} />
            </Tooltip>
          )}
        </Inline>
        {subtitle && <Txt theme={'muted'}>{subtitle}</Txt>}
      </Stack>
      {rightComponent}
    </Inline>
  </Box>
);

export default SectionHeader;
