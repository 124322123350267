import { Timestamps } from './Timestamps';
import { NavbarIcon } from '../../renderers/NavbarIconRenderer';
import {
  FullStructuredTabComponent,
  StructuredTabComponent,
} from './SavedComponent';

export interface SavedPageSchema extends Timestamps {
  browserTabTitle: string;
  components: StructuredTabComponent[]; // references to components
  filtersVisible: boolean;
  key: string;
  name: string;
  navStructure: TopLevelNavStructure | LowerLevelNavStructure;
  statSummaryConfig: StatSummaryItem[];
}

export type FullSavedPageSchema = Omit<SavedPageSchema, 'components'> & {
  components: FullStructuredTabComponent[];
};

export type TopLevelNavStructure = {
  analyticsAttr?: string;
  navbarIcon: NavbarIcon;
  navbarTooltip?: string;
  order: number; //within the top of the application
  urlParent: null; // if null, it's a top level nav link
  urlPaths: UrlPath[];
};

export type LowerLevelNavStructure = {
  urlParent: string;
  urlPaths: UrlPath[];
};

export type UrlPath = {
  param: string;
  path: string;
  label?: string;
};

export enum StatSummaryItem {
  avgWinfluence = 'avgWinfluence',
  totalAccounts = 'totalAccounts',
  totalAccountsWithoutClosedOpps = 'totalAccountsWithoutClosedOpps',
  totalIndustries = 'totalIndustries',
}
