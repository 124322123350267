import _map from 'lodash/map';

import {HeatMapDefaultItems, HeatMapPropsType} from './HeatMap';
import {Tooltip} from '@chakra-ui/react';
import {ReactElement, ReactNode} from 'react';

type DataGridType = HeatMapDefaultItems & {
  grids: Array<HeatMapPropsType>;
  onClick?: (grid: HeatMapPropsType) => void;
};

type GridItemWrapperType = {
  'aria-label': string;
  children: ReactElement;
  label: string | ReactNode;
  showTooltip: boolean;
};

const GridItemWrapper = ({
  children,
  showTooltip = true,
  ...rest
}: GridItemWrapperType) =>
  showTooltip ? <Tooltip {...rest}>{children}</Tooltip> : <>{children}</>;

const DataGrid = ({
  columns = [],
  columnsId = 'columnId',
  gridHeight = '100',
  grids = [],
  gridLabelFormatter = (props) => props.label,
  onClick = undefined,
  rows = [],
  showTooltip = true,
  tooltipFormatter = (props) => props.label,
}: DataGridType) => (
  <>
    {_map(grids, (grid, idx) => (
      <GridItemWrapper
        aria-label={grid?.label || ''}
        key={idx}
        label={tooltipFormatter(grid)}
        showTooltip={showTooltip}
      >
        <div
          className={'heatmap-grid-label'}
          onClick={() => onClick?.(grid)}
          style={{
            backgroundColor: grid?.color || 'var(--color-black)',
            border: `1px solid var(--color-${
              grid?.isSelected ? 'red' : 'white'
            })`,
            cursor: onClick ? 'pointer' : 'auto',
            height: `${gridHeight}px`,
          }}
        >
          <div className={'heatmap-grid-label-span-wrapper'}>
            <span>{gridLabelFormatter(grid)}</span>
          </div>
        </div>
      </GridItemWrapper>
    ))}
  </>
);

export default DataGrid;
