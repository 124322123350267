import { Box, Flex, Text } from '@chakra-ui/react';
import { getXeominVolumeStringAndWidths } from 'app/src/components/GaugeChart/helpers/getXeominVolumeStringAndWidths';
import { Maybe } from 'shared/graphql/generatedApiTypes';
import { useRemark } from 'react-remark';
import { useEffect } from 'react';

export type VolumeBarChartProps = {
  currentQtrXeominTotal?: number;
  endDate: string;
  prevQtrXeominTotal?: number;
  predictedTotal?: number;
  volumeNarratives: Array<Maybe<string>>;
};

export const VolumeBarChart = ({
  currentQtrXeominTotal,
  endDate,
  prevQtrXeominTotal,
  predictedTotal,
  volumeNarratives,
}: VolumeBarChartProps) => {
  const {
    prevVolumeWidth,
    currentVolumeWidth,
    // referenceLineLeftMargin,
    xeominVolumeString,
  } = getXeominVolumeStringAndWidths({
    currentQtrXeominTotal,
    endDate,
    prevQtrXeominTotal,
    predictedTotal,
  });

  const [volumeContent, setVolumeMarkdownSource] = useRemark({
    rehypeReactOptions: {
      components: {
        ul: (props) => (
          <ul
            style={{
              listStyleType: 'disc',
              paddingLeft: '20px',
              fontSize: '18px',
              display: 'inline-block',
              verticalAlign: 'top',
            }}
          >
            {props.children}
          </ul>
        ),
      },
    },
  });

  useEffect(() => {
    if (volumeNarratives.length) {
      setVolumeMarkdownSource(volumeNarratives.join(' '));
    } else {
      setVolumeMarkdownSource(xeominVolumeString);
    }
  }, []);

  return (
    <>
      {volumeContent}
      <Box mt={4}>
        <Text fontWeight={'bold'} ml={4}>
          Xeomin Purchase Qty
        </Text>
        <Flex>
          <Box minW={24} mr={2} textAlign={'right'}>
            <Text fontWeight={'bold'} mb={2}>
              So Far
            </Text>
            <Text>Last Qtr</Text>
          </Box>
          <Box>
            <Flex align={'center'} mb={2}>
              <Box
                h={6}
                w={`${currentVolumeWidth}px`}
                bg={'brand.gray-600'}
                mr={2}
              />
              <Text fontWeight={'bold'}>
                {currentQtrXeominTotal?.toLocaleString()}
              </Text>
            </Flex>
            <Flex align={'center'}>
              <Box
                h={6}
                w={`${prevVolumeWidth}px`}
                bg={'brand.gray-400'}
                mr={2}
              />
              <Text>{prevQtrXeominTotal?.toLocaleString()}</Text>
            </Flex>
          </Box>
          {/*<Box mb={8} pos={'absolute'}>*/}
          {/*  <Box*/}
          {/*    h={16}*/}
          {/*    pos={'absolute'}*/}
          {/*    left={104 + referenceLineLeftMargin}*/}
          {/*    borderLeftColor={'brand.gray-800'}*/}
          {/*    borderLeftWidth={2}*/}
          {/*    borderLeftStyle={'dotted'}*/}
          {/*  />*/}
          {/*  <Flex*/}
          {/*    borderRadius={12}*/}
          {/*    w={6}*/}
          {/*    h={6}*/}
          {/*    ml={referenceLineLeftMargin + 92}*/}
          {/*    bg={'brand.lightBlue'}*/}
          {/*    align={'center'}*/}
          {/*    justify={'center'}*/}
          {/*    mt={16}*/}
          {/*  >*/}
          {/*    <Text*/}
          {/*      color={'brand.white'}*/}
          {/*      fontSize={'xs'}*/}
          {/*      fontWeight={'extrabold'}*/}
          {/*    >*/}
          {/*      {predictedTotal}*/}
          {/*    </Text>*/}
          {/*  </Flex>*/}
          {/*  <Text fontSize={'xs'} w={60} ml={24}>*/}
          {/*    Current qtr projected value*/}
          {/*  </Text>*/}
          {/*</Box>*/}
        </Flex>
        {/*{!!volumeNarratives.length ? (*/}
        {/*  volumeNarratives.map((item, i) => (*/}
        {/*    <Text mt={2} ml={8} maxW={64} key={i}>*/}
        {/*      {!!item && item}*/}
        {/*    </Text>*/}
        {/*  ))*/}
        {/*) : (*/}
        {/*  <Text mt={2} ml={8} maxW={64}>*/}
        {/*    {xeominVolumeString}*/}
        {/*  </Text>*/}
        {/*)}*/}
      </Box>
    </>
  );
};
