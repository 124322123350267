export const UserIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
  >
    <path
      d='M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6Z'
      fill='#323F5A'
    />
    <path
      d='M6 7C2.625 7 1 9.106 1 10.333V11C1 11.2652 1.10536 11.5196 1.29289 11.7071C1.48043 11.8946 1.73478 12 2 12H10C10.2652 12 10.5196 11.8946 10.7071 11.7071C10.8946 11.5196 11 11.2652 11 11V10.333C11 9.106 9.375 7 6 7Z'
      fill='#323F5A'
    />
  </svg>
);
