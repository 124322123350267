import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  fontWeight: 'bold',
  _focus: {
    boxShadow: 'none',
  },
  _hover: {
    boxShadow: '0 10px 10px -8px rgba(0, 0, 0, 0.4)',
  },
};

const sizes = {};

const variants = {
  action: {
    bg: 'brand.lightBlue',
    color: 'brand.white',
    _hover: {
      bg: 'brand.denim',
    },
  },
  actionPurple: {
    bg: 'brand.purple',
    color: 'brand.white',
    _hover: {
      bg: 'brand.purple',
    },
  },
  actionOutline: {
    bg: 'transparent',
    borderWidth: '1px',
    borderColor: 'brand.lightBlue',
    borderStyle: 'solid',
    color: 'brand.lightBlue',
  },
  active: {
    bg: 'brand.crimson',
    color: 'brand.white',
    _hover: {
      bg: 'brand.ruby',
    },
  },
  activeOutline: {
    bg: 'transparent',
    borderWidth: '1px',
    borderColor: 'brand.crimson',
    borderStyle: 'solid',
    color: 'brand.crimson',
  },
  banner: {
    bg: 'rgba(255,255,255,0.2)',
    color: 'brand.white',
  },
  muted: {
    bg: 'brand.gray-600',
    color: 'brand.gray-800',
    _hover: {
      boxShadow: 'none',
    },
  },
  mutedOutline: {
    bg: 'transparent',
    borderWidth: '1px',
    borderColor: 'brand.gray-600',
    borderStyle: 'solid',
    color: 'brand.gray-600',
    _hover: {
      boxShadow: 'none',
    },
  },
  transparent: {
    bg: 'transparent',
    color: 'brand.gray-600',
  },
};

const defaultProps = {
  variant: 'action',
};

export const Button = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
