import { Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export const LocationLink = ({
  googleMapsLink,
  labelText,
}: {
  googleMapsLink: string | null;
  labelText: string;
}) =>
  googleMapsLink ? (
    <Text
      _hover={{ cursor: 'pointer' }}
      analytics-attr={`single-account-external-map-link`}
      as={'a'}
      color={'brand.coral'}
      fontSize={'xs'}
      fontWeight={'bold'}
      href={googleMapsLink}
      mr={2}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      {labelText}
      <ExternalLinkIcon ml={2} fontSize={'xl'} />
    </Text>
  ) : (
    <Text color={'brand.coral'} fontSize={'sm'}>
      {labelText}
    </Text>
  );
