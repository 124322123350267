import { Box, Flex, Text } from '@chakra-ui/react';
import { ServerPaginatedTable } from 'app/src/components/TableSection/components/ServerPaginatedTable';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ActionDrawerData,
  TableColumnDisplay,
  TableColumnType,
} from 'shared/firebase/types/tableTypes';
import { ApiPageInfo } from 'shared/graphql/generatedApiTypes';
import {
  DataFieldFormat,
  DataFieldRenderer,
} from 'shared/renderers/DataFieldRenderer';
import { AnalyticsAttrType, OptionsType } from 'shared/types/coreTypes.d';
import { Tooltip } from '../../../../../shared/components/Core';
import { getTableCell } from '../helpers/getTableCell';
import {
  CompanyTooltip,
  LastInOutboundTooltip,
  PredictionColumnTooltip,
} from '../renderers/AccountDrawerItemRenderer';

type AbstractedTableProps = AnalyticsAttrType & {
  actionDrawer?: ActionDrawerData;
  columns: TableColumnType[];
  data: any;
  defaultSort: string;
  error: string | null;
  fetchData: (p: { pageIndex: any; pageSize: any }) => void;
  legend: ReactNode | null;
  pageInfo: ApiPageInfo;
  selectedSort: any;
};

export const AbstractedTable = ({
  actionDrawer,
  analyticsAttr,
  columns,
  data = [],
  defaultSort,
  error,
  legend,
  pageInfo,
  selectedSort,
  fetchData,
}: AbstractedTableProps) => {
  const history = useHistory();
  const [sortColumns, setSortColumn] = useState<any>([]);

  let customerStatus = {
    label: 'Customer Status',
    value: 'customerStatus',
    analyticsAttr: 'customer-status-sort-select',
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => {
      // there can only be one of these
      const subcomponentStructure = columns.find(
        (column) => column.displayType === TableColumnDisplay.Expander,
      );

      if (!subcomponentStructure) {
        return null;
      }

      //@ts-ignore: we know this is here if it's an expander column
      const { subcomponent } = subcomponentStructure;
      let titleValue;
      if (
        subcomponent.title.dataFieldFormat === DataFieldFormat.ADDRESS_LINE2
      ) {
        titleValue = {
          addressCity: row.original.addressCity,
          addressRegion: row.original.addressRegion,
          addressZipCode: row.original.addressZipCode,
        };
      } else {
        titleValue = row.original[subcomponent.title.fields[0]];
      }

      return (
        <Box ml={8}>
          <Text fontWeight={'bold'} mb={2}>
            {DataFieldRenderer(titleValue, subcomponent.title.dataFieldFormat)}
          </Text>
          {subcomponent.rows.map((subRow) => (
            <Flex key={subRow.label}>
              <Text>{subRow.label} &nbsp;</Text>
              <Text fontWeight={'bold'}>
                {row.original[subRow.dataField]
                  ? DataFieldRenderer(
                      row.original[subRow.dataField],
                      subRow.dataFieldFormat,
                    )
                  : '-'}
              </Text>
            </Flex>
          ))}
        </Box>
      );
    },
    [columns],
  );

  // This function returns header and tooltip for  Compnay , Acquisition , Retention and Fit , Last Inbound Response, Last Outbound Activity
  const handleTooltip = (column) => {
    // This if return the tooltip for Prediction columns
    if (
      column.id === 'acquisitionPredictions' ||
      column.id === 'fitPredictions' ||
      column.id === 'retentionPredictions'
    ) {
      return PredictionColumnTooltip(
        column?.headerTooltip,
        column?.header,
        column?.header,
      );
    }

    // This if return the tooltip for Company columns
    if (column.id === 'accountName') {
      return CompanyTooltip(column?.headerTooltip, column?.header);
    }

    // This if returns the tooltip for Last Inbound Response or Last Outbound Activity
    if (
      column?.id === 'lastInboundResponse' ||
      column?.id === 'lastOutboundActivity'
    ) {
      return LastInOutboundTooltip(
        column?.headerTooltip,
        column?.header,
        column?.id,
        '',
      );
    }
  };

  // setup table columns and data
  const tableColumns = useMemo(
    () =>
      columns.map((column, index) => ({
        Header: 'All Accounts',
        columns: [
          {
            Cell: getTableCell({ column, history }),
            Header: column.headerTooltip ? (
              column.id === 'acquisitionPredictions' ||
              column.id === 'fitPredictions' ||
              column.id === 'retentionPredictions' ||
              column.id === 'accountName' ||
              column.id === 'lastInboundResponse' ||
              column.id === 'lastOutboundActivity' ? (
                handleTooltip(column)
              ) : (
                <Tooltip content={column.headerTooltip}>
                  <Text>{column.header}</Text>
                </Tooltip>
              )
            ) : (
              column.header
            ),
            accessor: column.accessor,
            analyticsAttrIndex: column.accessor,
            id: column.id,
            isNumeric: column.isNumeric,
            disableSortBy: !column.isSortable,
            headerString: column.header,
          },
        ],
      })),
    [columns, history],
  );

  const sortableColumnOptions = columns.reduce((accum, current) => {
    if (current.isSortable) {
      accum.push({
        label: current.header ?? '',
        value: current.accessor,
        analyticsAttr: cleanAnalyticsStringList([
          current.header,
          'sort select',
        ]),
      });
    }

    return accum;
  }, [] as OptionsType[]);

  useEffect(() => {
    let temp = sortableColumnOptions;
    temp.splice(1, 0, customerStatus);
    setSortColumn(temp);
  }, []);

  return (
    <Box px={4} py={2}>
      <ServerPaginatedTable
        actionDrawer={actionDrawer}
        analyticsAttr={analyticsAttr}
        columns={tableColumns}
        data={data}
        defaultSort={defaultSort}
        error={error}
        fetchData={fetchData}
        legend={legend}
        pageInfo={pageInfo}
        renderRowSubComponent={renderRowSubComponent}
        sortableColumnOptions={sortColumns}
        selectedSort={selectedSort}
      />
    </Box>
  );
};
