import classnames from 'classnames';
import { ReactNode } from 'react';
import {
  allSizeOptionsLiteral,
  ClassNameType,
  FullSizeType,
  HeightType,
} from 'shared/types/coreTypes.d';

type ContainerProps = ClassNameType & {
  children?: ReactNode;
  height?: HeightType;
  ph?: allSizeOptionsLiteral;
  pv?: allSizeOptionsLiteral;
  width?: FullSizeType;
};

function Container({
  children,
  className,
  height,
  ph,
  pv,
  width,
}: ContainerProps) {
  const containerClasses = classnames({
    'c-container': true,
    [`c-container--width-${width}`]: width,
    [`h-height-${height}`]: height,
    [`h-ph-${ph}`]: ph,
    [`h-pv-${pv}`]: pv,
    [`${className}`]: className,
  });

  return <div className={containerClasses}>{children}</div>;
}

export default Container;
