export const zIndexValues = {
  popoverContainer: 10000,
  accountSearch: 9000,
  menu: 8500,
  modalContent: 8000,
  modalBackdrop: 7000,
  brandAndFilterBar: 5000,
  loader: 4000,
  badge: 4000,
  disabledOverlay: 1000,
  selectDefault: 1000,
  selectBlanket: 500,
  header: 400,
};
