import { BrandBar, Header, Logo } from 'shared/components/Core';
import { Box } from '@chakra-ui/react';
import * as ROUTES from 'app/src/constants/routes';
import { ReactNode } from 'react';

export const UnauthedNavigationBar = ({
  children,
}: {
  children: ReactNode;
}) => (
  <>
    <BrandBar />
    <header className={'c-header c-header-transparent'}>
      <Header>
        <Box as={'a'} href={ROUTES.HOME} className={'c-header__logo h-pointer'}>
          <Logo />
        </Box>
      </Header>
    </header>
    {children}
  </>
);
