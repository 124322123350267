import { createIcon } from '@chakra-ui/icon';

export const XCircleIcon = createIcon({
  displayName: 'XCircleIcon',
  viewBox: '0 0 64 64',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='64'
      width='64'
      viewBox='0 0 64 64'
    >
      <title>f remove</title>
      <g fill='#ffffff' className='nc-icon-wrapper'>
        <path
          d='M32,2A30,30,0,1,0,62,32,30.026,30.026,0,0,0,32,2ZM46.8,41.2a.967.967,0,0,1,0,1.4l-4.2,4.2a.967.967,0,0,1-1.4,0L32,37.7l-9.2,9.2a.967.967,0,0,1-1.4,0l-4.2-4.2a.967.967,0,0,1,0-1.4l9.2-9.2-9.2-9.2a.967.967,0,0,1,0-1.4l4.2-4.2a.967.967,0,0,1,1.4,0L32,26.5l9.2-9.2a.967.967,0,0,1,1.4,0l4.2,4.2a.967.967,0,0,1,0,1.4L37.7,32Z'
          fill='#000'
        />
      </g>
    </svg>
  ),
});
