import {LabPieChartProps} from '../../types/labChartTypes';
import {PieChart} from '../../../../../../shared/components/Core';
import {getLabsPieChartKeyValue} from '../../helpers/labChartHelpers';

export const LabPieChart = ({
  columns,
  context = undefined,
  height = '100%',
  isAnswerTypePercent = false,
  isChartClickable = false,
  isSmallerViewport = false,
  rows,
  width = '100%',
}: LabPieChartProps) => {
  return (
    <PieChart
      data={getLabsPieChartKeyValue({ columns, rows })}
      events={undefined}
      height={height}
      isChartClickable={isChartClickable}
      isSmallerViewport={isSmallerViewport}
      isPercent={isAnswerTypePercent}
      plotToolText={`$label, $dataValue ${context}, ($percentValue of ${context})`}
      width={width}
    />
  );
};
