import {ApiGetAccountQuery} from 'shared/graphql/generatedApiTypes';
import _find from 'lodash/find';
import _round from 'lodash/round';
import _map from 'lodash/map';
import {useMemo} from 'react';
import _startCase from 'lodash/startCase';
import {BulletChart, Tooltip, Txt,} from '../../../../../../../shared/components/Core';
import {Text} from '@chakra-ui/react';
import _filter from 'lodash/filter';
import {GetImpactValuesReturnType, GetImpactValuesType,} from '../../../types/impactValuesTypes';
import {recommendationsTableAccessors} from '../../../constants/recommendationsTableAccessors';

const ACTION_MAPPING = {
  decrease: 'Pause',
  increase: 'Pursue',
  maintain: 'Pursue',
  pause: 'Pause',
  pursue: 'Pursue',
  stop: 'Pause',
};

export const useImpactValuesTable = (
  accountInfo: GetImpactValuesType['accountInfo'],
  tableHeaders: GetImpactValuesType['tableHeader'],
): GetImpactValuesReturnType => {
  const impactAddOn = (impact) => (impact > 0 ? '+' : '');
  const { currentValue = undefined, goalValues = undefined } = {
    ...(accountInfo as ApiGetAccountQuery['account']),
  };
  const prioritySignal = _find(goalValues, ['isPriority', true]);
  const nonPrioritySignal = _find(goalValues, ['isPriority', false]);
  const prioritySignalProbability = prioritySignal?.value;
  const nonPrioritySignalProbability = nonPrioritySignal?.value;
  const prioritySignalSignalPredictions = prioritySignal?.signalPredictions;
  const nonPrioritySignalSignalPredictions =
    nonPrioritySignal?.signalPredictions;
  const priorityImpact = _round(
    (prioritySignalProbability as number) - (currentValue ?? 0),
  );
  const nonPriorityImpact = _round(
    (nonPrioritySignalProbability as number) - (currentValue ?? 0),
  );
  const isPriorityImpactEqual = prioritySignalProbability === currentValue;
  const isNonPriorityImpactEqual =
    nonPrioritySignalProbability === currentValue;
  const priorityCleanedTableData = _map(
    prioritySignalSignalPredictions,
    (sp) => ({
      ...sp,
      expected_recommended: {
        goal: sp?.goal ?? 0,
        high: sp?.expectedHigh ?? 0,
        low: sp?.expectedLow ?? 0,
      },
    }),
  );
  const nonPriorityCleanedTableData = _map(
    nonPrioritySignalSignalPredictions,
    (sp) => ({
      ...sp,
      expected_recommended: {
        goal: sp?.goal ?? 0,
        high: sp?.expectedHigh ?? 0,
        low: sp?.expectedLow ?? 0,
      },
    }),
  );
  const priorityImpactLabel = isPriorityImpactEqual
    ? '0'
    : priorityImpact || priorityImpact === 0
    ? `${impactAddOn(priorityImpact)}${priorityImpact}`
    : '';
  const nonPriorityImpactLabel = isNonPriorityImpactEqual
    ? '0'
    : nonPriorityImpact || nonPriorityImpact === 0
    ? `${impactAddOn(nonPriorityImpact)}${nonPriorityImpact}`
    : '';
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Priority Signal',
        columns: [
          {
            Cell: ({ value }) => _startCase(value),
            Header: (
              <Tooltip
                analyticsAttr={tableHeaders.signalTypeText}
                content={<Txt>{tableHeaders.signalTypeTooltip}</Txt>}
                text={tableHeaders.signalTypeText}
              />
            ),
            accessor: recommendationsTableAccessors.signalType,
            analyticsAttrIndex: recommendationsTableAccessors.signalType,
          },
          {
            Cell: ({ value }) => ACTION_MAPPING[value],
            Header: (
              <Tooltip
                analyticsAttr={tableHeaders.recommendationText}
                content={<Txt>{tableHeaders.recommendationTooltip}</Txt>}
                text={tableHeaders.recommendationText}
              />
            ),
            accessor: recommendationsTableAccessors.recommendation,
            analyticsAttrIndex: recommendationsTableAccessors.signalType,
          },
          {
            Cell: ({ value }) => (
              <div analytics-attr={'recommendations-bullet-chart'}>
                <BulletChart {...value} />
              </div>
            ),
            Header: (
              <Tooltip
                analyticsAttr={tableHeaders.expectedRecommendedText}
                content={
                  <Text fontSize={'sm'}>
                    {tableHeaders.expectedRecommendedTooltip}
                  </Text>
                }
                text={tableHeaders.expectedRecommendedText}
              />
            ),
            accessor: recommendationsTableAccessors.expectedRecommended,
            analyticsAttrIndex: recommendationsTableAccessors.signalType,
            inlineHeaderProps: { justifyContent: 'center' },
          },
        ],
      },
    ],
    [
      tableHeaders.expectedRecommendedText,
      tableHeaders.expectedRecommendedTooltip,
      tableHeaders.recommendationText,
      tableHeaders.recommendationTooltip,
      tableHeaders.signalTypeText,
      tableHeaders.signalTypeTooltip,
    ],
  );
  const priorityTableData = useMemo(() => {
    return priorityCleanedTableData;
  }, [priorityCleanedTableData]);
  const nonPriorityTableData = useMemo(() => {
    return _filter(
      nonPriorityCleanedTableData,
      (data) => (data.goal ?? 0) > 0 || (data.expectedHigh ?? 0) > 0,
    );
  }, [nonPriorityCleanedTableData]);
  return {
    nonPriorityImpactLabel,
    nonPriorityTableData,
    priorityImpactLabel,
    priorityTableData,
    tableColumns,
  };
};
