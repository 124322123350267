import { createIcon } from '@chakra-ui/icon';

export const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width='24'
      height='24'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
    >
      <g fill='none' className='nc-icon-wrapper'>
        <path
          d='M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z'
          fill='#323F5A'
        />
      </g>
    </svg>
  ),
});
