import {
  AccountGroupStat,
  AggregateDataContext,
} from 'app/src/context/AggregateDataContext';
import { useContext } from 'react';
import {
  filterIndexToString,
  FilterIndices,
  WINFLUENCE_RANK_TICKS,
  PREDICTION_NAME_LEVEL_FILTER_ITEM,
  PREDICTION_NAME_LEVEL_FIT_FILTER_ITEM,
} from 'app/src/components/GlobalFilters/types/filterTypes';
import {
  cleanStringCase,
  snakeCaseToTitleCase,
} from 'shared/helpers/formatHelpers';
import { Flex, Text } from '@chakra-ui/react';
import { returnMinMaxFilterValue } from 'app/src/context/helpers/getAccountGroupStats';
import {
  accountGroupStatValueIsArray,
  accountGroupStatValueIsString,
} from 'app/src/components/GlobalFilters/helpers/typeGuards';
import { RemovableChipButton } from 'shared/components/Core/RemovableChipButton';

export const FilterChipSet = ({ stat }: { stat: AccountGroupStat }) => {
  const { updateFilter, globalFilter } = useContext(AggregateDataContext);
  const removeArrayItem = (index: number) => {
    const currentFilterAtKey = globalFilter && globalFilter[stat.key];
    if (currentFilterAtKey) {
      const updatedValue = currentFilterAtKey.filter(
        (item) => item !== stat.value[index],
      );
      updateFilter({ index: stat.key, value: updatedValue });
    }
  };

  const removeRangeItem = () => {
    updateFilter({ index: stat.key, value: null });
  };

  // set label for predcitonNamelevel in reset filter
  const predictionTypeNameLevelLabel = (item) => {
    if (item.split(':')[1] === 'any') {
      let labelArray = item.split(':');
      const index = labelArray.indexOf('any');
      if (index > -1) {
        labelArray.splice(index, 1);
      }
      if (labelArray[0] === 'fit') {
        return (
          labelArray[0].charAt(0).toUpperCase() +
          labelArray[0].slice(1) +
          ' - ' +
          PREDICTION_NAME_LEVEL_FIT_FILTER_ITEM[labelArray[1]]
        );
      } else {
        return (
          labelArray[0].charAt(0).toUpperCase() +
          labelArray[0].slice(1) +
          ' - ' +
          PREDICTION_NAME_LEVEL_FILTER_ITEM[labelArray[1]]
        );
      }
    } else {
      let labelArray = item.split(':');
      return (
        labelArray[0].charAt(0).toUpperCase() +
        labelArray[0].slice(1) +
        ' - ' +
        labelArray[1] + ' - ' +
        PREDICTION_NAME_LEVEL_FILTER_ITEM[labelArray[2]]
      );
    }
  };

  if (accountGroupStatValueIsArray(stat.value)) {
    const itemLabels = stat.value.map((item) => {
      if (stat.key === FilterIndices.WINFLUENCE_ACTUAL) {
        return WINFLUENCE_RANK_TICKS[item];
      }
      if (stat.key === FilterIndices.PREDICTION_TYPE_NAME_LEVEL) {
        return predictionTypeNameLevelLabel(item);
      }
      return snakeCaseToTitleCase(item);
    });

    return (
      <Flex align={'center'} flexWrap={'wrap'}>
        <Text fontStyle={'italic'} mr={1}>{`${
          filterIndexToString[stat.key]
        }:`}</Text>
        {itemLabels.map((item, index) => (
          <RemovableChipButton
            text={item}
            onClick={() => removeArrayItem(index)}
          />
        ))}
      </Flex>
    );
  }
  if (accountGroupStatValueIsString(stat.value)) {
    return (
      <Flex align={'center'} flexWrap={'wrap'}>
        <Text fontStyle={'italic'} mr={1}>{`${cleanStringCase(
          stat.key,
        )}:`}</Text>
        <RemovableChipButton
          text={cleanStringCase(stat.value)}
          onClick={removeRangeItem}
        />
      </Flex>
    );
  }
  const displayString = returnMinMaxFilterValue(
    stat.value,
    stat.key,
    stat.key === FilterIndices.XEOMIN_PORTFOLIO_SPEND,
  );
  return displayString ? (
    <Flex align={'center'} flexWrap={'wrap'}>
      <Text fontStyle={'italic'} mr={1}>{`${cleanStringCase(stat.key)}:`}</Text>
      <RemovableChipButton text={displayString} onClick={removeRangeItem} />
    </Flex>
  ) : null;
};
