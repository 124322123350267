import {createIcon} from '@chakra-ui/icon';

export const LightBulbIcon = createIcon({
  displayName: 'LightBulb',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M3 11H0V13H3V11Z' fill='white' />
      <path
        d='M4.22084 2.80748L2.80664 4.22168L4.92794 6.34298L6.34214 4.92878L4.22084 2.80748Z'
        fill='white'
      />
      <path d='M13 0H11V3H13V0Z' fill='white' />
      <path
        d='M19.7785 2.80729L17.6572 4.92859L19.0714 6.34279L21.1927 4.22149L19.7785 2.80729Z'
        fill='white'
      />
      <path d='M24 11H21V13H24V11Z' fill='white' />
      <path
        d='M12 5C8.14 5 5 8.141 5 12C5 14.714 6.581 17.17 9 18.319V23H15V18.319C17.419 17.171 19 14.715 19 12C19 8.141 15.86 5 12 5Z'
        fill='white'
      />
    </svg>
  ),
});
