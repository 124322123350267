import { useRef } from 'react';
import _round from 'lodash/round';
import useComponentSize from '@rehooks/component-size';
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { LineChartDataItemType } from 'shared/components/Core/LineChart';
import {
  Container,
  LineChart,
  Loading,
  Tooltip,
} from '../../../../../../../shared/components/Core';

type AccountScoreBodyProps = {
  data: Array<{
    backgroundColor: string;
    score: number | string;
    subtitle: string;
    title: string;
    tooltip: string | null | undefined;
  }>;
  isLoading: boolean;
  lineChartData: LineChartDataItemType;
  needsProcessing: boolean;
};

export const AccountScoreBody = ({
  data,
  isLoading = false,
  lineChartData,
  needsProcessing = false,
}: AccountScoreBodyProps) => {
  let chartWrapperRef = useRef(null);
  let { width } = useComponentSize(chartWrapperRef);
  return (
    <SimpleGrid columns={[1, null, null, 3, null]}>
      <Box
        analytics-attr={'account-score-line-chart'}
        ref={chartWrapperRef}
        gridColumn={[null, null, null, 'span 2', null]}
      >
        {isLoading ? (
          <Container width={'full'}>
            <Loading
              dotColor={'color'}
              flexDirection={'column'}
              text={'Processing Account Score Trend...'}
            />
          </Container>
        ) : (
          <>
            {needsProcessing && (
              <Text px={'sm'} color={'brand.crimson'}>
                {
                  'Attention: Klearly has not yet made historical predictions for this recently added account. Please allow 24 hours for processing.'
                }
              </Text>
            )}
            <LineChart data={lineChartData} width={width} />
          </>
        )}
      </Box>
      <Flex
        align={'top'}
        gap={'lg'}
        justify={'center'}
        mt={[0, null, null, 150, null]}
      >
        {data.map((item, idx) => (
          <Box key={idx} mx={2}>
            <Flex
              align={'center'}
              bg={item.backgroundColor}
              borderColor={'brand.white'}
              borderRadius={'50%'}
              borderStyle={'solid'}
              borderWidth={6}
              boxShadow={'0 1px 1px rgba(0, 0, 0, 0.25)'}
              height={18}
              justify={'center'}
              margin={'auto'}
              width={18}
            >
              <Text
                color={'brand.white'}
                fontSize={'xl'}
                fontWeight={'extrabold'}
              >
                {typeof item.score === 'number'
                  ? _round(item.score)
                  : item.score}
              </Text>
            </Flex>
            <Box>
              <Tooltip
                analyticsAttr={item.title}
                content={<Text>{item.tooltip}</Text>}
                hideIcon={true}
              >
                <Text align={'center'} fontWeight={'bold'}>
                  {item.title}
                </Text>
              </Tooltip>
            </Box>
            <Text align={'center'}>{item.subtitle}</Text>
          </Box>
        ))}
      </Flex>
    </SimpleGrid>
  );
};
