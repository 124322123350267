import { Maybe } from 'shared/graphql/generatedApiTypes';
import { numberFormatter } from 'shared/helpers/formatHelpers';

export const getFormattedEmployeeCount = (employeeCount: Maybe<string>) => {
  if (employeeCount === null || employeeCount === undefined) {
    return '-';
  }
  const formattedCount = numberFormatter(employeeCount, false);
  if (parseFloat(formattedCount) === 1) {
    return `${formattedCount} employee`;
  }
  return `${formattedCount} employees`;
};
