import { useContext, useEffect } from 'react';
import _isEqual from 'lodash/isEqual';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringNumberValueTypes,
} from 'shared/hooks/inputHook';
import { usePrevious } from 'app/src/hooks/previousHook';
import { AnalyticsAttrType, OptionsType } from 'shared/types/coreTypes.d';
import { filterItemsLoadingVar } from 'app/src/apollo/rootReactiveVariables';
import { RadioInput } from '../../../../../../shared/components/Core';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { FilterItemProps } from '../../types/filterItemProps';
import { sortSelectFilterOptions } from '../../helpers/sortSelectFilterOptions';
import { getFilterItemConsts } from '../../helpers/getFilterItemConsts';
import { adaptDictAsString } from '../../helpers/adaptFilterForQuery';
import _omit from 'lodash/omit';
import { useGetAggregateDataWithFilterVarQuery } from 'shared/graphql/generatedApiTypes';

type RadioInputFilterItemProps = AnalyticsAttrType &
  FilterItemProps & {
    defaultValue: string | number;
  };

const RadioInputFilterItem = ({
  analyticsAttr = undefined,
  defaultValue,
  initialItems = [],
  filterIndex,
  tableAccessor = '',
}: RadioInputFilterItemProps) => {
  const {
    data: initialAggregatedAccountsData,
    loading: initialAggregatedAccountsDataLoading,
    globalFilter,
    updateFilter,
    fullAggregateLoading,
  } = useContext(AggregateDataContext);

  const {
    data: filterItemAggregatedAccountsData,
    loading: filterItemAggregatedAccountsDataLoading,
  } = useGetAggregateDataWithFilterVarQuery({
    variables: {
      filterString: adaptDictAsString(_omit(globalFilter, filterIndex)) ?? '',
      type: 'account',
    },
  });

  const { aggregatedItemsAccountData, areFiltersApplied, isSelectedDisabled } =
    getFilterItemConsts({
      filterIndices: [filterIndex],
      filterItemAggregatedAccountsData,
      filterItemAggregatedAccountsDataLoading,
      globalFilter,
      initialAggregatedAccountsData,
      initialAggregatedAccountsDataLoading:
        initialAggregatedAccountsDataLoading || fullAggregateLoading,
    });

  const itemsSorted = sortSelectFilterOptions({
    aggregatedItemsAccountData,
    areFiltersApplied,
    filterIndex,
    initialItems,
    tableAccessor,
  });
  const totalAccounts = itemsSorted.reduce(
    (accum, current) => accum + current.count,
    0,
  );
  const {
    bind: bindItems,
    setValue: setItems,
    value,
  } = useInput(globalFilter?.[filterIndex] || defaultValue, {
    defaultValue: defaultValue,
    options: [
      {
        accountsCount: totalAccounts,
        count: totalAccounts,
        label: 'All',
        value: 'all',
        analyticsAttr: 'all',
      },
      ...itemsSorted,
    ],
  }) as UseInputHookReturnType & UseInputHookStringNumberValueTypes;

  // setup previous filter dict
  const previousFilterDict = usePrevious(globalFilter);
  const previousLoadingState = usePrevious(isSelectedDisabled);

  useEffect(() => {
    if (!_isEqual(previousFilterDict, globalFilter)) {
      setItems(globalFilter?.[filterIndex] || defaultValue);
    }
  }, [defaultValue, globalFilter, filterIndex, previousFilterDict, setItems]);
  useEffect(() => {
    if (!_isEqual(previousLoadingState, isSelectedDisabled)) {
      filterItemsLoadingVar(isSelectedDisabled);
    }
  }, [isSelectedDisabled, previousLoadingState]);

  const updateSelection = (selectedOption: string) => {
    if (selectedOption === 'all') {
      updateFilter({
        index: filterIndex,
        value: undefined,
      });
    } else {
      updateFilter({
        index: filterIndex,
        value: selectedOption,
      });
    }
  };

  return (
    <RadioInput
      {...bindItems}
      analytics-attr={analyticsAttr}
      className={'h-grid h-grid-column-gap-lg'}
      disabled={isSelectedDisabled}
      onChange={(e) => updateSelection(e.target.value)}
      options={bindItems.options as OptionsType[]}
      value={value}
    />
  );
};

export default RadioInputFilterItem;
