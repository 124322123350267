import { VolumeBarChartProps } from 'app/src/components/GaugeChart/components/VolumeBarChart';
import { differenceInCalendarDays } from 'date-fns';

export const getXeominVolumeStringAndWidths = ({
  currentQtrXeominTotal,
  prevQtrXeominTotal,
  endDate,
  predictedTotal,
}: Omit<VolumeBarChartProps, 'volumeNarratives'>) => {
  if (
    currentQtrXeominTotal === undefined ||
    prevQtrXeominTotal === undefined ||
    predictedTotal === undefined
  ) {
    return {
      xeominVolumeString: '',
      prevVolumeWidth: 0,
      currentVolumeWidth: 0,
      referenceLineLeftMargin: 0,
    };
  }

  const xeominComparison =
    currentQtrXeominTotal > prevQtrXeominTotal
      ? 'ahead of'
      : currentQtrXeominTotal === prevQtrXeominTotal
      ? 'the same as'
      : 'trailing';

  const daysUntilEndOfQtr =
    differenceInCalendarDays(new Date(endDate), new Date()) + 1;

  const daysString =
    daysUntilEndOfQtr === 0
      ? 'There are no days left in the quarter'
      : daysUntilEndOfQtr === 1
      ? 'There is still 1 day left in the quarter.'
      : `There are still ${daysUntilEndOfQtr} days left in the quarter.`;

  const greatestTotal = Math.max(
    predictedTotal,
    currentQtrXeominTotal,
    prevQtrXeominTotal,
  );

  const SECTION_WIDTH = greatestTotal ? 120 : 0;

  const currentVolumeWidth =
    currentQtrXeominTotal !== greatestTotal
      ? (currentQtrXeominTotal / greatestTotal) * SECTION_WIDTH
      : SECTION_WIDTH;
  const prevVolumeWidth =
    prevQtrXeominTotal !== greatestTotal
      ? (prevQtrXeominTotal / greatestTotal) * SECTION_WIDTH
      : SECTION_WIDTH;
  const referenceLineLeftMargin =
    predictedTotal !== greatestTotal
      ? (predictedTotal / greatestTotal) * SECTION_WIDTH
      : SECTION_WIDTH;

  const difference = Math.abs(currentQtrXeominTotal - prevQtrXeominTotal);
  const byString = difference === 0 ? '.' : ` by ${difference}.`;

  const xeominVolumeString = `Xeomin purchase qty is ${xeominComparison} the previous quarter${byString} ${daysString}`;

  return {
    prevVolumeWidth,
    currentVolumeWidth,
    referenceLineLeftMargin,
    xeominVolumeString,
  };
};
