import {Button} from '../../../../../../../shared/components/Core';
import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons';

type RecommendationsToggleShowHideButtonProps = {
  accountId: number;
  isShowing: boolean;
  sectionType: 'all' | 'primary';
  setIsShowing: (newVal: boolean) => void;
};

export const RecommendationsToggleShowHideButton = ({
  accountId,
  isShowing,
  sectionType,
  setIsShowing,
}: RecommendationsToggleShowHideButtonProps) => {
  const sectionString =
    sectionType === 'all' ? 'All Actions' : 'Primary Action';
  return (
    <Button
      analyticsAttr={`View ${sectionString} Account ${accountId}`}
      extraAnalyticsAttr={`View ${sectionString}`}
      onClick={() => setIsShowing(!isShowing)}
      leftIcon={isShowing ? <ViewIcon /> : <ViewOffIcon />}
      fontProps={{ fontSize: 'sm' }}
      minW={40}
      ml={6}
      text={isShowing ? `Hide ${sectionString}` : `View ${sectionString}`}
    />
  );
};
