import {
  ApiMessageTag,
  ApiMessageType,
  useGetAggregateMessagesLazyQuery,
} from 'shared/graphql/generatedApiTypes';
import { subDays } from 'date-fns';
import {
  formatDateForTable,
  formatDateString,
} from 'shared/helpers/formatHelpers';
import { Card, Loading } from 'shared/components/Core';
import { MessageItem } from 'app/src/components/MessageFeed/components/MessageItem';
import { Flex, FormLabel, Select, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useEffect, useState } from 'react';

export type AggregatedMessageFeedProps = {};

export const AggregatedMessageFeed = ({}: AggregatedMessageFeedProps) => {
  const last24Hours = {
    dateFrom: formatDateString(new Date()),
    dateTo: formatDateString(new Date()),
  };
  const last7Days = {
    dateFrom: formatDateString(subDays(new Date(), 7)),
    dateTo: formatDateString(new Date()),
  };
  const last30Days = {
    dateFrom: formatDateString(subDays(new Date(), 30)),
    dateTo: formatDateString(new Date()),
  };
  const last90Days = {
    dateFrom: formatDateString(subDays(new Date(), 90)),
    dateTo: formatDateString(new Date()),
  };

  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string>(
    JSON.stringify(last30Days),
  );
  const [selectedTag, setSelectedTag] = useState<ApiMessageTag | 'All'>('All');

  const [getAggregateMessages, { data, loading, error }] =
    useGetAggregateMessagesLazyQuery({
      variables: {
        dateFrom: formatDateString(subDays(new Date(), 30)),
        dateTo: formatDateString(new Date()),
        interval: 1,
      },
    });

  useEffect(() => {
    getAggregateMessages({
      variables: { ...JSON.parse(selectedTimePeriod), interval: 1 },
    });
  }, [selectedTimePeriod]);

  const timePeriodOptions = [
    { label: 'Last 24 hours', value: JSON.stringify(last24Hours) },
    {
      label: 'Last 7 days',
      value: JSON.stringify(last7Days),
    },
    {
      label: 'Last 30 days',
      value: JSON.stringify(last30Days),
    },
    {
      label: 'Last 90 days',
      value: JSON.stringify(last90Days),
    },
  ];

  return loading ? (
    <Loading />
  ) : (
    <>
      {!!error && (
        <Card>
          <Text color={'tomato'}>Error loading messages</Text>
        </Card>
      )}
      <Flex justify={'flex-end'} align={'center'}>
        <FormLabel mt={2}>Topic: </FormLabel>
        <Select
          rightIcon={<ChevronDownIcon />}
          borderWidth={2}
          borderRadius={24}
          borderColor={'#ECF2FA'}
          fontWeight={'normal'}
          maxW={160}
          mr={2}
          variant={'actionOutline'}
          value={selectedTag}
          onChange={(e) =>
            setSelectedTag(e.target.value as ApiMessageTag | 'All')
          }
        >
          {/*temporarily do this until the others exist*/}
          <option value={'All'}>All</option>
          <option value={ApiMessageTag.Engagement}>Engagement</option>
          <option value={ApiMessageTag.Outreach}>Outreach</option>
        </Select>
        <FormLabel mt={2}>Time period: </FormLabel>
        <Select
          rightIcon={<ChevronDownIcon />}
          borderWidth={2}
          borderRadius={24}
          borderColor={'#ECF2FA'}
          fontWeight={'normal'}
          maxW={200}
          variant={'actionOutline'}
          value={selectedTimePeriod}
          onChange={(e) => setSelectedTimePeriod(e.target.value)}
        >
          {timePeriodOptions.map((period) => (
            <option key={period.value} value={period.value}>
              {period.label}
            </option>
          ))}
        </Select>
      </Flex>
      {!data?.aggregateMessages?.length ? (
        <Card minH={36}>
          <Text>No messages for the selected filters</Text>
        </Card>
      ) : (
        data.aggregateMessages
          ?.filter(
            (message) =>
              selectedTag === 'All' || message?.tags?.includes(selectedTag),
          )
          .map((message, i) => (
            <Card isLoading={loading} minH={36} key={i}>
              <MessageItem
                messageType={message?.type ?? ApiMessageType.Insight}
                message={message?.body ?? ''}
                date={formatDateForTable(message?.dateFrom)}
                accountsToLink={
                  message?.accounts?.map((item) => item?.id) ?? undefined
                }
                tags={message?.tags}
              />
            </Card>
          ))
      )}
    </>
  );
};
