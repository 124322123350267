import {
  LabBarChartColumnType,
  LabCardApiResponse,
  LabHeatMapChartColumnType,
  LabPieChartColumnType,
  LabScatterBubbleChartColumnType,
} from '../types/labApiTypes';
import { BarChartType } from 'shared/components/Core/BarChart';
import _size from 'lodash/size';
import _isString from 'lodash/isString';
import { PieChartProps } from 'shared/components/Core/PieChart';
import { HeatMapPropsType } from 'shared/components/Core/HeatMap/HeatMap';
import _round from 'lodash/round';
import {
  HeatMapAnswerTypes,
  ScatterChartAnswerReturnType,
} from '../types/labChartTypes';
import {
  cleanStringCase,
  formatAsPercentage,
  numberFormatter,
} from 'shared/helpers/formatHelpers';

type GetChartLabelValueBaseParams = {
  columns: LabCardApiResponse['columns'];
  rows: LabCardApiResponse['rows'];
};

type GetBarChartLabelValueParams = GetChartLabelValueBaseParams & {
  context?: string;
  isDollars?: boolean;
  totalNumber: number;
};

export function getBarChartLabelValue({
  columns,
  context,
  isDollars = false,
  rows,
  totalNumber = 0,
}: GetBarChartLabelValueParams): BarChartType['data'] {
  if (_size(columns) === 0) {
    return [
      {
        label: '',
        value: 0,
      },
    ];
  }

  // make sure it's a string in case undefined was passed
  const labelContext = context ?? '';

  // if max is null, then it's the top end of the range, so return a '+'
  const getLabel = (label: string | number) => {
    if (_isString(label)) {
      return ' ' + label + ' ';
    }
    if (label === null) {
      return '+';
    }
    return numberFormatter(label, isDollars);
  };

  return rows.map((row) => {
    return columns.reduce(
      (accum, col, colIndex) => {
        let placeholderPercentValue = '';
        switch (col.type) {
          case LabBarChartColumnType.labelMin:
            const maxColumnIndex = columns.findIndex(
              (colToFind) => colToFind.type === LabBarChartColumnType.labelMax,
            );
            accum.label += `${getLabel(row[colIndex])}-${getLabel(
              row[maxColumnIndex],
            )}`;
            break;
          case LabBarChartColumnType.key:
            accum.label = cleanStringCase(row[colIndex] as string);
            break;
          case LabBarChartColumnType.value:
            accum.value = row[colIndex] as number;
            break;
          case LabBarChartColumnType.valueAsPercentage:
            placeholderPercentValue = formatAsPercentage(
              row[colIndex] as number,
              { precision: 2 },
            );
            break;
        }
        const percentValue = placeholderPercentValue
          ? placeholderPercentValue
          : formatAsPercentage(accum.value / totalNumber, { precision: 2 });
        accum.toolText = `$label, $dataValue ${labelContext}, ${percentValue} of total ${labelContext}`;
        return accum;
      },
      {
        label: '',
        value: 0,
        toolText: '',
      },
    );
  });
}

export function getLabsPieChartKeyValue({
  columns,
  rows,
}: GetChartLabelValueBaseParams): PieChartProps['data'] {
  if (_size(columns) === 0) {
    return [
      {
        key: '',
        value: 0,
      },
    ];
  }
  return rows.map((row) => {
    return columns.reduce(
      (accum, col, colIndex) => {
        switch (col.type) {
          case LabPieChartColumnType.key:
            accum.key += row[colIndex];
            break;
          case LabPieChartColumnType.value:
            accum.value = row[colIndex] as number;
            break;
        }
        return accum;
      },
      {
        key: '',
        value: 0,
      },
    );
  });
}

export function getLabsHeatMapDataValues({
  columns,
  rows,
}: GetChartLabelValueBaseParams): HeatMapAnswerTypes {
  if (_size(columns) === 0) {
    return {
      columns: [],
      data: [],
      rows: [],
    };
  }
  const getColor = (value: number = 0) =>
    value >= 0 && value <= 33.33
      ? '#F3ECF6'
      : value > 33.33 && value <= 66.66
      ? '#B98BC7'
      : '#7E2A98';
  let heatMapCols: HeatMapPropsType[] = [];
  let heatMapData: HeatMapPropsType[] = [];
  let heatMapRows: HeatMapPropsType[] = [];
  const xIndex = columns.findIndex(
    (item) => item.type === LabHeatMapChartColumnType.xAxis,
  );
  const yIndex = columns.findIndex(
    (item) => item.type === LabHeatMapChartColumnType.yAxis,
  );
  const percentageOfTotalIndex = columns.findIndex(
    (item) => item.type === LabHeatMapChartColumnType.valueAsPercentage,
  );

  rows.forEach((row) => {
    columns.forEach((col, colIndex) => {
      switch (col.type) {
        case LabHeatMapChartColumnType.xAxis:
          // only add unique labels
          if (
            !heatMapRows.find(
              (existingRow) => existingRow.label === row[colIndex],
            )
          ) {
            heatMapRows.push({
              label: row[colIndex] as string,
              id: row[colIndex] as string,
            });
          }
          break;
        case LabHeatMapChartColumnType.yAxis:
          // only add unique labels
          if (
            !heatMapCols.find(
              (existingRow) => existingRow.label === row[colIndex],
            )
          ) {
            heatMapCols.push({
              label: row[colIndex] as string,
              id: row[colIndex] as string,
            });
          }
          break;
        case LabHeatMapChartColumnType.value:
          heatMapData.push({
            value: row[colIndex] as number,
            // this calculates based on the percentage as a number, not a decimal
            color: getColor((row[percentageOfTotalIndex] as number) * 100),
            rowId: row[xIndex] as string,
            columnId: row[yIndex] as string,
            label: formatAsPercentage(
              row[percentageOfTotalIndex] as number,
              {},
            ),
          });
          break;
      }
    });
  });

  return {
    columns: heatMapCols,
    data: heatMapData,
    rows: heatMapRows,
  };
}

export function getLabsScatterBubbleChartValues({
  columns,
  rows,
}: GetChartLabelValueBaseParams): ScatterChartAnswerReturnType {
  if (_size(columns) === 0) {
    return {
      data: [],
      xAxisLabel: '',
      yAxisLabel: '',
      zAxisLabel: '',
    };
  }

  const data = rows.map((row) => {
    return columns.reduce(
      (accum, col, colIndex) => {
        switch (col.type) {
          case LabScatterBubbleChartColumnType.key:
            accum.key = row[colIndex] as string;
            accum.label = row[colIndex] as string;
            break;
          case LabScatterBubbleChartColumnType.value:
            accum.value = row[colIndex] as number;
            break;
          case LabScatterBubbleChartColumnType.xAxis:
            accum.x = row[colIndex] as number;
            break;
          case LabScatterBubbleChartColumnType.yAxis:
            accum.y = _round((row[colIndex] as number) * 100, 2);
            break;
          case LabScatterBubbleChartColumnType.zAxis:
            accum.z = _round((row[colIndex] as number) * 100, 2);
            break;
        }
        return accum;
      },
      {
        x: 0,
        y: 0,
        z: 0,
        key: '',
        label: '',
        value: 0,
        fill: 'var(--color-klearly-blue)',
      },
    );
  });
  const xAxisLabel =
    columns.find((col) => col.type === LabScatterBubbleChartColumnType.xAxis)
      ?.label ?? '';
  const yAxisLabel =
    columns.find((col) => col.type === LabScatterBubbleChartColumnType.yAxis)
      ?.label ?? '';
  const zAxisLabel =
    columns.find((col) => col.type === LabScatterBubbleChartColumnType.zAxis)
      ?.label ?? '';
  return {
    data,
    xAxisLabel,
    yAxisLabel,
    zAxisLabel,
  };
}
