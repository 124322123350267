import {
  SavedPageSchema,
  TopLevelNavStructure,
} from '../firebase/schemas/SavedPage';

export const isTopLevelNavLink = (
  pageNavStructure: SavedPageSchema['navStructure'],
): pageNavStructure is TopLevelNavStructure => {
  return (
    (pageNavStructure as TopLevelNavStructure).urlParent === null ||
    (pageNavStructure as TopLevelNavStructure).urlParent === undefined ||
    (pageNavStructure as TopLevelNavStructure).urlParent === ''
  );
};

export const buildPath = (
  navStructure: SavedPageSchema['navStructure'],
): string => {
  // no parent means it's a top level link
  if (!navStructure.urlParent && navStructure.urlPaths[0]) {
    return navStructure.urlPaths[0].path;
  }
  let newPath = navStructure.urlParent;
  navStructure.urlPaths.forEach(
    (path) => (newPath += `${path.param}${path.path}`),
  );
  return newPath ?? '';
};
