export const MoneyIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='40'
    viewBox='0 0 40 40'
    fill='none'
  >
    <rect width='40' height='40' rx='20' fill='white' />
    <rect width='40' height='40' rx='20' fill='#4BDF95' fillOpacity='0.2' />
    <path
      d='M20 9V31'
      stroke='#323F5A'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24.5 12C23.0847 11.3608 21.5528 11.0203 20 11C17.47 11 14.5 12 14.5 15C14.5 21 25.5 18 25.5 24C25.5 27 22.984 27.977 20 28C17.9578 28.0087 15.929 27.6705 14 27'
      stroke='#323F5A'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
