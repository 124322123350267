import { Flex } from '@chakra-ui/react';
import { ApiGetAccountWithIncludeDirectivesQuery } from 'shared/graphql/generatedApiTypes';
import { FormattedText } from './SingleAccountQuickStatText';
import { QuickStatType } from './singleAccountQuickStatTypes';
import {
  DataFieldFormat,
  DataFieldRenderer,
} from 'shared/renderers/DataFieldRenderer';
import { IconRenderer } from 'shared/renderers/IconRenderer';

type SingleAccountQuickStatRowProps = {
  rowDetails: QuickStatType;
  dataToShow: Partial<ApiGetAccountWithIncludeDirectivesQuery['account']>;
  rowType: 'center' | 'bottom';
};

export const SingleAccountQuickStatRow = ({
  rowDetails,
  dataToShow,
  rowType,
}: SingleAccountQuickStatRowProps) => {
  return (
    <Flex align={'center'} my={1}>
      {rowDetails.leftIcon && rowDetails.dataField && dataToShow
        ? IconRenderer({
            iconName: rowDetails.leftIcon,
            iconColor: rowDetails.leftIconColor,
            value: dataToShow[rowDetails.dataField],
          })
        : null}
      {rowDetails.leftText && (
        <FormattedText rowType={rowType}>
          {rowDetails.leftText} &nbsp;
        </FormattedText>
      )}
      <FormattedText rowType={rowType} ml={rowDetails.leftIcon ? 2 : 0}>
        {rowDetails.dataField &&
          dataToShow &&
          DataFieldRenderer(
            dataToShow[rowDetails.dataField],
            rowDetails.dataFieldFormat ?? DataFieldFormat.TEXT,
          )}
      </FormattedText>
      {!rowDetails.dataField && rowDetails.fallbackValue && (
        <FormattedText rowType={rowType}>
          {rowDetails.fallbackValue}
        </FormattedText>
      )}
      {rowDetails.rightText && (
        <FormattedText rowType={rowType}>
          &nbsp;
          {rowDetails.rightText}
        </FormattedText>
      )}
    </Flex>
  );
};
