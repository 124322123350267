import { createIcon } from '@chakra-ui/icon';

export const TrimIcon = createIcon({
  displayName: 'TrimIcon',
  viewBox: '0 0 64 64',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='64'
      width='64'
      viewBox='0 0 64 64'
    >
      <title>path minus</title>
      <g fill='#ffffff' className='nc-icon-wrapper'>
        <path
          fill='#ffffff'
          d='M2,63h40c0.552,0,1-0.447,1-1V43h19c0.552,0,1-0.447,1-1V2c0-0.553-0.448-1-1-1H22c-0.552,0-1,0.447-1,1v19 H2c-0.552,0-1,0.447-1,1v40C1,62.553,1.448,63,2,63z M3,23h19h19v19v19H3V23z'
        />
      </g>
    </svg>
  ),
});
