import { BaseModel } from './BaseModel';
import { Firestore } from '../types/firebaseTypes';
import { SavedComponentDocument } from '../documents/SavedComponentDocument';
import { SavedComponentSchema } from '../schemas/SavedComponent';

export class SavedComponentModel extends BaseModel<
  SavedComponentSchema,
  SavedComponentDocument
> {
  constructor(firestore: Firestore | null) {
    super(
      firestore,
      'SavedComponent',
      `saved_components`,
      SavedComponentDocument,
    );
  }
}
