import { useContext, useEffect, useMemo } from 'react';
import { Button, IndeterminateCheckbox } from 'shared/components/Core';
import { Box, ButtonGroup, IconButton } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { formatDateForTable } from 'shared/helpers/formatHelpers';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { SelectedCompaniesIcon } from 'shared/components/Core/Icon/companies';
import { filterStringFromBackendToDict } from 'app/src/components/GlobalFilters/helpers/filterDictAndFilterStringHelpers';
import {
  appliedFilterVar,
  appliedListsVar,
} from 'app/src/apollo/rootReactiveVariables';
import { FilterDictType } from 'app/src/components/GlobalFilters/types/filterDict';
import { OptionsType } from 'shared/types/coreTypes.d';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import { FieldValue } from 'shared/firebase/initializeFirebase';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { useAsync } from 'app/src/hooks/useAsync';
import { useHistory } from 'react-router-dom';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';

export const useSavedGroupColumns = ({
  accountGroupData,
  groupType,
}: {
  accountGroupData;
  groupType: 'filter' | 'list';
}) => {
  const { updateFilter, overrideFilter, globalFilter } =
    useContext(AggregateDataContext);

  const firebaseUserUID = AuthManager.currentUser?.uid ?? '';
  const companyName = AuthManager.klearlyUser?.companyName ?? '';
  const userSettingsDocId = `${firebaseUserUID}#${companyName}`;

  const history = useHistory();
  const getUserSettingsAsync = async () => {
    return DatabaseManager.UserSettingsModel.get(userSettingsDocId);
  };
  const getUserSettings = useAsync(getUserSettingsAsync);
  useEffect(() => {
    getUserSettings.execute();
  }, []);

  const applySelectedList = async (id: number) => {
    const appliedLists = appliedListsVar();
    if (!appliedLists) {
      updateFilter({
        index: FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS,
        value: [id],
      });
    } else {
      updateFilter({
        index: FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS,
        value: Array.from(new Set([...appliedLists, id])),
      });
    }
    history.push('/companies');
  };

  const applySelectedFilter = async (id: number) => {
    const selectedFilter = accountGroupData?.accountGroups?.find(
      (group) => group?.id === id,
    );
    if (globalFilter) {
      const selectedDict = filterStringFromBackendToDict(
        selectedFilter?.filterString ?? '',
      );
      appliedFilterVar({
        id,
        title: selectedFilter?.title ?? '',
      });
      const newFilter: FilterDictType & { accounts: Array<OptionsType> } = {
        [FilterIndices.EXCLUDED_ACCOUNT_IDS]:
          globalFilter[FilterIndices.EXCLUDED_ACCOUNT_IDS],
        [FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS]:
          globalFilter[FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS],
        ...selectedDict,
      };
      overrideFilter(newFilter);
    }
    history.push('/companies');
  };

  const toggleFavoritingList = async (listId: number) => {
    if (getUserSettings.value?.data && firebaseUserUID) {
      const { data } = getUserSettings.value;
      // it's already favorited
      const isFavorited = data?.listFavorites?.find((id) => id === listId);
      if (isFavorited) {
        await getUserSettings.value.ref.update({
          listFavorites: FieldValue.arrayRemove(listId),
        });
        getUserSettings.execute();
      } else {
        const newFavorites = data?.listFavorites ?? [];
        newFavorites.push(listId);
        await DatabaseManager.UserSettingsModel.update(userSettingsDocId, {
          ...data,
          listFavorites: newFavorites,
        });
      }
    } else {
      await DatabaseManager.UserSettingsModel.create(
        {
          excludedAccountIds: [],
          labCardFavorites: [],
          filterFavorites: [],
          listFavorites: [listId],
          quickAccessFilters: null,
        },
        userSettingsDocId,
      );
    }
    getUserSettings.execute();
  };

  const toggleFavoritingFilter = async (filterId: number) => {
    if (getUserSettings.value?.data && firebaseUserUID) {
      const { data } = getUserSettings.value;
      // it's already favorited
      const isFavorited = data?.filterFavorites?.find((id) => id === filterId);
      if (isFavorited) {
        await getUserSettings.value.ref.update({
          filterFavorites: FieldValue.arrayRemove(filterId),
        });
        getUserSettings.execute();
      } else {
        const newFavorites = data?.filterFavorites ?? [];
        newFavorites.push(filterId);
        await DatabaseManager.UserSettingsModel.update(userSettingsDocId, {
          ...data,
          filterFavorites: newFavorites,
        });
      }
    } else {
      await DatabaseManager.UserSettingsModel.create(
        {
          excludedAccountIds: [],
          labCardFavorites: [],
          filterFavorites: [filterId],
          listFavorites: [],
          quickAccessFilters: null,
        },
        userSettingsDocId,
      );
    }
    getUserSettings.execute();
  };

  if (groupType === 'filter') {
    return useMemo(
      () => [
        {
          Header: 'Filters',
          columns: [
            {
              Cell: ({ row }) => (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              ),
              id: 'selector',
            },
            {
              Cell: ({ row, value }) => {
                return (
                  <Box>
                    {value}
                    <br />
                    <em>
                      {!row.original?.description
                        ? 'No description provided'
                        : row.original.description}
                    </em>
                  </Box>
                );
              },
              Header: 'Name',
              accessor: 'title',
              analyticsAttrIndex: 'title',
            },
            {
              Header: 'Favorite',
              id: 'favorite',
              analyticsAttrIndex: 'favorite',
              Cell: ({ row }) => {
                const isFavorited =
                  getUserSettings.value?.data?.filterFavorites?.find(
                    (id) => id === row.original.id,
                  );
                return (
                  <IconButton
                    ml={4}
                    aria-label={'favorite-list'}
                    icon={
                      <StarIcon
                        color={isFavorited ? 'orange' : 'brand.gray-600'}
                      />
                    }
                    variant={'transparent'}
                    onClick={() =>
                      toggleFavoritingFilter(
                        typeof row.original.id === 'string'
                          ? parseInt(row.original.id)
                          : row.original.id,
                      )
                    }
                  />
                );
              },
            },
            {
              Header: 'Created By',
              Cell: ({ value }) => (
                <Box maxW={[12, null, null, 20, null]}>{value}</Box>
              ),
              accessor: (originalRow) =>
                `${originalRow.user.firstName} ${originalRow.user.lastName}`,
              analyticsAttrIndex: 'createdBy',
            },
            {
              Cell: ({ value }) => `${formatDateForTable(value)}`,
              Header: 'Modified Date',
              accessor: (originalRow) => originalRow.accountsUpdatedAt,
              analyticsAttrIndex: 'modifiedDate',
            },
            {
              Cell: ({ value }) => `${formatDateForTable(value)}`,
              Header: 'Created Date',
              accessor: (originalRow) => originalRow.createdAt,
              analyticsAttrIndex: 'createdDate',
            },
            {
              Cell: ({ row }) => (
                <ButtonGroup>
                  <Button
                    analyticsAttr={cleanAnalyticsStringList([
                      'refresh',
                      row.original.id,
                    ])}
                    onClick={() => applySelectedFilter(row.original.id)}
                    size={'sm'}
                    variant={'transparent'}
                    leftIcon={<SelectedCompaniesIcon boxSize={4} />}
                    text={`Companies`}
                    bg={'brand.cornflower'}
                    color={'white'}
                  />
                </ButtonGroup>
              ),
              accessor: 'accountCount',
              Header: 'View',
            },
          ],
        },
      ],
      [getUserSettings.value],
    );
  }
  return useMemo(
    () => [
      {
        Header: 'Lists',
        columns: [
          {
            Cell: ({ row }) => (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),
            id: 'selector',
          },
          {
            Cell: ({ row, value }) => {
              return (
                <Box>
                  {value}
                  <br />
                  <em>
                    {!row.original?.description
                      ? 'No description provided'
                      : row.original.description}
                  </em>
                </Box>
              );
            },
            Header: 'Name',
            accessor: 'title',
            analyticsAttrIndex: 'title',
          },
          {
            Header: 'Favorite',
            id: 'favorite',
            analyticsAttrIndex: 'favorite',
            Cell: ({ row }) => {
              const isFavorited =
                getUserSettings.value?.data?.listFavorites?.find(
                  (id) => id === row.original.id,
                );
              return (
                <IconButton
                  ml={4}
                  aria-label={'favorite-list'}
                  icon={
                    <StarIcon
                      color={isFavorited ? 'orange' : 'brand.gray-600'}
                    />
                  }
                  variant={'transparent'}
                  onClick={() =>
                    toggleFavoritingList(
                      typeof row.original.id === 'string'
                        ? parseInt(row.original.id)
                        : row.original.id,
                    )
                  }
                />
              );
            },
          },
          {
            Header: 'Created By',
            Cell: ({ value }) => (
              <Box maxW={[12, null, null, 20, null]}>{value}</Box>
            ),
            accessor: (originalRow) =>
              `${originalRow.user.firstName} ${originalRow.user.lastName}`,
            analyticsAttrIndex: 'createdBy',
          },
          {
            Cell: ({ value }) => `${formatDateForTable(value)}`,
            Header: 'Modified Date',
            accessor: (originalRow) => originalRow.accountsUpdatedAt,
            analyticsAttrIndex: 'modifiedDate',
          },
          {
            Cell: ({ value }) => `${formatDateForTable(value)}`,
            Header: 'Created Date',
            accessor: (originalRow) => originalRow.createdAt,
            analyticsAttrIndex: 'createdDate',
          },
          {
            Cell: ({ row, value }) => (
              <ButtonGroup>
                <Button
                  analyticsAttr={cleanAnalyticsStringList([
                    'refresh',
                    row.original.id,
                  ])}
                  onClick={() => applySelectedList(row.original.id)}
                  size={'sm'}
                  variant={'transparent'}
                  leftIcon={<SelectedCompaniesIcon boxSize={4} />}
                  text={`Companies (${value})`}
                  isDisabled={!value}
                  bg={'brand.cornflower'}
                  color={'white'}
                />
              </ButtonGroup>
            ),
            accessor: 'accountCount',
            Header: 'View',
          },
        ],
      },
    ],
    [getUserSettings.value],
  );
};
