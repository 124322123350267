import { Box, Button, FormLabel, Select } from '@chakra-ui/react';
import { SignalSources } from '../components/Home/types/homeTypes';
import React, { useState } from 'react';

export const signalSourceOptions = [
  {
    value: SignalSources.All,
    label: 'All',
  },
  {
    value: SignalSources.marketing,
    label: 'Marketing',
  },
  {
    value: SignalSources.sales,
    label: 'Sales',
  },
];

export const useSignalSourceButtons = (
  analyticsAttr: string,
  asSelect?: boolean,
) => {
  const [signalSource, setSignalSource] = useState<SignalSources>(
    SignalSources.All,
  );

  return {
    signalSourceComponent: asSelect ? (
      <Box>
        <FormLabel>SOURCE</FormLabel>
        <Select
          analytics-attr={`${analyticsAttr}-signal-source-select`}
          onChange={(e) => setSignalSource(e.target.value as SignalSources)}
          value={signalSource}
          color={'brand.black'}
        >
          {signalSourceOptions.map((item) => (
            <option
              analytics-attr={`${analyticsAttr}-signal-source-select-${item.label}`}
              style={{ color: 'brand.black' }}
              key={item.value}
              label={item.label}
              value={item.value}
            >
              {item.label}
            </option>
          ))}
        </Select>
      </Box>
    ) : (
      <>
        <Button
          analytics-attr={`${analyticsAttr}-all`}
          onClick={() => setSignalSource(SignalSources.All)}
          size={'sm'}
          variant={
            signalSource === SignalSources.All ? 'actionPurple' : 'transparent'
          }
        >
          All
        </Button>
        <Button
          analytics-attr={`${analyticsAttr}-marketing`}
          onClick={() => setSignalSource(SignalSources.marketing)}
          size={'sm'}
          variant={
            signalSource === SignalSources.marketing
              ? 'actionPurple'
              : 'transparent'
          }
        >
          Marketing
        </Button>
        <Button
          analytics-attr={`${analyticsAttr}-sales`}
          onClick={() => setSignalSource(SignalSources.sales)}
          size={'sm'}
          variant={
            signalSource === SignalSources.sales
              ? 'actionPurple'
              : 'transparent'
          }
        >
          Sales
        </Button>
      </>
    ),
    signalSource,
  };
};
