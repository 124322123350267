import classnames from 'classnames';
import {Logo as LogoIcon} from './Icon/logo';

type LogoType = {
  color?: 'full' | 'white' | 'gray-300' | 'gray-400' | 'gray-600';
};

const Logo = ({ color }: LogoType) => {
  // setup classes
  const logoClass = classnames({
    'c-logo': true,
    'c-logo--solid': color && color !== 'full',
    [`c-logo--${color}`]: color,
  });
  return (
    <div className={logoClass}>
      <LogoIcon color={'brand.gray-400'} boxSize={28} />
    </div>
  );
};

export default Logo;
