import {Box, Flex, Text} from '@chakra-ui/react';
import {GaugeLabelText} from 'app/src/components/GaugeChart/components/GaugeLabelText';
import {GaugeIcon} from './Icon/gauge';
import {Fragment} from 'react';

type BaseGaugeProps = {
  chartLabel: string;
  leftLabel: string;
  rightLabel: string;
  selectedSectionIndex: number;
  selectedSectionLabel: string;
  topLabel: string;
};

type OpacityScaleGaugeProps = BaseGaugeProps & {
  sectionColor: string;
  numberOfSections: number;
};

export const OpacityScaleGauge = ({
  numberOfSections,
  sectionColor,
  ...props
}: OpacityScaleGaugeProps) => {
  const sectionColors: string[] = [];
  let opacityPercentage = 1 / numberOfSections;

  const indexOfSectionColor = sectionColor.indexOf(')');
  const sliced = sectionColor.slice(4, indexOfSectionColor);

  for (let i = 1; i <= numberOfSections; i++) {
    sectionColors.push(`rgba(${sliced}, ${i * opacityPercentage})`);
  }

  return <ColorListGauge {...props} colorList={sectionColors} />;
};

type ColorListGaugeProps = BaseGaugeProps & {
  colorList: string[];
};

export const ColorListGauge = ({
  chartLabel,
  colorList,
  leftLabel,
  rightLabel,
  selectedSectionIndex,
  selectedSectionLabel,
  topLabel,
}: ColorListGaugeProps) => {
  const sectionTransformRotate = 0.5 / colorList.length;
  const transform = (index) =>
    colorList.length === 7
      ? `rotate(${180 + sectionTransformRotate * 2 * index * 180}deg) skew(${
          180 + 9.5 * colorList.length
        }deg)`
      : `rotate(${180 + sectionTransformRotate * 2 * index * 183}deg) skew(${
          182 + 9.5 * colorList.length
        }deg)`;

  return (
    <>
      <Box mb={2} textAlign={'center'}>
        <GaugeLabelText label={topLabel} />
      </Box>
      <Flex justify={'center'}>
        <Box mt={20}>
          <GaugeLabelText label={leftLabel} />
        </Box>
        <Box position={'relative'} display={'inline-block'}>
          <Box
            position={'relative'}
            w={'300px'}
            h={'300px'}
            bg={'transparent'}
            borderRadius={'50%'}
            overflow={'hidden'}
          >
            {colorList.map((color, index) => (
              <Fragment key={color}>
                <Box
                  position={'absolute'}
                  top={'50%'}
                  left={'50%'}
                  w={'100vw'}
                  h={'100vw'}
                  bg={selectedSectionIndex === index ? '#274A67' : color}
                  transformOrigin={'0 0'}
                  transform={transform(index)}
                />
              </Fragment>
            ))}
          </Box>
          <Box
            position={'absolute'}
            left={'50%'}
            top={'50%'}
            w={'120px'}
            h={'120px'}
            transform={'translateX(-50%) translateY(-50%)'}
            bg={'brand.white'}
            borderRadius={'50%'}
            zIndex={1}
          >
            <GaugeIcon
              boxSize={14}
              ml={8}
              mt={8}
              transform={
                colorList.length === 3
                  ? `rotate(${
                      0.7 -
                      colorList.length +
                      selectedSectionIndex * sectionTransformRotate
                    }turn)`
                  : `rotate(${
                      0.64 -
                      colorList.length +
                      selectedSectionIndex * sectionTransformRotate
                    }turn)`
              }
            />
            <Box>
              <Text
                position={'relative'}
                w={'250px'}
                ml={-16}
                color={'brand.gray-800'}
                fontSize={'lg'}
                fontWeight={'extrabold'}
                textAlign={'center'}
                textTransform={'capitalize'}
              >
                {selectedSectionLabel}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box mr={4} mt={20}>
          <GaugeLabelText label={rightLabel} />
        </Box>
      </Flex>
      <Text
        color={'brand.maroon'}
        fontSize={'xs'}
        fontWeight={'extrabold'}
        textTransform={'uppercase'}
        textAlign={'center'}
        mt={-22}
        ml={-6}
        mb={8}
      >
        {chartLabel}
      </Text>
    </>
  );
};
