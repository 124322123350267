import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { CheckboxSelectFilterItem } from './CheckboxSelectFilterItem';
import { useContext } from 'react';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import AccountsFilterItem from './AccountsFilterItem';
import { RangerFilterItem } from './rangerFilterItem/RangerFilterItem';
import {
  createAggregateMinMaxFromItems,
  createRangeMinMaxFromItems,
} from '../../helpers/rangerFilterHelpers';
import CalendarRangeFilterItem from './CalendarFilterItem';
import CheckboxFilterItem from './CheckboxFilterItem';
import RadioInputFilterItem from './RadioInputFilterItem';
import { GroupedCheckboxSelectFilterItem } from 'app/src/components/GlobalFilters/components/filterItems/GroupedCheckboxSelectFilterItem';
import { AggregateSearchFilterItem } from 'app/src/components/GlobalFilters/components/filterItems/AggregateSearchFilterItem';
import {
  FilterItemOption,
  FilterItemType,
} from 'shared/firebase/schemas/SavedStructure';

export const FilterItem = ({
  filterItem,
  itemSource,
}: {
  filterItem: FilterItemType;
  itemSource?: 'panel' | 'bar' | 'wideMenu';
}) => {
  const { fullAggregateData } = useContext(AggregateDataContext);

  const analyticsAttr = cleanAnalyticsStringList([filterItem.title, 'filter']);

  switch (filterItem.type) {
    case FilterItemOption.checkboxDropdown:
      return (
        <CheckboxSelectFilterItem
          analytics-attr={analyticsAttr}
          initialItems={
            (fullAggregateData?.aggregateData &&
              fullAggregateData.aggregateData[filterItem.field]) ??
            []
          }
          filterIndex={filterItem.field}
          itemSource={itemSource}
          title={filterItem.title}
          tableAccessor={'name'}
          width={
            itemSource === 'panel' || itemSource === 'wideMenu'
              ? '400px'
              : '200px'
          }
        />
      );
    case FilterItemOption.quickFiltersSelect:
      return (
        <CheckboxSelectFilterItem
          analytics-attr={analyticsAttr}
          initialItems={
            (fullAggregateData?.aggregateData &&
              fullAggregateData.aggregateData[filterItem.field]) ??
            []
          }
          filterIndex={filterItem.field}
          itemSource={'bar'}
          title={filterItem.title}
          tableAccessor={'name'}
        />
      );
    case FilterItemOption.groupedCheckboxDropdown:
      return (
        <GroupedCheckboxSelectFilterItem
          analytics-attr={analyticsAttr}
          aggregateData={fullAggregateData?.aggregateData}
          filterIndices={
            filterItem.additionalFields
              ? [filterItem.field, ...filterItem.additionalFields]
              : [filterItem.field]
          }
          itemSource={itemSource}
          title={filterItem.title}
          tableAccessor={'name'}
          width={
            itemSource === 'panel' || itemSource === 'wideMenu'
              ? '400px'
              : '200px'
          }
        />
      );
    case FilterItemOption.searchableDropdown:
      if (filterItem.field === 'accountIds') {
        return (
          <AccountsFilterItem itemType={itemSource ? itemSource : 'panel'} />
        );
      }
      return (
        <AggregateSearchFilterItem
          itemType={itemSource ? itemSource : 'panel'}
          filterIndex={filterItem.field}
        />
      );
    case FilterItemOption.ranger:
      return (
        <RangerFilterItem
          analyticsAttr={analyticsAttr}
          filterIndex={filterItem.field}
          initialFilterItem={
            fullAggregateData?.aggregateData
              ? createRangeMinMaxFromItems(
                  fullAggregateData.aggregateData[filterItem.field],
                  false,
                )
              : []
          }
          isDollars={false}
          isHundredPointScale={false}
          isPercentageScale={false}
          labelString={filterItem.title}
          labelStringTooltip={filterItem.tooltip ?? ''}
        />
      );
    case FilterItemOption.hundredPointScaleRanger:
      return (
        <RangerFilterItem
          analyticsAttr={analyticsAttr}
          filterIndex={filterItem.field}
          initialFilterItem={
            fullAggregateData?.aggregateData
              ? createAggregateMinMaxFromItems(
                  fullAggregateData.aggregateData[filterItem.field],
                )
              : []
          }
          isDollars={false}
          isHundredPointScale
          isPercentageScale={false}
          labelString={filterItem.title}
          labelStringTooltip={filterItem.tooltip ?? ''}
        />
      );
    case FilterItemOption.percentageScaleRanger:
      return (
        <RangerFilterItem
          analyticsAttr={analyticsAttr}
          filterIndex={filterItem.field}
          initialFilterItem={
            fullAggregateData?.aggregateData
              ? createAggregateMinMaxFromItems(
                  fullAggregateData.aggregateData[filterItem.field],
                )
              : []
          }
          isDollars={false}
          isHundredPointScale={false}
          isPercentageScale
          labelString={filterItem.title}
          labelStringTooltip={filterItem.tooltip ?? ''}
        />
      );
    case FilterItemOption.dollarRanger:
      return (
        <RangerFilterItem
          analyticsAttr={analyticsAttr}
          filterIndex={filterItem.field}
          initialFilterItem={
            fullAggregateData?.aggregateData
              ? createRangeMinMaxFromItems(
                  fullAggregateData.aggregateData[filterItem.field],
                  true,
                )
              : []
          }
          isDollars
          isHundredPointScale={false}
          isPercentageScale={false}
          labelString={filterItem.title}
          labelStringTooltip={filterItem.tooltip ?? ''}
        />
      );
    case FilterItemOption.calendar:
      return (
        <CalendarRangeFilterItem
          analytics-attr={analyticsAttr}
          filterIndex={filterItem.field}
          label={filterItem.title}
          itemSource={itemSource}
        />
      );
    case FilterItemOption.checkboxes:
      return (
        <CheckboxFilterItem
          analyticsAttr={analyticsAttr}
          initialItems={
            fullAggregateData?.aggregateData
              ? fullAggregateData.aggregateData[filterItem.field]
              : []
          }
          filterIndex={filterItem.field}
          label={filterItem.title}
          labelStringTooltip={filterItem.tooltip}
          tableAccessor={'name'}
        />
      );
    case FilterItemOption.radio:
      return (
        <RadioInputFilterItem
          analyticsAttr={analyticsAttr}
          defaultValue={'all'}
          initialItems={
            fullAggregateData?.aggregateData
              ? fullAggregateData.aggregateData[filterItem.field]
              : []
          }
          filterIndex={filterItem.field}
          tableAccessor={'name'}
        />
      );
    default:
      return null;
  }
};
