import { SignalDirections } from '../components/Home/types/homeTypes';
import React, { useState } from 'react';
import { Box, Button, FormLabel, Select } from '@chakra-ui/react';

export const signalDirectionOptions = [
  {
    value: SignalDirections.All,
    label: 'All',
  },
  {
    value: SignalDirections.Inbound,
    label: 'Inbound',
  },
  {
    value: SignalDirections.Outbound,
    label: 'Outbound',
  },
];

export const useSignalDirectionButtons = (
  analyticsAttr: string,
  asSelect?: boolean,
) => {
  const [signalDirection, setSignalDirection] = useState<SignalDirections>(
    SignalDirections.All,
  );

  return {
    signalDirectionComponent: asSelect ? (
      <Box>
        <FormLabel>DIRECTION</FormLabel>
        <Select
          analytics-attr={`${analyticsAttr}-signal-direction-select`}
          onChange={(e) =>
            setSignalDirection(e.target.value as SignalDirections)
          }
          value={signalDirection}
        >
          {signalDirectionOptions.map((item) => (
            <option
              analytics-attr={`${analyticsAttr}-signal-direction-select-${item.label}`}
              key={item.value}
              label={item.label}
              value={item.value}
            >
              {item.label}
            </option>
          ))}
        </Select>
      </Box>
    ) : (
      <>
        <Button
          analytics-attr={`${analyticsAttr}-signal-direction-all`}
          onClick={() => setSignalDirection(SignalDirections.All)}
          size={'sm'}
          variant={
            signalDirection === SignalDirections.All
              ? 'actionPurple'
              : 'transparent'
          }
        >
          All
        </Button>
        <Button
          analytics-attr={`${analyticsAttr}-signal-direction-marketing`}
          onClick={() => setSignalDirection(SignalDirections.Outbound)}
          size={'sm'}
          variant={
            signalDirection === SignalDirections.Outbound
              ? 'actionPurple'
              : 'transparent'
          }
        >
          Outbound
        </Button>
        <Button
          analytics-attr={`${analyticsAttr}-signal-direction-sales`}
          onClick={() => setSignalDirection(SignalDirections.Inbound)}
          size={'sm'}
          variant={
            signalDirection === SignalDirections.Inbound
              ? 'actionPurple'
              : 'transparent'
          }
        >
          Inbound
        </Button>
      </>
    ),
    signalDirection,
  };
};
