import { ChangeEvent, CSSProperties, ReactNode, useState } from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import _filter from 'lodash/filter';
import _size from 'lodash/size';

import {
  addOptionComponentDataAcceptance,
  cleanAnalyticsStringList,
} from 'app/src/helpers/analyticsHelpers';

import { Tooltip, Txt } from './index';
import {
  AnalyticsAttrType,
  ClassNameType,
  OptionsType,
} from 'shared/types/coreTypes.d';
import { zIndexValues } from '../../constants/zIndexValues';
import Icon from './Icon';

export type OnChangeSelectChangeEventType = ChangeEvent<HTMLSelectElement>;

type CustomSelectType = AnalyticsAttrType &
  ClassNameType & {
    defaultValue?: number | string | boolean | null | Array<string | object>;
    disabled?: boolean;
    displayPlaceholder?: boolean;
    error?: string;
    extraFieldSetStyles?: CSSProperties;
    id?: string;
    isFilterBar?: boolean;
    isMultiSelect?: boolean;
    label?: string;
    labelTooltip?: string;
    onChange?: (
      options: OnChangeSelectChangeEventType | Array<OptionsType>,
    ) => void;
    options?: Array<OptionsType>;
    placeholder?: string;
    required?: boolean;
    success?: string;
    value?: string;
  };

const CustomSelect = ({
  analyticsAttr = undefined,
  className = undefined,
  defaultValue,
  disabled,
  displayPlaceholder = true,
  error,
  extraFieldSetStyles = {},
  id = 'select',
  isFilterBar = false,
  isMultiSelect = false,
  label,
  labelTooltip,
  onChange,
  options,
  placeholder,
  required = false,
  success,
  value,
}: CustomSelectType) => {
  // setup state
  const defaultSelectedOptions = _filter(options, (option) =>
    isMultiSelect ? value!.includes(option.value) : option.value === value,
  );
  const [selectedOptions, setSelectedOptions] = useState<OptionsType[] | []>(
    defaultSelectedOptions,
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // setup classes
  const fieldClass = classnames({
    'c-text-field': true,
    'c-text-field--disabled': disabled && !isMultiSelect,
    'c-text-field--error': !!error,
    'c-text-field--success': !!success,
    ...(typeof className === 'string' ? { [className]: className } : {}),
  });
  // setup consts
  let children: Array<ReactNode> = [];
  if (options && !isMultiSelect && !isFilterBar) {
    if (displayPlaceholder) {
      children.push(
        <option key={''} value={''}>
          {placeholder}
        </option>,
      );
    }
    children.push(
      ...options.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      )),
    );
  }
  const selectAnalyticsAttr = cleanAnalyticsStringList([
    `analytics attr`,
    analyticsAttr,
    'select-control',
  ]);
  const selectClassNames = classnames({
    'c-text-field__input': true,
    [selectAnalyticsAttr]: _size(selectAnalyticsAttr) > 0,
  });

  return (
    <fieldset
      className={fieldClass}
      disabled={disabled}
      style={{ ...extraFieldSetStyles }}
    >
      <label className={'c-text-field__wrapper'} htmlFor={id}>
        <div className={'c-text-field__label'}>
          {labelTooltip && labelTooltip.length > 0 ? (
            <Tooltip
              analyticsAttr={cleanAnalyticsStringList([
                analyticsAttr,
                'tooltip',
              ])}
              content={<Txt>{labelTooltip}</Txt>}
            >
              <Txt size={'md'}>{label}</Txt>
            </Tooltip>
          ) : (
            <Txt size={'md'}>{label}</Txt>
          )}
        </div>
        <div className={'c-text-field__input-block'}>
          {disabled && <div className={'h-disabled-overlay'} />}
          {options && (isMultiSelect || isFilterBar) ? (
            <div className={'h-relative'}>
              {/* @ts-ignore */}
              <Select
                backspaceRemovesValue={false}
                blurInputOnSelect={false}
                className={selectClassNames}
                closeMenuOnSelect={false}
                components={{
                  Option: addOptionComponentDataAcceptance(
                    `${analyticsAttr}-select-option`,
                  ),
                }}
                defaultValue={defaultValue}
                id={id}
                isClearable={true}
                isDisabled={disabled}
                isMulti={isMultiSelect}
                menuIsOpen={isMenuOpen}
                name={id}
                onChange={(selectedOptions, { action }) => {
                  setSelectedOptions(selectedOptions as OptionsType[]);
                  if (action === 'remove-value' || action === 'clear') {
                    onChange!(selectedOptions as OptionsType[]);
                  }
                }}
                onInputChange={(string, { action }) => {
                  if (action === 'menu-close' && _size(selectedOptions) > 0) {
                    onChange!(selectedOptions);
                  }
                }}
                onMenuClose={() => setIsMenuOpen(false)}
                onMenuOpen={() => setIsMenuOpen(true)}
                options={options}
                placeholder={placeholder}
                required
                styles={{
                  container: (styles) => ({ ...styles, padding: 0 }),
                  control: (styles) => ({
                    ...styles,
                    borderWidth: 0,
                    cursor: 'pointer',
                  }),
                  menu: (styles) => ({
                    ...styles,
                    zIndex: zIndexValues.selectDefault,
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    flexWrap: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }),
                }}
                value={selectedOptions}
              />
              {isMenuOpen && (
                <div
                  className={'react-select-blanket'}
                  onClick={() => setIsMenuOpen(false)}
                />
              )}
            </div>
          ) : options && !isMultiSelect ? (
            <select
              analytics-attr={cleanAnalyticsStringList([
                analyticsAttr,
                'select',
              ])}
              className={'c-text-field__input h-pointer'}
              id={id}
              name={id}
              onChange={onChange}
              required={required}
              style={{ zIndex: 2, backgroundColor: 'transparent' }}
              value={value}
            >
              {children}
            </select>
          ) : null}
          {!isMultiSelect && !isFilterBar && (
            <span className={'c-text-field__icon'} style={{ zIndex: 1 }}>
              <Icon name={'if-tri-down'} />
            </span>
          )}
        </div>
      </label>
      {(error || success) && (
        <div className={'c-text-field__message'}>
          {error ? (
            <div className={'c-text h-text-size-2xs'}>
              <b>Error!</b>
              {error}
            </div>
          ) : success ? (
            <div className={'c-text h-text-size-2xs'}>
              <b>Success!</b>
              {success}
            </div>
          ) : null}
        </div>
      )}
    </fieldset>
  );
};

export default CustomSelect;
