import {createIcon} from '@chakra-ui/icon';

export const NoRecentSaleIcon = createIcon({
  displayName: 'NoRecentSale',
  viewBox: '0 0 21 20',
  path: (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M16.167 5.8335C15.8337 5.8335 15.5837 5.91683 15.3337 5.91683V5.8335C15.3337 3.0835 13.0837 0.833496 10.3337 0.833496C8.41699 0.833496 6.83366 1.8335 6.00033 3.41683C5.75033 3.3335 5.58366 3.3335 5.33366 3.3335C3.00033 3.3335 1.16699 5.16683 1.16699 7.50016C1.16699 9.5835 2.66699 11.2502 4.66699 11.5835C5.16699 14.0002 7.33366 15.8335 9.91699 15.8335C12.2503 15.8335 14.2503 14.3335 15.0003 12.2502C15.3337 12.4168 15.7503 12.5002 16.167 12.5002C18.0003 12.5002 19.5003 11.0002 19.5003 9.16683C19.5003 7.3335 18.0003 5.8335 16.167 5.8335Z'
          stroke='#152F45'
          fill={'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.83366 19.1668C3.75413 19.1668 4.50033 18.4206 4.50033 17.5002C4.50033 16.5797 3.75413 15.8335 2.83366 15.8335C1.91318 15.8335 1.16699 16.5797 1.16699 17.5002C1.16699 18.4206 1.91318 19.1668 2.83366 19.1668Z'
          stroke='#152F45'
          fill={'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.66699 5.8335H11.167L8.66699 10.0002H12.0003'
          stroke='#152F45'
          fill={'white'}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_761:2790'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0.333496)'
          />
        </clipPath>
      </defs>
    </svg>
  ),
});
