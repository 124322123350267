import { SingleAccountQuickStat } from './singleAccountQuickStat/SingleAccountQuickStat';
import { useFetchLabCardData } from 'app/src/hooks/useFetchLabCardData';
import { useParams } from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import { SingleAccountQuickStatWrapper } from './singleAccountQuickStat/SingleAccountQuickStatWrapper';
import { useMemo } from 'react';

export type ConfiguredSingleAccountQuickStatProps = {
  configurationId: string;
};

export const ConfiguredSingleAccountQuickStat = ({
  configurationId,
}: ConfiguredSingleAccountQuickStatProps) => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useFetchLabCardData(configurationId, {
    accountId: parseInt(id),
  });

  const leftText = useMemo(() => {
    if (data?.hero?.value !== null && data?.hero?.value !== undefined) {
      return data.hero.displayType === 'integer'
        ? `${data.hero.value.toLocaleString()} total`
        : `${data.hero.value} total`;
    }
    return '';
  }, [data?.hero]);

  return !!error ? (
    <SingleAccountQuickStatWrapper>
      <Text color={'brand.crimson'}>
        Error retrieving this data: refresh to reload
      </Text>
    </SingleAccountQuickStatWrapper>
  ) : (
    <SingleAccountQuickStat
      title={!!data?.columns?.length ? data?.columns[0].label ?? '' : ''}
      center={{
        leftText,
      }}
      bottom={{
        leftText: `${data?.hero?.context ? data.hero.context : ''}`,
      }}
      externalQueryLoading={loading}
    />
  );
};
