import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import _size from 'lodash/size';

import { CREATE_GOOGLE_SECRET_VERSION } from 'shared/graphql/gqlMutations';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';

import {
  Button,
  Container,
  Input,
  Select,
  Txt,
} from '../../../../shared/components/Core';
import { Stack } from '@chakra-ui/react';
import { isEmailValid } from 'shared/helpers/validationHelpers';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';

type CRMCredentialsCredentialsFormType = {
  companyName: string;
  contactPersonEmail: string;
  contactPersonName: string;
  crmType: string;
  email: string;
  marketoClientId?: string;
  marketoClientSecret?: string;
  marketoEndpointBaseURL?: string;
  password: string;
};

const CRMCredentialsPage = () => {
  useEffect(() => {
    updateAppHeaders('CRM Credentials');
  }, []);
  return (
    <Container ph={'xs'} pv={'4xl'} width={'sm'}>
      <Stack spacing={8}>
        <Txt align={'center'} size={'3xl'} weight={'heavy'}>
          {'Enter CRM Credentials'}
        </Txt>
        <Txt align={'center'} size={'md'} theme={'muted'}>
          {
            'We will securely store your CRM Credentials so we can sync the requisite data from your system to Klearly.'
          }
        </Txt>
      </Stack>
      <FormBase />
    </Container>
  );
};

const FormBase = () => {
  // setup base consts
  const SALESFORCE_VALUE = 'SALESFORCE';
  const HUBSPOT_VALUE = 'HUBSPOT';
  const MARKETO_VALUE = 'MARKETO';
  const successMessageText: string =
    'Credentials saved successfully. A Klearly administrator will be in touch if we run into any issues and/or with next steps.';
  const crmEmailLabel: string = 'CRM API Username';
  const crmPasswordLabel: string = 'CRM API User Password';
  // get param values
  const { company_name }: { company_name: string } = useParams();
  // setup state and input hooks
  const [successMessage, setSuccessMessage] = useState<string>();
  const {
    bind: bindEmail,
    reset: resetEmail,
    value: email,
  } = useInput('', {
    id: 'email',
    label: crmEmailLabel,
    name: 'email',
    placeholder: 'crmuser@baldersystems.com',
    type: 'email',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindPassword,
    reset: resetPassword,
    value: password,
  } = useInput('', {
    id: 'password',
    label: crmPasswordLabel,
    name: 'password',
    placeholder: 'ex. ••••••••••',
    type: 'password',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindContactPersonName,
    reset: resetContactPersonName,
    value: contactPersonName,
  } = useInput('', {
    id: 'contact-person-name',
    label: 'Your Name',
    name: 'contact-person-name',
    placeholder: 'John Doe',
    required: true,
    type: 'text',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindContactPersonEmail,
    reset: resetContactPersonEmail,
    value: contactPersonEmail,
  } = useInput('', {
    id: 'contact-person-email',
    label: 'Your Email',
    name: 'contact-person-email',
    placeholder: 'jdoe@baldersystems.com',
    required: true,
    type: 'email',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const { bind: bindCompanyName } = useInput(company_name, {
    disabled: true,
    id: 'company-name',
    label: 'Your Company',
    name: 'company-name',
    required: true,
    type: 'text',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindCRMType,
    reset: resetCRMType,
    value: crmType,
  } = useInput('', {
    defaultValue: '',
    label: 'CRM Type',
    options: [
      { label: 'Salesforce', value: SALESFORCE_VALUE },
      { label: 'HubSpot', value: HUBSPOT_VALUE },
      { label: 'Marketo', value: MARKETO_VALUE },
    ],
    placeholder: 'Select CRM Type',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindEndpointBaseURL,
    reset: resetEndpointBaseURL,
    value: endpointBaseURL,
  } = useInput('', {
    id: 'end-point-base-url',
    label: 'Endpoint Base URL',
    name: 'end-point-base-url',
    placeholder:
      'Marketo REST Endpoint URL (ex. https://xyz.mktorest.com/rest)',
    required: crmType === MARKETO_VALUE,
    type: 'text',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindClientId,
    reset: resetClientId,
    value: clientId,
  } = useInput('', {
    id: 'client-id',
    label: 'Client ID',
    name: 'client-id',
    placeholder: 'Marketo Client ID',
    required: crmType === MARKETO_VALUE,
    type: 'text',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindClientSecret,
    reset: resetClientSecret,
    value: clientSecret,
  } = useInput('', {
    id: 'client-secret',
    label: 'Client Secret',
    name: 'client-secret',
    placeholder: 'Marketo Client Secret',
    required: crmType === MARKETO_VALUE,
    type: 'password',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  // setup mutation queries
  const [
    addGoogleSecret,
    { called: createGoogleSecretCalled, error, loading },
  ] = useMutation(CREATE_GOOGLE_SECRET_VERSION);
  // setup consts
  const baseIsSubmitButtonDisabled =
    _size(contactPersonName) === 0 ||
    !isEmailValid(contactPersonEmail) ||
    _size(company_name) === 0 ||
    _size(crmType) === 0;
  const crmTypeIsSubmitButtonDisabled =
    crmType === MARKETO_VALUE
      ? _size(endpointBaseURL) === 0 ||
        _size(clientId) === 0 ||
        _size(clientSecret) === 0
      : !isEmailValid(email) || _size(password) === 0;
  const isSubmitButtonDisabled =
    baseIsSubmitButtonDisabled || crmTypeIsSubmitButtonDisabled;
  // setup funtions
  const _onSubmit = (event) => {
    event.preventDefault();
    setSuccessMessage(undefined);
    const credentialsForm: CRMCredentialsCredentialsFormType = {
      companyName: company_name,
      contactPersonEmail,
      contactPersonName,
      crmType,
      email,
      password,
    };
    if (crmType === MARKETO_VALUE) {
      credentialsForm.marketoClientId = clientId;
      credentialsForm.marketoClientSecret = clientSecret;
      credentialsForm.marketoEndpointBaseURL = endpointBaseURL;
    }
    addGoogleSecret({ variables: { googleSecret: credentialsForm } });
  };
  // clean variables, if mutation is successful
  if (createGoogleSecretCalled && !loading && !successMessage) {
    resetEmail();
    resetPassword();
    resetContactPersonName();
    resetContactPersonEmail();
    resetCRMType();
    resetEndpointBaseURL();
    resetClientId();
    resetClientSecret();
    setSuccessMessage(successMessageText);
  }
  // render
  if (successMessage) {
    return <p>{successMessage}</p>;
  }
  return (
    <form onSubmit={_onSubmit} className={'c-form h-pv-lg'}>
      <Stack spacing={4}>
        <>
          <Input {...bindContactPersonName} />
          <Input {...bindContactPersonEmail} />
          <Input {...bindCompanyName} />
          {/* @ts-ignore */}
          <Select {...bindCRMType} />
          {crmType === MARKETO_VALUE ? (
            <>
              <Input {...bindEndpointBaseURL} />
              <Input {...bindClientId} />
              <Input {...bindClientSecret} />
            </>
          ) : (
            <>
              <Input {...bindEmail} required={crmType !== MARKETO_VALUE} />
              <Input {...bindPassword} required={crmType !== MARKETO_VALUE} />
            </>
          )}
        </>
        {error?.message && (
          <>
            <Txt theme={'active'}>{error.message}</Txt>
            <br />
          </>
        )}
        <Button
          isDisabled={isSubmitButtonDisabled}
          isLoading={loading}
          type={'submit'}
          text={'Submit'}
        />
      </Stack>
    </form>
  );
};

export default CRMCredentialsPage;
