import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {};

const sizes = {};

const variants = {
  standard: {},
  redIcon: {
    backgroundColor: 'red.500',
    borderRadius: '50%',
    color: 'white',
    fontSize: '2xs',
    left: '-2px',
    position: 'absolute',
    py: 'md',
    top: '-2px',
  },
};

const defaultProps = {
  variant: 'redIcon',
};

export const Badge = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
