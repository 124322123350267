import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { Avatar } from 'shared/components/Core';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { getAvatarName } from 'app/src/components/Navigation/helpers/getAvatarName';
import { NavigationProps } from 'app/src/components/Navigation/components/NavigationBar';
import Honeybadger from '@honeybadger-io/js';
import { useApolloClient } from '@apollo/client';
import AuthManager from 'shared/firebase/classes/AuthManager';


export const NavigationMenu = ({
  onOpenUpdateCompanyModal,
}: Omit<NavigationProps, 'children'>) => {
  const klearlyUser = AuthManager.klearlyUser;
  const apolloClient = useApolloClient();

  const signOut = () => {
    AuthManager.signOut().then(() => {
      // clear apollo store
      apolloClient.resetStore();
      // clear Honeybadger context
      Honeybadger.clear();
    });
  };

  if (!klearlyUser) {
    return null;
  }
  const avatarName = getAvatarName(klearlyUser);

  return (
    <Menu>
      {({}) => (
        <>
          <MenuButton>
            <Flex align={'center'}>
              <Avatar ml={4} text={avatarName} />
              <ChevronDownIcon ml={2} fontSize={20} />
            </Flex>
          </MenuButton>
          <MenuList pt={4} pr={4}>
            <Flex align={'center'}>
              <Avatar ml={4} text={avatarName} />
              <Box ml={2}>
                <Text
                  fontWeight={'bold'}
                >{`${klearlyUser?.firstName} ${klearlyUser?.lastName} `}</Text>
                <Text>{klearlyUser?.email}</Text>
              </Box>
            </Flex>
            {klearlyUser?.isKlearlyAdmin && (
              <>
                <Text ml={4} mt={2} fontWeight={'bold'}>
                  Currently viewing:{' '}
                  <Text fontWeight={'normal'} ml={2}>
                    {klearlyUser.companyName}
                  </Text>
                </Text>
                <MenuItem
                  analytics-attr={'sign-out-nav-btn'}
                  bg={'#F5F8F9'}
                  borderRadius={8}
                  color={'brand.black'}
                  _hover={{ bg: 'brand.crimson', color: 'brand.white' }}
                  m={4}
                  maxW={48}
                  onClick={onOpenUpdateCompanyModal}
                >
                  <Text fontSize={'lg'} textAlign={'center'}>
                    Change Company
                  </Text>
                </MenuItem>             
              </>
            )}
             
            <MenuItem
              analytics-attr={'sign-out-nav-btn'}
              bg={'#F5F8F9'}
              borderRadius={8}
              color={'brand.black'}
              _hover={{ bg: 'brand.crimson', color: 'brand.white' }}
              m={4}
              maxW={24}
              onClick={signOut}
            >
              <Text fontSize={'lg'} textAlign={'center'}>
                Sign Out
              </Text>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
