import {
  FilterIndices,
  PREDICTION_NAME_LEVEL_FILTER_ITEM,
} from 'app/src/components/GlobalFilters/types/filterTypes';
import { ApiGetAggregateDataWithFilterVarQuery } from 'shared/graphql/generatedApiTypes';
import {
  AllPredictionRenderJSON,
  PredictionAcquisitionIconJSON,
  PredictionFitIconJSON,
  PredictionRetentionIconJSON,
} from '../../TableSection/renderers/PredictionIconRender';

export enum VolumePredictionLevel {
  AboutTheSame = 'ABOUT_THE_SAME',
  Higher = 'HIGHER',
  Lower = 'LOWER',
  MuchHigher = 'MUCH_HIGHER',
  MuchLower = 'MUCH_LOWER',
  SlightlyHigher = 'SLIGHTLY_HIGHER',
  SlightlyLower = 'SLIGHTLY_LOWER',
}

export enum RetentionPredictionLevel {
  LeaningLikely = 'LEANING_LIKELY',
  LeaningUnlikely = 'LEANING_UNLIKELY',
  Likely = 'LIKELY',
  Tossup = 'TOSSUP',
  Unlikely = 'UNLIKELY',
  VeryLikely = 'VERY_LIKELY',
  VeryUnlikely = 'VERY_UNLIKELY',
}

export enum AcquisitionPredictionLevel {
  ModerateBet = 'MODERATE_BET',
  StrongBet = 'STRONG_BET',
  WeakBet = 'WEAK_BET',
}

export const volumeBins = {
  1: VolumePredictionLevel.MuchLower,
  2: VolumePredictionLevel.Lower,
  3: VolumePredictionLevel.SlightlyLower,
  4: VolumePredictionLevel.AboutTheSame,
  5: VolumePredictionLevel.SlightlyHigher,
  6: VolumePredictionLevel.Higher,
  7: VolumePredictionLevel.MuchHigher,
};

export const retentionBins = {
  1: RetentionPredictionLevel.VeryUnlikely,
  2: RetentionPredictionLevel.Unlikely,
  3: RetentionPredictionLevel.LeaningUnlikely,
  4: RetentionPredictionLevel.Tossup,
  5: RetentionPredictionLevel.LeaningLikely,
  6: RetentionPredictionLevel.Likely,
  7: RetentionPredictionLevel.VeryLikely,
};

export const acquisitionBins = {
  1: AcquisitionPredictionLevel.WeakBet,
  2: AcquisitionPredictionLevel.ModerateBet,
  3: AcquisitionPredictionLevel.StrongBet,
};

export const adaptPredictionTypeLevelToFilterIndices = (
  initialData: NonNullable<
    ApiGetAggregateDataWithFilterVarQuery['aggregateData']
  >,
) => {
  const predictionLevelArray = [
    {
      matchingName: 'volume',
      bins: volumeBins,
      filterIndex: FilterIndices.PREDICTED_XEOMIN_PURCHASE_QUANTITY,
    },
    {
      matchingName: 'acquisition',
      bins: acquisitionBins,
      filterIndex: FilterIndices.PREDICTED_XEOMIN_PURCHASE_PROPENSITY,
    },
    {
      matchingName: 'retention',
      bins: retentionBins,
      filterIndex: FilterIndices.PREDICTED_XEOMIN_REORDER_PROPENSITY,
    },
  ];
  predictionLevelArray.forEach((levelType) => {
    const filteredPredictionLevelData =
      initialData?.predictionTypeLevel?.filter((item) =>
        item.name.includes(levelType.matchingName),
      ) || [];
    initialData[levelType.filterIndex] = Object.keys(levelType.bins).map(
      (binKey) => ({
        count:
          filteredPredictionLevelData.find(
            (item) => item.name.split(':')[1] === binKey,
          )?.count ?? 0,
        name: levelType.bins[binKey],
      }),
    );
  });
  return initialData;
};

export const predictionTooltip = {
  Acquisition:
    'The relative likelihood a prospect or past customer will become a customer of a given product in the next 90 days compared to other prospects and past customers.',
  Retention:
    'The relative likelihood a current customer will remain a customer of a given product in the next 90 days compared to other current customers of that product.',
};

export const PredictionTootipLegend = {
  Acquisition: [
    {
      label: 'Least',
      color: '#8454d218',
      level: 0,
      icon: PredictionAcquisitionIconJSON[0],
    },
    {
      label: 'Less',
      color: '#8454d24a',
      level: 1,
      icon: PredictionAcquisitionIconJSON[1],
    },
    {
      label: 'Typical',
      color: '#8454d282',
      level: 2,
      icon: PredictionAcquisitionIconJSON[2],
    },
    {
      label: 'More',
      color: '#8454d2c9',
      level: 3,
      icon: PredictionAcquisitionIconJSON[3],
    },
    {
      label: 'Most',
      color: '#8454d2ff',
      level: 4,
      icon: PredictionAcquisitionIconJSON[4],
    },
  ],
  Fit: [
    {
      label: 'Very Weak',
      color: '#2a96fc18',
      level: 0,
      icon: PredictionFitIconJSON[0],
    },
    {
      label: 'Weak',
      color: '#2a96fc4a',
      level: 1,
      icon: PredictionFitIconJSON[1],
    },
    {
      label: 'Moderate',
      color: '#2a96fc82',
      level: 2,
      icon: PredictionFitIconJSON[2],
    },
    {
      label: 'Strong',
      color: '#2a96fcc9',
      level: 3,
      icon: PredictionFitIconJSON[3],
    },
    {
      label: 'Very Strong',
      color: '#2a96fcff',
      level: 4,
      icon: PredictionFitIconJSON[4],
    },
  ],
  Retention: [
    {
      label: 'Least',
      color: '#2a96fc18',
      level: 0,
      icon: PredictionRetentionIconJSON[0],
    },
    {
      label: 'Less',
      color: '#2a96fc4a',
      level: 1,
      icon: PredictionRetentionIconJSON[1],
    },
    {
      label: 'Typical',
      color: '#2a96fc82',
      level: 2,
      icon: PredictionRetentionIconJSON[2],
    },
    {
      label: 'More',
      color: '#2a96fcc9',
      level: 3,
      icon: PredictionRetentionIconJSON[3],
    },
    {
      label: 'Most',
      color: '#2a96fcff',
      level: 4,
      icon: PredictionRetentionIconJSON[4],
    },
  ],
};

export const LikelihoodBox = (item: any, predictionType) => {
  let data = PredictionTootipLegend[item?.type].filter(
    (el) => el.level === item.level,
  )[0];
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <span>{AllPredictionRenderJSON[predictionType]?.[item.level]}</span>
      <span
        style={{
          margin: '3px',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '14px',
          fontWeight: '700',
          color: '#323F5A',
        }}
      >
        {PREDICTION_NAME_LEVEL_FILTER_ITEM[item.level]}
      </span>
    </div>
  );
};

export const reguralityMapping = (value) => {
  if (value < 0.3) {
    return 'High';
  } else if (0.3 < value && value < 0.7) {
    return 'Medium';
  } else if (0.7 > value && value < 1) {
    return 'Low';
  } else {
    return 'None';
  }
};
