import { useColorMode } from '@chakra-ui/react';
import { getTokenVar } from 'app/src/apollo/rootReactiveVariables';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { NavigationBar } from '../../Navigation/components/NavigationBar';
import LogInPage from '../../LogIn';
import * as ROUTES from '../../../constants/routes';
import PasswordForgotPage from '../../PasswordForgot';
import PasswordResetPage from '../../PasswordReset';
import CRMCredentialsPage from '../../CRMCredentials';
import AggregateDataContextProvider from '../../../context/AggregateDataContext';
import { Loading } from '../../../../../shared/components/Core';
import { AppType } from '../types/appTypes';
import { ProtectedRoute } from './ProtectedRoute';
import { RootPage } from '../../RootPage/RootPage';
import { AdminPage } from '../../Admin';
import { AppNavigationalStructureContextProvider } from 'app/src/context/AppNavigationalStructureContext';
import { IntegrationRequestPage } from 'app/src/components/Integrations/components/IntegrationRequestPage';
import { IntegrationSuccessPage } from 'app/src/components/Integrations/components/IntegrationSuccessPage';
import { MarketoIntegrationPage } from 'app/src/components/Integrations/components/MarketoIntegrationPage';
import { UnauthedNavigationBar } from 'app/src/components/Navigation/components/UnauthedNavigationBar';
import { buildPath } from 'shared/helpers/navigationHelpers';
import { homePageKey } from 'shared/constants/homePageKey';
import { sortPagesByParam } from 'app/src/components/App/helpers/sortPagesByParam';
import { PendoIntegrationPage } from 'app/src/components/Integrations/components/PendoIntegrationPage';
import { PardotIntegrationPage } from 'app/src/components/Integrations/components/PardotIntegrationPage';
import { useToken } from 'shared/hooks/useToken';
import AuthManager from 'shared/firebase/classes/AuthManager';
import SettingSection from 'app/src/components/Admin/SettingSection';

export const App = ({
  isAuthenticated,
  user,
  structureFromLoading,
}: Partial<AppType>) => {
  document.body.style.backgroundColor = 'white';
  const showAdminOrSuperUserRoute: boolean =
    !!user?.isAdmin || !!user?.isKlearlyAdmin;
  const { setColorMode } = useColorMode();
  setColorMode('light');

  useToken(getTokenVar, AuthManager.currentUser);

  return (
    <Router>
      <div>
        {!isAuthenticated ? (
          <UnauthedNavigationBar>
            <Switch>
              <Route component={LogInPage} exact path={ROUTES.LOG_IN} />
              <Route
                component={PasswordForgotPage}
                exact
                path={ROUTES.PASSWORD_FORGOT}
              />
              <Route
                component={PasswordResetPage}
                exact
                path={ROUTES.PASSWORD_RESET}
              />
              <Route
                component={CRMCredentialsPage}
                exact
                path={ROUTES.CRM_CREDENTIALS_PAGE.path}
              />
              <Route
                component={IntegrationRequestPage}
                exact
                path={ROUTES.INTEGRATIONS_PAGE.path}
              />
              {/* <Route
                component={IntegrationSuccessPage}
                exact
                path={ROUTES.INTEGRATION_SUCCESS_PAGE.path}
              /> */}
              <Route
                component={MarketoIntegrationPage}
                exact
                path={ROUTES.MARKETO_INTEGRATION_PAGE.path}
              />
              <Route
                component={PardotIntegrationPage}
                exact
                path={ROUTES.PARDOT_INTEGRATION_PAGE.path}
              />
              <Route
                component={PendoIntegrationPage}
                exact
                path={ROUTES.PENDO_INTEGRATION_PAGE.path}
              />
              <Route render={() => <Redirect to={ROUTES.LOG_IN} />} />
            </Switch>
          </UnauthedNavigationBar>
        ) : structureFromLoading ? (
          <AggregateDataContextProvider structure={structureFromLoading}>
            <AppNavigationalStructureContextProvider
              topLevelStructure={structureFromLoading}
            >
              <NavigationBar>
                <Switch>
                  <Route
                    component={CRMCredentialsPage}
                    exact
                    path={ROUTES.CRM_CREDENTIALS_PAGE.path}
                  />
                  <Route
                    component={IntegrationSuccessPage}
                    exact
                    path={ROUTES.INTEGRATION_SUCCESS_PAGE.path}
                  />

                  {showAdminOrSuperUserRoute && (
                    <ProtectedRoute
                      render={() => <AdminPage />}
                      exact
                      path={ROUTES.ADMIN}
                    />
                  )}
                  {structureFromLoading?.pages
                    ?.sort(sortPagesByParam)
                    .map((page) => (
                      <ProtectedRoute
                        key={page.key}
                        exact
                        path={buildPath(page.navStructure)}
                        render={() => (
                          <RootPage pageKey={page.key} key={page.key} />
                        )}
                      />
                    ))}
                  <Route
                    render={() => (
                      <Redirect
                        to={
                          structureFromLoading?.pages?.find(
                            (page) => page.key === homePageKey,
                          )!.navStructure.urlPaths[0].path
                        }
                      />
                    )}
                  />
                </Switch>
              </NavigationBar>
            </AppNavigationalStructureContextProvider>
            )
          </AggregateDataContextProvider>
        ) : (
          <Loading
            dotColor={'color'}
            w={'100vw'}
            h={'100vh'}
            text={'Loading...'}
          />
        )}
      </div>
    </Router>
  );
};
