import { EngagementStatusItemType } from '../types/homeTypes';
import { EngagementStatusProps } from 'app/src/components/Home/components/EngagementStatus';

const convertEngagementStatusNameToLabel = (name: string) => {
  switch (name) {
    case 'unengaged':
      return 'not_engaged';
    case 'engaged':
      return 'recently_engaged';
    case 'disengaging':
      return 'previously_engaged';
    case 'disengaged':
      return 'previously_engaged';
    default:
      return '';
  }
};

export const mapEngagementStatusDataToChart = (
  aggregateAccountsQuery: any | undefined,
  tooltips: Omit<
    EngagementStatusProps,
    'sectionHeaderTooltip' | 'sectionHeaderSubtext' | 'sectionHeaderText'
  >,
): EngagementStatusItemType[] => {
  if (
    !aggregateAccountsQuery ||
    !aggregateAccountsQuery.aggregateData ||
    !aggregateAccountsQuery.aggregateData.engagementStatus?.length
  ) {
    return [];
  }

  // build the initial array to handle the sort
  const sortedEngagementStatusArr = [
    {
      label: 'recently_engaged',
      x: 'engaged',
      y: 0,
      maxYValue: 0,
      tooltip: tooltips.engagedTooltip,
    },
    {
      label: 'previously_engaged',
      x: 'disengaging',
      y: 0,
      maxYValue: 0,
      tooltip: tooltips.disengagingTooltip,
    },
    {
      label: 'previously_engaged',
      x: 'disengaged',
      y: 0,
      maxYValue: 0,
      tooltip: tooltips.disengagedTooltip,
    },
    {
      label: 'not_engaged',
      x: 'unengaged',
      y: 0,
      maxYValue: 0,
      tooltip: tooltips.unengagedTooltip,
    },
  ];

  aggregateAccountsQuery.aggregateData.engagementStatus.forEach((item) => {
    if (!item) return;
    // can now cast because we did a null check above
    const { name, count } = item as { name: string; count: number };
    const correctedItem = {
      label: convertEngagementStatusNameToLabel(name),
      x: name,
      y: count,
      maxYValue: count,
      tooltip: tooltips[`${name}Tooltip`],
    };
    const indexToInsert = sortedEngagementStatusArr.findIndex(
      (item) => item.x === correctedItem.x,
    );
    sortedEngagementStatusArr[indexToInsert] = correctedItem;
  });
  return sortedEngagementStatusArr;
};
