import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

export const AccountHeaderError = ({ error }: { error: string }) => {
  const toast = useToast();
  useEffect(() => {
    toast({
      title: error,
      status: 'error',
      position: 'top',
    });
  }, [error]);

  return <Redirect push to={'/account'} />;
};
