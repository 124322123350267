import { Inline, Txt } from '../../../../../shared/components/Core';
import _size from 'lodash/size';
import { LabCardLikeFavoriteIcons, LabCardShareIcons } from './LabCardFeedback';
import { RefObject, useEffect, useMemo } from 'react';
import { styleNumber } from 'app/src/components/Lab/helpers/styleNumber';
import AuthManager from 'shared/firebase/classes/AuthManager';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { useAsync } from 'app/src/hooks/useAsync';
import { FieldValue } from 'shared/firebase/initializeFirebase';

type LabCardPageHeaderProps = {
  componentRef: RefObject<HTMLElement>;
  context: string | undefined;
  heroNumber?: number;
  labCardId: string;
  question: string;
  totalNumberType: 'number' | 'percent' | 'currency';
};

export const LabCardPageHeader = ({
  componentRef,
  context = undefined,
  heroNumber = 0,
  labCardId = '',
  question = '',
  totalNumberType = 'number',
}: LabCardPageHeaderProps) => {
  const firebaseUserUID = AuthManager.currentUser?.uid ?? '';
  const companyName = AuthManager.klearlyUser?.companyName ?? '';
  const userSettingsDocId = `${firebaseUserUID}#${companyName}`;

  const getUserSettingsAsync = async () => {
    return DatabaseManager.UserSettingsModel.get(userSettingsDocId);
  };
  const getUserSettings = useAsync(getUserSettingsAsync);

  const getLabCardFeedbackAsync = async ({
    companyName,
  }: {
    companyName: string;
  }) => {
    return DatabaseManager.LabCardFeedbackModel.get(companyName);
  };
  const getLabCardFeedback = useAsync(getLabCardFeedbackAsync);

  useEffect(() => {
    if (firebaseUserUID) {
      getUserSettings.execute();
    }
    if (companyName) {
      getLabCardFeedback.execute({ companyName });
    }
  }, [firebaseUserUID]);

  const toggleFavoriteFlag = async () => {
    if (getUserSettings.value?.data && firebaseUserUID && companyName) {
      const { data } = getUserSettings.value;
      // it's already favorited
      if (isFavorited) {
        await getUserSettings.value.ref.update({
          labCardFavorites: FieldValue.arrayRemove(labCardId),
        });
      } else {
        const newFavorites = data?.labCardFavorites ?? [];
        newFavorites.push(labCardId);
        await DatabaseManager.UserSettingsModel.update(userSettingsDocId, {
          ...data,
          labCardFavorites: newFavorites,
        });
      }
    } else {
      await DatabaseManager.UserSettingsModel.create(
        {
          excludedAccountIds: [],
          labCardFavorites: [labCardId],
          filterFavorites: [],
          listFavorites: [],
          quickAccessFilters: null,
        },
        userSettingsDocId,
      );
    }
    getUserSettings.execute();
  };

  const toggleFeedbackLikeFlag = async () => {
    if (
      getLabCardFeedback.value?.data &&
      getLabCardFeedback.value.data[labCardId]
    ) {
      if (isLiked) {
        const newLikes = getLabCardFeedback.value.data[labCardId].likes.filter(
          (like) => like !== firebaseUserUID,
        );
        await DatabaseManager.LabCardFeedbackModel.update(companyName, {
          ...getLabCardFeedback.value.data,
          [labCardId]: {
            ...getLabCardFeedback.value.data[labCardId],
            likes: newLikes,
          },
        });
      } else {
        const newLikes =
          getLabCardFeedback.value.data[labCardId].likes ?? ([] as string[]);
        newLikes.push(firebaseUserUID);
        await DatabaseManager.LabCardFeedbackModel.update(companyName, {
          ...getLabCardFeedback.value.data,
          [labCardId]: {
            ...getLabCardFeedback.value.data[labCardId],
            likes: newLikes,
          },
        });
      }
    } else {
      const existingData = getLabCardFeedback.value?.data ?? {};
      await DatabaseManager.LabCardFeedbackModel.create(
        {
          ...existingData,
          [labCardId]: { comments: [], likes: [firebaseUserUID] },
        },
        companyName,
      );
    }
    getLabCardFeedback.execute({ companyName });
  };

  const isFavorited = useMemo(() => {
    if (getUserSettings?.value && firebaseUserUID) {
      return !!getUserSettings.value.data?.labCardFavorites?.includes(
        labCardId,
      );
    }
    return false;
  }, [getUserSettings?.value, getUserSettings?.error, firebaseUserUID]);

  const isLiked = useMemo(() => {
    if (getLabCardFeedback?.value?.data) {
      const selectedCard = Object.keys(getLabCardFeedback.value.data).find(
        (key) => key === labCardId,
      );
      if (selectedCard && firebaseUserUID) {
        return getLabCardFeedback.value.data[selectedCard].likes.includes(
          firebaseUserUID,
        );
      }
    }
    return false;
  }, [getLabCardFeedback?.value, getLabCardFeedback?.error, firebaseUserUID]);

  return (
    <Inline
      alignItems={'center'}
      className={'h-border-bottom'}
      gap={'md'}
      justifyContent={'between'}
      ph={'lg'}
      pv={'lg'}
      width={'full'}
    >
      <div>
        <Txt className={'h-color-black'} size={'lg'} weight={'bold'}>
          {question}
        </Txt>
        <div className={'h-mt-md'}>
          {heroNumber && (
            <Txt className={'h-color-black'} size={'3xl'} weight={'regular'}>
              {styleNumber(
                heroNumber,
                totalNumberType === 'currency',
                totalNumberType === 'percent',
              )}
            </Txt>
          )}
        </div>
        <div className={'h-mt-2xs'}>
          {_size(context) > 0 && (
            <Txt className={'h-color-black'}>{context}</Txt>
          )}
        </div>
      </div>
      <LabCardLikeFavoriteIcons
        toggleFeedbackLikeFlag={toggleFeedbackLikeFlag}
        toggleFavoriteFlag={toggleFavoriteFlag}
        isFavorited={isFavorited}
        isLiked={isLiked}
        middleComponent={
          <LabCardShareIcons
            componentRef={componentRef}
            customContentIdPrefix={'lab-page-'}
            id={labCardId}
            question={question}
          />
        }
      />
    </Inline>
  );
};
