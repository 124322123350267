import { mode } from '@chakra-ui/theme-tools';

export const styles = {
  global: (props) => ({
    body: {
      fontFamily: 'body',
      color: mode('#152f45', 'rgba(255,255,255,0.9)')(props),
      bg: mode('#f6f8f9', '#f6f8f9')(props),
      lineHeight: 'base',
      overscrollBehavior: 'none',
    },
    '*::placeholder': {
      color: mode('gray.400', 'whiteAlpha.400')(props),
    },
    '*, *::before, &::after': {
      borderColor: mode('gray.200', 'whiteAlpha.300')(props),
      wordWrap: 'break-word',
    },
  }),
};
