import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, Select, Text } from '@chakra-ui/react';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { ApiPageInfo } from 'shared/graphql/generatedApiTypes';
import { AnalyticsAttrType } from 'shared/types/coreTypes.d';
import { Button } from '../../../../../shared/components/Core';

type ServerPaginationProps = AnalyticsAttrType & {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (newPage: number) => void;
  nextPage: () => void;
  pageIndex: number;
  pageInfo: ApiPageInfo;
  pageLength: number;
  pageSize: number;
  previousPage: () => void;
  setPageSize: (newSize: number) => void;
};

export const ServerPagination = ({
  analyticsAttr,
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageIndex,
  pageInfo,
  pageLength,
  pageSize,
  previousPage,
  setPageSize,
}: ServerPaginationProps) => {
  const totalPages = pageInfo.totalPages;
  const totalEntries = pageInfo.totalEntries ?? 0;
  const showingText = `${pageSize * pageIndex + 1}-${
    pageSize * pageIndex + pageLength
  } of ${totalEntries.toLocaleString()}`;
  const pageOfPagesText = `${pageIndex + 1} of ${
    totalPages.toLocaleString() ?? 0
  }`;

  return (
    <Flex
      justify={'space-between'}
      background={'#FFFFFF'}
      borderRadius={'0px 0px 16px 16px'}
      padding={'6px 6px 12px 12px'}
      boxShadow={'0px 2px 4px rgba(0, 0, 0, 0.08)'}
    >
      <div
        style={{
          display: 'flex',
          marginTop: '16px',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            paddingLeft: '8px',
          }}
        >
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            Show rows per page
          </Text>
          <div>
            <Select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
              analytics-attr={cleanAnalyticsStringList([
                'Page size',
                analyticsAttr,
                'select',
              ])}
              className='pagination-select'
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option
                  key={pageSize}
                  value={pageSize}
                  analytics-attr={cleanAnalyticsStringList([
                    `page size`,
                    pageSize,
                    analyticsAttr,
                    'select option',
                  ])}
                >
                  {pageSize}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Text style={{ margin: 'auto' }}>{showingText} </Text>
          <Flex align={'center'}>
            <Button
              analyticsAttr={cleanAnalyticsStringList([
                'Previous page',
                analyticsAttr,
              ])}
              text={<ChevronLeftIcon />}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              variant={'transparent'}
              fontProps={{ fontSize: 'xl' }}
              color={'#30384A'}
            />

            <Button
              analyticsAttr={cleanAnalyticsStringList([
                'Next page',
                analyticsAttr,
              ])}
              text={<ChevronRightIcon />}
              onClick={() => nextPage()}
              disabled={!canNextPage}
              mr={2}
              variant={'transparent'}
              fontProps={{ fontSize: 'xl' }}
              color={'#30384A'}
            />
          </Flex>
        </div>
      </div>
    </Flex>
  );
};
