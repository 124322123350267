import React, { useState } from 'react';
import { Layer } from 'recharts';

export const SankeyLink = (props) => {
  const {
    sourceX,
    targetX,
    sourceY,
    targetY,
    sourceControlX,
    targetControlX,
    linkWidth,
    index,
    colorGradients,
    setAreLinksFaded,
  } = props;
  const gradientID = `linkGradient${index}`;
  const [fill, setFill] = useState<string>(`url(#${gradientID})`);

  const onMouseEnter = () => {
    setAreLinksFaded(true);
    setFill('#66c2a4');
  };

  const onMouseLeave = () => {
    setAreLinksFaded(false);
    setFill(`url(#${gradientID})`);
  };

  return (
    <Layer
      key={`CustomLink${index}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <defs>
        <linearGradient id={gradientID}>
          <stop offset='20%' stopColor={colorGradients[index].source} />
          <stop offset='80%' stopColor={colorGradients[index].target} />
        </linearGradient>
      </defs>
      <path
        d={`
            M${sourceX},${sourceY + linkWidth / 2}
            C${sourceControlX},${sourceY + linkWidth / 2}
              ${targetControlX},${targetY + linkWidth / 2}
              ${targetX},${targetY + linkWidth / 2}
            L${targetX},${targetY - linkWidth / 2}
            C${targetControlX},${targetY - linkWidth / 2}
              ${sourceControlX},${sourceY - linkWidth / 2}
              ${sourceX},${sourceY - linkWidth / 2}
            Z
          `}
        fill={fill}
        strokeWidth='0'
      />
    </Layer>
  );
};
