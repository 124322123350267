import { Box, Text } from '@chakra-ui/react';
import { BarGraphBackgroundBarsType } from '../../../helpers/rangerFilterHelpers';

type RangerBarAndLabelProps = {
  bars: { values: BarGraphBackgroundBarsType; selectedBarsIndex: number[] };
  currentValueIndices: number[];
  index: number;
  label: string | undefined;
};

export const RangerBarAndLabel = ({
  bars,
  currentValueIndices,
  index,
  label,
}: RangerBarAndLabelProps) => (
  <Box mt={13} maxH={8}>
    <Box
      w={`98%`}
      mr={'1%'}
      ml={'49%'}
      mt={-12}
      h={bars.values[index].height}
      bg={
        index >= currentValueIndices[0] && index <= currentValueIndices[1] - 1
          ? 'brand.gray-400'
          : 'brand.gray-200'
      }
      transform={`translateY(-${bars.values[index].height})`}
    />
    <RangerLabel bars={bars} index={index} label={label} />
  </Box>
);

type RangerLabelProps = Omit<RangerBarAndLabelProps, 'currentValueIndices'>;

export const RangerLabel = ({ bars, index, label }: RangerLabelProps) => (
  <Text
    fontSize={'3xs'}
    // mt={2}
    pt={2}
    ml={-4}
    whiteSpace={'nowrap'}
    transform={
      bars.values[index].height
        ? `translateY(-${bars.values[index].height});`
        : undefined
    }
  >
    {label ?? ''}
  </Text>
);
