import {
  CartesianGrid,
  Legend,
  Scatter,
  ScatterChart as RechartsScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import _findIndex from 'lodash/findIndex';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';

import { RechartsBase, RechartsTooltip } from './index';
import { RechartsTooltipPayloadType } from './RechartsBase';
import { useRef } from 'react';
import { cleanStringCase, numberFormatter } from '../../helpers/formatHelpers';

export type ScatterChartType = {
  data: Array<{
    fill: string;
    label?: string;
    status?: string;
    x?: number;
    y?: number;
  }>;
  handleBubbleSize: boolean;
  height?: string | number;
  isChartClickable: boolean;
  isPercent: boolean;
  onClick?: { dataPlotClick: ({ index: number }) => void };
  showLegend?: boolean;
  width?: string;
  xAxisLabel: string;
  yAxisLabel: string;
  zAxisLabel: string;
};

const renderTooltipContent = (
  dataPayload: RechartsTooltipPayloadType,
  xAxisLabel: string,
  yAxisLabel: string,
  zAxisLabel: string,
  isPercent: boolean,
) => {
  if (!dataPayload) {
    return null;
  }
  const items = [
    `${yAxisLabel}: ${dataPayload?.y}${isPercent ? '%' : ''}`,
    `${xAxisLabel}: ${dataPayload?.x?.toLocaleString()}`,
    `${zAxisLabel}: ${dataPayload?.z}${isPercent ? '%' : ''}`,
  ];
  return (
    <>
      <p
        style={{
          borderBottom: '1px solid var(--color-white)',
          margin: 0,
          paddingBottom: 4,
        }}
      >
        {dataPayload.label}
      </p>
      <ul style={{ margin: 0, padding: 0, paddingTop: 4 }}>
        {_map(items, (item, idx) => (
          <li
            key={idx}
            style={{
              color: 'var(--color-white)',
              display: 'block',
              paddingBottom: 4,
              paddingTop: 4,
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </>
  );
};

const getLegendPayload = (data: ScatterChartType['data']): Array<any> => {
  const uniqueData = _uniqBy(data, 'status');
  return _map(uniqueData, (d, idx) => ({
    color: d.fill,
    id: `${idx}`,
    type: 'circle',
    value: cleanStringCase(d.status as string),
  }));
};

const ScatterChart = ({
  data = [],
  handleBubbleSize = true,
  isChartClickable = false,
  isPercent = true,
  onClick = undefined,
  showLegend = false,
  xAxisLabel = '',
  yAxisLabel = '',
  zAxisLabel = '',
}: ScatterChartType) => {
  const baseRef = useRef(null);

  return (
    <RechartsBase
      chartClickableType={'bubble'}
      height={300}
      isChartClickable={isChartClickable}
      ref={baseRef}
      width={'100%'}
    >
      <RechartsScatterChart
        margin={{ bottom: 60, left: 20, right: 20, top: 10 }}
      >
        <CartesianGrid stroke={'#ccc'} strokeDasharray={'5 5'} />
        <XAxis
          dataKey={'x'}
          label={{
            offset: -15,
            position: 'insideBottom',
            value: xAxisLabel,
          }}
          name={xAxisLabel}
          tickFormatter={(val) => numberFormatter(val, false)}
          tickMargin={5}
          type={'number'}
        />
        <YAxis
          dataKey={'y'}
          domain={[0, 100]}
          label={{
            angle: -90,
            offset: -5,
            position: 'insideLeft',
            value: yAxisLabel,
          }}
          name={yAxisLabel}
          scale={'linear'}
          tickMargin={5}
          type={'number'}
          unit={isPercent ? '%' : undefined}
        />
        {handleBubbleSize && (
          <ZAxis
            dataKey={'z'}
            name={zAxisLabel}
            range={[10, 1000]}
            type={'number'}
            unit={isPercent ? '%' : undefined}
          />
        )}
        <Tooltip
          content={
            <RechartsTooltip
              renderContent={(dataPayload) =>
                renderTooltipContent(
                  dataPayload,
                  xAxisLabel,
                  yAxisLabel,
                  zAxisLabel,
                  isPercent,
                )
              }
            />
          }
        />
        <Scatter
          className={onClick ? 'h-pointer' : ''}
          data={data}
          onClick={
            onClick
              ? ({ payload }) => {
                  const index = _findIndex(data, {
                    x: payload.x,
                    y: payload.y,
                  });
                  onClick.dataPlotClick({ index });
                }
              : undefined
          }
        />
        {showLegend && (
          <Legend
            payload={getLegendPayload(data)}
            wrapperStyle={{ bottom: 0 }}
          />
        )}
      </RechartsScatterChart>
    </RechartsBase>
  );
};

export default ScatterChart;
