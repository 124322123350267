import { createIcon } from '@chakra-ui/icon';

export const SelectedSettingsGearIcon = createIcon({
  displayName: 'StatusCircleGradient',
  viewBox: '0 0 48 48',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='48'
      width='48'
      viewBox='0 0 48 48'
      fill='#ffffff'
    >
      <g fill='#ffffff' className='nc-icon-wrapper'>
        <path d='M40.728,27.007A17.1,17.1,0,0,0,41,24v0a17.1,17.1,0,0,0-.272-3.005l4.927-3.732a1,1,0,0,0,.263-1.3l-4-6.928a1,1,0,0,0-1.255-.421l-5.707,2.407a17.143,17.143,0,0,0-5.2-3.016l-.767-6.13A1,1,0,0,0,28,1H20a1,1,0,0,0-.992.876l-.767,6.13a17.143,17.143,0,0,0-5.2,3.016L7.337,8.615a1,1,0,0,0-1.255.421l-4,6.928a1,1,0,0,0,.263,1.3l4.926,3.731a16.984,16.984,0,0,0,0,6.016L2.345,30.739a1,1,0,0,0-.263,1.3l4,6.928a1,1,0,0,0,1.255.421l5.707-2.407a17.143,17.143,0,0,0,5.2,3.016l.767,6.13A1,1,0,0,0,20,47h8a1,1,0,0,0,.992-.876l.767-6.13a17.143,17.143,0,0,0,5.2-3.016l5.707,2.407a1,1,0,0,0,1.255-.421l4-6.928a1,1,0,0,0-.263-1.3ZM24,31a7,7,0,1,1,7-7A7,7,0,0,1,24,31Z' />
      </g>
    </svg>
  ),
});

export const SettingsGearIcon = createIcon({
  displayName: 'StatusCircleGradient',
  viewBox: '0 0 48 48',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='48'
      width='48'
      viewBox='0 0 48 48'
    >
      <g
        strokeLinecap='square'
        strokeWidth='2'
        fill='none'
        stroke='#D4DAE1'
        strokeLinejoin='miter'
        className='nc-icon-wrapper'
        strokeMiterlimit='10'
      >
        <circle cx='24' cy='24' r='7' stroke='#D4DAE1' />
        <path d='M40,24a16.046,16.046,0,0,0-.376-3.426l5.428-4.11-4-6.928-6.286,2.652A15.963,15.963,0,0,0,28.844,8.75L28,2H20l-.844,6.75a15.963,15.963,0,0,0-5.922,3.438L6.948,9.536l-4,6.928,5.428,4.11a15.807,15.807,0,0,0,0,6.852l-5.428,4.11,4,6.928,6.286-2.652a15.963,15.963,0,0,0,5.922,3.438L20,46h8l.844-6.75a15.963,15.963,0,0,0,5.922-3.438l6.286,2.652,4-6.928-5.428-4.11A16.046,16.046,0,0,0,40,24Z' />
      </g>
    </svg>
  ),
});
