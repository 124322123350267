export type LabCardApiResponse = {
  id: string;
  question: string;
  chartType: {
    available: ChartType[];
    default: ChartType;
  };
  columns: LabCardColumn[];
  rows: Array<Array<string | number>>;
  hero: {
    context: string;
    displayType: LabsHeroDisplay;
    value: number;
  };
};

type LabCardColumn = {
  label: string | null;
  name: LabChartColumnNameType;
  type:
    | LabBarChartColumnType
    | LabPieChartColumnType
    | LabHeatMapChartColumnType
    | LabScatterBubbleChartColumnType;
};

export enum LabsHeroDisplay {
  'integer' = 'integer',
  'percent' = 'percent',
  'currency' = 'currency',
}

export enum ChartType {
  'pie' = 'pie',
  'bubble' = 'bubble',
  'bar' = 'bar',
  'heat' = 'heat',
  'scatter' = 'scatter',
}

export enum LabChartColumnNameType {
  idCount = 'id_count',
  idCountPercentageOfTotal = 'id_count_pct_of_total',
  countryIsNull = 'country_is_null',
  industryIsNull = 'industry_is_null',
  titleIsNull = 'title_is_null',
  sizeIsNull = 'employee_count_is_null',
  annualRevenueIsNull = 'annual_revenue_is_null',
  min = 'min',
  max = 'max',
  firstClosedWinAmountSum = 'first_closed_win_amount_sum',
  type = 'type',
  x = 'x',
  y = 'y',
  z = 'z',
  industry = 'industry',
}

// Bar charts must have a value and either both min & max labels OR a key
export enum LabBarChartColumnType {
  labelMin = 'labelMin',
  labelMax = 'labelMax',
  key = 'key',
  value = 'value',
  valueAsPercentage = 'valueAsPercentage',
}

// Pie charts must have a key & a value
export enum LabPieChartColumnType {
  key = 'key',
  value = 'value',
  valueAsPercentage = 'valueAsPercentage',
}

// Heat maps must have an xAxis, a yAxis, and a value
export enum LabHeatMapChartColumnType {
  xAxis = 'xAxis',
  yAxis = 'yAxis',
  value = 'value',
  valueAsPercentage = 'valueAsPercentage',
}

// Scatter bubble charts must have an xAxis, a yAxis, a zAxis, a key, and a value
export enum LabScatterBubbleChartColumnType {
  xAxis = 'xAxis',
  yAxis = 'yAxis',
  zAxis = 'zAxis',
  key = 'key',
  value = 'value',
  valueAsPercentage = 'valueAsPercentage',
}
