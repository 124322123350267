import Button from './Button';
import { XCircleIcon } from './Icon/xCircle';

export const RemovableChipButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => (
  <Button
    fontProps={{
      fontSize: 'sm',
      color: 'brand.black',
      fontWeight: 'normal',
    }}
    p={2}
    h={8}
    borderRadius={16}
    text={text}
    variant={'transparent'}
    ml={2}
    mb={0.5}
    rightIcon={<XCircleIcon />}
    bg={`rgba(42, 150, 252, 0.1)`}
    borderColor={'brand.klarityBlue'}
    borderWidth={2}
    onClick={onClick}
  />
);
