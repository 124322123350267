import { createIcon } from '@chakra-ui/icon';

export const RetentionIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='28'
    width='64'
    viewBox='0 0 64 64'
  >
    <title>stay home</title>
    <g
      strokeLinecap='square'
      strokeWidth='4'
      fill='none'
      stroke='#2a96fc'
      strokeLinejoin='miter'
      strokeMiterlimit='10'
    >
      <polyline points='2 28 32 4 62 28' data-cap='butt' strokeLinecap='butt' />
      <polyline points='10 30 10 60 54 60 54 30' />
      <circle cx='32' cy='31' r='6' stroke='#2a96fc' />
      <path
        d='M32,41A12,12,0,0,0,20,53H44A12,12,0,0,0,32,41Z'
        stroke='#2a96fc'
      />
    </g>
  </svg>
);
