import React from 'react';
import { AspectRatio, Text } from '@chakra-ui/react';
import { Card } from 'shared/components/Core';
import { useQuery } from 'shared/graphql/generatedApiTypes';

export default function index() {
  const { data, loading } = useQuery();

  return (
    <Card isLoading={loading} height={'83vh'} overflow={'scroll'}>
      {data?.metabaseEmbedUrl ? (
        <AspectRatio maxW='100%' ratio={1}>
          <iframe
            id='webpage'
            title='Metabase reports'
            src={data?.metabaseEmbedUrl}
            allowFullScreen
            loading='lazy'
          />
        </AspectRatio>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'inherit',
          }}
        >
          <Text>No reports are available, yet.</Text>
          <Text>
            Please contact{' '}
            <a
              style={{
                color: '#0253a3',
                textDecoration: 'underline',
                fontWeight: 'bold',
              }}
              href={'mailto:support@klearly.com'}
            >
              {'support@klearly.com'}
            </a>
            .
          </Text>
        </div>
      )}
    </Card>
  );
}
