import { BrandBar, Logo } from './index';
import { navbarSizeStr } from 'app/src/constants/navbarSize';
import { Box, Container, Stack, Text } from '@chakra-ui/react';

export const CustomErrorComponent = () => (
  <Container ph={'lg'} maxW={'92%'}>
    <BrandBar />
    <Box as={'header'} bg={'transparent'} h={navbarSizeStr} pt={2}>
      <Box mt={4}>
        <Logo />
      </Box>
    </Box>
    <Stack spacing={6}>
      <Text
        as={'h1'}
        color={'brand.black'}
        fontWeight={'bold'}
        fontSize={'2xl'}
      >
        {'Exception Error'}
      </Text>
      <Text>
        {'Please retry. If the error persists, please contact '}
        <a
          style={{
            color: '#0253a3',
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
          href={'mailto:support@klearly.com'}
        >
          {'support@klearly.com'}
        </a>
        .
      </Text>
    </Stack>
  </Container>
);
