export const NONE_ICON = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <g clip-path='url(#clip0_6423_25713)'>
      <path
        d='M6 0C2.5 0.9 0 4.1 0 7.9C0 12.4 3.6 16 8.1 16C11.9 16 15 13.5 16 10C9.9 11.7 4.3 6.1 6 0Z'
        fill='#BC65D7'
      />
    </g>
    <defs>
      <clipPath id='clip0_6423_25713'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const WELL_BELOW_AVERAGE_ICON = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='22'
    viewBox='0 0 17 22'
    fill='none'
  >
    <path
      d='M13.4291 5.99979C13.4291 5.80204 13.3704 5.60874 13.2605 5.44433C13.1506 5.27992 12.9945 5.15178 12.8118 5.07611C12.6291 5.00044 12.428 4.98064 12.2341 5.0192C12.0401 5.05777 11.862 5.15298 11.7221 5.29279L8.42912 8.58579L5.13612 5.29279C4.94752 5.11063 4.69491 5.00983 4.43272 5.01211C4.17052 5.01439 3.91971 5.11956 3.7343 5.30497C3.54889 5.49038 3.44372 5.74119 3.44144 6.00339C3.43917 6.26558 3.53996 6.51818 3.72212 6.70679L7.72212 10.7068C7.90965 10.8943 8.16395 10.9996 8.42912 10.9996C8.69428 10.9996 8.94859 10.8943 9.13612 10.7068L13.1361 6.70679C13.3237 6.51929 13.4291 6.26498 13.4291 5.99979Z'
      fill='#E84A5D'
    />
    <path
      d='M13.4291 11.9998C13.4291 11.802 13.3704 11.6087 13.2605 11.4443C13.1506 11.2799 12.9945 11.1518 12.8118 11.0761C12.6291 11.0004 12.428 10.9806 12.2341 11.0192C12.0401 11.0578 11.862 11.153 11.7221 11.2928L8.42912 14.5858L5.13612 11.2928C4.94752 11.1106 4.69491 11.0098 4.43272 11.0121C4.17052 11.0144 3.91971 11.1196 3.7343 11.305C3.54889 11.4904 3.44372 11.7412 3.44144 12.0034C3.43917 12.2656 3.53996 12.5182 3.72212 12.7068L7.72212 16.7068C7.90965 16.8943 8.16395 16.9996 8.42912 16.9996C8.69428 16.9996 8.94859 16.8943 9.13612 16.7068L13.1361 12.7068C13.3237 12.5193 13.4291 12.265 13.4291 11.9998Z'
      fill='#E84A5D'
    />
  </svg>
);
export const BELOW_AVERAGE_ICON = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M12.9994 5.99979C12.9994 5.80204 12.9407 5.60874 12.8308 5.44433C12.7209 5.27992 12.5648 5.15178 12.3821 5.07611C12.1994 5.00044 11.9984 4.98064 11.8044 5.0192C11.6104 5.05777 11.4323 5.15298 11.2924 5.29279L7.99943 8.58579L4.70643 5.29279C4.51783 5.11063 4.26523 5.00983 4.00303 5.01211C3.74083 5.01439 3.49002 5.11956 3.30461 5.30497C3.1192 5.49038 3.01403 5.74119 3.01176 6.00339C3.00948 6.26558 3.11027 6.51818 3.29243 6.70679L7.29243 10.7068C7.47996 10.8943 7.73427 10.9996 7.99943 10.9996C8.26459 10.9996 8.5189 10.8943 8.70643 10.7068L12.7064 6.70679C12.894 6.51929 12.9994 6.26498 12.9994 5.99979Z'
      fill='#E84A5D'
    />
  </svg>
);
export const AVERAGE_ICON = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
  >
    <g opacity='0.5'>
      <path
        d='M11.7221 5.70679C11.9107 5.88894 12.1633 5.98974 12.4255 5.98746C12.6877 5.98518 12.9385 5.88001 13.1239 5.6946C13.3093 5.5092 13.4145 5.25838 13.4168 4.99619C13.4191 4.73399 13.3183 4.48139 13.1361 4.29279L9.13612 0.292786C8.94859 0.105315 8.69428 0 8.42912 0C8.16395 0 7.90965 0.105315 7.72212 0.292786L3.72212 4.29279C3.53996 4.48139 3.43917 4.73399 3.44144 4.99619C3.44372 5.25838 3.54889 5.5092 3.7343 5.6946C3.91971 5.88001 4.17052 5.98518 4.43272 5.98746C4.69491 5.98974 4.94752 5.88894 5.13612 5.70679L7.42912 3.41379V12.5858L5.13612 10.2928C4.94752 10.1106 4.69491 10.0098 4.43272 10.0121C4.17052 10.0144 3.91971 10.1196 3.7343 10.305C3.54889 10.4904 3.44372 10.7412 3.44144 11.0034C3.43917 11.2656 3.53996 11.5182 3.72212 11.7068L7.72212 15.7068C7.90965 15.8943 8.16395 15.9996 8.42912 15.9996C8.69428 15.9996 8.94859 15.8943 9.13612 15.7068L13.1361 11.7068C13.3183 11.5182 13.4191 11.2656 13.4168 11.0034C13.4145 10.7412 13.3093 10.4904 13.1239 10.305C12.9385 10.1196 12.6877 10.0144 12.4255 10.0121C12.1633 10.0098 11.9107 10.1106 11.7221 10.2928L9.42912 12.5858V3.41379L11.7221 5.70679Z'
        fill='#323F5A'
      />
    </g>
  </svg>
);
export const ABOVE_AVERAGE_ICON = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M12.9994 10.0002C12.9994 10.198 12.9407 10.3913 12.8308 10.5557C12.7209 10.7201 12.5648 10.8482 12.3821 10.9239C12.1994 10.9996 11.9984 11.0194 11.8044 10.9808C11.6104 10.9422 11.4323 10.847 11.2924 10.7072L7.99943 7.41421L4.70643 10.7072C4.51783 10.8894 4.26523 10.9902 4.00303 10.9879C3.74083 10.9856 3.49002 10.8804 3.30461 10.695C3.1192 10.5096 3.01403 10.2588 3.01176 9.99661C3.00948 9.73442 3.11027 9.48182 3.29243 9.29321L7.29243 5.29321C7.47996 5.10574 7.73427 5.00043 7.99943 5.00043C8.26459 5.00043 8.5189 5.10574 8.70643 5.29321L12.7064 9.29321C12.894 9.48071 12.9994 9.73502 12.9994 10.0002Z'
      fill='#2A96FC'
    />
  </svg>
);

export const WELL_ABOVE_AVERAGE_ICON = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='22'
    viewBox='0 0 16 22'
    fill='none'
  >
    <path
      d='M12.9994 16.0002C12.9994 16.198 12.9407 16.3913 12.8308 16.5557C12.7209 16.7201 12.5648 16.8482 12.3821 16.9239C12.1994 16.9996 11.9984 17.0194 11.8044 16.9808C11.6104 16.9422 11.4323 16.847 11.2924 16.7072L7.99943 13.4142L4.70643 16.7072C4.51783 16.8894 4.26523 16.9902 4.00303 16.9879C3.74083 16.9856 3.49002 16.8804 3.30461 16.695C3.1192 16.5096 3.01403 16.2588 3.01176 15.9966C3.00948 15.7344 3.11027 15.4818 3.29243 15.2932L7.29243 11.2932C7.47996 11.1057 7.73427 11.0004 7.99943 11.0004C8.26459 11.0004 8.5189 11.1057 8.70643 11.2932L12.7064 15.2932C12.894 15.4807 12.9994 15.735 12.9994 16.0002Z'
      fill='#2A96FC'
    />
    <path
      d='M12.9994 10.0002C12.9994 10.198 12.9407 10.3913 12.8308 10.5557C12.7209 10.7201 12.5648 10.8482 12.3821 10.9239C12.1994 10.9996 11.9984 11.0194 11.8044 10.9808C11.6104 10.9422 11.4323 10.847 11.2924 10.7072L7.99943 7.41421L4.70643 10.7072C4.51783 10.8894 4.26523 10.9902 4.00303 10.9879C3.74083 10.9856 3.49002 10.8804 3.30461 10.695C3.1192 10.5096 3.01403 10.2588 3.01176 9.99661C3.00948 9.73442 3.11027 9.48182 3.29243 9.29321L7.29243 5.29321C7.47996 5.10574 7.73427 5.00043 7.99943 5.00043C8.26459 5.00043 8.5189 5.10574 8.70643 5.29321L12.7064 9.29321C12.894 9.48071 12.9994 9.73502 12.9994 10.0002Z'
      fill='#2A96FC'
    />
  </svg>
);

export const SignalIntensity = {
  WELL_BELOW_AVERAGE: WELL_BELOW_AVERAGE_ICON,
  BELOW_AVERAGE: BELOW_AVERAGE_ICON,
  AVERAGE: AVERAGE_ICON,
  ABOVE_AVERAGE: ABOVE_AVERAGE_ICON,
  WELL_ABOVE_AVERAGE: WELL_ABOVE_AVERAGE_ICON,
  'No Activities': NONE_ICON,
  'No Responses': NONE_ICON,
  NONE : NONE_ICON
};
