import { useContext, useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import { getCalendarFilterLabel } from '../../helpers/getCalendarFilterLabel';
import { Button, Inline, Txt } from '../../../../../../shared/components/Core';
import { FilterDictType } from '../../types/filterDict';
import { usePrevious } from 'app/src/hooks/previousHook';
import { ApiGetAggregateDataQuery } from 'shared/graphql/generatedApiTypes';
import { filterItemsLoadingVar } from 'app/src/apollo/rootReactiveVariables';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { FilterIndices } from '../../types/filterTypes';
import { AnalyticsAttrType } from 'shared/types/coreTypes.d';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { CalendarRangeWrapper } from './calendarFilterItem/CalendarRangeWrapper';
import { CalendarDateType } from '../../types/calendarTypes';
import { formatDateString } from 'shared/helpers/formatHelpers';

type CalendarRangeFilterItemProps = AnalyticsAttrType & {
  filterIndex: FilterIndices;
  label?: string;
  itemSource?: 'bar' | 'panel' | 'wideMenu';
};

type CalendarRangeFilterItemState = {
  endDate: CalendarDateType;
  startDate: CalendarDateType;
};

const CalendarRangeFilterItem = ({
  analyticsAttr = undefined,
  filterIndex,
  label = '',
  itemSource,
}: CalendarRangeFilterItemProps) => {
  const {
    data: filterItemAggregatedAccountsData,
    loading: filterItemAggregatedAccountsDataLoading,
    globalFilter,
    updateFilter,
    fullAggregateLoading,
  } = useContext(AggregateDataContext);

  const initialState: CalendarRangeFilterItemState = {
    endDate: null,
    startDate: null,
  };
  const [{ endDate, startDate }, setState] =
    useState<CalendarRangeFilterItemState>(initialState);

  const aggregatedFilterItemsData:
    | ApiGetAggregateDataQuery['aggregateData']
    | undefined = filterItemAggregatedAccountsData?.aggregateData;
  const labelString = getCalendarFilterLabel({
    count: aggregatedFilterItemsData?.totalAccounts,
    endDate,
    label: label,
    startDate,
  });

  const _onReset = () => {
    setState(initialState);
    updateFilter({ index: filterIndex, value: undefined });
  };

  useEffect(() => {
    if (startDate && endDate) {
      const cleanedValue = {
        min: formatDateString(startDate, 'yyyy-MM-dd'),
        max: formatDateString(endDate, 'yyyy-MM-dd'),
      };
      updateFilter({ index: filterIndex, value: cleanedValue });
    }
    // Excluding updateFilter since it's used in context
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, filterIndex]);

  const previousFilterDict: FilterDictType | undefined =
    usePrevious(globalFilter);
  const isLoading =
    filterItemAggregatedAccountsDataLoading || fullAggregateLoading;

  const previousLoadingState: boolean | undefined = usePrevious(isLoading);

  useEffect(() => {
    if (!_isEqual(previousFilterDict, globalFilter)) {
      const isValid = !!globalFilter?.[filterIndex];
      const minValue = isValid
        ? new Date(`${globalFilter?.[filterIndex]?.min}T00:00:00`)
        : null;
      const maxValue = isValid
        ? new Date(`${globalFilter?.[filterIndex]?.max}T00:00:00`)
        : null;
      setState((prevState) => ({
        ...prevState,
        endDate: maxValue,
        startDate: minValue,
      }));
    }
  }, [globalFilter, filterIndex, previousFilterDict]);

  useEffect(() => {
    if (!_isEqual(previousLoadingState, isLoading)) {
      filterItemsLoadingVar(isLoading);
    }
  }, [isLoading, previousLoadingState]);

  return (
    <div className={'h-pv-md h-ph-xl'}>
      <Inline
        display={'flex'}
        justifyContent={'between'}
        alignItems={'end'}
        pv={'sm'}
      >
        {labelString && (
          <Txt align={'center'} className={'h-mb-sm'} weight={'bold'}>
            {labelString}
          </Txt>
        )}
        <Button
          analyticsAttr={cleanAnalyticsStringList([analyticsAttr, 'reset'])}
          onClick={() => _onReset()}
          size={'sm'}
          variant={'active'}
          text={'Clear Dates'}
        />
      </Inline>
      <CalendarRangeWrapper
        itemSource={itemSource}
        endDate={endDate}
        setEndDate={(date: CalendarDateType) =>
          setState((prevState) => ({ ...prevState, endDate: date }))
        }
        setStartDate={(date: CalendarDateType) =>
          setState((prevState) => ({ ...prevState, startDate: date }))
        }
        startDate={startDate}
      />
    </div>
  );
};

export default CalendarRangeFilterItem;
