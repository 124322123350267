export enum FilterIndices {
  ACCOUNTS_FILTER_TYPE_INCLUSIVE = 'and',
  ACCOUNTS_FILTER_TYPE_EXCLUSIVE = 'or',
  ACCOUNTS_FILTER_TYPE = 'accountFilterType',
  ACCOUNTS_FILTER = 'accountIds',
  ACCOUNTS_FULL_FILTER = 'accounts',
  ACCOUNT_LIST_ACCOUNT_GROUP_IDS = 'accountListAccountGroupIds',
  ACCOUNT_NAME = 'accountName',
  ADDRESS_ZIP_CODE = 'addressZipCode',
  AREA = 'area',
  COUNTRY = 'country',
  CUSTOMER_STATUS = 'customerStatus',
  CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE = 'currentAccountScore',
  CURRENT_WIN_PROBABILITY = 'currentValue',
  ENGAGEMENT_INTENSITY = 'signalResponseIntensity',
  ENGAGEMENT_STATUS = 'engagementStatus',
  EXCLUDED_ACCOUNT_IDS = 'excludedAccountIds',
  INDUSTRY = 'industry',
  LAST_INBOUND_SIGNAL_DATE_FILTER = 'lastInboundSignalDate',
  LAST_OUTBOUND_SIGNAL_DATE_FILTER = 'lastOutboundSignalDate',
  LIFT = 'lift',
  OUTREACH_INTENSITY = 'signalActivityIntensity',
  OUTREACH_STATUS = 'outreachStatus',
  PREDICTION_LEVEL = 'predictionLevel',
  PREDICTION_NAME = 'predictionName',
  PREDICTION_TYPE = 'predictionType',
  PREDICTION_TYPE_NAME_LEVEL = 'predictionTypeNameLevel',
  PREDICTION_TYPE_LEVEL = 'predictionTypeLevel',
  PREDICTED_XEOMIN_PURCHASE_QUANTITY = 'predictedXeominPurchaseQuantity',
  PREDICTED_XEOMIN_PURCHASE_PROPENSITY = 'predictedXeominPurchasePropensity',
  PREDICTED_XEOMIN_REORDER_PROPENSITY = 'predictedXeominReorderPropensity',
  RANK = 'winfluenceRank',
  RECOMMENDED_ACTION = 'recommendedAction',
  REGION = 'region',
  REVENUES_FILTER = 'revenue',
  SIGNAL_TYPE_FILTER = 'signalType',
  SIZES_FILTER = 'size',
  TERRITORY = 'territory',
  WINFLUENCE = 'lift',
  WINFLUENCE_ACTUAL = 'winfluence',
  XEOMIN_AVERAGE_QUANTITY = 'xeominAverageQuantity',
  XEOMIN_PORTFOLIO_SPEND = 'xeominPortfolioSpend',
  XEOMIN_PREDICTION_SUMMARY = 'xeominPredictionSummary',
  XEOMIN_TREND = 'xeominTrend',
}

export enum FilterStrings {
  COUNTRY = 'Country',
  INDUSTRY = 'Industry',
  LAST_INBOUND_SIGNAL_DATE = 'Last Inbound Signal Date',
  LAST_OUTBOUND_SIGNAL_DATE = 'Last Outbound Signal Date',
  RECOMMENDED_ACTION = 'Recommended Action',
  ENGAGEMENT_STATUS = 'Engagement Status',
  ENGAGEMENT_INTENSITY = 'Engagement Intensity',
  OUTREACH_INTENSITY = 'Outreach Intensity',
  OUTREACH_STATUS = 'Outreach Status',
  REVENUE = 'Revenue',
  SIGNAL_TYPE = 'Signal Type',
  SIZE = 'Size',
  WINFLUENCE = 'Winfluence',
  XEOMIN_AVERAGE_QUANTITY = 'Xeomin Average Quantity',
  XEOMIN_PORTFOLIO_SPEND = 'Xeomin Portfolio Spend',
  XEOMIN_PREDICTION_SUMMARY = 'Xeomin Prediction Summary',
  XEOMIN_TREND = 'Xeomin Trend',
  PREDICTED_XEOMIN_PURCHASE_QUANTITY = 'Predicted Xeomin Purchase Quantity',
  PREDICTED_XEOMIN_PURCHASE_PROPENSITY = 'Predicted Xeomin Purchase Propensity',
  PREDICTED_XEOMIN_REORDER_PROPENSITY = 'Predicted Xeomin Reorder Propensity',
  AREA = 'Area',
  REGION = 'Region',
  TERRITORY = 'Territory',
  PREDICTION_TYPE = "Prediction Type",
  PREDICTION_NAME = "Prediction Name",
  PREDICTION_LEVEL = "Prediction Level",
  PREDICTION_TYPE_LEVEL = "Prediction Type Level",
  PREDICTION_TYPE_NAME_LEVEL = "Prediction Type Name Level",
}

type FilterItemsType = {
  accessor: string;
  index: string;
};
export const INDUSTRY_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'industry',
};
export const COUNTRY_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'country',
};
export const ENGAGEMENT_STATUS_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'engagementStatus',
};
export const WINFLUENCE_DROPDOWN_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'winfluence',
};
export const RECOMMENDED_ACTIONS_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'recommendedAction',
};
export const SIGNAL_TYPES_FILTER_ITEMS: FilterItemsType = {
  accessor: 'name',
  index: 'signalType',
};
export const WINFLUENCE_RANK_TICKS = [
  'None',
  'Very Low',
  'Low',
  'Moderate',
  'High',
  'Very High',
];

export const PREDICTION_NAME_LEVEL_FIT_FILTER_ITEM = {
 0 : "Very Weak",
 1 : "Weak",
 2 : "Moderate",
 3 : "Strong",
 4 : "Very Strong"
}

export const PREDICTION_NAME_LEVEL_FILTER_ITEM = {
  0 : "Least",
  1 : "Less",
  2 : "Typical",
  3 : "More",
  4 : "Most"
 }
 
export const filterIndexToString: {
  [x: string]: string;
} = {
  [FilterIndices.ADDRESS_ZIP_CODE]: 'Zip Codes',
  [FilterIndices.ACCOUNTS_FILTER]: 'Accounts',
  [FilterIndices.ACCOUNTS_FULL_FILTER]: 'Accounts',
  [COUNTRY_FILTER_ITEMS.index]: FilterStrings.COUNTRY,
  [FilterIndices.CUSTOMER_STATUS]: 'Customer Status',
  [FilterIndices.CURRENT_WIN_PROBABILITY_AS_ACCOUNT_SCORE]: 'Account Scores',
  [FilterIndices.INDUSTRY]: FilterStrings.INDUSTRY,
  [FilterIndices.LAST_INBOUND_SIGNAL_DATE_FILTER]:
    FilterStrings.LAST_INBOUND_SIGNAL_DATE,
  [FilterIndices.LAST_OUTBOUND_SIGNAL_DATE_FILTER]:
    FilterStrings.LAST_OUTBOUND_SIGNAL_DATE,
  [RECOMMENDED_ACTIONS_FILTER_ITEMS.index]: FilterStrings.RECOMMENDED_ACTION,
  [ENGAGEMENT_STATUS_FILTER_ITEMS.index]: FilterStrings.ENGAGEMENT_STATUS,
  [FilterIndices.ENGAGEMENT_INTENSITY]: FilterStrings.ENGAGEMENT_INTENSITY,
  [FilterIndices.OUTREACH_INTENSITY]: FilterStrings.OUTREACH_INTENSITY,
  [FilterIndices.REVENUES_FILTER]: FilterStrings.REVENUE,
  [FilterIndices.SIZES_FILTER]: FilterStrings.SIZE,
  [SIGNAL_TYPES_FILTER_ITEMS.index]: FilterStrings.SIGNAL_TYPE,
  [FilterIndices.WINFLUENCE]: FilterStrings.WINFLUENCE,
  [WINFLUENCE_DROPDOWN_FILTER_ITEMS.index]: FilterStrings.WINFLUENCE,
  [FilterIndices.RANK]: FilterStrings.WINFLUENCE,
  [FilterIndices.XEOMIN_PREDICTION_SUMMARY]:
    FilterStrings.XEOMIN_PREDICTION_SUMMARY,
  [FilterIndices.AREA]: FilterStrings.AREA,
  [FilterIndices.REGION]: FilterStrings.REGION,
  [FilterIndices.TERRITORY]: FilterStrings.TERRITORY,
  [FilterIndices.XEOMIN_TREND]: FilterStrings.XEOMIN_TREND,
  [FilterIndices.XEOMIN_PORTFOLIO_SPEND]: FilterStrings.XEOMIN_PORTFOLIO_SPEND,
  [FilterIndices.XEOMIN_AVERAGE_QUANTITY]:
    FilterStrings.XEOMIN_AVERAGE_QUANTITY,
  [FilterIndices.PREDICTED_XEOMIN_PURCHASE_PROPENSITY]:
    FilterStrings.PREDICTED_XEOMIN_PURCHASE_PROPENSITY,
  [FilterIndices.PREDICTED_XEOMIN_PURCHASE_QUANTITY]:
    FilterStrings.PREDICTED_XEOMIN_PURCHASE_QUANTITY,
  [FilterIndices.PREDICTED_XEOMIN_REORDER_PROPENSITY]:
    FilterStrings.PREDICTED_XEOMIN_REORDER_PROPENSITY,
  [FilterIndices.OUTREACH_STATUS]: FilterStrings.OUTREACH_STATUS,
  [FilterIndices.PREDICTION_LEVEL]: FilterStrings.PREDICTION_LEVEL,
  [FilterIndices.PREDICTION_TYPE]: FilterStrings.PREDICTION_TYPE,
  [FilterIndices.PREDICTION_NAME]: FilterStrings.PREDICTION_NAME,
  [FilterIndices.PREDICTION_TYPE_LEVEL] : FilterStrings.PREDICTION_TYPE_LEVEL,
  [FilterIndices.PREDICTION_TYPE_NAME_LEVEL] : FilterStrings.PREDICTION_TYPE_NAME_LEVEL
};
