import {
  GetAccountGroupDocument,
  GetAccountsListOfIdsWithFilterVarDocument,
} from 'shared/graphql/generatedApiTypes';
import { ApolloClient } from '@apollo/client';

export const mergeListsHelper = async (
  selectedRows: number[],
  apolloClient: ApolloClient<any>,
) => {
  const listOfIds: number[] = [];
  const promises = selectedRows.map(async (selectedRow) => {
    const accountGroup = await apolloClient.query({
      query: GetAccountGroupDocument,
      variables: { id: selectedRow },
    });
    if (accountGroup.data?.accountGroup?.accounts) {
      const newList = accountGroup.data.accountGroup.accounts
        .filter((account) => account?.id)
        .map((account) => account!.id);

      listOfIds.push(...newList);
    }
  });
  await Promise.all(promises);
  return Array.from(new Set(listOfIds));
};

export const mergeFiltersHelper = async (
  allFilters: string[],
  apolloClient: ApolloClient<any>,
) => {
  // apply each filter and get the list of account IDs that is found
  const promises = allFilters.map(async (filterString) => {
    const accountList = await apolloClient.query({
      query: GetAccountsListOfIdsWithFilterVarDocument,
      variables: { filterString },
    });
    const fullList = await apolloClient.query({
      query: GetAccountsListOfIdsWithFilterVarDocument,
      variables: {
        filterString,
        limit: accountList?.data?.accounts?.pageInfo?.totalEntries ?? 0,
      },
    });
    if (fullList.data?.accounts?.accounts) {
      return fullList.data.accounts.accounts.map((account) => account!.id);
    }
  });
  return await Promise.all(promises);
};
