import { Container } from '../../../../../../../shared/components/Core';

import SectionHeader from '../../../../../../../shared/components/Core/SectionHeader';
import { ApiGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { Stack } from '@chakra-ui/react';
import { RecommendationsBody } from './RecommendationsBody';
import { RecommendationsTabProps } from 'app/src/components/Account/components/sav/recommendations/RecommendationsTab';

export type RecommendationsProps = {
  accountInfo: ApiGetAccountQuery['account'];
  sectionVisibilityToggles: {
    setShowingAllActions: (newVal: boolean) => void;
    showingAllActions: boolean;
    setShowingPrimaryActions: (newVal: boolean) => void;
    showingPrimaryActions: boolean;
  };
} & RecommendationsTabProps;

const Recommendations = ({
  accountInfo,
  title,
  subtitle,
  tooltip,
  sectionVisibilityToggles,
  ...props
}: RecommendationsProps) => {
  return (
    <Container ph={'xl'} pv={'lg'} width={'full'}>
      <Stack spacing={4}>
        <SectionHeader tooltip={tooltip} subtitle={subtitle} title={title} />
        <RecommendationsBody
          accountInfo={accountInfo}
          sectionVisibilityToggles={sectionVisibilityToggles}
          {...props}
        />
      </Stack>
    </Container>
  );
};

export default Recommendations;
