import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Divider, Stack, Text } from '@chakra-ui/react';
import { AccountField } from 'app/src/components/Account/components/sav/header/AccountHeader';
import { buildGoogleMapsLink } from 'app/src/components/Account/helpers/buildGoogleMapsLink';
import { getFormattedAddressString } from 'app/src/components/Account/helpers/getFormattedAddressString';
import { formatDistanceToNow } from 'date-fns';
import { Tooltip } from 'shared/components/Core';
import { CustomerIcon } from 'shared/components/Core/Icon/customer';
import { PastCustomerIcon } from 'shared/components/Core/Icon/past_customer';
import { ProspectIcon } from 'shared/components/Core/Icon/propspect';
import { DrawerSectionField } from 'shared/firebase/types/tableTypes';
import { ApiAccount, ApiSignal, Maybe } from 'shared/graphql/generatedApiTypes';
import {
  numberFormatter,
  snakeCaseToTitleCase,
} from 'shared/helpers/formatHelpers';
import { PredictionTootipLegend } from '../../GaugeChart/constants/gaugeBins';
import { SignalIntensity } from './SignalintensityIcon';

export const option: any = { year: 'numeric', month: 'short', day: 'numeric' };

export type AccountDrawerItemRendererParams = {
  drawerSectionField: DrawerSectionField;
  accountInfo:
    | (Pick<
        ApiAccount,
        | 'id'
        | 'crmAccountId'
        | 'accountName'
        | 'addressStreet'
        | 'addressCity'
        | 'addressRegion'
        | 'addressZipCode'
        | 'industry'
        | 'employeeCount'
        | 'annualRevenue'
        | 'customerStatus'
        | 'signalActivityIntensity'
        | 'signalResponseIntensity'
        | 'engagementStatus'
        | 'outreachStatus'
        | 'xeominAverageQuantity'
        | 'xeominPredictionSummary'
        | 'xeominPurchaseTrendShort'
        | 'xeominSpendFraction'
        | 'rankPriority'
      > & {
        lastInboundSignal: Maybe<Pick<ApiSignal, 'occurredAt'>>;
        lastOutboundSignal: Maybe<Pick<ApiSignal, 'occurredAt'>>;
      })
    | null;
};

export const AccountDrawerItemRenderer = ({
  accountInfo,
  drawerSectionField,
}: AccountDrawerItemRendererParams) => {
  if (!accountInfo) {
    return null;
  }
  switch (drawerSectionField.dataField) {
    case AccountField.crmId:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={accountInfo.crmAccountId}
        />
      );
    case AccountField.accountName:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={accountInfo.accountName ?? 'N/A'}
        />
      );
    case AccountField.revenue:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={numberFormatter(accountInfo.annualRevenue)}
        />
      );
    case AccountField.size:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={numberFormatter(accountInfo.employeeCount, false)}
        />
      );
    case AccountField.industry:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={snakeCaseToTitleCase(accountInfo.industry ?? '-')}
        />
      );
    case AccountField.lastInboundResponse:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={
            accountInfo.lastInboundSignal?.occurredAt
              ? formatDistanceToNow(
                  new Date(accountInfo.lastInboundSignal.occurredAt),
                  { addSuffix: true },
                )
              : 'No' + ' responses'
          }
        />
      );
    case AccountField.outreachIntensity:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={snakeCaseToTitleCase(
            accountInfo.signalResponseIntensity ?? '-',
          )}
        />
      );
    case AccountField.outreachStatus:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={snakeCaseToTitleCase(accountInfo.outreachStatus ?? '-')}
        />
      );
    case AccountField.lastOutboundActivity:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={
            accountInfo.lastOutboundSignal?.occurredAt
              ? formatDistanceToNow(
                  new Date(accountInfo.lastOutboundSignal.occurredAt),
                  { addSuffix: true },
                )
              : 'No' + ' activities'
          }
        />
      );
    case AccountField.engagementIntensity:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={snakeCaseToTitleCase(
            accountInfo.signalActivityIntensity ?? '-',
          )}
        />
      );
    case AccountField.engagementStatus:
      return (
        <DrawerItemText
          label={drawerSectionField.label}
          data={snakeCaseToTitleCase(accountInfo.engagementStatus ?? '-')}
        />
      );
    case AccountField.location:
      const googleMapsLink = buildGoogleMapsLink({
        addressStreet: accountInfo?.addressStreet,
        addressCity: accountInfo?.addressCity,
        addressRegion: accountInfo?.addressRegion,
        addressZipCode: accountInfo?.addressZipCode,
      });
      return (
        <Stack spacing={0}>
          <DrawerLocationLink
            googleMapsLink={googleMapsLink}
            labelText={drawerSectionField.label}
          />
          <Text>{accountInfo?.addressStreet ?? '-'}</Text>
          <Text>
            {getFormattedAddressString({
              addressCity: accountInfo?.addressCity,
              addressRegion: accountInfo?.addressRegion,
              addressZipCode: accountInfo?.addressZipCode,
            })}
          </Text>
        </Stack>
      );
  }
};

const DrawerItemText = ({ label, data }: { label: string; data: string }) => {
  return (
    <Stack spacing={0}>
      <Text fontWeight={'bold'} fontSize={'sm'}>
        {label.toUpperCase()}
      </Text>
      <Text>{data}</Text>
    </Stack>
  );
};

const DrawerLocationLink = ({
  googleMapsLink,
  labelText,
}: {
  googleMapsLink: string;
  labelText: string;
}) =>
  googleMapsLink ? (
    <Text
      _hover={{ cursor: 'pointer' }}
      analytics-attr={`single-account-external-map-link`}
      as={'a'}
      fontSize={'sm'}
      fontWeight={'bold'}
      href={googleMapsLink}
      mr={2}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      {labelText.toUpperCase()}
      <ExternalLinkIcon ml={2} fontSize={'xl'} />
    </Text>
  ) : (
    <Text fontSize={'sm'} fontWeight={'bold'}>
      {labelText.toUpperCase()}
    </Text>
  );
const renderClockcolorJson = {
  ACTIVATED: '#27E492',
  DEACTIVATED: '#EB3E49',
  DEACTIVATING: '#FFAF51',
  INACTIVATED: '',
  DISENGAGED: '#EB3E49',
  DISENGAGING: '#FFAF51',
  ENGAGED: '#27E492',
  UNENGAGED: '',
};

export const ClockIcon = (data: string | number) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16px'
      height='16px'
      viewBox='0 0 16 16'
      version='1.1'
      style={{ marginTop: '2px' }}
    >
      <g id='surface1'>
        <path
          fill='none'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke={data !== undefined ? renderClockcolorJson[data] : 'gray'}
          strokeOpacity='1'
          strokeMiterlimit='4'
          d='M 12 22.998047 C 18.076172 22.998047 22.998047 18.076172 22.998047 12 C 22.998047 5.923828 18.076172 1.001953 12 1.001953 C 5.923828 1.001953 1.001953 5.923828 1.001953 12 C 1.001953 18.076172 5.923828 22.998047 12 22.998047 Z M 12 22.998047 '
          transform='matrix(0.666667,0,0,0.666667,0,0)'
        />
        <path
          fill='none'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          stroke={data !== undefined ? renderClockcolorJson[data] : 'gray'}
          strokeOpacity='1'
          strokeMiterlimit='4'
          d='M 12 6 L 12 12 L 18 12 '
          transform='matrix(0.666667,0,0,0.666667,0,0)'
        />
      </g>
    </svg>
  );
};

export const DateRenderer = (signal, iconName) => {
  let occurredDate = signal !== null ? new Date(signal.occurredAt) : null;

  return (
    <div style={{ display: 'flex', gap: '5px' }}>
      {signal !== null ? (
        signal.occurredAt !== null && occurredDate !== null ? (
          <>
            {/* {iconName !== undefined && <div style={{ width: '16px' }}>{ClockIcon(iconName)}</div>} */}
            <span>
              {formatDistanceToNow(new Date(signal.occurredAt), {
                addSuffix: true,
              })}
              <br />({occurredDate.toLocaleDateString('en-US', option)})
            </span>
          </>
        ) : (
          'N/A'
        )
      ) : (
        'N/A'
      )}
    </div>
  );
};

export const LastInOutActionJSON = {
  attend: 'Attended',
  bounce: 'Bounced',
  change: 'Changed',
  click: 'Clicked',
  complete: 'Completed',
  defer: 'Deferred',
  deliver: 'Delivered',
  drop: 'Dropped',
  fail: 'Failed',
  hold: 'Held',
  listen: 'Listened',
  make: 'Made',
  open: 'Opened',
  process: 'Processed',
  refer: 'Referred',
  register: 'Registered',
  reply: 'Replied',
  report: 'Reported',
  send: 'Sent',
  submit: 'Submitted',
  subscribe: 'Subscribed',
  suppress: 'Suppressed',
  unsubscribe: 'Unsubscribed',
  use: 'Used',
  view: 'Viewed',
  visit: 'Visited',
};

// Last Inbound logic for company list
export const LastInboundLogic = (item) => {
  return item?.['lastInboundSignal']?.['direction'] === 'response' &&
    item?.['lastInboundSignal']?.['typeDetail'] ? (
    <>
      <div>
        {item?.['lastInboundSignal']?.['typeDetail'].charAt(0).toUpperCase() +
          item?.['lastInboundSignal']?.['typeDetail'].slice(1) +
          ' ' +
          'Order:' +
          ' ' +
          item?.['lastInboundSignal']?.['topicDetail']}
      </div>
      {DateRenderer(item.lastInboundSignal, item['engagementStatus'])}
    </>
  ) : item?.['lastInboundSignal']?.['type'] ? (
    <>
      <div style={{ textTransform: 'capitalize' }}>
        {item?.['lastInboundSignal']?.['type'] +
          ':' +
          ' ' +
          (LastInOutActionJSON[item?.['lastInboundSignal']?.['action']] || ' ')}
      </div>
      {DateRenderer(item.lastInboundSignal, item['outreachStatus'])}
    </>
  ) : (
    'N/A'
  );
};

// Last Outbound logic for company list
export const LastOutboundLogic = (item) => {
  return item?.['lastOutboundSignal']?.['direction'] === 'activity' ? (
    item?.['lastOutboundSignal']?.['type'] === 'call' &&
    item?.['lastOutboundSignal']?.['topic'] ? (
      <>
        <>
          <div>
            {item?.['lastOutboundSignal']?.['type'].charAt(0).toUpperCase() +
              item?.['lastOutboundSignal']?.['type'].slice(1) +
              ':' +
              ' ' +
              item?.['lastOutboundSignal']?.['topicDetail']
                .charAt(0)
                .toUpperCase() +
              item?.['lastOutboundSignal']?.['topicDetail'].slice(1)}
          </div>
          {DateRenderer(item.lastOutboundSignal, item['outreachStatus'])}
        </>
      </>
    ) : item?.['lastOutboundSignal']?.['type'] === 'promo_code' ? (
      <>
        <div>Promo Code</div>
        {DateRenderer(item.lastOutboundSignal, item['outreachStatus'])}
      </>
    ) : item?.['lastOutboundSignal']?.['typeDetail'] &&
      item?.['lastOutboundSignal']?.['topic'] ? (
      <>
        <div>
          {item?.['lastOutboundSignal']?.['typeDetail']
            .charAt(0)
            .toUpperCase() +
            item?.['lastOutboundSignal']?.['typeDetail'].slice(1) +
            ':' +
            ' ' +
            item?.['lastOutboundSignal']?.['topicDetail']
              .charAt(0)
              .toUpperCase() +
            item?.['lastOutboundSignal']?.['topicDetail'].slice(1)}
        </div>
        {DateRenderer(item.lastOutboundSignal, item['outreachStatus'])}
      </>
    ) : item?.['lastOutboundSignal']?.['type'] ? (
      <>
        <div style={{ textTransform: 'capitalize' }}>
          {item?.['lastOutboundSignal']?.['type'] +
            ':' +
            ' ' +
            (LastInOutActionJSON[item?.['lastOutboundSignal']?.['action']] ||
              '')}
        </div>
        {DateRenderer(item.lastOutboundSignal, item['outreachStatus'])}
      </>
    ) : (
      'N/A'
    )
  ) : (
    'N/A'
  );
};

// Last In/Out bounds legends
export const lastInOutBoundTooltipLegend = {
  lastInboundResponse: [
    { label: 'Engaged', color: '#27E492' },
    { label: 'Disengaging', color: '#FFAF51' },
    { label: 'Disengaged', color: '#EB3E49' },
  ],
  lastOutboundActivity: [
    { label: 'Activated', color: '#27E492' },
    { label: 'Deactivating', color: '#FFAF51' },
    { label: 'Deactivated', color: '#EB3E49' },
  ],
};
export const signalIntensityIcons = [
  { label: 'Well Below Average', icon: SignalIntensity['WELL_BELOW_AVERAGE'] },
  { label: 'Below Average', icon: SignalIntensity['BELOW_AVERAGE'] },
  { label: 'Average', icon: SignalIntensity['AVERAGE'] },
  { label: 'Above Average', icon: SignalIntensity['ABOVE_AVERAGE'] },
  { label: 'Well Above Average', icon: SignalIntensity['WELL_ABOVE_AVERAGE'] },
  { label: 'None', icon: SignalIntensity['NONE'] },
];

// Tooltip For Last Inbound Response and Last Outbound Activity columns
export const LastInOutboundTooltip = (
  description: {} | null | undefined,
  headerText: {} | null | undefined,
  tooltiptype: string,
  headerType: string,
) => {
  return (
    <Tooltip
      background='#FFFFFF'
      content={
        <div>
          <div>
            <span style={{ color: '#303030' }}>{description}</span>
          </div>
          <Divider
            style={{
              marginTop: '5px',
              color: '#DAE3E9',
              border: '1px solid #DAE3E9',
              width: '278px',
              background: '#DAE3E9',
            }}
          />
          <div
            style={{
              color: '#303030',
              marginTop: '5px',
              flexDirection: 'column',
              gap: '50px',
            }}
          >
            {signalIntensityIcons.map((item, index) => {
              return (
                <>
                  <div
                    style={{
                      lineHeight: '25px',
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                    }}
                    key={index}
                  >
                    {item.icon}
                    {item.label}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      }
    >
      <Text fontSize={'12px'} color={'#152F45'}>
        {headerText}
      </Text>
    </Tooltip>
  );
};

// Tooltip for company columns
export const CompanyTooltip = (
  description: {} | null | undefined,
  headerText: {} | null | undefined,
) => {
  return (
    <Tooltip
      background='#FFFFFF'
      content={
        <div>
          <div>
            <span style={{ color: '#303030' }}>{description}</span>
          </div>
          <Divider
            style={{
              marginTop: '5px',
              color: '#DAE3E9',
              border: '1px solid #DAE3E9',
              width: '278px',
              background: '#DAE3E9',
            }}
          />
          <div
            style={{
              color: '#303030',
              marginTop: '5px',
              flexDirection: 'column',
              gap: '50px',
            }}
          >
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div style={{ width: '36px' }}>{CustomerIcon}</div>
              <span>At least one purchase in the last 12 months</span>
            </div>
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <div style={{ width: '45px' }}>{ProspectIcon}</div>
              <span>At least one purchase, but not in the last 12 months</span>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                marginTop: '5px',
              }}
            >
              <div style={{ width: '36px' }}>{PastCustomerIcon}</div>
              <span>No purchase history</span>
            </div>
          </div>
        </div>
      }
    >
      <Text>{headerText}</Text>
    </Tooltip>
  );
};

// Tooltip for Prediction columns
export const PredictionColumnTooltip = (
  description: {} | null | undefined,
  predictionType: string,
  headerText: {} | null | undefined,
) => {
  return (
    <Tooltip
      background='#FFFFFF'
      content={
        <div>
          <div>
            <span style={{ color: '#303030' }}>{description}</span>
          </div>
          <Divider
            style={{
              marginTop: '5px',
              color: '#DAE3E9',
              border: '1px solid #DAE3E9',
              width: '278px',
              background: '#DAE3E9',
            }}
          />
          <div
            style={{
              color: '#303030',
              marginTop: '5px',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            {PredictionTootipLegend[predictionType].map((item) => {
              return (
                <>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    {item?.icon}
                    {item?.label}
                  </span>
                </>
              );
            })}
          </div>
        </div>
      }
    >
      <Text>{headerText}</Text>
    </Tooltip>
  );
};

// Adding comas in numbers
export const ComaConverter = (value) => {
  return String(value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export const RecommendetionTooltip = (description, headerText) => {
  return (
    <Tooltip
      background='#FFFFFF'
      content={
        <div>
          <div>
            <span style={{ color: '#303030' }}>{description}</span>
          </div>
        </div>
      }
    >
      <Text>{headerText}</Text>
    </Tooltip>
  );
};

export const purchaseEventLogic = (item) => {
  return (
    <>
      <div>
        {item?.['type'].charAt(0).toUpperCase() +
          item?.['type'].slice(1) +
          ':' +
          ' ' +
          item?.['topicDetail'].charAt(0).toUpperCase() +
          item?.['topicDetail'].slice(1)}
      </div>
      {DateRenderer(item, item['outreachStatus'])}
    </>
  );
};
