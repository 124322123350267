import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Text } from '@chakra-ui/react';
import { Button, RechartsTooltip } from 'shared/components/Core';
import useComponentSize from '@rehooks/component-size';
import {
  Granularity,
  SingleAccountSignalItem,
  SingleAccountSignalsTimePeriod,
} from '../../../types/SingleAccountSignalsTypes';
import { ZoomOutIcon } from 'shared/components/Core/Icon/zoomOut';
import { add, isBefore, lastDayOfMonth } from 'date-fns';
import { capitalize } from 'lodash';
import { formatDateString } from 'shared/helpers/formatHelpers';

type SingleAccountSignalHistoryChartState = {
  refAreaLeft: string;
  refAreaRight: string;
};

type SingleAccountSignalHistoryChartProps = {
  chartData: SingleAccountSignalItem[];
  currentGranularity: Granularity;
  currentTimePeriod: SingleAccountSignalsTimePeriod;
  isCurrentlyZoomedIn: boolean;
  setIsCurrentlyZoomedIn: Dispatch<SetStateAction<boolean>>;
  updateStartAndEndDates: (newStartDate: string, newEndDate: string) => void;
  zoomOut: () => void;
};

export const SingleAccountSignalHistoryChart = ({
  chartData,
  currentGranularity,
  currentTimePeriod,
  isCurrentlyZoomedIn,
  setIsCurrentlyZoomedIn,
  updateStartAndEndDates,
  zoomOut,
}: SingleAccountSignalHistoryChartProps) => {
  const initialState: SingleAccountSignalHistoryChartState = {
    refAreaLeft: '',
    refAreaRight: '',
  };

  const [{ refAreaLeft, refAreaRight }, setState] =
    useState<SingleAccountSignalHistoryChartState>(initialState);
  const chartWrapperRef = useRef(null);
  const { width } = useComponentSize(chartWrapperRef);

  useEffect(() => {
    setIsCurrentlyZoomedIn(false);
  }, [currentTimePeriod]);

  const zoom = () => {
    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      setState((prevState) => ({
        ...prevState,
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    const bottomItem = chartData?.find(
      (item) => item.occurredAt === refAreaLeft,
    );
    const topItem = chartData?.find((item) => item.occurredAt === refAreaRight);

    // Safari needs /s not -s for dates, but we need the -s in the helpers to differentiate so we convert them here
    const bottomString = bottomItem?.originalDate.split('-').join('/') ?? '';
    const topString = topItem?.originalDate.split('-').join('/') ?? '';
    const bottomDate = new Date(bottomString);
    const topDate = new Date(topString);
    if (chartData?.length) {
      let leftDate = '';
      let rightDate = '';
      if (bottomItem && topItem) {
        leftDate = isBefore(bottomDate, topDate) ? bottomString : topString;
        rightDate = isBefore(bottomDate, topDate) ? topString : bottomString;
      }
      if (currentGranularity === Granularity.monthly) {
        rightDate = lastDayOfMonth(new Date(rightDate)).toDateString();
      }
      if (currentGranularity === Granularity.quarterly) {
        rightDate = lastDayOfMonth(
          add(new Date(rightDate), {
            months: 2,
          }),
        ).toDateString();
      }
      leftDate = formatDateString(new Date(leftDate));
      rightDate = formatDateString(new Date(rightDate));
      updateStartAndEndDates(leftDate, rightDate);
    }

    setState((prevState) => ({
      ...prevState,
      refAreaLeft: '',
      refAreaRight: '',
    }));
    setIsCurrentlyZoomedIn(true);
  };

  return (
    <>
      <Box ref={chartWrapperRef} analytics-attr={'activity-explorer-chart'}>
        <Button
          analyticsAttr={'activity-explorer-zoom-out'}
          isDisabled={!isCurrentlyZoomedIn}
          leftIcon={<ZoomOutIcon boxSize={6} />}
          mb={4}
          onClick={() => {
            zoomOut();
            setIsCurrentlyZoomedIn(false);
          }}
          maxW={40}
          ml={4}
          borderWidth={2}
          borderRadius={24}
          borderColor={'#ECF2FA'}
          fontWeight={'normal'}
          maxWidth={140}
          py={4}
          color={'brand.darkGrayBlue'}
          variant={'actionOutline'}
          text={'Zoom Out'}
        />
        <LineChart
          width={width}
          height={400}
          data={chartData}
          onMouseDown={(e) =>
            e?.activeLabel &&
            setState((prevState) => ({
              ...prevState,
              refAreaLeft: e.activeLabel,
            }))
          }
          onMouseMove={(e) =>
            refAreaLeft &&
            e?.activeLabel &&
            setState((prevState) => ({
              ...prevState,
              refAreaRight: e.activeLabel,
            }))
          }
          onMouseUp={zoom}
        >
          <Legend
            formatter={(dataPayload: any) => capitalize(dataPayload)}
            iconType={'circle'}
            verticalAlign={'top'}
            wrapperStyle={{ top: -20 }}
          />
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            allowDataOverflow
            dataKey='occurredAt'
            domain={
              chartData.length
                ? [
                    chartData[0].occurredAt,
                    chartData[chartData.length - 1].occurredAt,
                  ]
                : ['dataMin', 'dataMax']
            }
            type='category'
            style={{ userSelect: 'none' }}
            tickMargin={6}
            tickFormatter={(value) =>
              value && value !== 'dataMax' && value !== 'dataMin' ? value : ''
            }
          />
          <YAxis
            allowDecimals={false}
            domain={[0, 'dataMax+1']}
            type='number'
            yAxisId='1'
            style={{ userSelect: 'none' }}
            tickFormatter={(value) => value.toLocaleString()}
          />
          <Tooltip
            content={
              <RechartsTooltip
                lightBackground
                renderContent={renderTooltipContent}
              />
            }
          />
          <Line
            yAxisId='1'
            dataKey='marketing'
            stroke={'#2A96FC'}
            animationDuration={300}
            type={'monotone'}
            strokeWidth={2}
            dot={false}
          />
          <Line
            yAxisId='1'
            dataKey='sales'
            stroke={'#F97D2F'}
            animationDuration={300}
            type={'monotone'}
            strokeWidth={2}
            dot={false}
          />
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea
              yAxisId='1'
              x1={refAreaLeft}
              x2={refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}
        </LineChart>
      </Box>
    </>
  );
};

const renderTooltipContent = (dataPayload: any) => {
  const marketing = dataPayload?.marketing;
  const sales = dataPayload?.sales;
  const occurredAt = dataPayload?.occurredAt;
  return (
    <>
      <Text color={'brand.black'}>{`Date: ${occurredAt}`}</Text>
      <Text
        color={'brand.black'}
      >{`Marketing: ${marketing.toLocaleString()}`}</Text>
      <Text color={'brand.black'}>{`Sales: ${sales.toLocaleString()}`}</Text>
    </>
  );
};
