import { Flex } from '@chakra-ui/react';
import { NewsIcon } from 'shared/components/Core/Icon/news';
import { ApiMessageType } from 'shared/graphql/generatedApiTypes';
import { LightBulbIcon } from 'shared/components/Core/Icon/lightBulb';

export const MessageIcon = ({
  messageType,
}: {
  messageType: ApiMessageType;
}) => {
  switch (messageType) {
    case ApiMessageType.Insight:
      return (
        <IconWrapper color={'brand.midnight'}>
          <LightBulbIcon color={'brand.white'} fontSize={24} />
        </IconWrapper>
      );
    default:
      return (
        <IconWrapper color={'brand.purple'}>
          <NewsIcon color={'brand.white'} fontSize={24} />
        </IconWrapper>
      );
  }
};

const IconWrapper = ({ children, color }) => (
  <Flex
    w={14}
    h={14}
    align={'center'}
    justify={'center'}
    borderRadius={48}
    bg={color}
  >
    {children}
  </Flex>
);
