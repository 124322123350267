import { gql } from '@apollo/client';

export const ADD_ACCOUNT_GROUP = gql`
  mutation AddAccountGroup($input: AddAccountGroupInput!) {
    addAccountGroup(input: $input) {
      accountGroup {
        id
      }
    }
  }
`;

export const CREATE_DATA_SOURCE_CONFIG = gql`
  mutation CreateDataSourceConfig($dataSourceConfig: DataSourceConfigInput!) {
    createDataSource(dataSourceConfig: $dataSourceConfig) {
      sourceId
    }
  }
`;

export const CREATE_GOOGLE_SECRET_VERSION = gql`
  mutation CreateGoogleSecretVersion($googleSecret: GoogleSecretInput!) {
    createGoogleSecretVersion(googleSecret: $googleSecret) {
      message
    }
  }
`;

export const CREATE_NEW_COMPANY = gql`
  mutation CreateNewCompany($company: CompanyInput!) {
    addCompany(company: $company) {
      id
      companyName
      canSubmitCredentials
      # Alias in place for now to support existing code
      CRMTypes: crmTypes
      insertedAt
      updatedAt
    }
  }
`;

export const CREATE_NEW_USER = gql`
  mutation CreateNewUser($user: UserInput!) {
    addUser(user: $user) {
      email
      firstName
      lastName
    }
  }
`;

export const CREATE_NEW_USERS = gql`
  mutation CreateNewUsers($users: [UserInput]!) {
    addUsers(users: $users) {
      users {
        firstName
        lastName
        email
        companyName
      }
      errors {
        email
        message
      }
    }
  }
`;

export const UPDATE_ACCOUNT_GROUP_ACCOUNTS = gql`
  mutation UpdateAccountGroupAccounts(
    $input: UpdateAccountGroupAccountsInput!
  ) {
    updateAccountGroupAccounts(input: $input) {
      accountGroup {
        title
        id
      }
    }
  }
`;

export const UPDATE_ACCOUNT_GROUP_DESCRIPTORS = gql`
  mutation UpdateAccountGroupDescriptors(
    $input: UpdateAccountGroupDescriptorsInput!
  ) {
    updateAccountGroupDescriptors(input: $input) {
      accountGroup {
        title
        id
      }
    }
  }
`;

export const UPDATE_ACCOUNT_GROUP_IS_HIDDEN = gql`
  mutation UpdateAccountGroupIsHidden(
    $input: UpdateAccountGroupIsHiddenInput!
  ) {
    updateAccountGroupIsHidden(input: $input) {
      accountGroup {
        title
        id
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($company: CompanyInputUpdate!) {
    updateCompany(company: $company) {
      companyName
      canSubmitCredentials
      # Alias in place for now to support existing code
      CRMTypes: crmTypes
    }
  }
`;

export const UPDATE_CURRENT_USER_COMPANY = gql`
  mutation UpdateCurrentUserCompany($companyName: String!) {
    updateUserCompany(companyName: $companyName) {
      companyName
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($user: UserInputUpdate!) {
    updateUser(user: $user) {
      companyName
      email
      firstName
      lastName
      isAdmin
      disabled
    }
  }
`;
