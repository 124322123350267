import {AnalyticsAttrType} from 'shared/types/coreTypes.d';
import {AccordionButton, AccordionItem, AccordionPanel, Box, Text, TextProps,} from '@chakra-ui/react';
import {AddIcon, MinusIcon} from '@chakra-ui/icons';
import {Component, ReactNode} from 'react';

type AccordionPanelWrapperProps = AnalyticsAttrType & {
  render: (isExpanded: boolean) => ReactNode;
  title: string;
  titleProps?: TextProps;
};

export class AccordionPanelWrapper extends Component<AccordionPanelWrapperProps> {
  render() {
    return (
      <AccordionItem
        analytics-attr={this.props.analyticsAttr}
        key={this.props.title}
      >
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton>
                <Box flex='1' textAlign='left'>
                  <Text
                    fontWeight={'bold'}
                    fontSize={'xl'}
                    color={'brand.black'}
                    {...this.props.titleProps}
                  >
                    {this.props.title}
                  </Text>
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize={'md'} />
                ) : (
                  <AddIcon fontSize={'md'} />
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel>{this.props.render(isExpanded)}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    );
  }
}
