import { ReactNode, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs } from '../../../../shared/components/Core';
import * as ROUTES from '../../constants/routes';
import { AdminUserList } from './components/userList/AdminUserList';
import { updateAppHeaders } from 'app/src/helpers/updateAppHeaders';
import { IntegrationRequestPage } from '../Integrations/components/IntegrationRequestPage';
import AuthManager from 'shared/firebase/classes/AuthManager';

const useURLQuery = () => new URLSearchParams(useLocation().search);

export const AdminPage = () => {
  const query = useURLQuery();
  const page: string | null = query.get('page');
  const history = useHistory();
  const klearlyUser = AuthManager.klearlyUser;
  let validRoutes: Array<string> = [
    ROUTES.ADMIN_USER_LIST_TAB,
    ROUTES.ADMIN_INTEGRATIONS_TAB,
  ];
  const viewingUnauthorizedRoute: boolean = !validRoutes.includes(page!);
  if (!page || viewingUnauthorizedRoute) {
    if (page === 'users') {
      history.push(`${ROUTES.ADMIN[1]}?page=${ROUTES.ADMIN_USER_LIST_TAB}`);
    }
    if (page === 'integrations') {
      history.push(`${ROUTES.ADMIN[1]}?page=${ROUTES.ADMIN_INTEGRATIONS_TAB}`);
    }
  }
  let tabs: Array<ReactNode> = [
    //@ts-ignore
    <div key={ROUTES.ADMIN_USER_LIST_TAB} label={ROUTES.ADMIN_USER_LIST_TAB}>
      <AdminUserList />
    </div>,
    <div
      key={ROUTES.ADMIN_INTEGRATIONS_TAB}
      label={ROUTES.ADMIN_INTEGRATIONS_TAB}
    >
      <IntegrationRequestPage companyName={klearlyUser?.companyName} />
    </div>,
  ];
  useEffect(() => {
    updateAppHeaders(page || undefined);
  }, [page]);
  return (
    <div className='bg_blue'>
      <div className='ad_card'>
        <div className='tab_centers'>
          <Tabs
            activeLabel={page}
            className={'tab-class'}
            onTabChange={(newTab) => {
              history.push(`${ROUTES.ADMIN[1]}?page=${newTab}`);
            }}
          >
            {klearlyUser?.isAdmin || klearlyUser?.isKlearlyAdmin
              ? tabs
              : [tabs[0]]}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
