import { ApiGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { SelectedChartValuesType } from 'app/src/components/Account/components/mav/MultiAccountWinfluence';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _inRange from 'lodash/inRange';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';
import _isArray from 'lodash/isArray';

export function filterAccountByPriorityAndRank(
  account:
    | ApiGetAccountQuery['account']
    | NonNullable<ApiGetAccountQuery['account']>['goalValues']
    | null = null,
  selectedRangeValues: SelectedChartValuesType | undefined,
  selectedCurrentRange?: Array<number>,
  selectedLiftRange?: Array<number> | number,
) {
  const rankPriorityValue = (account as ApiGetAccountQuery['account'])
    ?.rankPriority;
  if (selectedRangeValues) {
    const probabilityCheck = !!_filter(
      _map(_map(selectedRangeValues, 'current'), (val) =>
        _inRange(
          account?.[FilterIndices.CURRENT_WIN_PROBABILITY],
          val[0],
          val[1] + 1,
        ),
      ),
    ).length;
    const rankCheck = !!_filter(
      _map(_map(selectedRangeValues, 'winfluence'), (val) =>
        _isArray(val)
          ? _inRange(rankPriorityValue ?? 0, val[0], val[1] + 1)
          : rankPriorityValue === val,
      ),
    ).length;
    return {
      probabilityCheck,
      rankCheck,
    };
  }
  const probabilityCheck = account
    ? _inRange(
        account[FilterIndices.CURRENT_WIN_PROBABILITY],
        selectedCurrentRange![0],
        selectedCurrentRange![1] + 1,
      )
    : false;
  const rankMin = _isArray(selectedLiftRange)
    ? selectedLiftRange[0]
    : selectedLiftRange;
  const rankMax = _isArray(selectedLiftRange) ? selectedLiftRange[1] + 1 : null;
  const rankCheck = account
    ? rankMax
      ? _inRange(rankPriorityValue ?? 0, rankMin as number, rankMax)
      : rankPriorityValue === rankMin
    : false;
  return {
    probabilityCheck,
    rankCheck,
  };
}
