import { LabCardApiResponse, LabsHeroDisplay } from '../types/labApiTypes';
import { LabFirestoreValuesType } from '../types/firestoreValuesTypes';
import { Container, IconBadge } from 'shared/components/Core';
import { Flex, Stack } from '@chakra-ui/react';
import { LabChart } from 'app/src/components/Lab/components/LabCharts';
import IfComment from 'shared/components/Core/Icon/if-comments';
import LabCardComments from 'app/src/components/Lab/components/LabCardComments';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import { useAsync } from 'app/src/hooks/useAsync';
import { useEffect, useMemo } from 'react';

type LabCardPageContentProps = {
  labFromApi: LabCardApiResponse;
  tableValue: LabFirestoreValuesType;
};

export const LabCardPageContent = ({
  labFromApi,
  tableValue,
}: LabCardPageContentProps) => {
  const getLabCardFeedbackAsync = async ({
    companyName,
  }: {
    companyName: string;
  }) => {
    return DatabaseManager.LabCardFeedbackModel.getSingleCardFeedback(
      companyName,
      labFromApi.id,
    );
  };

  const getLabCardFeedback = useAsync(getLabCardFeedbackAsync);

  useEffect(() => {
    getLabCardFeedback.execute({});
  }, []);

  const comments = useMemo(
    () => getLabCardFeedback.value?.comments ?? [],
    [getLabCardFeedback.value],
  );

  return (
    <Container pv={'lg'}>
      <Stack spacing={4}>
        <div className={'h-flex h-flex-justify-content-end h-mr-lg'}>
          <IconBadge
            badgeProps={{ position: 'relative' }}
            iconComponent={<IfComment boxSize={6} ml={-3} mt={2} />}
            text={comments.toString()}
          />
        </div>
        <Flex p={8}>
          <div className={'h-height-full h-width-full'}>
            {labFromApi && (
              <LabChart
                columns={labFromApi.columns}
                context={labFromApi.hero.context}
                displayType={labFromApi.chartType.default}
                isAnswerTypePercent={
                  labFromApi.hero.displayType === LabsHeroDisplay.percent
                }
                isChartClickable={false}
                isDollars={
                  labFromApi.hero.displayType === LabsHeroDisplay.currency
                }
                isSmallerViewport={true}
                monoColor={tableValue.monoColor}
                rows={labFromApi.rows}
                sortOrder={'asc'}
                totalNumber={labFromApi.hero.value}
              />
            )}
          </div>
        </Flex>
        <div className={'h-pointer h-flex h-width-full'}>
          <div
            className={'h-width-full h-mr-lg h-ml-lg'}
            style={{ height: '450px' }}
          >
            <LabCardComments id={labFromApi.id} />
          </div>
        </div>
      </Stack>
    </Container>
  );
};
