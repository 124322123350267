import React from 'react';
import {createIcon} from '@chakra-ui/icon';

export const NeverPurchasedIcon = createIcon({
  displayName: 'NeverPurchased',
  viewBox: '0 0 21 20',
  path: (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.7501 5.75C12.2335 5.75 12.7835 6.45833 12.8335 7.5H14.6751C14.6168 6.06667 13.7418 4.75 12.0001 4.325V2.5H9.50013V4.3C9.05846 4.4 8.6418 4.55 8.2668 4.75L9.4918 5.975C9.83346 5.83333 10.2501 5.75 10.7501 5.75ZM4.77513 3.38333L3.7168 4.44167L6.58346 7.30833C6.58346 9.04167 7.88346 9.98333 9.8418 10.5667L12.7668 13.4917C12.4835 13.8917 11.8918 14.25 10.7501 14.25C9.03346 14.25 8.35846 13.4833 8.2668 12.5H6.43346C6.53346 14.325 7.90013 15.35 9.50013 15.6917V17.5H12.0001V15.7083C12.8001 15.5583 13.5168 15.25 14.0418 14.775L15.8918 16.625L16.9501 15.5667L4.77513 3.38333Z'
        fill='#152F45'
      />
    </svg>
  ),
});
