import { createIcon } from '@chakra-ui/icon';

export const Logo = createIcon({
  displayName: 'Logo',
  viewBox: '0 0 324.21 69.5',
  path: (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 566.37 118.12'>
      <defs />
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path
            id='Path_18560'
            data-name='Path 18560'
            fill='#c7305b'
            d='M14.76,86.78a4.7,4.7,0,1,1,9.39-.27v.1h0a4.7,4.7,0,0,1-4.61,4.79h-.08a4.71,4.71,0,0,1-4.7-4.63Z'
          />
          <ellipse
            id='Ellipse_95936'
            data-name='Ellipse 95936'
            fill={'#ea434c'}
            cx='533.98'
            cy='80.13'
            rx='11.45'
            ry='11.47'
          />
          <path
            fill={'#323f5a'}
            d='M94.25,1.83A1.8,1.8,0,0,1,96.05,0h20.44a1.81,1.81,0,0,1,1.81,1.81V31.75a1.8,1.8,0,0,0,3.19,1.16L148.4.67A1.8,1.8,0,0,1,149.78,0h26.4A1.81,1.81,0,0,1,178,1.83,1.79,1.79,0,0,1,177.53,3L147.91,36.1a1.81,1.81,0,0,0-.17,2.2l32.77,50.52a1.81,1.81,0,0,1-1.52,2.8H150.85a1.76,1.76,0,0,1-1.52-.84L129.75,59.92a1.8,1.8,0,0,0-2.49-.57,2.3,2.3,0,0,0-.38.32l-8.11,9a1.82,1.82,0,0,0-.47,1.22v20a1.79,1.79,0,0,1-1.8,1.81H96.06a1.81,1.81,0,0,1-1.81-1.81h0Z'
          />
          <path
            fill={'#323f5a'}
            d='M187.48,0h22.73A1.82,1.82,0,0,1,212,1.81v88a1.81,1.81,0,0,1-1.8,1.81H187.48a1.81,1.81,0,0,1-1.81-1.81v-88A1.82,1.82,0,0,1,187.48,0Z'
          />
          <path
            fill={'#323f5a'}
            d='M282,30q-9.78-10.38-27.25-10.38a40.58,40.58,0,0,0-19.56,4.57,32.63,32.63,0,0,0-13.17,12.87,38.45,38.45,0,0,0-4.69,19.27,37.88,37.88,0,0,0,4.63,19A31.75,31.75,0,0,0,235.22,88q8.61,4.52,20.34,4.51a47,47,0,0,0,17.94-3.27A36.48,36.48,0,0,0,285.89,81a1.83,1.83,0,0,0-.05-2.57l-11.23-11a1.8,1.8,0,0,0-2.43-.07q-6.87,5.7-14.4,5.7a13.85,13.85,0,0,1-8.67-2.74A14.09,14.09,0,0,1,245,65.1a1.83,1.83,0,0,1,.87-2.45,1.86,1.86,0,0,1,.77-.19h43.22a1.82,1.82,0,0,0,1.94-1.71v-.07c0-.71,0-1.28,0-2.27Q291.81,40.38,282,30ZM264,49.52h-18.3a1.84,1.84,0,0,1-1.81-1.87,1.6,1.6,0,0,1,.09-.53,15.91,15.91,0,0,1,3.46-6.35A9.82,9.82,0,0,1,255,37.51a9.93,9.93,0,0,1,7.82,3.4,11.34,11.34,0,0,1,2.79,6.56A1.84,1.84,0,0,1,264,49.52Z'
          />
          <path
            fill={'#323f5a'}
            d='M355.52,26.33q-8.4-6.85-23.41-6.86-15.41,0-32.48,6.65a1.85,1.85,0,0,0-1.05,2.35l5.25,14.09a1.78,1.78,0,0,0,2.3,1l.06,0a64.9,64.9,0,0,1,9.69-3.07,44.78,44.78,0,0,1,10-1.31c4,0,7,.83,9.13,2.48a8.35,8.35,0,0,1,3.12,6.93v.26H320.77q-13.42.14-20.41,5.68t-7,15.88q0,10.18,6.39,16.27t17.6,6.07a29.66,29.66,0,0,0,12.19-2.35,21.39,21.39,0,0,0,8.54-6.67v6a1.82,1.82,0,0,0,1.8,1.81h22.21a1.82,1.82,0,0,0,1.81-1.81V45.48Q363.93,33.2,355.52,26.33ZM338.11,67.78a1.8,1.8,0,0,1-.17.82,11.19,11.19,0,0,1-4.52,5,13.78,13.78,0,0,1-7.3,2,8.55,8.55,0,0,1-5.87-1.83,6.55,6.55,0,0,1-2.08-5.22q0-6.66,7.95-6.67h10.19a1.81,1.81,0,0,1,1.81,1.81Z'
          />
          <path
            fill={'#323f5a'}
            d='M406,22.67a25.09,25.09,0,0,1,11-3.14,1.81,1.81,0,0,1,1.88,1.74v20c0,1.38-1.23,1.82-2.31,1.77-.67,0-1.42,0-2.25,0q-8.22,0-13,3.92a13.15,13.15,0,0,0-4.76,10.71V89.79a1.81,1.81,0,0,1-1.81,1.81H372a1.81,1.81,0,0,1-1.81-1.81V22.33A1.81,1.81,0,0,1,372,20.52h22.72a1.81,1.81,0,0,1,1.81,1.81v4.16a1.8,1.8,0,0,0,3.1,1.24A26.17,26.17,0,0,1,406,22.67Z'
          />
          <path
            fill={'#323f5a'}
            d='M427,0H449.7a1.81,1.81,0,0,1,1.81,1.81v88a1.81,1.81,0,0,1-1.81,1.81H427a1.81,1.81,0,0,1-1.8-1.81v-88A1.82,1.82,0,0,1,427,0Z'
          />
          <path
            fill={'#323f5a'}
            d='M495.06,113.09q-7.31,5-18,5a36.49,36.49,0,0,1-12-1.9,38.18,38.18,0,0,1-9.45-4.94,1.83,1.83,0,0,1-.48-2.45l9-14.45a1.8,1.8,0,0,1,2.47-.58l.13.09a14.79,14.79,0,0,0,3.18,1.88,12.65,12.65,0,0,0,4.83.92q5.47,0,7.69-4.58l1.31-2.48L455.45,23a1.82,1.82,0,0,1,1-2.38,1.86,1.86,0,0,1,.7-.14h23a1.81,1.81,0,0,1,1.7,1.2L495,58.87a1.8,1.8,0,0,0,3.43-.09l11.2-37a1.8,1.8,0,0,1,1.73-1.29h22.23a1.81,1.81,0,0,1,1.81,1.81,1.75,1.75,0,0,1-.13.65L506.53,97.61q-4.17,10.45-11.47,15.48Z'
          />
          <path
            id='Path_18562'
            data-name='Path 18562'
            fill={'#e84a5d'}
            d='M61.13,57.17A14.2,14.2,0,1,1,76.57,44.33v0h0a14.19,14.19,0,0,1-14.1,12.89c-.45,0-.89,0-1.35-.07Z'
          />
          <path
            id='Path_18563'
            data-name='Path 18563'
            fill={'#fd405c'}
            d='M63.07,81A10.86,10.86,0,0,1,73.72,69.89h0a10.86,10.86,0,0,1,11,10.67h0A10.84,10.84,0,0,1,74.11,91.62h-.2A10.85,10.85,0,0,1,63.07,81Z'
          />
          <path
            id='Path_18564'
            data-name='Path 18564'
            fill={'#fd405c'}
            d='M64.57,11.63A9.58,9.58,0,0,1,71.87.23h0A9.58,9.58,0,0,1,76,19h0a9.55,9.55,0,0,1-11.37-7.28v0Z'
          />
          <path
            id='Path_18565'
            data-name='Path 18565'
            fill={'#dd415d'}
            d='M35.35,21.13a6.93,6.93,0,1,1,7.81,5.93h0a7.46,7.46,0,0,1-.95.06,6.93,6.93,0,0,1-6.86-6Z'
          />
          <path
            id='Path_18566'
            data-name='Path 18566'
            fill={'#d93e5c'}
            d='M25.3,67.1A11.76,11.76,0,0,1,35.91,54.29h0A11.75,11.75,0,0,1,48.69,64.93h0a11.76,11.76,0,0,1-10.62,12.8h0a10.05,10.05,0,0,1-1.09.05A11.75,11.75,0,0,1,25.29,67.1Z'
          />
          <path
            id='Path_18567'
            data-name='Path 18567'
            fill={'#d0375c'}
            d='M17.92,40.3a7.8,7.8,0,0,1,7.65-7.95h0A7.8,7.8,0,0,1,33.52,40h0A7.8,7.8,0,0,1,25.85,48h-.14a7.8,7.8,0,0,1-7.79-7.69Z'
          />
          <path
            id='Path_18568'
            data-name='Path 18568'
            fill={'#c62f5b'}
            d='M5.85,12.2a7.09,7.09,0,0,1,7-7.22h0a7.1,7.1,0,0,1,.25,14.19h-.13a7.09,7.09,0,0,1-7.07-7Z'
          />
          <path
            id='Union_2'
            data-name='Union 2'
            fill={'#c52e5c'}
            d='M.05,55.31A5.7,5.7,0,0,1,4.91,48.9h0a5.68,5.68,0,0,1,6.39,4.85h0a5.68,5.68,0,0,1-4.85,6.41,5.12,5.12,0,0,1-.78.06A5.69,5.69,0,0,1,.05,55.31Z'
          />
          <path
            id='Path_18535'
            data-name='Path 18535'
            fill={'#323f5a'}
            d='M548.28,23h-2.85V20.51h8.63V23h-2.85v7.34h-2.93Zm6.59-2.49h4.28l1.47,4.58,1.46-4.58h4.29v9.83h-2.93V23.82l-1.51,4.7H559.3l-1.5-4.7v6.52h-2.93Z'
          />
        </g>
      </g>
    </svg>
  ),
});
