import { createIcon } from '@chakra-ui/icon';

export const IntersectIcon = createIcon({
  displayName: 'IntersectIcon',
  viewBox: '0 0 64 64',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='64'
      width='64'
      viewBox='0 0 64 64'
    >
      <title>path intersect</title>
      <g fill='#ffffff' className='nc-icon-wrapper'>
        <path
          fill='#ffffff'
          d='M62,1H22c-0.6,0-1,0.4-1,1v19H2c-0.6,0-1,0.4-1,1v40c0,0.6,0.4,1,1,1h40c0.6,0,1-0.4,1-1V43h19 c0.6,0,1-0.4,1-1V2C63,1.4,62.6,1,62,1z M41,61H3V23h18v19c0,0.6,0.4,1,1,1h19V61z M61,41H43V22c0-0.6-0.4-1-1-1H23V3h38V41z'
        />
      </g>
    </svg>
  ),
});
