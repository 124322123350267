import { createIcon } from '@chakra-ui/icon';

export const SelectedLibraryIcon = createIcon({
  displayName: 'SelectedLibraryIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      width='24'
      viewBox='0 0 24 24'
    >
      <g fill='#ffffff' className='nc-icon-wrapper'>
        <path
          fill='#ffffff'
          d='M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M17.736,16.677 C17.542,16.887,17.274,17,17,17c-0.125,0-0.251-0.023-0.372-0.071L12,15.077l-4.628,1.852c-0.385,0.154-0.826,0.054-1.107-0.251 c-0.281-0.306-0.344-0.753-0.159-1.125l5-10c0.339-0.678,1.45-0.678,1.789,0l5,10C18.08,15.924,18.017,16.372,17.736,16.677z'
        />
      </g>
    </svg>
  ),
});

export const LibraryIcon = createIcon({
  displayName: 'LibraryIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      height='24'
      width='24'
      viewBox='0 0 24 24'
    >
      <g
        strokeLinecap='square'
        strokeWidth='2'
        fill='none'
        stroke='#D4DAE1'
        strokeLinejoin='miter'
        className='nc-icon-wrapper'
        strokeMiterlimit='10'
      >
        <circle cx='12' cy='12' r='11' />
        <polygon points=' 12,6 17,16 12,14 7,16 ' stroke='#D4DAE1' />
      </g>
    </svg>
  ),
});
