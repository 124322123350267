import {
  initialApolloState,
  InitialApolloState,
} from 'app/src/apollo/rootReactiveVariables';
import { FilterIndices } from '../types/filterTypes';

export type GlobalFilterReducerState = InitialApolloState['globalFilter'];

export enum GlobalFilterActionType {
  RESET = 1,
  UPDATE_OR_ADD,
  OVERRIDE,
}

export type GlobalFilterAction =
  | {
      type: GlobalFilterActionType.RESET;
      payload: GlobalFilterReducerState;
    }
  | {
      type: GlobalFilterActionType.UPDATE_OR_ADD;
      payload: {
        index: FilterIndices;
        value: any;
      };
    }
  | {
      type: GlobalFilterActionType.OVERRIDE;
      payload: GlobalFilterReducerState;
    };

const initialState: GlobalFilterReducerState = initialApolloState.globalFilter;

export const globalFilterReducer = (
  state = initialState,
  action: GlobalFilterAction,
): GlobalFilterReducerState => {
  const { type, payload } = action;

  switch (type) {
    case GlobalFilterActionType.RESET:
      return payload as GlobalFilterReducerState;
    case GlobalFilterActionType.UPDATE_OR_ADD:
      const { index, value } = payload as {
        index: FilterIndices;
        value: any;
      };
      return state ? { ...state, [index]: value } : null;
    case GlobalFilterActionType.OVERRIDE:
      state = payload as GlobalFilterReducerState;
      return state;
  }
};
