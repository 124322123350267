import {ReactNode} from 'react';
import classnames from 'classnames';
import {fontWeightOptionsLiteral, sizeOptionsLiteral, themeOptionsLiteral,} from 'shared/types/coreTypes.d';

type TxtType = {
  align?: 'left' | 'center' | 'right';
  as?:
    | 'div'
    | 'span'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'b'
    | 'strong'
    | 'i'
    | 'em'
    | 'mark'
    | 'small'
    | 'del'
    | 'ins'
    | 'sub'
    | 'sup';
  block?: boolean;
  children?: ReactNode;
  className?: string;
  size?: sizeOptionsLiteral;
  text?: string;
  theme?: themeOptionsLiteral;
  uppercase?: boolean;
  weight?: fontWeightOptionsLiteral;
};

function Txt({
  align,
  as: Comp = 'span',
  block,
  children,
  className,
  size,
  text,
  theme,
  uppercase,
  weight,
}: TxtType) {
  const txtClass = classnames({
    'c-text': true,
    [`c-text--${theme}`]: theme,
    'h-block': block,
    'h-text-uppercase': uppercase,
    [`h-text-align-${align}`]: align,
    [`h-text-size-${size}`]: size,
    [`h-text-weight-${weight}`]: weight,
    ...(typeof className === 'string' ? { [className]: className } : {}),
  });

  return <Comp className={txtClass}>{children || text}</Comp>;
}

export default Txt;
