import {createIcon} from '@chakra-ui/icon';

export const NewAccountIcon = createIcon({
  displayName: 'NewAccount',
  viewBox: '0 0 21 20',
  path: (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          d='M1.16699 4.1665H19.5003'
          stroke='#152F45'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.16699 15.833H19.5003'
          stroke='#152F45'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.29532 12.9163H4.73783L2.22283 8.50384C2.22283 8.50384 2.26283 9.88717 2.26283 12.9163H1.16699V7.08301H2.71283L5.22366 11.4522C5.22366 11.4522 5.19616 8.06884 5.19199 7.08301H6.29532V12.9163Z'
          fill='#152F45'
        />
        <path
          d='M11.1363 12.9163H7.80713V7.08301H11.1363V8.09634H9.03296V9.37717H10.9905V10.3905H9.03296V11.8947H11.1363V12.9163Z'
          fill='#152F45'
        />
        <path
          d='M18.0297 12.9163H16.6339L15.8506 9.85217C15.7931 9.63551 15.6039 8.73551 15.5822 8.48717C15.5489 8.78884 15.3839 9.59551 15.3172 9.85967L14.5381 12.9163H13.1464L11.6714 7.08301H12.8772L13.6164 10.2672C13.7456 10.8547 13.8389 11.3638 13.8972 11.7955C14.0139 11.0247 14.6914 8.28134 15.0081 7.08301H16.1672L17.0097 10.3505C17.0947 10.6888 17.2256 11.4313 17.2747 11.7947C17.3297 11.3622 17.4689 10.6313 17.5597 10.2663L18.2947 7.08301H19.5006L18.0297 12.9163Z'
          fill='#152F45'
        />
      </g>
      <defs>
        <clipPath id='clip0_761:2780'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0.333496)'
          />
        </clipPath>
      </defs>
    </svg>
  ),
});
