import {
  Divider,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import _filter from 'lodash/filter';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'shared/components/Core';
import { AcquisitionIcon } from 'shared/components/Core/Icon/handShake';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import {
  useGetAccountQuery,
  useGetAccountWithPredictionsLazyQuery
} from 'shared/graphql/generatedApiTypes';
import { RetentionIcon } from '../../../../../shared/components/Core/Icon/stayHome';
import {
  PredictionColumnTooltip,
  RecommendetionTooltip
} from '../../TableSection/renderers/AccountDrawerItemRenderer';
import {
  RecommendationJSON,
  TooltipText
} from '../../TopCompanyList/components/TopCompanyList';
import { SecondBulletPointRender } from '../constants/bulletPointJSON';
import { LikelihoodBox, predictionTooltip } from '../constants/gaugeBins';
import { PredictionDescription } from '../constants/multiPredctionJSON';

export default function MultipleProductPredictionsSection() {
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const FirstBulletPointCommanLabel = [
    'is',
    'As Likely',
    'to purchase',
    'in the next 90 days',
    'as',
    'than',
    'the typical company.',
  ];
  const SecondBulletPointCommanLabel = [
    'would increase the likelihood of',
    'remaining',
    'becoming',
    'a',
    'customer.',
  ];
  const ActionToolTipDesctiption =
    'The recommended action you could take that would increase the likelihood of a company becoming a customer.';
  const [currentPredictions, setCurrentPredictions] = useState<any>([]);
  const [predictionLoading, setPredictionLoading] = useState<boolean>(true);
  // prediction types
  enum PredictionForType {
    Acquisition = 'ACQUISITION',
    Retention = 'RETENTION',
    Volume = 'VOLUME',
    Fit = 'FIT',
  }
  // Fetch data for Account
  const { data } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });
  const accountInfo = data?.account;

  // Fetch details for RecommendationSection
  const [getAccountWithPrediction] = useGetAccountWithPredictionsLazyQuery({
    variables: {
      id: parseInt(selectedAccountId),
      predictionTypes: [
        PredictionForType?.Acquisition,
        PredictionForType?.Retention,
        PredictionForType?.Volume,
      ],
      predictionNames: [],
      recentPredictionDate: moment().format('YYYY-MM-DD'),
    },
  });

  useEffect(() => {
    (async () => {
      setPredictionLoading(true);
      await getAccountWithPrediction().then((res) => {
        let predictionData = res?.data;
        if (predictionData?.account) {
          let currentData = predictionData?.account?.currentPredictions;
          let tempCurrentData: any = [];
          const capitalize = (s) =>
            s && s[0].toUpperCase() + s.slice(1).toLowerCase();
          if (currentData !== undefined && currentData !== null) {
            currentData.forEach((data) => {
              if (data?.calculatedFor?.name !== 'any') {
                tempCurrentData.push({
                  name: data?.calculatedFor?.name,
                  level: data?.level,
                  type: capitalize(data?.calculatedFor?.type),
                  currentValue: data?.currentValue,
                  recommendedPriorityAction:
                    data?.recommendedPriorityAction !== null
                      ? data?.recommendedPriorityAction
                      : '-',
                });
              }
            });
            let maxCurrentValue = Math.max(
              ...tempCurrentData.map((object) => {
                return object.currentValue;
              }),
            );
            let maxObj = _filter(
              tempCurrentData,
              (v) => v?.currentValue === maxCurrentValue,
            );
            setCurrentPredictions([maxObj[0]]);
          }
        }
      });
      setPredictionLoading(false);
    })();
  }, [selectedAccountId]);

  return (
    <Card isLoading={predictionLoading} style={{ minHeight: '100%' }}>
      {!accountInfo ? (
        <Text>
          Error getting account data for this account, please refresh to try
          again.
        </Text>
      ) : (
        <>
          <div style={{ fontSize: 24, fontWeight: 'bold' }}>
            Top Recommendation
          </div>
          {/* <div className='progress-icon'>{progressIcon} Pursue</div> */}
          {currentPredictions.length > 0 && (
            <div style={{ marginTop: '20px' }}>
              <TableContainer>
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th className='recommendation-header'>PRODUCT</Th>
                      <Th className='recommendation-header'>
                        <span>
                          {RecommendetionTooltip(
                            TooltipText.recommendation,
                            'ACTION',
                          )}
                        </span>
                      </Th>
                      <Th className='recommendation-header'>
                        <span>STRATEGY</span>
                      </Th>
                      <Th className='recommendation-header'>
                        <span>
                          {PredictionColumnTooltip(
                            predictionTooltip[currentPredictions[0]['type']],
                            currentPredictions[0]['type'],
                            'LIKELIHOOD',
                          )}
                        </span>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {currentPredictions[0]?.name !== 'any' && (
                      <Tr>
                        <Td>{currentPredictions[0]?.name}</Td>
                        <Td style={{ fontStyle: 'italic' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {
                              RecommendationJSON[
                                currentPredictions[0]?.recommendedPriorityAction
                              ]
                            }
                          </div>
                        </Td>
                        <Td>
                          {currentPredictions[0]?.type === 'Retention' ? (
                            <div className='strategy-detials'>
                              <div>{RetentionIcon}</div>

                              <span>{currentPredictions[0]?.type}</span>
                            </div>
                          ) : (
                            <div className='strategy-detials'>
                              <div>{AcquisitionIcon}</div>

                              <span>{currentPredictions[0]?.type} </span>
                            </div>
                          )}
                        </Td>
                        <Td>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {LikelihoodBox(
                              currentPredictions[0],
                              currentPredictions[0]?.type === 'Retention'
                                ? 'retentionPredictions'
                                : 'acquisitionPredictions',
                            )}
                          </div>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          )}
          <Divider />
          <div style={{ marginTop: 10, padding: '12px 24px' }}>
            <span style={{ fontSize: 16, fontWeight: 'bold', color: 'black' }}>
              Why
            </span>
            <div style={{ padding: 12 }}>
              <div style={{ display: 'flex', gap: 10 }}>
                <div>
                  <Icon
                    viewBox='0 0 200 200'
                    color='#D1065A'
                    style={{ fontSize: 10 }}
                  >
                    <path
                      fill='currentColor'
                      d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                    />
                  </Icon>
                </div>
                <span style={{ color: '#4a5872' }}>
                  {accountInfo?.accountName} {FirstBulletPointCommanLabel[0]}{' '}
                  {currentPredictions[0]?.level !== undefined &&
                  currentPredictions[0].level === 2 ? (
                    <b style={{ color: '#323F5A' }}>
                      {' '}
                      {FirstBulletPointCommanLabel[1]}
                    </b>
                  ) : (
                    PredictionDescription?.[currentPredictions[0]?.type]?.[
                      currentPredictions[0]?.name
                    ]?.[currentPredictions[0]?.level]
                  )}{' '}
                  {FirstBulletPointCommanLabel[2]}{' '}
                  <b style={{ color: '#323F5A' }}>
                    {' '}
                    {currentPredictions[0]?.name}
                  </b>{' '}
                  {FirstBulletPointCommanLabel[3]}{' '}
                  {currentPredictions[0]?.level !== undefined &&
                  currentPredictions[0]?.level === 2
                    ? FirstBulletPointCommanLabel[4]
                    : FirstBulletPointCommanLabel[5]}{' '}
                  {FirstBulletPointCommanLabel[6]}
                </span>
              </div>
              <div style={{ display: 'flex', gap: 10 }}>
                <div>
                  <Icon
                    viewBox='0 0 200 200'
                    color='#D1065A'
                    style={{ fontSize: 10 }}
                  >
                    <path
                      fill='currentColor'
                      d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                    />
                  </Icon>
                </div>
                <span style={{ color: '#4a5872' }}>
                  {
                    SecondBulletPointRender[
                      currentPredictions[0]?.recommendedPriorityAction
                    ]
                  }{' '}
                  {SecondBulletPointCommanLabel[0]} {accountInfo?.accountName}{' '}
                  {currentPredictions[0]?.type === 'Acquisition'
                    ? SecondBulletPointCommanLabel[2]
                    : SecondBulletPointCommanLabel[1]}{' '}
                  {SecondBulletPointCommanLabel[3]}{' '}
                  <b style={{ color: '#323F5A' }}>
                    {' '}
                    {currentPredictions[0]?.name}
                  </b>{' '}
                  {SecondBulletPointCommanLabel[4]}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  );
}
