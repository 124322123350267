import { useContext, useEffect, useState } from 'react';
import { AnalyticsAttrType } from 'shared/types/coreTypes.d';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { FilterIndices } from '../../types/filterTypes';
import { sortGroupedSelectFilterOptions } from '../../helpers/sortSelectFilterOptions';
import { getFilterItemConsts } from '../../helpers/getFilterItemConsts';
import {
  ApiGetAggregateDataQuery,
  useGetAggregateDataWithFilterVarLazyQuery,
} from 'shared/graphql/generatedApiTypes';
import { adaptDictAsString } from '../../helpers/adaptFilterForQuery';
import _omit from 'lodash/omit';
import Select, { components } from 'react-select';
import { Icon, Inline } from '../../../../../../shared/components/Core';
import {
  getCheckboxSelectFilterItemStyles,
  getGroupedSelectedOptionsInitialState,
} from '../../helpers/checkboxSelectFilterItem';
import _isEqual from 'lodash/isEqual';

export type CheckboxSelectFilterItemProps = AnalyticsAttrType & {
  aggregateData: ApiGetAggregateDataQuery['aggregateData'];
  filterIndices: FilterIndices[];
  itemSource?: 'panel' | 'bar' | 'wideMenu';
  tableAccessor: string;
  title?: string;
  width?: string;
};

export const GroupedCheckboxSelectFilterItem = ({
  aggregateData,
  analyticsAttr = '',
  filterIndices,
  itemSource,
  tableAccessor,
  title,
}: CheckboxSelectFilterItemProps) => {
  const initialItems = filterIndices.map((filterIndex) => {
    return {
      [filterIndex]: (aggregateData && aggregateData[filterIndex]) ?? [],
    };
  });
  const {
    data: initialAggregatedAccountsData,
    loading: initialAggregatedAccountsDataLoading,
    globalFilter,
    updateFilter,
    fullAggregateLoading,
  } = useContext(AggregateDataContext);

  const [
    getAggregateAccountsDataWithFilterVar,
    {
      data: filterItemAggregatedAccountsData,
      loading: filterItemAggregatedAccountsDataLoading,
    },
  ] = useGetAggregateDataWithFilterVarLazyQuery();

  const { aggregatedItemsAccountData, areFiltersApplied, isSelectedDisabled } =
    getFilterItemConsts({
      filterIndices,
      filterItemAggregatedAccountsData,
      filterItemAggregatedAccountsDataLoading,
      globalFilter,
      initialAggregatedAccountsData,
      initialAggregatedAccountsDataLoading:
        initialAggregatedAccountsDataLoading || fullAggregateLoading,
    });

  const itemsSorted = sortGroupedSelectFilterOptions({
    aggregatedItemsAccountData,
    areFiltersApplied,
    filterIndices,
    initialItems,
    tableAccessor,
  });
  const initialSelectedOptions = getGroupedSelectedOptionsInitialState({
    filterIndices,
    filterItemAggregatedAccountsData,
    globalFilter,
    tableAccessor,
  });

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    initialSelectedOptions,
  );

  useEffect(() => {
    getAggregateAccountsDataWithFilterVar({
      variables: {
        filterString:
          adaptDictAsString(_omit(globalFilter, filterIndices)) ?? '',
        type: 'account',
      },
    });
    const updatedSelectedOptions: string[] = [];
    filterIndices.forEach((filterIndex) => {
      if (globalFilter && globalFilter[filterIndex]) {
        updatedSelectedOptions.push(
          ...globalFilter[filterIndex].map((item) => `${item}:${filterIndex}`),
        );
      }
    });
    setSelectedOptions(updatedSelectedOptions);
  }, [globalFilter, filterIndices]);

  const addFilterLocally = (itemValue) => {
    if (selectedOptions.find((existingItem) => itemValue === existingItem)) {
      setSelectedOptions((prevState) =>
        prevState.filter((existingItem) => itemValue !== existingItem),
      );
    } else {
      setSelectedOptions((prevState) => [...prevState, itemValue]);
    }
  };

  const handleMenuClose = () => {
    if (!_isEqual(initialSelectedOptions, selectedOptions)) {
      // Bucket these back by key
      const bucketsForUpdate = selectedOptions
        .map((option) => ({
          value: option.split(':')[0],
          filterIndex: option.split(':')[1] as FilterIndices,
        }))
        .reduce((accum, current) => {
          const existingIndex = accum.find(
            (item) => item.filterIndex === current.filterIndex,
          );
          if (existingIndex) {
            existingIndex.value.push(current.value);
          } else {
            accum.push({
              filterIndex: current.filterIndex,
              value: [current.value],
            });
          }
          return accum;
        }, [] as Array<{ filterIndex: FilterIndices; value: string[] }>);
      bucketsForUpdate.forEach((bucket) => {
        updateFilter({
          index: bucket.filterIndex,
          value: bucket.value,
        });
      });
    }
  };

  const getIconName = (value: string) =>
    selectedOptions.find((existingItem) => value === existingItem)
      ? 'checkbox-checked'
      : 'checkbox';

  // A reference for this https://julietonyekaoha.medium.com/customizing-reusable-react-select-menu-options-components-7642190caa73
  // https://github.com/esotericjules/custom-react-select-menu-component/blob/master/src/custom-select-menu/custom-select.jsx
  const Option = (props) => (
    <components.Option
      {...props}
      analytics-attr={`${analyticsAttr}-${props.data.value}`}
    >
      <Inline>
        <span className={'h-flex h-flex-align-items-center'}>
          <span className={'flag-icon-wrapper'}>
            <Icon
              color={'blue'}
              name={getIconName(props.data.value)}
              size={'md'}
            />
          </span>
          <span className={'h-ml-md'}>{props.data.label}</span>
        </span>
      </Inline>
    </components.Option>
  );

  return (
    <Select
      analytics-attr={`${analyticsAttr}-checkbox-select`}
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      components={{ Option }}
      isSearchable={true}
      isDisabled={isSelectedDisabled}
      isMulti={true}
      maxMenuHeight={200}
      onChange={(selectedOptions, { action }) => {
        action === 'select-option' &&
          addFilterLocally(selectedOptions[0].value);
      }}
      onInputChange={(newValue, { action }) => {
        action === 'menu-close' && handleMenuClose();
      }}
      options={itemsSorted}
      placeholder={isSelectedDisabled ? 'Loading...' : title}
      styles={getCheckboxSelectFilterItemStyles(itemSource)}
      value={null}
    />
  );
};
