import { useEffect, useMemo, useState } from 'react';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import {
  showingFilterPanelVar,
  windowWidthVar,
} from 'app/src/apollo/rootReactiveVariables';
import {
  GetAccountGroupDocument,
  UpdateAccountGroupDescriptorsDocument,
  UpdateAccountGroupIsHiddenDocument,
  useAddAccountGroupMutation,
  useGetAllAccountGroupsQuery,
} from 'shared/graphql/generatedApiTypes';
import { groupAccountGroups } from '../../GlobalFilters/helpers/groupAccountGroups';
import { Button, Input, RadioInput, Table } from 'shared/components/Core';
import {
  Box,
  Flex,
  HStack,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from '@chakra-ui/icons';
import { cleanStringCase } from 'shared/helpers/formatHelpers';
import {
  fullNavPanelWidthInt,
  navbarSizeInt,
  navbarSizeStr,
} from 'app/src/constants/navbarSize';
import { BannerPopover } from 'app/src/components/TableSection/components/BannerPopover';
import {
  useInput,
  UseInputHookReturnType,
  UseInputHookStringValueTypes,
} from 'shared/hooks/inputHook';
import _difference from 'lodash/difference';
import _intersection from 'lodash/intersection';
import DualListBox from 'react-dual-listbox';
import { mergeListsHelper } from 'app/src/components/GlobalFilters/helpers/setOperationsOnLists';
import { MergeIcon } from 'shared/components/Core/Icon/merge';
import { IntersectIcon } from 'shared/components/Core/Icon/intersect';
import { TrimIcon } from 'shared/components/Core/Icon/trim';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { GroupFilterOptions } from 'app/src/components/ListsAndFilters/types/GroupFilterOptions';
import { useSavedGroupColumns } from 'app/src/components/ListsAndFilters/hooks/useSavedGroupColumns';
import { KlearlyUserType } from 'shared/firebase/types/klearlyUserType';

export const SavedLists = () => {
  const apolloClient = useApolloClient();
  const { data: accountGroupData, refetch: refetchAccountGroups } =
    useGetAllAccountGroupsQuery();
  const [hasJustSaved, setHasJustSaved] = useState<boolean>(false);

  const [selectedTableFilter, setSelectedTableFilter] =
    useState<GroupFilterOptions>(GroupFilterOptions.Your);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [excludedTrimSelections, setExcludedTrimSelections] = useState<any[]>(
    [],
  );
  const editInteractionModal = useDisclosure();
  const mergeInteractionModal = useDisclosure();
  const intersectInteractionModal = useDisclosure();
  const trimInteractionModal = useDisclosure();
  const deleteInteractionModal = useDisclosure();
  const toast = useToast();
  const windowWidth = useReactiveVar(windowWidthVar);
  const filterPanelIsShowing = useReactiveVar(showingFilterPanelVar);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [addAccountGroup] = useAddAccountGroupMutation();
  const tableColumns = useSavedGroupColumns({
    accountGroupData,
    groupType: 'list',
  });
  const klearlyUser: KlearlyUserType | null = AuthManager.klearlyUser || null;

  useEffect(() => {
    refetchAccountGroups();
  }, []);

  useEffect(() => {
    setSelectedRows([]);
    setHasJustSaved(true);
  }, [selectedTableFilter]);

  const tableData = useMemo(() => {
    const { lists } = groupAccountGroups({
      accountGroups: accountGroupData?.accountGroups ?? [],
      klearlyUser,
      showOnlyLoggedInUsersGroups:
        selectedTableFilter === GroupFilterOptions.Your,
    });
    return lists ?? [];
  }, [accountGroupData, selectedTableFilter]);

  const actionBarIsShowing = useMemo(
    () => !!selectedRows.length,
    [selectedRows],
  );

  const bannerWidth = useMemo(() => {
    if (filterPanelIsShowing) {
      return windowWidth - navbarSizeInt - fullNavPanelWidthInt;
    }
    return windowWidth - navbarSizeInt;
  }, [windowWidth, filterPanelIsShowing]);

  const bannerLeft = useMemo(() => {
    if (filterPanelIsShowing) {
      return `${navbarSizeInt + fullNavPanelWidthInt}px`;
    }
    return navbarSizeStr;
  }, [filterPanelIsShowing]);

  const {
    bind: bindAccountGroupTitle,
    setValue: setAccountGroupTitle,
    value: accountGroupTitle,
  } = useInput('', {
    label: 'Name',
    placeholder: 'Name new list',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;
  const {
    bind: bindAccountGroupDescription,
    setValue: setAccountGroupDescription,
    value: accountGroupDescription,
  } = useInput('', {
    label: 'Description',
    placeholder: 'Describe new list',
  }) as UseInputHookReturnType & UseInputHookStringValueTypes;

  const editList = async () => {
    try {
      setAccountGroupDescription('');
      setAccountGroupTitle('');
      editInteractionModal.onClose();
      await apolloClient.mutate({
        mutation: UpdateAccountGroupDescriptorsDocument,
        variables: {
          input: {
            title: accountGroupTitle.trim(),
            description: accountGroupDescription.trim(),
            id: selectedRows[0],
          },
        },
      });
      setHasJustSaved(true);
      setSelectedRows([]);
      await refetchAccountGroups();
    } catch (err) {
      toast({
        title: `Error editing list; please check your connection & try again`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  };

  const mergeLists = async () => {
    setIsLoading(true);
    const uniqueListOfIds = await mergeListsHelper(selectedRows, apolloClient);
    await saveNewList(uniqueListOfIds);
    setIsLoading(false);
    mergeInteractionModal.onClose();
  };

  const intersectLists = async () => {
    setIsLoading(true);
    const promises = selectedRows.map(async (selectedRow) => {
      const accountGroup = await apolloClient.query({
        query: GetAccountGroupDocument,
        variables: { id: selectedRow },
      });
      if (accountGroup.data?.accountGroup?.accounts) {
        return accountGroup.data.accountGroup.accounts
          .filter((account) => account?.id)
          .map((account) => account!.id);
      }
    });
    const resolvedListOfArrays = await Promise.all(promises);
    const intersectedList = _intersection(...resolvedListOfArrays) as number[];
    await saveNewList(intersectedList);
    setIsLoading(false);
    intersectInteractionModal.onClose();
  };

  const trimLists = async () => {
    setIsLoading(true);
    // Get the selected rows that aren't in the excluded rows - these are the included ones
    const includedRows = _difference(selectedRows, excludedTrimSelections);
    // Merge all the included arrays and separately all the excluded arrays
    const includedIds = await mergeListsHelper(includedRows, apolloClient);
    const excludedIds = await mergeListsHelper(
      excludedTrimSelections,
      apolloClient,
    );
    // Then get the difference of those
    const trimmedList = _difference(includedIds, excludedIds);
    await saveNewList(trimmedList);
    setIsLoading(false);
    trimInteractionModal.onClose();
  };

  const deleteLists = async () => {
    try {
      setIsLoading(true);
      const promises = selectedRows.map(async (id) => {
        await apolloClient.mutate({
          mutation: UpdateAccountGroupIsHiddenDocument,
          variables: {
            input: {
              isHidden: true,
              id,
            },
          },
        });
      });
      await Promise.all(promises);
      setSelectedRows([]);
      setHasJustSaved(true);
      await refetchAccountGroups();
      setIsLoading(false);
      deleteInteractionModal.onClose();
    } catch (err) {
      setIsLoading(false);
      toast({
        title: `Error deleting; please check your connection & try again`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  };

  const saveNewList = async (accountIds: number[]) => {
    try {
      await addAccountGroup({
        variables: {
          input: {
            accountIds,
            description: accountGroupDescription,
            title: accountGroupTitle,
            filterString: undefined,
          },
        },
      });
      setAccountGroupDescription('');
      setAccountGroupTitle('');
      setHasJustSaved(true);
      await refetchAccountGroups();
      toast({
        title: `List successfully created`,
        status: 'success',
        position: 'top',
        isClosable: true,
      });
    } catch {
      setIsLoading(false);
      mergeInteractionModal.onClose();
      intersectInteractionModal.onClose();
      trimInteractionModal.onClose();
      toast({
        title: `Error creating list; please check your connection & try again`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  };

  const currentUserDoesNotOwnAllSelected = useMemo(
    () =>
      !!selectedRows.filter((id) =>
        tableData.find((group) => group?.id === id && !group?.isLoggedInUsers),
      )?.length,
    [selectedRows],
  );

  return (
    <>
      <Flex justify={'space-between'}>
        <Text fontWeight={'bold'}>
          {tableData.length === 1 ? '1 List' : `${tableData.length} Lists`}
        </Text>
        <Spacer />
        <RadioInput
          horizontal
          onChange={(e) =>
            setSelectedTableFilter(e.target.value as GroupFilterOptions)
          }
          options={Object.values(GroupFilterOptions).map((item) => ({
            value: item,
            label: cleanStringCase(item),
          }))}
          value={selectedTableFilter}
        />
        <Spacer />
      </Flex>
      <Table
        columns={tableColumns}
        //@ts-ignore
        data={tableData && Object.keys(tableData) ? tableData : []}
        initialDefaultSortByColumnId={'title'}
        perPage={30}
        hasJustSaved={hasJustSaved}
        setHasJustSaved={setHasJustSaved}
        initialSelectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        tableVPadding={'sm'}
        classname='library-list-table'
      />
      {actionBarIsShowing && (
        <Box
          left={bannerLeft}
          pos={'fixed'}
          w={bannerWidth}
          bottom={0}
          color={'brand.white'}
        >
          <Flex
            align={'center'}
            justify={'space-between'}
            bg={'brand.klarityBlue'}
            minH={'56px'}
            p={3}
          >
            <Text ml={4} mr={2}>
              {selectedRows.length} item(s) selected
            </Text>
            <Flex maxW={'60%'} gap={2} flexWrap={'wrap'} justify={'center'}>
              {selectedRows.length === 1 && (
                <BannerPopover
                  interactionModal={editInteractionModal}
                  popoverTitle={'Edit name & description'}
                  body={
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        editList();
                      }}
                    >
                      <Input {...bindAccountGroupTitle} />
                      <Input {...bindAccountGroupDescription} />
                      <Button
                        type={'submit'}
                        text={'Save Changes'}
                        isLoading={isLoading}
                      />
                    </form>
                  }
                >
                  <Button
                    isDisabled={currentUserDoesNotOwnAllSelected}
                    text={'Edit'}
                    leftIcon={<EditIcon />}
                    variant={'banner'}
                    onClick={() => {
                      setAccountGroupTitle(
                        tableData.find((item) => item?.id === selectedRows[0])
                          ?.title ?? '',
                      );
                      setAccountGroupDescription(
                        tableData.find((item) => item?.id === selectedRows[0])
                          ?.description ?? '',
                      );
                      editInteractionModal.onOpen();
                    }}
                    fontSize={['sm', null, null, 'md', null]}
                  />
                </BannerPopover>
              )}
              {selectedRows.length > 1 && (
                <>
                  <BannerPopover
                    interactionModal={mergeInteractionModal}
                    popoverTitle={'Merge lists'}
                    body={
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          mergeLists();
                        }}
                      >
                        <Text mb={4}>
                          Merging will combine all companies in selected lists
                          into one new list and remove duplicates.
                        </Text>
                        <Input {...bindAccountGroupTitle} />
                        <Input {...bindAccountGroupDescription} />
                        <Button
                          type={'submit'}
                          text={'Save New List'}
                          isLoading={isLoading}
                        />
                      </form>
                    }
                  >
                    <Button
                      leftIcon={<MergeIcon />}
                      variant={'banner'}
                      text={'Merge'}
                      onClick={mergeInteractionModal.onOpen}
                      fontSize={['sm', null, null, 'md', null]}
                    />
                  </BannerPopover>
                  <BannerPopover
                    interactionModal={intersectInteractionModal}
                    popoverTitle={'Intersect lists'}
                    body={
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          intersectLists();
                        }}
                      >
                        <Text mb={4}>
                          Intersecting will combine all companies that are in
                          both lists into one new list.
                        </Text>
                        <Input {...bindAccountGroupTitle} />
                        <Input {...bindAccountGroupDescription} />
                        <Button
                          type={'submit'}
                          text={'Save New List'}
                          isLoading={isLoading}
                        />
                      </form>
                    }
                  >
                    <Button
                      text={'Intersect'}
                      leftIcon={<IntersectIcon />}
                      variant={'banner'}
                      onClick={intersectInteractionModal.onOpen}
                      fontSize={['sm', null, null, 'md', null]}
                    />
                  </BannerPopover>
                  <BannerPopover
                    popoverTitle={'Trim lists'}
                    interactionModal={trimInteractionModal}
                    body={
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          trimLists();
                        }}
                      >
                        <Text mb={4}>
                          Trimming will select all companies from the included
                          lists and exclude all companies from the excluded
                          lists, then create a new list.
                        </Text>
                        <Flex justify={'space-around'}>
                          <Text fontWeight={'bold'}>Include</Text>
                          <Text fontWeight={'bold'}>Exclude</Text>
                        </Flex>
                        <DualListBox
                          options={selectedRows.map((rowId) => ({
                            label:
                              tableData.find((item) => item?.id === rowId)
                                ?.title ?? '',
                            value: rowId,
                          }))}
                          selected={excludedTrimSelections}
                          onChange={setExcludedTrimSelections}
                          icons={{
                            moveLeft: <ChevronLeftIcon boxSize={4} />,
                            moveAllLeft: <ArrowLeftIcon boxSize={2} />,
                            moveRight: <ChevronRightIcon boxSize={4} />,
                            moveAllRight: [<ArrowRightIcon boxSize={2} />],
                            moveDown: <ChevronDownIcon boxSize={4} />,
                            moveUp: <ChevronUpIcon boxSize={4} />,
                            moveTop: <ArrowUpIcon boxSize={2} />,
                            moveBottom: <ArrowDownIcon boxSize={2} />,
                          }}
                        />
                        <Box mt={4}>
                          <Input {...bindAccountGroupTitle} />
                          <Input {...bindAccountGroupDescription} />
                          <Button
                            isDisabled={
                              !selectedRows ||
                              !excludedTrimSelections ||
                              !accountGroupTitle
                            }
                            type={'submit'}
                            text={'Save New List'}
                            isLoading={isLoading}
                          />
                        </Box>
                      </form>
                    }
                  >
                    <Button
                      text={'Trim'}
                      onClick={trimInteractionModal.onOpen}
                      leftIcon={<TrimIcon />}
                      variant={'banner'}
                      fontSize={['sm', null, null, 'md', null]}
                    />
                  </BannerPopover>
                </>
              )}
              <BannerPopover
                popoverTitle={'Delete Lists'}
                interactionModal={deleteInteractionModal}
                body={
                  <>
                    <Text mb={4}>
                      {selectedRows.length === 1
                        ? 'Are you sure you want to delete this list?'
                        : 'Are you sure you' +
                          ' want to' +
                          ' delete these lists?'}
                    </Text>
                    <HStack justify={'flex-end'} spacing={2}>
                      <Button
                        onClick={() => {
                          setSelectedRows([]);
                          deleteInteractionModal.onClose();
                        }}
                        variant={'actionOutline'}
                        text={'Cancel'}
                      />
                      <Button
                        onClick={deleteLists}
                        text={'Confirm'}
                        isLoading={isLoading}
                      />
                    </HStack>
                  </>
                }
              >
                <Button
                  text={'Delete'}
                  onClick={deleteInteractionModal.onOpen}
                  isDisabled={currentUserDoesNotOwnAllSelected}
                  variant={'banner'}
                  leftIcon={<DeleteIcon />}
                  fontSize={['sm', null, null, 'md', null]}
                />
              </BannerPopover>
            </Flex>
            <Button
              mr={24}
              ml={6}
              leftIcon={<CloseIcon boxSize={3} />}
              text={'Cancel'}
              onClick={() => {
                setSelectedRows([]);
                setHasJustSaved(true);
              }}
            />
          </Flex>
        </Box>
      )}
    </>
  );
};
