import { useMemo, useRef } from 'react';
import { LabFirestoreValuesType } from '../types/firestoreValuesTypes';
import { Card, IconBadge } from '../../../../../shared/components/Core';
import { useFetchLabCardData } from 'app/src/hooks/useFetchLabCardData';
import { LabChart } from './LabCharts';
import { LabCardLikeFavoriteIcons, LabCardShareIcons } from './LabCardFeedback';
import { LabsHeroDisplay } from '../types/labApiTypes';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { Flex, Text } from '@chakra-ui/react';
import { cleanStringCase } from 'shared/helpers/formatHelpers';
import { styleNumber } from 'app/src/components/Lab/helpers/styleNumber';
import { UserSettingsDocument } from 'shared/firebase/documents/UserSettingsDocument';
import { LabCardFeedbackDocument } from 'shared/firebase/documents/LabCardFeedbackDocument';
import AuthManager from 'shared/firebase/classes/AuthManager';
import IoMagnifyingGlass from 'shared/components/Core/Icon/io-magnifying-glass';
import Tooltip from 'shared/components/Core/Tooltip';

type LabCardProps = {
  lab: LabFirestoreValuesType;
  section: string;
  userSettingsDocument: UserSettingsDocument | null;
  labCardFeedbackDocument: LabCardFeedbackDocument | null;
  labCardFeedbackError: any;
  userSettingsError: any;
  toggleFeedbackLikeFlag: (id: string, isLiked: boolean) => void;
  toggleFavoriteFlag: (id: string, isFavorited: boolean) => void;
};

const LabCard = ({
  lab,
  section = '',
  userSettingsDocument,
  userSettingsError,
  labCardFeedbackError,
  labCardFeedbackDocument,
  toggleFavoriteFlag,
  toggleFeedbackLikeFlag,
}: LabCardProps) => {
  const { id: labCardId } = lab;
  const { data: labFromApi, loading, error } = useFetchLabCardData(labCardId);
  const componentRef = useRef<HTMLDivElement>(null);
  const cardIdPrefix = 'lab-card-';
  const cardId = `${cardIdPrefix}${labCardId}`;
  const contentWrapperId = 'content-wrapper';
  const contentHeaderWrapperId = 'content-header-wrapper';
  const contentChartWrapperId = 'content-chart-wrapper';
  const firebaseUserUID = AuthManager.currentUser?.uid ?? '';

  const isFavorited = useMemo(() => {
    if (userSettingsDocument?.data && firebaseUserUID) {
      return userSettingsDocument.data?.labCardFavorites?.includes(labCardId);
    }
    return false;
  }, [userSettingsDocument, userSettingsError]);

  const isLiked = useMemo(() => {
    if (labCardFeedbackDocument?.data) {
      const selectedCard = Object.keys(labCardFeedbackDocument.data).find(
        (key) => key === labCardId,
      );
      if (selectedCard && firebaseUserUID) {
        return labCardFeedbackDocument.data[selectedCard].likes.includes(
          firebaseUserUID,
        );
      }
    }
    return false;
  }, [labCardFeedbackDocument, labCardFeedbackError]);

  const numberOfComments = useMemo(() => {
    if (labCardFeedbackDocument?.data) {
      const selectedCard = Object.keys(labCardFeedbackDocument.data).find(
        (key) => key === labCardId,
      );
      if (selectedCard && firebaseUserUID) {
        return labCardFeedbackDocument.data[selectedCard].comments?.length ?? 0;
      }
    }
    return 0;
  }, [labCardFeedbackDocument, labCardFeedbackError]);

  return (
    <Card isLoading={loading} loadingText={'Loading...'} minH={600}>
      <div className={'lab-card'} id={cardId}>
        {isFavorited && section === 'Favorites' && (
          <div className={'h-p-md h-border-bottom lab-card-header'}>
            <Text as={'i'}>{cleanStringCase(lab.LabsTags)}</Text>
          </div>
        )}
        <div
          className={'h-p-md h-border-bottom lab-card-body'}
          id={contentWrapperId}
          ref={componentRef}
        >
          <div id={contentHeaderWrapperId}>
            <Flex justify={'space-between'}>
              <Text color={'brand.black'} fontSize={'lg'} fontWeight={'bold'}>
                {labFromApi?.question ?? ''}
              </Text>
              <Link to={`${ROUTES.LABS_TAB.path}/${labCardId}`}>
                <Tooltip content={'Magnify'} hideIcon={true}>
                  <IconBadge
                    badgeProps={{ position: 'relative' }}
                    iconComponent={
                      <IoMagnifyingGlass boxSize={6} ml={-3} mt={2} />
                    }
                    text={numberOfComments.toString()}
                  />
                </Tooltip>
              </Link>
            </Flex>
            {labFromApi?.hero.value && (
              <Text color={'brand.black'} mt={'md'} fontSize={'3xl'}>
                {styleNumber(
                  labFromApi.hero.value,
                  labFromApi.hero.displayType === LabsHeroDisplay.currency,
                  labFromApi.hero.displayType === LabsHeroDisplay.percent,
                )}
              </Text>
            )}
            {!!labFromApi?.hero.context.length && (
              <Text color={'brand.black'} mt={'2xs'}>
                {labFromApi.hero.context}
              </Text>
            )}
          </div>
          <div id={contentChartWrapperId}>
            {labFromApi && lab && (
              <LabChart
                columns={labFromApi.columns}
                context={labFromApi.hero.context}
                displayType={labFromApi.chartType.default}
                isAnswerTypePercent={
                  labFromApi.hero.displayType === LabsHeroDisplay.percent
                }
                isChartClickable={false}
                isDollars={
                  labFromApi.hero.displayType === LabsHeroDisplay.currency
                }
                isSmallerViewport={true}
                monoColor={lab.monoColor}
                rows={labFromApi.rows}
                sortOrder={'asc'}
                totalNumber={labFromApi.hero.value}
              />
            )}
            {!!error && (
              <Text align={'center'} color={'brand.crimson'}>
                {'Error loading this chart. Please refresh and try again.'}
              </Text>
            )}
          </div>
        </div>
        <div className={'h-p-md lab-card-footer'}>
          <LabCardLikeFavoriteIcons
            isLiked={isLiked}
            isFavorited={isFavorited}
            toggleFavoriteFlag={() =>
              toggleFavoriteFlag(labCardId, isFavorited)
            }
            toggleFeedbackLikeFlag={() =>
              toggleFeedbackLikeFlag(labCardId, isLiked)
            }
            middleComponent={
              <LabCardShareIcons
                componentRef={componentRef}
                customContentIdPrefix={cardIdPrefix}
                customURLPrefix={window.location.href}
                id={labCardId}
                question={labFromApi?.question ?? ''}
              />
            }
          />
        </div>
      </div>
    </Card>
  );
};

export { LabCard };
