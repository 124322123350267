import { Card, Table } from 'shared/components/Core';
import SectionHeader from 'shared/components/Core/SectionHeader';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { SingleAccountQuickStat } from 'app/src/components/Account/components/sav/overview/accountSummary/singleAccountQuickStat/SingleAccountQuickStat';
import { IconRendererIcons } from 'shared/renderers/IconRenderer';
import { QuickStatFieldOptions } from 'shared/types/quickStatFieldOptions';
import { DataFieldFormat } from 'shared/renderers/DataFieldRenderer';
import {
  ApiGetAccountSignalsQuery,
  ApiSignal,
  Maybe,
  useGetAccountSignalsLazyQuery,
} from 'shared/graphql/generatedApiTypes';
import { useLocation, useParams } from 'react-router-dom';
import {
  cleanStringCase,
  formatDateString,
} from 'shared/helpers/formatHelpers';
import { isAfter, isBefore, parseISO, subDays } from 'date-fns';

const FULL_2_WEEKS = 14;
const FULL_1_WEEK = 7;

export type SignalsOverviewProps = {};

type SignalOverviewTableValue = {
  signalTypeComparison: {
    max: number;
    lastSeven: number;
    prevSeven: number;
    signalType: string;
  };
  lastSevenDays: string;
  prevSevenDays: string;
};

export const SignalsOverview = ({}: SignalsOverviewProps) => {
  const [getAccountSignals, { loading }] = useGetAccountSignalsLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const [inboundData, setInboundData] = useState<SignalOverviewTableValue[]>(
    [],
  );
  const [topInbound, setTopInbound] = useState<{
    inboundTopThisWeek: string;
    inboundTopLastWeek: string;
  }>({ inboundTopThisWeek: '', inboundTopLastWeek: '' });
  const [outboundData, setOutboundData] = useState<SignalOverviewTableValue[]>(
    [],
  );
  const [topOutbound, setTopOutbound] = useState<{
    outboundTopThisWeek: string;
    outboundTopLastWeek: string;
  }>({ outboundTopThisWeek: '', outboundTopLastWeek: '' });

  const { pathname } = useLocation();

  useEffect(() => {
    (async () => {
      if (getAccountSignals && !loading) {
        const { data } = await getAccountSignals({
          variables: {
            accountId: parseInt(selectedAccountId),
            startDate: formatDateString(subDays(new Date(), FULL_2_WEEKS)),
            endDate: formatDateString(new Date()),
          },
        });
        if (data?.accountSignals) {
          const {
            inboundChartValues,
            inboundTopThisWeek,
            inboundTopLastWeek,
            outboundChartValues,
            outboundTopLastWeek,
            outboundTopThisWeek,
          } = getStatsForOverview(data.accountSignals);
          setInboundData(inboundChartValues);
          setTopInbound({ inboundTopLastWeek, inboundTopThisWeek });
          setOutboundData(outboundChartValues);
          setTopOutbound({ outboundTopThisWeek, outboundTopLastWeek });
        }
      }
    })();
  }, [getAccountSignals, pathname]);

  const inboundTableColumns = useMemo(() => {
    return [
      {
        Header: 'Inbound Signals',
        columns: [
          {
            Cell: ({ value }) => (
              <Box w={'120px'} h={12} bg={'transparent'}>
                <Text ml={1}>{value.signalType}</Text>
                <Box
                  h={2}
                  w={`${Math.min((120 * value.lastSeven) / value.max, 120)}px`}
                  bg={'brand.klarityBlue'}
                  pos={'absolute'}
                  mt={4}
                />
                <Box
                  h={6}
                  mt={2}
                  w={`${Math.min((120 * value.prevSeven) / value.max, 120)}px`}
                  bg={'brand.klarityBlue'}
                  opacity={0.25}
                  pos={'absolute'}
                />
              </Box>
            ),
            Header: 'Signal Type',
            accessor: 'signalTypeComparison',
            disableSortBy: true,
          },
          {
            Cell: ({ value }) => value,
            Header: 'Last 7 Days',
            accessor: 'lastSevenDays',
            disableSortBy: true,
          },
          {
            Cell: ({ value }) => value,
            Header: 'Vs Prev 7 Days',
            accessor: 'prevSevenDays',
            disableSortBy: true,
          },
        ],
      },
    ];
  }, []);

  const outboundTableColumns = useMemo(() => {
    return [
      {
        Header: 'Outbound Signals',
        columns: [
          {
            Cell: ({ value }) => (
              <Box w={'120px'} h={12} bg={'transparent'}>
                <Text ml={1}>{value.signalType}</Text>
                <Box
                  h={2}
                  w={`${Math.min((120 * value.lastSeven) / value.max, 120)}px`}
                  bg={'brand.dustyOrange'}
                  pos={'absolute'}
                  mt={4}
                />
                <Box
                  h={6}
                  mt={2}
                  w={`${Math.min((120 * value.prevSeven) / value.max, 120)}px`}
                  bg={'brand.dustyOrange'}
                  opacity={0.25}
                  pos={'absolute'}
                />
              </Box>
            ),
            Header: 'Signal Type',
            accessor: 'signalTypeComparison',
            disableSortBy: true,
          },
          {
            Cell: ({ value }) => value,
            Header: 'Last 7 Days',
            accessor: 'lastSevenDays',
            disableSortBy: true,
          },
          {
            Cell: ({ value }) => value,
            Header: 'Vs Prev 7 Days',
            accessor: 'prevSevenDays',
            disableSortBy: true,
          },
        ],
      },
    ];
  }, []);

  return (
    <Card style={{ marginTop: '0px' }}>
      <SectionHeader
        title={'Signals Overview'}
        showBackgroundColor={false}
        addHeaderLeftMargin
      />
      <SimpleGrid columns={[1, null, null, 2, null]} gap={2}>
        <Box borderWidth={1} borderColor={'brand.gray-200'} py={1} px={4}>
          <Text fontWeight={'bold'} fontSize={'lg'}>
            Inbound Responses
          </Text>
          <SimpleGrid columns={[1, null, 2, null, null]} gap={2}>
            <SingleAccountQuickStat
              title={'Engagement Intensity'}
              center={{
                leftIcon: IconRendererIcons.TREND,
                dataField: QuickStatFieldOptions.signalResponseIntensity,
                dataFieldFormat: DataFieldFormat.TEXT,
              }}
              bottom={{
                leftText: 'Last response - ',
                dataField: QuickStatFieldOptions.lastInboundSignal,
                dataFieldFormat: DataFieldFormat.DATE,
              }}
              bgColor={'#F5F8F9'}
            />
            <SingleAccountQuickStat
              title={'Top Signal Type - This Week'}
              center={{
                fallbackValue: cleanStringCase(topInbound.inboundTopThisWeek),
              }}
              bottom={{
                fallbackValue: topInbound.inboundTopLastWeek,
              }}
              bgColor={'#F5F8F9'}
            />
          </SimpleGrid>
          <Box mt={2}>
            <Table columns={inboundTableColumns} data={inboundData} />
          </Box>{' '}
        </Box>
        <Box borderWidth={1} borderColor={'brand.gray-200'} py={1} px={4}>
          <Text fontWeight={'bold'} fontSize={'lg'}>
            Outbound Activities
          </Text>
          <SimpleGrid columns={[1, null, 2, null, null]} gap={2}>
            <SingleAccountQuickStat
              title={'Outreach Intensity'}
              center={{
                leftIcon: IconRendererIcons.TREND,
                dataField: QuickStatFieldOptions.signalActivityIntensity,
                dataFieldFormat: DataFieldFormat.TEXT,
              }}
              bottom={{
                leftText: 'Last activity - ',
                dataField: QuickStatFieldOptions.lastOutboundSignal,
                dataFieldFormat: DataFieldFormat.DATE,
              }}
              bgColor={'#F5F8F9'}
            />
            <SingleAccountQuickStat
              title={'Top Signal Type - This Week'}
              center={{
                fallbackValue: cleanStringCase(topOutbound.outboundTopThisWeek),
              }}
              bottom={{
                fallbackValue: topOutbound.outboundTopLastWeek,
              }}
              bgColor={'#F5F8F9'}
            />
          </SimpleGrid>
          <Box mt={2}>
            <Table columns={outboundTableColumns} data={outboundData} />
          </Box>
        </Box>
      </SimpleGrid>
    </Card>
  );
};

type SignalsGroupedByType = Maybe<
  Pick<
    ApiSignal,
    | 'id'
    | 'crmAccountId'
    | 'type'
    | 'typeDetail'
    | 'action'
    | 'direction'
    | 'source'
    | 'occurredAt'
    | 'topic'
    | 'topicDetail'
    | 'quantity'
    | 'value'
    | 'groupKey'
  >
>[];
const getStatsForOverview = (
  signalsArr: NonNullable<ApiGetAccountSignalsQuery['accountSignals']>,
) => {
  // top signal type this week and its rank last week (inbound)

  const inboundSignalsThisWeek = signalsArr.filter(
    (signal) =>
      signal?.direction === 'response' &&
      isAfter(parseISO(signal.occurredAt), subDays(new Date(), FULL_1_WEEK)),
  );
  const inboundSignalsLastWeek = signalsArr.filter(
    (signal) =>
      signal?.direction === 'response' &&
      isBefore(parseISO(signal.occurredAt), subDays(new Date(), FULL_1_WEEK)),
  );
  const outboundSignalsThisWeek = signalsArr.filter(
    (signal) =>
      signal?.direction === 'activity' &&
      isAfter(parseISO(signal.occurredAt), subDays(new Date(), FULL_1_WEEK)),
  );
  const outboundSignalsLastWeek = signalsArr.filter(
    (signal) =>
      signal?.direction === 'activity' &&
      isBefore(parseISO(signal.occurredAt), subDays(new Date(), FULL_1_WEEK)),
  );

  const {
    chartValues: inboundChartValues,
    topThisWeek: inboundTopThisWeek,
    topLastWeek: inboundTopLastWeek,
  } = groupThisAndLastWeekSignals(
    inboundSignalsThisWeek,
    inboundSignalsLastWeek,
  );
  const {
    chartValues: outboundChartValues,
    topThisWeek: outboundTopThisWeek,
    topLastWeek: outboundTopLastWeek,
  } = groupThisAndLastWeekSignals(
    outboundSignalsThisWeek,
    outboundSignalsLastWeek,
  );

  return {
    inboundChartValues,
    inboundTopThisWeek,
    inboundTopLastWeek,
    outboundChartValues,
    outboundTopThisWeek,
    outboundTopLastWeek,
  };
};

const differenceWithSign = (val1: number, val2: number) => {
  if (val1 === val2) {
    return '0';
  }
  if (val1 > val2) {
    return `+${val1 - val2}`;
  }
  return `${val1 - val2}`;
};

const groupThisAndLastWeekSignals = (
  thisWeekArr: NonNullable<ApiGetAccountSignalsQuery['accountSignals']>,
  lastWeekArr: NonNullable<ApiGetAccountSignalsQuery['accountSignals']>,
) => {
  const initialThisWeekGroupedByType: {
    [key: string]: SignalsGroupedByType;
  } = {};
  const initialLastWeekGroupedByType: {
    [key: string]: SignalsGroupedByType;
  } = {};
  const groupThisWeekByType = thisWeekArr.reduce((accum, current) => {
    if (current && accum[current.type]) {
      accum[current.type].push(current);
    } else if (current) {
      accum[current.type] = [current];
    }
    return accum;
  }, initialThisWeekGroupedByType);

  const groupLastWeekByType = lastWeekArr.reduce((accum, current) => {
    if (current && accum[current.type]) {
      accum[current.type].push(current);
    } else if (current) {
      accum[current.type] = [current];
    }
    return accum;
  }, initialLastWeekGroupedByType);

  let topThisWeek = { count: 0, label: 'No Signals' };
  Object.keys(groupThisWeekByType).forEach((signalType) => {
    if (groupThisWeekByType[signalType].length > topThisWeek.count) {
      topThisWeek.count = groupThisWeekByType[signalType].length;
      topThisWeek.label = signalType;
    }
  });

  // last week's rank of the top from this week
  let topLastWeek = 'Not ranked last week';
  if (groupLastWeekByType[topThisWeek.label]) {
    const arrayOfLastWeek = Object.keys(groupLastWeekByType)
      .map((signalType) => ({
        type: signalType,
        count: groupLastWeekByType[signalType].length,
      }))
      // order descending
      .sort((a, b) => (a.count > b.count ? -1 : 1));
    // find the index position and add 1 to get the rank now that it's sorted
    const rankLastWeek =
      arrayOfLastWeek.findIndex((item) => item.type === topThisWeek.label) + 1;
    if (rankLastWeek) {
      topLastWeek = `Ranked #${rankLastWeek} last week`;
    }
  }
  // counts for this week grouped by signal type and ordered by count with change from last week
  const bulletChart = Object.keys(groupThisWeekByType)
    .map((signalType) => ({
      type: signalType,
      count: groupThisWeekByType[signalType].length,
      lastWeekCount: groupLastWeekByType[signalType]
        ? groupLastWeekByType[signalType].length
        : 0,
    }))
    .sort((a, b) => (a.count > b.count ? 1 : -1));

  const max = bulletChart.length
    ? Math.max(
        bulletChart[bulletChart.length - 1].count,
        bulletChart[bulletChart.length - 1].lastWeekCount,
      )
    : 1;

  const chartValues = bulletChart.map((item) => ({
    signalTypeComparison: {
      max,
      lastSeven: item.count,
      prevSeven: item.lastWeekCount,
      signalType: cleanStringCase(item.type),
    },
    lastSevenDays: item.count.toLocaleString(),
    prevSevenDays: item.lastWeekCount
      ? differenceWithSign(item.count, item.lastWeekCount)
      : `+${item.count}`,
  }));

  return {
    // only do top 10
    chartValues: chartValues.slice(0, 10),
    topThisWeek: topThisWeek.label,
    topLastWeek,
  };
};
