import { appliedFiltersHeightVar } from 'app/src/apollo/rootReactiveVariables';
import { useContext, useEffect, useRef } from 'react';
import { MainFilterBar } from './filterBar/MainFilterBar';
import { FilterStatsSummaryBar } from './filterBar/FilterStatsSummaryBar';
import { Box } from '@chakra-ui/react';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { AppliedSavedGroupsBar } from 'app/src/components/GlobalFilters/components/filterBar/AppliedSavedGroupsBar';
import { UserSettingsDocument } from 'shared/firebase/documents/UserSettingsDocument';

export const GlobalFilters = ({
  getUserSettingsValue,
  getUserSettingsExecute,
  getUserSettingsPending,
}: {
  getUserSettingsValue: UserSettingsDocument | null;
  getUserSettingsExecute: () => void;
  getUserSettingsPending: boolean;
}) => {
  const appliedFiltersBarRef = useRef<HTMLDivElement>(null);
  const { globalFilter } = useContext(AggregateDataContext);

  // get the height so that we can adjust the top of the content below it
  useEffect(() => {
    if (appliedFiltersBarRef.current) {
      appliedFiltersHeightVar(appliedFiltersBarRef.current.clientHeight);
    }
  }, [globalFilter, appliedFiltersBarRef.current?.clientHeight]);

  return (
    <Box ref={appliedFiltersBarRef} pos={'sticky'} top={16}>
      <MainFilterBar
        getUserSettingsValue={getUserSettingsValue}
        getUserSettingsExecute={getUserSettingsExecute}
        getUserSettingsPending={getUserSettingsPending}
      />
      <AppliedSavedGroupsBar />
      <FilterStatsSummaryBar />
    </Box>
  );
};
