import {
  AnalyticsAttrType,
  ExtraAnalyticsAttrType,
} from 'shared/types/coreTypes.d';
import { Button, ButtonProps, Text, TextProps } from '@chakra-ui/react';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import { forwardRef, LegacyRef } from 'react';

type StyledButtonProps = AnalyticsAttrType &
  ExtraAnalyticsAttrType &
  ButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    asLink?: boolean;
    fontProps?: TextProps;
    onClick?: () => void;
    text?: any;
    type?: 'submit' | 'reset' | 'button';
  };

const StyledButton = forwardRef(
  (
    {
      analyticsAttr = undefined,
      asLink = false,
      extraAnalyticsAttr,
      fontProps,
      onClick = () => {},
      text,
      ...props
    }: StyledButtonProps,
    ref,
  ) => {
    let analyticsStr = cleanAnalyticsStringList([
      analyticsAttr,
      extraAnalyticsAttr,
      'button',
    ]);
    return (
      <Button
        as={asLink ? 'a' : undefined}
        analytics-attr={analyticsStr}
        onClick={onClick}
        ref={ref as LegacyRef<HTMLButtonElement> | undefined}
        {...props}
        _hover={props.isDisabled ? { boxShadow: 'none' } : undefined}
      >
        <Text {...fontProps}>{text}</Text>
      </Button>
    );
  },
);

export default StyledButton;
