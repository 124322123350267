import { Flex, Text } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { IconRenderer, IconRendererIcons } from 'shared/renderers/IconRenderer';
import { cleanStringCase } from 'shared/helpers/formatHelpers';

type TableLegendType = { leftIcon: IconRendererIcons; value: string };

export const TableLegend = ({
  legendContent,
}: {
  legendContent: TableLegendType[];
}) => {
  return legendContent.length ? (
    <>
      {legendContent.map((item) => (
        <Flex align={'center'} gridGap={1} key={item.value}>
          {IconRenderer({ iconName: item.leftIcon, value: item.value })}
          <Text fontSize={'sm'} mr={4}>
            {capitalize(cleanStringCase(item.value))}
          </Text>
        </Flex>
      ))}
    </>
  ) : null;
};
