import {
  CalendarDateType,
  CalendarRangeFilterItemProps,
} from '../../../types/calendarTypes';
import { Button, CalendarRange } from 'shared/components/Core';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

export const CalendarRangeBetweenFilterItem = ({
  endDate,
  setEndDate,
  setStartDate,
  startDate,
  itemSource,
}: CalendarRangeFilterItemProps) =>
  itemSource === 'bar' ? (
    <CalendarRange
      analytics-attr={'calendar-range-select'}
      endDate={endDate || undefined}
      setEndDate={(date: CalendarDateType) => setEndDate(date)}
      setStartDate={(date: CalendarDateType) => setStartDate(date)}
      startDate={startDate || undefined}
    />
  ) : (
    <Popover placement={'right'}>
      <PopoverTrigger>
        <Button text={'Open Calendar'} />
      </PopoverTrigger>
      <Portal>
        <PopoverContent minW={'650px'}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Date Range</PopoverHeader>
          <PopoverBody>
            {' '}
            <CalendarRange
              analytics-attr={'calendar-range-select'}
              endDate={endDate || undefined}
              setEndDate={(date: CalendarDateType) => setEndDate(date)}
              setStartDate={(date: CalendarDateType) => setStartDate(date)}
              startDate={startDate || undefined}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
