import React from 'react';

export const SecondBulletPointRender = {
  q_call: (
    <span>
      Making a{" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        call
      </b>
    </span>
  ),
  q_call_action_item_sample: (
    <span>
      Providing a{" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        product sample
      </b>
    </span>
  ),
  q_call_basic_training: (
    <span>
      Making a{" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        basic training call
      </b>
    </span>
  ),
  q_call_business_review: (
    <span>
      Making a {" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        business review call
      </b>
    </span>
  ),
  q_call_clinical_discussion: (
    <span>
      Making a {" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        clinical discussion call
      </b>
    </span>
  ),
  q_call_marketing_discussion: (
    <span>
      Making a{" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        marketing discussion call
      </b>
    </span>
  ),
  q_call_product_event: (
    <span>
      Making a{" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        product event call
      </b>
    </span>
  ),
  q_event_training: (
    <span>
      Holding a{" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        training event
      </b>
    </span>
  ),
  q_order_line_item_promo_material: (
    <span>
      Providing a{" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        promo item
      </b>
    </span>
  ),
  q_order_line_item_sample: (
    <span>
      Providing a{" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        product sample
      </b>
    </span>
  ),
  q_promo_code: (
    <span>
      Providing a{" "}
      <b
        style={{
          color: '#323F5A',
        }}
      >
        promo code
      </b>
    </span>
  ),
  '-': (
    <span>
      Doing {" "}<b style={{ color: '#323F5A' }}> nothing</b>
    </span>
  ),
};
