import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  SmallAddIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';
import {
  Box,
  ButtonGroup,
  Checkbox,
  Flex,
  FormLabel,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Select as PageSelect,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import _uniqWith from 'lodash/uniqWith';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Select, { components } from 'react-select';
import {
  Button,
  Icon,
  Inline,
  RadioInput,
  Select as OptionSelect,
} from 'shared/components/Core';
import { SortIcon } from 'shared/components/Core/Icon/sort';
import { ApiSort } from 'shared/graphql/generatedApiTypes';
import { getCheckboxSelectFilterItemStyles } from '../GlobalFilters/helpers/checkboxSelectFilterItem';
import { ComaConverter } from '../TableSection/renderers/AccountDrawerItemRenderer';
import { ActionTray } from './components/ActionTray';

export default function PeopleListTable({
  allContacts,
  selectIds,
  setSelectIds,
  allSelected,
  setAllSelected,
  pageInfo,
  hanlePagination,
  setPageInfo,
  pageDropdown,
  section,
  currentSortsInForm,
  setCurrentSortsInForm,
  setCurrentSorts,
  setSelectedEngagementStatus,
  setSelectedOutreachStatus,
  selectedEngagementStatus,
  selectedOutreachStatus,
  filterVariable,
}) {
  const [hide, setHide] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);

  let analyticsAttr = '';
  const applySorts = () => {
    let sort = currentSortsInForm.map((selectedSort) => ({
      field: selectedSort.id,
      direction: selectedSort.desc ? ApiSort.Desc : ApiSort.Asc,
    }));
    setCurrentSorts(sort);
    setOpen(false);
  };
  const [selectedEngagementStatusOption, setSelectedEngagementStatusOption] =
    React.useState<any>([]);
  const [selectedOutrachOption, setSelectedOutrachOption] = React.useState<any>(
    [],
  );

  // disable this if the same column is selected twice
  const disableSortApply = !!currentSortsInForm.find(
    (sort1, index1) =>
      sort1.id &&
      currentSortsInForm.find(
        (sort2, index2) => sort2 && sort2.id === sort1.id && index1 !== index2,
      ),
  );

  const history = useHistory();

  const sortdata = [
    {
      label: 'Name',
      value: 'FIRST_NAME',
    },
    {
      label: 'Company',
      value: 'COMPANY_NAME',
    },
    {
      label: 'Email',
      value: 'EMAIL',
    },
    {
      label: 'Phone',
      value: 'PHONE',
    },
    {
      label: 'Job title',
      value: 'TITLE',
    },
    {
      label: 'Engagement status',
      value: 'ENGAGEMENT_STATUS',
    },
    {
      label: 'Outreach status',
      value: 'OUTREACH_STATUS',
    },
  ];
  const onClose = () => {
    setOpen(false);
  };

  const EngagementStatusOption = [
    { label: 'Disengaged', value: 'disengaged' },
    { label: 'Disengaging', value: 'disengaging' },
    { label: 'Unengaged', value: 'unengaged' },
    { label: 'Engaged', value: 'engaged' },
  ];
  const OutreachStatusOption = [
    { label: 'Activated', value: 'activated' },
    { label: 'Deactivated', value: 'deactivated' },
    { label: 'Deactivating', value: 'deactivating' },
    { label: 'Inactivated', value: 'inactivated' },
  ];

  const getIconName = (value: string) => {
    let Engagementdata = EngagementStatusOption.filter(
      (item) => item?.value === value,
    );
    let Outreachdata = OutreachStatusOption.filter(
      (item) => item?.value === value,
    );

    if (Outreachdata.length > 0) {
      return selectedOutrachOption.find(
        (existingItem) => value === existingItem,
      ) !== undefined
        ? 'checkbox-checked'
        : 'checkbox';
    }
    if (Engagementdata.length > 0) {
      return selectedEngagementStatusOption.find(
        (existingItem) => value === existingItem,
      ) !== undefined
        ? 'checkbox-checked'
        : 'checkbox';
    }
    return selectedEngagementStatusOption.find(
      (existingItem) => value === existingItem,
    ) !== undefined
      ? 'checkbox-checked'
      : 'checkbox';
  };

  const Option = (props) => (
    <components.Option
      {...props}
      analytics-attr={`${analyticsAttr}-${props.data.value}`}
    >
      <Inline>
        <span className={'h-flex h-flex-align-items-center'}>
          <span className={'flag-icon-wrapper'}>
            <Icon
              color={'blue'}
              name={getIconName(props.data.value)}
              size={'md'}
            />
          </span>
          <span className={'h-ml-md'}>{props.data.label}</span>
        </span>
      </Inline>
    </components.Option>
  );

  const optionselect = (value, selectedOption, setSelectedOption) => {
    if (
      selectedOption.find((existingItem) => value === existingItem) !==
      undefined
    ) {
      setSelectedOption((prevState) =>
        prevState.filter((existingItem) => value !== existingItem),
      );
    } else {
      setSelectedOption((prevState) => [...prevState, value]);
    }
  };

  const sendEngagementData = () => {
    if (
      selectedEngagementStatusOption.length !== selectedEngagementStatus.length
    ) {
      setPageInfo({
        ...pageInfo,
        from: 1,
        page: 1,
        to: pageInfo.pageSize,
      });
    }
    setSelectedEngagementStatus(selectedEngagementStatusOption);
  };

  const setOutreachData = () => {
    if (selectedOutrachOption.length !== selectedOutreachStatus.length) {
      setPageInfo({
        ...pageInfo,
        from: 1,
        page: 1,
        to: pageInfo.pageSize,
      });
    }
    setSelectedOutreachStatus(selectedOutrachOption);
  };

  return (
    <>
      <div className='contacts'>
        {(selectedEngagementStatus.length > 0 ||
          selectedOutreachStatus.length > 0) && (
          <div style={{ display: 'flex' }}>
            <span
              style={{
                color: 'rgb(60, 157, 253)',
                cursor: 'pointer',
                marginTop: '3px',
              }}
              onClick={() => {
                setSelectedEngagementStatus([]);
                setSelectedEngagementStatusOption([]);
                setSelectedOutreachStatus([]);
                setSelectedOutrachOption([]);
                setPageInfo({
                  ...pageInfo,
                  from: 1,
                });
              }}
            >
              Reset filters{' '}
            </span>
            <div style={{ marginLeft: '20px' }}>
              {selectedEngagementStatus.length > 0 ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '3px',
                      marginBottom: 10,
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontStyle: 'italic',
                      }}
                    >
                      Engagement Status :
                    </span>

                    {selectedEngagementStatus.map((item) => {
                      return (
                        <span
                          className='people-filter-btn'
                          onClick={(data) => {
                            setSelectedEngagementStatus(
                              selectedEngagementStatus.filter(
                                (data) => data !== item,
                              ),
                            );
                            setSelectedEngagementStatusOption(
                              selectedEngagementStatusOption.filter(
                                (data) => data !== item,
                              ),
                            );
                            setPageInfo({
                              ...pageInfo,
                              from: 1,
                            });
                          }}
                        >
                          {item}
                          <SmallCloseIcon />
                        </span>
                      );
                    })}
                  </div>
                </>
              ) : null}
              {selectedOutreachStatus.length > 0 ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '3px' }}
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontStyle: 'italic',
                    }}
                  >
                    Outreach Status :
                  </span>
                  {selectedOutreachStatus.map((item) => {
                    return (
                      <span
                        className='people-filter-btn'
                        onClick={(data) => {
                          setSelectedOutreachStatus(
                            selectedOutreachStatus.filter(
                              (data) => data !== item,
                            ),
                          );
                          setSelectedOutrachOption(
                            selectedOutrachOption.filter(
                              (data) => data !== item,
                            ),
                          );
                          setPageInfo({
                            ...pageInfo,
                            from: 1,
                          });
                        }}
                      >
                        {item}
                        <SmallCloseIcon />
                      </span>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        )}
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3
              style={{
                marginBottom: '28px',
                fontSize: '22px',
                color: '#143351',
              }}
            >
              {ComaConverter(pageInfo['totalEntries'])} people
            </h3>

            <div style={{ display: 'flex' }}>
              <div style={{ marginTop: '10px', gap: 5, display: 'flex' }}>
                <Select
                  analytics-attr={`${analyticsAttr}-checkbox-select`}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  components={{ Option }}
                  isSearchable={true}
                  isMulti={true}
                  maxMenuHeight={200}
                  onChange={(selectedOptions, { action }) => {
                    action === 'select-option' &&
                      optionselect(
                        selectedOptions[0].value,
                        selectedEngagementStatusOption,
                        setSelectedEngagementStatusOption,
                      );
                  }}
                  onInputChange={(newValue, { action }) => {
                    action === 'menu-close' && sendEngagementData();
                  }}
                  options={EngagementStatusOption}
                  placeholder={'Engagement Status'}
                  styles={getCheckboxSelectFilterItemStyles('bar')}
                  value={null}
                />
                <Select
                  analytics-attr={`${analyticsAttr}-checkbox-select`}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  components={{ Option }}
                  isSearchable={true}
                  isMulti={true}
                  maxMenuHeight={200}
                  onChange={(selectedOptions, { action }) => {
                    action === 'select-option' &&
                      optionselect(
                        selectedOptions[0].value,
                        selectedOutrachOption,
                        setSelectedOutrachOption,
                      );
                  }}
                  onInputChange={(newValue, { action }) => {
                    action === 'menu-close' && setOutreachData();
                  }}
                  options={OutreachStatusOption}
                  placeholder={'Outreach Status'}
                  styles={getCheckboxSelectFilterItemStyles('bar')}
                  value={null}
                />
              </div>
              <Popover onClose={onClose} isOpen={open}>
                <Flex alignItems={'center'} justify={'space-between'} mb={2}>
                  <Flex
                    align={'center'}
                    flexDirection={['column', null, null, 'row', null]}
                  >
                    <PopoverTrigger>
                      <Button
                        onClick={() => setOpen(true)}
                        leftIcon={<SortIcon />}
                        rightIcon={<ChevronDownIcon />}
                        text={`Sort by`}
                        maxW={40}
                        ml={4}
                        my={2}
                        borderWidth={2}
                        borderRadius={24}
                        borderColor={'#ECF2FA'}
                        fontWeight={'normal'}
                        maxWidth={140}
                        py={4}
                        variant={'actionOutline'}
                      />
                    </PopoverTrigger>
                  </Flex>
                </Flex>
                <PopoverContent right={16} w={500}>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    {currentSortsInForm.map((sort, index) => (
                      <Flex
                        alignItems={'center'}
                        h={20}
                        justify={'space-between'}
                        key={index}
                        mt={4}
                      >
                        <IconButton
                          aria-label={'remove-sort'}
                          icon={<CloseIcon boxSize={3} />}
                          mx={2}
                          mb={6}
                          onClick={() =>
                            setCurrentSortsInForm(
                              currentSortsInForm.filter(
                                (_, indexToFilter) => indexToFilter !== index,
                              ),
                            )
                          }
                          size={'sm'}
                          variant={'activeOutline'}
                        />
                        <Flex mx={4} alignItems={'center'}>
                          <FormLabel fontSize={'sm'} mb={6}>
                            {index === 0 ? 'Sort by:' : 'Then by:'}
                          </FormLabel>
                          <OptionSelect
                            extraFieldSetStyles={{
                              fontSize: '12px',
                              minWidth: '200px',
                            }}
                            options={_uniqWith(
                              sortdata,
                              (a) => a.label === 'Company',
                            )}
                            //@ts-ignore
                            onChange={(e: OnChangeSelectChangeEventType) => {
                              const copy = [...currentSortsInForm];
                              copy[index].id = e.target.value;
                              setCurrentSortsInForm(copy);
                            }}
                            //@ts-ignore
                            value={sort.id}
                          />
                        </Flex>
                        <Box pt={4}>
                          <RadioInput
                            onChange={(e) => {
                              const copy = [...currentSortsInForm];
                              copy[index].desc =
                                e.target.value === ApiSort.Desc;
                              setCurrentSortsInForm(copy);
                            }}
                            options={[
                              { label: 'A-Z', value: ApiSort.Asc },
                              { label: 'Z-A', value: ApiSort.Desc },
                            ]}
                            value={sort.desc ? ApiSort.Desc : ApiSort.Asc}
                          />
                        </Box>
                      </Flex>
                    ))}
                    <Box>
                      <Button
                        leftIcon={<SmallAddIcon />}
                        text={'Add Sort'}
                        p={2}
                        m={2}
                        maxH={8}
                        onClick={() =>
                          setCurrentSortsInForm([
                            ...currentSortsInForm,
                            { desc: false, id: '' },
                          ])
                        }
                      />
                    </Box>
                    <ButtonGroup
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        text={'Cancel'}
                        onClick={onClose}
                        variant={'actionOutline'}
                      />
                      <Button
                        text={'Apply'}
                        isDisabled={disableSortApply}
                        onClick={applySorts}
                      />
                    </ButtonGroup>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </div>
          </div>

          <TableContainer className='table-container'>
            <Table variant='simple'>
              <Thead top={0} zIndex={0}>
                <Tr>
                  <Th width='10px'>
                    <Checkbox
                      isChecked={allSelected}
                      onChange={(e) => {
                        if (e.target.checked) {
                          let temp: number[] = [];
                          allContacts?.map((data: { id: any }) => {
                            temp.push(data?.id);
                            return temp;
                          });
                          setAllSelected(true);
                          setSelectIds(temp);
                          setHide(true);
                        } else {
                          setAllSelected(false);
                          setSelectIds([]);
                        }
                      }}
                    ></Checkbox>
                  </Th>
                  {sortdata.map((data, index) => {
                    return (
                      <Th key={index}>
                        <div className='people-list-table-header'>
                          <span>{data?.label}</span>
                        </div>
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {allContacts !== undefined &&
                  allContacts.map((data: any, index) => {
                    return (
                      <Tr key={index}>
                        <Td>
                          <Checkbox
                            isChecked={selectIds.includes(data?.id)}
                            onChange={(e) => {
                              let temp: number[] = [...selectIds];
                              const index = temp.indexOf(data?.id);
                              if (e.target.checked) {
                                temp.push(data?.id);
                                setHide(true);
                              } else {
                                if (index > -1) {
                                  temp.splice(index, 1);
                                }
                                setAllSelected(false);
                              }
                              setSelectIds(temp);
                            }}
                          ></Checkbox>
                        </Td>
                        {section === 'peopleMenu' ? (
                          <>
                            <Td>{data['name']}</Td>
                            <Td
                              style={{
                                color: '#2B96FC',
                                fontWeight: 600,
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                              onClick={() =>
                                history.push(
                                  `companies/${data?.accounts[0]?.id}`,
                                )
                              }
                            >
                              {data?.companyName}
                            </Td>
                          </>
                        ) : (
                          <>
                            <Td>{data?.name}</Td>
                            <Td>{data?.companyName}</Td>
                          </>
                        )}
                        <Td>{data?.email}</Td>
                        <Td>{data?.phone}</Td>
                        <Td>{data?.title}</Td>
                        <Td>
                          {data?.engagementStatus !== ' '
                            ? data?.engagementStatus.charAt(0).toUpperCase() +
                              data?.engagementStatus.substr(1).toLowerCase()
                            : '-'}
                        </Td>
                        <Td>
                          {data?.outreachStatus !== ' '
                            ? data?.outreachStatus.charAt(0).toUpperCase() +
                              data?.outreachStatus.substr(1).toLowerCase()
                            : '-'}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
          <div className='table-pagination'>
            <div style={{ display: 'flex' }}>
              <span style={{ width: '160px', margin: 'auto' }}>
                Show rows per page{' '}
              </span>
              <div>
                <PageSelect
                  style={{ width: 89, marginLeft: '20px' }}
                  value={pageInfo.pageLimit}
                  onChange={(e) => {
                    if (
                      pageInfo?.totalEntries > 0 &&
                      pageInfo?.totalEntries <= parseInt(e.target.value)
                    ) {
                      setPageInfo({
                        ...pageInfo,
                        to: pageInfo?.totalEntries,
                        pageLimit: parseInt(e.target.value),
                        from: 1,
                        page: 1,
                      });
                    } else {
                      setPageInfo({
                        ...pageInfo,
                        to: parseInt(e.target.value),
                        pageLimit: parseInt(e.target.value),
                        from: 1,
                        page: 1,
                      });
                    }
                  }}
                >
                  {pageDropdown.map((data) => {
                    return <option>{data}</option>;
                  })}
                </PageSelect>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <span
                style={{ width: '160px', margin: 'auto', textAlign: 'center' }}
              >
                {pageInfo?.totalEntries !== 0
                  ? ComaConverter(pageInfo?.from)
                  : 0}
                -
                {pageInfo?.totalEntries !== null &&
                pageInfo?.totalEntries !== undefined &&
                pageInfo?.totalEntries <= parseInt(pageInfo.to)
                  ? ComaConverter(pageInfo['totalEntries'])
                  : ComaConverter(pageInfo.to)}{' '}
                of {ComaConverter(pageInfo['totalEntries'])}{' '}
              </span>
              <div style={{ display: 'flex', gap: 22 }}>
                <IconButton
                  disabled={
                    pageInfo.from === 0 || pageInfo.from === 1 ? true : false
                  }
                  style={{ fontSize: 22, background: 'white', color: 'black' }}
                  aria-label={'left'}
                  icon={<ChevronLeftIcon />}
                  onClick={() => hanlePagination('left')}
                />
                <IconButton
                  disabled={
                    pageInfo?.totalEntries !== null &&
                    pageInfo?.totalEntries !== undefined &&
                    pageInfo.to >= pageInfo?.totalEntries
                      ? true
                      : false
                  }
                  style={{ fontSize: 22, background: 'white', color: 'black' }}
                  aria-label={'left'}
                  onClick={() => hanlePagination('right')}
                  icon={<ChevronRightIcon />}
                />
              </div>
            </div>
          </div>
        </>
      </div>
      {hide ? (
        <ActionTray
          selectedRowIds={selectIds}
          toggleAllRowsSelected={setHide}
          allContacts={allContacts}
          filterVariable={filterVariable}
          pageInfo={pageInfo}
        />
      ) : null}
    </>
  );
}
