import { useReactiveVar } from '@apollo/client';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Stack,
  Table as StyledTable,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { globalFilterVar } from 'app/src/apollo/rootReactiveVariables';
import {
  PREDICTION_NAME_LEVEL_FILTER_ITEM,
  PREDICTION_NAME_LEVEL_FIT_FILTER_ITEM,
} from 'app/src/components/GlobalFilters/types/filterTypes';
import { CompaniesTableTopRow } from 'app/src/components/TableSection/components/CompaniesTableTopRow';
import { HiddenActionBar } from 'app/src/components/TableSection/components/HiddenActionBar';
import { ServerPagination } from 'app/src/components/TableSection/components/ServerPagination';
import { UnhiddenActionBar } from 'app/src/components/TableSection/components/UnhiddenActionBar';
import { AccountDrawerButtonRenderer } from 'app/src/components/TableSection/renderers/AccountDrawerButtonRenderer';
import {
  AccountDrawerItemRenderer,
  AccountDrawerItemRendererParams,
  ComaConverter,
  LastInboundLogic,
  LastOutboundLogic,
} from 'app/src/components/TableSection/renderers/AccountDrawerItemRenderer';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import classnames from 'classnames';
import _filter from 'lodash/filter';
import React, {
  Fragment,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { IndeterminateCheckbox } from 'shared/components/Core';
import { MessageIcon } from 'shared/components/Core/Icon/messageIcon';
import { UserIcon } from 'shared/components/Core/Icon/userIcon';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { ActionDrawerData } from 'shared/firebase/types/tableTypes';
import { ApiPageInfo } from 'shared/graphql/generatedApiTypes';
import { getStatusCircleIcon } from 'shared/renderers/helpers/getStatusCircleIcon';
import { OptionsType } from 'shared/types/coreTypes.d';
import { AllPredictionRenderJSON } from '../renderers/PredictionIconRender';
import { SignalIntensity } from '../renderers/SignalintensityIcon';

type TableProps = {
  actionDrawer?: ActionDrawerData;
  analyticsAttr?: string;
  columns: any[];
  data: any[];
  defaultSort: string;
  error: string | null;
  fetchData: (p: {
    pageIndex: number;
    pageSize: number;
    sortBy: any;
    hiddenCompanies: boolean;
  }) => any;
  legend: ReactNode | null;
  pageInfo: ApiPageInfo;
  renderRowSubComponent: (row) => ReactNode;
  sortableColumnOptions: OptionsType[];
  selectedSort: any;
};

export const ServerPaginatedTable = ({
  actionDrawer,
  analyticsAttr = undefined,
  columns,
  data,
  defaultSort,
  error,
  fetchData,
  legend,
  pageInfo,
  renderRowSubComponent,
  sortableColumnOptions,
  selectedSort,
}: TableProps) => {
  const drawerDisclosure = useDisclosure();
  const [drawerAccount, setDrawerAccount] = useState<
    AccountDrawerItemRendererParams['accountInfo'] | null
  >(null);
  const [currentSorts, setCurrentSorts] = useState([
    { desc: false, id: defaultSort },
  ]);
  const [currentSortsInForm, setCurrentSortsInForm] = useState([
    { desc: false, id: defaultSort },
  ]);
  const [showingHiddenCompanies, setShowingHiddenCompanies] =
    useState<boolean>(false);
  const sortInteractionModal = useDisclosure();

  const globalFilter = useReactiveVar(globalFilterVar);
  const history = useHistory();
  const klearlyUser = AuthManager.klearlyUser;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleAllRowsSelected,
    visibleColumns,
    // Get the state from the instance
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable<any>(
    {
      columns,
      //@ts-ignore
      data: data ?? [],
      getRowId: (row) => row.id,
      autoResetExpanded: false,
      autoResetPage: false,
      autoResetRowState: false,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      initialState: {
        pageIndex: 0,
        pageSize: 30,
      },
      manualPagination: true,
      pageCount: pageInfo.totalPages,
      stateReducer: (newState, action) => {
        switch (action.type) {
          case 'toggleAllRowsSelected':
            if (action.value === false) {
              return {
                ...newState,
                selectedRowIds: {},
              };
            }
            return newState;
          case 'goToPage':
            return {
              ...newState,
              selectedRowIds,
            };

          default:
            return newState;
        }
      },
    },
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    },
  );

  const actionBarIsShowing = useMemo(
    () => !!Object.keys(selectedRowIds).length,
    [selectedRowIds],
  );

  useEffect(() => {
    gotoPage(0);
  }, [currentSorts, gotoPage]);

  useEffect(() => {
    fetchData({
      pageIndex: pageIndex + 1,
      pageSize,
      sortBy: currentSorts,
      hiddenCompanies: showingHiddenCompanies,
    });
  }, [fetchData, pageIndex, pageSize, currentSorts, showingHiddenCompanies]);

  useEffect(() => {
    if (globalFilter) {
      if (pageIndex === 0) {
        fetchData({
          pageIndex: pageIndex + 1,
          pageSize,
          sortBy: currentSorts,
          hiddenCompanies: showingHiddenCompanies,
        });
      } else {
        gotoPage(0);
      }
    }
    // we only want this to happen when the filter changes, otherwise the page change will trigger it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter, gotoPage]);

  const tableClass = classnames({
    'c-table': true,
  });

  const applySorts = () => {
    setCurrentSorts(currentSortsInForm.filter((sort) => sort.id));
    sortInteractionModal.onClose();
  };

  const sortPopoverOnClose = () => {
    sortInteractionModal.onClose();
    setCurrentSortsInForm(currentSorts);
  };

  // disable this if the same column is selected twice
  const disableSortApply = !!currentSortsInForm.find(
    (sort1, index1) =>
      sort1.id &&
      currentSortsInForm.find(
        (sort2, index2) => sort2 && sort2.id === sort1.id && index1 !== index2,
      ),
  );
  // Custome Column company's JSON
  const CustomeCompnayTableData = {
    acquisitionPredictions: 'acquisitionPredictions',
    retentionPredictions: 'retentionPredictions',
    fitPredictions: 'fitPredictions',
    lastInboundResponse: 'lastInboundResponse',
    lastOutboundActivity: 'lastOutboundActivity',
    contactCount: 'contactCount',
    messageCount: 'messageCount',
    accountName: 'accountName',
  };

  // This function returns the data of Acquisition , Retention , Fit columns
  const PredictionColumnData = (data, predictionColumn) => {
    let PredictionsSort = data?.[predictionColumn]
      .slice()
      .sort((a, b) => a.calculatedFor.name - b.calculatedFor.name)
      .slice()
      .sort((a, b) => b.level - a.level);
    let predictionArray: any = [];
    if (klearlyUser?.company?.id === 10) {
      predictionArray = _filter(
        PredictionsSort,
        (v) => v?.calculatedFor?.name !== 'any',
      );
    } else {
      predictionArray = PredictionsSort;
    }
    return (
      <Grid
        className='responsible-grid'
        style={{ minWidth: predictionArray.length < 4 ? '200px' : '260px' }}
      >
        {predictionArray.length && data?.customerStatus !== 'CUSTOMER'
          ? predictionArray.map((item, index) => {
              return (
                <GridItem
                  key={index}
                  colSpan={2}
                  h='7'
                  className='prediction-companies'
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '3px',
                      minWidth: '38px',
                    }}
                  >
                    {AllPredictionRenderJSON[predictionColumn]?.[item?.level]}
                  </span>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: '700',
                      color: '#323F5A',
                      margin: '3px',
                    }}
                  >
                    {item?.calculatedFor?.name === 'any'
                      ? predictionColumn === 'fitPredictions'
                        ? PREDICTION_NAME_LEVEL_FIT_FILTER_ITEM[item?.level]
                        : PREDICTION_NAME_LEVEL_FILTER_ITEM[item?.level]
                      : item?.calculatedFor?.name.charAt(0).toUpperCase() +
                        item?.calculatedFor?.name.slice(1)}
                  </span>
                </GridItem>
              );
            })
          : '-'}
      </Grid>
    );
  };

  // Companies table data
  const CompaneisTableDataRender = (cell, index: number) => {
    const capitalize = (s) =>
      s && s[0].toUpperCase() + s.slice(1).toLowerCase();
    switch (cell?.column?.id) {
      case CustomeCompnayTableData?.acquisitionPredictions:
        return PredictionColumnData(data[index], cell?.column?.id);
      case CustomeCompnayTableData?.retentionPredictions:
        return PredictionColumnData(data[index], cell?.column?.id);
      case CustomeCompnayTableData?.fitPredictions:
        return PredictionColumnData(data[index], cell?.column?.id);
      case CustomeCompnayTableData?.lastInboundResponse:
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className='intensity-span'>
              {capitalize(data[index]['engagementStatus'])}
              {SignalIntensity[data[index]['signalResponseIntensity']]}
            </span>
            <span
              style={{ color: '#70798B', fontWeight: '600', fontSize: '12px' }}
            >
              {LastInboundLogic(data[index])}
            </span>{' '}
          </div>
        );

      case CustomeCompnayTableData?.lastOutboundActivity:
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className='intensity-span'>
              {capitalize(data[index]['outreachStatus'])}
              {SignalIntensity[data[index]['signalActivityIntensity']]}
            </span>
            <span
              style={{ color: '#70798B', fontWeight: '600', fontSize: '12px' }}
            >
              {LastOutboundLogic(data[index])}
            </span>{' '}
          </div>
        );
      case CustomeCompnayTableData?.contactCount:
        return (
          <Button
            type='button'
            className='people-message-btn'
            onClick={() =>
              history.push(`/companies/${data[index]['id']}#people`)
            }
          >
            {UserIcon}
            <p>{ComaConverter(data[index]['contactCount'])}</p>
          </Button>
        );
      case CustomeCompnayTableData?.messageCount:
        return (
          <Button
            type='button'
            className='people-message-btn'
            onClick={() =>
              history.push(`/companies/${data[index]['id']}#messages`)
            }
          >
            {MessageIcon}
            <p>{ComaConverter(data[index]['messageCount'])}</p>
          </Button>
        );
      case CustomeCompnayTableData?.accountName:
        return (
          <Button
            type='button'
            className='people-message-btn'
            style={{ height: 'auto' }}
            onClick={() => history.push(`/companies/${data[index]['id']}`)}
          >
            <div style={{ width: '36px' }}>
              {data[index]['customerStatus'] !== null
                ? getStatusCircleIcon(data[index]['customerStatus'])
                : null}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p
                style={{
                  color: '#2A96FC',
                  fontWeight: '700',
                  fontSize: '14px',
                  textDecoration: 'none',
                }}
              >
                {data[index]['accountName'].length > 15
                  ? data[index]['accountName'].slice(0, 15) + '...'
                  : data[index]['accountName']}
              </p>
              <p
                style={{
                  color: '#70798B',
                  fontWeight: '600',
                  fontSize: '12px',
                  textDecoration: 'none',
                }}
              >
                {capitalize(data[index]['customerStatus'])}
              </p>

              <p
                style={{
                  color: '#70798B',
                  fontSize: '12px',
                  textDecoration: 'none',
                }}
              >
                {klearlyUser?.company?.id === 10 ? (
                  <>Ship to Party ID : {data[index]['crmAccountId']}</>
                ) : (
                  <>
                    {data[index]['crmAccountId'].split(',')?.length > 1
                      ? data[index]['crmAccountId'].split(',')?.map((item) => {
                          return (
                            <>
                              {item.split(':')?.length > 1
                                ? ' ' + item.split(':')[1]
                                : ' ' + item}{' '}
                              <br />
                            </>
                          );
                        })
                      : data[index]['crmAccountId']}
                  </>
                )}
              </p>
            </div>
          </Button>
        );
      default:
        return cell.render('Cell');
    }
  };

  const openDetailDrawer = (
    accountInfo: AccountDrawerItemRendererParams['accountInfo'],
  ) => {
    setDrawerAccount(accountInfo);
    drawerDisclosure.onOpen();
  };

  const acquisitionBackgroundColor = (item: any) => {
    switch (item.level) {
      case 0:
        return '#8454d218';
      case 1:
        return '#8454d24a';
      case 2:
        return '#8454d282';
      case 3:
        return '#8454d2c9';
      case 4:
        return '#8454d2ff';
      default:
        return '-';
    }
  };

  return (
    <>
      <CompaniesTableTopRow
        onClose={sortPopoverOnClose}
        legend={legend}
        sortInteractionModal={sortInteractionModal}
        currentSortsInForm={currentSortsInForm}
        setCurrentSortsInForm={setCurrentSortsInForm}
        sortableColumnOptions={sortableColumnOptions}
        disableSortApply={disableSortApply}
        applySorts={applySorts}
        showingHiddenCompanies={showingHiddenCompanies}
        setShowingHiddenCompanies={setShowingHiddenCompanies}
        totalCompanies={pageInfo.totalEntries}
      />

      <TableContainer className='accountlist-table'>
        <StyledTable
          {...getTableProps()}
          analytics-attr={cleanAnalyticsStringList([analyticsAttr, 'table'])}
          className={tableClass}
        >
          {!!error ? (
            <Text color={'brand.crimson'} fontWeight={'bold'}>
              {error}
            </Text>
          ) : (
            <>
              <Thead top={4} zIndex={0}>
                {headerGroups.map((headerGroup) => (
                  <Tr
                    {...headerGroup.getHeaderGroupProps()}
                    style={{ border: 'none' }}
                  >
                    {headerGroup.headers.map((column) => {
                      let inlineHeaderProps = {
                        alignItems: 'center' as const,
                        className: 'h-flex-nowrap',
                      };
                      // @ts-ignore
                      if (column.inlineHeaderProps) {
                        inlineHeaderProps = {
                          ...inlineHeaderProps,
                          // @ts-ignore
                          ...column.inlineHeaderProps,
                        };
                      }
                      const columnHeaderText = column.render('Header') as
                        | string
                        | number;
                      return (
                        <Th
                          {...column.getHeaderProps()}
                          style={{
                            paddingLeft: '8px',
                            paddingTop: '24px',
                            width: '209px',
                          }}
                        >
                          <Flex
                            {...inlineHeaderProps}
                            analytics-attr={cleanAnalyticsStringList([
                              columnHeaderText === ''
                                ? 'expander'
                                : columnHeaderText,
                              analyticsAttr,
                              'table header',
                            ])}
                          >
                            <Text mr={1} fontSize={'12px'} color={'#152F45'}>
                              {columnHeaderText}
                            </Text>
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page?.map((row, el) => {
                  prepareRow(row);
                  const rowProps = { ...row.getRowProps() };
                  delete rowProps.role;
                  return (
                    <Fragment {...rowProps}>
                      <Tr
                        style={{ width: '209px' }}
                        _hover={{ cursor: 'pointer' }}
                        className='table-tr-columns'
                      >
                        {row.cells.map((cell, index) => {
                          const headerText =
                            // @ts-ignore
                            cell.column.Header?.props?.text ||
                            cell.column.Header;
                          return (
                            <Td
                              {...cell.getCellProps()}
                              analytics-attr={cleanAnalyticsStringList([
                                headerText ? headerText : 'expander',
                                analyticsAttr,
                              ])}
                              onClick={() =>
                                cell.column.id !== 'selection' &&
                                openDetailDrawer(row.original)
                              }
                              style={{
                                paddingLeft: '8px',
                                width: '209px',
                              }}
                            >
                              {CompaneisTableDataRender(cell, el)}
                            </Td>
                          );
                        })}
                      </Tr>
                      {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                      {row.isExpanded && renderRowSubComponent ? (
                        <Tr>
                          <Td colSpan={visibleColumns.length}>
                            {renderRowSubComponent({ row })}
                          </Td>
                        </Tr>
                      ) : null}
                    </Fragment>
                  );
                })}
              </Tbody>
            </>
          )}
        </StyledTable>
      </TableContainer>
      {!error && !!data?.length && (
        <ServerPagination
          analyticsAttr={analyticsAttr}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageIndex={pageIndex}
          pageInfo={pageInfo}
          pageLength={page.length}
          pageSize={pageSize}
          previousPage={previousPage}
          setPageSize={setPageSize}
        />
      )}

      {actionBarIsShowing &&
        (showingHiddenCompanies ? (
          <HiddenActionBar
            selectedRowIds={selectedRowIds}
            toggleAllRowsSelected={toggleAllRowsSelected}
            pageSize={pageSize}
            selectedSort={selectedSort}
            pageInfo={pageInfo}
          />
        ) : (
          <UnhiddenActionBar
            selectedRowIds={selectedRowIds}
            toggleAllRowsSelected={toggleAllRowsSelected}
            pageSize={pageSize}
            selectedSort={selectedSort}
            pageInfo={pageInfo}
          />
        ))}
      {actionDrawer && (
        <Drawer
          isOpen={drawerDisclosure.isOpen}
          placement='right'
          onClose={drawerDisclosure.onClose}
          size={'md'}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth='1px'>
              <Text>{actionDrawer.title.label}</Text>
            </DrawerHeader>
            <DrawerBody>
              <Text fontSize={'lg'}>
                {drawerAccount
                  ? drawerAccount[actionDrawer.title.dataField]
                  : ''}
              </Text>
              <Stack spacing='24px'>
                {actionDrawer.sections.map((section) => (
                  <>
                    <Text fontSize={'lg'} fontWeight={'bold'}>
                      {section.title}
                    </Text>
                    {section.fields.map((field) => (
                      <>
                        {AccountDrawerItemRenderer({
                          accountInfo: drawerAccount,
                          drawerSectionField: field,
                        })}
                      </>
                    ))}
                  </>
                ))}
              </Stack>
              <Flex gap={2} mt={8} justifyContent={'center'}>
                {actionDrawer.buttons?.map((buttonType) => (
                  <>
                    {drawerAccount &&
                      AccountDrawerButtonRenderer({
                        accountId: drawerAccount.id.toString() ?? '',
                        buttonType,
                        onDrawerClose: drawerDisclosure.onClose,
                      })}
                  </>
                ))}
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
