import { ApiSignalHistoryItem } from 'shared/graphql/generatedApiTypes';

export const getMaxYValue = ({
  data,
  propName,
}: {
  data: ApiSignalHistoryItem[];
  propName: 'signalCount' | 'accountCount';
}) => {
  const mapped: number[] = Object.keys(data).map((key) => {
    if (data[key] && data[key][propName]) {
      return data[key][propName];
    }
    return 0;
  });
  return Math.max(...mapped) ?? 100;
};
