import { IconRendererIcons } from '../../renderers/IconRenderer';
import { DataFieldFormat } from '../../renderers/DataFieldRenderer';
import { AccountField } from 'app/src/components/Account/components/sav/header/AccountHeader';

export enum TableColumnDisplay {
  Button = 'button',
  Static = 'static',
  NonData = 'nonData',
  Expander = 'expander',
}

export enum ButtonActionType {
  Navigate = 'navigate',
  Drawer = 'drawer',
}

export type ButtonActionData =
  | {
      type: ButtonActionType.Navigate;
      data: {
        routeToPush: string;
        hash?: string;
      };
    }
  | {
      type: ButtonActionType.Drawer;
      data: ActionDrawerData;
    };

export type ActionDrawerData = {
  title: DrawerSectionField;
  sections: Array<{
    title: string;
    fields: DrawerSectionField[];
  }>;
  buttons: Array<DrawerButtonType>;
};

export type DrawerSectionField = {
  label: string;
  dataField: AccountField;
};
export enum DrawerButtonType {
  AccountAddToList = 'accountAddToList',
  HideAccount = 'hideAccount',
  NavigateToSav = 'navigateToSav',
}

export enum TableLegend {
  none = 'none',
  customerStatus = 'customerStatus',
}

type BaseTableColumnType = {
  accessor: string; // defined based on source of data
  header: string;
  headerTooltip: string;
  isNumeric?: boolean; // only needed if column is sortable
  isSortable: boolean;
  id : string;
};

export type TableColumnType = BaseTableColumnType &
  (
    | {
        leftIcon?: IconRendererIcons;
        dataFieldFormat: DataFieldFormat;
        displayType: TableColumnDisplay.Static;
        truncateAt?: number;
      }
    | {
        actionData: ButtonActionData;
        displayType: TableColumnDisplay.Button;
        dataFieldFormat: DataFieldFormat;
        leftIcon?: IconRendererIcons;
        iconAccessor?: string;
        truncateAt?: number;
      }
    | {
        displayType: TableColumnDisplay.NonData;
        leftIcon?: IconRendererIcons;
        value: string;
      }
    | {
        displayType: TableColumnDisplay.Expander;
        subcomponent: TableSubcomponent;
      }
  );

export type TableSubcomponent = {
  dataSource: string; // build enum
  title: {
    fields: string[]; // build enum
    dataFieldFormat: DataFieldFormat;
  };
  rows: TableSubcomponentRow[];
};

export type TableSubcomponentRow = {
  label: string;
  dataField: string;
  dataFieldFormat: DataFieldFormat;
};

export type TableComponentProps = {
  actionDrawer?: ActionDrawerData;
  analyticsAttr: string;
  columns: TableColumnType[];
  defaultSort: string;
  legendType: TableLegend;
};
