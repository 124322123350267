import {Button} from '../../../../../../../shared/components/Core';
import {PageIndexOptions} from '../../../types/PageIndexOptions';
import {ButtonGroup} from '@chakra-ui/react';

type RecommendationsButtonGroupType = {
  id: number | string;
  maxImpactButtonLabel: string;
  pageIndex: PageIndexOptions;
  prioritySignalButtonLabel: string;
  setPageIndex: (pageIndex: PageIndexOptions) => void;
};

const RecommendationsButtonGroup = ({
  id = '',
  maxImpactButtonLabel = '',
  pageIndex = PageIndexOptions.primary,
  prioritySignalButtonLabel = '',
  setPageIndex = () => {},
}: RecommendationsButtonGroupType) => (
  <ButtonGroup spacing={0}>
    <Button
      analyticsAttr={`${prioritySignalButtonLabel} Account ${id}`}
      bg={pageIndex === PageIndexOptions.primary ? 'brand.blue' : 'transparent'}
      borderColor={'brand.gray-600'}
      borderRadius={0}
      borderStyle={'solid'}
      borderWidth={1}
      color={
        pageIndex === PageIndexOptions.primary
          ? 'brand.white'
          : 'brand.gray-600'
      }
      extraAnalyticsAttr={prioritySignalButtonLabel}
      onClick={() => setPageIndex(PageIndexOptions.primary)}
      text={prioritySignalButtonLabel}
      variant={'transparent'}
    />
    <Button
      analyticsAttr={`${maxImpactButtonLabel} Account ${id}`}
      bg={pageIndex === PageIndexOptions.all ? 'brand.green' : 'transparent'}
      borderColor={'brand.gray-600'}
      borderRadius={0}
      borderStyle={'solid'}
      borderWidth={1}
      color={
        pageIndex === PageIndexOptions.all ? 'brand.white' : 'brand.gray-600'
      }
      extraAnalyticsAttr={maxImpactButtonLabel}
      onClick={() => setPageIndex(PageIndexOptions.all)}
      text={maxImpactButtonLabel}
      variant={'transparent'}
    />
  </ButtonGroup>
);

export default RecommendationsButtonGroup;
