import {ApiSignalIntensity} from 'shared/graphql/generatedApiTypes';
import {MinusIcon, TriangleDownIcon, TriangleUpIcon} from '@chakra-ui/icons';

export const getTrendIcon = (value: any, iconColor: string | undefined) => {
  const upTrendValues = [
    'up',
    ApiSignalIntensity.AboveAverage,
    ApiSignalIntensity.WellAboveAverage,
  ];

  const downTrendValues = [
    'down',
    ApiSignalIntensity.BelowAverage,
    ApiSignalIntensity.WellBelowAverage,
  ];

  if (upTrendValues.includes(value)) {
    return (
      <TriangleUpIcon
        color={iconColor ? iconColor : 'brand.green'}
        data-testid={'up-icon'}
      />
    );
  } else if (downTrendValues.includes(value)) {
    return (
      <TriangleDownIcon
        color={iconColor ? iconColor : 'brand.crimson'}
        data-testid={'down-icon'}
      />
    );
  }
  return <MinusIcon color={iconColor} data-testid={'dash-icon'} />;
};
