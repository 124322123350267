import { CSSProperties, useContext } from 'react';
import Select from 'react-select';
import classnames from 'classnames';
import _size from 'lodash/size';

import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';
import {
  AnalyticsAttrType,
  ClassNameType,
  OptionsType,
} from 'shared/types/coreTypes.d';
import Checkbox from './Checkbox';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { FilterIndices } from 'app/src/components/GlobalFilters/types/filterTypes';

type CustomSelectType = AnalyticsAttrType &
  ClassNameType & {
    disabled?: boolean;
    extraFieldSetStyles?: CSSProperties;
    filterIndex: FilterIndices;
    id?: string;
    isFilterBar?: boolean;
    label?: string;
    labelTooltip?: string;
    options?: Array<OptionsType>;
  };

const MultiSelectCheckbox = ({
  analyticsAttr = undefined,
  className = undefined,
  disabled,
  extraFieldSetStyles = {},
  filterIndex,
  id = 'select',
  isFilterBar = false,
  options,
}: CustomSelectType) => {
  const { globalFilter, updateFilter } = useContext(AggregateDataContext);

  const fieldClass = classnames({
    'c-text-field': true,
    'h-m-md': true,
    'c-text-field--disabled': disabled,
    ...(typeof className === 'string' ? { [className]: className } : {}),
  });

  const selectAnalyticsAttr = cleanAnalyticsStringList([
    `analytics attr`,
    analyticsAttr,
    'select-control',
  ]);
  const selectClassNames = classnames({
    'c-text-field__input': true,
    [selectAnalyticsAttr]: _size(selectAnalyticsAttr) > 0,
  });

  const handleChange = (newValue: any) => {
    updateFilter({
      index: filterIndex,
      value: newValue,
    });
  };
  if (!globalFilter) {
    return null;
  }
  return (
    <fieldset
      className={fieldClass}
      disabled={disabled}
      style={{ ...extraFieldSetStyles }}
    >
      <label className={'c-text-field__wrapper'} htmlFor={id}>
        <div className={'c-text-field__input-block'}>
          {disabled && <div className={'h-disabled-overlay'} />}
          {options && isFilterBar ? (
            <div className={'h-relative'}>
              <Select
                className={selectClassNames}
                id={id}
                isDisabled={disabled}
                isMulti={true}
                name={id}
                options={options}
                styles={{
                  container: (styles) => ({ ...styles, display: 'none' }),
                }}
                value={null}
              />
              {options.map((option) => {
                const checked =
                  globalFilter[filterIndex] &&
                  !!globalFilter[filterIndex].find(
                    (selected) => selected === option.value,
                  );
                return (
                  <Checkbox
                    checked={checked}
                    key={option.value}
                    onChange={() =>
                      checked
                        ? handleChange(
                            globalFilter[filterIndex].filter(
                              (opt) => opt !== option.value,
                            ),
                          )
                        : globalFilter[filterIndex]
                        ? handleChange([
                            ...globalFilter[filterIndex],
                            option.value,
                          ])
                        : handleChange([option.value])
                    }
                    text={option.label}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </label>
    </fieldset>
  );
};

export default MultiSelectCheckbox;
