import React from 'react';

const commnonData = (level, data) => {
  switch (level) {
    case 0:
      return <b style={{ color: '#323F5A' }}> {data} Less Likely </b>;
    case 1:
      return <b style={{ color: '#323F5A' }}> {data} Less Likely </b>;
    case 3:
      return <b style={{ color: '#323F5A' }}> {data} More Likely </b>;
    case 4:
      return <b style={{ color: '#323F5A' }}> {data} More Likely </b>;
    default:
      return '';
  }
};
export const PredictionDescription = {
  Acquisition: {
    Belotero: {
      0: commnonData(0, '19%'),
      1: commnonData(1, ''),
      3: commnonData(3, '68%'),
      4: commnonData(4, '4.5X'),
    },
    Neocutis: {
      0: commnonData(0, '25%'),
      1: commnonData(1, '16%'),
      3: commnonData(3, ''),
      4: commnonData(4, '10%'),
    },
    Radiesse: {
      0: commnonData(0, '20%'),
      1: commnonData(1, '23%'),
      3: commnonData(3, ''),
      4: commnonData(4, '3.4X'),
    },
    Transducer: {
      0: commnonData(0, ''),
      1: commnonData(1, ''),
      3: commnonData(3, '80%'),
      4: commnonData(4, '2.1X'),
    },
    Xeomin: {
      0: commnonData(0, '29%'),
      1: commnonData(1, '14%'),
      3: commnonData(3, '25%'),
      4: commnonData(4, '92%'),
    },
  },
  Retention: {
    Belotero: {
      0: commnonData(0, '45%'),
      1: commnonData(1, '23%'),
      3: commnonData(3, '27%'),
      4: commnonData(5, '91%'),
    },
    Neocutis: {
      0: commnonData(0, '58%'),
      1: commnonData(1, '48%'),
      3: commnonData(3, '38%'),
      4: commnonData(4, '49%'),
    },
    Radiesse: {
      0: commnonData(0, '57%'),
      1: commnonData(1, '31%'),
      3: commnonData(3, '14%'),
      4: commnonData(4, '21%'),
    },
    Transducer: {
      0: commnonData(0, '37%'),
      1: commnonData(1, '28%'),
      3: commnonData(3, '38%'),
      4: commnonData(4, '60%'),
    },
    Xeomin: {
      0: commnonData(0, '54%'),
      1: commnonData(1, '21%'),
      3: commnonData(3, '17%'),
      4: commnonData(4, '50%'),
    },
  },
};
