/* We need to sort the pages so that any particular nested paths are declared before paths with params that might match.
Example: if companies/lists is declared after companies/:id in the router, then companies/:id will always match first.
This will result in an error when it tries to navigate to /:id with "lists" as the id param. Therefore, we sort
 everything with a param to the end.
 */

import { FullSavedPageSchema } from 'shared/firebase/schemas/SavedPage';

export const sortPagesByParam = (a: FullSavedPageSchema) => {
  if (a.navStructure.urlPaths.find((path) => path.param)) {
    return 1;
  }
  return -1;
};
