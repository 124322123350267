import {createIcon} from '@chakra-ui/icon';

export const TrendingUpIcon = createIcon({
  displayName: 'TrendingUp',
  viewBox: '0 0 21 20',
  path: (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.6667 5L15.575 6.90833L11.5083 10.975L8.175 7.64167L2 13.825L3.175 15L8.175 10L11.5083 13.3333L16.7583 8.09167L18.6667 10V5H13.6667Z'
        fill='#62D1A6'
      />
    </svg>
  ),
});
