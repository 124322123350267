import classnames from 'classnames';
import {v4 as uuidv4} from 'uuid';
import _map from 'lodash/map';

import {Inline, Txt} from './index';
import {OnChangeInputChangeEventType} from './Input';
import {OptionsType} from 'shared/types/coreTypes.d';

type RadioInputProps = {
  className?: string;
  disabled?: boolean;
  horizontal?: boolean;
  label?: string | null;
  onChange: (event: OnChangeInputChangeEventType) => void;
  options: Array<OptionsType>;
  value: string | number;
};

const RadioInput = ({
  className,
  disabled = false,
  horizontal = false,
  label = null,
  onChange = () => {},
  options = [],
  value,
}: RadioInputProps) => {
  // setup classes
  const fieldClass = classnames({
    'c-text-field': true,
    'c-text-field--disabled': disabled,
    ...(typeof className === 'string' ? { [className]: className } : {}),
  });

  // add a uuid to use as the radio button input ID so that each input/label has a unique name
  const optionsWithUuid = options.map((option) => ({
    ...option,
    uuid: uuidv4(),
  }));

  return horizontal ? (
    <fieldset className={fieldClass} disabled={disabled}>
      <Inline className={'h-mt-2xs'}>
        <RadioInputFieldset
          label={label}
          options={optionsWithUuid}
          value={value}
          onChange={onChange}
        />
      </Inline>
    </fieldset>
  ) : (
    <fieldset className={fieldClass} disabled={disabled}>
      <RadioInputFieldset
        label={label}
        options={optionsWithUuid}
        value={value}
        onChange={onChange}
      />
    </fieldset>
  );
};

export default RadioInput;

type RadioInputFieldsetProps = {
  label: RadioInputProps['label'];
  options: Array<OptionsType & { uuid: string }>;
  value: RadioInputProps['value'];
  onChange: RadioInputProps['onChange'];
};

const RadioInputFieldset = ({
  label,
  options,
  value,
  onChange,
}: RadioInputFieldsetProps) => (
  <>
    {label && (
      <div className={'c-text-field__label'}>
        <Txt className={'c-text h-text-size-md'} weight={'bold'}>
          {label}
        </Txt>
      </div>
    )}
    {_map(options, (option, key) => {
      return (
        <label
          analytics-attr={option.analyticsAttr ?? ''}
          className={'c-text-field__wrapper h-pb-sm'}
          htmlFor={option.uuid}
          key={key}
        >
          <Inline className={'c-text-field__input-block'} gap={'xs'}>
            <input
              checked={value === option.value}
              className={
                'c-text-field__input c-text-field__input-radio h-pointer'
              }
              id={option.uuid}
              name={option.uuid}
              onChange={onChange}
              type={'radio'}
              value={option.value}
            />
            <div>
              <Txt size={'md'} className={'h-pointer h-mr-sm'}>
                {option.label}
              </Txt>
            </div>
          </Inline>
        </label>
      );
    })}
  </>
);
