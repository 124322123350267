import { BaseModel } from './BaseModel';
import { Firestore } from '../types/firebaseTypes';
import { SavedPageDocument } from '../documents/SavedPageDocument';
import { SavedPageSchema } from '../schemas/SavedPage';

export class SavedPageModel extends BaseModel<
  SavedPageSchema,
  SavedPageDocument
> {
  constructor(firestore: Firestore | null) {
    super(firestore, 'SavedPage', `saved_pages`, SavedPageDocument);
  }
}
