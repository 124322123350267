import {ReactNode, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {OnChangeInputChangeEventType} from '../components/Core/Input';
import {InputAutoCompleteType, InputTypeType, OptionsType,} from 'shared/types/coreTypes.d';

type UseInputHookType = {
  autoComplete?: InputAutoCompleteType;
  children?: ReactNode;
  defaultValue?: number | string | boolean | null | Array<string | object>;
  disabled?: boolean;
  error?: string;
  id?: string;
  isCheckbox?: boolean;
  label?: string;
  labelTooltip?: string;
  name?: string;
  options?: Array<string | OptionsType> | object;
  placeholder?: string;
  required?: boolean;
  success?: string;
  text?: string;
  type?: InputTypeType;
};

export type UseInputHookValueStringType = string;
export type UseInputHookValueBooleanType = boolean;
export type UseInputHookValueArrayType = Array<string | object>;
export type UseInputHookBooleanValueTypes = {
  setValue: (value: UseInputHookValueBooleanType) => void;
  value: UseInputHookValueBooleanType;
};
export type UseInputHookStringValueTypes = {
  setValue: (value: UseInputHookValueStringType | number) => void;
  value: UseInputHookValueStringType;
};
export type UseInputHookArrayValueTypes = {
  setValue: (value: UseInputHookValueArrayType) => void;
  value: UseInputHookValueArrayType;
};
export type UseInputHookReturnType = {
  bind: UseInputHookType & {
    onChange: (event: OnChangeInputChangeEventType | OptionsType) => void;
  };
  reset: () => void;
};
export type UseInputHookStringNumberValueTypes = {
  setValue: (value: UseInputHookValueStringType | number | undefined) => void;
  value: UseInputHookValueStringType | number;
};

export const useInput = (
  initialValue:
    | UseInputHookValueStringType
    | UseInputHookValueBooleanType
    | UseInputHookValueArrayType
    | number,
  {
    autoComplete = 'off',
    children = null,
    defaultValue = null,
    disabled = false,
    error,
    id = uuidv4(),
    isCheckbox = false,
    label,
    labelTooltip,
    name,
    options,
    placeholder,
    required,
    success,
    text,
    type = 'text',
  }: UseInputHookType,
) => {
  const [value, setValue] = useState<
    | UseInputHookValueStringType
    | UseInputHookValueBooleanType
    | UseInputHookValueArrayType
    | number
  >(initialValue);
  return {
    bind: {
      autoComplete,
      children,
      defaultValue,
      disabled,
      error,
      id,
      label,
      labelTooltip,
      name,
      onChange: (event: {
        target: { checked: boolean; value: string | boolean };
      }) =>
        disabled
          ? {}
          : setValue(isCheckbox ? event.target.checked : event.target.value),
      options,
      placeholder,
      required,
      success,
      text,
      type,
      value,
    },
    reset: () =>
      setValue(
        defaultValue || defaultValue === 0 ? defaultValue : initialValue,
      ),
    setValue,
    value,
  };
};
