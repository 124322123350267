import { AppKlearlyUser } from './components/AppKlearlyUser';
import { App } from './components/App';
import withAuthentication from 'shared/helpers/withAuthentication';

const AppWrapper = () => (
  <AppKlearlyUser>
    <App />
  </AppKlearlyUser>
);

export default withAuthentication(AppWrapper);
