import _includes from 'lodash/includes';
import {
  FavoritePropsType,
  FeedbackPropsType,
} from '../types/firestoreValuesTypes';

export function copyLinkToClipboard(
  toast,
  customLink: string | undefined = undefined,
) {
  // trigger toast message
  toast({
    title: `Link added to clipboard.`,
    status: 'success',
    position: 'top',
  });

  // copy link to clipboard
  const textField = document.createElement('textarea');
  textField.innerText = customLink ?? window.location.href;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
}

export function triggerEmail(
  toast,
  question: string,
  customLink: string | undefined = undefined,
) {
  // trigger toast message
  toast({
    title: `Email started`,
    status: 'success',
    position: 'top',
  });
  // trigger mailto link
  const body = `Checkout this Klearly insight: ${
    customLink || window.location.href
  }`;
  const subject = `Klearly Insight: ${question}`;
  window.location.href = `mailto:?body=${body}&subject=${subject}`;
}

export function handleFeedbackFavoriteForLabCard(
  favoriteProps: FavoritePropsType,
  feedbackProps: FeedbackPropsType | undefined,
  firebaseUserUID: string,
  id?: string | undefined,
) {
  let { error: favoriteError, data: favoriteData } = { ...favoriteProps };
  let { error: feedbackError, data: feedbackData } = { ...feedbackProps };
  favoriteData =
    favoriteData && !favoriteError ? favoriteData : { labCardIds: [] };
  feedbackData =
    feedbackData && !feedbackError
      ? feedbackData
      : {
          comments: [],
          likes: [],
        };
  const isFavorited = _includes(favoriteData.labCardIds, id);
  const isLiked = _includes(feedbackData.likes, firebaseUserUID);
  return {
    favoriteData,
    feedbackData,
    isFavorited,
    isLiked,
  };
}
