import { Box, BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

type HeaderProps = BoxProps & {
  children: ReactNode;
};

export const Header = ({ children, ...props }: HeaderProps) => {
  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      h={'100%'}
      justifyContent={'space-between'}
      mx={'auto'}
      maxW={'1400px'}
      px={4}
      {...props}
    >
      {children}
    </Box>
  );
};
