import { useState } from 'react';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _flatten from 'lodash/flatten';
import _includes from 'lodash/includes';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _uniq from 'lodash/uniq';
import { Card } from '../../../../../../shared/components/Core';
import { AccountsWithoutSignals } from './Accounts';
import MultiAccountWinfluence, {
  AccountsDataType,
  SelectedChartValuesType,
} from './MultiAccountWinfluence';
import PrioritySignalRecommendations from './PrioritySignalRecommendations';
import { useReactiveVar } from '@apollo/client';
import { globalFilterVar } from 'app/src/apollo/rootReactiveVariables';
import {
  ApiGetAccountQuery,
  useGetAccountsQuery,
} from 'shared/graphql/generatedApiTypes';
import { getMultipleAccountsHeaderValues } from 'app/src/components/Account/helpers/getMultipleAccountsHeaderValues';
import { filterAccountByPriorityAndRank } from 'app/src/components/Account/helpers/filterAccountByPriorityAndRank';

const PAUSE_RECOMMENDATIONS = ['pause', 'stop', 'decrease'];
const PURSUE_RECOMMENDATIONS = ['pursue', 'maintain', 'increase'];

export type MultipleAccountsCutsAndListProps = {};

export const MultipleAccountsCutsAndList =
  ({}: MultipleAccountsCutsAndListProps) => {
    useReactiveVar(globalFilterVar);
    const { data: accountGroupsData, loading: accountGroupsLoading } =
      useGetAccountsQuery({ notifyOnNetworkStatusChange: true });
    const accounts = accountGroupsData?.accountsWithPredictions ?? [];

    const [selectedRangeValues, setSelectedRangeValues] = useState<
      SelectedChartValuesType | undefined
    >(undefined);
    const [selectedSignalAction, setSelectedSignalAction] = useState<
      string[] | undefined
    >(undefined);

    // setup filter functions
    const filterBySignalType = (
      accounts: NonNullable<ApiGetAccountQuery['account']>['goalValues'] = [],
    ) =>
      _filter(accounts, (d) =>
        _find(d?.signalPredictions, (sp) =>
          _includes(selectedSignalAction, sp?.signal_type),
        ),
      );
    const filterBySelectedRange = (
      accounts: NonNullable<ApiGetAccountQuery['account']>['goalValues'] = [],
    ) =>
      _filter(accounts, (d) => {
        const { probabilityCheck, rankCheck } = filterAccountByPriorityAndRank(
          d as unknown as NonNullable<
            ApiGetAccountQuery['account']
          >['goalValues'],
          selectedRangeValues,
        );
        return probabilityCheck && rankCheck;
      });
    const getAccountsSizeForPrioritySignalRecommendations = (
      flattenedPriorityAccounts,
      rec,
      areRangesSelected,
    ) => {
      const filteredAccounts = _filter(
        flattenedPriorityAccounts,
        (pa) => pa.signal_type === rec,
      );
      return _size(
        areRangesSelected
          ? filterBySelectedRange(filteredAccounts)
          : filteredAccounts,
      );
    };
    const getPauseSizeForPrioritySignalRecommendations = (
      flattenedPriorityAccounts,
      rec,
      areRangesSelected,
    ) => {
      const filteredAccounts = _filter(
        flattenedPriorityAccounts,
        (pa) =>
          pa.signal_type === rec &&
          _includes(PAUSE_RECOMMENDATIONS, pa.recommendation),
      );
      return _size(
        areRangesSelected
          ? filterBySelectedRange(filteredAccounts)
          : filteredAccounts,
      );
    };
    const getPursueSizeForPrioritySignalRecommendations = (
      flattenedPriorityAccounts,
      rec,
      areRangesSelected,
    ) => {
      const filteredAccounts = _filter(
        flattenedPriorityAccounts,
        (pa) =>
          pa.signal_type === rec &&
          _includes(PURSUE_RECOMMENDATIONS, pa.recommendation),
      );
      return _size(
        areRangesSelected
          ? filterBySelectedRange(filteredAccounts)
          : filteredAccounts,
      );
    };

    const {
      accountsWithoutSignals,
      openAccounts,
    }: {
      accountsWithoutSignals: Array<any>;
      openAccounts: Array<any>;
    } = getMultipleAccountsHeaderValues(accounts);
    const isPrioritySignalRecommendationsFiltered =
      _size(selectedSignalAction) > 0;
    const winfluenceIsFiltered = isPrioritySignalRecommendationsFiltered;
    const areRangesSelected = !_isUndefined(selectedRangeValues);
    const priorityAccounts = _map(openAccounts, (a) => ({
      ...a,
      ..._find(a.goalValues, ['isPriority', true]),
    })) as NonNullable<ApiGetAccountQuery['account']>['goalValues'];
    const winfluenceAccountsData = (isPrioritySignalRecommendationsFiltered
      ? filterBySignalType(priorityAccounts)
      : priorityAccounts) as unknown as AccountsDataType;
    const flattenedPriorityAccounts = _map(
      _flatten(priorityAccounts),
      (gp) => ({
        ...gp,
        ...gp?.signalPredictions?.[0],
      }),
    );
    const prioritySignalRecommendations = _uniq(
      _map(
        flattenedPriorityAccounts,
        (gp) => gp?.signalPredictions?.[0]!.signal_type,
      ),
    );
    const prioritySignalRecommendationsAccountsData = _map(
      prioritySignalRecommendations,
      (rec) => ({
        accounts: getAccountsSizeForPrioritySignalRecommendations(
          flattenedPriorityAccounts,
          rec,
          areRangesSelected,
        ),
        pause: getPauseSizeForPrioritySignalRecommendations(
          flattenedPriorityAccounts,
          rec,
          areRangesSelected,
        ),
        pursue: getPursueSizeForPrioritySignalRecommendations(
          flattenedPriorityAccounts,
          rec,
          areRangesSelected,
        ),
        signal_type: rec,
      }),
    );

    return (
      <>
        <Card isLoading={accountGroupsLoading}>
          <MultiAccountWinfluence
            accountsData={winfluenceAccountsData}
            isFilterApplied={winfluenceIsFiltered}
            onChartSelection={(values) => setSelectedRangeValues(values)}
          />
          <PrioritySignalRecommendations
            data={prioritySignalRecommendationsAccountsData}
            onSignalSelection={(signals) => {
              if (_size(signals) === 0 || !signals) {
                setSelectedRangeValues(undefined);
              }
              setSelectedSignalAction(signals);
            }}
          />
        </Card>
        <AccountsWithoutSignals
          accountsWithoutSignals={accountsWithoutSignals}
        />
      </>
    );
  };
