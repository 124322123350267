import {createIcon} from '@chakra-ui/icon';

export const FirstRecentSaleIcon = createIcon({
  displayName: 'FirstRecentSale',
  viewBox: '0 0 21 20',
  path: (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M-1220.5 -56C-1220.5 -62.3513 -1215.35 -67.5 -1209 -67.5H207C213.351 -67.5 218.5 -62.3513 218.5 -56V659.5H-1220.5V-56Z'
        fill='white'
      />
      <g>
        <g>
          <path
            d='M13.0778 5.206L8.66699 0.833496H1.16699V8.3335L11.167 18.3335L14.4278 15.0002'
            stroke='#152F45'
            fill={'white'}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M14.5005 15C17.2619 15 19.5005 12.7614 19.5005 10C19.5005 7.23858 17.2619 5 14.5005 5C11.7391 5 9.50049 7.23858 9.50049 10C9.50049 12.7614 11.7391 15 14.5005 15Z'
            stroke='#152F45'
            fill={'white'}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M14.5005 8.3335V10.0002H16.1672'
            stroke='#152F45'
            fill={'white'}
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
    </svg>
  ),
});
