import React from "react";
// functoion to show the lest side icon on change vs last week
export const displaySign = (_item: any, arrow: string, difference: number) => {
  switch (arrow) {
    case 'dash':
      return (
        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.0'
            style={{ marginTop: '2px' }}
            width='20.000000pt'
            height='12.000000pt'
            viewBox='0 0 512.000000 512.000000'
            preserveAspectRatio='xMidYMid meet'
          >
            <g
              transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
              fill='#000000'
              stroke='none'
            >
              <path d='M986 3585 c-31 -11 -138 -112 -498 -472 -252 -252 -465 -472 -473 -490 -19 -40 -19 -86 1 -126 21 -42 876 -901 933 -938 50 -33 105 -38 150 -15 44 23 149 142 153 173 9 75 2 84 -284 370 l-273 273 1865 0 1865 0 -273 -273 c-289 -290 -297 -300 -281 -374 13 -65 118 -168 184 -183 81 -18 76 -22 582 483 414 415 472 477 478 509 17 92 30 76 -453 562 -246 246 -465 461 -486 477 -32 24 -49 29 -94 29 l-56 0 -78 -78 c-78 -78 -78 -79 -78 -131 0 -29 5 -62 11 -74 6 -12 129 -140 272 -284 l262 -263 -1855 0 -1855 0 262 263 c143 144 266 273 273 285 17 34 14 109 -5 138 -29 44 -86 103 -118 122 -45 27 -86 32 -131 17z' />
            </g>
          </svg>
          <span style={{ fontWeight: 500 }}>Flat since last week</span>
        </div>
      );
    case 'downArrow':
      return (
        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <svg
            width='20'
            height='12'
            viewBox='0 0 20 12'
            fill='none'
            style={{ marginTop: '5px' }}
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M19 11L11 3L7 7L1 1M19 11H13M19 11V5'
              fill='none'
              stroke='black'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <span>{Math.abs(difference)} places since last week</span>
        </div>
      );
    case 'upArrow':
      return (
        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <svg
            width='20'
            height='12'
            viewBox='0 0 20 12'
            style={{ marginTop: '5px' }}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M19 1L11 9L7 5L1 11M19 1H13M19 1V7'
              fill='none'
              stroke='black'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <span>{difference} places since last week</span>
        </div>
      );
    default:
      return '-';
  }
};

// Functoion to show the level difference between current and previous
export const changetolastWeek = (currentData: any, recentData: any) => {
  let difference = currentData?.level - recentData?.level;
  if (difference === 0) {
    return displaySign(currentData, 'dash', difference);
  }
  if (difference < 0) {
    return displaySign(currentData, 'downArrow', difference);
  }
  if (difference > 0) {
    return displaySign(currentData, 'upArrow', difference);
  }
};
