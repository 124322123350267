import { useParams } from 'react-router-dom';
import AuthManager from 'shared/firebase/classes/AuthManager';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { useGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { formatDateString } from 'shared/helpers/formatHelpers';
import { AccountHeaderContent } from './AccountHeaderContent';
import { AccountHeaderError } from './AccountHeaderError';

export enum AccountField {
  accountName = 'accountName',
  crmId = 'crmId',
  engagementIntensity = 'engagementIntensity',
  engagementStatus = 'engagementStatus',
  industry = 'industry',
  lastInboundResponse = 'lastInboundResponse',
  lastOutboundActivity = 'lastOutboundActivity',
  location = 'location',
  outreachIntensity = 'outreachIntensity',
  outreachStatus = 'outreachStatus',
  revenue = 'revenue',
  size = 'size',
}

export type AccountHeaderProps = {
  items: Array<{ field: AccountField; label: string; tooltip?: string }>;
};

export const AccountHeader = ({ items }: AccountHeaderProps) => {
  const userCompanyId = AuthManager.klearlyUser?.company?.id;
  const { id: selectedAccountId } = useParams<{ id: string }>();
  const { data, error } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });

  const dataProcessedAt = AuthManager.klearlyUser?.company?.dataProcessedAt
    ? formatDateString(
        new Date(AuthManager.klearlyUser.company.dataProcessedAt),
        'MMM dd, yyyy',
      )
    : 'N/A';

  const accountInfo = data?.account;

  if (error) {
    return (
      <AccountHeaderError
        error={`Error fetching account data for account ${selectedAccountId}`}
      />
    );
  }

  return (
    <AccountHeaderContent
      accountInfo={accountInfo}
      dataProcessedAt={dataProcessedAt}
      items={items}
      userCompanyId={userCompanyId}
    />
  );
};
