import { ReactNode } from 'react';
import { AnalyticsAttrType } from 'shared/types/coreTypes.d';
import { BoxProps, Flex, Tooltip } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { cleanAnalyticsStringList } from 'app/src/helpers/analyticsHelpers';

type StyledTooltipProps = AnalyticsAttrType &
  BoxProps & {
    children?: ReactNode;
    content: ReactNode;
    hideIcon?: boolean;
    iconColor?: string;
    text?: string;
    hasArrow?: boolean;
    background?:string;
  };

const StyledTooltip = ({
  analyticsAttr = undefined,
  children,
  content,
  hasArrow = true,
  hideIcon = false,
  iconColor = 'brand.gray-400',
  text = undefined,
  background ='components.tooltip',
  ...props
}: StyledTooltipProps) => {

  return (
    <Tooltip
      analytics-attr={cleanAnalyticsStringList([analyticsAttr, 'tooltip'])}
      bg={background}
      borderRadius={8}
      hasArrow={hasArrow}
      label={<>{content}</>}
      m={2}
      p={4}
      shouldWrapChildren
      zIndex={15000}
      {...props}
    >
      <Flex alignItems={'center'} zIndex={15000}>
        {text ? text : children}
        {!hideIcon && <QuestionIcon color={iconColor} ml={2} />}
      </Flex>
    </Tooltip>
  );
};

export default StyledTooltip;
