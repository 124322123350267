type ArrayRouteType = Array<string>;

type StringRouteType = string;

type ObjectNamePathRouteType = {
  name: string;
  path: string;
};

export const ACCOUNT: ArrayRouteType = ['/account', '/account/:id'];
export const ADMIN: ArrayRouteType = ['/admin?page=users', '/admin'];
export const CRM_CREDENTIALS_PAGE: ObjectNamePathRouteType = {
  name: 'CRMCredentials',
  path: '/credentials/:company_name',
};
export const HOME: StringRouteType = '/';
export const LOG_IN: StringRouteType = '/login';
export const INTEGRATION_SUCCESS_PAGE: ObjectNamePathRouteType = {
  name: 'Integration Success',
  path: '/integration_success',
};
export const INTEGRATIONS_PAGE: ObjectNamePathRouteType = {
  name: 'Integrations',
  path: '/integrations',
};
export const MARKETO_INTEGRATION_PAGE: ObjectNamePathRouteType = {
  name: 'Marketo Integration',
  path: '/marketo_integration',
};

export const PARDOT_INTEGRATION_PAGE: ObjectNamePathRouteType = {
  name: 'Pardot Integration',
  path: '/pardot_integration',
};

export const PENDO_INTEGRATION_PAGE: ObjectNamePathRouteType = {
  name: 'Pendo Integration',
  path: '/pendo_integration',
};

export const PASSWORD_FORGOT: StringRouteType = '/forgot';
export const PASSWORD_RESET: StringRouteType = '/reset';
// tabs
export const ADMIN_USER_LIST_TAB: StringRouteType = 'users';
export const ADMIN_INTEGRATIONS_TAB: StringRouteType = 'integrations';
export const LABS_TAB: ObjectNamePathRouteType = {
  name: 'Labs',
  path: '/labs',
};
