import { useApolloClient, useReactiveVar } from '@apollo/client';
import { Text } from '@chakra-ui/react';
import { globalFilterVar } from 'app/src/apollo/rootReactiveVariables';
import { AbstractedTable } from 'app/src/components/TableSection/components/AbstractedTable';
import { TableLegend } from 'app/src/components/TableSection/components/TableLegend';
import { AggregateDataContext } from 'app/src/context/AggregateDataContext';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  TableComponentProps,
  TableLegend as TableLegendValueType,
} from 'shared/firebase/types/tableTypes';
import {
  ApiAccountCustomerStatus,
  ApiPageInfo,
  ApiSort,
  GetAccountsListDocument,
} from 'shared/graphql/generatedApiTypes';
import { IconRendererIcons } from 'shared/renderers/IconRenderer';
import { Card } from '../../../../../shared/components/Core';
import { mapColumnNamesToSorts } from '../helpers/mapColumnNamesToSorts';

type TableLegendType = { leftIcon: IconRendererIcons; value: string };

export const getTableLegendValues = (
  legendValue: TableLegendValueType,
): TableLegendType[] | undefined => {
  switch (legendValue) {
    case TableLegendValueType.customerStatus:
      return [
        {
          leftIcon: IconRendererIcons.STATUS_CIRCLE,
          value: ApiAccountCustomerStatus.Customer,
        },
        {
          leftIcon: IconRendererIcons.STATUS_CIRCLE,
          value: ApiAccountCustomerStatus.PastCustomer,
        },
        {
          leftIcon: IconRendererIcons.STATUS_CIRCLE,
          value: ApiAccountCustomerStatus.Prospect,
        },
      ];
    case TableLegendValueType.none:
      return undefined;
    default:
      return undefined;
  }
};

export const AbstractedTableSection = ({
  actionDrawer,
  analyticsAttr,
  columns,
  defaultSort,
  legendType,
}: TableComponentProps) => {
  //  We need to keep the table from resetting the pageIndex when we update data; keep track of that with a ref
  const { fullAggregateLoading } = useContext(AggregateDataContext);
  const skipResetRef = useRef(false);
  const apolloClient = useApolloClient();
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any[] | null>(null);
  const [selectedSort, setSelectedSort] = useState<any[] | null>(null);
  const [pageInfo, setPageInfo] = useState<ApiPageInfo | null>(null);
  const globalFilter = useReactiveVar(globalFilterVar);
  const fetchData = useCallback(
    async ({ pageIndex, pageSize, sortBy, hiddenCompanies }) => {
      skipResetRef.current = true;
      setError(null);
      try {
        const sort = sortBy.map((selectedSort) => ({
          field: mapColumnNamesToSorts(selectedSort.id),
          direction: selectedSort.desc ? ApiSort.Desc : ApiSort.Asc,
        }));
        setSelectedSort(sort);
        const query = {
          query: GetAccountsListDocument,
          variables: {
            messageCountDaysAgo: 7,
            page: pageIndex,
            limit: pageSize,
            sort,
            acqType: ['ACQUISITION'],
            retentionType: ['RETENTION'],
            fitType: ['FIT'],
          },
        };
        //@ts-ignore
        const { data: queryData } = await apolloClient.query(query);

        if (queryData) {
          setData(queryData?.accounts?.accounts ?? null);
          setPageInfo(queryData?.accounts?.pageInfo ?? null);
        }
      } catch (err) {
        setError('Error loading data; please refresh & try again.');
      }
    },
    [apolloClient, globalFilter],
  );

  useEffect(() => {
    skipResetRef.current = false;
  }, [data]);

  const legendContent = getTableLegendValues(legendType);

  return (
    <Card
      isLoading={fullAggregateLoading}
      style={{ background: 'none', border: 'none' }}
    >
      {!!error && (
        <Text color={'brand.crimson'} size={'lg'} m={4}>
          {error}
        </Text>
      )}
      <AbstractedTable
        actionDrawer={actionDrawer}
        analyticsAttr={analyticsAttr}
        columns={columns}
        data={data}
        selectedSort={selectedSort}
        defaultSort={defaultSort}
        error={error}
        fetchData={fetchData}
        legend={
          legendContent ? <TableLegend legendContent={legendContent} /> : null
        }
        pageInfo={pageInfo ?? { totalPages: 0, totalEntries: 0 }}
      />
    </Card>
  );
};
