import { Winfluence } from './Winfluence';
import { useGetAccountQuery } from 'shared/graphql/generatedApiTypes';
import { PageIndexOptions } from '../../../types/PageIndexOptions';
import { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import Recommendations from './Recommendations';
import { AccountScoreSection } from './AccountScoreSection';
import { useParams } from 'react-router-dom';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { Card } from '../../../../../../../shared/components/Core';
import { getClosedWonBannerText } from 'app/src/components/Account/helpers/getClosedWonBannerText';

type State = {
  pageIndex: PageIndexOptions;
  showingPrimaryActions: boolean;
  showingAllActions: boolean;
};

const initialState: State = {
  pageIndex: PageIndexOptions.primary,
  showingPrimaryActions: true,
  showingAllActions: false,
};

export type RecommendationsTabProps = {
  allActions: {
    title: string;
    subtitle?: string;
    tooltip?: string;
  };
  primaryAction: {
    title: string;
    subtitle?: string;
    tooltip?: string;
  };
  expectedRecommended: {
    title: string;
    subtitle?: string;
    tooltip?: string;
  };
  recommendation: {
    title: string;
    subtitle?: string;
    tooltip?: string;
  };
  signalType: {
    title: string;
    subtitle?: string;
    tooltip?: string;
  };
  winfluence: {
    title: string;
    subtitle: string;
    tooltip: string;
  };
  title: string;
  subtitle: string;
  tooltip?: string;
};

export const RecommendationsTab = ({ ...props }: RecommendationsTabProps) => {
  const { id: selectedAccountId } = useParams<{ id: string }>();

  const { data: accountInfo, loading } = useGetAccountQuery({
    ...defaultQueryFetchPolicy,
    variables: selectedAccountId
      ? {
          id: parseInt(selectedAccountId),
        }
      : undefined,
  });

  const [{ pageIndex, showingAllActions, showingPrimaryActions }, setState] =
    useState<State>(initialState);

  const closedWonBannerText = getClosedWonBannerText(
    accountInfo?.account,
  ) as string;

  // ensure that the recommendation section also toggles when a new page index is selected
  const updatePageIndex = (newPageIndex: PageIndexOptions) => {
    const pageIndexUpdate = { pageIndex: newPageIndex };
    switch (newPageIndex) {
      case PageIndexOptions.all:
        setState((prevState) => ({
          ...prevState,
          showingAllActions: true,
          showingPrimaryActions: false,
          ...pageIndexUpdate,
        }));
        break;
      case PageIndexOptions.primary:
        setState((prevState) => ({
          ...prevState,
          showingAllActions: false,
          showingPrimaryActions: true,
          ...pageIndexUpdate,
        }));
        break;
    }
  };

  return (
    <Card isLoading={loading}>
      {closedWonBannerText ? (
        <Box
          p={6}
          borderBottomWidth={1}
          borderBottomStyle={'solid'}
          borderBottomColor={'brand.gray-300'}
        >
          <Text>{closedWonBannerText}</Text>
        </Box>
      ) : null}
      {accountInfo?.account && (
        <>
          <Winfluence
            accountInfo={accountInfo.account}
            headerText={props.winfluence.title}
            subtitle={props.winfluence.subtitle}
            tooltip={props.winfluence.tooltip}
          />
          <AccountScoreSection
            accountInfo={accountInfo.account}
            pageIndex={pageIndex}
            setPageIndex={updatePageIndex}
          />
          <Recommendations
            accountInfo={accountInfo.account}
            sectionVisibilityToggles={{
              setShowingAllActions: (newVal: boolean) =>
                setState((prevState) => ({
                  ...prevState,
                  showingAllActions: newVal,
                })),
              showingAllActions,
              setShowingPrimaryActions: (newVal: boolean) =>
                setState((prevState) => ({
                  ...prevState,
                  showingPrimaryActions: newVal,
                })),
              showingPrimaryActions,
            }}
            {...props}
          />
        </>
      )}
    </Card>
  );
};
