import { QuickStatType } from 'app/src/components/Account/components/sav/overview/accountSummary/singleAccountQuickStat/singleAccountQuickStatTypes';

export enum QuickStatFieldOptions {
  none = 'none',
  currentValue = 'currentValue',
  engagementStatus = 'engagementStatus',
  firstClosedOpportunityAt = 'firstClosedOpportunityAt',
  lastInboundSignal = 'lastInboundSignal',
  lastOutboundSignal = 'lastOutboundSignal',
  lostOpportunityCount = 'lostOpportunityCount',
  openOpportunityCount = 'openOpportunityCount',
  signalActivityIntensity = 'signalActivityIntensity',
  signalResponseIntensity = 'signalResponseIntensity',
  wonOpportunityCount = 'wonOpportunityCount',
  xeominAverageQuantity = 'xeominAverageQuantity',
  xeominPurchaseTrendLong = 'xeominPurchaseTrendLong',
  xeominPurchaseTrendShort = 'xeominPurchaseTrendShort',
  xeominSpendFraction = 'xeominSpendFraction',
}

export type SingleAccountQuickStatProps = {
  title: string;
  tooltip?: string;
  center: QuickStatType;
  bottom: QuickStatType;
  externalQueryLoading?: boolean;
  bgColor?: string;
};
