import {createIcon} from '@chakra-ui/icon';

export const NewsIcon = createIcon({
  displayName: 'News',
  viewBox: '0 0 20 24',
  path: (
    <svg
      width='20'
      height='24'
      viewBox='0 0 20 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V23C0 23.2652 0.105357 23.5196 0.292893 23.7071C0.48043 23.8946 0.734784 24 1 24H19C19.2652 24 19.5196 23.8946 19.7071 23.7071C19.8946 23.5196 20 23.2652 20 23V1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0V0ZM16 20H4V18H16V20ZM16 15H4V13H16V15ZM16 10H4V4H16V10Z'
        fill='white'
      />
    </svg>
  ),
});
