import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { QueryResult, useReactiveVar } from '@apollo/client';
import {
  ApiGetAggregateDataQuery,
  ApiGetAggregateDataQueryVariables,
  ApiGetAggregateDataWithFilterVarQuery,
  ApiGetAggregateDataWithFilterVarQueryVariables,
  useGetAggregateDataLazyQuery,
  useGetAggregateDataWithFilterVarQuery,
} from 'shared/graphql/generatedApiTypes';
import {
  appliedFilterVar,
  appliedListsVar,
  getInitialApolloState,
  globalFilterVar,
  InitialApolloState,
  initialApolloState,
} from '../apollo/rootReactiveVariables';
import {
  GlobalFilterActionType,
  globalFilterReducer,
  GlobalFilterReducerState,
} from '../components/GlobalFilters/reducers/globalFilterReducer';
import _size from 'lodash/size';
import { adaptDictAsString } from '../components/GlobalFilters/helpers/adaptFilterForQuery';
import { FilterIndices } from '../components/GlobalFilters/types/filterTypes';
import { getAccountGroupStats } from 'app/src/context/helpers/getAccountGroupStats';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { defaultQueryFetchPolicy } from 'shared/graphql';
import { filterStringFromBackendToDict } from 'app/src/components/GlobalFilters/helpers/filterDictAndFilterStringHelpers';
import { FullSavedStructureSchema } from 'shared/firebase/schemas/SavedStructure';
import DatabaseManager from 'shared/firebase/classes/FirestoreManager';
import AuthManager from 'shared/firebase/classes/AuthManager';

export type AccountGroupStat = {
  key: FilterIndices;
  value:
    | { min?: number | string; max?: number | string }
    | string
    | string[]
    | number[];
};

export const AggregateDataContext = createContext<
  Pick<
    QueryResult<ApiGetAggregateDataQuery, ApiGetAggregateDataQueryVariables>,
    'data' | 'loading' | 'error'
  > & {
    accountGroupStats: AccountGroupStat[];
    fullAggregateData: QueryResult<
      ApiGetAggregateDataWithFilterVarQuery,
      ApiGetAggregateDataWithFilterVarQueryVariables
    >['data'];
    fullAggregateError: QueryResult<
      ApiGetAggregateDataWithFilterVarQuery,
      ApiGetAggregateDataWithFilterVarQueryVariables
    >['error'];
    fullAggregateLoading: QueryResult<
      ApiGetAggregateDataWithFilterVarQuery,
      ApiGetAggregateDataWithFilterVarQueryVariables
    >['loading'];
    globalFilter: InitialApolloState['globalFilter'];
    overrideFilter: (payload: InitialApolloState['globalFilter']) => void;
    resetFilter: () => void;
    updateExcludedIds: (newIds: number[]) => void;
    updateFilter: (payload: { index: FilterIndices; value: any }) => void;
  }
>({
  accountGroupStats: [],
  data: {
    aggregateData: null,
    filterString: null,
  },
  error: undefined,
  fullAggregateData: {
    aggregateData: null,
  },
  fullAggregateError: undefined,
  fullAggregateLoading: false,
  globalFilter: initialApolloState.globalFilter,
  loading: false,
  overrideFilter: () => {},
  resetFilter: () => {},
  updateExcludedIds: () => {},
  updateFilter: () => {},
});

export default function AggregateDataContextProvider({
  children,
  structure,
}: {
  children: ReactNode;
  structure: FullSavedStructureSchema;
}) {
  const globalFilter = useReactiveVar(globalFilterVar);
  const globalFiltersStructure = structure.filters;
  const { search } = useLocation();
  const [userExcludedAccountIds, setUserExcludedAccountIds] = useState<
    number[] | null
  >(null);

  const defaultFilter = useMemo(() => {
    // either the filter comes from the configured default
    let filterFromStructure = globalFiltersStructure?.defaultAppliedFilter;
    if (search) {
      const parsedSearch = queryString.parse(search);
      const filter = parsedSearch.filter ?? '';
      // or from the URL if there are more configured params than just the account filter type
      if (
        filter &&
        Object.keys(JSON.parse(decodeURIComponent(filter as string))).length > 1
      ) {
        filterFromStructure = decodeURIComponent(parsedSearch.filter as string);
      }
    }
    return filterFromStructure ?? JSON.stringify({});
  }, [search, globalFiltersStructure]);

  const apolloState = getInitialApolloState(defaultFilter);

  const [state, dispatch] = useReducer(
    globalFilterReducer,
    apolloState.globalFilter,
  );

  // use a lazy query so that we wait for the initial global filter to be set before querying
  const [getAggregateAccountsData, { data, error, loading }] =
    useGetAggregateDataLazyQuery({
      ...defaultQueryFetchPolicy,
      notifyOnNetworkStatusChange: true,
    });

  useEffect(() => {
    (async () => {
      if (!userExcludedAccountIds) {
        const firebaseUserUID = AuthManager.currentUser?.uid ?? '';
        const companyName = AuthManager.klearlyUser?.companyName ?? '';
        const userSettingsDocId = `${firebaseUserUID}#${companyName}`;
        const userSettingsDoc = await DatabaseManager.UserSettingsModel.get(
          userSettingsDocId,
        );
        const excludedAccountIds =
          userSettingsDoc?.data?.excludedAccountIds ?? [];
        setUserExcludedAccountIds(excludedAccountIds);
      } else if (globalFilter) {
        await getAggregateAccountsData({
          //@ts-ignore: filter string is handled as a client export
          variables: {
            type: 'account',
          },
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalFilter]);

  useEffect(() => {
    if (userExcludedAccountIds) {
      dispatch({
        type: GlobalFilterActionType.UPDATE_OR_ADD,
        payload: {
          index: FilterIndices.EXCLUDED_ACCOUNT_IDS,
          value: userExcludedAccountIds,
        },
      });
    }
  }, [userExcludedAccountIds]);

  // Get all the data so that we can do the filter comparisions (# of # accounts)
  const {
    data: fullAggregateData,
    loading: fullAggregateLoading,
    error: fullAggregateError,
  } = useGetAggregateDataWithFilterVarQuery({
    ...defaultQueryFetchPolicy,
    variables: {
      filterString:
        adaptDictAsString({
          accountFilterType: FilterIndices.ACCOUNTS_FILTER_TYPE_INCLUSIVE,
        }) ?? '',
      type: 'account',
    },
    notifyOnNetworkStatusChange: true,
  });

  const accountGroupStats = useMemo(
    () => getAccountGroupStats(globalFilter),
    [globalFilter],
  );
  useEffect(() => {
    if (state) {
      const newFilter: any = {};
      Object.keys(state).forEach((key) => {
        if (_size(state[key])) {
          newFilter[key] = state[key];
        }
        if (key === FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS) {
          const groupIdsInFilter = state[key];
          appliedListsVar(groupIdsInFilter);
        }
      });
      globalFilterVar(newFilter);
    }
  }, [state]);

  return (
    <AggregateDataContext.Provider
      value={{
        accountGroupStats,
        data,
        error,
        fullAggregateData,
        fullAggregateError,
        fullAggregateLoading,
        globalFilter: state,
        loading,
        overrideFilter: (payload: GlobalFilterReducerState) =>
          dispatch({
            type: GlobalFilterActionType.OVERRIDE,
            payload: {
              ...payload!,
              [FilterIndices.EXCLUDED_ACCOUNT_IDS]: userExcludedAccountIds,
            },
          }),
        resetFilter: () =>
          globalFiltersStructure
            ? dispatch({
                type: GlobalFilterActionType.RESET,
                payload: {
                  ...filterStringFromBackendToDict(
                    globalFiltersStructure.defaultAppliedFilter,
                  ),
                  [FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS]:
                    globalFilter &&
                    globalFilter[FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS]
                      ? globalFilter[
                          FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS
                        ]
                      : [],
                  [FilterIndices.ACCOUNTS_FILTER_TYPE]:
                    FilterIndices.ACCOUNTS_FILTER_TYPE_INCLUSIVE,
                  accounts: [],
                  [FilterIndices.EXCLUDED_ACCOUNT_IDS]: userExcludedAccountIds,
                },
              })
            : {},
        updateExcludedIds: (newIds: number[]) =>
          setUserExcludedAccountIds(newIds),
        updateFilter: (payload: { index: FilterIndices; value: any }) => {
          // clear out the previously applied filter unless the updated field is not a filter field
          if (
            ![
              FilterIndices.ACCOUNT_LIST_ACCOUNT_GROUP_IDS,
              FilterIndices.EXCLUDED_ACCOUNT_IDS,
            ].includes(payload.index)
          ) {
            appliedFilterVar(null);
          }
          dispatch({
            type: GlobalFilterActionType.UPDATE_OR_ADD,
            payload,
          });
        },
      }}
    >
      {children}
    </AggregateDataContext.Provider>
  );
}
