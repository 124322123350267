import { extendTheme } from '@chakra-ui/react';
import { styles } from './styles';
import { foundations } from './foundations';
import { config } from './themeConfig';
import { direction } from './themeDirection';
import { components } from './components';

export const theme = extendTheme({
  ...foundations,
  direction,
  styles,
  config,
  components,
});
