import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
  Input,
} from '@chakra-ui/react';
import { BannerPopover } from 'app/src/components/TableSection/components/BannerPopover';
import { usePanelWidthAdjustments } from 'app/src/hooks/usePanelWidthAdjustments';
import { useEffect, useState } from 'react';
import { Button } from 'shared/components/Core';
import { ExportIcon } from 'shared/components/Core/Icon/export';
import { useContactCsvExport } from '../../TableSection/hooks/useContactCsvExport';
import AuthManager from 'shared/firebase/classes/AuthManager';
import Select from 'react-select';

type UnhiddenActionBarProps = {
  selectedRowIds: string[];
  toggleAllRowsSelected: (val: boolean) => void;
  allContacts: any[];
  filterVariable: any;
  pageInfo: any;
};

export const ActionTray = ({
  selectedRowIds,
  toggleAllRowsSelected,
  allContacts,
  filterVariable,
  pageInfo,
}: UnhiddenActionBarProps) => {
  const exportModal = useDisclosure();
  const { contentLeft, contentWidth } = usePanelWidthAdjustments();
  const [selectedFlatRows, setSelectedFlatRows] = useState<any[]>([]);
  const klearlyUser = AuthManager?.klearlyUser;
  const hubSpotListInteractionModel = useDisclosure();
  const { contactExportButton, contactExportFieldsCheckboxGrid } =
    useContactCsvExport(selectedFlatRows, filterVariable, pageInfo);
  const [hubSpotListName, setHubSpotListName] = useState<string>('');
  const [hubSpotList, setHubSpotList] = useState<any[]>([]);
  const [radioSelect, setRadioSelect] = useState<string>('');

  useEffect(() => {
    (async () => {
      let tempContact = allContacts?.filter((contact) =>
        selectedRowIds.includes(contact?.id),
      );
      setSelectedFlatRows(tempContact ?? []);
    })();
  }, [selectedRowIds]);

  useEffect(() => {
    if (selectedRowIds.length === 0) {
      toggleAllRowsSelected(false);
    }
  }, [selectedRowIds]);

  const handleHubSpotIntegration = async () => {
    try {
      let tempSelectedData: any = [];
      selectedFlatRows.forEach((row) => {
        let tempObj: any = {
          vid: row.id,
          properties: [],
        };
        Object.entries(row).forEach(([key, value]) => {
          if (
            key === 'firstName' ||
            key === 'lastName' ||
            key === 'companyName'
          ) {
            tempObj.properties.push({
              property: key === 'companyName' ? 'company' : key.toLowerCase(),
              value: value,
            });
          }
        });
        tempSelectedData.push(tempObj);
      });
      if (klearlyUser?.company?.id) {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
        var urlencoded = new URLSearchParams();
        urlencoded.append('data', JSON.stringify(tempSelectedData));
        urlencoded.append('companyId', klearlyUser.company.id.toString());
        urlencoded.append('listName', hubSpotListName);
        var requestOptions: any = {
          method: 'POST',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow',
        };
        await fetch(
          'https://actions-gxn6pxrd4q-uc.a.run.app/contacts/bulkUploadToList',
          requestOptions,
        )
          .then(async (response) => {
            response.text();
            await fetchHubSpotList();
          })
          .catch((error) => console.log('error', error));
      }
    } catch {}
  };

  const fetchHubSpotList = async () => {
    fetch(
      'https://actions-gxn6pxrd4q-uc.a.run.app/contacts/cotactLists?companyId=11',
    )
      .then((response) => response.text())
      .then((result) => {
        let listData = JSON.parse(result);
        setHubSpotList(
          listData?.data.map((item) => {
            return { ...item, label: item?.name, value: item?.name };
          }),
        );
      })
      .catch((error) => console.log('error', error));
  };

  // useEffect(() => {
  //   fetchHubSpotList();
  // }, []);

  return (
    <Box
      left={contentLeft}
      pos={'fixed'}
      w={contentWidth}
      bottom={0}
      color={'brand.white'}
    >
      <Flex
        align={'center'}
        justify={'space-between'}
        bg={'brand.klarityBlue'}
        minH={'56px'}
        p={3}
      >
        <Text ml={4} mr={2}>
          {selectedRowIds?.length ?? 0} item(s) selected
        </Text>
        <Flex maxW={'60%'} gap={2} flexWrap={'wrap'} justify={'center'}>
          <BannerPopover
            popoverTitle={'Export'}
            interactionModal={exportModal}
            body={
              <>
                <Text>Select fields:</Text>
                {contactExportFieldsCheckboxGrid}
                <HStack justify={'flex-end'} spacing={2}>
                  <Button
                    onClick={() => {
                      toggleAllRowsSelected(false);
                      exportModal.onClose();
                    }}
                    variant={'actionOutline'}
                    text={'Cancel'}
                    fontSize={['sm', null, null, 'md', null]}
                  />
                  {contactExportButton}
                </HStack>
              </>
            }
          >
            <Button
              fontSize={['sm', null, null, 'md', null]}
              text={'Export'}
              variant={'banner'}
              leftIcon={<ExportIcon />}
              onClick={exportModal.onOpen}
            />
          </BannerPopover>
          {/* <BannerPopover
            interactionModal={hubSpotListInteractionModel}
            popoverTitle={'Create a new static list'}
            body={
              <>
                <div>
                  
                  <RadioGroup onChange={(e) => setRadioSelect(e)}>
                    <Stack spacing={5} direction='column'>
                      <Radio value='1'>
                        {' '}
                        <Text>Create a new static list</Text>
                      </Radio>
                      <Input
                        placeholder=''
                        onChange={(e) => {
                          setHubSpotListName(e.target.value);
                        }}
                        isDisabled={radioSelect === '1' ? false : true}
                      />
                      <Radio value='2'>
                        <Text>Add to an exisiting static list</Text>
                      </Radio>
                      <Select
                        className='basic-single'
                        isLoading={false}
                        isSearchable={true}
                        name='color'
                        options={hubSpotList}
                        classNamePrefix='hubSpot-list-select'
                        onChange={(e) => setHubSpotListName(e?.value)}
                        isDisabled={radioSelect === '2' ? false : true}
                      />
                    </Stack>
                  </RadioGroup>
                </div>
                <Button
                  text={'Continue'}
                  isDisabled={hubSpotListName !== '' ? false : true}
                  onClick={handleHubSpotIntegration}
                  mt={3}
                />
              </>
            }
          >
            <Button
              fontSize={['sm', null, null, 'md', null]}
              text={'Hub spot'}
              onClick={hubSpotListInteractionModel.onOpen}
              variant={'banner'}
            />
          </BannerPopover> */}
        </Flex>
        <Button
          mr={24}
          ml={6}
          text={'Cancel'}
          leftIcon={<CloseIcon boxSize={3} />}
          onClick={() => toggleAllRowsSelected(false)}
        />
      </Flex>
    </Box>
  );
};
