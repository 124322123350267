import { createRef } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

export const BannerPopover = ({
  interactionModal,
  children,
  body,
  popoverTitle,
}) => {
  const bannerPopoverTriggerRef = createRef();
  const popoverTop = bannerPopoverTriggerRef?.current
    ? ((bannerPopoverTriggerRef?.current as HTMLButtonElement)
        ?.offsetTop as number)
    : 0;
  return (
    <Popover
      onClose={interactionModal.onClose}
      isOpen={interactionModal.isOpen}
    >
      <Flex alignItems={'center'} justify={'space-between'} pb={2}>
        <PopoverTrigger>{children}</PopoverTrigger>
      </Flex>
      <Portal>
        <PopoverContent top={popoverTop} w={500} color={'brand.black'}>
          <PopoverHeader pt={4} fontWeight='bold'>
            {popoverTitle}
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Box bg={'brand.white'}>{body}</Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
