import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { FirebaseInvokedAuth } from 'shared/firebase/types/firebaseTypes';

// sets the reactive var tokenVar to be a getter function for the current users' auth token
export const useToken = (
  tokenVar,
  currentUser: FirebaseInvokedAuth['currentUser'] | null = null,
) => {
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (currentUser) {
        // This means that we have an unexpired JWT saved in state, so just return that
        if (
          token &&
          Math.round(new Date().getTime() / 1000) <
            jwtDecode<{ exp: number }>(token).exp
        ) {
          return () => token;
        }
        // @ts-ignore Otherwise, we use 'true' to force a refresh of the token & overwrite it
        const refreshedToken = await currentUser.getIdToken(true);
        setToken(refreshedToken);
        tokenVar(() => refreshedToken);
      }
    })();
  }, [currentUser]);
};
