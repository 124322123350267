import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import {
  SingleAccountSignalAction,
  SingleAccountSignalReducerState,
} from 'app/src/components/Account/reducers/singleAccountSignalReducer';
import React, { Dispatch } from 'react';
import {
  SignalHistorySectionFilter,
  SingleAccountSignalsTimePeriod,
} from 'app/src/components/Account/types/SingleAccountSignalsTypes';
import { getSignalHistoryFilter } from 'app/src/components/Account/helpers/getSignalHistoryFilter';

type SingleAccountSignalHistoryFilterDrawerProps = {
  dispatch: Dispatch<SingleAccountSignalAction>;
  drawerIsOpen: boolean;
  drawerOnClose: () => void;
  fiscalYearStartingMonth: number;
  sectionPanelFilters?: {
    filters: SignalHistorySectionFilter[];
    lineBreakAfter?: SignalHistorySectionFilter;
  };
  selectedTimePeriod: SingleAccountSignalsTimePeriod;
  setSelectedTimePeriod: (
    newTimePeriod: SingleAccountSignalsTimePeriod,
  ) => void;
  state: SingleAccountSignalReducerState;
  typeDetailOptions: Array<{ value: string; label: string }>;
  typeOptions: Array<{ value: string; label: string }>;
};

export const SingleAccountSignalHistoryFilterDrawer = ({
  dispatch,
  drawerIsOpen,
  drawerOnClose,
  fiscalYearStartingMonth,
  sectionPanelFilters,
  selectedTimePeriod,
  setSelectedTimePeriod,
  state,
  typeDetailOptions,
  typeOptions,
}: SingleAccountSignalHistoryFilterDrawerProps) => {
  return (
    <Drawer isOpen={drawerIsOpen} placement='right' onClose={drawerOnClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth='1px'>
          Signals section filters
        </DrawerHeader>
        <DrawerBody>
          <Stack spacing='24px'>
            {sectionPanelFilters?.filters.map((filter) => (
              <>
                {getSignalHistoryFilter({
                  dispatch,
                  filter,
                  fiscalYearStartingMonth,
                  selectedTimePeriod,
                  setSelectedTimePeriod,
                  state,
                  typeOptions: typeOptions ?? [],
                  typeDetailOptions: typeDetailOptions ?? [],
                })}
                {filter === sectionPanelFilters.lineBreakAfter && (
                  <>
                    <StackDivider />
                    <Divider borderColor={'brand.gray-600'} />
                    <StackDivider />
                  </>
                )}
              </>
            ))}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
