export enum SingleAccountSignalsTimePeriod {
  'currentQtr' = 'currentQtr',
  'previousQtr' = 'previousQtr',
  'last5Qtrs' = 'last5Qtrs',
  'oneWeek' = 'oneWeek',
  'oneMonth' = 'oneMonth',
  'sixMonths' = 'sixMonths',
  'yearToDate' = 'yearToDate',
  'oneYear' = 'oneYear',
}

export enum Granularity {
  quarterly = 'quarterly',
  monthly = 'monthly',
  daily = 'daily',
}

export enum SignalSectionUnitType {
  count = 'count',
  quantity = 'quantity',
}

export type SingleAccountSignalItem = {
  marketing: number;
  occurredAt: string;
  originalDate: string;
  sales: number;
};

export type SingleAccountSignalHistorySectionProps = {
  sectionHeaderText: string;
  sectionHeaderSubtext: string;
  sectionHeaderTooltip: string;
  sectionTableColumns?: SignalHistorySectionTableColumn[];
  sectionQuickFilters?: SignalHistorySectionFilter[];
  sectionPanelFilters?: {
    filters: SignalHistorySectionFilter[];
    lineBreakAfter?: SignalHistorySectionFilter;
  };
};

export enum SignalHistorySectionTableColumn {
  type = 'type',
  typeWithDot = 'typeWithDot',
  typeDetail = 'typeDetail',
  topic = 'topic',
  topicDetail = 'topicDetail',
  orderNumber = 'orderNumber',
  quantity = 'quantity',
  direction = 'direction',
  directionWithDot = 'directionWithDot',
  action = 'action',
  source = 'source',
  sourceWithDot = 'sourceWithDot',
  count = 'count',
}

export enum SignalHistorySectionFilter {
  summarizeBy = 'summarizeBy',
  granularity = 'granularity',
  source = 'source',
  direction = 'direction',
  type = 'type',
  typeDetail = 'typeDetail',
  timePeriod = 'timePeriod',
}
