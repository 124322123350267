/**
 * Run an async method once for each item in a list; comparable to running
 * `list.forEach(...)` except that each method runs asynchronously.
 * @param list The list of items to iterate over.
 * @param func The function to be run for each item in `list`, with the item passed
 *             as the first param. Should be an async function or otherwise return a Promise.
 * @return Promise that resolves to the resolved value of each invocation of `func` as soon as
 *         the slowest invocation resolves.
 */
export async function asyncForEach<T, K>(
  list: T[],
  func: (t: T) => Promise<K>,
): Promise<K[]> {
  const promises: Promise<K>[] = [];

  for (let i = 0; i < list.length; i++) {
    promises.push(func(list[i]));
  }

  return Promise.all(promises);
}
